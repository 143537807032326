// TODO: location of this file is not yet defined

import { takeLatest, delay, put, call, fork, take } from 'redux-saga/effects';
import { channel } from 'redux-saga';
import restClient from 'erpcore/api/restClient';
import { actions as notificationManagerActions } from 'erputils/NotificationManager/NotificationManager.reducer';
import dto from 'erputils/dto';
import { actions as headerMenuActions } from 'erpcore/screens/Settings/screens/ResellerAdmin/HeaderMenu/HeaderMenu.reducer';

/**
 * Create headerMenu
 * @param  promise {Object}
 * @param  formData {Object}
 * @return {void}
 */
export function* createHeaderMenu({ promise, formData }) {
    try {
        // Create headerMenu
        const createHeaderMenuAPI = yield restClient.put(
            `api/general-settings/reseller-header-menu-sort`,
            formData
        );
        yield put({
            type: headerMenuActions.CREATE_HEADER_MENU_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: { ...createHeaderMenuAPI.data, code: 'headermenu.itemSuccessfulyCreated' }
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: headerMenuActions.CREATE_HEADER_MENU_FAILED,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Fetch ticketType
 * @param promise {Object}
 * @param id {number} ticketType id
 * @param params {Object}
 * @return {void}
 */
export function* fetchTicketType({ promise, id, params }) {
    try {
        const fetchTicketTypeAPI = yield restClient.get(`api/ticket-types/${id}`, { params });
        yield put({
            type: headerMenuActions.FETCH_SINGLE_TICKET_TYPE_SUCCESSFUL
        });
        yield put({
            type: headerMenuActions.STORE_SINGLE_TICKET_TYPE_DATA,
            id,
            response: dto(fetchTicketTypeAPI.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: headerMenuActions.FETCH_SINGLE_TICKET_TYPE_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Update ticketType single data
 * @param  promise {Object}
 * @param  formData {Object}
 * @param  id {number} ticketType id
 * @param  debounceDelay {number} Number of milliseconds to delay execution
 * @return {void}
 */
export function* updateSingleHeaderMenu({ promise, formData, id, debounceDelay = 0 }) {
    yield delay(debounceDelay);
    yield put({
        type: headerMenuActions.START_UPDATE_SINGLE_HEADER_MENU
    });
    try {
        const updateSingleHeaderMenuAPI = yield restClient.put(
            `api/general-settings/reseller-header-menu-sort`,
            formData
        );
        yield put({
            type: headerMenuActions.UPDATE_SINGLE_HEADER_MENU_SUCCESSFUL
        });
        yield put({
            type: headerMenuActions.STORE_SINGLE_HEADER_MENU_DATA,
            id,
            response: dto(updateSingleHeaderMenuAPI.data)
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: {
                ...updateSingleHeaderMenuAPI.data,
                code: 'headermenu.itemSuccessfulyUpdated'
            }
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: headerMenuActions.UPDATE_SINGLE_HEADER_MENU_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Delete Single ticketType
 * @param  id {number} ticketType id
 * @param  promise {Object}
 * @return {void}
 */
export function* deleteSingleHeaderMenu({ promise, formData }) {
    try {
        const updateSingleHeaderMenuAPI = yield restClient.put(
            `api/general-settings/reseller-header-menu-sort`,
            formData
        );
        yield put({
            type: headerMenuActions.DELETE_SINGLE_HEADER_MENU_SUCCESSFUL
        });
        yield put({
            type: headerMenuActions.STORE_SINGLE_HEADER_MENU_DATA,
            response: dto(updateSingleHeaderMenuAPI.data)
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: {
                ...updateSingleHeaderMenuAPI.data,
                code: 'headermenu.itemSuccessfulyRemoved'
            }
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: headerMenuActions.DELETE_SINGLE_HEADER_MENU_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

function takeLatestPerProps(propsOrSelector, pattern, worker, ...args) {
    // Not a generator
    return fork(function* generator() {
        // Fork a generator here to make it work like takeLatest
        const channelsMap = {};
        while (true) {
            const action = yield take(pattern); // yield necessary here
            const propsValue =
                typeof propsOrSelector === 'function'
                    ? propsOrSelector(action)
                    : action[propsOrSelector];
            if (!channelsMap[propsValue]) {
                channelsMap[propsValue] = channel();
                yield takeLatest(channelsMap[propsValue], worker, ...args);
            }
            yield put(channelsMap[propsValue], action);
        }
    });
}

/**
 * Register action to watcher
 */
export const headerMenuSaga = [
    takeLatest(headerMenuActions.START_CREATE_HEADER_MENU, createHeaderMenu),
    // takeLatest(headerMenuActions.START_FETCHING_SINGLE_TICKET_TYPE, fetchTicketType),
    takeLatestPerProps(
        'id',
        headerMenuActions.REQUEST_UPDATE_SINGLE_HEADER_MENU,
        updateSingleHeaderMenu
    ),
    takeLatest(headerMenuActions.START_DELETE_SINGLE_HEADER_MENU, deleteSingleHeaderMenu)
];
