import { takeLatest, put, call } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import dto from 'erputils/dto';
import { actions as notificationManagerActions } from 'erputils/NotificationManager/NotificationManager.reducer';
import { actions as listingActions } from 'erpcomponents/Listing/Listing.reducer';
import { actions as eventCategoriesActions } from './EventCategories.reducer';

/**
 * Create Category
 * @param  {Object} promise
 * @return {Object} formData
 */
export function* createCategory({ promise, formData }) {
    try {
        // Prepare data for /event-categories API

        formData = Object.assign(formData);

        // Create Category
        const createCategoryAPI = yield restClient.post(`api/event-categories`, formData);
        yield put({
            type: eventCategoriesActions.CREATE_EVENT_CATEGORY_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createCategoryAPI.data
        });
        yield put({
            type: listingActions.START_FETCHING_LISTING,
            entity: 'EVENT_CATEGORIES',
            endpoint: 'api/event-categories?pagination=false'
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: eventCategoriesActions.CREATE_EVENT_CATEGORY_FAILED,
            response: error.response.data
        });

        yield call(promise.reject, error.response.data);
    }
}

/**
 * Fetch CATEGORY
 * @param  {Object} promise
 * @return {string} id Event id
 */
export function* fetchCategory({ promise, id }) {
    try {
        const fetchEventCategoryAPI = yield restClient.get(`api/event-categories/${id}`);
        yield put({
            type: eventCategoriesActions.FETCH_SINGLE_EVENT_CATEGORY_SUCCESSFUL
        });
        yield put({
            type: eventCategoriesActions.STORE_SINGLE_EVENT_CATEGORY_DATA,
            id,
            response: dto(fetchEventCategoryAPI.data)
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: eventCategoriesActions.FETCH_SINGLE_EVENT_CATEGORY_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}
/**
 * Update event single data
 * @param  {Object} id ID of an event
 * @return {Object} Response from API
 */
export function* updateSingleEventCategory({ promise, formData, id }) {
    try {
        const updateSingleEventCategoryAPI = yield restClient.put(
            `api/event-categories/${id}`,
            formData
        );

        yield put({
            type: eventCategoriesActions.UPDATE_SINGLE_EVENT_CATEGORY_SUCCESSFUL
        });
        yield put({
            type: eventCategoriesActions.STORE_SINGLE_EVENT_CATEGORY_DATA,
            id,
            response: dto(updateSingleEventCategoryAPI.data)
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateSingleEventCategoryAPI.data
        });
        yield put({
            type: listingActions.START_FETCHING_LISTING,
            entity: 'EVENT_CATEGORIES',
            endpoint: 'api/event-categories?pagination=false'
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: eventCategoriesActions.UPDATE_SINGLE_EVENT_CATEGORY_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Delete Single CATEGORY
 * @param  {Object} id ID of an Event
 * @return {Object} Response from API
 */
export function* deleteSingleCategory({ promise, id }) {
    try {
        // Merge default with current params for listing refresh purpose

        const deleteSingleCategoryAPI = yield restClient.delete(`api/event-categories/${id}`);
        yield put({
            type: eventCategoriesActions.DELETE_SINGLE_EVENT_CATEGORY_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteSingleCategoryAPI.data
        });
        yield put({
            type: listingActions.START_FETCHING_LISTING,
            entity: 'EVENT_CATEGORIES',
            endpoint: 'api/event-categories?pagination=false'
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: eventCategoriesActions.DELETE_SINGLE_EVENT_CATEGORY_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

export const eventCategoriesSaga = [
    takeLatest(eventCategoriesActions.START_CREATE_EVENT_CATEGORY, createCategory),
    takeLatest(eventCategoriesActions.START_DELETE_SINGLE_EVENT_CATEGORY, deleteSingleCategory),
    takeLatest(eventCategoriesActions.START_FETCHING_SINGLE_EVENT_CATEGORY, fetchCategory),
    takeLatest(eventCategoriesActions.START_UPDATE_SINGLE_EVENT_CATEGORY, updateSingleEventCategory)
];
