import { takeLatest, put, call, all } from 'redux-saga/effects';
import dto from 'erputils/dto';
import restClient from 'erpcore/api/restClient';
import { actions as notificationManagerActions } from 'erputils/NotificationManager/NotificationManager.reducer';
import { actions as ticketSocketApiActions } from './TicketSocketAPI.reducer';

/**
 * Sync TicketSocket
 * @param  {Object} promise
 * @return {string} id Event id
 */
export function* syncTicketSocketApi({ promise }) {
    try {
        const syncTicketSocketApiAPI = yield restClient.get(`/api/orders/import/ticket-socket`);
        yield put({
            type: ticketSocketApiActions.SYNC_TICKET_SOCKET_API_SUCCESSFUL
        });
        yield put({
            type: ticketSocketApiActions.STORE_TICKET_SOCKET_API_DATA,
            response: syncTicketSocketApiAPI
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: { code: 'ticketsocket.syncSuccessfullyStarted' }
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: ticketSocketApiActions.SYNC_TICKET_SOCKET_API_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}
/**
 * Fetch TicketSocket
 * @param  {Object} promise
 * @return {string} id Event id
 */
export function* fetchTicketSocketApi({ promise }) {
    try {
        const responses = yield all([
            restClient.get(`/api/general-settings/ticket-socket-auto-pull`),
            restClient.get(`/api/general-settings/ticket-socket-create-from-import`),
            restClient.get(`/api/general-settings/ticket-socket-import-since-last-import`),
            restClient.get(`/api/general-settings/ticket-socket-interval-minutes`),
            restClient.get(`/api/general-settings/ticket-socket-import-from-date`),
            restClient.get(`/api/general-settings/ticket-socket-import-only-events`)
        ]);

        const fetchTicketSocketApiUrlAPI = responses[0];
        const fetchTicketSocketApiCreateFromImportAPI = responses[1];
        const fetchTicketSocketApiImportSinceAPI = responses[2];
        const fetchTicketSocketApiImportIntervalDataAPI = responses[3];
        const fetchTicketSocketApiImportFromDataAPI = responses[4];
        const fetchTicketSocketApiImportOnlyEventsDataAPI = responses[5];

        yield all([
            put({
                type: ticketSocketApiActions.STORE_TICKET_SOCKET_API_CREATE_NEW_DATA,
                response: dto(fetchTicketSocketApiCreateFromImportAPI.data)
            }),
            put({
                type: ticketSocketApiActions.STORE_TICKET_SOCKET_API_AUTOMATED_SYNC_DATA,
                response: dto(fetchTicketSocketApiUrlAPI.data)
            }),
            put({
                type: ticketSocketApiActions.STORE_TICKET_SOCKET_API_SINCE_LAST_IMPORT_DATA,
                response: dto(fetchTicketSocketApiImportSinceAPI.data)
            }),
            put({
                type: ticketSocketApiActions.STORE_TICKET_SOCKET_API_IMPORT_INTERVAL_DATA,
                response: dto(fetchTicketSocketApiImportIntervalDataAPI.data)
            }),
            put({
                type: ticketSocketApiActions.FETCHING_TICKET_SOCKET_API_SUCCESSFUL
            }),
            put({
                type: ticketSocketApiActions.STORE_TICKET_SOCKET_API_IMPORT_FROM_DATA,
                response: dto(fetchTicketSocketApiImportFromDataAPI.data)
            }),
            put({
                type: ticketSocketApiActions.STORE_TICKET_SOCKET_API_IMPORT_ONLY_EVENTS_DATA,
                response: dto(fetchTicketSocketApiImportOnlyEventsDataAPI.data)
            })
        ]);
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: fetchTicketSocketApiUrlAPI
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: ticketSocketApiActions.FETCHING_TICKET_SOCKET_API_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}
/**
 * Update TicketSocket
 * @param  {Object} promise
 * @return {string}
 */
export function* updateTicketSocketApi({ promise, formData, property }) {
    try {
        if (property === 'autopull') {
            const updateTicketSocketApiUrlAPI = yield restClient.put(
                `/api/general-settings/ticket-socket-auto-pull`,
                formData
            );
            yield put({
                type: ticketSocketApiActions.UPDATE_TICKET_SOCKET_API_SUCCESSFUL
            });
            yield put({
                type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                response: updateTicketSocketApiUrlAPI.data
            });
            yield put({
                type: ticketSocketApiActions.STORE_TICKET_SOCKET_API_AUTOMATED_SYNC_DATA,
                response: dto(updateTicketSocketApiUrlAPI.data)
            });
        }
        if (property === 'createfrom') {
            const updateTicketSocketApiCreateFromImportAPI = yield restClient.put(
                `/api/general-settings/ticket-socket-create-from-import`,
                formData
            );
            yield put({
                type: ticketSocketApiActions.UPDATE_TICKET_SOCKET_API_SUCCESSFUL
            });
            yield put({
                type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                response: updateTicketSocketApiCreateFromImportAPI.data
            });
            yield put({
                type: ticketSocketApiActions.STORE_TICKET_SOCKET_API_CREATE_NEW_DATA,
                response: dto(updateTicketSocketApiCreateFromImportAPI.data)
            });
        }
        if (property === 'importsince') {
            const updateTicketSocketApiImportSinceAPI = yield restClient.put(
                `/api/general-settings/ticket-socket-import-since-last-import`,
                formData
            );
            yield put({
                type: ticketSocketApiActions.UPDATE_TICKET_SOCKET_API_SUCCESSFUL
            });
            yield put({
                type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                response: updateTicketSocketApiImportSinceAPI.data
            });
            yield put({
                type: ticketSocketApiActions.STORE_TICKET_SOCKET_API_SINCE_LAST_IMPORT_DATA,
                response: dto(updateTicketSocketApiImportSinceAPI.data)
            });
        }
        if (property === 'interval') {
            const updateTicketSocketApiImportIntervalAPI = yield restClient.put(
                `/api/general-settings/ticket-socket-interval-minutes`,
                formData
            );
            yield put({
                type: ticketSocketApiActions.UPDATE_TICKET_SOCKET_API_SUCCESSFUL
            });
            yield put({
                type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                response: updateTicketSocketApiImportIntervalAPI.data
            });
            yield put({
                type: ticketSocketApiActions.STORE_TICKET_SOCKET_API_IMPORT_INTERVAL_DATA,
                response: dto(updateTicketSocketApiImportIntervalAPI.data)
            });
        }
        if (property === 'importOnlyEvents') {
            const updateTicketSocketApiImportOnlyEventsAPI = yield restClient.put(
                `/api/general-settings/ticket-socket-import-only-events`,
                formData
            );
            yield put({
                type: ticketSocketApiActions.UPDATE_TICKET_SOCKET_API_SUCCESSFUL
            });
            yield put({
                type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                response: updateTicketSocketApiImportOnlyEventsAPI.data
            });
            yield put({
                type: ticketSocketApiActions.STORE_TICKET_SOCKET_API_IMPORT_ONLY_EVENTS_DATA,
                response: dto(updateTicketSocketApiImportOnlyEventsAPI.data)
            });
        }
        if (property === 'importFrom') {
            const updateTicketSocketApiImportFromAPI = yield restClient.put(
                `/api/general-settings/ticket-socket-import-from-date`,
                formData
            );
            yield put({
                type: ticketSocketApiActions.UPDATE_TICKET_SOCKET_API_SUCCESSFUL
            });
            yield put({
                type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                response: updateTicketSocketApiImportFromAPI.data
            });
            yield put({
                type: ticketSocketApiActions.STORE_TICKET_SOCKET_API_IMPORT_FROM_DATA,
                response: dto(updateTicketSocketApiImportFromAPI.data)
            });
        }

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: ticketSocketApiActions.UPDATE_TICKET_SOCKET_API_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

export const ticketSocketApiSaga = [
    takeLatest(ticketSocketApiActions.START_SYNC_TICKET_SOCKET_API, syncTicketSocketApi),
    takeLatest(ticketSocketApiActions.START_UPDATE_TICKET_SOCKET_API, updateTicketSocketApi),
    takeLatest(ticketSocketApiActions.START_FETCHING_TICKET_SOCKET_API, fetchTicketSocketApi)
];
