import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FieldArray, reduxForm /* SubmissionError */ } from 'redux-form';
import Form, { Repeater } from 'erpcore/components/Form';
// import reduxFormErrorMapper from 'erputils/reduxFormErrorMapper';
import TicketTypeRepeaterItem from 'erpcore/screens/Events/screens/EventEdit/components/TicketTypeRepeaterItem';
// import valueValidation from 'erputils/valueValidation';
import { FormattedMessage } from 'react-intl';
import LayoutManager from 'erputils/LayoutManager';
import PageHeader from 'erpcomponents/Layout/PageHeader';
import PageContent from 'erpcomponents/Layout/PageContent';
import EventInfo from 'erpcore/screens/Events/components/EventInfo';
import EventEditTabs from 'erpcore/screens/Events/components/EventEditTabs';
import NotificationManager from 'erputils/NotificationManager';
// import Button from 'erpcomponents/Button';
import { getEventData, getSingleEventFetching } from 'erpcore/screens/Events/Events.selectors';
import {
    getSingleTicketTypesCreating,
    getSingleTicketTypesDeleting,
    getSingleTicketTypesUpdating
} from 'erpcore/screens/Events/screens/EventEdit/TicketTypes.selectors';
import { actions as eventsActions } from 'erpcore/screens/Events/Events.reducer';
import { actions as ticketTypesActions } from 'erpcore/screens/Events/screens/EventEdit/TicketTypes.reducer';

/**
 * Convert string ticket_types.id to int.
 * Sort ticket_types items by defined positions first, undefined/null positions last.
 * Then sort items with defined positions by ticket_types.position ascending.
 * If positions are equal then sort by ticket_types.id (ascending).
 * Sort group of ticket_types items with undefined/null positions by id (ascending).
 *
 * @param data
 * @return {Object}
 */
export const dtoEventTicketTypeSort = data => {
    const dataCopy = { ...{}, ...data };

    if (dataCopy && dataCopy.ticket_types && dataCopy.ticket_types.length) {
        dataCopy.ticket_types = dataCopy.ticket_types.map(ticketType => {
            return { ...ticketType };
        });
        // convert ticket_types.id to int
        dataCopy.ticket_types.forEach((item, index) => {
            if (!Number.isNaN(parseInt(item.id, 10))) {
                dataCopy.ticket_types[index].id = parseInt(item.id, 10);
            }
        });

        // sort ticket types
        dataCopy.ticket_types.sort((firstItem, secondItem) => {
            // if position is not set, treat position as a 999999 (otherwise null position would evaluate as less than any other defined position)
            const firstItemPosition =
                firstItem.position || firstItem.position === 0 ? firstItem.position : 999999;
            const secondItemPosition =
                secondItem.position || secondItem.position === 0 ? secondItem.position : 999999;

            let compare = 0;
            //  0 = order is unchanged
            //  1 = secondItem takes precedence over firstItem
            // -1 = firstItem takes precedence over secondItem
            if (firstItemPosition === secondItemPosition) {
                if (firstItem.id > secondItem.id) {
                    compare = 1;
                } else if (firstItem.id < secondItem.id) {
                    compare = -1;
                }
            } else if (firstItemPosition > secondItemPosition) {
                compare = 1;
            } else if (firstItemPosition < secondItemPosition) {
                compare = -1;
            }
            return compare;
        });
    }
    return dataCopy;
};

class EventTickets extends Component {
    componentDidMount() {
        const { fetchEventData, match } = this.props;
        fetchEventData(match.params.id);
    }

    handleSaveNewItem = ({ itemData = {} }) => {
        const { dispatch, match } = this.props;

        itemData.event = `/api/events/${match.params.id}`;

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: ticketTypesActions.START_CREATE_TICKET_TYPE,
                formData: itemData
            })
        )
            .catch(() => {
                // throw new SubmissionError(reduxFormErrorMapper(error));
            })
            .then(() => {
                this.handleAfterAnyTicketApiUpdate();
            });
    };

    handleRemoveItem = ({ itemData = {} }) => {
        const { dispatch } = this.props;

        const { id = false } = itemData;

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: ticketTypesActions.START_DELETE_SINGLE_TICKET_TYPE,
                id
            })
        )
            .catch(() => {
                // throw new SubmissionError(reduxFormErrorMapper(error));
            })
            .then(() => {
                this.handleAfterAnyTicketApiUpdate();
            });
    };

    handleSort = ({ allData }) => {
        const { dispatch } = this.props;

        const positions = allData.reduce((result, item, index) => {
            if (item.id) {
                result.push({
                    object_id: item.id,
                    position: index
                });
            }
            return result;
        }, []);

        const formData = {
            positions
        };

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: ticketTypesActions.REQUEST_SORT_TICKET_TYPES,
                formData,
                debounceDelay: 200
            })
        ).catch(() => {
            // throw new SubmissionError(reduxFormErrorMapper(error));
            this.handleAfterAnyTicketApiUpdate();
        });
    };

    handleAfterAnyTicketApiUpdate() {
        const { fetchEventData, match, reset } = this.props;
        fetchEventData(match.params.id).then(() => {
            reset();
        });
    }

    render() {
        const {
            // submitting,
            // pristine,
            // invalid,
            // singleTicketTypesUpdating,
            error,
            initialValues,
            match,
            singleEventFetching,
            singleTicketTypesCreating,
            singleTicketTypesDeleting
        } = this.props;

        return (
            <LayoutManager slot="main" className="main--narrow" layoutType="merge">
                <PageHeader
                    title={
                        <FormattedMessage id="EventTickets.title" defaultMessage="Ticket Types" />
                    }
                    backButtonLink="/events"
                />
                <EventInfo {...initialValues} />
                <EventEditTabs />
                <PageContent>
                    {error && error.code && <NotificationManager code={error.code} />}
                    <Form onSubmit={() => {}}>
                        <p>Note: Updates to existing ticket types will be automatically saved.</p>
                        <FieldArray
                            name="ticket_types"
                            component={Repeater}
                            RepeatableItem={TicketTypeRepeaterItem}
                            loading={
                                singleEventFetching ||
                                singleTicketTypesCreating ||
                                singleTicketTypesDeleting
                            }
                            data={{
                                eventId: match.params.id,
                                initialValues: initialValues && initialValues
                            }}
                            deleteModalTitle={
                                <FormattedMessage
                                    id="EventTickets.Repeater.deleteModal.title"
                                    defaultMessage="Delete Ticket Type"
                                />
                            }
                            deleteModalSubTitle={
                                <FormattedMessage
                                    id="EventTickets.Repeater.deleteModal.subTitle"
                                    defaultMessage="Are you sure you wanna delete this Ticket Type?"
                                />
                            }
                            onSortEnd={this.handleSort}
                            onSaveNewItem={this.handleSaveNewItem}
                            onRemoveItem={this.handleRemoveItem}
                        />
                    </Form>
                </PageContent>
            </LayoutManager>
        );
    }
}

EventTickets.defaultProps = {
    submitting: false,
    pristine: false,
    invalid: false,
    error: null,
    match: {},
    fetchEventData: () => {},
    singleEventFetching: false,
    singleTicketTypesCreating: false,
    singleTicketTypesDeleting: false,
    singleTicketTypesUpdating: false,
    initialValues: null
};

EventTickets.propTypes = {
    dispatch: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.object]),
    match: PropTypes.oneOfType([PropTypes.object]),
    fetchEventData: PropTypes.func,
    singleEventFetching: PropTypes.bool,
    singleTicketTypesCreating: PropTypes.bool,
    singleTicketTypesDeleting: PropTypes.bool,
    singleTicketTypesUpdating: PropTypes.bool,
    initialValues: PropTypes.oneOfType([PropTypes.object])
};

EventTickets = reduxForm({
    form: 'EventTicketsForm',
    enableReinitialize: true,
    keepDirtyOnReinitialize: false, // important
    updateUnregisteredFields: false // important
})(EventTickets);

//  Getting initial value populated in the form from the store
const mapStateToProps = (state, ownProps) => ({
    initialValues: dtoEventTicketTypeSort(getEventData(state, ownProps.match.params.id)),
    singleEventFetching: getSingleEventFetching(state),
    singleTicketTypesCreating: getSingleTicketTypesCreating(state),
    singleTicketTypesDeleting: getSingleTicketTypesDeleting(state),
    singleTicketTypesUpdating: getSingleTicketTypesUpdating(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchEventData: () => {
        // Getting included data from API by setting params
        const params = {
            include:
                'ticketTypes,organization,location,location.country,location.state,location.city'
        };
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: eventsActions.START_FETCHING_SINGLE_EVENT,
                id: ownProps.match.params.id,
                params
            });
        }).catch(error => ({ error }));
    }
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(EventTickets)
);
