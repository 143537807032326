import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { EditorState, convertToRaw, ContentState, SelectionState, Modifier } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import './TextEditor.scss';

class TextEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editorState: EditorState.createEmpty(),
            initialized: false
        };

        this.getEditorState = this.getEditorState.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        const { initialized } = state;
        if (!initialized && props.meta && props.meta.initial) {
            const blocksFromHtml = htmlToDraft(props.meta.initial || '');
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            return {
                editorState: EditorState.createWithContent(contentState),
                initialized: true
            };
        }
        return {};
    }

    getSnapshotBeforeUpdate(prevProps) {
        const { input } = this.props;
        const { input: prevInput } = prevProps;

        if (input.value !== prevInput.value)
            if (!input.value) {
                this.setState({
                    editorState: EditorState.createEmpty()
                });
            }
    }

    getEditorState() {
        const { editorState } = this.state;
        return editorState;
    }

    onEditorStateChange = editorState => {
        const { input } = this.props;

        this.setState({
            editorState
        });

        let reduxFormValue = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        if (String(reduxFormValue).trim() === String('<p></p>').trim()) {
            reduxFormValue = '';
        }
        input.onChange(reduxFormValue);
    };

    handleEmptyContent() {
        const { editorState } = this.state;
        let emptyEditorState = editorState;
        let contentState = emptyEditorState.getCurrentContent();
        const firstBlock = contentState.getFirstBlock();
        const lastBlock = contentState.getLastBlock();
        const allSelected = new SelectionState({
            anchorKey: firstBlock.getKey(),
            anchorOffset: 0,
            focusKey: lastBlock.getKey(),
            focusOffset: lastBlock.getLength(),
            hasFocus: true
        });
        contentState = Modifier.removeRange(contentState, allSelected, 'backward');
        emptyEditorState = EditorState.push(emptyEditorState, contentState, 'remove-range');
        this.setState({ editorState: emptyEditorState });
    }

    render() {
        const { meta, input, fieldAttr } = this.props;
        return (
            <div
                className={`text-editor${meta.touched && meta.error ? ` text-editor--error` : ''}${
                    fieldAttr.required === true ? ' text-editor--required' : ''
                }`}
            >
                <Editor
                    editorState={this.getEditorState()}
                    toolbarClassName="text-editor__toolbar"
                    wrapperClassName="text-editor__wrapper"
                    editorClassName="text-editor__editor"
                    onEditorStateChange={this.onEditorStateChange}
                    onFocus={() => input.onFocus()}
                    onBlur={() => input.onBlur()}
                />
                {meta.touched && meta.error && (
                    <span className="text-editor__error">
                        {meta.error.message ? meta.error.message : meta.error}
                    </span>
                )}
            </div>
        );
    }
}
TextEditor.defaultProps = {
    input: {},
    meta: {},
    fieldAttr: {}
};
TextEditor.propTypes = {
    input: PropTypes.oneOfType([PropTypes.object]),
    meta: PropTypes.oneOfType([PropTypes.object]),
    fieldAttr: PropTypes.oneOfType([PropTypes.object])
};
export default TextEditor;
