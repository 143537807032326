import React, { Component } from 'react';
import './OrderNumberAndInfo.scss';
import PropTypes from 'prop-types';
import Svg from 'erpcomponents/Svg';
import { NavLink } from 'react-router-dom';

class OrderNumberAndInfo extends Component {
    state = {};

    render() {
        const { orderNumber, shipped, orderId } = this.props;
        return (
            <div className="orders-table-order-number">
                <NavLink
                    to={`orders/${orderId}/order-info`}
                    className="orders-table-order-number__number"
                >
                    {orderNumber}
                </NavLink>
                {shipped && <Svg icon="time" />}
            </div>
        );
    }
}
OrderNumberAndInfo.defaultProps = {
    orderNumber: '',
    shipped: false,
    orderId: ''
};

OrderNumberAndInfo.propTypes = {
    orderNumber: PropTypes.oneOfType([PropTypes.string]),
    shipped: PropTypes.bool,
    orderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default OrderNumberAndInfo;
