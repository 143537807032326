import React from 'react';
import { connect } from 'react-redux';
import { Field, FieldArray, reduxForm } from 'redux-form';
import Form, {
    Select,
    MultiSelect,
    Autocomplete,
    MultiAutocomplete,
    Creatable,
    MultiCreatable,
    SwitchCard,
    Text,
    Gallery
} from 'erpcore/components/Form';
import valueValidation from 'erputils/valueValidation';
import LayoutManager from 'erputils/LayoutManager';
import PageHeader from 'erpcomponents/Layout/PageHeader';
import PageContent from 'erpcomponents/Layout/PageContent';
import { typeOfProduct } from 'erpdata/vendors';

let FormTestPage = () => {
    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <PageHeader title="Form Test Page" />
            <PageContent>
                <Form onSubmit={() => {}}>
                    <h2>featured_images</h2>
                    <Form.Row>
                        <FieldArray
                            name="gallery"
                            id="form-test-gallery"
                            component={Gallery}
                            maxImageCount={20}
                            featuredKey="featured"
                            versionName="edited"
                            attachedToEntity={{ type: 'event', iri: '/api/events/1', id: '1' }}
                            getImagesApi="/api/events/1/images"
                            galleryApi="/api/event-images"
                            groupName="featured_images"
                        />
                    </Form.Row>
                    <h2>event_photos 2</h2>
                    <Form.Row>
                        <FieldArray
                            name="gallery-2"
                            id="form-test-gallery-2"
                            component={Gallery}
                            maxImageCount={20}
                            versionName="edited"
                            attachedToEntity={{ type: 'event', iri: '/api/events/1', id: '1' }}
                            getImagesApi="/api/events/1/images"
                            galleryApi="/api/event-images"
                            groupName="event_photos"
                            useImageManager={false}
                            useLightbox
                            featuredKey={false}
                        />
                    </Form.Row>
                    <Form.Row>
                        <Field
                            name="example_select"
                            id="example_select"
                            fieldProps={{
                                label: 'Select',
                                options: typeOfProduct,
                                clearable: true
                            }}
                            fieldAttr={{ required: true }}
                            component={Select}
                            validate={valueValidation([{ validator: 'required' }])}
                        />
                    </Form.Row>
                    <Form.Row>
                        <Field
                            name="example_multiselect"
                            id="example_multiselect"
                            fieldProps={{
                                label: 'MultiSelect',
                                options: typeOfProduct,
                                clearable: true
                            }}
                            fieldAttr={{ required: true }}
                            component={MultiSelect}
                            validate={valueValidation([{ validator: 'required' }])}
                        />
                    </Form.Row>
                    <Form.Row>
                        <Field
                            name="example_autocomplete"
                            id="example_autocomplete"
                            fieldProps={{
                                label: 'Autocomplete',
                                options: {
                                    endpoint: '/api/events',
                                    mapData: {
                                        value: 'iri',
                                        label: 'name'
                                    }
                                },
                                clearable: true
                            }}
                            fieldAttr={{ required: true }}
                            component={Autocomplete}
                            validate={valueValidation([{ validator: 'required' }])}
                        />
                    </Form.Row>
                    <Form.Row>
                        <Field
                            name="example_autocomplete_relationship"
                            id="example_autocomplete_relationship"
                            fieldProps={{
                                label: 'Autocomplete with relationship button',
                                options: {
                                    endpoint: '/api/events',
                                    mapData: {
                                        value: 'iri',
                                        label: 'name'
                                    }
                                },
                                clearable: true
                            }}
                            fieldAttr={{ required: true }}
                            component={Autocomplete}
                            validate={valueValidation([{ validator: 'required' }])}
                            displayActionButton
                            actionButtonUrl={{
                                prefix: '/events/',
                                suffix: '/edit/event-details'
                            }}
                        />
                    </Form.Row>
                    <Form.Row>
                        <Field
                            name="example_multiautocomplete"
                            id="example_multiautocomplete"
                            fieldProps={{
                                label: 'MultiAutocomplete',
                                options: {
                                    endpoint: '/api/events',
                                    mapData: {
                                        value: 'iri',
                                        label: 'name'
                                    }
                                },
                                clearable: true
                            }}
                            fieldAttr={{ required: true }}
                            component={MultiAutocomplete}
                            validate={valueValidation([{ validator: 'required' }])}
                        />
                    </Form.Row>
                    <Form.Row>
                        <Field
                            name="example_creatable"
                            id="example_creatable"
                            fieldProps={{
                                label: 'Creatable',
                                options: {
                                    endpoint: '/api/countries',
                                    mapData: {
                                        value: 'iri',
                                        label: 'name'
                                    }
                                }
                            }}
                            component={Creatable}
                        />
                    </Form.Row>
                    <Form.Row>
                        <Field
                            name="example_multicreatable"
                            id="example_multicreatable"
                            fieldProps={{
                                label: 'MultiCreatable',
                                options: {
                                    endpoint: '/api/countries',
                                    mapData: {
                                        value: 'iri',
                                        label: 'name'
                                    }
                                }
                            }}
                            component={MultiCreatable}
                        />
                    </Form.Row>

                    <Form.Row>
                        <Form.Columns breakOnMobile>
                            <Form.Column>
                                <Field
                                    name="example_switchCard_col_1"
                                    id="example_switchCard_col_1"
                                    fieldProps={{
                                        label: 'Form.Column 1',
                                        description:
                                            'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
                                    }}
                                    component={SwitchCard}
                                />
                            </Form.Column>
                            <Form.Column>
                                <Field
                                    name="example_switchCard_col_2"
                                    id="example_switchCard_col_2"
                                    fieldProps={{
                                        label: 'Form.Column 2',
                                        description:
                                            'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
                                    }}
                                    component={SwitchCard}
                                />
                            </Form.Column>
                        </Form.Columns>
                    </Form.Row>
                    <Form.Row>
                        <Field
                            name="example_switchCard_1"
                            id="example_switchCard_1"
                            fieldProps={{
                                label:
                                    'Long Title Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dapibus maximus lorem mollis malesuada dolor ullamcorper in.',
                                description:
                                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dapibus maximus lorem, mollis malesuada dolor ullamcorper in. Proin justo leo, mattis at molestie at, mattis a elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dapibus maximus lorem, mollis malesuada dolor ullamcorper in. Proin justo leo, mattis at molestie at, mattis a elit'
                            }}
                            component={SwitchCard}
                        />
                    </Form.Row>
                    <Form.Row>
                        <Field
                            name="example_switchCard_2"
                            id="example_switchCard_2"
                            fieldProps={{
                                label: 'Title test test',
                                description:
                                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
                            }}
                            component={SwitchCard}
                        />
                    </Form.Row>
                    <Form.Row>
                        <Field
                            name="example_switchCard_3"
                            id="example_switchCard_3"
                            fieldProps={{
                                label: 'Lorem ipsum dolor sit amet'
                            }}
                            component={SwitchCard}
                        />
                    </Form.Row>
                    <Form.Row>
                        <Field
                            name="example_3"
                            id="example_3"
                            fieldProps={{
                                label: 'Mask some text right here'
                            }}
                            format={value => {
                                // uzima se vrijednost iz redux-form stora i njoj se vizualno dodaje '.test.com' u prikazu, ne mjenjajući vrijednost u storu
                                const mask = '.test.com';
                                return value === null || value === undefined
                                    ? mask
                                    : `${value}${mask}`;
                            }}
                            // uzima se vrijednot iz inputa i uklanja se '.test.com' prije nego se vrijednost zapiše u redux-form stora
                            normalize={value => {
                                const mask = '.test.com';
                                return value === null || value === undefined
                                    ? null
                                    : value
                                          .replace(mask, '')
                                          .replace(
                                              /ovdje ide dodatno regex za ukloniti special characters/,
                                              ''
                                          );
                            }}
                            component={Text}
                        />
                    </Form.Row>
                </Form>
            </PageContent>
        </LayoutManager>
    );
};

FormTestPage = reduxForm({
    form: 'FormTestPageForm',
    enableReinitialize: true,
    updateUnregisteredFields: true
})(FormTestPage);

export default connect(
    null,
    null
)(FormTestPage);
