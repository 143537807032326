import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import LayoutManager from 'erputils/LayoutManager';
import PageHeader from 'erpcomponents/Layout/PageHeader';
import Listing from 'erpcomponents/Listing';
import ListingTableActions from 'erpcomponents/Listing/components/TableActions';
import Button from 'erpcomponents/Button';
import Chart from 'erpcomponents/Chart';
import Svg from 'erpcomponents/Svg';
import Tooltip from 'erpcomponents/Tooltip';
import EventDelete from 'erpcore/screens/Events/components/EventDelete';
import { actions as listingActions } from 'erpcomponents/Listing/Listing.reducer';
import { getListingFetching, getListingResponse } from 'erpcomponents/Listing/Listing.selectors';
import { stringOperations } from 'erpdata/filterOperations';
import EventTableInfo from 'erpcore/screens/Events/components/EventTableInfo';
import StatusBadge from 'erpcomponents/StatusBadge';

import './EventsListing.scss';

const reducerName = 'events';

class EventsListing extends Component {
    tableData() {
        const { eventsData } = this.props;
        const table = {};
        table.data = [];

        // Advanced Filters
        table.filters = [
            {
                name: 'name',
                label: (
                    <FormattedMessage
                        id="Events.Table.EventName.Label"
                        defaultMessage="Event Name"
                    />
                ),
                filterFields: {
                    operator: {
                        component: 'select',
                        fieldProps: {
                            clearable: false,
                            label: (
                                <FormattedMessage
                                    id="Events.Table.EventName.Operator"
                                    defaultMessage="Operator"
                                />
                            ),
                            options: stringOperations
                        },
                        fieldValidation: [{ validator: 'required' }]
                    },
                    value: {
                        component: 'text',
                        fieldProps: {
                            label: (
                                <FormattedMessage
                                    id="Events.Table.EventInfo"
                                    defaultMessage="Event Info"
                                />
                            )
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            },
            {
                name: 'status',
                label: <FormattedMessage id="Events.Table.Status.Label" defaultMessage="Status" />,
                defaultOperator: 'equals',
                filterFields: {
                    value: {
                        component: 'select',
                        fieldProps: {
                            label: (
                                <FormattedMessage
                                    id="Events.Table.Status"
                                    defaultMessage="Status"
                                />
                            ),
                            options: [
                                { label: 'Offline', value: 'Offline' },
                                { label: 'Live', value: 'Live' },
                                { label: 'Notify Me', value: 'Notify Me' }
                            ]
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            },
            {
                name: 'end_utc',
                label: (
                    <FormattedMessage id="Events.Table.EndTime.Label" defaultMessage="Event Time" />
                ),
                defaultOperator: 'upcoming',
                filterFields: {
                    value: {
                        component: 'select',
                        fieldProps: {
                            label: (
                                <FormattedMessage
                                    id="Events.Table.EndTime"
                                    defaultMessage="Event Time"
                                />
                            ),
                            options: [
                                { label: 'Upcoming', value: 'true' },
                                { label: 'Past', value: 'false' }
                            ]
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            },
            {
                name: 'end',
                label: <FormattedMessage id="Events.Table.Date.Label" defaultMessage="Date" />,
                filterFields: {
                    operator: {
                        component: 'select',
                        fieldProps: {
                            label: (
                                <FormattedMessage
                                    id="Events.Table.Date.Operator"
                                    defaultMessage="Operator"
                                />
                            ),
                            options: [
                                { label: 'Before', value: 'smaller_than' },
                                { label: 'After', value: 'larger_than' },
                                { label: 'Equals', value: 'equals' }
                            ]
                        }
                    },
                    value: {
                        component: 'dateTime',
                        fieldProps: {
                            label: <FormattedMessage id="Events.Table.Date" defaultMessage="Date" />
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            },
            {
                name: 'event_series',
                label: (
                    <FormattedMessage
                        id="Events.Table.EventSeries.Label"
                        defaultMessage="Event Series"
                    />
                ),
                defaultOperator: 'equals',
                filterFields: {
                    value: {
                        component: 'autocomplete',
                        fieldProps: {
                            label: (
                                <FormattedMessage
                                    id="Events.Table.EventSeries"
                                    defaultMessage="Event Series"
                                />
                            ),
                            options: {
                                endpoint: '/api/event-series',
                                mapData: {
                                    label: 'name',
                                    value: 'iri'
                                }
                            }
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            }
        ];
        // Table Schema
        table.schema = [
            {
                title: <FormattedMessage id="Events.Table.EventInfo" defaultMessage="Event Info" />,
                field: 'eventInfo',
                sortable: 'name',
                mobile: 'title'
            },
            {
                title: <FormattedMessage id="Events.Table.Status" defaultMessage="Status" />,
                field: 'status',
                mobile: 'status'
            },
            {
                title: (
                    <FormattedMessage id="Events.Table.TicketsSold" defaultMessage="Tickets Sold" />
                ),
                field: 'ticketsSold',
                mobile: 'subtitle'
            },
            {
                title: (
                    <FormattedMessage id="Events.Table.Attendance" defaultMessage="Attendance" />
                ),
                field: 'attendance'
            },
            {
                title: <FormattedMessage id="Events.Table.Revenue" defaultMessage="Revenue" />,
                field: 'revenue'
            },
            {
                title: <FormattedMessage id="Events.Table.Actions" defaultMessage="Actions" />,
                field: 'actions'
            }
        ];

        eventsData.data.map(row => {
            const ticketChartLabels = ['Number of Tickets', 'Percentage of Tickets'];
            const ticketChartData = [
                row.tickets_sold ? row.tickets_sold : 0,
                row.max_tickets_to_sell ? row.max_tickets_to_sell : 0
            ];
            const attendanceChartLabels = ['Number of Attendees', 'Percentage of Attendees'];
            const attendanceChartData = [
                row.attendees ? row.attendees : 0,
                row.max_attendance ? row.max_attendance : 0
            ];
            const revenueChartLabels = ['Revenue', 'Percentage'];
            const revenue = row.revenue ? row.revenue.amount && row.revenue.amount / 100 : 0;
            const maxRevenue = row.max_revenue
                ? row.max_revenue.amount && row.max_revenue.amount / 100
                : 0;
            const revenueChartData = [revenue, maxRevenue];
            const badgeActive = {
                type: 'fulfilled',
                text: 'Active'
            };
            const badgeOffline = {
                type: 'voided',
                text: 'Offline'
            };
            const badge = row.status === 'Live' ? badgeActive : badgeOffline;

            return table.data.push({
                id: row.id,
                eventInfo: <EventTableInfo name={row.name} start={row.start} end={row.end} />,
                status: <StatusBadge type={badge.type} text={badge.text} />,
                ticketsSold: (
                    <Chart
                        data={ticketChartData}
                        labels={ticketChartLabels}
                        color="primary"
                        showPercentage
                        tooltipTitle="Tickets Sold"
                    />
                ),
                attendance: (
                    <Chart
                        data={attendanceChartData}
                        labels={attendanceChartLabels}
                        color="secondary"
                        tooltipTitle="Attendance"
                    />
                ),
                revenue: (
                    <Chart
                        data={revenueChartData}
                        labels={revenueChartLabels}
                        color="tertiary"
                        isCurrency
                        tooltipTitle="Revenue"
                    />
                ),
                actions: (
                    <ListingTableActions>
                        <ListingTableActions.Action>
                            <Tooltip
                                content={
                                    <FormattedMessage
                                        id="Events.Table.Actions.Edit"
                                        defaultMessage="Edit Event"
                                    />
                                }
                            >
                                <NavLink to={`/events/${row.id}/edit/event-details`}>
                                    <Svg icon="edit" />
                                </NavLink>
                            </Tooltip>
                        </ListingTableActions.Action>
                        <ListingTableActions.Action>
                            <EventDelete
                                id={row.id}
                                key={`EventDelete_${row.id}`}
                                form="EventDeleteForm"
                            />
                        </ListingTableActions.Action>
                    </ListingTableActions>
                )
            });
        });

        return table;
    }

    render() {
        const { eventsListingFetching, eventsData, fetchEvents } = this.props;
        const tableData = this.tableData();

        return (
            <LayoutManager slot="main" layoutType="merge">
                <PageHeader title={<FormattedMessage id="Events.title" defaultMessage="Events" />}>
                    <Button
                        href="/events/create"
                        label={
                            <FormattedMessage
                                id="Events.CreateEvent"
                                defaultMessage="Create Event"
                            />
                        }
                    />
                </PageHeader>
                <Listing
                    name={reducerName}
                    reducerName={reducerName}
                    loading={eventsListingFetching}
                    meta={eventsData.meta}
                    table={tableData}
                    onListingConfigUpdate={params => fetchEvents(params)}
                />
            </LayoutManager>
        );
    }
}

EventsListing.defaultProps = {
    eventsListingFetching: false,
    eventsData: {}
};

EventsListing.propTypes = {
    fetchEvents: PropTypes.func.isRequired,
    eventsListingFetching: PropTypes.bool,
    eventsData: PropTypes.oneOfType([PropTypes.object])
};

//  Redux
const mapStateToProps = state => ({
    eventsListingFetching: getListingFetching(state, 'events'),
    eventsData: getListingResponse(state, 'events')
});

const mapDispatchToProps = dispatch => ({
    fetchEvents: params => {
        // Getting included data from API by setting defaultParams
        const defaultParams = {
            include: 'location'
        };
        const listingParams = Object.assign({}, defaultParams, params);

        dispatch({
            type: listingActions.START_FETCHING_LISTING,
            params: listingParams,
            name: reducerName,
            entity: 'EVENTS',
            endpoint: 'api/events'
        });
    }
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(EventsListing)
);
