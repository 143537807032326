import { createSelector } from 'reselect';

export const getSingleHomepageSliderFetching = state => state.homepageSlider.singleFetching;

export const getSingleHomepageSliderCreating = state => state.homepageSlider.singleCreating;

export const getSingleHomepageSliderDeleting = state => state.homepageSlider.singleDeleting;

export const getSingleHomepageSliderUpdating = state => state.homepageSlider.singleUpdating;

export default createSelector([
    getSingleHomepageSliderFetching,
    getSingleHomepageSliderCreating,
    getSingleHomepageSliderDeleting,
    getSingleHomepageSliderUpdating
]);
