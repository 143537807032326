import React from 'react';
import PropTypes from 'prop-types';
import Svg from 'erpcomponents/Svg';
import './Hero.scss';

const Hero = ({ className }) => {
    return (
        <div className={`hero ${className || ''}`}>
            <div className="hero__content">
                <Svg icon="balldrop" className="hero__logo" />
            </div>
            <footer className="hero__footer">
                <Svg icon="easPowered" className="hero__logo" />
            </footer>
        </div>
    );
};

Hero.defaultProps = {
    className: ''
};

Hero.propTypes = {
    className: PropTypes.string
};

export default Hero;
