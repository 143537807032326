import React, { Component } from 'react';
import LayoutManager from 'erputils/LayoutManager';
import PageHeader from 'erpcomponents/Layout/PageHeader';
import PageContent from 'erpcomponents/Layout/PageContent';
import { withRouter } from 'react-router-dom';
import SettingsTabs from 'erpcore/screens/Settings/components/SettingsTabs';
import PropTypes from 'prop-types';
import Listing from 'erpcomponents/Listing';
import { FormattedMessage } from 'react-intl';
import ListingTableActions from 'erpcomponents/Listing/components/TableActions';
import { connect } from 'react-redux';
import { getListingFetching, getListingResponse } from 'erpcomponents/Listing/Listing.selectors';
import { actions as listingActions } from 'erpcomponents/Listing/Listing.reducer';
import EventSeriesCreate from 'erpcore/screens/Settings/components/EventSeriesCreate';
import EventSeriesDelete from 'erpcore/screens/Settings/components/EventSeriesDelete';
import EventSeriesEdit from 'erpcore/screens/Settings/components/EventSeriesEdit';

const reducerName = 'eventSeries';

class EventSeries extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventSeriesId: null
        };
        this.toggleEdit = this.toggleEdit.bind(this);
        this.resetEventSeriesId = this.resetEventSeriesId.bind(this);
    }

    toggleEdit(id) {
        this.setState({
            eventSeriesId: id
        });
    }

    resetEventSeriesId() {
        this.setState({ eventSeriesId: null });
    }

    tableData() {
        const { seriesData } = this.props;
        const table = {};
        table.data = [];

        // Table Schema
        table.schema = [
            {
                title: (
                    <FormattedMessage
                        id="Settings.EventSeries.Table"
                        defaultMessage="Event Series"
                    />
                ),
                field: 'eventName',
                sortable: 'name'
            },
            {
                title: (
                    <FormattedMessage
                        id="Settings.EventSeries.Table.Actions"
                        defaultMessage="Actions"
                    />
                ),
                field: 'actions'
            }
        ];

        seriesData.data.map(row => {
            return table.data.push({
                id: row.id,
                eventName: row.name,
                actions: (
                    <ListingTableActions>
                        <ListingTableActions.Action>
                            <EventSeriesEdit
                                id={row.id}
                                key={`EventSeries_${row.id}`}
                                form="EventSeriesForm"
                            />
                        </ListingTableActions.Action>
                        <ListingTableActions.Action>
                            <EventSeriesDelete
                                id={row.id}
                                key={`EventDelete_${row.id}`}
                                form="EventDeleteForm"
                            />
                        </ListingTableActions.Action>
                    </ListingTableActions>
                )
            });
        });

        return table;
    }

    render() {
        const { fetchSeries, seriesListingFetching, seriesData } = this.props;
        const { eventSeriesId } = this.state;
        return (
            <LayoutManager slot="main" className="main--narrow" layoutType="merge">
                <PageHeader title="Settings - Categories" />
                <SettingsTabs />
                <PageContent>
                    {!eventSeriesId ? (
                        <EventSeriesCreate />
                    ) : (
                        <EventSeriesEdit resetId={this.resetEventSeriesId} id={eventSeriesId} />
                    )}
                    <Listing
                        name={reducerName}
                        reducerName={reducerName}
                        loading={seriesListingFetching}
                        hideSearch
                        meta={seriesData.meta}
                        table={this.tableData()}
                        onListingConfigUpdate={params => fetchSeries(params)}
                    />
                </PageContent>
            </LayoutManager>
        );
    }
}

EventSeries.defaultProps = {
    seriesData: {},
    seriesListingFetching: false
};

EventSeries.propTypes = {
    seriesData: PropTypes.oneOfType([PropTypes.object]),
    fetchSeries: PropTypes.func.isRequired,
    seriesListingFetching: PropTypes.bool
};

const mapStateToProps = state => ({
    seriesListingFetching: getListingFetching(state, 'eventSeries'),
    seriesData: getListingResponse(state, 'eventSeries')
});

const mapDispatchToProps = dispatch => ({
    fetchSeries: params => {
        // Getting included data from API by setting defaultParams
        const listingParams = Object.assign({ pagination: false }, params);

        dispatch({
            type: listingActions.START_FETCHING_LISTING,
            params: listingParams,
            name: reducerName,
            entity: 'EVENT_SERIES',
            endpoint: 'api/event-series'
        });
    }
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(EventSeries)
);
