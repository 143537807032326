import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import Form, { Text, Phone, Email, Creatable } from 'erpcore/components/Form';
import valueValidation from 'erputils/valueValidation';
import { dtoForm } from 'erputils/dto';
import { diff } from 'deep-object-diff';
import Modal from 'erpcomponents/Modal';
import Button from 'erpcomponents/Button';
import { actions as customersActions } from 'erpcore/screens/Customers/Customers.reducer';
import {
    getCustomersData,
    getSingleCustomerFetching
} from 'erpcore/screens/Customers/Customers.selectors';
import PageLoader from 'erpcomponents/PageLoader';

class CustomerEditModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            opened: true
        };

        this.handleModal = this.handleModal.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    // componentDidUpdate(prevProps, prevState) {
    //     const { fetchGroupData } = this.props;
    //     const { opened } = this.state;
    //     if (prevState.opened !== opened) {
    //         fetchGroupData();
    //     }
    // }

    componentDidMount() {
        const { fetchCustomerData } = this.props;
        fetchCustomerData();
    }

    onSubmit(formData) {
        const { id, dispatch, initialValues, refreshData } = this.props;
        // send only changed data
        formData = diff(initialValues, formData);

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: customersActions.START_UPDATE_SINGLE_CUSTOMER,
                id,
                formData
            })
        )
            .then(() => {
                this.handleModal();
                refreshData();
            })
            .catch(error => {
                return error;
            });
    }

    handleModal() {
        const { opened: isOpen } = this.state;
        this.setState({ opened: !isOpen });
    }

    render() {
        const { handleSubmit, submitting, pristine, invalid, singleFetching } = this.props;
        const { opened } = this.state;
        return (
            <Modal title="Edit Customer" opened={opened} onClose={() => this.handleModal()}>
                {singleFetching === true && <PageLoader />}

                <Form onSubmit={handleSubmit(this.onSubmit)}>
                    <Form.Row>
                        <Field
                            name="first_name"
                            id="first_name"
                            fieldProps={{
                                label: (
                                    <FormattedMessage
                                        id="CustomerEdit.FirstName"
                                        defaultMessage="First Name"
                                    />
                                ),
                                clearable: true
                            }}
                            component={Text}
                            validate={valueValidation([{ validator: 'required' }])}
                            fieldAttr={{ required: true }}
                        />
                    </Form.Row>
                    <Form.Row>
                        <Field
                            name="last_name"
                            id="last_name"
                            fieldProps={{
                                label: (
                                    <FormattedMessage
                                        id="CustomerEdit.LastName"
                                        defaultMessage="Last Name"
                                    />
                                ),
                                clearable: true
                            }}
                            component={Text}
                            validate={valueValidation([{ validator: 'required' }])}
                            fieldAttr={{ required: true }}
                        />
                    </Form.Row>
                    <Form.Row>
                        <Field
                            name="email"
                            id="email"
                            fieldProps={{
                                label: (
                                    <FormattedMessage
                                        id="CustomerEdit.Email"
                                        defaultMessage="Email"
                                    />
                                ),
                                clearable: true
                            }}
                            component={Email}
                            validate={valueValidation([{ validator: 'required' }])}
                            fieldAttr={{ required: true }}
                        />
                    </Form.Row>
                    <Form.Row>
                        <Field
                            name="phone"
                            id="phone"
                            fieldProps={{
                                label: (
                                    <FormattedMessage
                                        id="CustomerEdit.Phone"
                                        defaultMessage="Phone"
                                    />
                                )
                            }}
                            component={Phone}
                            validate={valueValidation([{ validator: 'required' }])}
                            fieldAttr={{ required: true }}
                        />
                    </Form.Row>
                    <Form.Columns>
                        <Form.Column>
                            <Form.Row>
                                <Field
                                    name="country"
                                    id="country"
                                    fieldProps={{
                                        label: (
                                            <FormattedMessage
                                                id="CustomerEdit.Country"
                                                defaultMessage="Country"
                                            />
                                        ),
                                        options: {
                                            endpoint: '/api/countries',
                                            mapData: {
                                                value: 'iri',
                                                label: 'name'
                                            }
                                        },
                                        clearable: true
                                    }}
                                    component={Creatable}
                                    validate={valueValidation([{ validator: 'required' }])}
                                    fieldAttr={{ required: true }}
                                />
                            </Form.Row>
                        </Form.Column>
                        <Form.Column>
                            <Form.Row>
                                <Field
                                    name="state"
                                    id="state"
                                    fieldProps={{
                                        label: (
                                            <FormattedMessage
                                                id="CustomerEdit.State"
                                                defaultMessage="State"
                                            />
                                        ),
                                        options: {
                                            endpoint: '/api/states',
                                            mapData: {
                                                value: 'iri',
                                                label: 'name'
                                            }
                                        },
                                        clearable: true
                                    }}
                                    component={Creatable}
                                    validate={valueValidation([{ validator: 'required' }])}
                                    fieldAttr={{ required: true }}
                                />
                            </Form.Row>
                        </Form.Column>
                    </Form.Columns>
                    <Form.Columns>
                        <Form.Column>
                            <Form.Row>
                                <Field
                                    name="city"
                                    id="city"
                                    fieldProps={{
                                        label: (
                                            <FormattedMessage
                                                id="CustomerEdit.City"
                                                defaultMessage="City"
                                            />
                                        ),
                                        options: {
                                            endpoint: '/api/cities',
                                            mapData: {
                                                value: 'iri',
                                                label: 'name'
                                            }
                                        },
                                        clearable: true
                                    }}
                                    component={Creatable}
                                    validate={valueValidation([{ validator: 'required' }])}
                                    fieldAttr={{ required: true }}
                                />
                            </Form.Row>
                        </Form.Column>
                        <Form.Column>
                            <Form.Row>
                                <Field
                                    name="zip"
                                    id="zip"
                                    fieldProps={{
                                        label: (
                                            <FormattedMessage
                                                id="CustomerEdit.Zip"
                                                defaultMessage="Zip"
                                            />
                                        ),
                                        clearable: true
                                    }}
                                    component={Text}
                                    validate={valueValidation([{ validator: 'required' }])}
                                    fieldAttr={{ required: true }}
                                />
                            </Form.Row>
                        </Form.Column>
                    </Form.Columns>
                    <Form.Row>
                        <Field
                            name="address"
                            id="address"
                            fieldProps={{
                                clearable: true,
                                icon: 'location',
                                label: (
                                    <FormattedMessage
                                        id="CustomerEdit.Address"
                                        defaultMessage="Street Line 1"
                                    />
                                )
                            }}
                            component={Text}
                            validate={valueValidation([{ validator: 'required' }])}
                            fieldAttr={{ required: true }}
                        />
                    </Form.Row>
                    <Form.Row>
                        <Field
                            name="address2"
                            id="address2"
                            fieldProps={{
                                clearable: true,
                                icon: 'location',
                                label: (
                                    <FormattedMessage
                                        id="CustomerEdit.Address2"
                                        defaultMessage="Street Line 2"
                                    />
                                )
                            }}
                            component={Text}
                        />
                    </Form.Row>
                    <Form.Row>
                        <Button
                            submit
                            disabled={pristine || invalid}
                            loading={submitting}
                            label={
                                <FormattedMessage
                                    id="CustomerEdit.Update"
                                    defaultMessage="Update"
                                />
                            }
                        />
                    </Form.Row>
                </Form>
            </Modal>
        );
    }
}

CustomerEditModal.defaultProps = {
    submitting: false,
    pristine: false,
    invalid: false,
    fetchCustomerData: () => {},
    refreshData: () => {},
    singleFetching: false,
    initialValues: {},
    id: ''
};

CustomerEditModal.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    initialValues: PropTypes.oneOfType([PropTypes.object]),
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    fetchCustomerData: PropTypes.func,
    refreshData: PropTypes.func,
    singleFetching: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

CustomerEditModal = reduxForm({
    enableReinitialize: false
})(CustomerEditModal);

const mapStateToProps = (state, ownProps) => ({
    initialValues: dtoForm(getCustomersData(state, ownProps.id)),
    singleFetching: getSingleCustomerFetching(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchCustomerData: () => {
        // Getting included data from API by setting params
        const params = {
            include: 'city,country,state'
        };
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: customersActions.START_FETCHING_SINGLE_CUSTOMER,
                id: ownProps.id,
                params
            });
        }).catch(error => ({ error }));
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomerEditModal);
