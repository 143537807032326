import React, { Fragment } from 'react';
import { Field, formValueSelector } from 'redux-form';
import Form, { Text, ImageUpload, SwitchCard } from 'erpcore/components/Form';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import valueValidation from 'erputils/valueValidation';
import { actions as homepageSliderActions } from 'erpcore/screens/Settings/screens/ResellerAdmin/HomepageSlider/HomepageSlider.reducer';

class HomepageSliderRepeaterItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pendingUpdates: {}
        };

        this.dispatchUpdate = debounce(this.dispatchUpdate, 2000);
    }

    dispatchUpdate = () => {
        const { formValues, dispatch } = this.props;

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: homepageSliderActions.REQUEST_UPDATE_SINGLE_HOMEPAGE_SLIDER,
                formData: {
                    slides: {
                        ...formValues
                    }
                }
            })
        )
            .catch(() => {
                this.removePendingUpdates();
            })
            .then(() => {
                this.removePendingUpdates();
            });
    };

    handleChange = (event, newValue, previousValue, name) => {
        const { member, isNewRepeaterItem } = this.props;

        if (!isNewRepeaterItem) {
            const nameForApi = name.replace(`${member}.`, '');
            const updates = { [nameForApi]: newValue };
            const { pendingUpdates } = this.state;
            const mergedUpdates = { ...pendingUpdates, ...updates };
            this.setState({ pendingUpdates: mergedUpdates });
            this.dispatchUpdate(mergedUpdates, name);
        }
    };

    removePendingUpdates() {
        this.setState({ pendingUpdates: {} });
    }

    isValid(validatorFunctions = [], value) {
        const errors = validatorFunctions.map(validatorFunction => {
            return validatorFunction(value);
        });
        const isNotValid = errors.find(item => item !== undefined);

        return !isNotValid;
    }

    render() {
        const { member, data } = this.props;

        return (
            <Fragment>
                <Form.Row>
                    <Form.Columns breakOnMobile>
                        <Form.Column>
                            <Field
                                name={`${member}.title`}
                                id="title"
                                fieldProps={{
                                    label: 'Title'
                                }}
                                component={Text}
                                validate={valueValidation([{ validator: 'required' }])}
                                onChange={(event, newValue, previousValue, name) => {
                                    /*
                                     * redux-form validation is started after the Field onChange is done,
                                     * so the validity of the field has to be checked manually
                                     * before handling the change data further
                                     */
                                    if (
                                        this.isValid(
                                            valueValidation([{ validator: 'required' }]),
                                            newValue
                                        )
                                    ) {
                                        this.handleChange(event, newValue, previousValue, name);
                                    }
                                }}
                            />
                        </Form.Column>
                        <Form.Column>
                            <Field
                                name={`${member}.subtitle`}
                                id="subtitle"
                                fieldProps={{
                                    label: 'Subtitle'
                                }}
                                component={Text}
                                validate={valueValidation([{ validator: 'required' }])}
                                onChange={(event, newValue, previousValue, name) => {
                                    /*
                                     * redux-form validation is started after the Field onChange is done,
                                     * so the validity of the field has to be checked manually
                                     * before handling the change data further
                                     */
                                    if (
                                        this.isValid(
                                            valueValidation([{ validator: 'required' }]),
                                            newValue
                                        )
                                    ) {
                                        this.handleChange(event, newValue, previousValue, name);
                                    }
                                }}
                            />
                        </Form.Column>
                    </Form.Columns>

                    <Form.Columns breakOnMobile>
                        <Form.Column>
                            <Field
                                name={`${member}.cta_label`}
                                id="cta_label"
                                fieldProps={{
                                    label: 'CTA Label'
                                }}
                                component={Text}
                                validate={valueValidation([{ validator: 'required' }])}
                                onChange={(event, newValue, previousValue, name) => {
                                    /*
                                     * redux-form validation is started after the Field onChange is done,
                                     * so the validity of the field has to be checked manually
                                     * before handling the change data further
                                     */
                                    if (
                                        this.isValid(
                                            valueValidation([{ validator: 'required' }]),
                                            newValue
                                        )
                                    ) {
                                        this.handleChange(event, newValue, previousValue, name);
                                    }
                                }}
                            />
                        </Form.Column>
                        <Form.Column>
                            <Field
                                name={`${member}.cta_link`}
                                id="cta_link"
                                fieldProps={{
                                    label: 'CTA Link'
                                }}
                                component={Text}
                                validate={valueValidation([{ validator: 'required' }])}
                                onChange={(event, newValue, previousValue, name) => {
                                    /*
                                     * redux-form validation is started after the Field onChange is done,
                                     * so the validity of the field has to be checked manually
                                     * before handling the change data further
                                     */
                                    if (
                                        this.isValid(
                                            valueValidation([{ validator: 'required' }]),
                                            newValue
                                        )
                                    ) {
                                        this.handleChange(event, newValue, previousValue, name);
                                    }
                                }}
                            />
                        </Form.Column>
                    </Form.Columns>
                    <Form.Columns breakOnMobile>
                        <Form.Column>
                            <Field
                                name={`${member}.image`}
                                id="image"
                                fieldProps={{
                                    label: 'Picture',
                                    limit: 1
                                }}
                                meta={{
                                    initial: data.image || []
                                }}
                                component={ImageUpload}
                                validate={valueValidation([{ validator: 'required' }])}
                                onChange={(event, newValue, previousValue, name) => {
                                    /*
                                     * redux-form validation is started after the Field onChange is done,
                                     * so the validity of the field has to be checked manually
                                     * before handling the change data further
                                     */
                                    if (
                                        this.isValid(
                                            valueValidation([{ validator: 'required' }]),
                                            newValue
                                        )
                                    ) {
                                        this.handleChange(event, newValue, previousValue, name);
                                    }
                                }}
                            />
                        </Form.Column>
                    </Form.Columns>
                </Form.Row>
                <Form.Row>
                    <Field
                        name={`${member}.counter`}
                        id="counter"
                        fieldProps={{
                            label: 'Display counter',
                            onValue: true,
                            offValue: false
                        }}
                        fieldAttr={{
                            input: {
                                value: data.counter
                            }
                        }}
                        onChange={(event, newValue, previousValue, name) => {
                            this.handleChange(event, newValue, previousValue, name);
                        }}
                        component={SwitchCard}
                    />
                </Form.Row>
            </Fragment>
        );
    }
}

HomepageSliderRepeaterItem.defaultProps = {
    member: null,
    data: {},
    isNewRepeaterItem: false,
    formValues: []
};

HomepageSliderRepeaterItem.propTypes = {
    dispatch: PropTypes.func.isRequired,
    member: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    data: PropTypes.oneOfType([PropTypes.object]),
    isNewRepeaterItem: PropTypes.bool,
    formValues: PropTypes.oneOfType([PropTypes.array])
};

const mapDispatchToProps = state => {
    const getFormValues = formValueSelector('HomepageSliderForm');
    return {
        formValues: getFormValues(state, 'value')
    };
};

export default connect(mapDispatchToProps)(HomepageSliderRepeaterItem);
