import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import LayoutManager from 'erputils/LayoutManager';
import Button from 'erpcomponents/Button';
import Tooltip from 'erpcomponents/Tooltip';

import { getMeData } from 'erputils/AuthManager/AuthManager.selectors';
import Svg from 'erpcomponents/Svg';
import './Sidebar.scss';

class Sidebar extends Component {
    constructor(props) {
        super(props);

        this.props = props;
        this.state = { sidebar: window.innerWidth > 1030, profile: false };
    }

    /**
     *
     */
    closeSidebar() {
        this.setState({ sidebar: false, profile: false });
    }

    /**
     *
     */
    handleSidebarMenu() {
        const { sidebar } = this.state;
        this.setState({ sidebar: !sidebar, profile: false });
    }

    /**
     *
     */
    handleSidebarProfile() {
        const { profile } = this.state;
        this.setState({ profile: !profile, sidebar: false });
    }

    /**
     *
     */
    handleProfilePhoto() {
        const { user } = this.props;
        let imageURL = null;

        if (user && user.image && user.image.content_url) {
            imageURL = user.image.content_url;
        }

        if (imageURL) {
            return <img src={imageURL} alt="Profile" width="32" height="32" />;
        }

        return <Svg icon="profile" />;
    }

    render() {
        const { className, layout, user } = this.props;
        const { sidebar, profile } = this.state;

        return (
            <aside
                className={`sidebar ${sidebar ? 'sidebar--menu-opened' : ''} ${
                    profile ? 'sidebar--profile-opened' : ''
                } ${className || ''}`}
            >
                <button
                    type="button"
                    className="sidebar__mobile-overlay"
                    onClick={() => this.closeSidebar()}
                    onKeyPress={() => this.closeSidebar()}
                />
                <div className="sidebar__content">
                    <div className="sidebar__bolt">
                        <ul className="sidebar__bolt-top">
                            <li className="sidebar__bolt-top-hamburger">
                                <button
                                    type="button"
                                    className="sidebar__button sidebar__button--hamburger"
                                    onClick={() => this.handleSidebarMenu()}
                                    onKeyPress={() => this.handleSidebarMenu()}
                                >
                                    <Svg icon="menu" />
                                </button>
                            </li>
                            <li className="sidebar__bolt-top-logo">
                                <Link to="/" className="sidebar__logo">
                                    <Svg icon="ball" />
                                </Link>
                            </li>
                            <li className="sidebar__bolt-top-profile">
                                <button
                                    type="button"
                                    className="sidebar__profile"
                                    onClick={() => this.handleSidebarProfile()}
                                    onKeyPress={() => this.handleSidebarProfile()}
                                >
                                    <Svg icon="profile" />
                                </button>
                            </li>
                        </ul>
                        <ul className="sidebar__bolt-bottom">
                            <li className="sidebar__bolt-bottom-item">
                                <Tooltip content="Sign Out" direction="right">
                                    <Button
                                        label=""
                                        iconName="signOut"
                                        href="/sign-out"
                                        className="sidebar__button"
                                    />
                                </Tooltip>
                            </li>
                            <li className="sidebar__bolt-bottom-item">
                                <Tooltip
                                    content={
                                        user && user.first_name && user.last_name
                                            ? `${user.first_name} ${user.last_name}`
                                            : ''
                                    }
                                    direction="right"
                                >
                                    <NavLink to="/my-account" className="sidebar__profile">
                                        {this.handleProfilePhoto()}
                                    </NavLink>
                                </Tooltip>
                            </li>
                        </ul>
                    </div>
                    <div className="sidebar__deck">
                        <LayoutManager layout={layout} />
                    </div>
                    <div className="sidebar__mobile-profile">
                        <ul className="sidebar__profile-list">
                            <li className="sidebar__profile-list-item">
                                <NavLink to="/my-account">My Account</NavLink>
                            </li>
                            <li className="sidebar__profile-list-item">
                                <NavLink to="/sign-out">Sign Out</NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </aside>
        );
    }
}

Sidebar.defaultProps = {
    layout: null,
    className: '',
    user: null
};

Sidebar.propTypes = {
    layout: PropTypes.oneOfType([PropTypes.object]),
    className: PropTypes.string,
    user: PropTypes.oneOfType([PropTypes.object])
};

const mapStateToProps = state => ({
    user: getMeData(state)
});

export default connect(mapStateToProps)(Sidebar);
