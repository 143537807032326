import { put, take, takeLatest, fork, call } from 'redux-saga/effects';
import { channel } from 'redux-saga';
import restClient from 'erpcore/api/restClient';
import dto from 'erpcore/utils/dto';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import { parseParamsForApi } from 'erpcore/components/Listing/Listing.utils';

/**
 * Fetch Listing Saga
 * @param  {Object} promise Resolve and reject promise
 * @param  {Object} params Set query params for listing request
 * @param  {String} entity Name of the listing entity
 * @param  {String} endpoint Endpoint to wich saga points
 * @return {Object} Response from API
 */
export function* fetchListing({ promise, params, entity = 'LISTING', endpoint }) {
    try {
        yield put({
            type: `${listingActions.START_FETCHING}_${entity}`
        });
        const fetchListingAPI = yield restClient.get(endpoint, {
            params: parseParamsForApi(params)
        });
        yield put({
            type: `${listingActions.FETCHING_SUCCESSFUL}_${entity}`,
            response: dto(fetchListingAPI?.data)
        });
        if (promise) {
            yield call(promise.resolve);
        }
    } catch (error) {
        yield put({
            type: `${listingActions.FETCHING_FAILED}_${entity}`,
            response: error?.response?.data || error
        });
        if (promise) {
            yield call(promise.reject, error?.response?.data || error);
        }
    }
}

function takeLatestPerProps(propsOrSelector, pattern, worker, ...args) {
    // Not a generator
    return fork(function* generator() {
        // Fork a generator here to make it work like takeLatest
        const channelsMap = {};
        while (true) {
            const action = yield take(pattern); // yield necessary here
            const propsValue =
                typeof propsOrSelector === 'function'
                    ? propsOrSelector(action)
                    : action[propsOrSelector];
            if (!channelsMap[propsValue]) {
                channelsMap[propsValue] = channel();
                yield takeLatest(channelsMap[propsValue], worker, ...args);
            }
            yield put(channelsMap[propsValue], action);
        }
    });
}

/**
 * Register action to watcher
 */
export const listingSaga = [
    takeLatestPerProps('entity', listingActions.START_FETCHING_LISTING, fetchListing)
];
