import React from 'react';
import { FieldArray, reduxForm } from 'redux-form';
import Form, { Gallery } from 'erpcore/components/Form';
import PropTypes from 'prop-types';

class TableFloorPlans extends React.Component {
    constructor(props) {
        super(props);

        this.test = false;
        this.galleryRef = React.createRef();
    }

    getGalleryInstance() {
        if (this.galleryRef.current !== undefined && this.galleryRef.current) {
            return this.galleryRef.current.getRenderedComponent();
        }

        return null;
    }

    render() {
        const { eventId } = this.props;
        return (
            <Form.Row>
                <h4>Floor Plans</h4>
                <FieldArray
                    name="floorPlans"
                    id="event-floor-plans"
                    component={Gallery}
                    useImageManager={false}
                    useLightbox
                    featuredKey={false}
                    versionName="entityVersion"
                    attachedToEntity={{
                        type: 'event',
                        iri: `/api/events/${eventId}`,
                        id: eventId
                    }}
                    getImagesApi={`/api/events/${eventId}/images`}
                    galleryApi="/api/event-images"
                    groupName="floorPlans"
                    ref={this.galleryRef}
                    forwardRef
                />
            </Form.Row>
        );
    }
}

TableFloorPlans.defaultProps = {
    eventId: null
};

TableFloorPlans.propTypes = {
    eventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

TableFloorPlans = reduxForm({
    form: 'EventTableFloorPlan',
    enableReinitialize: true
    // keepDirtyOnReinitialize: false, // important
    // updateUnregisteredFields: false // important
})(TableFloorPlans);

export default TableFloorPlans;
