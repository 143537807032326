import { createSelector } from 'reselect';

export const getTicketzoneID = state => state.dashboards.ticketzoneEventID;

export const getTicketTypeData = (state, iri) => {
    if (state.dashboards && state.dashboards[iri]) {
        return state.dashboards[iri];
    }

    return {};
};

export default createSelector([getTicketzoneID, getTicketTypeData]);
