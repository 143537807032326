import React, { Component } from 'react';
import './CustomerTableInfo.scss';
import PropTypes from 'prop-types';

class ResellerTableInfo extends Component {
    state = {};

    render() {
        const { name, phone, email } = this.props;
        return (
            <div className="resellers-table-reseller-info">
                <span className="resellers-table-reseller-info__name">{name}</span>
                <span className="resellers-table-reseller-info__phone">{email}</span>
                <span className="resellers-table-reseller-info__email">{phone}</span>
            </div>
        );
    }
}
ResellerTableInfo.defaultProps = {
    name: '',
    phone: '',
    email: ''
};

ResellerTableInfo.propTypes = {
    name: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string
};

export default ResellerTableInfo;
