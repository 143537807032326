import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { dtoForm } from 'erputils/dto';
import { FormattedMessage } from 'react-intl';
import LayoutManager from 'erputils/LayoutManager';
import PageHeader from 'erpcomponents/Layout/PageHeader';
import PageContent from 'erpcomponents/Layout/PageContent';
import LocationEditTabs from 'erpcore/screens/Venues/components/LocationEditTabs';
import PageLoader from 'erpcomponents/PageLoader';
import { actions as tableTemplatesActions } from 'erpcore/screens/Venues/screens/VenuesEdit/TableTemplates/TableTemplates.reducer';
import { actions as roomsActions } from 'erpcore/screens/Venues/screens/VenuesEdit/Rooms/Rooms.reducer';
import { getVenueRooms } from 'erpcore/screens/Venues/screens/VenuesEdit/Rooms/Rooms.selectors';
import {
    getTableTemplateData,
    getSingleTableTemplateFetching,
    getNewTables,
    getTableTemplateTables
} from 'erpcore/screens/Venues/screens/VenuesEdit/TableTemplates/TableTemplates.selectors';
import { getVenueName } from 'erpcore/screens/Venues/Venues.selectors';
import TemplateNameForm from 'erpcore/screens/Venues/screens/VenuesEdit/TableTemplates/components/TemplateNameForm';
import TablesForm from 'erpcore/screens/Venues/screens/VenuesEdit/TableTemplates/components/TablesForm';
import TableTemplateFloorPlans from 'erpcore/screens/Venues/screens/VenuesEdit/TableTemplates/components/TableTemplateFloorPlans';
import Notification from 'erpcomponents/Notification';
import Button from 'erpcomponents/Button';

class TableTemplateEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirectToCorrectVenueId: false
        };
    }

    componentDidMount() {
        const {
            fetchTableTemplateData,
            fetchVenueRooms,
            match,
            fetchTableTemplateTables
        } = this.props;
        fetchVenueRooms(match.params.id);
        fetchTableTemplateTables(match.params.tid);
        fetchTableTemplateData();
    }

    componentWillReceiveProps(nextProps) {
        const { initialValues = {} } = this.props;
        const { initialValues: nextInitialValues = {}, match } = nextProps;

        const urlSuppliedVenueId = match.params.id;
        const { id: currentTableTemplateId = false } = initialValues;
        const { id: nextTableTemplateId = false } = nextInitialValues;

        if (nextTableTemplateId && nextTableTemplateId !== currentTableTemplateId) {
            const { locations = [] } = nextInitialValues;
            if (locations.length) {
                const venueMatch = locations.findIndex(venue => venue.id === urlSuppliedVenueId);
                if (venueMatch === -1) {
                    const redirectToCorrectVenueId = locations[0].id;
                    this.setState({
                        redirectToCorrectVenueId: redirectToCorrectVenueId.toString()
                    });
                }
            }
        }
    }

    renderVenueMismatchNotification(redirectToCorrectVenueId) {
        const { initialValues, match } = this.props;

        const tableTemplateName =
            initialValues && initialValues.name !== undefined && initialValues.name
                ? initialValues.name
                : '';

        return (
            <Notification
                type="warning"
                text={
                    <FormattedMessage
                        id="TableTemplateEdit.VenueMismatch.Text"
                        defaultMessage="Table Template {tableTemplateName} is not connected to this Venue."
                        values={{ tableTemplateName: <strong>{tableTemplateName}</strong> }}
                    />
                }
            >
                <Button
                    external
                    externalTarget={false}
                    variation="secondary"
                    size="small"
                    href={`/venues/${redirectToCorrectVenueId}/edit/table-templates/${
                        match.params.tid
                    }/edit`}
                    label={
                        <FormattedMessage
                            id="TableTemplateEdit.VenueMismatch.GoToVenue"
                            defaultMessage="Go to correct Venue page"
                        />
                    }
                />
            </Notification>
        );
    }

    render() {
        const {
            venueName,
            singleFetching,
            initialValues,
            match,
            fetchTableTemplateData,
            venueRooms,
            fetchTableTemplateTables
        } = this.props;

        const { redirectToCorrectVenueId } = this.state;

        const venue = venueName && ` - ${venueName}`;
        return (
            <LayoutManager slot="main" className="main--narrow" layoutType="merge">
                <PageHeader
                    title={
                        <FormattedMessage
                            id="TableTemplateEdit.title"
                            defaultMessage="Edit Venue {venue}"
                            values={{ venue }}
                        />
                    }
                    backButtonLink={`/venues/${match.params.id}/edit/table-templates`}
                />
                {singleFetching === true && <PageLoader />}
                <LocationEditTabs venueId={match.params.id} />
                <PageContent>
                    {!redirectToCorrectVenueId && (
                        <React.Fragment>
                            <TemplateNameForm match={match} initialValues={initialValues} />
                            <br />
                            <TableTemplateFloorPlans
                                entityId={match.params.id}
                                tableTemplateId={match.params.tid}
                            />
                            <br />
                            <TablesForm
                                match={match}
                                fetchTableTemplateData={fetchTableTemplateData}
                                fetchTableTemplateTables={fetchTableTemplateTables}
                                venueRooms={venueRooms && venueRooms}
                            />
                        </React.Fragment>
                    )}
                    {!!redirectToCorrectVenueId &&
                        this.renderVenueMismatchNotification(redirectToCorrectVenueId)}
                </PageContent>
            </LayoutManager>
        );
    }
}

TableTemplateEdit.defaultProps = {
    // submitting: false,
    // pristine: false,
    // invalid: false,
    fetchTableTemplateData: () => {},
    fetchVenueRooms: () => {},
    fetchTableTemplateTables: () => {},
    singleFetching: false,
    initialValues: {},
    venueRooms: [],
    venueName: ''
};

TableTemplateEdit.propTypes = {
    match: PropTypes.oneOfType([PropTypes.object]).isRequired,
    initialValues: PropTypes.oneOfType([PropTypes.object]),
    venueRooms: PropTypes.oneOfType([PropTypes.array]),
    // submitting: PropTypes.bool,
    // pristine: PropTypes.bool,
    // invalid: PropTypes.bool,
    fetchTableTemplateData: PropTypes.func,
    fetchVenueRooms: PropTypes.func,
    fetchTableTemplateTables: PropTypes.func,
    singleFetching: PropTypes.bool,
    venueName: PropTypes.string
};

//  Getting initial value populated in the form from the store
const mapStateToProps = (state, ownProps) => ({
    initialValues: getTableTemplateData(state, ownProps.match.params.tid),
    singleFetching: getSingleTableTemplateFetching(state),
    data: dtoForm(getTableTemplateData(state, ownProps.match.params.tid)),
    newTables: getNewTables(state, 'tableTemplates'),
    venueName: getVenueName(state),
    venueRooms: getVenueRooms(state),
    tableTemplateTables: getTableTemplateTables(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchTableTemplateData: () => {
        // Getting included data from API by setting params
        const params = {
            include: 'tables,tables.room,locations,tables.canBeGroupedWith'
        };
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: tableTemplatesActions.START_FETCHING_SINGLE_TABLE_TEMPLATE,
                id: ownProps.match.params.tid,
                params
            });
        }).catch(error => ({ error }));
    },
    fetchVenueRooms: id => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: roomsActions.START_FETCHING_ROOMS_FOR_VENUE,
                promise: { resolve, reject },
                id
            });
        }).catch(error => ({
            error
        }));
    },
    fetchTableTemplateTables: templateId => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: tableTemplatesActions.START_FETCHING_TABLE_TEMPLATE_TABLES,
                promise: { resolve, reject },
                id: templateId
            });
        }).catch(error => ({
            error
        }));
    }
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(TableTemplateEdit)
);
