import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Tabs from 'erpcomponents/Tabs';

const EventManageTabs = ({ match }) => {
    const links = [
        {
            link: `/events/${match.params.id}/manage/event-settings`,
            label: (
                <FormattedMessage id="EventManage.Tabs.EventSettings" defaultMessage="Settings" />
            )
        },
        {
            link: `/events/${match.params.id}/manage/tables`,
            label: <FormattedMessage id="EventManage.Tabs.Tables" defaultMessage="Tables" />
        },
        {
            link: `/events/${match.params.id}/manage/production`,
            label: <FormattedMessage id="EventManage.Tabs.Production" defaultMessage="Production" />
        },
        {
            link: `/events/${match.params.id}/manage/activity-log`,
            label: (
                <FormattedMessage id="EventManage.Tabs.ActivityLog" defaultMessage="Activity Log" />
            )
        }
    ];

    return <Tabs links={links} />;
};

EventManageTabs.defaultProps = {
    match: {}
};

EventManageTabs.propTypes = {
    match: PropTypes.oneOfType([PropTypes.object])
};

export default withRouter(EventManageTabs);
