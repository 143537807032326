import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './MainNavigation.scss';

import Menu from 'erpcore/components/Layout/MainNavigation/components/Menu';

import { getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import { hasPermission, getPermissionsAccessForEntities } from 'erpcore/utils/RolesManager';

/**
 * Main Navigation
 * @param {Object} props
 * @return {Node} Returns Main Navigation
 */
const MainNavigation = () => {
    const menuId = 'main-navigation';
    const meData = useSelector(getMeData) || {};
    const hasPermissionsAccessForEntities = getPermissionsAccessForEntities(meData);

    /**
     * Effect used only on component mount
     */
    useEffect(() => {
        // Expand Submenu on Page Load
        const menu = document.getElementById(menuId);
        const activeItems = menu.querySelectorAll('.main-navigation__submenu-link--active');

        if (activeItems.length) {
            activeItems.forEach(activeItem => {
                activeItem
                    .closest('.main-navigation__menu-item')
                    .classList.add('main-navigation__menu-item--open');
            });
        }
    }, []);

    return (
        <Menu id={menuId}>
            {hasPermissionsAccessForEntities.dashboard &&
                (hasPermission('CAN_CUSTOM_MANAGE_OVERALL_SALES_DASHBOARD', meData) ||
                    hasPermission('CAN_CUSTOM_MANAGE_YEAR_OVER_YEAR', meData)) && (
                    <Menu.Submenu label="Dashboards">
                        {hasPermission('CAN_CUSTOM_MANAGE_OVERALL_SALES_DASHBOARD', meData) && (
                            <Menu.SubmenuItem to="/dashboard/overall-sales" label="OverallSales" />
                        )}
                        {hasPermission('CAN_CUSTOM_MANAGE_YEAR_OVER_YEAR', meData) && (
                            <Menu.SubmenuItem
                                to="/dashboard/year-over-year"
                                label="Year Over Year"
                            />
                        )}
                    </Menu.Submenu>
                )}
            {hasPermissionsAccessForEntities.users && (
                <Menu.Submenu label="Users">
                    <Menu.SubmenuItem to="/users" label="Users" />
                    <Menu.SubmenuItem to="/user-invites" label="User Invites" />
                </Menu.Submenu>
            )}
            {hasPermissionsAccessForEntities.orders && <Menu.Item to="/orders" label="Orders" />}
            {hasPermissionsAccessForEntities.customers && (
                <Menu.Item to="/customers" label="Customers" />
            )}
            {hasPermissionsAccessForEntities.tableAllotments && (
                <Menu.Item to="/table-allotments" label="Table Allotments" />
            )}
            {hasPermissionsAccessForEntities.events && (
                <Menu.Item
                    to="/events?events[filter]=ZW5kX3V0YyU1QjAlNUQlNUJ2YWx1ZSU1RD10cnVlJmVuZF91dGMlNUIwJTVEJTVCb3BlcmF0b3IlNUQ9dXBjb21pbmc%3D"
                    label="Events"
                />
            )}
            {hasPermissionsAccessForEntities.venues && <Menu.Item to="/venues" label="Venues" />}
            {hasPermissionsAccessForEntities.groups && <Menu.Item to="/groups" label="Groups" />}
            {hasPermissionsAccessForEntities.resellers && (
                <Menu.Item to="/resellers" label="Resellers" />
            )}
            {hasPermissionsAccessForEntities.companies && (
                <Menu.Item to="/companies" label="Companies" />
            )}
            {hasPermissionsAccessForEntities.settings && (
                <Menu.Submenu label="Settings">
                    {hasPermission('CAN_CUSTOM_MANAGE_CATEGORIES', meData) && (
                        <Menu.SubmenuItem
                            to="/settings/categories/event-categories"
                            label="Categories"
                        />
                    )}
                    {hasPermission('CAN_CUSTOM_MANAGE_INTEGRATIONS', meData) && (
                        <Menu.SubmenuItem
                            to="/settings/integrations/ticketsocket-api"
                            label="Integrations"
                        />
                    )}
                    {hasPermission('CAN_CUSTOM_MANAGE_RESELLER_ADMIN', meData) && (
                        <Menu.SubmenuItem
                            to="/settings/reseller/header-menu"
                            label="Reseller Admin"
                        />
                    )}
                    {hasPermission('CAN_CUSTOM_MANAGE_PRINT_SETTINGS', meData) && (
                        <Menu.SubmenuItem to="/settings/print-settings" label="Print Settings" />
                    )}
                    {hasPermission('CAN_MANAGE_SECURITY_BUNDLE_ROLE', meData) && (
                        <Menu.SubmenuItem to="/settings/roles" label="Roles" />
                    )}
                    {hasPermission('CAN_MANAGE_SECURITY_BUNDLE_PERMISSION', meData) && (
                        <Menu.SubmenuItem to="/settings/permissions" label="Permissions" />
                    )}
                </Menu.Submenu>
            )}
            {hasPermissionsAccessForEntities.notifications && (
                <Menu.Item to="/notifications" label="Notifications" />
            )}
            {hasPermissionsAccessForEntities.tableCardPrinting && (
                <Menu.Item to="/table-card-printing" label="Table Card Printing" />
            )}
        </Menu>
    );
};

export default withRouter(MainNavigation);
