import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import LayoutManager from 'erputils/LayoutManager';
import PageHeader from 'erpcomponents/Layout/PageHeader';
import PageContent from 'erpcomponents/Layout/PageContent';
import NotificationManager from 'erputils/NotificationManager';
import { actions as eventsActions } from 'erpcore/screens/Events/Events.reducer';
import PageLoader from 'erpcomponents/PageLoader';
import { actions as listingActions } from 'erpcomponents/Listing/Listing.reducer';
import { getListingFetching, getListingResponse } from 'erpcomponents/Listing/Listing.selectors';
import { getEventData, getSingleEventFetching } from 'erpsrc/screens/Events/Events.selectors';
import Button from 'erpcomponents/Button';
import PrintPage from 'erpcore/screens/Tables/screens/PrintGuestlistByName/components/PrintPage';
import ReactToPrint from 'react-to-print';

class PrintGuestlistByName extends React.Component {
    constructor(props) {
        super(props);

        this.printContent = React.createRef();
        this.grid = 8;

        this.state = {
            tablesData: [],
            groupsData: [],
            eventNoTables: false,
            eventNoGroups: false
        };

        const { tablesData, groupsData } = this.props;
        if (tablesData && tablesData.data !== undefined && tablesData.data.length) {
            this.state.tablesData = tablesData.data;
        }
        if (groupsData && groupsData.data !== undefined && groupsData.data.length) {
            this.state.groupsData = groupsData.data;
        }
        this.state.eventNoTables = this.isListingResponseEmpty(tablesData);
        this.state.eventNoGroups = this.isListingResponseEmpty(groupsData);

        this.getGroupById = this.getGroupById.bind(this);
        this.getTableById = this.getTableById.bind(this);
    }

    componentDidMount() {
        const { match } = this.props;
        const { eventId } = match.params;

        if (eventId) {
            const { fetchOrders, fetchEventData, fetchTables, fetchGroups } = this.props;

            // fetch orders
            fetchOrders({
                'filters[event.id][equals]': eventId
            });

            // fetch event
            fetchEventData(eventId);

            // fetch event tables
            fetchTables({
                'filters[event.id][equals]': eventId
            });

            // fetch event groups
            fetchGroups({
                'filters[event.id][equals]': eventId
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        const { tablesData, groupsData } = nextProps;

        // Set Table and Groups local state
        const eventNoTables = this.isListingResponseEmpty(tablesData);
        if (tablesData && tablesData.data !== undefined) {
            if (tablesData.data.length) {
                this.setState({ tablesData: tablesData.data, eventNoTables });
            } else {
                this.setState({ tablesData: [], eventNoTables });
            }
        }
        const eventNoGroups = this.isListingResponseEmpty(groupsData);
        if (groupsData && groupsData.data !== undefined) {
            if (groupsData.data.length) {
                this.setState({ groupsData: groupsData.data, eventNoGroups });
            } else {
                this.setState({ groupsData: [], eventNoGroups });
            }
        }
    }

    getGroupById = (id = false, property = false) => {
        const { groupsData } = this.state;

        let output = null;

        if (groupsData && groupsData.length) {
            output = groupsData.find(item => id === item.id);

            if (property && output && output.property !== undefined) {
                output = output.property;
            }
        }

        return output;
    };

    getTableById = (id = false, property = false) => {
        const { tablesData } = this.state;

        let output = null;

        if (tablesData && tablesData.length) {
            output = tablesData.find(item => id.toString() === item.id.toString());
            if (property && output && output.property !== undefined) {
                output = output.property;
            }
        }

        return output;
    };

    isListingResponseEmpty(listingData) {
        let isEmpty = false;
        if (
            listingData &&
            listingData.meta !== undefined &&
            listingData.meta.totalItems !== undefined &&
            (listingData.meta.totalItems === 0 || listingData.meta.totalItems === '0')
        ) {
            isEmpty = true;
        }
        return isEmpty;
    }

    renderCustomStyle() {
        return `
            @media print {
                .page-header,
                .button,
                .sidebar {
                    display: none;
                }
                .main {
                    padding: 0;
                }
                br {
                    display:none;
                }
            }
        `;
    }

    render() {
        const {
            error,
            eventData,
            eventFetching,
            ordersListingFetching,
            tablesListingFetching,
            groupsListingFetching
        } = this.props;

        const { tablesData, groupsData } = this.state;

        return (
            <LayoutManager slot="main" layoutType="merge">
                <style>{this.renderCustomStyle()}</style>

                <PageHeader
                    title={
                        <FormattedMessage
                            id="PrintGuestlistByName.title"
                            defaultMessage="Print Guest List by Last Name"
                        />
                    }
                />
                {(!!ordersListingFetching ||
                    !!eventFetching ||
                    !!tablesListingFetching ||
                    !!groupsListingFetching) && <PageLoader />}
                <PageContent>
                    {error && error.code && <NotificationManager code={error.code} />}

                    <ReactToPrint
                        trigger={() => <Button label="Print" />}
                        content={() => this.printContent.current}
                    />

                    <br />
                    <br />

                    <PrintPage
                        ref={this.printContent}
                        eventData={eventData}
                        tablesData={tablesData}
                        groupsData={groupsData}
                        getGroupById={this.getGroupById}
                    />

                    <br />

                    <ReactToPrint
                        trigger={() => <Button label="Print" />}
                        content={() => this.printContent.current}
                    />
                </PageContent>
            </LayoutManager>
        );
    }
}

PrintGuestlistByName.defaultProps = {
    error: null,
    // event: null,
    ordersListingFetching: false,
    eventFetching: false,
    tablesListingFetching: false,
    eventData: null,
    tablesData: {},
    groupsListingFetching: false,
    groupsData: {},
    // tableUpdating: false,
    fetchOrders: () => {},
    fetchEventData: () => {},
    fetchTables: () => {},
    fetchGroups: () => {}
};

PrintGuestlistByName.propTypes = {
    // dispatch: PropTypes.isRequired,
    error: PropTypes.oneOfType([PropTypes.object]),
    match: PropTypes.oneOfType([PropTypes.object]).isRequired,
    ordersListingFetching: PropTypes.bool,
    eventFetching: PropTypes.bool,
    tablesListingFetching: PropTypes.bool,
    eventData: PropTypes.oneOfType([PropTypes.object]),
    tablesData: PropTypes.oneOfType([PropTypes.object]),
    groupsListingFetching: PropTypes.bool,
    groupsData: PropTypes.oneOfType([PropTypes.object]),
    // tableUpdating: PropTypes.bool,
    fetchOrders: PropTypes.func,
    fetchEventData: PropTypes.func,
    fetchTables: PropTypes.func,
    fetchGroups: PropTypes.func
};

const mapStateToProps = (state, ownProps) => ({
    ordersListingFetching: getListingFetching(state, 'orders'),
    eventData: getEventData(state, ownProps.match.params.eventId),
    eventFetching: getSingleEventFetching(state),
    tablesListingFetching: getListingFetching(state, 'tables'),
    tablesData: getListingResponse(state, 'tables'),
    groupsListingFetching: getListingFetching(state, 'groups'),
    groupsData: getListingResponse(state, 'groups')
});

const mapDispatchToProps = dispatch => ({
    fetchEventData: id => {
        // Getting included data from API by setting params
        const params = {
            include: 'location'
        };
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: eventsActions.START_FETCHING_SINGLE_EVENT,
                id,
                params
            });
        }).catch(error => ({ error }));
    },
    fetchTables: params => {
        // Getting included data from API by setting defaultParams
        const defaultParams = {
            pagination: false,
            include: 'room,groups',
            'order_by[table_number]': 'ASC'
        };
        const listingParams = Object.assign({}, defaultParams, params);
        dispatch({
            type: listingActions.START_FETCHING_LISTING,
            params: listingParams,
            entity: 'TABLES_DND',
            endpoint: 'api/tables'
        });
    },
    fetchGroups: params => {
        // Getting included data from API by setting defaultParams
        const defaultParams = {
            pagination: false,
            'filters[has_children][equals]': false,
            include:
                'events,event,event.name,ticketTypes,orders,orders.tables,orders.customer,tables,table'
        };
        const listingParams = Object.assign({}, defaultParams, params);
        dispatch({
            type: listingActions.START_FETCHING_LISTING,
            params: listingParams,
            entity: 'GROUPS',
            endpoint: 'api/groups'
        });
    }
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(PrintGuestlistByName)
);
