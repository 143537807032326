export const actions = {
    START_FETCHING_COMPANIES: 'START_FETCHING_COMPANIES',
    FETCHING_SUCCESSFUL_COMPANIES: 'FETCHING_SUCCESSFUL_COMPANIES',
    FETCHING_FAILED_COMPANIES: 'FETCHING_FAILED_COMPANIES',
    START_CREATE_COMPANY: 'START_CREATE_COMPANY',
    CREATE_COMPANY_SUCCESSFUL: 'CREATE_COMPANY_SUCCESSFUL',
    CREATE_COMPANY_FAILED: 'CREATE_COMPANY_FAILED',
    START_FETCHING_SINGLE_COMPANY: 'START_FETCHING_SINGLE_COMPANY',
    FETCH_SINGLE_COMPANY_SUCCESSFUL: 'FETCH_SINGLE_COMPANY_SUCCESSFUL',
    FETCH_SINGLE_COMPANY_FAILED: 'FETCH_SINGLE_COMPANY_FAILED',
    START_UPDATE_SINGLE_COMPANY: 'START_UPDATE_SINGLE_COMPANY',
    UPDATE_SINGLE_COMPANY_SUCCESSFUL: 'UPDATE_SINGLE_COMPANY_SUCCESSFUL',
    UPDATE_SINGLE_COMPANY_FAILED: 'UPDATE_SINGLE_COMPANY_FAILED',
    START_DELETE_SINGLE_COMPANY: 'START_DELETE_SINGLE_COMPANY',
    DELETE_SINGLE_COMPANY_SUCCESSFUL: 'DELETE_SINGLE_COMPANY_SUCCESSFUL',
    DELETE_SINGLE_COMPANY_FAILED: 'DELETE_SINGLE_COMPANY_FAILED',
    START_IMPORT_COMPANIES: 'START_IMPORT_COMPANIES',
    START_EXPORT_COMPANIES: 'START_EXPORT_COMPANIES',
    EXPORT_COMPANIES_SUCCESSFUL: 'EXPORT_COMPANIES_SUCCESSFUL',
    EXPORT_COMPANIES_FAILED: 'EXPORT_COMPANIES_FAILED',
    STORE_SINGLE_COMPANY_DATA: 'STORE_SINGLE_COMPANY_DATA'
};

export const initialState = {
    listingFetching: false,
    listingData: [],
    listingMeta: {},
    listingError: [],
    singleFetching: false,
    singleError: null,
    deleteSingleError: null,
    companies: []
};

export default (state = initialState, { type, id, response }) => {
    switch (type) {
        case actions.START_FETCHING_COMPANIES: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_COMPANIES: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_COMPANIES: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        case actions.START_FETCHING_SINGLE_COMPANY: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.FETCH_SINGLE_COMPANY_SUCCESSFUL: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.FETCH_SINGLE_COMPANY_FAILED: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.UPDATE_SINGLE_COMPANY_FAILED: {
            return {
                ...state,
                singleError: response
            };
        }
        case actions.DELETE_SINGLE_COMPANY_FAILED: {
            return {
                ...state,
                deleteSingleError: response
            };
        }
        case actions.STORE_SINGLE_COMPANY_DATA: {
            return Object.assign({}, state, {
                [id]: response
            });
        }
        default:
            return state;
    }
};
