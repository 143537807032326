import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IntlProvider, addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import da from 'react-intl/locale-data/da';
import { getLanguage, getLocale } from './LanguageManager.selectors';
import getMessages from '../../../../build/locales/data.json';

addLocaleData(en);
addLocaleData(da);

const LanguageManager = ({ language, locale, messages, children }) => {
    return (
        <IntlProvider key={language} locale={language} messages={messages[locale]}>
            {children}
        </IntlProvider>
    );
};

LanguageManager.defaultProps = {
    children: null,
    language: 'en-US',
    locale: 'en',
    messages: getMessages
};

LanguageManager.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    language: PropTypes.string,
    locale: PropTypes.string,
    messages: PropTypes.oneOfType([PropTypes.object])
};

const mapStateToProps = state => ({
    language: getLanguage(state),
    locale: getLocale(state)
});

export default connect(mapStateToProps)(LanguageManager);
