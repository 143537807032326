import React from 'react';
import PropTypes from 'prop-types';
import './Cardbox.scss';

const LineBreak = () => {
    return <div className="card__break" />;
};

const Cardbox = props => {
    const { body, header, footer } = props;

    return (
        <div className="card">
            <div className="card__header">{header}</div>
            {header && (body || footer) && <LineBreak />}
            <div className="card__body">{body}</div>
            {footer && body && <LineBreak />}
            <div className="card__footer">{footer}</div>
        </div>
    );
};

Cardbox.defaultProps = {
    header: '',
    body: '',
    footer: ''
};

Cardbox.propTypes = {
    header: PropTypes.node,
    body: PropTypes.node,
    footer: PropTypes.node
};

export default Cardbox;
