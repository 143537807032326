import { createSelector } from 'reselect';

export const getTableTemplateData = (state, id) =>
    state.tableTemplates[id] && state.tableTemplates[id].data && state.tableTemplates[id].data;

export const getSelectedTableTemplate = state =>
    state.tableTemplates &&
    state.tableTemplates.tableTemplate &&
    state.tableTemplates.tableTemplate.data;

export const getDuplicatedTableTemplate = state =>
    state.tableTemplates &&
    state.tableTemplates.duplicatedTableTemplate &&
    state.tableTemplates.duplicatedTableTemplate.data;

export const getSingleTableTemplateFetching = state => state.tableTemplates.singleFetching;

export const getNewTables = state => state.tableTemplates.newTable && state.tableTemplates.newTable;

export const getCreatedTableTemplate = state =>
    state.tableTemplates &&
    state.tableTemplates.createdTableTemplate &&
    state.tableTemplates.createdTableTemplate;
export const getEventTables = state =>
    state.tableTemplates && state.tableTemplates.eventTables && state.tableTemplates.eventTables;

export const getEventTableTemplates = state =>
    state.tableTemplates &&
    state.tableTemplates.eventTableTemplates &&
    state.tableTemplates.eventTableTemplates;

export const getTableTemplateTables = state =>
    state.tableTemplates &&
    state.tableTemplates.tableTemplateTables &&
    state.tableTemplates.tableTemplateTables;

export default createSelector([
    getTableTemplateData,
    getSingleTableTemplateFetching,
    getNewTables,
    getCreatedTableTemplate,
    getTableTemplateTables,
    getDuplicatedTableTemplate,
    getCreatedTableTemplate,
    getEventTables,
    getEventTableTemplates
]);
