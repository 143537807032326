import React, { Component } from 'react';
import LayoutManager from 'erputils/LayoutManager';
import PageHeader from 'erpcomponents/Layout/PageHeader';
import PageContent from 'erpcomponents/Layout/PageContent';
import { withRouter } from 'react-router-dom';
import SettingsTabs from 'erpcore/screens/Settings/components/SettingsTabs';
import PropTypes from 'prop-types';
import Listing from 'erpcomponents/Listing';
import { FormattedMessage } from 'react-intl';
import ListingTableActions from 'erpcomponents/Listing/components/TableActions';
import { connect } from 'react-redux';
import { getListingFetching, getListingResponse } from 'erpcomponents/Listing/Listing.selectors';
import { actions as listingActions } from 'erpcomponents/Listing/Listing.reducer';
import FoodTypeCreate from 'erpcore/screens/Settings/components/FoodTypeCreate';
import FoodTypeDelete from 'erpcore/screens/Settings/components/FoodTypeDelete';
import FoodTypeEdit from 'erpcore/screens/Settings/components/FoodTypeEdit';

const reducerName = 'foodTypes';

class FoodTypes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            foodTypeId: null
        };
        this.toggleEdit = this.toggleEdit.bind(this);
        this.resetFoodTypeId = this.resetFoodTypeId.bind(this);
    }

    toggleEdit(id) {
        this.setState({
            foodTypeId: id
        });
    }

    resetFoodTypeId() {
        this.setState({ foodTypeId: null });
    }

    tableData() {
        const { foodTypesData } = this.props;
        const table = {};
        table.data = [];

        // Table Schema
        table.schema = [
            {
                title: (
                    <FormattedMessage id="Settings.FoodTypes.Table" defaultMessage="Food Types" />
                ),
                field: 'eventName',
                sortable: 'name'
            },
            {
                title: (
                    <FormattedMessage
                        id="Settings.FoodTypes.Table.Actions"
                        defaultMessage="Actions"
                    />
                ),
                field: 'actions'
            }
        ];

        foodTypesData.data.map(row => {
            return table.data.push({
                id: row.id,
                eventName: row.name,
                actions: (
                    <ListingTableActions>
                        <ListingTableActions.Action>
                            <FoodTypeEdit
                                id={row.id}
                                key={`FoodTypeEdit_${row.id}`}
                                form="FoodTypeEditForm"
                            />
                        </ListingTableActions.Action>
                        <ListingTableActions.Action>
                            <FoodTypeDelete
                                id={row.id}
                                key={`FoodTypeDelete_${row.id}`}
                                form="FoodTypeDeleteForm"
                            />
                        </ListingTableActions.Action>
                    </ListingTableActions>
                )
            });
        });

        return table;
    }

    render() {
        const { fetchFoodTypes, foodTypesListingFetching, foodTypesData } = this.props;
        const { foodTypeId } = this.state;

        return (
            <LayoutManager slot="main" className="main--narrow" layoutType="merge">
                <PageHeader title="Settings - Categories" />
                <SettingsTabs />
                <PageContent>
                    {!foodTypeId ? (
                        <FoodTypeCreate />
                    ) : (
                        <FoodTypeEdit resetId={this.resetFoodTypeId} id={foodTypeId} />
                    )}
                    <Listing
                        name={reducerName}
                        reducerName={reducerName}
                        loading={foodTypesListingFetching}
                        hideSearch
                        meta={foodTypesData.meta}
                        table={this.tableData()}
                        onListingConfigUpdate={params => fetchFoodTypes(params)}
                    />
                </PageContent>
            </LayoutManager>
        );
    }
}

FoodTypes.defaultProps = {
    foodTypesData: {},
    foodTypesListingFetching: false
};

FoodTypes.propTypes = {
    foodTypesData: PropTypes.oneOfType([PropTypes.object]),
    fetchFoodTypes: PropTypes.func.isRequired,
    foodTypesListingFetching: PropTypes.bool
};

const mapStateToProps = state => ({
    foodTypesListingFetching: getListingFetching(state, 'foodTypes'),
    foodTypesData: getListingResponse(state, 'foodTypes')
});

const mapDispatchToProps = dispatch => ({
    fetchFoodTypes: params => {
        // Getting included data from API by setting defaultParams
        const listingParams = Object.assign({ pagination: false }, params);

        dispatch({
            type: listingActions.START_FETCHING_LISTING,
            params: listingParams,
            name: reducerName,
            entity: 'FOOD_TYPES',
            endpoint: 'api/food-types'
        });
    }
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(FoodTypes)
);
