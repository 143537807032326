import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import LayoutManager from 'erputils/LayoutManager';
import PageHeader from 'erpcomponents/Layout/PageHeader';
import PageContent from 'erpcomponents/Layout/PageContent';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import UpdateProfileForm from 'erpcore/screens/MyAccount/components/UpdateProfileForm';
import ChangePasswordForm from 'erpcore/screens/MyAccount/components/ChangePasswordForm';
import { actions as authActions } from 'erpcore/utils/AuthManager/AuthManager.reducer';

const MyAccount = () => {
    const dispatch = useDispatch();
    const fetchMeData = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: authActions.START_FETCHING_ME
            });
        }).catch(error => ({ error }));
    };

    /*
     * Effects
     */
    useEffect(() => {
        fetchMeData();
    }, []);

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <HeadMeta title="My Account" />
            <PageHeader
                title={<FormattedMessage id="MyAccount.title" defaultMessage="My Account" />}
            />
            <PageContent>
                <UpdateProfileForm />
                <ChangePasswordForm />
            </PageContent>
        </LayoutManager>
    );
};

export default MyAccount;
