import Text from './components/Text';
import Textarea from './components/Textarea';
import Email from './components/Email';
import Checkbox from './components/Checkbox';
import CheckboxGroup from './components/CheckboxGroup';
import Radio from './components/Radio';
import RadioGroup from './components/RadioGroup';
import Number from './components/Number';
import Hidden from './components/Hidden';
import Password from './components/Password';
import Switch from './components/Switch';
import SwitchCard from './components/SwitchCard';
import Select from './components/Select';
import MultiSelect from './components/MultiSelect';
import Form from './components/Form';
import TextEditor from './components/TextEditor';
import ImageUpload from './components/ImageUpload';
import Signature from './components/Signature';
import Creatable from './components/Creatable';
import MultiCreatable from './components/MultiCreatable';
import Location from './components/Location';
import Autocomplete from './components/Autocomplete';
import MultiAutocomplete from './components/MultiAutocomplete';
import DateTime from './components/DateTime';
import FileUploader from './components/FileUploader';
import Phone from './components/Phone';
import Repeater from './components/Repeater';
import Gallery from './components/Gallery';
import TextEditorClass from './components/TextEditorClass';

export {
    Text,
    Textarea,
    Email,
    Checkbox,
    CheckboxGroup,
    Radio,
    RadioGroup,
    Number,
    Hidden,
    Password,
    Select,
    MultiSelect,
    Switch,
    SwitchCard,
    TextEditor,
    ImageUpload,
    Signature,
    Creatable,
    MultiCreatable,
    Location,
    Autocomplete,
    MultiAutocomplete,
    DateTime,
    FileUploader,
    Phone,
    Repeater,
    Gallery,
    TextEditorClass
};

export default Form;
