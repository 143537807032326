import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import Form, { Password } from 'erpcore/components/Form';
import valueValidation from 'erputils/valueValidation';
import LayoutManager from 'erputils/LayoutManager';
import frontLayout from 'erputils/LayoutManager/frontLayout';
import Button from 'erpcomponents/Button';
import PageNotification from 'erpcomponents/Layout/PageNotification';
import { getPageNotification } from 'erputils/NotificationManager/NotificationManager.selectors';
import { actions as forgotPasswordActions } from 'erpcore/screens/ForgotPassword/ForgotPassword.reducer';

class ResetPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            redirect: false
        };

        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(formData) {
        const { dispatch, match } = this.props;

        formData = Object.assign({}, formData, { reset_token: match.params.token });

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: forgotPasswordActions.START_RESET_PASSWORD,
                formData
            })
        )
            .then(() => {
                return this.setState({
                    redirect: true
                });
            })
            .catch(error => {
                return error;
            });
    }

    renderRedirect() {
        const { redirect } = this.state;
        if (redirect) {
            return <Redirect to="/" />;
        }

        return null;
    }

    render() {
        const { handleSubmit, submitting, pristine, pageNotification } = this.props;

        return (
            <Fragment>
                {this.renderRedirect()}

                <LayoutManager layout={frontLayout} slot="main" layoutType="merge">
                    <h2 className="front-main__title">
                        <FormattedMessage
                            id="ResetPassword.title"
                            defaultMessage="Setup Your New Password"
                        />
                    </h2>

                    {pageNotification && pageNotification.code && (
                        <PageNotification code={pageNotification.code} />
                    )}

                    <Form onSubmit={handleSubmit(this.onSubmit)}>
                        <Form.Row>
                            <Field
                                name="new_password"
                                id="new_password"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="ResetPassword.Password"
                                            defaultMessage="New Password"
                                        />
                                    ),
                                    clearable: true
                                }}
                                component={Password}
                                validate={valueValidation([
                                    { validator: 'length', args: { min: 8 } },
                                    { validator: 'required' }
                                ])}
                            />
                        </Form.Row>
                        <Form.Row verticalCenter>
                            <Form.RowColResponsive>
                                <Button
                                    submit
                                    disabled={pristine}
                                    loading={submitting}
                                    label={
                                        <FormattedMessage
                                            id="ResetPassword.Confirm"
                                            defaultMessage="Confirm"
                                        />
                                    }
                                />
                            </Form.RowColResponsive>
                            <Form.RowColResponsive>
                                <span className="form__help-text">
                                    <FormattedMessage
                                        id="ResetPassword.text"
                                        defaultMessage="Need help or more information?"
                                    />
                                    <br />
                                    <FormattedMessage
                                        id="ResetPassword.text.contact"
                                        defaultMessage="Contact:"
                                    />{' '}
                                    <a href="mailto:daryl@glowgardens.com">daryl@glowgardens.com</a>
                                </span>
                            </Form.RowColResponsive>
                        </Form.Row>
                    </Form>
                </LayoutManager>
            </Fragment>
        );
    }
}

ResetPassword.defaultProps = {
    match: null,
    submitting: false,
    pristine: false,
    pageNotification: null
};

ResetPassword.propTypes = {
    match: PropTypes.oneOfType([PropTypes.object]),
    handleSubmit: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    pageNotification: PropTypes.oneOfType([PropTypes.object])
};

ResetPassword = reduxForm({
    form: 'ResetPasswordForm',
    enableReinitialize: true
})(ResetPassword);

const mapStateToProps = state => ({
    pageNotification: getPageNotification(state)
});

export default withRouter(connect(mapStateToProps)(ResetPassword));
