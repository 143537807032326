import React from 'react';
import { Field, formValueSelector } from 'redux-form';
import Form, { Text, TextEditor, SwitchCard } from 'erpcore/components/Form';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import valueValidation from 'erputils/valueValidation';
import { actions as headerMenuActions } from 'erpcore/screens/Settings/screens/ResellerAdmin/HeaderMenu/HeaderMenu.reducer';

class HeaderMenuRepeaterItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pendingUpdates: {}
        };

        this.handleChange = debounce(this.handleChange, 2000);
    }

    dispatchUpdate = () => {
        const { formValues, dispatch } = this.props;

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: headerMenuActions.REQUEST_UPDATE_SINGLE_HEADER_MENU,
                formData: {
                    itemSorts: { ...formValues }
                }
            })
        )
            .then(() => {
                this.removePendingUpdates();
            })
            .catch(() => {
                this.removePendingUpdates();
            });
    };

    handleChange = (event, newValue, previousValue, name) => {
        const { member, isNewRepeaterItem } = this.props;

        if (!isNewRepeaterItem) {
            const nameForApi = name.replace(`${member}.`, '');
            const updates = { [nameForApi]: newValue };
            const { pendingUpdates } = this.state;
            const mergedUpdates = { ...pendingUpdates, ...updates };
            this.setState({ pendingUpdates: mergedUpdates });

            this.dispatchUpdate(mergedUpdates, name);
        }
    };

    removePendingUpdates() {
        this.setState({ pendingUpdates: {} });
    }

    isValid(validatorFunctions = [], value) {
        const errors = validatorFunctions.map(validatorFunction => {
            return validatorFunction(value);
        });
        const isNotValid = errors.find(item => item !== undefined);

        return !isNotValid;
    }

    render() {
        const { member, data } = this.props;
        return (
            <>
                <Form.Row>
                    <Form.Columns breakOnMobile>
                        <Form.Column>
                            <Field
                                name={`${member}.item_name`}
                                id="item_name"
                                fieldProps={{
                                    label: 'Menu Item Label'
                                }}
                                fieldAttr={{ required: true }}
                                component={Text}
                                validate={valueValidation([{ validator: 'required' }])}
                                onChange={(event, newValue, previousValue, name) => {
                                    /*
                                     * redux-form validation is started after the Field onChange is done,
                                     * so the validity of the field has to be checked manually
                                     * before handling the change data further
                                     */
                                    if (
                                        this.isValid(
                                            valueValidation([{ validator: 'required' }]),
                                            newValue
                                        )
                                    ) {
                                        this.handleChange(event, newValue, previousValue, name);
                                    }
                                }}
                            />
                        </Form.Column>
                        <Form.Column>
                            <Field
                                name={`${member}.item_value`}
                                id="item_value"
                                // parse={parseInteger}
                                fieldProps={{
                                    label: 'Menu Item URL'
                                }}
                                component={Text}
                                onChange={this.handleChange}
                            />
                        </Form.Column>
                    </Form.Columns>
                </Form.Row>
                <Form.Row>
                    <Field
                        name={`${member}.page_content`}
                        id={`${member}.page_content`}
                        fieldProps={{
                            label: 'Page content'
                        }}
                        component={TextEditor}
                        onChange={(event, newValue, previousValue, name) => {
                            /*
                             * redux-form validation is started after the Field onChange is done,
                             * so the validity of the field has to be checked manually
                             * before handling the change data further
                             */
                            if (
                                this.isValid(valueValidation([{ validator: 'required' }]), newValue)
                            ) {
                                if (newValue !== '') {
                                    this.handleChange(event, newValue, previousValue, name);
                                }
                            }
                        }}
                    />
                </Form.Row>
                <Form.Row>
                    <Field
                        name={`${member}.show_in_menu`}
                        id={`${member}.show_in_menu`}
                        fieldProps={{
                            label: 'Display in menu',
                            onValue: true,
                            offValue: false
                        }}
                        fieldAttr={{
                            input: {
                                value: data.show_in_menu
                            }
                        }}
                        onChange={(event, newValue, previousValue, name) => {
                            this.handleChange(event, newValue, previousValue, name);
                        }}
                        component={SwitchCard}
                    />
                </Form.Row>
            </>
        );
    }
}

HeaderMenuRepeaterItem.defaultProps = {
    member: null,
    data: {},
    isNewRepeaterItem: false,
    formValues: []
};

HeaderMenuRepeaterItem.propTypes = {
    dispatch: PropTypes.func.isRequired,
    member: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    data: PropTypes.oneOfType([PropTypes.object]),
    isNewRepeaterItem: PropTypes.bool,
    formValues: PropTypes.oneOfType([PropTypes.array])
};

const mapDispatchToProps = state => {
    const getFormValues = formValueSelector('HeaderMenuForm');
    return {
        formValues: getFormValues(state, 'value')
    };
};

export default connect(mapDispatchToProps)(HeaderMenuRepeaterItem);
