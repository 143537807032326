import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import NotificationManager from 'erputils/NotificationManager';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { actions as notificationManagerActions } from 'erputils/NotificationManager/NotificationManager.reducer';
import './FloatingNotifications.scss';

class FloatingNotifications extends React.Component {
    getNotifications() {
        const { notifications, removeFloatingNotification } = this.props;

        return notifications.map((notification, index) => {
            const key =
                notification.data !== undefined &&
                notification.data.id !== undefined &&
                notification.data.id
                    ? `${notification.code}-${notification.data.id}`
                    : notification.code;
            return (
                <NotificationManager
                    onRemoveFloatingNotification={() => removeFloatingNotification(index)}
                    key={key}
                    data={notification.data}
                    code={notification.code}
                />
            );
        });
    }

    render() {
        const { className } = this.props;
        const notifications = this.getNotifications();

        // .floating-notifications is always present in DOM for animation purposes. It is however hidden if empty.
        return (
            <div
                className={`floating-notifications ${
                    !(notifications && notifications.length) ? 'floating-notifications--empty' : ''
                } ${className || ''}`}
            >
                <ReactCSSTransitionGroup
                    transitionName="notification--transition"
                    transitionEnterTimeout={500}
                    transitionLeaveTimeout={500}
                >
                    {notifications}
                </ReactCSSTransitionGroup>
            </div>
        );
    }
}

FloatingNotifications.defaultProps = {
    notifications: [],
    className: '',
    removeFloatingNotification: () => {}
};

FloatingNotifications.propTypes = {
    notifications: PropTypes.oneOfType([PropTypes.array]),
    className: PropTypes.string,
    removeFloatingNotification: PropTypes.func
};

const mapDispatchToProps = dispatch => ({
    removeFloatingNotification: index => {
        dispatch({
            type: notificationManagerActions.REMOVE_FLOATING_NOTIFICATION,
            index
        });
    }
});

export default connect(
    null,
    mapDispatchToProps
)(FloatingNotifications);
