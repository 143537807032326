export const actions = {
    START_FETCHING_EVENT_SERIES: 'START_FETCHING_EVENT_SERIES',
    FETCHING_SUCCESSFUL_EVENT_SERIES: 'FETCHING_SUCCESSFUL_EVENT_SERIES',
    FETCHING_FAILED_EVENT_SERIES: 'FETCHING_FAILED_EVENT_SERIES',
    START_CREATE_EVENT_SERIES: 'START_CREATE_EVENT_SERIES',
    CREATE_EVENT_SERIES_SUCCESSFUL: 'CREATE_EVENT_SERIES_SUCCESSFUL',
    CREATE_EVENT_SERIES_FAILED: 'CREATE_EVENT_SERIES_FAILED',
    START_DELETE_SINGLE_EVENT_SERIES: 'START_DELETE_SINGLE_EVENT_SERIES',
    DELETE_SINGLE_EVENT_SERIES_SUCCESSFUL: 'DELETE_SINGLE_EVENT_SERIES_SUCCESSFUL',
    DELETE_SINGLE_EVENT_SERIES_FAILED: 'DELETE_SINGLE_EVENT_SERIES_FAILED',
    STORE_SINGLE_EVENT_SERIES_DATA: 'STORE_SINGLE_EVENT_SERIES_DATA',
    START_UPDATE_SINGLE_EVENT_SERIES: 'START_UPDATE_SINGLE_EVENT_SERIES',
    START_FETCHING_SINGLE_EVENT_SERIES: 'START_FETCHING_SINGLE_EVENT_SERIES',
    FETCH_SINGLE_EVENT_SERIES_SUCCESSFUL: 'FETCH_SINGLE_EVENT_SERIES_SUCCESSFUL',
    FETCH_SINGLE_EVENT_SERIES_FAILED: 'FETCH_SINGLE_EVENT_SERIES_FAILED',
    UPDATE_SINGLE_EVENT_SERIES_SUCCESSFUL: 'UPDATE_SINGLE_EVENT_SERIES_SUCCESSFUL',
    UPDATE_SINGLE_EVENT_SERIES_FAILED: 'UPDATE_SINGLE_EVENT_SERIES_FAILED'
};

export const initialState = {
    listingFetching: false,
    listingData: [],
    listingMeta: {},
    listingError: [],
    singleFetching: false,
    series: []
};

export default (state = initialState, { type, id, response }) => {
    switch (type) {
        case actions.START_FETCHING_EVENT_SERIES: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_EVENT_SERIES: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_EVENT_SERIES: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        case actions.START_CREATE_EVENT_SERIES: {
            return state;
        }
        case actions.START_FETCHING_SINGLE_EVENT_SERIES: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.FETCH_SINGLE_EVENT_SERIES_SUCCESSFUL: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.FETCH_SINGLE_EVENT_SERIES_FAILED: {
            return {
                ...state,
                singleFetching: false,
                singleError: response
            };
        }
        case actions.UPDATE_SINGLE_EVENT_SERIES_FAILED: {
            return {
                ...state,
                singleError: response
            };
        }

        case actions.STORE_SINGLE_EVENT_SERIES_DATA: {
            return Object.assign({}, state, {
                [id]: response.data
            });
        }
        default:
            return state;
    }
};
