import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import LayoutManager from 'erputils/LayoutManager';
import PageHeader from 'erpcomponents/Layout/PageHeader';
import Listing from 'erpcomponents/Listing';
import ListingTableActions from 'erpcomponents/Listing/components/TableActions';
import Button from 'erpcomponents/Button';
import Svg from 'erpcomponents/Svg';
import Tooltip from 'erpcomponents/Tooltip';
import ResellerDelete from 'erpcore/screens/Resellers/components/ResellerDelete';
import { actions as listingActions } from 'erpcomponents/Listing/Listing.reducer';
import { getListingFetching, getListingResponse } from 'erpcomponents/Listing/Listing.selectors';
import StatusBadge from 'erpcomponents/StatusBadge';
import ResellerTableInfo from 'erpcore/screens/Resellers/components/ResellerTableInfo';

// bulk actions components
import MergeResellers from 'erpsrc/screens/Resellers/screens/ResellersListing/BulkActions/MergeResellers';
import SetAccountStatus from 'erpsrc/screens/Resellers/screens/ResellersListing/BulkActions/SetAccountStatus';
import SetMicrositeStatus from 'erpsrc/screens/Resellers/screens/ResellersListing/BulkActions/SetMicrositeStatus';

import { getCheckedResellers } from 'erpsrc/screens/Resellers/Resellers.selectors';

import { getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import { hasPermission } from 'erpcore/utils/RolesManager';

const reducerName = 'resellers';

class ResellersListing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            createGroupModal: false
        };
    }

    handleModal = () => {
        const { createGroupModal } = this.state;
        this.setState({ createGroupModal: !createGroupModal });
    };

    tableData() {
        const { resellersData, meData } = this.props;
        const table = {};
        table.data = [];

        // Advanced Filters
        table.filters = [
            {
                name: 'company',
                label: (
                    <FormattedMessage id="Resellers.Table.Company.Label" defaultMessage="Company" />
                ),
                defaultOperator: 'in',
                filterFields: {
                    value: {
                        component: 'multiautocomplete',
                        fieldProps: {
                            label: (
                                <FormattedMessage
                                    id="Resellers.Table.Company Status"
                                    defaultMessage="Company"
                                />
                            ),
                            options: {
                                endpoint: '/api/companies',
                                mapData: {
                                    label: 'name',
                                    value: 'iri'
                                }
                            }
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            },
            {
                name: 'micro_site_status',
                label: (
                    <FormattedMessage
                        id="Resellers.Table.MicrositeStatus.Label"
                        defaultMessage="Microsite Status"
                    />
                ),
                defaultOperator: 'equals',
                filterFields: {
                    value: {
                        component: 'select',
                        fieldProps: {
                            label: (
                                <FormattedMessage
                                    id="Resellers.Table.MicrositeStatus"
                                    defaultMessage="Microsite Status"
                                />
                            ),
                            options: [
                                { label: 'Published', value: 'Published' },
                                { label: 'Unpublished', value: 'Unpublished' }
                            ]
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            },
            {
                name: 'reseller_primary_account.is_active',
                label: (
                    <FormattedMessage
                        id="Resellers.Table.AccountStatus.Label"
                        defaultMessage="Account Status"
                    />
                ),
                defaultOperator: 'equals',
                filterFields: {
                    value: {
                        component: 'select',
                        fieldProps: {
                            label: (
                                <FormattedMessage
                                    id="Resellers.Table.Filter.AccountStatus"
                                    defaultMessage="Account Status"
                                />
                            ),
                            options: [
                                { label: 'Active', value: 'true' },
                                { label: 'Inactive', value: 'false' }
                            ]
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            },
            {
                name: 'eventsIncluded',
                label: (
                    <FormattedMessage
                        id="Resellers.Table.EventsIncluded.Label"
                        defaultMessage="Events Included"
                    />
                ),
                defaultOperator: 'in',
                filterFields: {
                    value: {
                        component: 'multiautocomplete',
                        fieldProps: {
                            label: (
                                <FormattedMessage
                                    id="Resellers.Table.EventsIncluded"
                                    defaultMessage="Events Included"
                                />
                            ),
                            options: {
                                endpoint: '/api/events',
                                mapData: {
                                    label: 'name',
                                    value: 'iri'
                                }
                            }
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            },
            {
                name: 'eventsExcluded',
                label: (
                    <FormattedMessage
                        id="Resellers.Table.EventsExcluded.Label"
                        defaultMessage="Events Excluded"
                    />
                ),
                defaultOperator: 'in',
                filterFields: {
                    value: {
                        component: 'multiautocomplete',
                        fieldProps: {
                            label: (
                                <FormattedMessage
                                    id="Resellers.Table.EventsExcluded"
                                    defaultMessage="Events Excluded"
                                />
                            ),
                            options: {
                                endpoint: '/api/events',
                                mapData: {
                                    label: 'name',
                                    value: 'iri'
                                }
                            }
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            }
        ];

        table.bulkActions = {
            name: reducerName,
            actions: [
                { label: 'Merge Resellers', component: <MergeResellers /> },
                { label: 'Set Account Status', component: <SetAccountStatus /> },
                { label: 'Set Microsite Status', component: <SetMicrositeStatus /> }
            ]
        };

        // // Table Schema
        table.schema = [
            {
                title: (
                    <FormattedMessage id="Resellers.Table.ResellerName" defaultMessage="Reseller" />
                ),
                field: 'resellerName',
                sortable: 'reseller_name',
                mobile: 'subtitle'
            },
            {
                title: (
                    <FormattedMessage
                        id="Resellers.Table.ResellerCompany"
                        defaultMessage="Company"
                    />
                ),
                field: 'company',
                mobile: 'title'
            },
            {
                title: (
                    <FormattedMessage
                        id="Resellers.Table.ResellerMicrosite"
                        defaultMessage="Microsite"
                    />
                ),
                field: 'microsite'
            },
            {
                title: (
                    <FormattedMessage
                        id="Resellers.Table.ResellerAccountStatus"
                        defaultMessage="Account Status"
                    />
                ),
                field: 'account',
                mobile: 'status'
            },
            {
                title: (
                    <FormattedMessage
                        id="Resellers.Table.ResellerMicrositeStatus"
                        defaultMessage="Microsite Status"
                    />
                ),
                field: 'micrositeStatus'
            },
            {
                title: (
                    <FormattedMessage
                        id="Resellers.Table.ResellerHasNotes"
                        defaultMessage="Has notes"
                    />
                ),
                field: 'hasNotes',
                align: 'center'
            },
            {
                title: (
                    <FormattedMessage
                        id="Resellers.Table.ResellerActions"
                        defaultMessage="Action"
                    />
                ),
                field: 'actions'
            }
        ];

        resellersData.data.map(row => {
            return table.data.push({
                iri: row.iri,
                id: row.id,
                resellerName: row.reseller_name && (
                    <ResellerTableInfo
                        name={row.reseller_name}
                        phone={row.contact_number}
                        email={row.email}
                    />
                ),
                company: row.company && row.company.name,
                microsite: row.nye_sub_domain,
                account: (
                    <StatusBadge
                        type={
                            row.reseller_primary_account && row.reseller_primary_account.is_active
                                ? 'fulfilled'
                                : 'special'
                        }
                        text={
                            row.reseller_primary_account && row.reseller_primary_account.is_active
                                ? 'Active'
                                : 'Inactive'
                        }
                    />
                ),
                micrositeStatus: (
                    <StatusBadge
                        type={row.micro_site_status === 'Published' ? 'pending' : 'special'}
                        text={row.micro_site_status}
                    />
                ),
                hasNotes:
                    row &&
                    row.has_notes &&
                    row.comments &&
                    Array.isArray(row.comments) &&
                    row.comments.length > 0 ? (
                        <Tooltip
                            content={
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: row.comments[row.comments.length - 1].content
                                    }}
                                />
                            }
                        >
                            <NavLink to={`/resellers/${row.id}/edit/notes`}>
                                <Svg icon="note" />
                            </NavLink>
                        </Tooltip>
                    ) : (
                        '-'
                    ),
                actions: (
                    <ListingTableActions>
                        {row.micro_site_status && row.micro_site_status === 'Published' && (
                            <ListingTableActions.Action>
                                <Tooltip
                                    content={
                                        <FormattedMessage
                                            id="Resellers.Table.Actions.View"
                                            defaultMessage="View Reseller Site"
                                        />
                                    }
                                >
                                    <a
                                        href={`https://${row.nye_sub_domain &&
                                            row.nye_sub_domain}.nye.com`}
                                        target="blank"
                                    >
                                        <Svg icon="connect" />
                                    </a>
                                </Tooltip>
                            </ListingTableActions.Action>
                        )}
                        {(hasPermission('CAN_MANAGE_RESELLER_BUNDLE_RESELLER', meData) ||
                            hasPermission('CAN_UPDATE_RESELLER_BUNDLE_RESELLER', meData)) && (
                            <ListingTableActions.Action>
                                <Tooltip
                                    content={
                                        <FormattedMessage
                                            id="Resellers.Table.Actions.Edit"
                                            defaultMessage="Edit Reseller"
                                        />
                                    }
                                >
                                    <NavLink to={`/resellers/${row.id}/edit/general-info`}>
                                        <Svg icon="edit" />
                                    </NavLink>
                                </Tooltip>
                            </ListingTableActions.Action>
                        )}
                        {(hasPermission('CAN_MANAGE_RESELLER_BUNDLE_RESELLER', meData) ||
                            hasPermission('CAN_DELETE_RESELLER_BUNDLE_RESELLER', meData)) && (
                            <ListingTableActions.Action>
                                <ResellerDelete
                                    id={row.id}
                                    key={`ResellerDelete_${row.id}`}
                                    form="ResellerDeleteForm"
                                />
                            </ListingTableActions.Action>
                        )}
                    </ListingTableActions>
                )
            });
        });

        return table;
    }

    render() {
        const {
            resellersListingFetching,
            resellersData,
            fetchResellers,
            checked,
            meData
        } = this.props;

        return (
            <LayoutManager slot="main" layoutType="merge">
                <PageHeader
                    title={<FormattedMessage id="Resellers.title" defaultMessage="Resellers" />}
                >
                    {(hasPermission('CAN_MANAGE_RESELLER_BUNDLE_RESELLER', meData) ||
                        hasPermission('CAN_CREATE_RESELLER_BUNDLE_RESELLER', meData)) && (
                        <Button
                            href="/resellers/create"
                            label={
                                <FormattedMessage
                                    id="Resellers.CreateReseller"
                                    defaultMessage="Create Reseller"
                                />
                            }
                        />
                    )}
                </PageHeader>
                <Listing
                    name={reducerName}
                    reducerName={reducerName}
                    loading={resellersListingFetching}
                    meta={resellersData.meta}
                    table={this.tableData()}
                    onListingConfigUpdate={params => fetchResellers(params)}
                    groupsListingBulkActions={
                        checked && checked.length > 0 ? checked.length > 0 : false
                    }
                />
            </LayoutManager>
        );
    }
}

ResellersListing.defaultProps = {
    resellersListingFetching: false,
    resellersData: {},
    checked: [],
    meData: {}
};

ResellersListing.propTypes = {
    fetchResellers: PropTypes.func.isRequired,
    resellersListingFetching: PropTypes.bool,
    resellersData: PropTypes.oneOfType([PropTypes.object]),
    checked: PropTypes.oneOfType([PropTypes.array]),
    meData: PropTypes.oneOfType([PropTypes.object])
};

//  Redux
const mapStateToProps = state => ({
    resellersListingFetching: getListingFetching(state, 'resellers'),
    resellersData: getListingResponse(state, 'resellers'),
    checked: getCheckedResellers(state),
    meData: getMeData(state)
});

const mapDispatchToProps = dispatch => ({
    fetchResellers: params => {
        const defaultParams = {
            include: 'company,company.name,resellerPrimaryAccount,comments'
        };
        const listingParams = Object.assign({}, defaultParams, params);

        dispatch({
            type: listingActions.START_FETCHING_LISTING,
            params: listingParams,
            name: reducerName,
            entity: 'RESELLERS',
            endpoint: 'api/resellers'
        });
    }
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ResellersListing)
);
