import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import { BarChart } from 'erpcomponents/WidgetCharts';
import ElementLoader from 'erpcomponents/ElementLoader';
import Widget from 'erpcomponents/Widget';

import './TicketsOverview.scss';

import { actions as ticketsOverviewActions } from 'erpcomponents/Widgets/TicketsOverview/TicketsOverview.reducer';
import {
    getData,
    getFetching
} from 'erpcomponents/Widgets/TicketsOverview/TicketsOverview.selectors';
import { chartColors } from 'erpdata/colors';
import { change, Field, getFormValues, reduxForm } from 'redux-form';
import { Select } from 'erpcomponents/Form';
import { kFormatter } from 'erputils/utils';

class TicketsOverview extends Component {
    componentDidMount() {
        const { dateRange, eventSeries, eventID, soldBy } = this.props;
        if (eventSeries || eventID) {
            return this.fetchData({
                dateRange,
                eventID,
                eventSeries,
                soldBy
            });
        }
        return null;
    }

    componentWillReceiveProps(nextProps) {
        const { dateRange, setFormValue, soldBy } = this.props;
        const { dateRange: nextGlobalDateRange, soldBy: nextSoldBy } = nextProps;

        let willFetchData = false;

        if (dateRange !== nextGlobalDateRange) {
            return setFormValue('filter', nextGlobalDateRange);
        }

        if (nextSoldBy !== soldBy) {
            willFetchData = true;
        }

        if (willFetchData) {
            return this.fetchData({
                soldBy: nextSoldBy,
                dateRange
            });
        }

        return null;
    }

    componentDidUpdate(prevProps) {
        const { dateRange, eventID, filterValues, eventSeries } = this.props;
        const { eventID: prevEventID, eventSeries: prevEventSeries, soldBy } = prevProps;

        let willFetchData = false;

        if (
            prevProps.filterValues &&
            prevProps.filterValues.filter &&
            filterValues.filter &&
            prevProps.filterValues.filter !== filterValues.filter
        ) {
            willFetchData = true;
        }

        if (prevEventSeries !== eventSeries) {
            willFetchData = true;
        }

        if (prevEventID && prevEventID !== eventID) {
            willFetchData = true;
        }

        if (willFetchData) {
            return this.fetchData({ dateRange, soldBy, eventSeries, eventID });
        }

        return true;
    }

    fetchData({ eventID, eventSeries, dateRange, soldBy }) {
        const { dispatch, id, filterValues } = this.props;

        const params = {
            display_by: 'day'
        };

        if (filterValues.filter !== dateRange && filterValues.filter !== Infinity) {
            const now = new Date();
            const startDate = new Date(now.getTime() - filterValues.filter * 24 * 60 * 60 * 1000);
            params['filters[start_date]'] = startDate;
            params['filters[end_date]'] = now;
        } else if (dateRange !== Infinity && filterValues.filter === dateRange) {
            const now = new Date();
            const startDate = new Date(now.getTime() - dateRange * 24 * 60 * 60 * 1000);
            params['filters[start_date]'] = startDate;
            params['filters[end_date]'] = now;
        }

        if (eventSeries) {
            params['filters[event_series]'] = eventSeries;
        }

        if (eventID) {
            params['filters[events]'] = eventID;
        }

        if (dateRange === 1) {
            params.display_by = 'hourly';
        }

        if (soldBy) {
            params['filters[sold_by]'] = soldBy;
        }

        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: ticketsOverviewActions.START_FETCHING_TICKETSOVERVIEW_DATA,
                widgetId: id,
                params
            });
        }).catch(error => ({ error }));
    }

    formatWidgetData() {
        const { data } = this.props;

        const widgetData = {
            chart: {
                data: {
                    datasets: [
                        {
                            label: 'Revenue',
                            type: 'line',
                            fill: false,
                            backgroundColor: chartColors[1],
                            borderColor: chartColors[1],
                            data: [],
                            yAxisID: 'y-axis-2'
                        },
                        {
                            label: 'Tickets',
                            type: 'bar',
                            backgroundColor: chartColors[0],
                            data: [],
                            yAxisID: 'y-axis-1'
                        }
                    ]
                },
                options: {
                    responsive: true,
                    tooltips: {
                        mode: 'label',
                        callbacks: {
                            title(tooltipItem) {
                                if (tooltipItem && tooltipItem[0] && tooltipItem.label) {
                                    return tooltipItem[0].label;
                                }
                                return null;
                            },
                            label(tooltipItem, labelData) {
                                if (labelData.datasets) {
                                    const { label } = labelData.datasets[tooltipItem.datasetIndex];
                                    const value =
                                        labelData.datasets[tooltipItem.datasetIndex].data[
                                            tooltipItem.index
                                        ];
                                    return [`${label}: ${value.toLocaleString('en-US')}`];
                                }
                                return [];
                            }
                        }
                    },
                    elements: {
                        line: {
                            fill: false
                        }
                    },
                    scales: {
                        xAxes: [
                            {
                                display: true,
                                gridLines: {
                                    display: false
                                },
                                labels: []
                            }
                        ],
                        yAxes: [
                            {
                                type: 'linear',
                                display: true,
                                position: 'left',
                                id: 'y-axis-1',
                                gridLines: {
                                    display: false
                                },
                                labels: {
                                    show: true
                                },
                                ticks: {
                                    beginAtZero: true,
                                    // fontColor: chartColors[0],
                                    callback: value => kFormatter(value)
                                }
                            },
                            {
                                type: 'linear',
                                display: true,
                                position: 'right',
                                id: 'y-axis-2',
                                gridLines: {
                                    display: false
                                },
                                labels: {
                                    show: true
                                },
                                ticks: {
                                    beginAtZero: true,
                                    // fontColor: chartColors[1],
                                    callback: value => kFormatter(value)
                                }
                            }
                        ]
                    }
                }
            },
            totals: {
                revenue: {
                    value: (data.totals && data.totals.revenue) || 0,
                    currency: (data.totals && data.totals.revenue_currency) || 'USD'
                },
                tickets: (data.totals && data.totals.attendance) || 0
            }
        };

        if (data && data.data) {
            Object.keys(data.data).map(key => {
                const dateArray = key.split('-');
                widgetData.chart.options.scales.xAxes[0].labels.push(
                    `${dateArray[0]}-${dateArray[1]}-${dateArray[2]}`
                );
                widgetData.chart.data.datasets[1].data.push(data.data[key].attendance);
                widgetData.chart.data.datasets[0].data.push(
                    Math.round(data.data[key].revenue / 100)
                );

                return widgetData;
            });
        }

        return widgetData;
    }

    renderContent() {
        const widgetData = this.formatWidgetData();

        return (
            <div className="tickets-overview-widget__content">
                <div className="tickets-overview-widget__totals">
                    <p className="tickets-overview-widget__totals-item">
                        <FormattedMessage
                            id="TicketsOverview.TotalRevenue"
                            defaultMessage="Revenue"
                        />
                        :{' '}
                        <strong className="tickets-overview-widget__revenue">
                            {widgetData.totals.revenue.currency
                                ? ((style = 'currency') => {
                                      return (
                                          <FormattedNumber
                                              value={widgetData.totals.revenue.value / 100}
                                              currency={widgetData.totals.revenue.currency}
                                              style={style}
                                              minimumFractionDigits={0}
                                              maximumFractionDigits={0}
                                          />
                                      );
                                  })()
                                : widgetData.totals.revenue.value / 100}
                        </strong>
                    </p>
                    <p className="tickets-overview-widget__totals-item">
                        <FormattedMessage
                            id="TicketsOverview.TotalTickets"
                            defaultMessage="Tickets"
                        />
                        :{' '}
                        <strong className="tickets-overview-widget__tickets">
                            {widgetData.totals.tickets
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        </strong>
                    </p>
                </div>
                <div className="tickets-overview-widget__chart">
                    <BarChart data={widgetData.chart.data} options={widgetData.chart.options} />
                </div>
            </div>
        );
    }

    renderFilters() {
        return (
            <Field
                name="filter"
                id="filter"
                fieldProps={{
                    label: (
                        <FormattedMessage
                            id="TicketsOverview.Filter.DateRange"
                            defaultMessage="Date Range: "
                        />
                    ),
                    clearable: false,
                    options: [
                        {
                            label: (
                                <FormattedMessage
                                    id="TicketsOverview.Filter.DateRange.Last24h"
                                    defaultMessage="Last 24h"
                                />
                            ),
                            value: 1
                        },
                        {
                            label: (
                                <FormattedMessage
                                    id="TicketsOverview.Filter.DateRange.Last7Days"
                                    defaultMessage="Last 7 Days"
                                />
                            ),
                            value: 7
                        },
                        {
                            label: (
                                <FormattedMessage
                                    id="TicketsOverview.Filter.DateRange.Last30Days"
                                    defaultMessage="Last 30 Days"
                                />
                            ),
                            value: 30
                        },
                        {
                            label: (
                                <FormattedMessage
                                    id="TicketsOverview.Filter.DateRange.Last3Months"
                                    defaultMessage="Last 3 Months"
                                />
                            ),
                            value: 90
                        },
                        {
                            label: (
                                <FormattedMessage
                                    id="TicketsOverview.Filter.DateRange.Last6Months"
                                    defaultMessage="Last 6 Months"
                                />
                            ),
                            value: 180
                        },
                        {
                            label: (
                                <FormattedMessage
                                    id="TicketsOverview.Filter.DateRange.Last12Months"
                                    defaultMessage="Last 12 Months"
                                />
                            ),
                            value: 365
                        },
                        {
                            label: (
                                <FormattedMessage
                                    id="TicketsOverview.Filter.DateRange.AllTime"
                                    defaultMessage="All Time"
                                />
                            ),
                            value: Infinity
                        }
                    ]
                }}
                component={Select}
            />
        );
    }

    render() {
        const { size, fetching } = this.props;

        return (
            <Widget
                title={
                    <FormattedMessage
                        id="TicketsOverview.Title"
                        defaultMessage="Sales by Purchase Date"
                    />
                }
                filters={this.renderFilters()}
                size={size}
                className="tickets-overview-widget"
            >
                {fetching && <ElementLoader overlay />}
                {this.renderContent()}
            </Widget>
        );
    }
}

TicketsOverview.defaultProps = {
    size: null,
    fetching: false,
    data: {},
    filterValues: { filter: Infinity },
    setFormValue: () => {},
    dateRange: Infinity,
    eventSeries: null,
    eventID: null,
    soldBy: null
};

TicketsOverview.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    dispatch: PropTypes.func.isRequired,
    fetching: PropTypes.bool,
    data: PropTypes.oneOfType([PropTypes.object]),
    filterValues: PropTypes.oneOfType([PropTypes.object]),
    setFormValue: PropTypes.func,
    dateRange: PropTypes.number,
    eventSeries: PropTypes.string,
    eventID: PropTypes.string,
    soldBy: PropTypes.string
};

//  Getting initial value populated in the form from the store
const mapStateToProps = (state, ownProps) => {
    return {
        data: getData(state, ownProps.id),
        fetching: getFetching(state, ownProps.id),
        filterValues: getFormValues('TicketsOverviewForm')(state),
        initialValues: { filter: ownProps.dateRange }
    };
};

TicketsOverview = reduxForm(
    {
        form: 'TicketsOverviewForm'
    },
    mapStateToProps
)(TicketsOverview);

const mapDispatchToProps = dispatch => ({
    setFormValue: (field, value) => dispatch(change('TicketsOverviewForm', field, value))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TicketsOverview);
