import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import LayoutManager from 'erputils/LayoutManager';
import Listing from 'erpcomponents/Listing';
import PageHeader from 'erpcomponents/Layout/PageHeader';
import Button from 'erpcomponents/Button';
import { actions as listingActions } from 'erpcomponents/Listing/Listing.reducer';
import { getListingFetching, getListingResponse } from 'erpcomponents/Listing/Listing.selectors';
import ListingTableActions from 'erpcomponents/Listing/components/TableActions';
import Tooltip from 'erpcomponents/Tooltip';
import Svg from 'erpcomponents/Svg';
import CustomerDelete from 'erpcore/screens/Customers/components/CustomersDelete';

const reducerName = 'customers';

class CustomersListing extends Component {
    tableData() {
        const { customersData } = this.props;
        const table = {};
        table.data = [];

        // Advanced Filters
        table.filters = [
            {
                name: 'events.id',
                label: <FormattedMessage id="Customers.Table.Event.Label" defaultMessage="Event" />,
                defaultOperator: 'in',
                filterFields: {
                    value: {
                        component: 'multiautocomplete',
                        fieldProps: {
                            label: (
                                <FormattedMessage
                                    id="Customers.Table.Event"
                                    defaultMessage="Event"
                                />
                            ),
                            options: {
                                endpoint: `/api/events`,
                                mapData: {
                                    value: 'iri',
                                    label: 'name'
                                }
                            },
                            clearable: false
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            }
        ];
        // Table Schema
        table.schema = [
            {
                title: (
                    <FormattedMessage id="Customers.Table.Customers" defaultMessage="Customers" />
                ),
                field: 'name',
                sortable: 'firstName'
            },
            {
                title: <FormattedMessage id="Customers.Table.City" defaultMessage="City" />,
                field: 'city',
                sortable: false
            },
            {
                title: <FormattedMessage id="Customers.Table.Country" defaultMessage="Country" />,
                field: 'country',
                sortable: false
            },
            {
                title: (
                    <FormattedMessage
                        id="Customers.Table.PrimaryEmail"
                        defaultMessage="Primary Email"
                    />
                ),
                field: 'email',
                sortable: false
            },
            {
                title: (
                    <FormattedMessage
                        id="Customers.Table.PrimaryPhone"
                        defaultMessage="Primary Phone"
                    />
                ),
                field: 'phone_number',
                sortable: false
            },
            {
                title: <FormattedMessage id="Customers.Table.Orders" defaultMessage="Orders" />,
                field: 'orders',
                sortable: false
            },
            {
                title: <FormattedMessage id="Customers.Table.Actions" defaultMessage="Actions" />,
                field: 'actions',
                sortable: false
            }
        ];
        // Table Data
        customersData.data.map(row => {
            let cityName = null;
            let countryName = null;
            if (row.city && row.city.name) {
                cityName = row.city.name;
            }
            if (row.country && row.country.name) {
                countryName = row.country.name;
            }

            return table.data.push({
                id: row.id,
                name: `${row.last_name} ${row.first_name}`,
                city: cityName,
                country: countryName,
                email: row.email,
                phone_number: row.phone,
                orders: row.orders_number,
                actions: (
                    <ListingTableActions>
                        <ListingTableActions.Action>
                            <Tooltip
                                content={
                                    <FormattedMessage
                                        id="Customer.Table.Actions.Edit"
                                        defaultMessage="Edit Customer"
                                    />
                                }
                            >
                                <NavLink to={`/customers/${row.id}/edit`}>
                                    <Svg icon="edit" />
                                </NavLink>
                            </Tooltip>
                        </ListingTableActions.Action>
                        <ListingTableActions.Action>
                            <CustomerDelete id={row.id} key={`CustomerDelete ${row.id}`} />
                        </ListingTableActions.Action>
                    </ListingTableActions>
                )
            });
        });

        return table;
    }

    render() {
        const { customersListingFetching, customersData, fetchCustomers } = this.props;

        return (
            <LayoutManager slot="main" layoutType="merge">
                <PageHeader
                    title={
                        <FormattedMessage id="CustomersListing.title" defaultMessage="Customers" />
                    }
                >
                    <Button
                        href="/customers/create"
                        label={
                            <FormattedMessage
                                id="Customers.CreateCustomer"
                                defaultMessage="Create Customer"
                            />
                        }
                    />
                </PageHeader>
                <Listing
                    name={reducerName}
                    reducerName={reducerName}
                    loading={customersListingFetching}
                    meta={customersData.meta}
                    table={this.tableData()}
                    onListingConfigUpdate={params => fetchCustomers(params)}
                />
            </LayoutManager>
        );
    }
}

CustomersListing.defaultProps = {
    customersListingFetching: false,
    customersData: {}
};

CustomersListing.propTypes = {
    fetchCustomers: PropTypes.func.isRequired,
    customersListingFetching: PropTypes.bool,
    customersData: PropTypes.oneOfType([PropTypes.object])
};

//  Redux
const mapStateToProps = state => ({
    customersListingFetching: getListingFetching(state, 'customers'),
    customersData: getListingResponse(state, 'customers')
});

const mapDispatchToProps = dispatch => ({
    fetchCustomers: params => {
        // Getting included data from API by setting defaultParams
        const defaultParams = {
            include: 'city,state,country,event'
        };
        const listingParams = Object.assign({}, defaultParams, params);

        dispatch({
            type: listingActions.START_FETCHING_LISTING,
            params: listingParams,
            name: reducerName,
            entity: 'CUSTOMERS',
            endpoint: 'api/customers'
        });
    }
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(CustomersListing)
);
