import { getUrlParams } from 'erputils/utils';

export const actions = {
    START_FETCHING_NOTIFICATIONS: 'START_FETCHING_NOTIFICATIONS',
    FETCHING_SUCCESSFUL_NOTIFICATIONS: 'FETCHING_SUCCESSFUL_NOTIFICATIONS',
    FETCHING_FAILED_NOTIFICATIONS: 'FETCHING_FAILED_NOTIFICATIONS',

    MARK_NOTIFICATION_DONE: 'MARK_NOTIFICATION_DONE',
    MARK_NOTIFICATION_DONE_SUCCESSFUL: 'MARK_NOTIFICATION_DONE_SUCCESSFUL',
    MARK_NOTIFICATION_DONE_FAILED: 'MARK_NOTIFICATION_DONE_FAILED'
};

export const initialState = {
    listingFetching: false,
    listingData: [],
    listingMeta: {},
    listingError: [],
    singleFetching: false,
    notifications: []
};

export default (state = initialState, { type, response }) => {
    switch (type) {
        case actions.START_FETCHING_NOTIFICATIONS: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_NOTIFICATIONS: {
            const { page } = getUrlParams(response && response.links && response.links.self);

            return {
                ...state,
                listingFetching: false,
                listingResponse: response,
                notifications:
                    page && page > 1
                        ? [...state.notifications, ...response.data]
                        : [...response.data]
            };
        }
        case actions.FETCHING_FAILED_NOTIFICATIONS: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        case actions.MARK_NOTIFICATION_DONE: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.MARK_NOTIFICATION_DONE_SUCCESSFUL: {
            let updatedState = [...state.notifications];
            if (response && response.data && response.data.id && response.data.status) {
                // find the index of object from array that you want to update
                const objIndex = state.notifications.findIndex(obj => obj.id === response.data.id);

                // make new object of updated object.
                const updatedObj = {
                    ...state.notifications[objIndex],
                    status: response.data.status
                };

                // make final new array of objects by combining updated object.
                updatedState = [
                    ...state.notifications.slice(0, objIndex),
                    updatedObj,
                    ...state.notifications.slice(objIndex + 1)
                ];
            }
            return {
                ...state,
                singleFetching: false,
                notifications: [...updatedState]
            };
        }
        case actions.MARK_NOTIFICATION_DONE_FAILED: {
            return {
                ...state,
                singleFetching: false
            };
        }
        default:
            return state;
    }
};
