import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import LayoutManager from 'erputils/LayoutManager';
import PageHeader from 'erpcomponents/Layout/PageHeader';
import Listing from 'erpcomponents/Listing';
import ListingTableActions from 'erpcomponents/Listing/components/TableActions';
import Button from 'erpcomponents/Button';
import Svg from 'erpcomponents/Svg';
import Tooltip from 'erpcomponents/Tooltip';
import TableTemplateDelete from 'erpcore/screens/Venues/components/TableTemplateDelete';
import { actions as listingActions } from 'erpcomponents/Listing/Listing.reducer';
import { getListingFetching, getListingResponse } from 'erpcomponents/Listing/Listing.selectors';
import { getVenueName } from 'erpcore/screens/Venues/Venues.selectors';
// import { stringOperations } from 'erpdata/filterOperations';
import { actions as venuesActions } from 'erpcore/screens/Venues/Venues.reducer';
import LocationEditTabs from 'erpcore/screens/Venues/components/LocationEditTabs';

const reducerName = 'tableTemplates';

class TableTemplatesListing extends Component {
    componentDidMount() {
        const { fetchLocationData, venueName } = this.props;
        if (venueName === '') {
            fetchLocationData();
        }
    }

    tableData() {
        const { tableTemplatesData, match } = this.props;
        const table = {};
        table.data = [];

        // Table Schema
        table.schema = [
            {
                title: (
                    <FormattedMessage
                        id="TableTemplates.Table.Name"
                        defaultMessage="Template Name"
                    />
                ),
                field: 'name',
                sortable: 'name'
            },
            {
                title: (
                    <FormattedMessage id="TableTemplates.Table.Actions" defaultMessage="Actions" />
                ),
                field: 'actions'
            }
        ];

        tableTemplatesData.data.map(row => {
            return table.data.push({
                id: row.id,
                name: row.name,
                actions: (
                    <ListingTableActions>
                        <ListingTableActions.Action>
                            <Tooltip
                                content={
                                    <FormattedMessage
                                        id="TableTemplates.Table.Actions.Edit"
                                        defaultMessage="Edit Table Template"
                                    />
                                }
                            >
                                <NavLink to={`table-templates/${row.id}/edit`}>
                                    <Svg icon="edit" />
                                </NavLink>
                            </Tooltip>
                        </ListingTableActions.Action>
                        <ListingTableActions.Action>
                            <TableTemplateDelete
                                id={row.id}
                                locationId={match.params.id}
                                key={`TableTemplateDelete_${row.id}`}
                                form="TableTemplateDeleteForm"
                            />
                        </ListingTableActions.Action>
                    </ListingTableActions>
                )
            });
        });

        return table;
    }

    render() {
        const {
            tableTemplatesListingFetching,
            tableTemplatesData,
            fetchTableTemplates,
            venueName
        } = this.props;
        const venue = venueName && ` - ${venueName}`;
        return (
            <LayoutManager slot="main" className="main--narrow" layoutType="merge">
                <PageHeader
                    title={
                        <FormattedMessage
                            id="TableTemplates.title"
                            defaultMessage="Edit Venue {venue}"
                            values={{ venue }}
                        />
                    }
                    backButtonLink="/venues"
                >
                    <Button
                        href="table-templates/create"
                        label={
                            <FormattedMessage
                                id="TableTemplates.CreateNew"
                                defaultMessage="Add New Table Template"
                            />
                        }
                    />
                </PageHeader>
                <LocationEditTabs lessPadding />
                <Listing
                    name={reducerName}
                    reducerName={reducerName}
                    hideSearch
                    loading={tableTemplatesListingFetching}
                    meta={tableTemplatesData.meta}
                    table={this.tableData()}
                    onListingConfigUpdate={params => fetchTableTemplates(params)}
                />
            </LayoutManager>
        );
    }
}

TableTemplatesListing.defaultProps = {
    tableTemplatesListingFetching: false,
    tableTemplatesData: {},
    match: {},
    venueName: ''
};

TableTemplatesListing.propTypes = {
    fetchTableTemplates: PropTypes.func.isRequired,
    fetchLocationData: PropTypes.func.isRequired,
    tableTemplatesListingFetching: PropTypes.bool,
    tableTemplatesData: PropTypes.oneOfType([PropTypes.object]),
    match: PropTypes.oneOfType([PropTypes.object]),
    venueName: PropTypes.string
};

//  Redux
const mapStateToProps = state => ({
    tableTemplatesListingFetching: getListingFetching(state, 'tableTemplates'),
    tableTemplatesData: getListingResponse(state, 'tableTemplates'),
    venueName: getVenueName(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchTableTemplates: params => {
        // Getting included data from API by setting defaultParams
        const defaultParams = {
            include: 'location',
            'filters[locations.id][equals]': ownProps.match.params.id
        };
        const listingParams = Object.assign({}, defaultParams, params);
        dispatch({
            type: listingActions.START_FETCHING_LISTING,
            params: listingParams,
            name: reducerName,
            entity: 'TABLE_TEMPLATES',
            endpoint: 'api/table-templates'
        });
    },
    fetchLocationData: () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: venuesActions.START_FETCHING_SINGLE_VENUE,
                id: ownProps.match.params.id
            });
        }).catch(error => ({ error }));
    }
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(TableTemplatesListing)
);
