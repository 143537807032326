import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import { DoughnutChart } from 'erpcomponents/WidgetCharts';
import ElementLoader from 'erpcomponents/ElementLoader';
import Widget from 'erpcomponents/Widget';

import './TotalTickets24h.scss';

import { actions as totalTickets24h } from 'erpcomponents/Widgets/TotalTickets24h/TotalTickets24h.reducer';
import {
    getData,
    getFetching
} from 'erpcomponents/Widgets/TotalTickets24h/TotalTickets24h.selectors';

import { chartColors } from 'erpdata/colors';
import generateKey from 'erputils/generateKey';

class TotalTickets24h extends Component {
    componentDidMount() {
        const { eventSeries, eventID } = this.props;
        if (eventSeries || eventID) {
            const { id, sources, forDate, soldBy } = this.props;
            return this.fetchData({
                eventSeries,
                eventID,
                id,
                sources,
                forDate,
                soldBy
            });
        }
        return null;
    }

    componentWillReceiveProps(nextProps) {
        const {
            eventSeries: nextEventSeries,
            eventID: nextEventID,
            soldBy: nextSoldBy
        } = nextProps;
        const { eventSeries, eventID, soldBy } = this.props;

        let willFetchData = false;

        if (nextEventID !== eventID) {
            willFetchData = true;
        }

        if (nextEventSeries !== eventSeries) {
            willFetchData = true;
        }

        if (nextSoldBy !== soldBy) {
            willFetchData = true;
        }

        if (willFetchData) {
            const { id, sources, forDate } = nextProps;
            return this.fetchData({
                eventSeries: nextEventSeries,
                eventID: nextEventID,
                soldBy: nextSoldBy,
                id,
                sources,
                forDate
            });
        }

        return true;
    }

    fetchData({ eventSeries, eventID, id, sources, forDate, soldBy }) {
        const { dispatch } = this.props;
        const now = new Date();
        const yesterday = new Date(now.getTime() - 24 * 60 * 60 * 1000);

        const params = {
            'filters[start_date]': yesterday,
            'filters[end_date]': now,
            display_by: 'day',
            'filters[utc]': true
        };

        if (eventSeries) {
            params['filters[event_series]'] = eventSeries;
        }

        if (eventID) {
            params['filters[events]'] = eventID;
        }

        if (forDate) {
            params['filters[for_date]'] = forDate;
        }

        if (soldBy) {
            params['filters[sold_by]'] = soldBy;
        }

        sources.forEach(source => {
            const sourceParams = { ...params, ...{ 'filters[source]': source.value } };

            return new Promise((resolve, reject) => {
                dispatch({
                    promise: { resolve, reject },
                    type: totalTickets24h.START_FETCHING_TOTALTICKETS24H_DATA,
                    widgetId: id,
                    params: sourceParams,
                    source: source.value
                });
            }).catch(error => ({ error }));
        });
    }

    formatChartData() {
        const { sources, data } = this.props;

        const defaultValues = {
            labels: [],
            values: [],
            colors: [],
            revenueTotals: 0,
            currency: null
        };

        sources.forEach((source, index) => {
            if (data && data[source.value]) {
                defaultValues.labels.push(source.label);
                defaultValues.values.push(data[source.value].data.totals.attendance);
                defaultValues.colors.push(chartColors[index]);
                defaultValues.revenueTotals += data[source.value].data.totals.revenue;
                defaultValues.currency = data[source.value].data.totals.revenue_currency || 'USD';
            }
            return null;
        });

        const chartData = {
            labels: defaultValues.labels,
            revenueTotals: defaultValues.revenueTotals,
            currency: defaultValues.currency,
            datasets: [
                {
                    backgroundColor: defaultValues.colors,
                    data: defaultValues.values
                }
            ]
        };

        return chartData;
    }

    renderContent() {
        const chartData = this.formatChartData();
        const arraySum = array => array.reduce((a, b) => a + b, 0);
        let sum = 0;

        if (
            chartData &&
            chartData.datasets &&
            chartData.datasets[0] &&
            chartData.datasets[0].data
        ) {
            sum = arraySum(chartData.datasets[0].data);
        }

        return (
            <div className="total-tickets-24h-widget">
                <div className="total-tickets-24h-widget__wrap">
                    <DoughnutChart data={chartData} />
                    <div className="total-tickets-24h-widget__content">
                        <div className="total-tickets-24h-widget__data">
                            <span className="total-tickets-24h-widget__total">
                                {sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </span>
                            {chartData &&
                                chartData.labels &&
                                chartData.labels.map((label, index) => {
                                    const backgroundColor =
                                        chartData &&
                                        chartData.datasets &&
                                        chartData.datasets[0] &&
                                        chartData.datasets[0].backgroundColor &&
                                        chartData.datasets[0].backgroundColor[index];

                                    return (
                                        <div
                                            key={generateKey(label)}
                                            className="total-tickets-24h-widget__item"
                                        >
                                            <span
                                                style={{ backgroundColor }}
                                                className="total-tickets-24h-widget__item-icon"
                                            />
                                            <span className="total-tickets-24h-widget__item-label">
                                                {label}
                                            </span>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                </div>

                <React.Fragment>
                    <p className="total-tickets-24h-widget__title">
                        <FormattedMessage id="TotalTickets24h.Revenue" defaultMessage="Revenue" />
                    </p>
                    <p className="total-tickets-24h-widget__statistic-value">
                        {((style = 'currency') => {
                            return (
                                <FormattedNumber
                                    value={Math.round(chartData.revenueTotals / 100)}
                                    style={style}
                                    currency={chartData.currency}
                                />
                            );
                        })()}
                    </p>
                </React.Fragment>
            </div>
        );
    }

    render() {
        const { size, fetching } = this.props;

        return (
            <Widget
                title={
                    <FormattedMessage
                        id="TotalTickets24h.Title"
                        defaultMessage="Total Tickets (24h)"
                    />
                }
                size={size}
            >
                {fetching ? <ElementLoader /> : this.renderContent()}
            </Widget>
        );
    }
}

TotalTickets24h.defaultProps = {
    eventSeries: null,
    soldBy: null,
    eventID: null,
    size: null,
    sources: [
        {
            label: 'TicketSocket',
            value: 'ticketsocket'
        },
        {
            label: 'GetOut',
            value: 'get_out'
        }
    ],
    fetching: false,
    data: {},
    forDate: null
};

TotalTickets24h.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    eventSeries: PropTypes.string,
    eventID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    dispatch: PropTypes.func.isRequired,
    sources: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    fetching: PropTypes.bool,
    data: PropTypes.oneOfType([PropTypes.object]),
    forDate: PropTypes.string,
    soldBy: PropTypes.string
};

//  Getting initial value populated in the form from the store
const mapStateToProps = (state, ownProps) => {
    return {
        data: getData(state, ownProps.id),
        fetching: getFetching(state, ownProps.id)
    };
};

export default connect(mapStateToProps)(TotalTickets24h);
