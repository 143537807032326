import { put, takeLatest, call } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import dto from 'erputils/dto';
import { actions as notificationManagerActions } from 'erputils/NotificationManager/NotificationManager.reducer';
import { actions as activityLogActions } from './ActivityLog.reducer';

/**
 * Fetch Activity Log Saga
 * @param  {Object} params
 * @return {Object} Response from API
 */
export function* fetchActivityLog({ promise, entity, params, id }) {
    try {
        const fetchActivityLogAPI = yield restClient.get(`api/${entity}/${id}/audit`, {
            params
        });
        yield put({
            type: activityLogActions.FETCHING_SUCCESSFUL_ACTIVITY_LOGS
        });
        yield put({
            type: activityLogActions.STORE_ACTIVITY_LOG_DATA,
            id,
            entity,
            response: dto(fetchActivityLogAPI.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: activityLogActions.FETCHING_FAILED_ACTIVITY_LOGS
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Register action to watcher
 */
export const activityLogSaga = [
    takeLatest(activityLogActions.START_FETCHING_ACTIVITY_LOGS, fetchActivityLog)
];
