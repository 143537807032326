import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Notification from 'erpcomponents/Notification';
import { actions as notificationManagerActions } from 'erputils/NotificationManager/NotificationManager.reducer';

/**
 * Notification Manager
 * @return {Node} Returns Notification component
 */
const NotificationManager = ({
    code,
    data, // eslint-disable-line no-unused-vars
    clearNotificationManagerPageNotification,
    onRemoveFloatingNotification
}) => {
    switch (code) {
        case 'error404':
            return (
                <Notification
                    key="error404"
                    text={
                        <FormattedMessage
                            id="Notification.error404.text"
                            defaultMessage="404 (Not Found). Please try to refresh the page."
                        />
                    }
                    title={
                        <FormattedMessage id="Notification.error404.title" defaultMessage="Error" />
                    }
                    type="error"
                />
            );
        case 'error405':
            return (
                <Notification
                    key="error405"
                    text={
                        <FormattedMessage
                            id="Notification.error405.text"
                            defaultMessage="405 (Method Not Allowed). Please try to refresh the page."
                        />
                    }
                    title={
                        <FormattedMessage id="Notification.error405.title" defaultMessage="Error" />
                    }
                    type="error"
                />
            );
        case 'error500':
            return (
                <Notification
                    key="error500"
                    text={
                        <FormattedMessage
                            id="Notification.error500.text"
                            defaultMessage="500 (Internal Server Error). Please try to refresh the page."
                        />
                    }
                    title={
                        <FormattedMessage id="Notification.error500.title" defaultMessage="Error" />
                    }
                    type="error"
                />
            );
        case 'expiredJWT':
            return (
                <Notification
                    key="expiredJWT"
                    text={
                        <FormattedMessage
                            id="Notification.expiredJWT.text"
                            defaultMessage="Sorry, your session expired."
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.expiredJWT.title"
                            defaultMessage="Please Sign In!"
                        />
                    }
                    type="warning"
                    expire={10000}
                />
            );
        case 'invalidJWT':
            return (
                <Notification
                    key="invalidJWT"
                    text={
                        <FormattedMessage
                            id="Notification.invalidJWT.text"
                            defaultMessage="Sorry, your session expired."
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.invalidJWT.title"
                            defaultMessage="Please Sign In!"
                        />
                    }
                    type="warning"
                    expire={10000}
                />
            );
        case 'missingJWT':
            return true;

        case 'errorCustom':
            // eslint-disable-next-line no-case-declarations
            const errorCustomText = data && data.customText ? data.customText : '';
            // eslint-disable-next-line no-case-declarations
            const errorCustomTitle =
                data && data.customTitle ? (
                    data.customTitle
                ) : (
                    <FormattedMessage id="Notification.errorCustom.title" defaultMessage="Error!" />
                );
            return (
                <Notification
                    key="errorCustom"
                    text={errorCustomText}
                    title={errorCustomTitle}
                    type="error"
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'listingNoData':
            return (
                <Notification
                    key="listingNoData"
                    text={
                        <FormattedMessage
                            id="Notification.listingNoData.text"
                            defaultMessage="There are no results to display!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.listingNoData.title"
                            defaultMessage="No Results"
                        />
                    }
                    type="info"
                />
            );

        case 'authenticationFailure':
            return (
                <Notification
                    key="authenticationFailure"
                    text={
                        <FormattedMessage
                            id="Notification.authenticationFailure.text"
                            defaultMessage="Invalid username or wrong password. Please try again."
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.authenticationFailure.title"
                            defaultMessage="Error"
                        />
                    }
                    type="error"
                />
            );
        case 'userNotFound':
            return (
                <Notification
                    key="userNotFound"
                    text={
                        <FormattedMessage
                            id="Notification.userNotFound.text"
                            defaultMessage="User cannot be found using provided email address!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.userNotFound.title"
                            defaultMessage="Error"
                        />
                    }
                    type="error"
                />
            );
        //  My Account
        case 'user.missingNewPassword':
            return (
                <Notification
                    key="user.missingNewPassword"
                    text={
                        <FormattedMessage
                            id="Notification.user.missingNewPassword.text"
                            defaultMessage="Please enter new password!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.user.missingNewPassword.title"
                            defaultMessage="Error"
                        />
                    }
                    type="error"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'user.invalidOldPassword':
            return (
                <Notification
                    key="user.missingNewPassword"
                    text={
                        <FormattedMessage
                            id="Notification.user.invalidOldPassword.text"
                            defaultMessage="Invalid old password!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.user.invalidOldPassword.title"
                            defaultMessage="Error"
                        />
                    }
                    type="error"
                />
            );
        case 'user.ownerSuccessfullyChangePassword':
            return (
                <Notification
                    key="user.ownerSuccessfullyChangePassword"
                    text={
                        <FormattedMessage
                            id="Notification.user.ownerSuccessfullyChangePassword.text"
                            defaultMessage="Password changed successfuly!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.user.ownerSuccessfullyChangePassword.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'user.ownerNewPassordConfirmationWrongMatch':
            return (
                <Notification
                    key="user.ownerNewPassordConfirmationWrongMatch"
                    text={
                        <FormattedMessage
                            id="Notification.user.ownerNewPassordConfirmationWrongMatch.text"
                            defaultMessage="New password confirmation does not match!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.user.ownerNewPassordConfirmationWrongMatch.title"
                            defaultMessage="Error"
                        />
                    }
                    type="error"
                />
            );
        // Forgot Password
        case 'forgotPassword.emailSent':
            return (
                <Notification
                    key="forgotPassword.emailSent"
                    text={
                        <FormattedMessage
                            id="Notification.forgotPassword.emailSent.text"
                            defaultMessage="Email with further instructions was sent to your inbox."
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.forgotPassword.emailSent.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                />
            );
        case 'forgotPassword.userNotFound':
            return (
                <Notification
                    key="forgotPassword.userNotFound"
                    text={
                        <FormattedMessage
                            id="Notification.forgotPassword.userNotFound.text"
                            defaultMessage="Sorry, we don't have a user with that email address in the database. Have you made a mistake?"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.forgotPassword.userNotFound.title"
                            defaultMessage="Warning"
                        />
                    }
                    type="warning"
                />
            );
        case 'forgotPassword.emailSendError':
            return (
                <Notification
                    key="forgotPassword.emailSendError"
                    text={
                        <FormattedMessage
                            id="Notification.forgotPassword.emailSendError.text"
                            defaultMessage="Something went wrong, please refresh the page and try again."
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.forgotPassword.emailSendError.title"
                            defaultMessage="Error"
                        />
                    }
                    type="error"
                />
            );
        case 'confirmForgotPassword.passwordChanged':
            return (
                <Notification
                    key="confirmForgotPassword.passwordChanged"
                    text={
                        <FormattedMessage
                            id="Notification.confirmForgotPassword.passwordChanged.text"
                            defaultMessage="Sign in Using Your New Password."
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.confirmForgotPassword.passwordChanged.title"
                            defaultMessage="New Password Successfully Saved"
                        />
                    }
                    type="success"
                />
            );
        case 'confirmForgotPassword.notFound':
            return (
                <Notification
                    key="confirmForgotPassword.notFound"
                    text={
                        <FormattedMessage
                            id="Notification.confirmForgotPassword.notFound.text"
                            defaultMessage="This token has been already used. Please start the process of resetting password from the start."
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.confirmForgotPassword.notFound.title"
                            defaultMessage="Error"
                        />
                    }
                    type="error"
                />
            );
        case 'confirmForgotPassword.expiredResetToken':
            return (
                <Notification
                    key="confirmForgotPassword.expiredResetToken"
                    text={
                        <FormattedMessage
                            id="Notification.confirmForgotPassword.expiredResetToken.text"
                            defaultMessage="Reset token has expired! Please start the process of resetting password from the start."
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.confirmForgotPassword.expiredResetToken.title"
                            defaultMessage="Error"
                        />
                    }
                    type="error"
                />
            );
        // Users
        // Invitation
        case 'user.invitationSent':
            return (
                <Notification
                    key="user.invitationSent"
                    text={
                        <FormattedMessage
                            id="Notification.user.invitationSent.text"
                            defaultMessage="User has been invited!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.user.invitationSent.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'invitation.notFound':
            return (
                <Notification
                    key="invitation.notFound"
                    text={
                        <FormattedMessage
                            id="Notification.invitation.notFound.text"
                            defaultMessage="This invitation is expired, declined or already used!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.invitation.notFound.title"
                            defaultMessage="Error"
                        />
                    }
                    type="error"
                />
            );
        case 'user.createdSuccessfuly':
            return (
                <Notification
                    key="user.createdSuccessfuly"
                    text={
                        <FormattedMessage
                            id="Notification.user.createdSuccessfuly.text"
                            defaultMessage="Welcome!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.user.createdSuccessfuly.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                />
            );
        case 'user.adminSuccessfullyChangePassword':
            return (
                <Notification
                    key="user.adminSuccessfullyChangePassword"
                    text={
                        <FormattedMessage
                            id="Notification.user.adminSuccessfullyChangePassword.text"
                            defaultMessage="User Password successfully updated!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.user.adminSuccessfullyChangePassword.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'user.alreadyExists':
            return (
                <Notification
                    key="user.alreadyExists"
                    text={
                        <FormattedMessage
                            id="Notification.user.alreadyExists.text"
                            defaultMessage="User is already registered using provided email address!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.user.alreadyExists.title"
                            defaultMessage="Error"
                        />
                    }
                    type="error"
                />
            );
        case 'user.itemSuccessfulyUpdated':
            return (
                <Notification
                    key="user.itemSuccessfulyUpdated"
                    text={
                        <FormattedMessage
                            id="Notification.user.itemSuccessfulyUpdated.text"
                            defaultMessage="User successfully updated!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.user.itemSuccessfulyUpdated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'user.currentUserSuccessfulyUpdated':
            return (
                <Notification
                    key="user.currentUserSuccessfulyUpdated"
                    text={
                        <FormattedMessage
                            id="Notification.user.currentUserSuccessfulyUpdated.text"
                            defaultMessage="Profile Details successfully updated!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.user.currentUserSuccessfulyUpdated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'user.itemSuccessfulyRemoved':
            return (
                <Notification
                    key="user.itemSuccessfulyRemoved"
                    text={
                        <FormattedMessage
                            id="Notification.user.itemSuccessfulyRemoved.text"
                            defaultMessage="User successfully deleted!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.user.itemSuccessfulyRemoved.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'user.invitationResent':
            return (
                <Notification
                    key="user.invitationResent"
                    text={
                        <FormattedMessage
                            id="Notification.user.invitationResent.text"
                            defaultMessage="Invitation successfully resent!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.user.itemSuccessfulyRemoved.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                />
            );
        // Company
        case 'company.itemSuccessfulyRemoved':
            return (
                <Notification
                    key="company.itemSuccessfulyRemoved"
                    text={
                        <FormattedMessage
                            id="Notification.company.itemSuccessfulyRemoved.text"
                            defaultMessage="Company successfully deleted!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.company.itemSuccessfulyRemoved.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'company.itemSuccessfulyCreated':
            return (
                <Notification
                    key="company.itemSuccessfulyCreated"
                    text={
                        <FormattedMessage
                            id="Notification.company.itemSuccessfulyCreated.text"
                            defaultMessage="Company successfully created!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.company.itemSuccessfulyCreated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'company.itemSuccessfulyUpdated':
            return (
                <Notification
                    key="company.itemSuccessfulyUpdated"
                    text={
                        <FormattedMessage
                            id="Notification.company.itemSuccessfulyUpdated.text"
                            defaultMessage="Company successfully updated!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.company.itemSuccessfulyUpdated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        // Venues/Locations
        case 'location.itemSuccessfulyCreated':
            return (
                <Notification
                    key="location.itemSuccessfulyCreated"
                    text={
                        <FormattedMessage
                            id="Notification.location.itemSuccessfulyCreated.text"
                            defaultMessage="Venue successfully created!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.location.itemSuccessfulyCreated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'location.itemSuccessfulyUpdated':
            return (
                <Notification
                    key="location.itemSuccessfulyUpdated"
                    text={
                        <FormattedMessage
                            id="Notification.location.itemSuccessfulyUpdated.text"
                            defaultMessage="Venue successfully updated!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.location.itemSuccessfulyUpdated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'location.itemSuccessfulyRemoved':
            return (
                <Notification
                    key="location.itemSuccessfulyRemoved"
                    text={
                        <FormattedMessage
                            id="Notification.location.itemSuccessfulyRemoved.text"
                            defaultMessage="Venue successfully deleted!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.location.itemSuccessfulyRemoved.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        // Customers
        case 'customer.itemSuccessfulyCreated':
            return (
                <Notification
                    key="customer.itemSuccessfulyCreated "
                    text={
                        <FormattedMessage
                            id="Notification.customer.itemSuccessfulyCreated.text"
                            defaultMessage="Customer successfully created"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.customer.itemSuccessfulyCreated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'customer.itemSuccessfulyRemoved':
            return (
                <Notification
                    key="customer.itemSuccessfulyRemoved "
                    text={
                        <FormattedMessage
                            id="Notification.customer.itemSuccessfulyRemoved.text"
                            defaultMessage="Customer successfully removed"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.customer.itemSuccessfulyRemoved.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'customer.itemSuccessfulyUpdated':
            return (
                <Notification
                    key="customer.itemSuccessfulyUpdated"
                    text={
                        <FormattedMessage
                            id="Notification.customer.itemSuccessfulyUpdated.text"
                            defaultMessage="Customer successfully updated!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.customer.itemSuccessfulyUpdated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        // Events
        case 'event.itemSuccessfulyCreated':
            return (
                <Notification
                    key="event.itemSuccessfulyCreated"
                    text={
                        <FormattedMessage
                            id="Notification.event.itemSuccessfulyCreated.text"
                            defaultMessage="Event successfully created!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.event.itemSuccessfulyCreated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'event.itemSuccessfulyUpdated':
            return (
                <Notification
                    key="event.itemSuccessfulyUpdated"
                    text={
                        <FormattedMessage
                            id="Notification.event.itemSuccessfulyUpdated.text"
                            defaultMessage="Event successfully updated!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.event.itemSuccessfulyUpdated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'event.itemSuccessfulyRemoved':
            return (
                <Notification
                    key="event.itemSuccessfulyRemoved"
                    text={
                        <FormattedMessage
                            id="Notification.event.itemSuccessfulyRemoved.text"
                            defaultMessage="Event successfully deleted!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.event.itemSuccessfulyRemoved.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'event.collectionSuccessfullyUpdated':
            return (
                <Notification
                    key="event.collectionSuccessfullyUpdated"
                    text={
                        <FormattedMessage
                            id="Notification.event.collectionSuccessfullyUpdated.text"
                            defaultMessage="Sort order successfully updated!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.event.collectionSuccessfullyUpdated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="5500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        // Groups
        case 'group.itemSuccessfulyCreated':
            return (
                <Notification
                    key="group.itemSuccessfulyCreated"
                    text={
                        <FormattedMessage
                            id="Notification.group.itemSuccessfulyCreated.text"
                            defaultMessage="Group successfully created!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.group.itemSuccessfulyCreated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'group.itemSuccessfulyUpdated':
            return (
                <Notification
                    key="group.itemSuccessfulyUpdated"
                    text={
                        <FormattedMessage
                            id="Notification.group.itemSuccessfulyUpdated.text"
                            defaultMessage="Group successfully updated!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.group.itemSuccessfulyUpdated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'group.itemSuccessfulyRemoved':
            return (
                <Notification
                    key="group.itemSuccessfulyRemoved"
                    text={
                        <FormattedMessage
                            id="Notification.group.itemSuccessfulyRemoved.text"
                            defaultMessage="Group successfully deleted!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.group.itemSuccessfulyRemoved.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'group.itemSplitError':
            return (
                <Notification
                    key="group.itemSplitError"
                    text={
                        <FormattedMessage
                            id="Notification.group.itemSplitError.text"
                            defaultMessage="{message}"
                            values={{ message: data && data.message && data.message }}
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.group.itemSplitError.title"
                            defaultMessage="Error"
                        />
                    }
                    type="error"
                    expire="5000"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'groups.itemSuccessfulyRemoved':
            return (
                <Notification
                    key="groups.itemSuccessfulyRemoved"
                    text={
                        <FormattedMessage
                            id="Notification.groups.itemSuccessfulyRemoved.text"
                            defaultMessage="Selected Groups successfully deleted!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.groups.itemSuccessfulyRemoved.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'group.mergeError':
            return (
                <Notification
                    key="group.mergeError"
                    text={
                        <FormattedMessage
                            id="Notification.group.mergeError.text"
                            defaultMessage="{message}"
                            values={{ message: data && data.message && data.message }}
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.group.mergeError.title"
                            defaultMessage="Error"
                        />
                    }
                    type="error"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        // Ticket Types
        case 'tickettype.itemSuccessfulyCreated':
            return (
                <Notification
                    key="tickettype.itemSuccessfulyCreated"
                    text={
                        <FormattedMessage
                            id="Notification.tickettype.itemSuccessfulyCreated.text"
                            defaultMessage="Ticket Type {name} successfully created!"
                            values={{
                                name: (
                                    <strong>
                                        {data && data.name !== undefined && data.name
                                            ? data.name
                                            : ''}
                                    </strong>
                                )
                            }}
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.tickettype.itemSuccessfulyCreated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="5500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'tickettype.itemSuccessfulyUpdated':
            return (
                <Notification
                    key="tickettype.itemSuccessfulyUpdated"
                    text={
                        <FormattedMessage
                            id="Notification.tickettype.itemSuccessfulyUpdated.text"
                            defaultMessage="Ticket Type {name} successfully updated!"
                            values={{
                                name: (
                                    <strong>
                                        {data && data.name !== undefined && data.name
                                            ? data.name
                                            : ''}
                                    </strong>
                                )
                            }}
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.tickettype.itemSuccessfulyUpdated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="5500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'tickettype.itemSuccessfulyRemoved':
            return (
                <Notification
                    key="tickettype.itemSuccessfulyRemoved"
                    text={
                        <FormattedMessage
                            id="Notification.tickettype.itemSuccessfulyRemoved.text"
                            defaultMessage="Ticket Type successfully deleted!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.tickettype.itemSuccessfulyRemoved.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="5500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'tickettype.sortSuccessfulyUpdated':
            return (
                <Notification
                    key="tickettype.sortSuccessfulyUpdated"
                    text={
                        <FormattedMessage
                            id="Notification.tickettype.sortSuccessfulyUpdated.text"
                            defaultMessage="Ticket Type order successfully updated!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.tickettype.sortSuccessfulyUpdated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="5500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        // Table templates
        case 'tabletemplate.itemSuccessfulyCreated':
            return (
                <Notification
                    key="tabletemplate.itemSuccessfulyCreated"
                    text={
                        <FormattedMessage
                            id="Notification.tabletemplate.itemSuccessfulyCreated.text"
                            defaultMessage="Table Template successfully created!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.tabletemplate.itemSuccessfulyCreated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'tabletemplate.itemSuccessfulyUpdated':
            return (
                <Notification
                    key="tabletemplate.itemSuccessfulyUpdated"
                    text={
                        <FormattedMessage
                            id="Notification.tabletemplate.itemSuccessfulyUpdated.text"
                            defaultMessage="Table Template successfully updated!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.tabletemplate.itemSuccessfulyUpdated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'tabletemplate.itemSuccessfulyRemoved':
            return (
                <Notification
                    key="tabletemplate.itemSuccessfulyRemoved"
                    text={
                        <FormattedMessage
                            id="Notification.tabletemplate.itemSuccessfulyRemoved.text"
                            defaultMessage="Table Template successfully deleted!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.tabletemplate.itemSuccessfulyRemoved.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'table.itemSuccessfulyCreated':
            return (
                <Notification
                    key="table.itemSuccessfulyCreated"
                    text={
                        <FormattedMessage
                            id="Notification.table.itemSuccessfulyCreated.text"
                            defaultMessage="Table successfully created!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.table.itemSuccessfulyCreated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'table.itemSuccessfulyUpdated':
            return (
                <Notification
                    key="table.itemSuccessfulyUpdated"
                    text={
                        <FormattedMessage
                            id="Notification.table.itemSuccessfulyUpdated.text"
                            defaultMessage="Table successfully updated!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.table.itemSuccessfulyUpdated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'table.itemSuccessfulyRemoved':
            return (
                <Notification
                    key="table.itemSuccessfulyRemoved"
                    text={
                        <FormattedMessage
                            id="Notification.table.itemSuccessfulyRemoved.text"
                            defaultMessage="Table successfully deleted!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.table.itemSuccessfulyRemoved.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'tables.eventDoesNotHaveAnLocation':
            return (
                <Notification
                    key="table.eventDoesNotHaveAnLocation"
                    text={
                        <FormattedMessage
                            id="Notification.table.eventDoesNotHaveAnLocation.text"
                            defaultMessage="Event does not have related location, can not link tables with rooms to missing location."
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.table.eventDoesNotHaveAnLocation.title"
                            defaultMessage="Error!"
                        />
                    }
                    type="error"
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'room.itemSuccessfulyCreated':
            return (
                <Notification
                    key="room.itemSuccessfulyCreated"
                    text={
                        <FormattedMessage
                            id="Notification.Room.itemSuccessfulyCreated.text"
                            defaultMessage="Room successfully created!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.Room.itemSuccessfulyCreated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'room.itemSuccessfulyUpdated':
            return (
                <Notification
                    key="room.itemSuccessfulyUpdated"
                    text={
                        <FormattedMessage
                            id="Notification.Room.itemSuccessfulyUpdated.text"
                            defaultMessage="Room successfully updated!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.Room.itemSuccessfulyUpdated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'room.itemSuccessfulyRemoved':
            return (
                <Notification
                    key="room.itemSuccessfulyRemoved"
                    text={
                        <FormattedMessage
                            id="Notification.Room.itemSuccessfulyRemoved.text"
                            defaultMessage="Room successfully deleted!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.Room.itemSuccessfulyRemoved.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        // header menus
        case 'headermenu.itemSuccessfulyCreated':
            return (
                <Notification
                    key="headermenu.itemSuccessfulyCreated"
                    text={
                        <FormattedMessage
                            id="Notification.headermenu.itemSuccessfulyCreated.text"
                            defaultMessage="Header Menu successfully created!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.headermenu.itemSuccessfulyCreated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'headermenu.itemSuccessfulyUpdated':
            return (
                <Notification
                    key="headermenu.itemSuccessfulyUpdated"
                    text={
                        <FormattedMessage
                            id="Notification.headermenu.itemSuccessfulyUpdated.text"
                            defaultMessage="Header Menu successfully updated!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.headermenu.itemSuccessfulyUpdated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'headermenu.itemSuccessfulyRemoved':
            return (
                <Notification
                    key="headermenu.itemSuccessfulyRemoved"
                    text={
                        <FormattedMessage
                            id="Notification.headermenu.itemSuccessfulyRemoved.text"
                            defaultMessage="Header Menu successfully deleted!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.headermenu.itemSuccessfulyRemoved.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'resellerfilter.itemSuccessfulyCreated':
            return (
                <Notification
                    key="resellerfilter.itemSuccessfulyCreated"
                    text={
                        <FormattedMessage
                            id="Notification.resellerfilter.itemSuccessfulyCreated.text"
                            defaultMessage="Reseller Filter successfully created!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.resellerfilter.itemSuccessfulyCreated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'resellerfilter.itemSuccessfulyUpdated':
            return (
                <Notification
                    key="resellerfilter.itemSuccessfulyUpdated"
                    text={
                        <FormattedMessage
                            id="Notification.resellerfilter.itemSuccessfulyUpdated.text"
                            defaultMessage="Reseller Filter successfully updated!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.resellerfilter.itemSuccessfulyUpdated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'resellerfilter.itemSuccessfulyRemoved':
            return (
                <Notification
                    key="resellerfilter.itemSuccessfulyRemoved"
                    text={
                        <FormattedMessage
                            id="Notification.resellerfilter.itemSuccessfulyRemoved.text"
                            defaultMessage="Reseller Filter successfully deleted!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.resellerfilter.itemSuccessfulyRemoved.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'homepageslider.itemSuccessfulyCreated':
            return (
                <Notification
                    key="homepageslider.itemSuccessfulyCreated"
                    text={
                        <FormattedMessage
                            id="Notification.homepageslider.itemSuccessfulyCreated.text"
                            defaultMessage="Homepage Slider successfully created!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.homepageslider.itemSuccessfulyCreated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'homepageslider.itemSuccessfulyUpdated':
            return (
                <Notification
                    key="homepageslider.itemSuccessfulyUpdated"
                    text={
                        <FormattedMessage
                            id="Notification.homepageslider.itemSuccessfulyUpdated.text"
                            defaultMessage="Homepage Slider successfully updated!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.homepageslider.itemSuccessfulyUpdated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'homepageslider.itemSuccessfulyRemoved':
            return (
                <Notification
                    key="homepageslider.itemSuccessfulyRemoved"
                    text={
                        <FormattedMessage
                            id="Notification.homepageslider.itemSuccessfulyRemoved.text"
                            defaultMessage="Homepage Slider successfully deleted!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.homepageslider.itemSuccessfulyRemoved.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'resellereventssort.itemSuccessfulyUpdated':
            return (
                <Notification
                    key="resellereventssort.itemSuccessfulyUpdated"
                    text={
                        <FormattedMessage
                            id="Notification.resellereventssort.itemSuccessfulyUpdated.text"
                            defaultMessage="Events Order successfully updated!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.resellereventssort.itemSuccessfulyUpdated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        // Vendors
        case 'vendor.itemSuccessfulyRemoved':
            return (
                <Notification
                    key="vendor.itemSuccessfulyRemoved"
                    text={
                        <FormattedMessage
                            id="Notification.vendor.itemSuccessfulyRemoved.text"
                            defaultMessage="Vendor successfully deleted!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.vendor.itemSuccessfulyRemoved.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        // Resellers
        case 'reseller.itemSuccessfulyCreated':
            return (
                <Notification
                    key="reseller.itemSuccessfulyCreated"
                    text={
                        <FormattedMessage
                            id="Notification.reseller.itemSuccessfulyCreated.text"
                            defaultMessage="Reseller successfully created!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.reseller.itemSuccessfulyCreated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                />
            );
        case 'reseller.itemSuccessfulyUpdated':
            return (
                <Notification
                    key="reseller.itemSuccessfulyUpdated"
                    text={
                        <FormattedMessage
                            id="Notification.reseller.itemSuccessfulyUpdated.text"
                            defaultMessage="Reseller successfully updated!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.reseller.itemSuccessfulyUpdated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                />
            );
        case 'reseller.itemSuccessfulyRemoved':
            return (
                <Notification
                    key="reseller.itemSuccessfulyRemoved"
                    text={
                        <FormattedMessage
                            id="Notification.reseller.itemSuccessfulyRemoved.text"
                            defaultMessage="Reseller successfully deleted!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.reseller.itemSuccessfulyRemoved.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                />
            );
        case 'reseller.batchUpdateSuccessful':
            return (
                <Notification
                    key="reseller.batchUpdateSuccessful"
                    text={
                        <FormattedMessage
                            id="Notification.reseller.batchUpdateSuccessful.text"
                            defaultMessage="Bulk update successful!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.reseller.batchUpdateSuccessful.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        // EventCategories
        case 'eventcategory.itemSuccessfulyCreated':
            return (
                <Notification
                    key="eventcategory.itemSuccessfulyCreated "
                    text={
                        <FormattedMessage
                            id="Notification.eventcategory.itemSuccessfulyCreated.text"
                            defaultMessage="Event Category successfully created"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.eventcategory.itemSuccessfulyCreated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'eventcategory.itemSuccessfulyRemoved':
            return (
                <Notification
                    key="eventcategory.itemSuccessfulyRemoved"
                    text={
                        <FormattedMessage
                            id="Notification.eventCategory.itemSuccessfulyRemoved.text"
                            defaultMessage="Event Category successfully removed"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.eventCategory.itemSuccessfulyRemoved.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'eventcategory.itemSuccessfulyUpdated':
            return (
                <Notification
                    key="eventcategory.itemSuccessfulyUpdated"
                    text={
                        <FormattedMessage
                            id="Notification.eventCategory.itemSuccessfulyUpdated.text"
                            defaultMessage="Event Category successfully updated"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.eventCategory.itemSuccessfulyUpdated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        // EventSeries
        case 'eventseries.itemSuccessfulyCreated':
            return (
                <Notification
                    key="eventseries.itemSuccessfulyCreated "
                    text={
                        <FormattedMessage
                            id="Notification.eventseries.itemSuccessfulyCreated.text"
                            defaultMessage="Event Series successfully created"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.eventseries.itemSuccessfulyCreated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'eventseries.itemSuccessfulyRemoved':
            return (
                <Notification
                    key="eventseries.itemSuccessfulyRemoved"
                    text={
                        <FormattedMessage
                            id="Notification.eventseries.itemSuccessfulyRemoved.text"
                            defaultMessage="Event Series successfully removed"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.eventseries.itemSuccessfulyRemoved.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'eventseries.itemSuccessfulyUpdated':
            return (
                <Notification
                    key="eventseries.itemSuccessfulyUpdated"
                    text={
                        <FormattedMessage
                            id="Notification.eventseries.itemSuccessfulyUpdated.text"
                            defaultMessage="Event Series successfully updated"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.eventseries.itemSuccessfulyUpdated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        // AgeRequirements
        case 'agerequirement.itemSuccessfulyCreated':
            return (
                <Notification
                    key="agerequirement.itemSuccessfulyCreated "
                    text={
                        <FormattedMessage
                            id="Notification.agerequirement.itemSuccessfulyCreated.text"
                            defaultMessage="Age Requirement successfully created"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.agerequirement.itemSuccessfulyCreated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'agerequirement.itemSuccessfulyRemoved':
            return (
                <Notification
                    key="agerequirement.itemSuccessfulyRemoved"
                    text={
                        <FormattedMessage
                            id="Notification.agerequirement.itemSuccessfulyRemoved.text"
                            defaultMessage="Age Requirement successfully removed"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.agerequirement.itemSuccessfulyRemoved.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'agerequirement.itemSuccessfulyUpdated':
            return (
                <Notification
                    key="agerequirement.itemSuccessfulyUpdated"
                    text={
                        <FormattedMessage
                            id="Notification.agerequirement.itemSuccessfulyUpdated.text"
                            defaultMessage="Age Requirement successfully updated"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.agerequirement.itemSuccessfulyUpdated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        // BarTypes
        case 'bartype.itemSuccessfulyCreated':
            return (
                <Notification
                    key="bartype.itemSuccessfulyCreated "
                    text={
                        <FormattedMessage
                            id="Notification.bartype.itemSuccessfulyCreated.text"
                            defaultMessage="Bar Type successfully created"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.bartype.itemSuccessfulyCreated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'bartype.itemSuccessfulyRemoved':
            return (
                <Notification
                    key="bartype.itemSuccessfulyRemoved"
                    text={
                        <FormattedMessage
                            id="Notification.bartype.itemSuccessfulyRemoved.text"
                            defaultMessage="Bar Type successfully removed"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.bartype.itemSuccessfulyRemoved.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'bartype.itemSuccessfulyUpdated':
            return (
                <Notification
                    key="bartype.itemSuccessfulyUpdated"
                    text={
                        <FormattedMessage
                            id="Notification.bartype.itemSuccessfulyUpdated.text"
                            defaultMessage="Bar Type successfully updated"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.bartype.itemSuccessfulyUpdated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        // FoodTypes
        case 'foodtype.itemSuccessfulyCreated':
            return (
                <Notification
                    key="foodtype.itemSuccessfulyCreated "
                    text={
                        <FormattedMessage
                            id="Notification.foodtype.itemSuccessfulyCreated.text"
                            defaultMessage="Food Type successfully created"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.foodtype.itemSuccessfulyCreated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'foodtype.itemSuccessfulyRemoved':
            return (
                <Notification
                    key="foodtype.itemSuccessfulyRemoved"
                    text={
                        <FormattedMessage
                            id="Notification.foodtype.itemSuccessfulyRemoved.text"
                            defaultMessage="Food Type successfully removed"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.foodtype.itemSuccessfulyRemoved.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'foodtype.itemSuccessfulyUpdated':
            return (
                <Notification
                    key="foodtype.itemSuccessfulyUpdated"
                    text={
                        <FormattedMessage
                            id="Notification.foodtype.itemSuccessfulyUpdated.text"
                            defaultMessage="Food Type successfully updated"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.foodtype.itemSuccessfulyUpdated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );

        case 'generalsetting.itemSuccessfulyUpdated':
            return (
                <Notification
                    key="generalsetting.itemSuccessfulyUpdated"
                    text={
                        <FormattedMessage
                            id="Notification.generalsetting.itemSuccessfulyUpdated.text"
                            defaultMessage="Feed successfully updated"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.generalsetting.itemSuccessfulyUpdated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                />
            );
        case 'printcards2x35left.itemSuccessfulyUpdated':
            return (
                <Notification
                    key="printcards2x35left.itemSuccessfulyUpdated"
                    text={
                        <FormattedMessage
                            id="Notification.notification.printCardSuccessfulyUpdate.text"
                            defaultMessage="Card 2x3.5 Left successfully updated!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.notification.itemSuccessfullyUpdated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'printcards2x35top.itemSuccessfulyUpdated':
            return (
                <Notification
                    key="printcards2x35top.itemSuccessfulyUpdated"
                    text={
                        <FormattedMessage
                            id="Notification.notification.printCardSuccessfulyUpdatedTop.text"
                            defaultMessage="Card 2x3.5 Top successfully updated!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.notification.itemSuccessfullyUpdated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'notification.itemSuccessfullyUpdated':
            return (
                <Notification
                    key="notification.itemSuccessfullyUpdated"
                    text={
                        <FormattedMessage
                            id="Notification.notification.itemSuccessfullyUpdated.text"
                            defaultMessage="Notification successfully updated!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.notification.itemSuccessfullyUpdated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'ticketsocket.syncSuccessfullyStarted':
            return (
                <Notification
                    key="generalsetting.itemSuccessfulyUpdated"
                    text={
                        <FormattedMessage
                            id="Notification.ticketsocket.syncSuccessfullyStarted.text"
                            defaultMessage="Import successfully started"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.ticketsocket.syncSuccessfullyStarted.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'getout.syncSuccessfullyStarted':
            return (
                <Notification
                    key="generalsetting.itemSuccessfulyUpdated"
                    text={
                        <FormattedMessage
                            id="Notification.getout.syncSuccessfullyStarted.text"
                            defaultMessage="Import successfully started"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.getout.syncSuccessfullyStarted.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );

        case 'cravecreatefromimport.itemSuccessfulyUpdated':
            return (
                <Notification
                    key="generalsetting.itemSuccessfulyUpdated"
                    text={
                        <FormattedMessage
                            id="Notification.cravecreatefromimport.itemSuccessfulyUpdated.text"
                            defaultMessage="Create new Events and Ticket Types from import updated"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.cravecreatefromimport.itemSuccessfulyUpdated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'craveimportsincelastimport.itemSuccessfulyUpdated':
            return (
                <Notification
                    key="generalsetting.itemSuccessfulyUpdated"
                    text={
                        <FormattedMessage
                            id="Notification.craveimportsincelastimport.itemSuccessfulyUpdated.text"
                            defaultMessage="Import since setting updated"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.craveimportsincelastimport.itemSuccessfulyUpdated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'craveautopull.itemSuccessfulyUpdated':
            return (
                <Notification
                    key="generalsetting.itemSuccessfulyUpdated"
                    text={
                        <FormattedMessage
                            id="Notification.craveautopull.itemSuccessfulyUpdated.text"
                            defaultMessage="Automated sync setting updated"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.craveautopull.itemSuccessfulyUpdated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'craveintervalminutes.itemSuccessfulyUpdated':
            return (
                <Notification
                    key="generalsetting.itemSuccessfulyUpdated"
                    text={
                        <FormattedMessage
                            id="Notification.craveintervalminutes.itemSuccessfulyUpdated.text"
                            defaultMessage="Import interval updated"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.craveintervalminutes.itemSuccessfulyUpdated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'ticketsocketcreatefromimport.itemSuccessfulyUpdated':
            return (
                <Notification
                    key="generalsetting.itemSuccessfulyUpdated"
                    text={
                        <FormattedMessage
                            id="Notification.ticketsocketcreatefromimport.itemSuccessfulyUpdated.text"
                            defaultMessage="Create new Events and Ticket Types from import updated"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.ticketsocketcreatefromimport.itemSuccessfulyUpdated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'ticketsocketimportsincelastimport.itemSuccessfulyUpdated':
            return (
                <Notification
                    key="generalsetting.itemSuccessfulyUpdated"
                    text={
                        <FormattedMessage
                            id="Notification.ticketsocketimportsincelastimport.itemSuccessfulyUpdated.text"
                            defaultMessage="Import since setting updated"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.ticketsocketimportsincelastimport.itemSuccessfulyUpdated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'ticketsocketautopull.itemSuccessfulyUpdated':
            return (
                <Notification
                    key="generalsetting.itemSuccessfulyUpdated"
                    text={
                        <FormattedMessage
                            id="Notification.ticketsocketautopull.itemSuccessfulyUpdated.text"
                            defaultMessage="Automated sync setting updated"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.ticketsocketautopull.itemSuccessfulyUpdated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'ticketsocketintervalminutes.itemSuccessfulyUpdated':
            return (
                <Notification
                    key="generalsetting.itemSuccessfulyUpdated"
                    text={
                        <FormattedMessage
                            id="Notification.ticketsocketintervalminutes.itemSuccessfulyUpdated.text"
                            defaultMessage="Import interval updated"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.ticketsocketintervalminutes.itemSuccessfulyUpdated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'ticketsocketimportonlyevents.itemSuccessfulyUpdated':
            return (
                <Notification
                    key="generalsetting.itemSuccessfulyUpdated"
                    text={
                        <FormattedMessage
                            id="Notification.ticketsocketimportonlyevents.itemSuccessfulyUpdated.text"
                            defaultMessage="Import only Events updated."
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.ticketsocketimportonlyevents.itemSuccessfulyUpdated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'ticketsocketimportfromdate.itemSuccessfulyUpdated':
            return (
                <Notification
                    key="generalsetting.itemSuccessfulyUpdated"
                    text={
                        <FormattedMessage
                            id="Notification.ticketsocketimportfromdate.itemSuccessfulyUpdated.text"
                            defaultMessage="Import From date updated."
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.ticketsocketimportfromdate.itemSuccessfulyUpdated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'craveimportonlyevents.itemSuccessfulyUpdated':
            return (
                <Notification
                    key="generalsetting.itemSuccessfulyUpdated"
                    text={
                        <FormattedMessage
                            id="Notification.craveimportonlyevents.itemSuccessfulyUpdated.text"
                            defaultMessage="Import only Events updated."
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.craveimportonlyevents.itemSuccessfulyUpdated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'craveimportfromdate.itemSuccessfulyUpdated':
            return (
                <Notification
                    key="generalsetting.itemSuccessfulyUpdated"
                    text={
                        <FormattedMessage
                            id="Notification.craveimportfromdate.itemSuccessfulyUpdated.text"
                            defaultMessage="Import From date updated."
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.craveimportfromdate.itemSuccessfulyUpdated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'table_merge_request.itemSuccessfulyCreated':
            return (
                <Notification
                    key="table_merge_request.itemSuccessfulyCreated"
                    text={
                        <FormattedMessage
                            id="Notification.table_merge_request.itemSuccessfulyCreated.text"
                            defaultMessage="Tables successfully merged!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.table_merge_request.itemSuccessfulyCreated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'malfromedRequest':
            return (
                <Notification
                    key="malfromedRequest"
                    text={
                        <FormattedMessage
                            id="Notification.malfromedRequest.text"
                            defaultMessage="The request you made was malformed!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.malfromedRequest.title"
                            defaultMessage="Error"
                        />
                    }
                    type="error"
                />
            );
        case 'malfromedRequst':
            return (
                <Notification
                    key="malfromedRequst"
                    text={
                        <FormattedMessage
                            id="Notification.malfromedRequst.text"
                            defaultMessage="The request you made was malformed!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.malfromedRequst.title"
                            defaultMessage="Error"
                        />
                    }
                    type="error"
                />
            );

        // MediaObject
        case 'mediaobject.itemSuccessfulyCreated':
            return (
                <Notification
                    key="mediaobject.itemSuccessfulyCreated"
                    text={
                        <FormattedMessage
                            id="Notification.mediaobject.itemSuccessfulyCreated.text"
                            defaultMessage="Image successfully uploaded!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.mediaobject.itemSuccessfulyCreated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'mediaobjectversion.itemSuccessfulyCreated':
            return (
                <Notification
                    key="mediaobjectversion.itemSuccessfulyCreated"
                    text={
                        <FormattedMessage
                            id="Notification.mediaobjectversion.itemSuccessfulyCreated.text"
                            defaultMessage="Image version successfully created!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.mediaobject.itemSuccessfulyCreated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'sync.backgroundJobCreated':
            return (
                <Notification
                    key="sync.backgroundJobCreated"
                    text={
                        <FormattedMessage
                            id="Notification.sync.backgroundJobCreated.text"
                            defaultMessage="Sync with Hubspot successfully started!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.sync.backgroundJobCreated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'printcard.notFound':
            return (
                <Notification
                    key="printcard.notFound"
                    text={
                        <FormattedMessage
                            id="Notification.printcard.notFound.text"
                            defaultMessage="There are no table cards for printing!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.printcard.notFound.title"
                            defaultMessage="Warning"
                        />
                    }
                    type="warning"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'order.itemSuccessfulyRemoved':
            return (
                <Notification
                    key="order.itemSuccessfulyRemoved"
                    text={
                        <FormattedMessage
                            id="Notification.order.itemSuccessfulyRemoved.text"
                            defaultMessage="Order successfully deleted!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.order.itemSuccessfulyRemoved.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'printcards4x6width.itemSuccessfulyUpdated':
            return (
                <Notification
                    key="printcards4x6width.itemSuccessfulyUpdated"
                    text={
                        <FormattedMessage
                            id="Notification.printcards4x6width.itemSuccessfulyUpdated.text"
                            defaultMessage="Card Container Width successfully updated!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.printcards4x6width.itemSuccessfulyUpdated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'printcards2x35offset.itemSuccessfulyUpdated':
            return (
                <Notification
                    key="printcards2x35offset.itemSuccessfulyUpdated"
                    text={
                        <FormattedMessage
                            id="Notification.printcards4x6Offset.itemSuccessfulyUpdated.text"
                            defaultMessage="Card Container Offset successfully updated!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.printcards4x6offset.itemSuccessfulyUpdated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'printcards2x35horizontaloffset.itemSuccessfulyUpdated':
            return (
                <Notification
                    key="printcards2x35horizontaloffset.itemSuccessfulyUpdated"
                    text={
                        <FormattedMessage
                            id="Notification.printcards2x35HorizontalOffset.itemSuccessfulyUpdated.text"
                            defaultMessage="Card Container Horizontal Offset successfully updated!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.printcards4x6Horizontaloffset.itemSuccessfulyUpdated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'printcards2x35width.itemSuccessfulyUpdated':
            return (
                <Notification
                    key="printcards2x35width.itemSuccessfulyUpdated"
                    text={
                        <FormattedMessage
                            id="Notification.printcards2x35ContainerWidth.itemSuccessfulyUpdated.text"
                            defaultMessage="Card Container width successfully updated!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.printcards4x6ContainerWidth.itemSuccessfulyUpdated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'printcardstitle.itemSuccessfulyUpdated':
            return (
                <Notification
                    key="printcardstitle.itemSuccessfulyUpdated"
                    text={
                        <FormattedMessage
                            id="Notification.printcardstitle.itemSuccessfulyUpdated.text"
                            defaultMessage="Card Title successfully updated!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.printcardstitle.itemSuccessfulyUpdated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        // Permissions
        case 'permission.itemSuccesfullyUpdated':
            return (
                <Notification
                    key="permission.itemSuccesfullyUpdated"
                    text={
                        <FormattedMessage
                            id="Notification.permission.itemSuccesfullyUpdated.text"
                            defaultMessage="Permission successfully updated!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.permission.itemSuccesfullyUpdated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        // Roles
        case 'role.itemSuccessfulyCreated':
            return (
                <Notification
                    key="role.itemSuccessfulyCreated"
                    text={
                        <FormattedMessage
                            id="Notification.role.itemSuccessfulyCreated.text"
                            defaultMessage="Role successfully created!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.role.itemSuccessfulyCreated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'role.itemSuccessfulyRemoved':
            return (
                <Notification
                    key="role.itemSuccessfulyRemoved"
                    text={
                        <FormattedMessage
                            id="Notification.role.itemSuccessfulyRemoved.text"
                            defaultMessage="Role successfully removed!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.role.itemSuccessfulyRemoved.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
        case 'role.itemSuccessfulyUpdated':
            return (
                <Notification
                    key="role.itemSuccessfulyUpdated"
                    text={
                        <FormattedMessage
                            id="Notification.role.itemSuccessfulyUpdated.text"
                            defaultMessage="Role successfully updated!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Notification.role.itemSuccessfulyUpdated.title"
                            defaultMessage="Success"
                        />
                    }
                    type="success"
                    expire="3500"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );

        default:
            return (
                <Notification
                    key="default"
                    text={
                        <Fragment>
                            <strong>{code}</strong> -{' '}
                            <FormattedMessage
                                id="Notification.default.text"
                                defaultMessage="This is the message missing Notification case!"
                            />
                        </Fragment>
                    }
                    title={
                        <FormattedMessage
                            id="Notification.default.title"
                            defaultMessage="Warning"
                        />
                    }
                    type="warning"
                    onNotificationExpire={clearNotificationManagerPageNotification}
                    onRemoveFloatingNotification={onRemoveFloatingNotification}
                />
            );
    }
};

NotificationManager.defaultProps = {
    code: null,
    data: null,
    clearNotificationManagerPageNotification: () => {},
    onRemoveFloatingNotification: null
};

NotificationManager.propTypes = {
    code: PropTypes.string,
    data: PropTypes.oneOfType([PropTypes.object]),
    clearNotificationManagerPageNotification: PropTypes.func,
    onRemoveFloatingNotification: PropTypes.func
};

const mapDispatchToProps = dispatch => ({
    clearNotificationManagerPageNotification: () => {
        dispatch({
            type: notificationManagerActions.REMOVE_PAGE_NOTIFICATION
        });
    }
});

export default connect(
    null,
    mapDispatchToProps
)(NotificationManager);
