import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import Svg from 'erpcomponents/Svg';
import Button from 'erpcore/components/Button';
import ButtonDropdown from 'erpcomponents/ButtonDropdown';
import ElementLoader from 'erpcomponents/ElementLoader';
import Modal from 'erpcomponents/Modal';
import Cropper from 'react-cropper';
import './ImageManager.cropperjs.scss';
import './ImageManager.scss';
import { connect } from 'react-redux';
import {
    getImageData,
    getImageFetching,
    getImageCreating,
    getImageDeleting,
    getImageUpdating
} from 'erpcomponents/ImageManager/ImageManager.selectors';
import { actions as imageManagerActions } from 'erpcomponents/ImageManager/ImageManager.reducer';
// import { FormattedMessage } from 'react-intl';

export const getImageVersion = ({ imageData, versionName = null, getProperty = null }) => {
    if (!imageData || !versionName) return null;

    let latestVersion = null;

    if (imageData.versions && imageData.versions.length) {
        const allVersionWithTargetName = imageData.versions.filter(version => {
            return version.name === versionName;
        });
        if (allVersionWithTargetName.length) {
            latestVersion = allVersionWithTargetName.reduce((prevItem, currentItem) => {
                const prevItemDate = new Date(prevItem.created_at);
                const currentItemDate = new Date(currentItem.created_at);
                return prevItemDate.getTime() > currentItemDate.getTime() ? prevItem : currentItem;
            });
        }
    }

    if (getProperty && latestVersion) {
        return latestVersion[getProperty];
    }

    return latestVersion;
};

const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    // const k = 1024;
    const k = 1000;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

class ImageManager extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isCropperReady: false,
            originalDimensions: []
        };

        this.cropper = React.createRef();
    }

    componentDidMount() {
        const { fetchImageData, imageId } = this.props;

        if (imageId) {
            fetchImageData(imageId);
        }
    }

    componentDidUpdate(prevProps) {
        const { fetchImageData, imageId } = this.props;
        const { imageId: prevImageId } = prevProps;

        if (imageId && prevImageId !== imageId) {
            fetchImageData(imageId);
        }
    }

    onModalClose() {
        const { onClose, resetImageManagerReduxState } = this.props;
        this.setState({ isCropperReady: false });
        resetImageManagerReduxState();
        onClose();
    }

    onCropperReady() {
        const initialCropData = this.getInitialCropData();

        if (initialCropData) {
            this.cropper.current.crop().setData(initialCropData);
        }

        const cropperImageData = this.cropper.current.getImageData();

        this.setState({
            isCropperReady: true,
            originalDimensions: [cropperImageData.naturalWidth, cropperImageData.naturalHeight]
        });

        this.writeCropBoxNaturalDimensions();
    }

    onZoom() {
        setTimeout(() => {
            this.writeCropBoxNaturalDimensions();
        }, 100);
    }

    onCropMove() {
        this.writeCropBoxNaturalDimensions();
    }

    onCropStart() {
        this.writeCropBoxNaturalDimensions();
    }

    getInitialCropData() {
        const { imageData, versionName } = this.props;
        let initialCropData = getImageVersion({
            imageData,
            versionName,
            getProperty: 'options'
        });
        if (
            (initialCropData && initialCropData.constructor !== Object) ||
            (initialCropData &&
                initialCropData.constructor === Object &&
                Object.keys(initialCropData).length === 0)
        ) {
            initialCropData = null;
        } else {
            initialCropData = this.transformImagePositionData(initialCropData, 'frontend');
        }

        return initialCropData;
    }

    setDragMode(action = 'crop') {
        this.cropper.current.setDragMode(action);
    }

    writeCropBoxNaturalDimensions() {
        const positionData = this.cropper.current.getData(true);

        const { viewBox } = this.cropper.current.cropper;

        const dimensionsText = `${positionData.width} x ${positionData.height}`;

        let targetElement = viewBox.querySelector('.cropper-crop-box-natural-dimensions');
        if (!targetElement) {
            const newCropBoxNode = document.createElement('span');
            newCropBoxNode.textContent = dimensionsText;
            newCropBoxNode.className = 'cropper-crop-box-natural-dimensions';
            viewBox.appendChild(newCropBoxNode);
        }
        targetElement = viewBox.querySelector('.cropper-crop-box-natural-dimensions');
        targetElement.textContent = dimensionsText;
        if (this.cropper.current.getCropBoxData().top < 15) {
            targetElement.setAttribute('data-position', 'top-in');
        } else {
            targetElement.setAttribute('data-position', 'top-out');
        }

        /*
        const alreadyAddedCropBoxNodes = document.getElementsByClassName(
            'cropper-crop-box-natural-dimensions'
        );
        while (alreadyAddedCropBoxNodes.length > 0) {
            alreadyAddedCropBoxNodes[0].parentNode.removeChild(alreadyAddedCropBoxNodes[0]);
        }
        */
    }

    saveImage() {
        const { imageData, imageId, versionName, deleteImageVersion, fetchImageData } = this.props;
        const canvasData = this.cropper.current.getCroppedCanvas().toDataURL();
        const positionData = this.cropper.current.getData(true);

        if (versionName) {
            const imageVersionId = getImageVersion({ imageData, versionName, getProperty: 'id' });

            // DELETE existing image version (non-blocking, runs in the background)
            if (imageVersionId) {
                deleteImageVersion(imageVersionId);
            }

            // SAVE image version
            const versionData = {
                media_object: `/api/media-objects/${imageId}`,
                name: versionName,
                meta: imageData.meta,
                options: this.transformImagePositionData(positionData, 'backend')
            };
            if (imageData.meta) {
                versionData.meta = imageData.meta;
            }
            const { createImageVersion, onSaveImage } = this.props;
            createImageVersion(versionData).finally(() => {
                fetchImageData(imageId);
                onSaveImage({ canvasData, positionData });
            });
        }
    }

    /**
     * Transform Image Position data from backend to frontend and vice versa
     * imagePosition {object}
     * transformFor {string<frontend|backend>}
     */
    transformImagePositionData(imagePosition, transformFor = 'frontend') {
        if (!imagePosition) return null;

        let transformedImagePosition = {};

        // transform data from backend to frontend friendly format
        if (transformFor === 'frontend') {
            if (imagePosition.crop) {
                transformedImagePosition = { ...imagePosition.crop };
            }
            if (imagePosition.rotate && imagePosition.rotate.angle) {
                transformedImagePosition.rotate = imagePosition.rotate.angle;
            }
            /*
            if (imagePosition.flip) {
                if (imagePosition.flip.h) {
                    transformedImagePosition.scaleX = -1;
                }
                if (imagePosition.flip.v) {
                    transformedImagePosition.scaleY = -1;
                }
            }
            */
        }

        // transform data from frontend to backend friendly format
        if (transformFor === 'backend') {
            if (imagePosition.width || imagePosition.height || imagePosition.x || imagePosition.y) {
                transformedImagePosition.crop = {};
                if (imagePosition.width) {
                    transformedImagePosition.crop.width = imagePosition.width;
                }
                if (imagePosition.height) {
                    transformedImagePosition.crop.height = imagePosition.height;
                }
                if (imagePosition.x) {
                    transformedImagePosition.crop.x = imagePosition.x;
                }
                if (imagePosition.y) {
                    transformedImagePosition.crop.y = imagePosition.y;
                }
            }
            if (imagePosition.rotate) {
                transformedImagePosition.rotate = {
                    angle: imagePosition.rotate
                };
            }
            /*
            if (imagePosition.scaleX || imagePosition.scaleY) {
                transformedImagePosition.flip = {};
                if (imagePosition.scaleX) {
                    transformedImagePosition.flip.h = imagePosition.scaleX;
                }
                if (imagePosition.scaleY) {
                    transformedImagePosition.flip.v = imagePosition.scaleY;
                }
            }
            */
        }

        return transformedImagePosition;
    }

    zoomIn() {
        this.cropper.current.zoom(0.1);
    }

    zoomOut() {
        this.cropper.current.zoom(-0.1);
    }

    rotateLeft() {
        this.cropper.current.rotate(-15);
    }

    rotateRight() {
        this.cropper.current.rotate(15);
    }

    flipHorizontal() {
        const cropper = this.cropper.current;
        cropper.scaleX(-cropper.getData().scaleX || -1);
    }

    flipVertical() {
        const cropper = this.cropper.current;
        cropper.scaleY(-cropper.getData().scaleY || -1);
    }

    resetToOriginal() {
        const { originalDimensions } = this.state;
        const cropper = this.cropper.current;
        // cropper.reset();
        cropper.setData({
            width: originalDimensions[0],
            height: originalDimensions[1],
            x: 0,
            y: 0,
            rotate: 0,
            scaleX: 1,
            scaleY: 1
        });
        this.writeCropBoxNaturalDimensions();
    }

    resetToSavedVersion() {
        const initialCropData = this.getInitialCropData();
        const cropper = this.cropper.current;
        cropper.setData(initialCropData);
        this.writeCropBoxNaturalDimensions();
    }

    renderMeta() {
        const { imageData, versionName } = this.props;

        const { originalDimensions } = this.state;

        const modifiedMeta = getImageVersion({ imageData, versionName, getProperty: 'meta' });

        return (
            <div className="image-editor__meta">
                {!!imageData.meta && !!imageData.meta.name && (
                    <p className="image-editor__meta-item">
                        <span className="image-editor__meta-item-title">File name</span>{' '}
                        {imageData.meta.name}
                    </p>
                )}
                {!!imageData.meta && !!imageData.meta.type && (
                    <p className="image-editor__meta-item">
                        <span className="image-editor__meta-item-title">File type:</span>{' '}
                        {imageData.meta.type}
                    </p>
                )}
                {!!imageData.meta && !!imageData.meta.created_at && (
                    <p className="image-editor__meta-item">
                        <span className="image-editor__meta-item-title">Uploaded on:</span>{' '}
                        {imageData.meta.created_at}
                    </p>
                )}
                {!!modifiedMeta && !!modifiedMeta.created_at && (
                    <p className="image-editor__meta-item">
                        <span className="image-editor__meta-item-title">Modified on:</span>{' '}
                        {modifiedMeta.created_at}
                    </p>
                )}
                {!!imageData.meta && !!imageData.meta.size && (
                    <p className="image-editor__meta-item">
                        <span className="image-editor__meta-item-title">File size (original):</span>{' '}
                        {formatBytes(imageData.meta.size)}
                    </p>
                )}
                {/* !!modifiedMeta && !!modifiedMeta.size && (
                    <p className="image-editor__meta-item">
                        <span className="image-editor__meta-item-title">File size (modified):</span>{' '}
                        {formatBytes(modifiedMeta.size)}
                    </p>
                ) */}
                {!!originalDimensions.length && (
                    <p className="image-editor__meta-item">
                        <span className="image-editor__meta-item-title">Original Dimensions:</span>{' '}
                        {`${originalDimensions[0]} x ${originalDimensions[1]}`}
                    </p>
                )}
            </div>
        );
    }

    renderCropper() {
        const { imageData, aspectRatio } = this.props;

        if (!imageData) return null;

        const { isCropperReady } = this.state;

        return (
            <div className="image-editor__canvas">
                <Cropper
                    ref={this.cropper}
                    checkCrossOrigin
                    src={imageData.content_url}
                    className="image-editor__canvas-cropper"
                    guides
                    rotatable
                    movable
                    aspectRatio={aspectRatio}
                    viewMode={2}
                    responsive
                    modal
                    center
                    highlight
                    background
                    dragMode="crop"
                    autoCrop={false}
                    ready={() => this.onCropperReady()}
                    cropstart={() => this.onCropStart()}
                    cropmove={() => this.onCropMove()}
                    zoom={() => this.onZoom()}
                />
                {!isCropperReady && (
                    <div className="image-editor__canvas-loader">
                        <ElementLoader />
                    </div>
                )}
            </div>
        );
    }

    render() {
        const { imageData, opened, fetching, creating, deleting, updating } = this.props;

        const { isCropperReady } = this.state;

        const initialCropData = this.getInitialCropData();

        const loading = fetching || creating || deleting || updating;

        return (
            <Modal
                root="body"
                opened={opened}
                onClose={() => this.onModalClose()}
                title="Edit Image"
            >
                {!!opened && (
                    <div className="image-editor">
                        {!!imageData && (
                            <React.Fragment>
                                {this.renderMeta()}
                                {this.renderCropper()}
                                <div className="image-editor__controls">
                                    <Button
                                        onClick={() => this.setDragMode('move')}
                                        variation="secondary"
                                        label="Move"
                                        iconName="move"
                                        labelOnlyAria
                                        disabled={!isCropperReady}
                                        className="image-editor__controls-button"
                                    />
                                    <Button
                                        onClick={() => this.setDragMode('crop')}
                                        variation="secondary"
                                        label="Crop"
                                        iconName="crop"
                                        labelOnlyAria
                                        disabled={!isCropperReady}
                                        className="image-editor__controls-button"
                                    />
                                    <Button
                                        onClick={() => this.zoomIn()}
                                        variation="secondary"
                                        label="Zoom in"
                                        iconName="plusCircle"
                                        labelOnlyAria
                                        disabled={!isCropperReady}
                                        className="image-editor__controls-button"
                                    />
                                    <Button
                                        onClick={() => this.zoomOut()}
                                        variation="secondary"
                                        label="Zoom out"
                                        iconName="minusCircle"
                                        labelOnlyAria
                                        disabled={!isCropperReady}
                                        className="image-editor__controls-button"
                                    />
                                    <Button
                                        onClick={() => this.rotateLeft()}
                                        variation="secondary"
                                        label="Rotate left"
                                        iconName="rotateLeft"
                                        labelOnlyAria
                                        disabled
                                        className="image-editor__controls-button"
                                    />
                                    <Button
                                        onClick={() => this.rotateRight()}
                                        variation="secondary"
                                        label="Rotate right"
                                        iconName="rotateRight"
                                        labelOnlyAria
                                        disabled
                                        className="image-editor__controls-button"
                                    />
                                    <Button
                                        onClick={() => this.flipHorizontal()}
                                        variation="secondary"
                                        label="Flip horizontal"
                                        iconName="flipHorizontal"
                                        labelOnlyAria
                                        disabled
                                        className="image-editor__controls-button"
                                    />
                                    <Button
                                        onClick={() => this.flipVertical()}
                                        variation="secondary"
                                        label="Flip vertical"
                                        iconName="flipVertical"
                                        labelOnlyAria
                                        disabled
                                        className="image-editor__controls-button"
                                    />
                                    {!!initialCropData && (
                                        <ButtonDropdown
                                            placeholder="Reset Image"
                                            triggerActionOnOptionSelection
                                            options={[
                                                {
                                                    id: 'reset-original',
                                                    label: 'Reset to Original',
                                                    onClick: () => this.resetToOriginal()
                                                },
                                                {
                                                    id: 'reset-modified',
                                                    label: 'Reset to Saved Version',
                                                    onClick: () => this.resetToSavedVersion()
                                                }
                                            ]}
                                            variation="secondary"
                                            disabled={!isCropperReady}
                                        />
                                    )}
                                    {!initialCropData && (
                                        <Button
                                            onClick={() => this.resetToOriginal()}
                                            variation="secondary"
                                            label="Reset to original"
                                            disabled={!isCropperReady}
                                        />
                                    )}
                                </div>
                                <div className="image-editor__actions">
                                    <Button
                                        onClick={() => this.saveImage()}
                                        label="Save"
                                        disabled={!isCropperReady}
                                        className="image-editor__controls-button"
                                    />
                                    <Button
                                        onClick={() => this.onModalClose()}
                                        variation="secondary"
                                        label="Cancel"
                                    />
                                </div>
                            </React.Fragment>
                        )}

                        {!!(!imageData || !!loading) && (
                            <div className="image-editor__loader">
                                <ElementLoader />
                            </div>
                        )}
                    </div>
                )}
            </Modal>
        );
    }
}

ImageManager.defaultProps = {
    imageData: null,
    imageId: null,
    opened: false,
    versionName: 'edited',
    aspectRatio: null,
    // crossOrigin: 'true',
    fetchImageData: () => {},
    createImageVersion: () => {},
    deleteImageVersion: () => {},
    resetImageManagerReduxState: () => {},
    fetching: false,
    creating: false,
    deleting: false,
    updating: false,
    onClose: () => {},
    onSaveImage: () => {}
};
ImageManager.propTypes = {
    imageData: PropTypes.oneOfType([PropTypes.object]),
    imageId: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number]),
    opened: PropTypes.bool,
    versionName: PropTypes.string,
    aspectRatio: PropTypes.number,
    // crossOrigin: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    fetchImageData: PropTypes.func,
    createImageVersion: PropTypes.func,
    deleteImageVersion: PropTypes.func,
    resetImageManagerReduxState: PropTypes.func,
    fetching: PropTypes.bool,
    creating: PropTypes.bool,
    deleting: PropTypes.bool,
    updating: PropTypes.bool,
    onClose: PropTypes.func,
    onSaveImage: PropTypes.func
};

const mapStateToProps = state => ({
    imageData: getImageData(state),
    fetching: getImageFetching(state),
    creating: getImageCreating(state),
    deleting: getImageDeleting(state),
    updating: getImageUpdating(state)
});

const mapDispatchToProps = dispatch => ({
    fetchImageData: imageId => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: imageManagerActions.START_FETCHING_IMAGE,
                id: imageId
            });
        }).catch(error => ({ error }));
    },
    createImageVersion: versionData => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: imageManagerActions.START_CREATE_IMAGE_VERSION,
                versionData
            });
        }).catch(error => ({ error }));
    },
    deleteImageVersion: versionId => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: imageManagerActions.START_DELETE_IMAGE_VERSION,
                versionId
            });
        }).catch(error => ({ error }));
    },
    resetImageManagerReduxState: () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: imageManagerActions.RESET_IMAGE_EDITOR_STATE
            });
        }).catch(error => ({ error }));
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ImageManager);
