import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage, FormattedDate, FormattedTime } from 'react-intl';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { diff } from 'deep-object-diff';

import PageHeader from 'erpcomponents/Layout/PageHeader';
import PageContent from 'erpcomponents/Layout/PageContent';
import Button from 'erpcomponents/Button';
import Form, { Switch, Text, DateTime } from 'erpcore/components/Form';
import PageLoader from 'erpcomponents/PageLoader';

import LayoutManager from 'erputils/LayoutManager';

import IntegrationsTabs from 'erpcore/screens/Settings/components/IntegrationsTabs';

import { actions as ticketSocketApiActions } from 'erpcore/screens/Settings/screens/Integrations/TicketSocketAPI/TicketSocketAPI.reducer';
import {
    ticketSocketApiSingleFetching,
    getTicketSocketInitialData
} from 'erpcore/screens/Settings/screens/Integrations/TicketSocketAPI/TicketSocketAPI.selectors';
import { actions as listingActions } from 'erpcomponents/Listing/Listing.reducer';
import { getListingFetching, getListingResponse } from 'erpcomponents/Listing/Listing.selectors';

const reducerName = 'orders';

class TicketSocketAPI extends Component {
    componentDidMount() {
        const { fetchTicketSocketApiData, fetchOrders } = this.props;
        fetchTicketSocketApiData();
        fetchOrders();
    }

    getLastOrderTimestamp() {
        const { ordersData } = this.props;
        if (
            ordersData &&
            ordersData.data &&
            ordersData.data.length > 0 &&
            ordersData.data[0] &&
            ordersData.data[0].source === 'Ticket Socket'
        ) {
            return ordersData.data[0].order_date;
        }
        return null;
    }

    handleAutomatedSyncChange = formData => {
        const { dispatch } = this.props;
        if (formData === true) {
            return new Promise((resolve, reject) =>
                dispatch({
                    promise: { resolve, reject },
                    type: ticketSocketApiActions.START_UPDATE_TICKET_SOCKET_API,
                    property: 'autopull',
                    formData: { value: 'true' }
                })
            );
        }

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: ticketSocketApiActions.START_UPDATE_TICKET_SOCKET_API,
                property: 'autopull',
                formData: { value: 'false' }
            })
        );
    };

    handleCreateNewChange = formData => {
        const { dispatch } = this.props;
        if (formData === true) {
            return new Promise((resolve, reject) =>
                dispatch({
                    promise: { resolve, reject },
                    type: ticketSocketApiActions.START_UPDATE_TICKET_SOCKET_API,
                    property: 'createfrom',
                    formData: { value: 'true' }
                })
            );
        }

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: ticketSocketApiActions.START_UPDATE_TICKET_SOCKET_API,
                property: 'createfrom',
                formData: { value: 'false' }
            })
        );
    };

    handleSinceLastImport = formData => {
        const { dispatch } = this.props;
        if (formData === true) {
            return new Promise((resolve, reject) =>
                dispatch({
                    promise: { resolve, reject },
                    type: ticketSocketApiActions.START_UPDATE_TICKET_SOCKET_API,
                    property: 'importsince',
                    formData: { value: 'true' }
                })
            );
        }

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: ticketSocketApiActions.START_UPDATE_TICKET_SOCKET_API,
                property: 'importsince',
                formData: { value: 'false' }
            })
        );
    };

    startSyncTicketSocket = () => {
        const { dispatch } = this.props;
        return new Promise((resolve, reject) =>
            dispatch({
                type: ticketSocketApiActions.START_SYNC_TICKET_SOCKET_API,
                promise: { resolve, reject }
            })
        );
    };

    handleIntervalChange = formData => {
        const { dispatch, fetchTicketSocketApiData, initialValues } = this.props;
        formData = diff(initialValues, formData);
        if (formData.importInterval) {
            new Promise((resolve, reject) =>
                dispatch({
                    promise: { resolve, reject },
                    type: ticketSocketApiActions.START_UPDATE_TICKET_SOCKET_API,
                    property: 'interval',
                    formData: { value: formData.importInterval }
                })
            )
                .then(() => fetchTicketSocketApiData())
                .catch(error => error);
        }
        if (formData.importFrom) {
            new Promise((resolve, reject) =>
                dispatch({
                    promise: { resolve, reject },
                    type: ticketSocketApiActions.START_UPDATE_TICKET_SOCKET_API,
                    property: 'importFrom',
                    formData: { value: formData.importFrom }
                })
            )
                .then(() => fetchTicketSocketApiData())
                .catch(error => error);
        }
        return null;
    };

    handleImportOnlyEvents = formData => {
        const { dispatch } = this.props;
        if (formData === true) {
            return new Promise((resolve, reject) =>
                dispatch({
                    promise: { resolve, reject },
                    type: ticketSocketApiActions.START_UPDATE_TICKET_SOCKET_API,
                    property: 'importOnlyEvents',
                    formData: { value: 'true' }
                })
            );
        }

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: ticketSocketApiActions.START_UPDATE_TICKET_SOCKET_API,
                property: 'importOnlyEvents',
                formData: { value: 'false' }
            })
        );
    };

    handleChange = (event, newValue, previousValue, name) => {
        switch (name) {
            case 'automatedSync':
                this.handleAutomatedSyncChange(newValue);
                break;
            case 'createNewData':
                this.handleCreateNewChange(newValue);
                break;
            case 'sinceLastImport':
                this.handleSinceLastImport(newValue);
                break;
            case 'importOnlyEvents':
                this.handleImportOnlyEvents(newValue);
                break;
            default:
                break;
        }
    };

    render() {
        // const { onSyncNow, getData } = this.props;
        const {
            handleSubmit,
            submitting,
            pristine,
            invalid,
            singleFetching,
            ordersFetching
        } = this.props;
        const orderTimestamp = this.getLastOrderTimestamp();

        return (
            <LayoutManager slot="main" className="main--narrow" layoutType="merge">
                <PageHeader title="Settings" />
                <IntegrationsTabs />
                <PageContent>
                    <Form onSubmit={handleSubmit(this.handleIntervalChange)}>
                        {(singleFetching || ordersFetching || !orderTimestamp) && <PageLoader />}
                        <Form.SectionTitle>
                            <FormattedMessage
                                id="Settings.TicketSocketAPI.Title"
                                defaultMessage="TicketSocket API Settings"
                            />
                        </Form.SectionTitle>
                        <Form.Row>
                            <Field
                                name="automatedSync"
                                id="automatedSync"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="Settings.TicketSocketAPI.ImportOrders"
                                            defaultMessage="Automated Sync of Orders"
                                        />
                                    ),
                                    on: {
                                        label: 'on',
                                        value: true,
                                        id: 'ts-import-on'
                                    },
                                    off: {
                                        label: 'off',
                                        value: false,
                                        id: 'ts-import-off'
                                    },
                                    type: 'single',
                                    clearable: true,
                                    validation: 'required'
                                }}
                                component={Switch}
                                onChange={(event, newValue, previousValue, name) => {
                                    this.handleChange(event, newValue, previousValue, name);
                                }}
                            />
                        </Form.Row>
                        <Form.Row>
                            <Field
                                name="createNewData"
                                id="createNewData"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="Settings.TicketSocketAPI.CreateEvents"
                                            defaultMessage="Create new Events and Ticket Types Automatically"
                                        />
                                    ),

                                    on: {
                                        label: 'on',
                                        value: true,
                                        id: 'ts-create-on'
                                    },
                                    off: {
                                        label: 'off',
                                        value: false,
                                        id: 'ts-create-off'
                                    },
                                    type: 'single',
                                    clearable: true,
                                    validation: 'required'
                                }}
                                component={Switch}
                                onChange={(event, newValue, previousValue, name) => {
                                    this.handleChange(event, newValue, previousValue, name);
                                }}
                            />
                        </Form.Row>
                        <Form.Row>
                            <Field
                                name="sinceLastImport"
                                id="sinceLastImport"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="Settings.TicketSocketAPI.SinceLast"
                                            defaultMessage="Import only since last import"
                                        />
                                    ),

                                    on: {
                                        label: 'on',
                                        value: true,
                                        id: 'ts-since-on'
                                    },
                                    off: {
                                        label: 'off',
                                        value: false,
                                        id: 'ts-since-off'
                                    },
                                    type: 'single',
                                    clearable: true,
                                    validation: 'required'
                                }}
                                component={Switch}
                                onChange={(event, newValue, previousValue, name) => {
                                    this.handleChange(event, newValue, previousValue, name);
                                }}
                            />
                        </Form.Row>
                        <Form.Row>
                            <Field
                                name="importOnlyEvents"
                                id="importOnlyEvents"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="Settings.TicketSocketAPI.ImportEventsOnly"
                                            defaultMessage="Import only Events"
                                        />
                                    ),

                                    on: {
                                        label: 'on',
                                        value: true,
                                        id: 'ts-only-events-on'
                                    },
                                    off: {
                                        label: 'off',
                                        value: false,
                                        id: 'ts-only-events-off'
                                    },
                                    type: 'single',
                                    clearable: true,
                                    validation: 'required'
                                }}
                                component={Switch}
                                onChange={(event, newValue, previousValue, name) => {
                                    this.handleChange(event, newValue, previousValue, name);
                                }}
                            />
                        </Form.Row>

                        <Form.Row>
                            <Form.Columns>
                                <Form.Column>
                                    <Field
                                        name="importInterval"
                                        id="importInterval"
                                        fieldProps={{
                                            label: (
                                                <FormattedMessage
                                                    id="Settings.TicketSocketAPI.Interval"
                                                    defaultMessage="TicketSocket API Import Interval in minutes"
                                                />
                                            ),
                                            clearable: true
                                        }}
                                        component={Text}
                                    />
                                </Form.Column>
                                <Form.Column />
                            </Form.Columns>
                        </Form.Row>
                        <Form.Row>
                            <Form.Columns>
                                <Form.Column>
                                    <Field
                                        name="importFrom"
                                        id="importFrom"
                                        fieldProps={{
                                            label: (
                                                <FormattedMessage
                                                    id="Settings.TicketSocketAPI.ImportFrom"
                                                    defaultMessage="TicketSocket API Import From"
                                                />
                                            ),
                                            clearable: true
                                        }}
                                        component={DateTime}
                                    />
                                </Form.Column>
                                <Form.Column>
                                    <Button
                                        disabled={pristine || invalid}
                                        loading={submitting}
                                        submit
                                        label={
                                            <FormattedMessage
                                                id="Settings.FormButton.Interval"
                                                defaultMessage="Save"
                                            />
                                        }
                                    />
                                </Form.Column>
                            </Form.Columns>
                        </Form.Row>
                    </Form>
                    {orderTimestamp && (
                        <p>
                            <FormattedMessage
                                id="Settings.TicketSocketAPI.LastOrderStamp"
                                defaultMessage="Last order from TicketSocket on: {date} {time}"
                                values={{
                                    date: (
                                        <FormattedDate
                                            value={orderTimestamp}
                                            day="numeric"
                                            month="long"
                                            year="numeric"
                                        />
                                    ),
                                    time: (
                                        <FormattedTime
                                            value={orderTimestamp}
                                            hour12
                                            hour="numeric"
                                            minute="numeric"
                                            second="numeric"
                                        />
                                    )
                                }}
                            />
                        </p>
                    )}
                    <Button label="Sync now" onClick={() => this.startSyncTicketSocket()} />
                </PageContent>
            </LayoutManager>
        );
    }
}

TicketSocketAPI.defaultProps = {
    submitting: false,
    pristine: false,
    invalid: false,
    singleFetching: false,
    initialValues: {},
    fetchOrders: () => {},
    ordersFetching: false,
    ordersData: {}
};

TicketSocketAPI.propTypes = {
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
    fetchTicketSocketApiData: PropTypes.func.isRequired,
    singleFetching: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    initialValues: PropTypes.oneOfType([PropTypes.object]),
    fetchOrders: PropTypes.func,
    ordersFetching: PropTypes.bool,
    ordersData: PropTypes.oneOfType([PropTypes.object])
};

TicketSocketAPI = reduxForm({
    form: 'TicketSocketAPIForm',
    enableReinitialize: true
})(TicketSocketAPI);

const mapStateToProps = state => ({
    initialValues: getTicketSocketInitialData(state),
    singleFetching: ticketSocketApiSingleFetching(state),
    ordersListingFetching: getListingFetching(state, reducerName),
    ordersData: getListingResponse(state, reducerName)
});

const mapDispatchToProps = dispatch => ({
    fetchTicketSocketApiData: () => {
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: ticketSocketApiActions.START_FETCHING_TICKET_SOCKET_API
            })
        ).catch(error => error);
    },
    fetchOrders: params => {
        // Getting included data from API by setting defaultParams
        params = {
            'order_by[orderDate]': 'DESC',
            include:
                'status,deliveryStatus,reseller,tickets,customer,event,eventSeries,event.ticketTypes,event.company,group',
            'filters[source][equals]': 'Ticket Socket',
            limit: 1
        };

        dispatch({
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: 'ORDERS',
            name: reducerName,
            endpoint: 'api/orders'
        });
    }
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(TicketSocketAPI)
);
