import React, { Component } from 'react';
import Form, { Text } from 'erpcore/components/Form';
import { FormattedMessage } from 'react-intl';
import { Field, reduxForm, SubmissionError, reset } from 'redux-form';
import Button from 'erpcomponents/Button';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import reduxFormErrorMapper from 'erputils/reduxFormErrorMapper';
import { actions as roomsActions } from 'erpcore/screens/Venues/screens/VenuesEdit/Rooms/Rooms.reducer';

class RoomCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    onSubmit = formData => {
        const { dispatch, venueData } = this.props;
        formData.location = venueData && `/api/locations/${venueData.data.id}`;
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: roomsActions.START_CREATE_ROOM,
                formData
            })
        )
            .then(() => {
                dispatch(reset('RoomCreateForm'));
            })
            .catch(error => {
                throw new SubmissionError(reduxFormErrorMapper(error));
            });
    };

    render() {
        const { handleSubmit, submitting, pristine, invalid } = this.props;
        return (
            <Form onSubmit={handleSubmit(this.onSubmit)}>
                <Form.Row>
                    <Form.Columns breakOnMobile>
                        <Form.Column>
                            <Field
                                name="name"
                                id="name"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="Venues.Rooms.RoomName"
                                            defaultMessage="Room Name"
                                        />
                                    ),
                                    clearable: true,
                                    validation: 'required'
                                }}
                                component={Text}
                            />
                        </Form.Column>

                        <Form.Column>
                            <Button
                                submit
                                disabled={pristine || invalid}
                                loading={submitting}
                                label={
                                    <FormattedMessage
                                        id="Venues.FormButton.AddNew"
                                        defaultMessage="Add New"
                                    />
                                }
                            />
                        </Form.Column>
                    </Form.Columns>
                </Form.Row>
            </Form>
        );
    }
}

RoomCreate.defaultProps = {
    submitting: false,
    pristine: false,
    invalid: false,
    dispatch: () => {},
    venueData: {}
};

RoomCreate.propTypes = {
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
    dispatch: PropTypes.func,
    venueData: PropTypes.oneOfType([PropTypes.object])
};

RoomCreate = reduxForm({
    form: 'RoomCreateForm',
    enableReinitialize: true
})(RoomCreate);

const mapDispatchToProps = dispatch => ({
    onSubmit: formData => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: roomsActions.START_CREATE_ROOM,
                formData
            });
        })
            .then(() => {
                dispatch(reset('RoomCreateForm'));
            })
            .catch(error => {
                throw new SubmissionError(reduxFormErrorMapper(error));
            });
    }
});

export default connect(
    null,
    mapDispatchToProps
)(RoomCreate);
