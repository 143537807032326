import { createSelector } from 'reselect';

export const getTablesData = (state, id) => state.tables && state.tables[id] && state.tables[id];

export const getSingleTablesFetching = state => state.tables.singleFetching;

export const getSingleTablesCreating = state => state.tables.singleCreating;

export const getSingleTablesDeleting = state => state.tables.singleDeleting;

export const getSingleTablesUpdating = state => state.tables.singleUpdating;

export const getFooterElementSize = state => state.tables.footerElementSize;

export const getModalRemoveGroup = state => state.tables && state.tables.modalRemoveGroup;

export const getModalGroupInfo = state => state.tables && state.tables.modalGroupInfo;

export const getModalSplitTable = state => state.tables && state.tables.modalSplitTable;

export const getModalTableNotes = state => state.tables && state.tables.modalTableNotes;

export default createSelector([
    getTablesData,
    getSingleTablesFetching,
    getSingleTablesCreating,
    getSingleTablesDeleting,
    getSingleTablesUpdating,
    getModalRemoveGroup,
    getModalGroupInfo,
    getModalSplitTable,
    getModalTableNotes
]);
