import React, { Component } from 'react';
import LayoutManager from 'erputils/LayoutManager';
import PageHeader from 'erpcomponents/Layout/PageHeader';
import PageContent from 'erpcomponents/Layout/PageContent';
import { withRouter } from 'react-router-dom';
import { FieldArray, reduxForm, SubmissionError } from 'redux-form';
import HeaderMenuRepeaterItem from 'erpcore/screens/Settings/screens/ResellerAdmin/components/HeaderMenuRepeaterItem';
import ResellerAdminTabs from 'erpcore/screens/Settings/components/ResellerAdminTabs';
import PropTypes from 'prop-types';
import { dtoForm } from 'erputils/dto';
import Form, { Repeater } from 'erpcore/components/Form';
import NotificationManager from 'erputils/NotificationManager';
import { connect } from 'react-redux';
import { getListingFetching, getListingResponse } from 'erpcomponents/Listing/Listing.selectors';
import { actions as listingActions } from 'erpcomponents/Listing/Listing.reducer';
import { actions as headerMenuActions } from 'erpcore/screens/Settings/screens/ResellerAdmin/HeaderMenu/HeaderMenu.reducer';
import reduxFormErrorMapper from 'erputils/reduxFormErrorMapper';
import {
    getSingleHeaderMenuCreating,
    getSingleHeaderMenuDeleting,
    getSingleHeaderMenuUpdating
} from 'erpcore/screens/Settings/screens/ResellerAdmin/HeaderMenu/HeaderMenu.selectors';

class HeaderMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount = () => {
        const { fetchHeaderMenus } = this.props;
        fetchHeaderMenus();
    };

    handleSaveNewItem = ({ itemData = {} }) => {
        const { dispatch, initialValues } = this.props;

        delete initialValues.included;
        let { value } = initialValues;

        const lastPosition =
            value && value.length >= 1
                ? value.reduce((a, b) => (a.position > b.position ? a : b)).position
                : 0;
        value = value
            ? [...value, { ...itemData, position: value.length === 0 ? 0 : lastPosition + 1 }]
            : [{ ...itemData, position: lastPosition }];
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: headerMenuActions.START_CREATE_HEADER_MENU,
                formData: {
                    itemSorts: value
                }
            })
        )
            .then(() => {
                this.handleAfterAnyHeaderMenuApiUpdate();
            })
            .catch(() => {});
    };

    handleSortEnd = formData => {
        const { dispatch } = this.props;
        const value = formData.allData.map((record, index) => ({ ...record, position: index }));
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: headerMenuActions.REQUEST_UPDATE_SINGLE_HEADER_MENU,
                formData: {
                    itemSorts: value
                }
            })
        ).catch(error => {
            throw new SubmissionError(reduxFormErrorMapper(error));
        });
    };

    handleRemoveItem = ({ itemData = {} }) => {
        const { dispatch, initialValues } = this.props;
        const value = initialValues.value.filter(record => record.position !== itemData.position);

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: headerMenuActions.START_DELETE_SINGLE_HEADER_MENU,
                formData: {
                    itemSorts: value
                }
            })
        )
            .then(() => {
                this.handleAfterAnyHeaderMenuApiUpdate();
            })
            .catch(() => {
                // throw new SubmissionError(reduxFormErrorMapper(error));
            });
    };

    handleAfterAnyHeaderMenuApiUpdate() {
        const { fetchHeaderMenus, reset } = this.props;
        fetchHeaderMenus().then(() => {
            reset();
        });
    }

    render() {
        const {
            headerMenusListingFetching,
            initialValues,
            error,
            singleHeaderMenuCreating,
            singleHeaderMenuDeleting,
            singleHeaderMenuUpdating
        } = this.props;
        return (
            <LayoutManager slot="main" className="main--narrow" layoutType="merge">
                <PageHeader title="Settings - Reseller Admin" />
                <ResellerAdminTabs />
                <PageContent>
                    {error && error.code && <NotificationManager code={error.code} />}

                    <Form>
                        <FieldArray
                            name="value"
                            id="value"
                            component={Repeater}
                            RepeatableItem={HeaderMenuRepeaterItem}
                            loading={
                                headerMenusListingFetching ||
                                singleHeaderMenuCreating ||
                                singleHeaderMenuDeleting ||
                                singleHeaderMenuUpdating
                            }
                            data={initialValues}
                            onSortStart={() => {}}
                            onSortEnd={this.handleSortEnd}
                            onSaveNewItem={this.handleSaveNewItem}
                            uniqueIdentifier="position"
                            onRemoveItem={this.handleRemoveItem}
                        />
                    </Form>
                </PageContent>
            </LayoutManager>
        );
    }
}

HeaderMenu.defaultProps = {
    // headerMenusData: {},
    headerMenusListingFetching: false,
    error: null,
    singleHeaderMenuCreating: false,
    singleHeaderMenuDeleting: false,
    singleHeaderMenuUpdating: false
};

HeaderMenu.propTypes = {
    // headerMenusData: PropTypes.oneOfType([PropTypes.object]),
    fetchHeaderMenus: PropTypes.func.isRequired,
    headerMenusListingFetching: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    initialValues: PropTypes.oneOfType([PropTypes.object]).isRequired,
    error: PropTypes.oneOfType([PropTypes.object]),
    singleHeaderMenuCreating: PropTypes.bool,
    singleHeaderMenuDeleting: PropTypes.bool,
    singleHeaderMenuUpdating: PropTypes.bool
};

HeaderMenu = reduxForm({
    form: 'HeaderMenuForm',
    enableReinitialize: true,
    keepDirtyOnReinitialize: false, // important
    updateUnregisteredFields: false // important
})(HeaderMenu);

const mapStateToProps = state => ({
    headerMenusListingFetching: getListingFetching(state, 'headerMenus'),
    headerMenusData: dtoForm(getListingResponse(state, 'headerMenus')),
    initialValues: dtoForm(getListingResponse(state, 'headerMenus')),
    singleHeaderMenuCreating: getSingleHeaderMenuCreating(state),
    singleHeaderMenuDeleting: getSingleHeaderMenuDeleting(state),
    singleHeaderMenuUpdating: getSingleHeaderMenuUpdating(state)
});

const mapDispatchToProps = dispatch => ({
    fetchHeaderMenus: params => {
        const listingParams = Object.assign({ pagination: false }, params);

        dispatch({
            type: listingActions.START_FETCHING_LISTING,
            params: listingParams,
            entity: 'HEADER_MENUS',
            endpoint: '/api/general-settings/reseller-header-menu-sort'
        });
    }
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(HeaderMenu)
);
