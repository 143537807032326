import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues, change } from 'redux-form';
import { FormattedMessage } from 'react-intl';

import { BarChart } from 'erpcomponents/WidgetCharts';
import ElementLoader from 'erpcomponents/ElementLoader';
import { Select } from 'erpcore/components/Form';
import Widget from 'erpcomponents/Widget';

import './TotalSalesByTicketType.scss';

import { actions as totalSalesByTicketType } from 'erpcomponents/Widgets/TotalSalesByTicketType/TotalSalesByTicketType.reducer';
import {
    getData,
    getFetching
} from 'erpcomponents/Widgets/TotalSalesByTicketType/TotalSalesByTicketType.selectors';
import { chartColors } from 'erpdata/colors';

class TotalSalesByTicketType extends Component {
    componentDidMount() {
        const { filterValues, eventSeries, eventID } = this.props;
        if (eventSeries || eventID) {
            return this.fetchData({
                filterValues,
                eventID,
                eventSeries
            });
        }
        return null;
    }

    componentWillReceiveProps(nextProps) {
        const { eventSeries, eventID, dateRange, setFormValue, filterValues } = this.props;
        const {
            eventSeries: nextEventSeries,
            eventID: nextEventID,
            dateRange: nextGlobalDateRange,
            filterValues: nextFilterValues
        } = nextProps;

        let willFetchData = false;

        let newFilterValues = nextFilterValues;

        if (dateRange !== nextGlobalDateRange) {
            willFetchData = true;
            newFilterValues = {
                filter: nextGlobalDateRange
            };
            setFormValue('filter', nextGlobalDateRange);
        }

        if (filterValues.filter !== nextFilterValues.filter) {
            willFetchData = true;
        }

        if (nextEventID !== eventID) {
            willFetchData = true;
        }

        if (nextEventSeries !== eventSeries) {
            willFetchData = true;
        }

        if (willFetchData) {
            return this.fetchData({
                filterValues: newFilterValues,
                eventID: nextEventID,
                eventSeries: nextEventSeries
            });
        }

        return null;
    }

    fetchData({ filterValues, eventID, eventSeries }) {
        const { dispatch, id } = this.props;

        const params = {
            display_by: 'ticket_type',
            'filters[utc]': 'true'
        };

        if (filterValues.filter !== Infinity) {
            const now = new Date();
            const startDate = new Date(now.getTime() - filterValues.filter * 24 * 60 * 60 * 1000);
            params['filters[start_date]'] = startDate;
            params['filters[end_date]'] = now;
        }

        if (eventID) {
            params['filters[events]'] = eventID;
        }

        if (eventSeries) {
            params['filters[event_series]'] = eventSeries;
        }

        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: totalSalesByTicketType.START_FETCHING_TOTALSALESBYTICKETTYPE_DATA,
                widgetId: id,
                params
            });
        }).catch(error => ({ error }));
    }

    formatWidgetData() {
        const { data: initialData } = this.props;

        const widgetData = {
            barChart: {
                data: {
                    labels: [],
                    datasets: [
                        {
                            fill: false,
                            lineTension: 0.1,
                            backgroundColor: chartColors[0],
                            borderColor: chartColors[0],
                            borderCapStyle: 'butt',
                            borderDash: [],
                            borderDashOffset: 0.0,
                            borderJoinStyle: 'miter',
                            pointBorderColor: chartColors[0],
                            pointBackgroundColor: '#fff',
                            pointBorderWidth: 1,
                            pointHoverRadius: 5,
                            pointHoverBackgroundColor: chartColors[0],
                            pointHoverBorderColor: 'rgba(220,220,220,1)',
                            pointHoverBorderWidth: 2,
                            pointRadius: 1,
                            pointHitRadius: 10,
                            data: [],
                            tooltipData: []
                        }
                    ]
                },
                options: {
                    legend: false,
                    maintainAspectRatio: false,
                    scales: {
                        xAxes: [
                            {
                                min: 0
                            }
                        ],
                        yAxes: [
                            {
                                display: true, // this will remove all the y-axis grid lines
                                ticks: {
                                    beginAtZero: true // minimum value will be 0.
                                }
                            }
                        ]
                    }
                }
            }
        };

        // barChart
        // Create object with event names
        let events = {};
        if (initialData && initialData.eventsData) {
            initialData.eventsData.map(event => {
                events = { ...events, ...{ [event.id]: event.attributes.name } };
                return events;
            });
        }
        // Prepare chart data
        if (initialData && initialData.data) {
            Object.keys(initialData.data).map(key => {
                widgetData.barChart.data.datasets[0].data.push(initialData.data[key].attendance);
                widgetData.barChart.data.labels.push(key);

                // tooltip
                const itemRevenueCurrency = initialData.data[key].revenue_currency
                    ? initialData.data[key].revenue_currency
                    : '';
                const itemRevenue = initialData.data[key].revenue
                    ? Math.round(initialData.data[key].revenue / 100)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    : 0;
                widgetData.barChart.data.datasets[0].tooltipData.push({
                    ticketsSold: initialData.data[key].tickets_sold
                        ? initialData.data[key].tickets_sold
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        : 0,
                    revenue: `${itemRevenueCurrency} ${itemRevenue}`,
                    attendance: initialData.data[key].attendance
                        ? initialData.data[key].attendance
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        : 0
                });

                return widgetData;
            });
        }
        // Prepare tooltip Data
        const tooltips = {
            callbacks: {
                title() {
                    return null;
                },
                label(tooltipItem) {
                    const label = tooltipItem.label || '';

                    return label;
                },
                afterLabel(tooltipItem, data) {
                    const { tooltipData } = data.datasets[0];
                    const labelArray = [
                        '',
                        `Tickets sold: ${tooltipData[tooltipItem.index].attendance}`,
                        `Revenue: ${tooltipData[tooltipItem.index].revenue}`
                    ];

                    return labelArray;
                }
            }
        };

        widgetData.barChart.options = { ...widgetData.barChart.options, ...{ tooltips } };

        return widgetData;
    }

    renderFilters() {
        return (
            <Field
                name="filter"
                id="filter"
                fieldProps={{
                    label: (
                        <FormattedMessage
                            id="TotalSalesByTicketType.Filter.DateRange"
                            defaultMessage="Date Range"
                        />
                    ),
                    clearable: false,
                    options: [
                        {
                            label: (
                                <FormattedMessage
                                    id="TotalSalesByTicketType.Filter.DateRange.Last24h"
                                    defaultMessage="Last 24h"
                                />
                            ),
                            value: 1
                        },
                        {
                            label: (
                                <FormattedMessage
                                    id="TotalSalesByTicketType.Filter.DateRange.Last7Days"
                                    defaultMessage="Last 7 Days"
                                />
                            ),
                            value: 7
                        },
                        {
                            label: (
                                <FormattedMessage
                                    id="TotalSalesByTicketType.Filter.DateRange.Last30Days"
                                    defaultMessage="Last 30 Days"
                                />
                            ),
                            value: 30
                        },
                        {
                            label: (
                                <FormattedMessage
                                    id="TotalSalesByTicketType.Filter.DateRange.Last3Months"
                                    defaultMessage="Last 3 Months"
                                />
                            ),
                            value: 90
                        },
                        {
                            label: (
                                <FormattedMessage
                                    id="TotalSalesByTicketType.Filter.DateRange.Last6Months"
                                    defaultMessage="Last 6 Months"
                                />
                            ),
                            value: 180
                        },
                        {
                            label: (
                                <FormattedMessage
                                    id="TotalSalesByTicketType.Filter.DateRange.Last12Months"
                                    defaultMessage="Last 12 Months"
                                />
                            ),
                            value: 365
                        },
                        {
                            label: (
                                <FormattedMessage
                                    id="TotalSalesByTicketType.Filter.DateRange.AllTime"
                                    defaultMessage="All Time"
                                />
                            ),
                            value: Infinity
                        }
                    ]
                }}
                component={Select}
            />
        );
    }

    renderEvents() {
        const widgetData = this.formatWidgetData();

        return <BarChart data={widgetData.barChart.data} options={widgetData.barChart.options} />;
    }

    renderContent() {
        return (
            <div className="total-sales-by-date-widget__content">
                <div className="total-sales-by-date-widget__main">{this.renderEvents()}</div>
            </div>
        );
    }

    render() {
        const { size, fetching } = this.props;

        return (
            <Widget
                title={
                    <FormattedMessage
                        id="TotalSalesByTicketType.Title"
                        defaultMessage="Sales by Event Ticket Types"
                    />
                }
                filters={this.renderFilters()}
                size={size}
                className="total-sales-by-date-widget"
            >
                {fetching && <ElementLoader overlay />}
                {this.renderContent()}
            </Widget>
        );
    }
}

TotalSalesByTicketType.defaultProps = {
    size: null,
    fetching: false,
    data: {},
    filterValues: { filter: Infinity },
    setFormValue: () => {},
    dateRange: Infinity,
    eventSeries: null,
    eventID: null
};

TotalSalesByTicketType.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    dispatch: PropTypes.func.isRequired,
    fetching: PropTypes.bool,
    data: PropTypes.oneOfType([PropTypes.object]),
    filterValues: PropTypes.oneOfType([PropTypes.object]),
    setFormValue: PropTypes.func,
    dateRange: PropTypes.number,
    eventSeries: PropTypes.string,
    eventID: PropTypes.string
};

//  Getting initial value populated in the form from the store
const mapStateToProps = (state, ownProps) => {
    return {
        data: getData(state, ownProps.id),
        fetching: getFetching(state, ownProps.id),
        filterValues: getFormValues('TotalSalesByTicketTypeForm')(state),
        initialValues: { filter: ownProps.dateRange }
    };
};

TotalSalesByTicketType = reduxForm(
    {
        form: 'TotalSalesByTicketTypeForm',
        destroyOnUnmount: false
    },
    mapStateToProps
)(TotalSalesByTicketType);

const mapDispatchToProps = dispatch => ({
    setFormValue: (field, value) => dispatch(change('TotalSalesByTicketTypeForm', field, value))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TotalSalesByTicketType);
