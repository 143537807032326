/* eslint-disable no-unused-vars */
import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import Cardbox from 'erpcomponents/Cardbox';
import Button from 'erpcomponents/Button';
import Svg from 'erpcomponents/Svg';
import './CustomerInfoCard.scss';
import Tooltip from 'erpcomponents/Tooltip';

const LineBreak = () => {
    return <div className="customer-info__break" />;
};

const handleCardBody = (includedData, handleCustomerEditModal) => {
    const [showMore, setShowMore] = useState(false);

    const toggleShowMore = () => setShowMore(!showMore);

    const customer = includedData.find(el => el.type === 'Customer');

    if (!customer) {
        return null;
    }

    const customerName = `${customer.first_name} ${customer.last_name}`;
    const { email, phone, id, address, city, state, country, zip } = customer;

    return (
        <Fragment>
            <div className={`customer-info, ${showMore && 'customer-info--opened'}`}>
                <div className="customer-info__info">
                    <span className="customer-info__name">
                        {customerName || '-'}{' '}
                        {id && (
                            <Tooltip content="Edit Customer Details">
                                <button
                                    type="button"
                                    onClick={() => handleCustomerEditModal()}
                                    className="customer-info__name--link"
                                >
                                    {' '}
                                    <Svg icon="editLink" className="customer-info__name--icon" />
                                </button>
                            </Tooltip>
                        )}
                    </span>
                    <span>{email}</span>
                    <span>{phone}</span>
                </div>
                <div className="customer-info__address">
                    <LineBreak />
                    <div className="customer-info__content">
                        <span>{address}</span>
                        <span>
                            {city && city.name}
                            {city && city.name && ((state && state.name) || zip) && (
                                <span>, </span>
                            )}{' '}
                            {state && state.name} {zip}
                        </span>
                        <span>{country && country.name}</span>
                    </div>
                </div>
                <div className="customer-info__action">
                    <Button
                        size="small"
                        variation="tertiary"
                        onClick={toggleShowMore}
                        label={showMore ? 'Show Less' : 'Show More'}
                    />
                </div>
            </div>
        </Fragment>
    );
};

const CustomerInfoCard = ({ data, handleCustomerEditModal }) => {
    const customerInfoCardData =
        data && data.included && handleCardBody(data.included, handleCustomerEditModal);

    return customerInfoCardData && <Cardbox body={customerInfoCardData} />;
};

CustomerInfoCard.propTypes = {
    data: PropTypes.oneOfType([PropTypes.object]).isRequired
};

export default CustomerInfoCard;
