import React, { Component } from 'react';
import { FormattedDate, FormattedTime } from 'react-intl';
import './EventTableInfo.scss';
import PropTypes from 'prop-types';

class EventTableInfo extends Component {
    state = {};

    render() {
        const { name, start, end } = this.props;
        return (
            <div className="events-table-event-info">
                <span className="events-table-event-info__title">{name}</span>
                <div className="events-table-event-info__start">
                    <span className="events-table-event-info__start-title">Starts </span>
                    <FormattedDate value={start} /> at <FormattedTime value={start} />
                </div>
                <span className="events-table-event-info__end">
                    <span className="events-table-event-info__end-title">Ends </span>
                    <FormattedDate value={end} /> at <FormattedTime value={end} />
                </span>
            </div>
        );
    }
}
EventTableInfo.defaultProps = {
    name: '',
    start: '',
    end: ''
};

EventTableInfo.propTypes = {
    name: PropTypes.string,
    start: PropTypes.string,
    end: PropTypes.string
};

export default EventTableInfo;
