import { createSelector } from 'reselect';

export const getPrintSettings = state => {
    if (state.ticketSocketApi) {
        return {
            width:
                state.printSettings && state.printSettings.width && state.printSettings.width.value,

            title:
                state.printSettings && state.printSettings.title && state.printSettings.title.value
        };
    }
    return null;
};

export const getPrintSettings2x35Width = state => {
    if (state.ticketSocketApi) {
        return {
            width2x35:
                state.printSettings &&
                state.printSettings.width2x35 &&
                state.printSettings.width2x35.value
        };
    }

    return null;
};

export const getPrintSettings2x35ContainerWidth = state => {
    if (state.ticketSocketApi) {
        return {
            containerWidth2x35:
                state.printSettings &&
                state.printSettings.containerWidth2x35 &&
                state.printSettings.containerWidth2x35.value
        };
    }

    return null;
};

export const getPrintSettings2x35Height = state => {
    if (state.ticketSocketApi) {
        return {
            height2x35:
                state.printSettings &&
                state.printSettings.height2x35 &&
                state.printSettings.height2x35.value
        };
    }

    return null;
};

export const getPrintSettings2x35Offset = state => {
    if (state.ticketSocketApi) {
        return {
            offset2x35:
                state.printSettings &&
                state.printSettings.offset2x35 &&
                state.printSettings.offset2x35.value
        };
    }

    return null;
};

export const getPrintSettings2x35HorizontalOffset = state => {
    if (state.ticketSocketApi) {
        return {
            horizontalOffset2x35:
                state.printSettings &&
                state.printSettings.horizontalOffset2x35 &&
                state.printSettings.horizontalOffset2x35.value
        };
    }

    return null;
};

export const getPrintSettingsFetching = state =>
    state.printSettings && state.printSettings.singleFetching;

export default createSelector([
    getPrintSettings,
    getPrintSettingsFetching,
    getPrintSettings2x35Height,
    getPrintSettings2x35Width,
    getPrintSettings2x35Offset,
    getPrintSettings2x35HorizontalOffset,
    getPrintSettings2x35ContainerWidth
]);
