import { takeLatest, delay, put, call, select, fork, take } from 'redux-saga/effects';
import { channel } from 'redux-saga';
import restClient from 'erpcore/api/restClient';
import { getListingUrlQueryParams } from 'erputils/RouterManager/RouterManager.selectors';
import { parseParamsForApi } from 'erputils/utils';
import { actions as listingActions } from 'erpcomponents/Listing/Listing.reducer';
import { actions as notificationManagerActions } from 'erputils/NotificationManager/NotificationManager.reducer';
import dto from 'erputils/dto';
import { actions as groupsActions } from './Groups.reducer';

/**
 * Create Group
 * @param  {Object} promise
 * @return {Object} formData
 */
export function* createGroup({ promise, formData }) {
    try {
        // Create Group
        const createGroupAPI = yield restClient.post(`api/groups`, formData);
        yield put({
            type: groupsActions.CREATE_GROUP_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: createGroupAPI.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: groupsActions.CREATE_GROUP_FAILED,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Fetch Group
 * @param  {Object} promise
 * @return {string} id Group id
 */
export function* fetchGroup({ promise, id, params }) {
    try {
        let fetchGroupAPI = yield restClient.get(`api/groups/${id}`, { params });

        fetchGroupAPI = dto(fetchGroupAPI.data);

        if (fetchGroupAPI && fetchGroupAPI.data && fetchGroupAPI.data.children) {
            for (let i = 0; i < fetchGroupAPI.data.children.length; i += 1) {
                let childrenGroupAPI = yield restClient.get(
                    `api/groups/${
                        fetchGroupAPI.data.children[i].id
                    }?include=tables,event,event.ticketsInclude,orders,children`
                );

                childrenGroupAPI = dto(childrenGroupAPI.data);

                fetchGroupAPI.data.children[i].tables = childrenGroupAPI.data.tables;
            }
        }

        yield put({
            type: groupsActions.FETCH_SINGLE_GROUP_SUCCESSFUL
        });

        yield put({
            type: groupsActions.STORE_SINGLE_GROUP_DATA,
            id,
            response: fetchGroupAPI
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: groupsActions.FETCH_SINGLE_GROUP_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Update group single data
 * @param  {Object} id ID of an group
 * @return {Object} Response from API
 */
export function* updateSingleGroup({ promise, formData, id }) {
    const params = Object.assign(
        {},
        {
            include:
                'event,event.name,ticketTypes,orders,orders.tables,tables,ichildren,orders,table,event'
        },
        yield select(getListingUrlQueryParams)
    );
    try {
        const updateSingleGroupAPI = yield restClient.put(
            `api/groups/${id}?include=children,orders,tables,event`,
            formData
        );
        yield put({
            type: groupsActions.UPDATE_SINGLE_GROUP_SUCCESSFUL
        });
        yield put({
            type: groupsActions.STORE_SINGLE_GROUP_DATA,
            id,
            response: dto(updateSingleGroupAPI.data)
        });
        yield put({
            type: listingActions.START_FETCHING_LISTING,
            entity: 'GROUPS',
            endpoint: 'api/groups',
            params: parseParamsForApi(params)
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateSingleGroupAPI.data
        });
        yield call(promise.resolve);
    } catch (error) {
        const { response } = error;
        yield put({
            type: groupsActions.UPDATE_SINGLE_GROUP_FAILED
        });
        const splitError = response && response.data.errors.find(err => err.name === 'children');
        if (response && response.data.code === 'malfromedRequst' && splitError)
            yield put({
                type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                response: { code: 'group.itemSplitError', data: { message: splitError.message } }
            });
        else {
            yield put({
                type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                response: error.response.data
            });
        }
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Update group single data
 * Used in Table Allotments / Updates Group and doesn't make additional requests
 *
 * TODO: This file is added across two branches. Non of them currently in master
 * @param  {Object} id ID of an group
 * @return {Object} Response from API
 */
export function* updateSingleGroupDnd({ promise, formData, id, debounceDelay = 0 }) {
    yield delay(debounceDelay);
    try {
        const updateSingleGroupAPI = yield restClient.put(`api/groups/${id}`, formData);
        yield put({
            type: groupsActions.UPDATE_SINGLE_GROUP_SUCCESSFUL
        });
        yield put({
            type: groupsActions.STORE_SINGLE_GROUP_DATA,
            id,
            response: dto(updateSingleGroupAPI.data)
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: updateSingleGroupAPI.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: groupsActions.UPDATE_SINGLE_GROUP_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Update group single data
 * @param  {Object} id ID of an group
 * @return {Object} Response from API
 */
export function* updateSingleGroupChecked({ promise, formData }) {
    try {
        yield put({
            type: groupsActions.UPDATE_SINGLE_GROUP_SUCCESSFUL
        });
        yield put({
            type: groupsActions.STORE_CHECKED_GROUPS,
            response: formData
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: groupsActions.UPDATE_SINGLE_GROUP_FAILED
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Update group single data
 * @param  {Object} id ID of an group
 * @return {Object} Response from API
 */
export function* checkAllGroups({ promise, formData }) {
    try {
        yield put({
            type: groupsActions.UPDATE_SINGLE_GROUP_SUCCESSFUL
        });
        yield put({
            type: groupsActions.STORE_CHECKED_GROUPS,
            response: formData
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: groupsActions.UPDATE_SINGLE_GROUP_FAILED
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Update group single data
 * @param  {Object} id ID of an group
 * @return {Object} Response from API
 */
export function* bulkActionStart({ promise, formData, routes }) {
    try {
        const params = Object.assign(
            {},
            { include: 'location.city' },
            yield select(getListingUrlQueryParams)
        );
        const data = { body: { ...formData }, routes, method: 'PUT' };
        const updateOrdersAPI = yield restClient.post(`api/batch-requests`, data);
        yield put({
            type: groupsActions.BULK_ACTION_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: updateOrdersAPI.data
        });
        yield put({
            type: listingActions.START_FETCHING_LISTING,
            entity: 'GROUPS',
            endpoint: 'api/groups',
            params: parseParamsForApi(params)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: groupsActions.BULK_ACTION_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Delete Single Group
 * @param  {Object} id ID of an Group
 * @return {Object} Response from API
 */
export function* deleteSingleGroup({ promise, id }) {
    try {
        // Merge default with current params for listing refresh purpose
        const params = Object.assign(
            {},
            {
                include:
                    'event,event.name,ticketTypes,orders,orders.tables,tables,ichildren,orders,table,event'
            },
            yield select(getListingUrlQueryParams)
        );

        const deleteSingleGroupAPI = yield restClient.delete(`api/groups/${id}`);
        yield put({
            type: groupsActions.DELETE_SINGLE_GROUP_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: deleteSingleGroupAPI.data
        });
        yield put({
            type: listingActions.START_FETCHING_LISTING,
            entity: 'GROUPS',
            endpoint: 'api/groups',
            params: parseParamsForApi(params)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: groupsActions.DELETE_SINGLE_GROUP_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Bulk Actions - Merge groups
 * @param  {Object} iri of the group
 * @return {Object} Response from API
 */
export function* startMergeGroups({ promise, formData }) {
    try {
        const params = Object.assign(
            {},
            { include: 'event,event.name,ticketTypes,orders,orders.tables,tables' },
            yield select(getListingUrlQueryParams)
        );
        const mergeGroupsAPI = yield restClient.post('api/group-merge-requests', formData);
        yield put({
            type: groupsActions.BULK_ACTIONS_UPDATE_GROUPS_SUCCESS
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: mergeGroupsAPI.data
        });
        yield put({
            type: listingActions.UPDATE_BULK_ACTIONS_IRIS,
            response: [],
            name: 'groups'
        });
        yield put({
            type: listingActions.START_FETCHING_LISTING,
            entity: 'GROUPS',
            endpoint: 'api/groups',
            params: parseParamsForApi(params)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: groupsActions.BULK_ACTIONS_UPDATE_GROUPS_FAILED
        });
        const mergeError = error?.response?.data?.errors?.find(err => err.name === 'primary_group');
        if (error?.response?.data?.code === 'malfromedRequst' && mergeError) {
            error.response.data = {
                ...error.response.data,
                code: 'group.mergeError',
                data: { message: mergeError.message }
            };
        }
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Single Action - Merge groups
 * @param  {Object} iri of the group
 * @return {Object} Response from API
 */

export function* startMergeGroupsSingle({ promise, formData }) {
    try {
        const mergeGroupsAPI = yield restClient.post('api/group-merge-requests', formData);
        yield put({
            type: groupsActions.MERGE_GROUPS_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: mergeGroupsAPI.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: groupsActions.MERGE_GROUPS_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * split single group
 * @param  {Object} id ID of an group, children
 * @return {Object} Response from API
 */
export function* splitSingleGroup({ promise, formData, id }) {
    // const params = Object.assign(
    //     {},
    //     { include: 'event,event.name,ticketTypes,orders,orders.tables,tables' }
    // );
    try {
        const splitSingleGroupAPI = yield restClient.put(
            `api/groups/${id}?include=children,orders,table,event`,
            formData
        );
        yield put({
            type: groupsActions.SPLIT_SINGLE_GROUP_SUCCESSFUL
        });
        yield put({
            type: groupsActions.STORE_SINGLE_GROUP_DATA,
            id,
            response: dto(splitSingleGroupAPI.data)
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: splitSingleGroupAPI.data
        });
        yield call(promise.resolve);
    } catch (error) {
        const { response } = error;
        yield put({
            type: groupsActions.SPLIT_SINGLE_GROUP_FAILED
        });
        const splitError = response && response.data.errors.find(err => err.name === 'children');
        if (response && response.data.code === 'malfromedRequst' && splitError)
            yield put({
                type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                response: { code: 'group.itemSplitError', data: { message: splitError.message } }
            });
        else {
            yield put({
                type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                response: error.response.data
            });
        }
        yield call(promise.reject, error.response.data);
    }
}

function takeLatestPerProps(propsOrSelector, pattern, worker, ...args) {
    // Not a generator
    return fork(function* generator() {
        // Fork a generator here to make it work like takeLatest
        const channelsMap = {};
        while (true) {
            const action = yield take(pattern); // yield necessary here
            const propsValue =
                typeof propsOrSelector === 'function'
                    ? propsOrSelector(action)
                    : action[propsOrSelector];
            if (!channelsMap[propsValue]) {
                channelsMap[propsValue] = channel();
                yield takeLatest(channelsMap[propsValue], worker, ...args);
            }
            yield put(channelsMap[propsValue], action);
        }
    });
}

/**
 * Bulk Actions - Delete Groups
 * @param  {Object} iri of the group
 * @return {Object} Response from API
 */
export function* deleteGroupsBulkAction({ promise, formData }) {
    try {
        const params = Object.assign(
            {},
            {
                include: 'event,event.name,ticketTypes,orders,orders.tables,tables'
            },
            yield select(getListingUrlQueryParams)
        );
        formData.method = 'DELETE';
        yield restClient.post('api/batch-requests', formData);
        yield put({
            type: groupsActions.BULK_ACTIONS_DELETE_GROUPS_SUCCESS
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: { code: 'groups.itemSuccessfulyRemoved' }
        });
        yield put({
            type: listingActions.UPDATE_BULK_ACTIONS_IRIS,
            response: [],
            name: 'groups'
        });
        yield put({
            type: listingActions.START_FETCHING_LISTING,
            entity: 'GROUPS',
            endpoint: 'api/groups',
            params: parseParamsForApi(params)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: groupsActions.BULK_ACTIONS_DELETE_GROUPS_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: (error && error.response && error.response.data) || error
        });
        yield call(promise.reject, (error && error.response && error.response.data) || error);
    }
}

/**
 * Register action to watcher
 */
export const groupsSaga = [
    takeLatest(groupsActions.START_CREATE_GROUP, createGroup),
    takeLatest(groupsActions.START_FETCHING_SINGLE_GROUP, fetchGroup),
    takeLatestPerProps('id', groupsActions.REQUEST_UPDATE_SINGLE_GROUP_DND, updateSingleGroupDnd),
    takeLatest(groupsActions.START_UPDATE_SINGLE_GROUP, updateSingleGroup),
    takeLatest(groupsActions.START_DELETE_SINGLE_GROUP, deleteSingleGroup),
    takeLatest(groupsActions.SINGLE_GROUP_CHECKED, updateSingleGroupChecked),
    takeLatest(groupsActions.CHECK_ALL_GROUPS, checkAllGroups),
    takeLatest(groupsActions.START_BULK_ACTION, bulkActionStart),
    takeLatest(groupsActions.START_BULK_ACTIONS_UPDATE_GROUPS, startMergeGroups),
    takeLatest(groupsActions.START_SPLIT_SINGLE_GROUP, splitSingleGroup),
    takeLatest(groupsActions.START_MERGE_GROUPS, startMergeGroupsSingle),
    takeLatest(groupsActions.START_BULK_ACTIONS_DELETE_GROUPS, deleteGroupsBulkAction)
];
