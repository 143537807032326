import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { EditorState, convertToRaw, ContentState, SelectionState, Modifier } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import './TextEditor.scss';

const TextEditor = ({ meta, fieldAttr, input }) => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [initialized, setInitialized] = useState(false);

    /**
     * Function for clearing content from editor,
     * createEmpty() method should be used only on initialized
     * for issues with caret position, not applicable here
     *
     * https://stackoverflow.com/questions/37463757/how-to-clear-input-field-in-draft-js
     */
    const handleEmptyContent = () => {
        let emptyEditorState = editorState;
        let contentState = emptyEditorState.getCurrentContent();
        const firstBlock = contentState.getFirstBlock();
        const lastBlock = contentState.getLastBlock();
        const allSelected = new SelectionState({
            anchorKey: firstBlock.getKey(),
            anchorOffset: 0,
            focusKey: lastBlock.getKey(),
            focusOffset: lastBlock.getLength(),
            hasFocus: true
        });
        contentState = Modifier.removeRange(contentState, allSelected, 'backward');
        emptyEditorState = EditorState.push(emptyEditorState, contentState, 'remove-range');
        setEditorState(emptyEditorState);
    };

    const onEditorStateChange = newEditorState => {
        setEditorState(newEditorState);

        let reduxFormValue = draftToHtml(convertToRaw(newEditorState.getCurrentContent()));
        if (String(reduxFormValue).trim() === String('<p></p>').trim()) {
            reduxFormValue = '';
        }
        input.onChange(reduxFormValue);
    };

    /**
     * Effects
     */
    useEffect(() => {
        if (!initialized && meta?.initial) {
            const blocksFromHtml = htmlToDraft(meta?.initial || '');
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            setEditorState(EditorState.createWithContent(contentState));
            setInitialized(true);
        }

        if (typeof meta?.initial === 'undefined' && !input.value && !meta.pristine) {
            handleEmptyContent();
        }
    }, [meta]);

    return (
        <div
            className={`text-editor${meta.touched && meta.error ? ` text-editor--error` : ''}${
                fieldAttr.required === true ? ' text-editor--required' : ''
            }`}
        >
            <Editor
                editorState={editorState}
                toolbarClassName="text-editor__toolbar"
                wrapperClassName="text-editor__wrapper"
                editorClassName="text-editor__editor"
                onEditorStateChange={onEditorStateChange}
                onFocus={() => input.onFocus()}
                onBlur={() => input.onBlur()}
            />
            {meta.touched && meta.error && (
                <span className="text-editor__error">
                    {meta.error.message ? meta.error.message : meta.error}
                </span>
            )}
        </div>
    );
};

TextEditor.defaultProps = {
    input: {},
    meta: {},
    fieldAttr: {}
};

TextEditor.propTypes = {
    input: PropTypes.oneOfType([PropTypes.object]),
    meta: PropTypes.oneOfType([PropTypes.object]),
    fieldAttr: PropTypes.oneOfType([PropTypes.object])
};
export default TextEditor;
