import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SignatureCanvas from 'react-signature-canvas';
import Input from '../Input';

class Signature extends Component {
    constructor(props) {
        super(props);
        this.signatureCanvas = React.createRef();
    }

    componentDidUpdate() {
        const { input, fieldProps } = this.props;
        this.signatureCanvas.current.clear();
        this.signatureCanvas.current.fromDataURL(input.value);
        if (fieldProps.isDisabled) {
            this.signatureCanvas.current.off();
        } else {
            this.signatureCanvas.current.on();
        }
    }

    render() {
        const { input, meta, fieldProps, fieldAttr, field } = this.props;
        return (
            <Input
                fieldProps={fieldProps}
                fieldAttr={fieldAttr}
                field={field}
                input={input}
                meta={meta}
            >
                <div className="input__field input__field--signature">
                    <SignatureCanvas
                        ref={this.signatureCanvas}
                        onBegin={() => input.onFocus()}
                        onEnd={() => input.onChange(this.signatureCanvas.current.toDataURL())}
                        id={input.name}
                        {...input}
                        canvasProps={{
                            width: 700,
                            height: 200,
                            disabled: true
                        }}
                    />
                </div>
            </Input>
        );
    }
}

Signature.defaultProps = {
    fieldProps: {},
    fieldAttr: {},
    field: {},
    input: {},
    meta: {}
};
Signature.propTypes = {
    fieldProps: PropTypes.oneOfType([PropTypes.object]),
    fieldAttr: PropTypes.oneOfType([PropTypes.object]),
    field: PropTypes.oneOfType([PropTypes.object]),
    input: PropTypes.oneOfType([PropTypes.object]),
    meta: PropTypes.oneOfType([PropTypes.object])
};
export default Signature;
