import alert from 'erpassets/svg/alert.svg';
import ball from 'erpassets/svg/ball.svg';
import balldrop from 'erpassets/svg/balldrop.svg';
import balldropWhite from 'erpassets/svg/balldropWhite.svg';
import easPowered from 'erpassets/svg/easPowered.svg';
import date from 'erpassets/svg/date.svg';
import remove from 'erpassets/svg/remove.svg';
import edit from 'erpassets/svg/edit.svg';
import favorites from 'erpassets/svg/favorites.svg';
import file from 'erpassets/svg/file.svg';
import filter from 'erpassets/svg/filter.svg';
import help from 'erpassets/svg/help.svg';
import location from 'erpassets/svg/location.svg';
import lock from 'erpassets/svg/lock.svg';
import locked from 'erpassets/svg/locked.svg';
import menu from 'erpassets/svg/menu.svg';
import move from 'erpassets/svg/move.svg';
import note from 'erpassets/svg/note.svg';
import notificationError from 'erpassets/svg/notificationError.svg';
import notificationSuccess from 'erpassets/svg/notificationSuccess.svg';
import notificationWarning from 'erpassets/svg/notificationWarning.svg';
import passHide from 'erpassets/svg/passHide.svg';
import passShow from 'erpassets/svg/passShow.svg';
import profile from 'erpassets/svg/profile.svg';
import signOut from 'erpassets/svg/signOut.svg';
import search from 'erpassets/svg/search.svg';
import settings from 'erpassets/svg/settings.svg';
import time from 'erpassets/svg/time.svg';
import elementLoader from 'erpassets/svg/elementLoader.svg';
import pageLoader from 'erpassets/svg/pageLoader.svg';
import close from 'erpassets/svg/close.svg';
import arrowDown from 'erpassets/svg/arrowDown.svg';
import arrowLeft from 'erpassets/svg/arrowLeft.svg';
import arrowRight from 'erpassets/svg/arrowRight.svg';
import checkmark from 'erpassets/svg/checkmark.svg';
import action from 'erpassets/svg/action.svg';
import notFound from 'erpassets/svg/notFound.svg';
import arrowDownRight from 'erpassets/svg/arrow-down-and-right.svg';
import connect from 'erpassets/svg/connect.svg';
import resendEmail from 'erpassets/svg/resendEmail.svg';
import editLink from 'erpassets/svg/editLink.svg';
import sorting from 'erpassets/svg/sorting.svg';
import minus from 'erpassets/svg/minus.svg';
import merge from 'erpassets/svg/merge.svg';
import crop from 'erpassets/svg/crop.svg';
import download from 'erpassets/svg/download.svg';
import upload from 'erpassets/svg/upload.svg';
import flipHorizontal from 'erpassets/svg/flipHorizontal.svg';
import flipVertical from 'erpassets/svg/flipVertical.svg';
import minusCircle from 'erpassets/svg/minusCircle.svg';
import plusCircle from 'erpassets/svg/plusCircle.svg';
import rotateLeft from 'erpassets/svg/rotateLeft.svg';
import rotateRight from 'erpassets/svg/rotateRight.svg';

export default {
    alert,
    ball,
    balldrop,
    balldropWhite,
    easPowered,
    date,
    remove,
    edit,
    favorites,
    file,
    filter,
    help,
    location,
    lock,
    locked,
    menu,
    move,
    note,
    notificationError,
    notificationSuccess,
    notificationWarning,
    profile,
    passHide,
    passShow,
    signOut,
    search,
    settings,
    time,
    elementLoader,
    pageLoader,
    close,
    arrowDown,
    arrowLeft,
    arrowRight,
    checkmark,
    action,
    notFound,
    arrowDownRight,
    connect,
    resendEmail,
    editLink,
    sorting,
    minus,
    merge,
    crop,
    download,
    upload,
    flipHorizontal,
    flipVertical,
    minusCircle,
    plusCircle,
    rotateLeft,
    rotateRight
};
