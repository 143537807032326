import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import LayoutManager from 'erputils/LayoutManager';
import PageHeader from 'erpcomponents/Layout/PageHeader';
import PageContent from 'erpcomponents/Layout/PageContent';
import TotalTickets24h from 'erpcomponents/Widgets/TotalTickets24h';
import TicketSocketTickets24h from 'erpcomponents/Widgets/TicketSocketTickets24h';
import SalesCycle from 'erpcomponents/Widgets/SalesCycle';
import SalesOverview from 'erpcomponents/Widgets/SalesOverview';
import TotalSalesByTicketType from 'erpcomponents/Widgets/TotalSalesByTicketType';
import TicketsOverview from 'erpcomponents/Widgets/TicketsOverview';
import EventSalesPerTicketType from 'erpcomponents/Widgets/EventSalesPerTicketType';
import Form, { Select, Autocomplete } from 'erpcomponents/Form';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getIdFromIri } from 'erputils/dto';
import TicketTypes from 'erpcomponents/Widgets/TicketTypes';

class OverallSalesPerEvent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            redirect: false
        };
    }

    componentDidUpdate(prevProps) {
        const { filterValues, match } = this.props;
        const { filterValues: prevFilterValues } = prevProps;

        if (
            getIdFromIri(prevFilterValues.eventFilter) !== getIdFromIri(filterValues.eventFilter) &&
            getIdFromIri(filterValues.eventFilter) !== match.params.id
        ) {
            // eslint-disable-next-line
            this.setState({
                redirect: {
                    pathname: `/dashboard/overall-sales/${getIdFromIri(filterValues.eventFilter)}`,
                    state: { redirect: false }
                }
            });
        }
        return null;
    }

    renderRedirect() {
        const { redirect } = this.state;
        if (redirect) {
            this.setState({ redirect: false });
            return <Redirect to={redirect} />;
        }

        return null;
    }

    renderFormFields() {
        return (
            <Form.Row>
                <Field
                    name="filter"
                    id="filter"
                    fieldProps={{
                        label: (
                            <FormattedMessage
                                id="Dashboards.OverallSalesPerEvent.Filter.DateRange"
                                defaultMessage="Date Range"
                            />
                        ),
                        clearable: false,
                        options: [
                            {
                                label: (
                                    <FormattedMessage
                                        id="Dashboards.OverallSalesPerEvent.Filter.DateRange.Last24h"
                                        defaultMessage="Last 24h"
                                    />
                                ),
                                value: 1
                            },
                            {
                                label: (
                                    <FormattedMessage
                                        id="Dashboards.OverallSalesPerEvent.Filter.DateRange.Last7Days"
                                        defaultMessage="Last 7 Days"
                                    />
                                ),
                                value: 7
                            },
                            {
                                label: (
                                    <FormattedMessage
                                        id="Dashboards.OverallSalesPerEvent.Filter.DateRange.Last30Days"
                                        defaultMessage="Last 30 Days"
                                    />
                                ),
                                value: 30
                            },
                            {
                                label: (
                                    <FormattedMessage
                                        id="Dashboards.OverallSalesPerEvent.Filter.DateRange.Last3Months"
                                        defaultMessage="Last 3 Months"
                                    />
                                ),
                                value: 90
                            },
                            {
                                label: (
                                    <FormattedMessage
                                        id="Dashboards.OverallSalesPerEvent.Filter.DateRange.Last6Months"
                                        defaultMessage="Last 6 Months"
                                    />
                                ),
                                value: 180
                            },
                            {
                                label: (
                                    <FormattedMessage
                                        id="Dashboards.OverallSalesPerEvent.Filter.DateRange.Last12Months"
                                        defaultMessage="Last 12 Months"
                                    />
                                ),
                                value: 365
                            },
                            {
                                label: (
                                    <FormattedMessage
                                        id="Dashboards.OverallSalesPerEvent.Filter.DateRange.AllTime"
                                        defaultMessage="All Time"
                                    />
                                ),
                                value: Infinity
                            }
                        ]
                    }}
                    component={Select}
                />

                <Field
                    name="eventFilter"
                    id="eventFilter"
                    fieldProps={{
                        label: (
                            <FormattedMessage
                                id="Dashboards.OverallSalesPerEvent.Filter.eventFilter"
                                defaultMessage="Event"
                            />
                        ),
                        clearable: false,
                        options: {
                            endpoint: `/api/events?pagination=false&`,
                            mapData: { value: 'iri', label: 'name' }
                        }
                    }}
                    component={Autocomplete}
                />
            </Form.Row>
        );
    }

    render() {
        const { filterValues } = this.props;

        return (
            <LayoutManager slot="main" layoutType="merge">
                <PageHeader
                    title={
                        <FormattedMessage
                            id="Dashboards.OverallSalesPerEvent.title"
                            defaultMessage="Overall Sales per event"
                        />
                    }
                    backButtonLink="/dashboard/overall-sales"
                />
                <PageContent>
                    <Form className="overall-sales">{this.renderFormFields()}</Form>
                    {filterValues && filterValues.eventFilter && (
                        <PageContent.Row>
                            <TotalTickets24h
                                id="totalTickets24h"
                                size="3"
                                eventID={filterValues.eventFilter}
                            />
                            <TicketSocketTickets24h
                                id="ticketSocketTickets24h"
                                size="3"
                                eventID={filterValues.eventFilter}
                            />
                            <SalesCycle
                                id="salesCycle"
                                size="3"
                                eventID={filterValues.eventFilter}
                            />
                            <TicketTypes
                                id="ticketTypes"
                                size="3"
                                eventID={filterValues.eventFilter}
                            />
                            <TotalSalesByTicketType
                                id="totalsSalesPerDate"
                                size="12"
                                dateRange={filterValues.filter}
                                eventID={filterValues.eventFilter}
                            />
                            <EventSalesPerTicketType
                                id="eventSalesPerTicketType"
                                size="12"
                                dateRange={filterValues.filter}
                                eventID={filterValues.eventFilter}
                            />
                            <SalesOverview
                                id="salesOverview"
                                size="12"
                                dateRange={filterValues.filter}
                                eventID={filterValues.eventFilter}
                            />
                            <TicketsOverview
                                id="ticketsOverview"
                                size="12"
                                dateRange={filterValues.filter}
                                eventID={filterValues.eventFilter}
                            />
                            {this.renderRedirect()}
                        </PageContent.Row>
                    )}
                </PageContent>
            </LayoutManager>
        );
    }
}

OverallSalesPerEvent.defaultProps = {
    filterValues: { filter: Infinity, eventFilter: null }
};

OverallSalesPerEvent.propTypes = {
    match: PropTypes.oneOfType([PropTypes.object]).isRequired,
    filterValues: PropTypes.oneOfType([PropTypes.object])
};

//  Getting initial value populated in the form from the store
const mapStateToProps = (state, ownProps) => {
    return {
        filterValues: getFormValues('OverallSalesPerEventForm')(state),
        initialValues: {
            filter: Infinity,
            eventFilter: `/api/events/${ownProps.match.params.id}`
        }
    };
};

OverallSalesPerEvent = reduxForm(
    {
        form: 'OverallSalesPerEventForm',
        enableReinitialize: true
    },
    mapStateToProps
)(OverallSalesPerEvent);

export default connect(mapStateToProps)(OverallSalesPerEvent);
