import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import ElementLoader from 'erpcomponents/ElementLoader';
import Widget from 'erpcomponents/Widget';

import './EventSalesPerTicketType.scss';

import {
    getData,
    getFetching
} from 'erpcomponents/Widgets/EventSalesPerTicketType/EventSalesPerTicketType.selectors';
import { actions as ticketTypesReportActions } from 'erpcomponents/Widgets/TicketTypesReport/TicketTypesReport.reducer';
import { actions as eventSalesActions } from 'erpcomponents/Widgets/EventSalesPerTicketType/EventSalesPerTicketType.reducer';
import Listing from 'erpcomponents/Listing';
import TicketTypesReport from 'erpcomponents/Widgets/TicketTypesReport';
import { getFormValues, reduxForm } from 'redux-form';
import Button from 'erpcomponents/Button';
import { getIdFromIri } from 'erputils/dto';

class EventSalesPerTicketType extends Component {
    constructor(props) {
        super(props);

        this.state = {
            detailsDisplayed: false
        };
    }

    componentDidMount() {
        const { eventSeries, eventID, dateRange } = this.props;
        if (eventSeries || eventID) {
            return this.fetchData({
                eventSeries,
                eventID,
                dateRange
            });
        }
        return null;
    }

    componentWillReceiveProps(nextProps) {
        const { dateRange, setFormValue } = this.props;
        const { dateRange: nextGlobalDateRange } = nextProps;

        if (dateRange !== nextGlobalDateRange) {
            return setFormValue('filter', nextGlobalDateRange);
        }

        return true;
    }

    componentDidUpdate(prevProps) {
        const { dateRange, eventID, filterValues, eventSeries } = this.props;
        const { eventID: prevEventID, eventSeries: prevEventSeries, soldBy } = prevProps;

        let willFetchData = false;

        if (
            prevProps.filterValues &&
            prevProps.filterValues.filter &&
            filterValues.filter &&
            prevProps.filterValues.filter !== filterValues.filter
        ) {
            willFetchData = true;
        }

        if (prevEventSeries !== eventSeries) {
            willFetchData = true;
        }

        if (prevEventID && prevEventID !== eventID) {
            willFetchData = true;
        }

        if (willFetchData) {
            return this.fetchData({ dateRange, soldBy, eventSeries, eventID });
        }

        return true;
    }

    fetchData({ dateRange, eventSeries, eventID }) {
        const { dispatch, id, filterValues } = this.props;

        const params = {
            display_by: 'ticket_type'
        };

        if (filterValues.filter !== dateRange && filterValues.filter !== Infinity) {
            const now = new Date();
            const startDate = new Date(now.getTime() - filterValues.filter * 24 * 60 * 60 * 1000);
            params['filters[start_date]'] = startDate;
            params['filters[end_date]'] = now;
        } else if (dateRange !== Infinity && filterValues.filter === dateRange) {
            const now = new Date();
            const startDate = new Date(now.getTime() - dateRange * 24 * 60 * 60 * 1000);
            params['filters[start_date]'] = startDate;
            params['filters[end_date]'] = now;
        }

        if (eventID) {
            params['filters[events]'] = eventID;
        }

        if (eventSeries) {
            params['filters[event_series]'] = eventSeries;
        }

        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: eventSalesActions.START_FETCHING_EVENT_SALES_PER_TICKET_TYPE_DATA,
                widgetId: id,
                params
            });
        }).catch(error => ({ error }));
    }

    fetchTicketTypeData(id, ticketTypeID) {
        const { dispatch } = this.props;

        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: ticketTypesReportActions.START_FETCHING_TICKET_TYPES_REPORT_DATA,
                widgetId: id,
                iri: `/api/ticket-type/${ticketTypeID}`
            });
        }).catch(error => ({ error }));
    }

    displayTicketTypeDetails(id, ticketTypeID, ticketTypeName) {
        const wrapper = document.getElementsByClassName('event-sales-per-ticket-type')[0];
        wrapper.classList.add('event-sales-per-ticket-type--animate-details');
        this.setState({ detailsDisplayed: true, id, ticketTypeID, ticketTypeName });
        this.fetchTicketTypeData(id, ticketTypeID);
        wrapper.getElementsByClassName('listing')[0].style.height = '0';
        wrapper.getElementsByClassName('listing')[0].style.minHeight = `150px`;
    }

    displayEventTickets() {
        const wrapper = document.getElementsByClassName('event-sales-per-ticket-type')[0];
        wrapper.classList.remove('event-sales-per-ticket-type--animate-details');
        this.setState({
            detailsDisplayed: false,
            ticketTypeName: null,
            id: null,
            ticketTypeID: null
        });
        wrapper.getElementsByClassName('listing')[0].style.height = 'auto';
    }

    tableData() {
        const { data } = this.props;
        let { eventID } = this.props;
        eventID = getIdFromIri(eventID);
        const table = {};

        table.data = [];

        // Table Schema
        table.schema = [
            {
                title: (
                    <FormattedMessage
                        id="EventSalesPerTicketType.Table.EventName"
                        defaultMessage="Ticket Type"
                    />
                ),
                field: 'ticket_type'
            },
            {
                title: (
                    <FormattedMessage
                        id="EventSalesPerTicketType.Table.Current Price"
                        defaultMessage="Current Price"
                    />
                ),
                field: 'current_price'
            },
            {
                title: (
                    <FormattedMessage id="EventSales.Table.Available" defaultMessage="Available" />
                ),
                field: 'available'
            },
            {
                title: (
                    <FormattedMessage
                        id="EventSalesPerTicketType.Table.Attendance"
                        defaultMessage="Tickets sold"
                    />
                ),
                field: 'attendance'
            },
            {
                title: (
                    <FormattedMessage
                        id="EventSalesPerTicketType.Table.PercentageSold"
                        defaultMessage="% Sold"
                    />
                ),
                field: 'sales_cycle'
            },
            {
                title: (
                    <FormattedMessage
                        id="EventSalesPerTicketType.Table.Revenue"
                        defaultMessage="Revenue"
                    />
                ),
                field: 'revenue'
            },
            {
                title: (
                    <FormattedMessage
                        id="EventSalesPerTicketType.Table.RevenuePerAttendee"
                        defaultMessage="Revenue per Attendee"
                    />
                ),
                field: 'revenuePerAttendee'
            },
            {
                title: (
                    <FormattedMessage id="EventSales.Table.Delivery" defaultMessage="Delivery" />
                ),
                field: 'delivery'
            },
            {
                title: <FormattedMessage id="EventSales.Table.Taxes" defaultMessage="Taxes" />,
                field: 'taxes'
            },
            {
                title: (
                    <FormattedMessage
                        id="EventSales.Table.ServiceFees"
                        defaultMessage="Service Fees"
                    />
                ),
                field: 'service_fees',
                align: 'right'
            }
        ];

        if (data && data.data) {
            const dataKeys = Object.keys(data.data);

            let revenueSum = 0;
            let ticketsSoldSum = 0;
            let revenuePerAttendeeSum = 0;
            let revenueCurrencyTotals = null;
            let deliverySum = 0;
            let taxesSum = 0;
            let serviceFeesSum = 0;
            let availableSum = 0;

            // Table Data
            dataKeys.map(key => {
                const id = data.data[key] && data.data[key].id && getIdFromIri(data.data[key].id);
                const revenue =
                    data.data[key] && data.data[key].revenue && data.data[key].revenue_currency
                        ? ((style = 'currency') => {
                              return (
                                  <FormattedNumber
                                      value={Math.round(data.data[key].revenue / 100)}
                                      currency={data.data[key].revenue_currency}
                                      style={style}
                                      minimumFractionDigits={0}
                                      maximumFractionDigits={0}
                                  />
                              );
                          })()
                        : data.data[key].revenue;
                const salesCycle =
                    data.data[key] &&
                    data.data[key].tickets_sold &&
                    data.data[key].max_tickets_to_sell > 0
                        ? (data.data[key].tickets_sold / data.data[key].max_tickets_to_sell) * 100
                        : 0;
                const attendance =
                    data.data[key] && data.data[key].attendance && data.data[key].attendance;
                const revenuePerAttendee =
                    data.data[key] && data.data[key].revenue && data.data[key].revenue_currency
                        ? ((style = 'currency') => {
                              return (
                                  <FormattedNumber
                                      value={data.data[key].revenue / 100 / attendance}
                                      currency={data.data[key].revenue_currency}
                                      style={style}
                                      minimumFractionDigits={2}
                                      maximumFractionDigits={2}
                                  />
                              );
                          })()
                        : data.data[key].revenue / 100 / attendance;

                const available = (() => {
                    if (data.data[key] && data.data[key].max_tickets_to_sell > 0) {
                        const sum =
                            data.data[key].max_tickets_to_sell - data.data[key].tickets_sold;
                        return sum;
                    }

                    return 0;
                })();

                const currentPrice =
                    data.data[key] &&
                    data.data[key].current_price &&
                    parseInt(data.data[key].current_price, 0);

                const delivery =
                    data.data[key] &&
                    data.data[key].delivery_cost_amount &&
                    data.data[key].revenue_currency
                        ? ((style = 'currency') => {
                              return (
                                  <FormattedNumber
                                      value={data.data[key].delivery_cost_amount / 100}
                                      currency={data.data[key].revenue_currency}
                                      style={style}
                                      minimumFractionDigits={2}
                                      maximumFractionDigits={2}
                                  />
                              );
                          })()
                        : data.data[key].delivery_cost_amount;

                const taxes =
                    data.data[key] && data.data[key].tax_amount && data.data[key].revenue_currency
                        ? ((style = 'currency') => {
                              return (
                                  <FormattedNumber
                                      value={data.data[key].tax_amount / 100}
                                      currency={data.data[key].revenue_currency}
                                      style={style}
                                      minimumFractionDigits={2}
                                      maximumFractionDigits={2}
                                  />
                              );
                          })()
                        : data.data[key].tax_amount;

                const serviceFees =
                    data.data[key] &&
                    data.data[key].services_amount &&
                    data.data[key].revenue_currency
                        ? ((style = 'currency') => {
                              return (
                                  <FormattedNumber
                                      value={data.data[key].services_amount / 100}
                                      currency={data.data[key].revenue_currency}
                                      style={style}
                                      minimumFractionDigits={2}
                                      maximumFractionDigits={2}
                                  />
                              );
                          })()
                        : data.data[key].tax_amount;

                revenueSum += data.data[key] && data.data[key].revenue && data.data[key].revenue;
                ticketsSoldSum +=
                    data.data[key] && data.data[key].attendance && data.data[key].attendance;
                revenuePerAttendeeSum +=
                    data.data[key] &&
                    data.data[key].revenue &&
                    data.data[key].attendance &&
                    data.data[key].revenue / 100 / data.data[key].attendance;
                revenueCurrencyTotals = data.data[key] && data.data[key].revenue_currency;
                deliverySum +=
                    data.data[key] &&
                    data.data[key].delivery_cost_amount &&
                    data.data[key].delivery_cost_amount / 100;

                taxesSum +=
                    data.data[key] && data.data[key].tax_amount && data.data[key].tax_amount / 100;

                serviceFeesSum +=
                    data.data[key] &&
                    data.data[key].services_amount &&
                    data.data[key].services_amount / 100;

                availableSum += available;

                return table.data.push({
                    id: key,
                    ticket_type: (
                        <Button
                            className="event-sales-per-ticket-type__details-button"
                            variation="tertiary"
                            onClick={() => this.displayTicketTypeDetails(eventID, id, key)}
                            label={key}
                        />
                    ),
                    current_price: ((style = 'currency') => {
                        return (
                            <FormattedNumber
                                value={currentPrice / 100}
                                currency={data.data[key].revenue_currency}
                                style={style}
                                minimumFractionDigits={2}
                                maximumFractionDigits={2}
                            />
                        );
                    })(),
                    revenue,
                    revenuePerAttendee,
                    attendance: data.data[key] && data.data[key].attendance && (
                        <FormattedNumber value={data.data[key].attendance} />
                    ),
                    available: <FormattedNumber value={available} />,
                    delivery,
                    taxes,
                    service_fees: serviceFees,
                    sales_cycle: `${salesCycle.toFixed(1)} %`
                });
            });

            table.data.push({
                ticket_type: (
                    <strong>
                        <FormattedMessage id="EventSales.Totals" defaultMessage="Totals:" />
                    </strong>
                ),
                revenue: ((style = 'currency') => {
                    return (
                        <strong>
                            <FormattedNumber
                                value={Math.round(revenueSum / 100)}
                                currency={revenueCurrencyTotals}
                                style={style}
                                minimumFractionDigits={0}
                                maximumFractionDigits={0}
                            />
                        </strong>
                    );
                })(),
                attendance: (
                    <strong>
                        <FormattedNumber value={ticketsSoldSum} />
                    </strong>
                ),
                revenuePerAttendee: ((style = 'currency') => {
                    return (
                        <strong>
                            <FormattedNumber
                                value={Math.round(revenuePerAttendeeSum)}
                                currency={revenueCurrencyTotals}
                                style={style}
                                minimumFractionDigits={2}
                                maximumFractionDigits={2}
                            />
                        </strong>
                    );
                })(),
                available: (
                    <strong>
                        <FormattedNumber value={availableSum} />
                    </strong>
                ),
                delivery: ((style = 'currency') => {
                    return (
                        <strong>
                            <FormattedNumber
                                value={Math.round(deliverySum)}
                                currency={revenueCurrencyTotals}
                                style={style}
                                minimumFractionDigits={2}
                                maximumFractionDigits={2}
                            />
                        </strong>
                    );
                })(),
                taxes: ((style = 'currency') => {
                    return (
                        <strong>
                            <FormattedNumber
                                value={Math.round(taxesSum)}
                                currency={revenueCurrencyTotals}
                                style={style}
                                minimumFractionDigits={2}
                                maximumFractionDigits={2}
                            />
                        </strong>
                    );
                })(),
                service_fees: ((style = 'currency') => {
                    return (
                        <strong>
                            <FormattedNumber
                                value={Math.round(serviceFeesSum)}
                                currency={revenueCurrencyTotals}
                                style={style}
                                minimumFractionDigits={2}
                                maximumFractionDigits={2}
                            />
                        </strong>
                    );
                })(),
                sales_cycle: ''
            });
        }

        return table;
    }

    render() {
        const { size, fetching } = this.props;
        const { detailsDisplayed, id, ticketTypeID, ticketTypeName } = this.state;

        return (
            <Widget
                title={
                    <span>
                        {detailsDisplayed && (
                            <Button
                                className="event-sales-per-ticket-type__back-button"
                                label=""
                                variation="tertiary"
                                iconName="arrowLeft"
                                onClick={() => this.displayEventTickets()}
                            />
                        )}
                        <FormattedMessage
                            id="EventSalesPerTicketType.Title"
                            defaultMessage="Event sales per Ticket Type {ticketTypeName}"
                            values={{
                                ticketTypeName: ticketTypeName && `- ${ticketTypeName}`
                            }}
                        />
                    </span>
                }
                size={size}
                className="event-sales-per-ticket-type"
            >
                {fetching && <ElementLoader overlay />}
                <Listing
                    table={this.tableData()}
                    loading={fetching}
                    hideSearch
                    hideFooter
                    hideFilters
                    hideHeader
                />
                <TicketTypesReport id={id} ticketTypeID={ticketTypeID} />
            </Widget>
        );
    }
}

EventSalesPerTicketType.defaultProps = {
    size: null,
    fetching: false,
    data: {},
    filterValues: { filter: Infinity },
    eventSeries: null,
    eventID: null,
    soldBy: null,
    setFormValue: () => {},
    dateRange: Infinity
};

EventSalesPerTicketType.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    dispatch: PropTypes.func.isRequired,
    fetching: PropTypes.bool,
    filterValues: PropTypes.oneOfType([PropTypes.object]),
    data: PropTypes.oneOfType([PropTypes.object]),
    eventSeries: PropTypes.string,
    eventID: PropTypes.string,
    soldBy: PropTypes.string,
    dateRange: PropTypes.number,
    setFormValue: PropTypes.func
};

//  Getting initial value populated in the form from the store
const mapStateToProps = (state, ownProps) => {
    return {
        data: getData(state, ownProps.id),
        fetching: getFetching(state, ownProps.id),
        filterValues: getFormValues('EventSalesPerTicketTypeForm')(state),
        initialValues: { filter: ownProps.dateRange }
    };
};

EventSalesPerTicketType = reduxForm(
    {
        form: 'EventSalesPerTicketTypeForm'
    },
    mapStateToProps
)(EventSalesPerTicketType);

export default connect(mapStateToProps)(EventSalesPerTicketType);
