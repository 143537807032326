export const stringOperations = [
    { label: 'Equals', value: 'equals' },
    { label: 'Contains', value: 'contains' }
];

export const existOperations = [
    { label: 'True', value: 'exists' },
    { label: 'False', value: 'not_exists' }
];

/*
export const stringOperations = [
    { label: 'Equals', value: 'equals' },
    { label: 'Not Equals', value: 'not_equals' },
    { label: 'Contains', value: 'contains' },
    { label: 'Not Contains', value: 'not_contains' },
    { label: 'Starts With', value: 'starts_with' },
    { label: 'Not Starts With', value: 'not_starts_with' },
    { label: 'End With', value: 'ends_with' }
];
*/
export const arrayOperations = [
    { label: 'In', value: 'in' },
    { label: 'Not in', value: 'not_in' },
    { label: 'Between', value: 'between' },
    { label: 'Not Between', value: 'not_between' }
];

export const intOperations = [
    { label: 'Smaller Than', value: 'smaller_than' },
    { label: 'Smaller Than Or Equal', value: 'smaller_than_or_equal' },
    { label: 'Larger Than', value: 'larger_than' },
    { label: 'Larger Than Or Equal', value: 'larger_than_or_equal' },
    { label: 'Equal', value: 'equals' }
];

export const operations = [
    ...stringOperations,
    ...arrayOperations,
    ...intOperations,
    ...existOperations
];
