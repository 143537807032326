import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm, SubmissionError, formValueSelector } from 'redux-form';
// import valueValidation from 'erputils/valueValidation';
import { FormattedMessage } from 'react-intl';
import reduxFormErrorMapper from 'erputils/reduxFormErrorMapper';
import Form, { Text, Autocomplete, MultiCreatable, Switch } from 'erpcore/components/Form';
import LayoutManager from 'erputils/LayoutManager';
import PageHeader from 'erpcomponents/Layout/PageHeader';
import PageContent from 'erpcomponents/Layout/PageContent';
import ResellerInfo from 'erpcore/screens/Resellers/components/ResellerInfo';
import ResellerEditTabs from 'erpcore/screens/Resellers/components/ResellerEditTabs';
import Button from 'erpcomponents/Button';
import { diff } from 'deep-object-diff';
import { actions as resellersActions } from 'erpsrc/screens/Resellers/Resellers.reducer';
import { dtoForm } from 'erputils/dto';
import { getResellerData } from 'erpsrc/screens/Resellers/Resellers.selectors';
import valueValidation from 'erputils/valueValidation';

class ResellerThirdPartyConnect extends Component {
    constructor(props) {
        super(props);
        this.state = { eventsIncluded: false };

        this.onSubmit = this.onSubmit.bind(this);
        this.toggleEventsIncluded = this.toggleEventsIncluded.bind(this);
    }

    componentDidMount() {
        const { fetchResellerData } = this.props;
        fetchResellerData().then(() => {
            const { formValues } = this.props;
            if (formValues.events_included.length > 0) {
                this.setState({ eventsIncluded: true });
            }
        });
    }

    onSubmit(formData) {
        const { match, dispatch, initialValues } = this.props;
        // send only changed data
        const data = diff(initialValues, formData);

        if (data.events_included) {
            data.events_included = formData.events_included;
        }
        if (data.events_excluded) {
            data.events_excluded = formData.events_excluded;
        }

        if (data.nye_sub_domain === '') {
            data.nye_sub_domain = null;
        }

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: resellersActions.START_UPDATE_SINGLE_RESELLER_GENERAL_INFO,
                id: match.params.id,
                formData: data
            })
        ).catch(error => {
            throw new SubmissionError(reduxFormErrorMapper(error));
        });
    }

    toggleEventsIncluded() {
        const { eventsIncluded } = this.state;

        this.setState({ eventsIncluded: !eventsIncluded });
    }

    render() {
        const {
            handleSubmit,
            submitting,
            pristine,
            invalid,
            fetchResellerData,
            formValues
        } = this.props;
        const { eventsIncluded } = this.state;

        return (
            <LayoutManager slot="main" className="main--narrow" layoutType="merge">
                <PageHeader
                    title={
                        <FormattedMessage
                            id="ResellerEdit.3DPC.title"
                            defaultMessage="Edit Reseller"
                        />
                    }
                    backButtonLink="/resellers"
                />
                <ResellerInfo fetchResellerData={fetchResellerData} />
                <ResellerEditTabs />
                <PageContent>
                    {/* {error && error.code && <NotificationManager code={error.code} />} */}
                    <Form onSubmit={handleSubmit(this.onSubmit)}>
                        <Form.Row>
                            <Form.Row>
                                <Field
                                    name="get_out_affiliate_id"
                                    id="get_out_affiliate_id"
                                    fieldProps={{
                                        label: (
                                            <FormattedMessage
                                                id="ResellerEdit.3DPC.CraveEventID"
                                                defaultMessage="Crave Event ID"
                                            />
                                        ),
                                        clearable: true
                                    }}
                                    component={Text}
                                />
                            </Form.Row>
                        </Form.Row>
                        <Form.Row>
                            <Form.Row>
                                <Field
                                    name="ticket_socket_affiliate_id"
                                    id="ticket_socket_affiliate_id"
                                    fieldProps={{
                                        label: (
                                            <FormattedMessage
                                                id="ResellerEdit.3DPC.TicketsocketEventID"
                                                defaultMessage="Ticketsocket Event ID"
                                            />
                                        ),
                                        clearable: true
                                    }}
                                    component={Text}
                                />
                            </Form.Row>
                        </Form.Row>
                        <Form.Row>
                            <Form.Row>
                                <Field
                                    name="facebook_id"
                                    id="facebook_id"
                                    fieldProps={{
                                        label: (
                                            <FormattedMessage
                                                id="ResellerEdit.3DPC.FacebookId"
                                                defaultMessage="Facebook App ID"
                                            />
                                        ),
                                        clearable: true
                                    }}
                                    component={Text}
                                />
                            </Form.Row>
                        </Form.Row>
                        <Form.Row>
                            <Form.Row>
                                <Field
                                    name="google_id"
                                    id="google_id"
                                    fieldProps={{
                                        label: (
                                            <FormattedMessage
                                                id="ResellerEdit.3DPC.GoogleId"
                                                defaultMessage="Google Site Verification"
                                            />
                                        ),
                                        clearable: true
                                    }}
                                    component={Text}
                                />
                            </Form.Row>
                        </Form.Row>
                        <Form.Row>
                            <Field
                                name="reseller_primary_account"
                                id="reseller_primay_account"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="ResellerEdit.PrimaryAccount"
                                            defaultMessage="Reseller Primary Account"
                                        />
                                    ),
                                    options: {
                                        endpoint: '/api/users?pagination=false',
                                        mapData: {
                                            value: 'iri',
                                            label: '{first_name} {last_name} <{email}>'
                                        }
                                    },
                                    clearable: true,
                                    actionButton: {
                                        edit: {
                                            url: {
                                                prefix: '/users/',
                                                suffix: '/edit'
                                            }
                                        },
                                        create: {
                                            url: '/users/create'
                                        }
                                    }
                                }}
                                component={Autocomplete}
                                validate={valueValidation([{ validator: 'required' }])}
                                fieldAttr={{ required: true }}
                            />
                        </Form.Row>
                        <Form.Row>
                            <Field
                                name="nye_sub_domain"
                                id="nye_sub_domain"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="ResellerEdit.MicrositeUrl"
                                            defaultMessage="Microsite URL"
                                        />
                                    ),
                                    clearable: true
                                }}
                                format={value => {
                                    const mask = '.nye.com';
                                    return value === null || value === undefined
                                        ? mask
                                        : `${value}${mask}`;
                                }}
                                normalize={value => {
                                    const mask = '.nye.com';
                                    // if (/^[a-z\d-]+$/g.test(value) || value === '')
                                    //     return value;
                                    return value === null || value === undefined
                                        ? null
                                        : value.replace(mask, '');
                                }}
                                component={Text}
                            />
                        </Form.Row>
                        <Form.Row>
                            <span>
                                You can only use lowercase letters, hyphen and numbers for Microsite
                                URL. Microsite URL will be deployed to .nye.com.
                            </span>
                        </Form.Row>

                        <div className="events-toggle">
                            <span
                                className={`events-toggle${
                                    !eventsIncluded ? '__enabled' : '__disabled'
                                }`}
                            >
                                Events excluded
                            </span>
                            <Field
                                name="includeEvents"
                                id="includeEvents"
                                input={{
                                    value: eventsIncluded,
                                    onChange:
                                        formValues.events_excluded.length > 0 ||
                                        formValues.events_included.length > 0
                                            ? () => {}
                                            : this.toggleEventsIncluded
                                }}
                                fieldProps={{
                                    on: {
                                        label: 'Events included',
                                        value: true,
                                        id: 'switch-on-true'
                                    },
                                    off: {
                                        label: 'Events excldued',
                                        value: false,
                                        id: 'switch-off-false'
                                    },
                                    type: 'single'
                                }}
                                component={Switch}
                            />
                            <span
                                className={`events-toggle${
                                    eventsIncluded ? '__enabled' : '__disabled'
                                }`}
                            >
                                Events included
                            </span>
                        </div>
                        {eventsIncluded ? (
                            <Form.Row>
                                <Field
                                    name="events_included"
                                    id="events_included"
                                    fieldProps={{
                                        options: {
                                            endpoint: '/api/events?filters[end_utc][upcoming]=true',
                                            mapData: {
                                                value: 'iri',
                                                label: 'name'
                                            }
                                        },
                                        clearable: true
                                    }}
                                    component={MultiCreatable}
                                />
                            </Form.Row>
                        ) : (
                            <Form.Row>
                                <Field
                                    name="events_excluded"
                                    id="events_excluded"
                                    fieldProps={{
                                        options: {
                                            endpoint: '/api/events?filters[end_utc][upcoming]=true',
                                            mapData: {
                                                value: 'iri',
                                                label: 'name'
                                            }
                                        },
                                        clearable: true
                                    }}
                                    component={MultiCreatable}
                                />
                            </Form.Row>
                        )}

                        <Form.Row>
                            <Button
                                submit
                                disabled={pristine || invalid}
                                loading={submitting}
                                label={
                                    <FormattedMessage
                                        id="ResellerEdit.3DPC.Update"
                                        defaultMessage="Update"
                                    />
                                }
                            />
                        </Form.Row>
                    </Form>
                </PageContent>
            </LayoutManager>
        );
    }
}

ResellerThirdPartyConnect.defaultProps = {
    submitting: false,
    pristine: false,
    invalid: false,
    fetchResellerData: () => {},
    initialValues: {},
    formValues: {}
};

ResellerThirdPartyConnect.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.oneOfType([PropTypes.object]).isRequired,
    initialValues: PropTypes.oneOfType([PropTypes.object]),
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    fetchResellerData: PropTypes.func,
    formValues: PropTypes.oneOfType([PropTypes.object])
};

ResellerThirdPartyConnect = reduxForm({
    form: 'ResellerThirdPartyConnectForm',
    enableReinitialize: true
})(ResellerThirdPartyConnect);

//  Getting initial value populated in the form from the store
const mapStateToProps = (state, ownProps) => {
    const getFormValues = formValueSelector('ResellerThirdPartyConnectForm');
    return {
        initialValues: dtoForm(getResellerData(state, ownProps.match.params.id)),
        formValues: {
            events_included: getFormValues(state, 'events_included') || [],
            events_excluded: getFormValues(state, 'events_excluded') || []
        }
    };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchResellerData: () => {
        // Getting included data from API by setting params
        const params = {
            include:
                'company,resellerPrimaryAccount,eventsIncluded,eventsExcluded,eventDescriptions,eventDescriptions.event'
        };
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: resellersActions.START_FETCHING_SINGLE_RESELLER,
                id: ownProps.match.params.id,
                params
            });
        }).catch(error => ({ error }));
    }
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ResellerThirdPartyConnect)
);
