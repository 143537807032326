import React from 'react';
import { Pie } from 'react-chartjs-2';

const PieChart = chartData => {
    return (
        <Pie
            data={chartData.data}
            width={60}
            height={60}
            options={{
                tooltips: {
                    enabled: false
                },
                legend: {
                    display: false
                }
            }}
        />
    );
};

export default PieChart;
