import { takeLatest, put, call, select } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import { actions as notificationManagerActions } from 'erputils/NotificationManager/NotificationManager.reducer';
import { parseParamsForApi } from 'erputils/utils';
import { actions as listingActions } from 'erpcomponents/Listing/Listing.reducer';
import dto from 'erputils/dto';
import { getListingUrlQueryParams } from 'erputils/RouterManager/RouterManager.selectors';
import { actions as eventSeriesActions } from './EventSeries.reducer';

/**
 * Create Event Series
 * @param  {Object} promise
 * @return {Object} formData
 */
export function* createSeries({ promise, formData }) {
    try {
        // Create Series
        const createSeriesAPI = yield restClient.post(`api/event-series`, formData);
        yield put({
            type: eventSeriesActions.CREATE_EVENT_SERIES_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createSeriesAPI.data
        });
        yield put({
            type: listingActions.START_FETCHING_LISTING,
            entity: 'EVENT_SERIES',
            endpoint: 'api/event-series',
            params: { pagination: false }
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: eventSeriesActions.CREATE_EVENT_SERIES_FAILED,
            response: error.response.data
        });

        yield call(promise.reject, error.response.data);
    }
}

/**
 * Fetch Event Series
 * @param  {Object} promise
 * @return {string} id Event id
 */
export function* fetchEventSeries({ promise, id }) {
    try {
        const fetchEventSeriesAPI = yield restClient.get(`api/event-series/${id}`);
        yield put({
            type: eventSeriesActions.FETCH_SINGLE_EVENT_SERIES_SUCCESSFUL
        });
        yield put({
            type: eventSeriesActions.STORE_SINGLE_EVENT_SERIES_DATA,
            id,
            response: dto(fetchEventSeriesAPI.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: eventSeriesActions.FETCH_SINGLE_EVENT_SERIES_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}
/**
 * Update event series single data
 * @param  {Object} id ID of an event
 * @return {Object} Response from API
 */
export function* updateSingleEventSeries({ promise, formData, id }) {
    try {
        const updateSingleEventSeriesAPI = yield restClient.put(`api/event-series/${id}`, formData);

        yield put({
            type: eventSeriesActions.UPDATE_SINGLE_EVENT_SERIES_SUCCESSFUL
        });
        yield put({
            type: eventSeriesActions.STORE_SINGLE_EVENT_SERIES_DATA,
            id,
            response: dto(updateSingleEventSeriesAPI.data)
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateSingleEventSeriesAPI.data
        });
        yield put({
            type: listingActions.START_FETCHING_LISTING,
            entity: 'EVENT_SERIES',
            endpoint: 'api/event-series',
            params: { pagination: false }
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: eventSeriesActions.UPDATE_SINGLE_EVENT_SERIES_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Delete Single Series
 * @param  {Object} id ID of an Event
 * @return {Object} Response from API
 */
export function* deleteSingleSeries({ promise, id }) {
    try {
        // Merge default with current params for listing refresh purpose
        const params = Object.assign(
            {},
            { include: 'location.city' },
            yield select(getListingUrlQueryParams)
        );

        const deleteSingleSeriesAPI = yield restClient.delete(`api/event-series/${id}`);
        yield put({
            type: eventSeriesActions.DELETE_SINGLE_EVENT_SERIES_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteSingleSeriesAPI.data
        });
        yield put({
            type: listingActions.START_FETCHING_LISTING,
            entity: 'EVENT_SERIES',
            endpoint: 'api/event-series',
            params: parseParamsForApi(params)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: eventSeriesActions.DELETE_SINGLE_EVENT_SERIES_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

export const eventSeriesSaga = [
    takeLatest(eventSeriesActions.START_CREATE_EVENT_SERIES, createSeries),
    takeLatest(eventSeriesActions.START_DELETE_SINGLE_EVENT_SERIES, deleteSingleSeries),
    takeLatest(eventSeriesActions.START_FETCHING_SINGLE_EVENT_SERIES, fetchEventSeries),
    takeLatest(eventSeriesActions.START_UPDATE_SINGLE_EVENT_SERIES, updateSingleEventSeries)
];
