/**
 * Collection of `util` helper functions
 */

const getOptionByValue = (value, options) => {
    //  Getting select required value from options
    let fieldValue = '';

    if (value || typeof value === 'boolean') {
        const filteredOption = options.find(o => o.value === value);
        //  If option exits inside options array store it to option
        if (filteredOption) {
            fieldValue = filteredOption;
        } else {
            // If Option is not found send the value
            fieldValue = {
                value,
                label: value
            };
        }
    }

    return fieldValue;
};

/**
 *
 * @param values {array|string|number}
 * @param options {array}
 * @return {Array}
 */
const getOptionsByValues = (values = [], options) => {
    const fieldValues = [];
    if (values && !Array.isArray(values)) {
        values = [values];
    }
    if (values && values.length) {
        values.forEach(value => {
            const filteredOption = options.find(o => o.value === value);
            //  If option exits inside options array store it to option
            if (filteredOption) {
                fieldValues.push({
                    value,
                    label: filteredOption.label
                });
            } else {
                // If Option is not found send the value
                fieldValues.push({
                    value,
                    label: value
                });
            }
        });
    }
    return fieldValues;
};

//  Used for parsing value of filter object
const parseParamsValueForApi = filterItem => {
    const tempFilterItem = Object.assign({}, filterItem);
    delete tempFilterItem.operator;
    let pharsedValue = Object.keys(tempFilterItem).length > 1 ? [] : '';

    Object.keys(tempFilterItem).map(filterItemKey => {
        if (Array.isArray(pharsedValue)) {
            pharsedValue.push(tempFilterItem[filterItemKey]);
        } else {
            pharsedValue = tempFilterItem[filterItemKey];
        }
        return true;
    });

    return pharsedValue;
};

/*
 *  Converts param objects to API accepted params
 *  ex. converts filter objects from regular javascript object
 *      to API readable object:
 *      from: filter {
 *              name: {
 *                  operator 'contains',
 *                  value: 'value'
 *                  }
 *             }
 *      to:
 *          filter[name][contains]=value
 */
const parseParamsForApi = (params, filterSchema) => {
    const pharsedParams = {};
    //  Go trough all params
    Object.keys(params).map(paramsKey => {
        const item = params[paramsKey];

        //  Preparing api params for filter API
        //  Checking if filter object is array or object and preparing object to API readable filters
        if (typeof item === 'object' && paramsKey === 'filter') {
            Object.keys(item).map(paramKey => {
                //  If filter is an array of objects (for multiple filtered items)
                if (Array.isArray(item[paramKey])) {
                    item[paramKey].map(filterItem => {
                        if (filterItem.operator) {
                            //  If you want to have deep filtering like location.city you should pass name like location>city
                            //  Preparing filter[field][firstOperator]=value
                            //            filter[field][secondOperator]=value
                            //  `${paramsKey}[${paramKey.replace('>
                            //  This has been hardcoded to filters bus can be dynamicly also like example above
                            //  `filters[${paramKey.replace('>
                            pharsedParams[
                                `filters[${paramKey.replace(/>/g, '.')}][${filterItem.operator}]`
                            ] = parseParamsValueForApi(filterItem);
                        } else {
                            let filterOperator = 'equals';
                            if (filterSchema && filterSchema.length) {
                                filterSchema.map(filterSchemaItem => {
                                    if (filterSchemaItem.name === paramKey.replace('>', '.')) {
                                        if (filterSchemaItem.defaultOperator) {
                                            filterOperator = filterSchemaItem.defaultOperator;
                                        }
                                    }

                                    return filterOperator;
                                });
                            }
                            //  Preparing filter[field]=value
                            //  Hardcoding equals beacuse API doesnt suport without operator
                            pharsedParams[
                                `filters[${paramKey.replace(/>/g, '.')}][${filterOperator}]`
                            ] = parseParamsValueForApi(filterItem);
                        }
                        return true;
                    });
                }
                return true;
            });
        } else {
            //  If is normal pram just send it directly
            pharsedParams[paramsKey] = item;
        }

        //  Prepare order_by
        if (paramsKey === 'order_by') {
            const orderKey = Object.keys(item)[0];
            pharsedParams[`${paramsKey}[${orderKey}]`] = item[orderKey];
            delete pharsedParams.order_by;
        }

        return true;
    });
    return pharsedParams;
};

/**
 * Set default order_by params
 * @param {object|object}
 * @return {object}
 */

const initialOrderBy = (params, query) => {
    let flag = false;
    if (params) {
        const paramKeys = Object.keys(params);

        paramKeys.map(fullKey => {
            if (fullKey.match(/order_by/g)) {
                flag = true;
            }
            return flag;
        });
    } else {
        params = Object.assign({}, query, params);
    }

    if (flag === false) {
        params = Object.assign({}, query, params);
    }

    return params;
};

/**
 * Access Object with string key as property path
 * @param object {Object}
 * @param path {string} String key
 * @return {*}
 */
const resolveObjectPathByString = (object, path) =>
    path
        .split(/[.[\]'"]/)
        .filter(p => p)
        .reduce((o, p) => (o ? o[p] : null), object);

/**
 * Converts 24h format into 12h with am/pm sufixes
 * @param String {String}
 * @return {String}
 *  */
const formatAmPm = (hours, minutes) => {
    let h = hours;
    let m = minutes;
    const ampm = h >= 12 ? 'pm' : 'am';
    h %= 12;
    h = h || 12; // the hour '0' should be '12'
    m = m < 10 && m > 0 ? `0${m}` : m;
    const strTime = `${h}:${m} ${ampm}`;
    return strTime;
};

/**
 * Turns Hex into rgba
 * @return {String} rgba
 * */
const hexToRGB = (hex, alpha) => {
    hex = hex.toString().replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    if (alpha) {
        return `rgba(${r},${g},${b},${alpha})`;
    }

    return `rgb(${r},${g},${b})`;
};

/**
 * Returns Currency in K format
 * @return {String} number
 * */
const kFormatter = n => {
    if (n >= 1000) {
        return `${+(n / 1000).toFixed(1)}K`;
    }
    return n;
};

/**
 * Formats currency
 * @return {String} currency in form of 55.555 or 55.555,00 ( if decimal )
 * */
const formatCurrency = value => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

/**
 * Return get parameters as key value pairs
 * @return {Object}
 * */
const getUrlParams = url =>
    `${url}?`
        .split('?')[1]
        .split('&')
        .reduce(
            (params, pair) =>
                ((key, val) => (key ? { ...params, [key]: val } : params))(
                    ...`${pair}=`.split('=').map(decodeURIComponent)
                ),
            {}
        );

/**
 * Format date
 * @return {String} date
 * */
const formatDate = (date, format = 'DD-MM-YYYY') => {
    const d = new Date(date);
    const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
    ];

    function appendZeroToTime(time) {
        if (time <= 9) {
            return `0${time}`;
        }
        return time;
    }

    // function formatAmPm(hours, minutes) {
    //     let h = hours;
    //     let m = minutes;
    //     const ampm = h >= 12 ? 'pm' : 'am';
    //     h %= 12;
    //     h = h || 12; // the hour '0' should be '12'
    //     m = m < 10 ? `0${m}` : m;
    //     const strTime = `${h}:${m} ${ampm}`;
    //     return strTime;
    // }

    const day = d.getDate();
    const month = d.getMonth() + 1;
    const year = d.getFullYear();
    const monthString = months[month];
    const hour = d.getHours();
    const minute = d.getMinutes();
    const second = d.getSeconds();

    switch (format) {
        case 'YYYY-MM-DD':
            return `${year}-${month}-${day}`;
        case 'YYYY/MM/DD':
            return `${year}/${month}/${day}`;
        case 'DD/MM/YYYY':
            return `${day}/${month}/${year}`;
        case 'DD-MM-YYYY':
            return `${day}-${month}-${year}`;
        case 'Month':
            return monthString;
        case 'Day+Month':
            return `${day} ${monthString}`;
        case 'Month+Day':
            return `${monthString} ${day}`;
        case 'hh:mm:ss':
            return `${appendZeroToTime(hour)}:${appendZeroToTime(minute)}:${appendZeroToTime(
                second
            )}`;
        case 'hh:mm':
            return `${appendZeroToTime(hour)}:${appendZeroToTime(minute)}`;
        case 'hh:mm AA':
            return formatAmPm(hour, minute);
        case 'YYYY-MM-DD hh:mm:ss':
            return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
        case 'YYYY-MM-DD hh:mm AA':
            return `${year}-${month}-${day} ${formatAmPm(hour, minute)}`;
        case 'YYYY/MM/DD hh:mm:ss':
            return `${year}/${month}/${day} ${hour}:${minute}:${second}`;
        case `YYYY/MM/DD hh:mm AA`:
            return `${year}/${month}/${day} ${formatAmPm(hour, minute)}`;
        default:
            return `${day}-${month}-${year}`;
    }
};

// max-width breakpoints
// current mobile breakpoint key: 'tablet'
const breakpoints = {
    small: 480,
    mobile: 850,
    tablet: 1030,
    desktop: 1200
};

/**
 * Check if you hit mobile resolution
 * @param currentWidth {number}
 * @returns {boolean}
 */
const ifMobile = currentWidth => {
    return currentWidth < breakpoints.tablet + 1;
};

export {
    parseParamsValueForApi,
    parseParamsForApi,
    initialOrderBy,
    resolveObjectPathByString,
    getOptionByValue,
    formatAmPm,
    hexToRGB,
    kFormatter,
    formatCurrency,
    getUrlParams,
    formatDate,
    ifMobile,
    getOptionsByValues
};
