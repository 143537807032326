import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues, change } from 'redux-form';
import { FormattedMessage } from 'react-intl';

import { LineChart } from 'erpcomponents/WidgetCharts';
import ElementLoader from 'erpcomponents/ElementLoader';
import { Select } from 'erpcore/components/Form';
import Widget from 'erpcomponents/Widget';

import './SalesOverview.scss';

import { actions as salesOverviewActions } from 'erpcomponents/Widgets/SalesOverview/SalesOverview.reducer';
import { getData, getFetching } from 'erpcomponents/Widgets/SalesOverview/SalesOverview.selectors';
import { chartColors } from 'erpdata/colors';
import { hexToRGB, kFormatter } from 'erputils/utils';
import { getBoundaryAdjustedData } from 'erpcomponents/Widgets/utils';

class SalesOverview extends Component {
    componentDidMount() {
        const { eventSeries, eventID, dateRange, soldBy } = this.props;
        if (eventSeries || eventID) {
            return this.fetchData({
                dateRange,
                eventID,
                eventSeries,
                soldBy
            });
        }
        return null;
    }

    componentWillReceiveProps(nextProps) {
        const { dateRange, soldBy, setFormValue } = this.props;
        const { dateRange: nextGlobalDateRange, soldBy: nextSoldBy } = nextProps;

        let willFetchData = false;

        if (dateRange !== nextGlobalDateRange) {
            return setFormValue('filter', nextGlobalDateRange);
        }

        if (nextSoldBy !== soldBy) {
            willFetchData = true;
        }

        if (willFetchData) {
            return this.fetchData({
                soldBy: nextSoldBy,
                dateRange
            });
        }

        return null;
    }

    componentDidUpdate(prevProps) {
        const { dateRange, eventID, filterValues, eventSeries } = this.props;
        const { eventID: prevEventID, eventSeries: prevEventSeries, soldBy } = prevProps;

        let willFetchData = false;

        if (
            prevProps.filterValues &&
            prevProps.filterValues.filter &&
            filterValues.filter &&
            prevProps.filterValues.filter !== filterValues.filter
        ) {
            willFetchData = true;
        }

        if (prevEventSeries !== eventSeries) {
            willFetchData = true;
        }

        if (prevEventID && prevEventID !== eventID) {
            willFetchData = true;
        }

        if (willFetchData) {
            return this.fetchData({ dateRange, soldBy, eventSeries, eventID });
        }

        return true;
    }

    fetchData({ dateRange, eventID, eventSeries, soldBy }) {
        const { dispatch, id, sources, filterValues } = this.props;

        const params = {
            display_by: 'day'
        };

        if (filterValues.filter !== dateRange && filterValues.filter !== Infinity) {
            const now = new Date();
            const startDate = new Date(now.getTime() - filterValues.filter * 24 * 60 * 60 * 1000);
            params['filters[start_date]'] = startDate;
            params['filters[end_date]'] = now;
        } else if (dateRange !== Infinity && filterValues.filter === dateRange) {
            const now = new Date();
            const startDate = new Date(now.getTime() - dateRange * 24 * 60 * 60 * 1000);
            params['filters[start_date]'] = startDate;
            params['filters[end_date]'] = now;
        }

        if (eventSeries) {
            params['filters[event_series]'] = eventSeries;
        }

        if (eventID) {
            params['filters[events]'] = eventID;
        }

        if (dateRange === 1) {
            params.display_by = 'hourly';
        }

        if (soldBy) {
            params['filters[sold_by]'] = soldBy;
        }

        sources.map(source => {
            const sourceParams = { ...params, ...{ 'filters[source]': source.value } };
            return new Promise((resolve, reject) => {
                dispatch({
                    promise: { resolve, reject },
                    type: salesOverviewActions.START_FETCHING_SALESOVERVIEW_DATA,
                    widgetId: id,
                    params: sourceParams,
                    source: source.value
                });
            }).catch(error => ({ error }));
        });
    }

    formatWidgetData() {
        const { sources, data } = this.props;

        const widgetData = {
            labels: [],
            datasets: []
        };

        const dataSets = sources.reduce((accumulator, currentSource) => {
            if (
                data &&
                currentSource.value &&
                data[currentSource.value] &&
                data[currentSource.value].data !== undefined &&
                data[currentSource.value].data &&
                data[currentSource.value].data.data !== undefined &&
                data[currentSource.value].data.data
            ) {
                accumulator.push({
                    id: currentSource.value,
                    data: data[currentSource.value].data.data
                });
            }
            return accumulator;
        }, []);

        const boundaryAdjustedData = getBoundaryAdjustedData(dataSets, {
            attendance: 0,
            revenue: 0,
            tickets_sold: 0
            /*
            attendance: ',',
            revenue: ',',
            tickets_sold: ','
            */
        });

        if (boundaryAdjustedData) {
            // <FormattedDate value={date} day="numeric" month="long" year="numeric" />
            widgetData.labels = boundaryAdjustedData.identifiers;

            sources.forEach((source, index) => {
                const set = boundaryAdjustedData.sets.find(item => item.id === source.value);

                if (set) {
                    const dataValues = [...Object.values(set.data)].map(
                        item => item.attendance || 0
                    );

                    // Changes default values of LineChart //
                    widgetData.datasets.push({
                        label: `${source.label}`,
                        pointBorderColor: chartColors[index], // Color of "dots" in line chart
                        pointBorderWidth: 6, // Width of "dots" in line chart
                        pointHoverRadius: 6, // Radius needed to detect hover on Dot
                        borderColor: chartColors[index], // Line Color
                        data: dataValues,
                        backgroundColor: chartColors[index],
                        pointHoverBackgroundColor: hexToRGB(chartColors[index], 0.3), // Color of dot when hovered over
                        pointHoverBorderColor: hexToRGB(chartColors[index], 0.2) // color of border around dot when hovered over
                    });
                }
            });
        }

        return widgetData;
    }

    renderFilters() {
        return (
            <Field
                name="filter"
                id="filter"
                fieldProps={{
                    label: (
                        <FormattedMessage
                            id="SalesOverview.Filter.DateRange"
                            defaultMessage="Date Range: "
                        />
                    ),
                    clearable: false,
                    options: [
                        {
                            label: (
                                <FormattedMessage
                                    id="SalesOverview.Filter.DateRange.Last24h"
                                    defaultMessage="Last 24h"
                                />
                            ),
                            value: 1
                        },
                        {
                            label: (
                                <FormattedMessage
                                    id="SalesOverview.Filter.DateRange.Last7Days"
                                    defaultMessage="Last 7 Days"
                                />
                            ),
                            value: 7
                        },
                        {
                            label: (
                                <FormattedMessage
                                    id="SalesOverview.Filter.DateRange.Last30Days"
                                    defaultMessage="Last 30 Days"
                                />
                            ),
                            value: 30
                        },
                        {
                            label: (
                                <FormattedMessage
                                    id="SalesOverview.Filter.DateRange.Last3Months"
                                    defaultMessage="Last 3 Months"
                                />
                            ),
                            value: 90
                        },
                        {
                            label: (
                                <FormattedMessage
                                    id="SalesOverview.Filter.DateRange.Last6Months"
                                    defaultMessage="Last 6 Months"
                                />
                            ),
                            value: 180
                        },
                        {
                            label: (
                                <FormattedMessage
                                    id="SalesOverview.Filter.DateRange.Last12Months"
                                    defaultMessage="Last 12 Months"
                                />
                            ),
                            value: 365
                        },
                        {
                            label: (
                                <FormattedMessage
                                    id="SalesOverview.Filter.DateRange.AllTime"
                                    defaultMessage="All Time"
                                />
                            ),
                            value: Infinity
                        }
                    ]
                }}
                component={Select}
            />
        );
    }

    renderEvents() {
        const widgetData = this.formatWidgetData();
        const options = {
            showScale: true,
            pointDot: true,
            scales: {
                yAxes: [
                    {
                        ticks: {
                            beginAtZero: true,
                            callback: value => kFormatter(value)
                        }
                    }
                ],
                xAxes: [
                    {
                        ticks: {
                            autoSkip: true,
                            maxTicksLimit: 30
                        }
                    }
                ]
            },
            tooltips: {
                callbacks: {
                    label(tooltipItem, data) {
                        const label = data.datasets[tooltipItem.datasetIndex].label || '';
                        const value = Math.round(tooltipItem.yLabel * 100) / 100;

                        return `${label}: ${value
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
                    }
                }
            }
        };

        return <LineChart data={widgetData} gradientFill options={options} />;
    }

    renderContent() {
        return <div className="sales-overview-widget__content">{this.renderEvents()}</div>;
    }

    render() {
        const { size, fetching } = this.props;

        return (
            <Widget
                title={
                    <FormattedMessage id="SalesOverview.Title" defaultMessage="Sales Overview" />
                }
                filters={this.renderFilters()}
                size={size}
                className="sales-overview-widget"
            >
                {fetching && <ElementLoader overlay />}
                {this.renderContent()}
            </Widget>
        );
    }
}

SalesOverview.defaultProps = {
    size: null,
    fetching: false,
    data: {},
    sources: [
        {
            label: 'TicketSocket',
            value: 'ticketsocket'
        },
        {
            label: 'GetOut',
            value: 'get_out'
        }
    ],
    filterValues: { filter: Infinity },
    setFormValue: () => {},
    dateRange: Infinity,
    eventSeries: null,
    eventID: null,
    soldBy: null
};

SalesOverview.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    dispatch: PropTypes.func.isRequired,
    fetching: PropTypes.bool,
    data: PropTypes.oneOfType([PropTypes.object]),
    sources: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    filterValues: PropTypes.oneOfType([PropTypes.object]),
    setFormValue: PropTypes.func,
    dateRange: PropTypes.number,
    eventSeries: PropTypes.string,
    eventID: PropTypes.string,
    soldBy: PropTypes.string
};

//  Getting initial value populated in the form from the store
const mapStateToProps = (state, ownProps) => {
    return {
        data: getData(state, ownProps.id),
        fetching: getFetching(state, ownProps.id),
        filterValues: getFormValues('SalesOverviewForm')(state),
        initialValues: { filter: ownProps.dateRange }
    };
};

SalesOverview = reduxForm(
    {
        form: 'SalesOverviewForm'
    },
    mapStateToProps
)(SalesOverview);

const mapDispatchToProps = dispatch => ({
    setFormValue: (field, value) => dispatch(change('SalesOverviewForm', field, value))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SalesOverview);
