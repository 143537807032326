import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { dtoForm } from 'erputils/dto';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { diff } from 'deep-object-diff';
import { FormattedMessage } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import Form, { Text, Email, Phone } from 'erpcore/components/Form';
import valueValidation from 'erputils/valueValidation';
import Button from 'erpcomponents/Button';
import { getMeData } from 'erputils/AuthManager/AuthManager.selectors';
import { actions as myAccountActions } from '../../MyAccount.reducer';
import ProfilePhoto from '../ProfilePhoto';

class UpdateProfileForm extends Component {
    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(formData) {
        const { dispatch, initialValues } = this.props;
        // send only changed data
        formData = diff(initialValues, formData);

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: myAccountActions.START_UPDATE_USER,
                formData
            })
        ).catch(error => {
            return error;
        });
    }

    render() {
        const { handleSubmit, submitting, pristine, invalid } = this.props;

        return (
            <Form onSubmit={handleSubmit(this.onSubmit)}>
                <Form.Columns>
                    <Form.Column>
                        <Form.SectionTitle>
                            <FormattedMessage
                                id="MyAccountForm.SectionTitle.ProfileDetails"
                                defaultMessage="Profile Details"
                            />
                        </Form.SectionTitle>
                        <Form.Row>
                            <Field
                                name="first_name"
                                id="first_name"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="MyAccountForm.FirstName"
                                            defaultMessage="First Name"
                                        />
                                    ),
                                    clearable: true
                                }}
                                fieldAttr={{ required: true }}
                                component={Text}
                                validate={valueValidation([{ validator: 'required' }])}
                            />
                        </Form.Row>
                        <Form.Row>
                            <Field
                                name="last_name"
                                id="last_name"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="MyAccountForm.LastName"
                                            defaultMessage="Last Name"
                                        />
                                    ),
                                    clearable: true
                                }}
                                fieldAttr={{ required: true }}
                                component={Text}
                                validate={valueValidation([{ validator: 'required' }])}
                            />
                        </Form.Row>

                        <Form.Row>
                            <Field
                                name="email"
                                id="email"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="MyAccountForm.Email"
                                            defaultMessage="Email Address"
                                        />
                                    ),
                                    clearable: true
                                }}
                                fieldAttr={{ required: true }}
                                component={Email}
                                validate={valueValidation([
                                    { validator: 'email' },
                                    { validator: 'required' }
                                ])}
                            />
                        </Form.Row>
                        <Form.Row>
                            <Field
                                name="phone_number"
                                id="phone_number"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="MyAccountForm.PhoneNumber"
                                            defaultMessage="Phone Number"
                                        />
                                    )
                                }}
                                component={Phone}
                            />
                        </Form.Row>
                        <Form.Row>
                            <Button
                                submit
                                disabled={pristine || invalid}
                                loading={submitting}
                                label={
                                    <FormattedMessage
                                        id="MyAccountForm.Update"
                                        defaultMessage="Update"
                                    />
                                }
                            />
                        </Form.Row>
                    </Form.Column>
                    <Form.Column>
                        <Form.SectionTitle>
                            <FormattedMessage
                                id="MyAccountForm.SectionTitle.ProfilePhoto"
                                defaultMessage="Profile Photo"
                            />
                        </Form.SectionTitle>
                        <Form.Row>
                            <Field name="image" id="image" component={ProfilePhoto} />
                        </Form.Row>
                    </Form.Column>
                </Form.Columns>
            </Form>
        );
    }
}

UpdateProfileForm.defaultProps = {
    submitting: false,
    pristine: false,
    invalid: false,
    initialValues: {}
};

UpdateProfileForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    initialValues: PropTypes.oneOfType([PropTypes.object])
};

// Order of this steps is mandatory to have initialValues working
UpdateProfileForm = reduxForm({
    form: 'UpdateProfileForm',
    enableReinitialize: true
})(UpdateProfileForm);

//  Getting initial value populated in the form from the store
const mapStateToProps = state => ({
    initialValues: dtoForm(getMeData(state))
});

export default withRouter(connect(mapStateToProps)(UpdateProfileForm));
