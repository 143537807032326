import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FormattedNumber, FormattedMessage } from 'react-intl';

import generateKey from 'erpcore/utils/generateKey';

import Listing from 'erpcore/components/Listing';

import { getYearOverYearFetching } from 'erpcore/screens/Dashboard/screens/YearOverYear/YearOverYear.selectors';

const style = {
    currency: 'currency',
    percent: 'percent'
};

const EventDetailsTable = ({ eventsData, params }) => {
    const fetching = useSelector(state => getYearOverYearFetching(state));

    const tableData = () => {
        const table = {};
        table.data = [];

        if (eventsData && eventsData.length) {
            let rowAttendance = {};
            let rowTicketsSold = {};
            let rowRevenue = {};
            let rowPercentage = {};
            table.schema = [
                {
                    title: '',
                    field: 'label'
                }
            ];

            eventsData.map(item => {
                const { totals } = item?.report;
                table.schema.push({
                    title:
                        item.type !== 'Event' ? (
                            <Link
                                key={generateKey(item.id)}
                                to={{
                                    pathname: '/dashboard/overall-sales',
                                    state: {
                                        filterParams: { ...params, eventSeriesFilter: item.iri }
                                    }
                                }}
                            >
                                {item.name}
                            </Link>
                        ) : (
                            <h4 key={generateKey(item.id)}>{item.name}</h4>
                        ),
                    field: item.name,
                    align: 'right'
                });
                rowAttendance = {
                    ...rowAttendance,
                    id: `row-attendance-${item.iri}`,
                    [item.name]: totals.max_tickets_to_sell
                };
                rowTicketsSold = {
                    ...rowTicketsSold,
                    id: `row-tickets-sold-${item.iri}`,
                    [item.name]: totals.tickets_sold
                };
                rowRevenue = {
                    ...rowRevenue,
                    id: `row-revenue-${item.iri}`,
                    [item.name]: totals?.revenue ? (
                        <FormattedNumber
                            value={totals.revenue / 100}
                            currency={item?.revenue?.currency || 'USD'}
                            style={style.currency}
                        />
                    ) : (
                        <FormattedMessage
                            id="Dashboard.YearOverYear.Table.EventDetails.NoSales"
                            defaultMessage="No sales"
                        />
                    )
                };
                rowPercentage = {
                    ...rowPercentage,
                    id: `row-percentage-${item.iri}`,
                    [item.name]:
                        totals.tickets_sold > 0 ? (
                            <FormattedNumber
                                value={totals.tickets_sold / totals.max_tickets_to_sell}
                                style={style.percent}
                                maximumFractionDigits={2}
                            />
                        ) : (
                            <FormattedMessage
                                id="Dashboard.YearOverYear.Table.EventDetails.NoSales"
                                defaultMessage="No sales"
                            />
                        )
                };
                return item;
            });

            table.data.push({
                label: (
                    <FormattedMessage
                        id="Dashboard.YearOverYear.Table.EventDetails.MaximumAttendance"
                        defaultMessage="Maximum Attendance"
                    />
                ),
                ...rowAttendance
            });

            table.data.push({
                label: (
                    <FormattedMessage
                        id="Dashboard.YearOverYear.Table.EventDetails.TicketsSold"
                        defaultMessage="Tickets Sold"
                    />
                ),
                ...rowTicketsSold
            });

            table.data.push({
                label: (
                    <FormattedMessage
                        id="Dashboard.YearOverYear.Table.EventDetails.Revenue"
                        defaultMessage="Revenue"
                    />
                ),
                ...rowRevenue
            });

            table.data.push({
                label: (
                    <FormattedMessage
                        id="Dashboard.YearOverYear.Table.EventDetails.PercentageSold"
                        defaultMessage="% sold"
                    />
                ),
                ...rowPercentage
            });
        }
        return table;
    };

    return (
        <Listing
            table={tableData()}
            loading={fetching}
            name="year_over_year"
            reducerName="YEAR_OVER_YEAR"
            hideSearch
            hideFooter
            hideFilters
            hideHeader
        />
    );
};

EventDetailsTable.defaultProps = {
    eventsData: [],
    params: {}
};

EventDetailsTable.propTypes = {
    eventsData: PropTypes.oneOfType([PropTypes.array]),
    params: PropTypes.oneOfType([PropTypes.object])
};

export default EventDetailsTable;
