export const actions = {
    SET_LOCALE: 'SET_LOCALE'
};

const language =
    (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage;

const initialLocale = language.toLowerCase().split(/[_-]+/)[0];

export const initialState = {
    language,
    locale: initialLocale
};

export default (state = initialState, { type, data }) => {
    switch (type) {
        case actions.SET_LOCALE: {
            return {
                ...state,
                language: data.language,
                locale: data.locale
            };
        }
        default:
            return state;
    }
};
