export const actions = {
    START_FETCHING_USERS: 'START_FETCHING_USERS',
    FETCHING_SUCCESSFUL_USERS: 'FETCHING_SUCCESSFUL_USERS',
    FETCHING_FAILED_USERS: 'FETCHING_FAILED_USERS',
    START_CREATE_USER: 'START_CREATE_USER',
    CREATE_USER_SUCCESSFUL: 'CREATE_USER_SUCCESSFUL',
    CREATE_USER_FAILED: 'CREATE_USER_FAILED',
    START_FETCHING_SINGLE_USER: 'START_FETCHING_SINGLE_USER',
    FETCH_SINGLE_USER_SUCCESSFUL: 'FETCH_SINGLE_USER_SUCCESSFUL',
    FETCH_SINGLE_USER_FAILED: 'FETCH_SINGLE_USER_FAILED',
    START_UPDATE_SINGLE_USER: 'START_UPDATE_SINGLE_USER',
    UPDATE_SINGLE_USER_SUCCESSFUL: 'UPDATE_SINGLE_USER_SUCCESSFUL',
    UPDATE_SINGLE_USER_FAILED: 'UPDATE_SINGLE_USER_FAILED',
    START_CHANGE_PASSWORD_SINGLE_USER: 'START_CHANGE_PASSWORD_SINGLE_USER',
    CHANGE_PASSWORD_SINGLE_USER_SUCCESSFUL: 'CHANGE_PASSWORD_SINGLE_USER_SUCCESSFUL',
    CHANGE_PASSWORD_SINGLE_USER_FAILED: 'CHANGE_PASSWORD_SINGLE_USER_FAILED',
    START_DELETE_SINGLE_USER: 'START_DELETE_SINGLE_USER',
    DELETE_SINGLE_USER_SUCCESSFUL: 'DELETE_SINGLE_USER_SUCCESSFUL',
    DELETE_SINGLE_USER_FAILED: 'DELETE_SINGLE_USER_FAILED',
    STORE_SINGLE_USER_DATA: 'STORE_SINGLE_USER_DATA',
    START_FETCHING_USER_INVITES: 'START_FETCHING_USER_INVITES',
    FETCHING_SUCCESSFUL_USER_INVITES: 'FETCHING_SUCCESSFUL_USER_INVITES',
    FETCHING_FAILED_USER_INVITES: 'FETCHING_FAILED_USER_INVITES',
    START_DELETE_SINGLE_USER_INVITATION: 'START_DELETE_SINGLE_USER_INVITATION',
    DELETE_SINGLE_USER_INVITATION_SUCCESSFUL: 'DELETE_SINGLE_USER_INVITATION_SUCCESSFUL',
    DELETE_SINGLE_USER_INVITATION_FAILED: 'DELETE_SINGLE_USER_INVITATION_FAILED',
    START_RESEND_SINGLE_USER_INVITATION: 'START_RESEND_SINGLE_USER_INVITATION',
    RESEND_SINGLE_USER_INVITATION_SUCCESSFUL: 'RESEND_SINGLE_USER_INVITATION_SUCCESSFUL',
    RESEND_SINGLE_USER_INVITATION_FAILED: 'RESEND_SINGLE_USER_INVITATION_FAILED'
};

export const initialState = {
    listingFetching: false,
    listingResponse: {
        data: [],
        meta: {},
        included: []
    },
    listingError: [],
    singleFetching: false,
    singleError: null,
    deleteSingleError: null,
    resendSingleError: null,
    packages: [],
    invitations: []
};

export default (state = initialState, { type, id, response }) => {
    switch (type) {
        case actions.START_FETCHING_USERS: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_USERS: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_USERS: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        case actions.START_FETCHING_USER_INVITES: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_USER_INVITES: {
            return {
                ...state,
                listingFetching: false,
                invitations: response
            };
        }
        case actions.FETCHING_FAILED_USER_INVITES: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        case actions.START_FETCHING_SINGLE_USER: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.FETCH_SINGLE_USER_SUCCESSFUL: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.FETCH_SINGLE_USER_FAILED: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.UPDATE_SINGLE_USER_FAILED: {
            return {
                ...state,
                singleError: response
            };
        }
        case actions.DELETE_SINGLE_USER_FAILED: {
            return {
                ...state,
                deleteSingleError: response
            };
        }
        case actions.DELETE_SINGLE_USER_INVITATION_FAILED: {
            return {
                ...state,
                deleteSingleError: response
            };
        }
        case actions.RESEND_SINGLE_USER_INVITATION_FAILED: {
            return {
                ...state,
                resendSingleError: response
            };
        }
        case actions.STORE_SINGLE_USER_DATA: {
            // Updating the store
            return Object.assign({}, state, {
                [id]: response.data
            });
        }
        default:
            return state;
    }
};
