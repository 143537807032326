import { createSelector } from 'reselect';

export const getCheckedOrders = state => state.orders && state.orders.checked;

export const getOrderData = (state, id) =>
    state.orders && state.orders.orders && state.orders.orders.find(order => order.id === id);

export const getSingleOrderData = (state, id) => state.orders && state.orders[id];

export const getSingleOrderFetching = state => state.orders.singleFetching;

export const getOrders = state => state.orders && state.orders.orders;

export const getSyncingHubspot = state => state.orders && state.orders.hubspotSyncing;

export const submittingBulkAction = state =>
    state.orders && state.orders.submittingBulkAction && state.orders.submittingBulkAction;

export const getOrderComments = (state, id) => state.orders.comments && state.orders.comments[id];

export default createSelector([
    getOrderData,
    getSingleOrderData,
    getSingleOrderFetching,
    getSyncingHubspot,
    submittingBulkAction,
    getOrderComments
]);
