import React from 'react';
// import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import LayoutManager from 'erputils/LayoutManager';
import PageHeader from 'erpcomponents/Layout/PageHeader';
import PageContent from 'erpcomponents/Layout/PageContent';

class Dashboard extends React.Component {
    constructor(props) {
        super(props);

        this.props = props;
    }

    render() {
        return (
            <LayoutManager slot="main" layoutType="merge">
                <PageHeader
                    title={<FormattedMessage id="Dashboard.title" defaultMessage="Dashboard" />}
                />

                <PageContent />
            </LayoutManager>
        );
    }
}

Dashboard.defaultProps = {
    user: null
};

Dashboard.propTypes = {};

export default Dashboard;
