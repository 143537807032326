export const actions = {
    START_FETCHING_PERMISSIONS: 'START_FETCHING_PERMISSIONS',
    FETCHING_SUCCESSFUL_PERMISSIONS: 'FETCHING_SUCCESSFUL_PERMISSIONS',
    FETCHING_FAILED_PERMISSIONS: 'FETCHING_FAILED_PERMISSIONS',

    START_UPDATE_PERMISSION: 'START_UPDATE_PERMISSION',
    UPDATE_PERMISSION_SUCCESSFUL: 'UPDATE_PERMISSION_SUCCESSFUL',
    UPDATE_PERMISSION_FAILED: 'UPDATE_PERMISSION_FAILED',

    STORE_PERMISSION_DATA: 'STORE_PERMISSION_DATA'
};

export const initialState = {
    listingFetching: false,
    listingResponse: {
        data: [],
        meta: {},
        included: []
    },
    listingError: []
};

export default (state = initialState, { type, response }) => {
    switch (type) {
        case actions.START_FETCHING_PERMISSIONS: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_PERMISSIONS: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_PERMISSIONS: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        case actions.STORE_PERMISSION_DATA: {
            if (response?.data) {
                const getPermissionIndex = permission => permission.id === response?.data?.id;
                const index = state.listingResponse.data.findIndex(getPermissionIndex);
                if (index) {
                    state.listingResponse.data[index] = {
                        ...response.data
                    };
                }
            }

            return {
                ...state,
                listingFetching: false,
                listingError: response,
                listingResponse: {
                    ...state.listingResponse,
                    data: [...state.listingResponse.data]
                }
            };
        }
        default:
            return state;
    }
};
