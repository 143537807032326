import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import reduxFormErrorMapper from 'erputils/reduxFormErrorMapper';
import { Field, reduxForm, SubmissionError, reset } from 'redux-form';
import Form, { Text, Email, Phone, Creatable } from 'erpcore/components/Form';
import valueValidation from 'erputils/valueValidation';
import LayoutManager from 'erputils/LayoutManager';
import PageHeader from 'erpcomponents/Layout/PageHeader';
import PageContent from 'erpcomponents/Layout/PageContent';
import Button from 'erpcomponents/Button';
import { actions as usersActions } from 'erpcore/screens/Users/Users.reducer';

let UserCreate = ({ handleSubmit, submitting, pristine, invalid, onSubmit }) => {
    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <PageHeader
                title={<FormattedMessage id="UserCreate.title" defaultMessage="Add New User" />}
                backButtonLink="/users"
            />
            <PageContent>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Row>
                        <Field
                            name="first_name"
                            id="first_name"
                            fieldProps={{
                                label: (
                                    <FormattedMessage
                                        id="UserCreate.FirstName"
                                        defaultMessage="First Name"
                                    />
                                ),
                                clearable: true
                            }}
                            component={Text}
                            fieldAttr={{ required: true }}
                            validate={valueValidation([{ validator: 'required' }])}
                        />
                    </Form.Row>
                    <Form.Row>
                        <Field
                            name="last_name"
                            id="last_name"
                            fieldProps={{
                                label: (
                                    <FormattedMessage
                                        id="UserCreate.LastName"
                                        defaultMessage="Last Name"
                                    />
                                ),
                                clearable: true
                            }}
                            component={Text}
                            fieldAttr={{ required: true }}
                            validate={valueValidation([{ validator: 'required' }])}
                        />
                    </Form.Row>
                    <Form.Row>
                        <Field
                            name="phone_number"
                            id="phone_number"
                            fieldProps={{
                                label: (
                                    <FormattedMessage
                                        id="UserCreate.Phone"
                                        defaultMessage="Phone"
                                    />
                                )
                            }}
                            component={Phone}
                        />
                    </Form.Row>
                    <Form.Row>
                        <Field
                            name="email"
                            id="email"
                            fieldProps={{
                                label: (
                                    <FormattedMessage
                                        id="UserCreate.Email"
                                        defaultMessage="Email"
                                    />
                                ),
                                clearable: true
                            }}
                            component={Email}
                            fieldAttr={{ required: true }}
                            validate={valueValidation([{ validator: 'required' }])}
                        />
                    </Form.Row>
                    <Form.Row>
                        <Field
                            name="city"
                            id="city"
                            fieldProps={{
                                label: (
                                    <FormattedMessage id="UserCreate.City" defaultMessage="City" />
                                ),
                                options: {
                                    endpoint: '/api/cities',
                                    mapData: {
                                        value: 'iri',
                                        label: 'name'
                                    }
                                },
                                clearable: true
                            }}
                            component={Creatable}
                        />
                    </Form.Row>
                    <Form.Row>
                        <Button
                            submit
                            disabled={pristine || invalid}
                            loading={submitting}
                            label={<FormattedMessage id="UserCreate.Add" defaultMessage="Add" />}
                        />
                    </Form.Row>
                </Form>
            </PageContent>
        </LayoutManager>
    );
};

UserCreate.defaultProps = {
    submitting: false,
    pristine: false,
    invalid: false,
    onSubmit: () => {}
};

UserCreate.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool
};

UserCreate = reduxForm({
    form: 'UserCreateForm',
    enableReinitialize: true
})(UserCreate);

const mapDispatchToProps = dispatch => ({
    onSubmit: formData => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: usersActions.START_CREATE_USER,
                formData
            });
        })
            .then(() => {
                dispatch(reset('UserCreateForm'));
            })
            .catch(error => {
                throw new SubmissionError(reduxFormErrorMapper(error));
            });
    }
});

export default connect(
    null,
    mapDispatchToProps
)(UserCreate);
