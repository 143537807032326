import { createSelector } from 'reselect';

export const getYearOverYearFetching = state => state?.yearOverYear?.fetching || false;

export const getYearOverYearEvents = state => state?.yearOverYear?.events || {};

export const getYearOverYearChartData = state => state?.yearOverYear?.chartData || {};

export const getTicketTypeBreakdownData = state => state?.yearOverYear?.ticketTypeBreakdown || {};

export default createSelector([
    getYearOverYearFetching,
    getYearOverYearEvents,
    getYearOverYearChartData,
    getTicketTypeBreakdownData
]);
