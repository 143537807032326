import { takeLatest, put, call, select } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import { actions as notificationManagerActions } from 'erputils/NotificationManager/NotificationManager.reducer';
import { parseParamsForApi } from 'erputils/utils';
import { actions as listingActions } from 'erpcomponents/Listing/Listing.reducer';
import dto from 'erputils/dto';
import { getListingUrlQueryParams } from 'erputils/RouterManager/RouterManager.selectors';
import { actions as ageRequirementsActions } from './AgeRequirements.reducer';

/**
 * Create Age Requirement
 * @param  {Object} promise
 * @return {Object} formData
 */
export function* createAgeRequirement({ promise, formData }) {
    try {
        // Create Age Requirement
        const createAgeRequirementAPI = yield restClient.post(`api/age-requirements`, formData);
        yield put({
            type: ageRequirementsActions.CREATE_AGE_REQUIREMENT_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createAgeRequirementAPI.data
        });
        yield put({
            type: listingActions.START_FETCHING_LISTING,
            entity: 'AGE_REQUIREMENTS',
            endpoint: 'api/age-requirements',
            params: { pagination: false }
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: ageRequirementsActions.CREATE_AGE_REQUIREMENT_FAILED,
            response: error.response.data
        });

        yield call(promise.reject, error.response.data);
    }
}

/**
 * Fetch Age requirements
 * @param  {Object} promise
 * @return {string} id Event id
 */
export function* fetchAgeRequirement({ promise, id }) {
    try {
        const fetchAgeRequirementsAPI = yield restClient.get(`api/age-requirements/${id}`);
        yield put({
            type: ageRequirementsActions.FETCH_SINGLE_AGE_REQUIREMENT_SUCCESSFUL
        });
        yield put({
            type: ageRequirementsActions.STORE_SINGLE_AGE_REQUIREMENT_DATA,
            id,
            response: dto(fetchAgeRequirementsAPI.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: ageRequirementsActions.FETCH_SINGLE_AGE_REQUIREMENT_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}
/**
 * Update event series single data
 * @param  {Object} id ID of an event
 * @return {Object} Response from API
 */
export function* updateSingleAgeRequirement({ promise, formData, id }) {
    try {
        const updateSingleEventSeriesAPI = yield restClient.put(
            `api/age-requirements/${id}`,
            formData
        );
        yield put({
            type: ageRequirementsActions.UPDATE_SINGLE_AGE_REQUIREMENT_SUCCESSFUL
        });
        yield put({
            type: ageRequirementsActions.STORE_SINGLE_AGE_REQUIREMENT_DATA,
            id,
            response: dto(updateSingleEventSeriesAPI.data)
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateSingleEventSeriesAPI.data
        });
        yield put({
            type: listingActions.START_FETCHING_LISTING,
            entity: 'AGE_REQUIREMENTS',
            endpoint: 'api/age-requirements',
            params: { pagination: false }
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: ageRequirementsActions.UPDATE_SINGLE_AGE_REQUIREMENT_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Delete Single Age Requirement
 * @param  {Object} id ID of an Event
 * @return {Object} Response from API
 */
export function* deleteSingleAgeRequirement({ promise, id }) {
    try {
        // Merge default with current params for listing refresh purpose
        const params = Object.assign(
            {},
            { include: 'location.city' },
            yield select(getListingUrlQueryParams)
        );

        const deleteSingleAgeRequirementAPI = yield restClient.delete(`api/age-requirements/${id}`);
        yield put({
            type: ageRequirementsActions.DELETE_SINGLE_AGE_REQUIREMENT_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteSingleAgeRequirementAPI.data
        });
        yield put({
            type: listingActions.START_FETCHING_LISTING,
            entity: 'AGE_REQUIREMENTS',
            endpoint: 'api/age-requirements',
            params: parseParamsForApi(params)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: ageRequirementsActions.DELETE_SINGLE_AGE_REQUIREMENT_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

export const ageRequirementsSaga = [
    takeLatest(ageRequirementsActions.START_CREATE_AGE_REQUIREMENT, createAgeRequirement),
    takeLatest(
        ageRequirementsActions.START_DELETE_SINGLE_AGE_REQUIREMENT,
        deleteSingleAgeRequirement
    ),
    takeLatest(ageRequirementsActions.START_FETCHING_SINGLE_AGE_REQUIREMENT, fetchAgeRequirement),
    takeLatest(
        ageRequirementsActions.START_UPDATE_SINGLE_AGE_REQUIREMENT,
        updateSingleAgeRequirement
    )
];
