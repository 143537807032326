import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import Form, { Text, Select } from 'erpcore/components/Form';
import { dtoForm } from 'erputils/dto';
import Button from 'erpcomponents/Button';
import Svg from 'erpcomponents/Svg';
import Tooltip from 'erpcomponents/Tooltip';
import PageLoader from 'erpcomponents/PageLoader';
import Modal from 'erpcomponents/Modal';
import { actions as eventCategoriesActions } from 'erpcore/screens/Settings/screens/AccountingCategories/EventCategories/EventCategories.reducer';
import {
    getEventCategoryData,
    getSingleEventCategoryFetching
} from 'erpcore/screens/Settings/screens/AccountingCategories/EventCategories/EventCategories.selectors';

class EventCategoryEdit extends Component {
    constructor(props) {
        super(props);

        this.state = {
            opened: false
        };

        this.handleModal = this.handleModal.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        const { fetchEventCategoryData } = this.props;
        const { opened } = this.state;
        if (prevState.opened !== opened) {
            fetchEventCategoryData();
        }
    }

    onSubmit(formData) {
        const { id, dispatch } = this.props;

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: eventCategoriesActions.START_UPDATE_SINGLE_EVENT_CATEGORY,
                id,
                formData
            })
        )
            .then(() => this.handleModal())
            .catch(error => {
                return error;
            });
    }

    handleModal() {
        const { opened: isOpen } = this.state;
        this.setState({ opened: !isOpen });
    }

    render() {
        const {
            handleSubmit,
            submitting,
            categories,
            singleFetching,
            pristine,
            invalid
        } = this.props;
        const { opened } = this.state;
        return (
            <Fragment>
                <Tooltip
                    content={
                        <FormattedMessage
                            id="Settings.EventCategories.Table.Edit"
                            defaultMessage="Edit Event Category"
                        />
                    }
                >
                    <button type="button" onClick={() => this.handleModal()}>
                        <Svg icon="edit" />
                    </button>
                </Tooltip>
                <Modal
                    variation="small"
                    opened={opened}
                    onClose={() => this.handleModal()}
                    title={
                        <FormattedMessage
                            id="Settings.EventCategories.EditModal.title"
                            defaultMessage="Edit Event Category"
                        />
                    }
                >
                    {singleFetching && <PageLoader />}
                    <Form onSubmit={handleSubmit(this.onSubmit)}>
                        <Form.Row>
                            <Field
                                name="name"
                                id="name"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="Settings.EventCategories.CategoryName"
                                            defaultMessage="Category Name"
                                        />
                                    ),
                                    clearable: true,
                                    validation: 'required'
                                }}
                                component={Text}
                            />
                        </Form.Row>
                        <Form.Row>
                            <Field
                                name="parent"
                                id="parent"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="Settings.EventCategories.selectParentCategory"
                                            defaultMessage="Choose parent category"
                                        />
                                    ),
                                    options: categories,
                                    clearable: true
                                }}
                                component={Select}
                            />
                        </Form.Row>
                        <Button
                            submit
                            loading={submitting}
                            disabled={pristine || invalid}
                            label={
                                <FormattedMessage
                                    id="Settings.EventCategories.Table.Actions.Edit.Modal.Update"
                                    defaultMessage="Update"
                                />
                            }
                        />
                        <Button
                            onClick={() => this.handleModal()}
                            variation="secondary"
                            label={
                                <FormattedMessage
                                    id="Settings.EventCategories.Table.Actions.Edit.Modal.Cancel"
                                    defaultMessage="Cancel"
                                />
                            }
                        />
                    </Form>
                </Modal>
            </Fragment>
        );
    }
}

EventCategoryEdit.defaultProps = {
    submitting: false,
    id: null,
    dispatch: () => {},
    // parent: null
    pristine: false,
    invalid: false,
    singleFetching: false,
    fetchEventCategoryData: () => {}
};

EventCategoryEdit.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    dispatch: PropTypes.func,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    // parent: PropTypes.string,
    // categoriesData: PropTypes.oneOfType([PropTypes.object]).isRequired,
    categories: PropTypes.oneOfType([PropTypes.object]).isRequired,
    singleFetching: PropTypes.bool,
    fetchEventCategoryData: PropTypes.func

    // iri: PropTypes.string.isRequired
};

EventCategoryEdit = reduxForm({
    form: 'EventCategoryEditForm',
    enableReinitialize: true
})(EventCategoryEdit);

const mapStateToProps = (state, ownProps) => ({
    initialValues: dtoForm(getEventCategoryData(state, ownProps.id)),
    singleFetching: getSingleEventCategoryFetching(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchEventCategoryData: () => {
        // Getting included data from API by setting params

        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: eventCategoriesActions.START_FETCHING_SINGLE_EVENT_CATEGORY,
                id: ownProps.id
            });
        }).catch(error => ({ error }));
    }
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(EventCategoryEdit)
);
