import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import LayoutManager from 'erputils/LayoutManager';
import frontLayout from 'erputils/LayoutManager/frontLayout';
import PageLoader from 'erpcomponents/PageLoader';
import PageNotification from 'erpcomponents/Layout/PageNotification';
import { actions as signUpActions } from 'erpcore/screens/SignUp/SignUp.reducer';
import { getPageNotification } from 'erputils/NotificationManager/NotificationManager.selectors';
import { getInvitationData, getInvitationFetching } from 'erpcore/screens/SignUp/SignUp.selectors';

class InvitationDeclined extends Component {
    componentDidMount() {
        const { declineInvitation } = this.props;
        declineInvitation();
    }

    render() {
        const { singleFetching, pageNotification, initialValues } = this.props;

        return (
            <LayoutManager layout={frontLayout} slot="main" layoutType="merge">
                {/* Content that goes into the "main" slot */}
                {singleFetching === true && <PageLoader />}
                <h2 className="front-main__title">
                    <FormattedMessage
                        id="InvitationDeclined.title"
                        defaultMessage="Invitation Declined"
                    />
                </h2>

                <p className="front-main__subtitle">
                    <FormattedMessage
                        id="InvitationDeclined.subtitle"
                        defaultMessage="You have declined invite from {companyName}."
                        values={{ companyName: initialValues.organization_name }}
                    />
                </p>

                {pageNotification && pageNotification.code && (
                    <PageNotification code={pageNotification.code} />
                )}

                <div className="page-content">
                    <p>
                        <FormattedMessage
                            id="InvitationDeclined.text"
                            defaultMessage="Need help or more information?"
                        />{' '}
                        <FormattedMessage
                            id="InvitationDeclined.text.contact"
                            defaultMessage="Contact:"
                        />{' '}
                        <a href="mailto:daryl@glowgardens.com">daryl@glowgardens.com</a>
                    </p>
                </div>
            </LayoutManager>
        );
    }
}

InvitationDeclined.defaultProps = {
    declineInvitation: () => {},
    singleFetching: false,
    pageNotification: null,
    initialValues: {}
};

InvitationDeclined.propTypes = {
    declineInvitation: PropTypes.func,
    singleFetching: PropTypes.bool,
    initialValues: PropTypes.oneOfType([PropTypes.object]),
    pageNotification: PropTypes.oneOfType([PropTypes.object])
};

const mapStateToProps = (state, ownProps) => ({
    initialValues: getInvitationData(state, ownProps.match.params.token),
    singleFetching: getInvitationFetching(state),
    pageNotification: getPageNotification(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    declineInvitation: () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: signUpActions.START_DECLINING_INVITATION,
                token: ownProps.match.params.token
            });
        }).catch(error => ({ error }));
    }
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(InvitationDeclined)
);
