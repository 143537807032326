import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { dtoForm } from 'erputils/dto';
import { FormattedMessage } from 'react-intl';
import LayoutManager from 'erputils/LayoutManager';
import PageHeader from 'erpcomponents/Layout/PageHeader';
import PageContent from 'erpcomponents/Layout/PageContent';
import EventInfo from 'erpcore/screens/Events/components/EventInfo';
import EventManageTabs from 'erpcore/screens/Events/components/EventManageTabs';
import PageLoader from 'erpcomponents/PageLoader';
import { actions as eventsActions } from 'erpcore/screens/Events/Events.reducer';
import { getEventData, getSingleEventFetching } from 'erpcore/screens/Events/Events.selectors';
import ActivityLog from 'erpcomponents/ActivityLog';

class EventActivityLog extends Component {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        const { fetchEventData } = this.props;
        fetchEventData();
    }

    onChange(formData) {
        const { match, dispatch } = this.props;

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: eventsActions.START_UPDATE_SINGLE_EVENT,
                id: match.params.id,
                formData
            })
        ).catch(error => {
            return error;
        });
    }

    render() {
        const { singleFetching } = this.props;

        return (
            <LayoutManager slot="main" className="main--narrow" layoutType="merge">
                <PageHeader
                    title={
                        <FormattedMessage
                            id="EventEdit.Production.title"
                            defaultMessage="Edit Event"
                        />
                    }
                />
                {singleFetching && <PageLoader />}
                <EventInfo />
                <EventManageTabs />
                <PageContent>
                    <ActivityLog entity="events" />
                </PageContent>
            </LayoutManager>
        );
    }
}

EventActivityLog.defaultProps = {
    fetchEventData: () => {},
    dispatch: () => {},
    singleFetching: false
};

EventActivityLog.propTypes = {
    dispatch: PropTypes.func,
    match: PropTypes.oneOfType([PropTypes.object]).isRequired,
    fetchEventData: PropTypes.func,
    singleFetching: PropTypes.bool
};

//  Getting initial value populated in the form from the store
const mapStateToProps = (state, ownProps) => ({
    initialValues: dtoForm(getEventData(state, ownProps.match.params.id)),
    singleFetching: getSingleEventFetching(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchEventData: () => {
        // Getting included data from API by setting params
        const params = {
            include: 'organization,location,location.country,location.state,location.city'
        };
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: eventsActions.START_FETCHING_SINGLE_EVENT,
                id: ownProps.match.params.id,
                params
            });
        }).catch(error => ({ error }));
    }
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(EventActivityLog)
);
