import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PageNotification from 'erpcomponents/Layout/PageNotification';
import FloatingNotifications from 'erpcomponents/Layout/FloatingNotifications';
import './Main.scss';
import {
    getPageNotification,
    getFloatingNotifications
} from 'erputils/NotificationManager/NotificationManager.selectors';

const Main = ({ children, className, pageNotification, floatingNotifications }) => {
    return (
        <>
            <main className={`main ${className || ''}`}>
                {pageNotification && pageNotification.code && (
                    <PageNotification code={pageNotification.code} data={pageNotification.data} />
                )}
                {children}
            </main>
            <FloatingNotifications notifications={floatingNotifications} />
        </>
    );
};

Main.defaultProps = {
    children: null,
    className: '',
    pageNotification: null,
    floatingNotifications: []
};

Main.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    className: PropTypes.string,
    pageNotification: PropTypes.oneOfType([PropTypes.object]),
    floatingNotifications: PropTypes.oneOfType([PropTypes.array])
};

const mapStateToProps = state => ({
    pageNotification: getPageNotification(state),
    floatingNotifications: getFloatingNotifications(state)
});

export default connect(mapStateToProps)(Main);
