import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';

import Button from 'erpcore/components/Button';
import Form, { Select } from 'erpcore/components/Form';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import {
    getBulkActionsIris,
    getBulkActionsModalIsOpened
} from 'erpcore/components/Listing/Listing.selectors';
import { submittingBulkAction, getResellers } from 'erpcore/screens/Resellers/Resellers.selectors';
import { actions as resellerActions } from 'erpcore/screens/Resellers/Resellers.reducer';

import valueValidation from 'erpcore/utils/valueValidation';

const SetAccountStatus = ({ handleSubmit, pristine, invalid }) => {
    const bulkActionsIris = useSelector(state => getBulkActionsIris(state, 'resellers'));
    const resellers = useSelector(state => getResellers(state, 'resellers'));
    const isOpen = useSelector(state => getBulkActionsModalIsOpened(state, 'resellers'));
    const submitting = useSelector(state => submittingBulkAction(state));
    const dispatch = useDispatch();

    const handleModal = () => {
        return dispatch({
            type: listingActions.TOGGLE_BULK_ACTIONS_MODAL,
            name: 'resellers',
            response: !isOpen
        });
    };

    const onSubmit = formData => {
        const userAccounts = [];
        if (resellers) {
            resellers.map(reseller => {
                const { reseller_primary_account: primaryAccount } = reseller;
                if (bulkActionsIris.indexOf(reseller?.iri) > -1 && primaryAccount) {
                    userAccounts.push(primaryAccount?.iri);
                }
                return null;
            });
        }
        formData = {
            is_active: JSON.parse(formData.is_active)
        };
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: resellerActions.START_BULK_ACTIONS_UPDATE_RESELLERS,
                formData: {
                    body: { ...formData },
                    method: 'PUT',
                    routes: userAccounts
                },
                endpoint: '/api/batch-requests'
            })
        )
            .then(() => handleModal())
            .catch(error => {
                return error;
            });
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <p>
                <FormattedMessage
                    id="Resellers.BulkAction.SetAccountStatus.Subtitle"
                    defaultMessage="You are about to change account status for {resellers} resellers."
                    values={{
                        resellers: bulkActionsIris && bulkActionsIris.length
                    }}
                />
            </p>
            <Form.Row>
                <Field
                    name="is_active"
                    component={Select}
                    fieldProps={{
                        label: (
                            <FormattedMessage
                                id="Resellers.BulkAction.SetAccountStatus.MicrositeStatus"
                                defaultMessage="Account Status"
                            />
                        ),
                        options: [
                            { label: 'Active', value: 'true' },
                            { label: 'Inactive', value: 'false' }
                        ]
                    }}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Button
                submit
                loading={submitting}
                disabled={pristine || invalid}
                label={
                    <FormattedMessage
                        id="Resellers.BulkAction.SetAccountStatus.Confirm"
                        defaultMessage="Submit"
                    />
                }
            />
            <Button
                variation="secondary"
                label={
                    <FormattedMessage
                        id="Resellers.BulkAction.SetAccountStatus.Cancel"
                        defaultMessage="Cancel"
                    />
                }
                onClick={() => handleModal()}
            />
        </Form>
    );
};

SetAccountStatus.defaultProps = {
    pristine: false,
    invalid: false
};

SetAccountStatus.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool
};

export default reduxForm({
    form: 'SetAccountStatusForm',
    enableReinitialize: true
})(SetAccountStatus);
