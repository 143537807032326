import { takeLatest, put, call, fork, take, select } from 'redux-saga/effects';
import { channel } from 'redux-saga';
import restClient from 'erpcore/api/restClient';
import { parseParamsForApi } from 'erputils/utils';
import { getListingUrlQueryParams } from 'erputils/RouterManager/RouterManager.selectors';
import { actions as listingActions } from 'erpcomponents/Listing/Listing.reducer';
import { actions as notificationManagerActions } from 'erputils/NotificationManager/NotificationManager.reducer';
import dto from 'erputils/dto';
// import { getVenueData } from 'erpcore/screens/Venues/Venues.selectors';
import { actions as tableTemplatesActions } from './TableTemplates.reducer';

/**
 * Create Table Template
 * @param  {Object} promise
 * @return {Object} formData
 */

export function* createTableTemplate({ promise, formData }) {
    try {
        // const venue = yield select(getVenueData('venues', venueId));
        // console.log('venue', venue);
        // formData = Object.assign(formData, {
        //     locations: [venue.data.iri]
        // });
        const createTableTemplateAPI = yield restClient.post(
            `api/table-templates?include=location`,
            formData
        );
        yield put({
            type: tableTemplatesActions.STORE_CREATED_TABLE_TEMPLATE,
            response: dto(createTableTemplateAPI.data)
        });
        yield put({
            type: tableTemplatesActions.CREATE_TABLE_TEMPLATE_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createTableTemplateAPI.data
        });
        yield call(promise.resolve, createTableTemplateAPI.data);
    } catch (error) {
        yield put({
            type: tableTemplatesActions.CREATE_TABLE_TEMPLATE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}
/**
 * Create Table Template
 * @param  {Object} promise
 * @return {Object} formData
 */

export function* createTable({ promise, formData }) {
    try {
        const createTableTemplateAPI = yield restClient.post(`api/tables?include=room`, formData);
        yield put({
            type: tableTemplatesActions.CREATE_TABLE_SUCCESSFUL,
            response: createTableTemplateAPI.data
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createTableTemplateAPI.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: tableTemplatesActions.CREATE_TABLE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Fetch Table Template
 * @param  {Object} promise
 * @return {string} id table template id
 */
export function* fetchTableTemplate({ promise, id, params }) {
    try {
        const fetchTableTemplateAPI = yield restClient.get(
            `api/table-templates/${id}?include=tableTemplates`,
            { params }
        );
        yield put({
            type: tableTemplatesActions.FETCH_SINGLE_TABLE_TEMPLATE_SUCCESSFUL
        });
        yield put({
            type: tableTemplatesActions.STORE_SINGLE_TABLE_TEMPLATE_DATA,
            id,
            response: dto(fetchTableTemplateAPI.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: tableTemplatesActions.FETCH_SINGLE_TABLE_TEMPLATE_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Fetch Table Template
 * @param  {Object} promise
 * @return {string} id table template id
 */
export function* fetchEventTableTemplate({ promise, id, params }) {
    try {
        const fetchTableTemplateAPI = yield restClient.get(
            `api/table-templates/${id}/duplicate?include=tables,tables.room,tables.canBeGroupedWith`,
            params
        );
        yield put({
            type: tableTemplatesActions.FETCH_SINGLE_TABLE_TEMPLATE_FOR_EVENT_SUCCESSFUL,
            response: dto(fetchTableTemplateAPI.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: tableTemplatesActions.FETCH_SINGLE_TABLE_TEMPLATE_FOR_EVENT_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Fetch Table Template Tables
 * @param  {Object} promise
 * @return {string} id table template id
 */
export function* fetchTableTemplateTables({ promise, id, params }) {
    try {
        const tableTemplatesTablesAPI = yield restClient.get(
            `api/tables?include=tableTemplate,room,canBeGroupedWith&filters[table_template.id][equals]=${id}&pagination=false`,
            { params }
        );
        yield put({
            type: tableTemplatesActions.FETCHING_TABLE_TEMPLATE_TABLES_SUCCESSFUL,
            response: dto(tableTemplatesTablesAPI.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: tableTemplatesActions.FETCHING_TABLE_TEMPLATE_TABLES_FAILED
        });

        yield call(promise.reject, error.response.data);
    }
}

/**
 * Fetch Tables
 * @param  {Object} promise
 * @return {string} id table template id
 */
export function* fetchEventTables({ promise, id, params }) {
    try {
        const eventTablesAPI = yield restClient.get(
            `api/tables?filters[event.id][equals]=${id}&include=canBeGroupedWith,room&pagination=false`,
            { params }
        );
        yield put({
            type: tableTemplatesActions.FETCH_EVENT_TABLES_SUCCESSFUL,
            response: dto(eventTablesAPI.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: tableTemplatesActions.FETCH_EVENT_TABLES_FAILED
        });

        yield call(promise.reject, error.response.data);
    }
}

/**
 * duplicate Table Template
 * @param  {Object} promise
 * @return {string} id table template id
 */
export function* duplicateTableTemplate({ promise, id, params }) {
    try {
        const duplicateTableTemplateAPI = yield restClient.get(
            `api/table-templates/${id}/duplicate?include=tables,tables.room,tables.canBeGroupedWith`,
            params
        );
        yield put({
            type: tableTemplatesActions.DUPLICATE_SINGLE_TABLE_TEMPLATE_SUCCESSFUL
        });
        yield put({
            type: tableTemplatesActions.STORE_DUPLICATED_TABLE_TEMPLATE_DATA,
            id,
            response: dto(duplicateTableTemplateAPI.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: tableTemplatesActions.DUPLICATE_SINGLE_TABLE_TEMPLATE_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Update Table Template single data
 * @param  {Object} id ID of an Table Template
 * @return {Object} Response from API
 */
export function* updateSingleTableTemplate({ promise, formData, id }) {
    try {
        const updateSingleTableTemplateAPI = yield restClient.put(
            `api/table-templates/${id}?include=tables,tables.room,tables.canBeGroupedWith`,
            formData
        );
        yield put({
            type: tableTemplatesActions.UPDATE_SINGLE_TABLE_TEMPLATE_SUCCESSFUL
        });
        yield put({
            type: tableTemplatesActions.STORE_SINGLE_TABLE_TEMPLATE_DATA,
            id,
            response: dto(updateSingleTableTemplateAPI.data)
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateSingleTableTemplateAPI.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: tableTemplatesActions.UPDATE_SINGLE_TABLE_TEMPLATE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Update Table Template single data
 * @param  {Object} id ID of an Table Template
 * @return {Object} Response from API
 */
export function* updateSingleTable({ promise, formData, id }) {
    try {
        const updateTableAPI = yield restClient.put(
            `api/tables/${id}?include=canBeGroupedWith,room`,
            formData
        );
        yield put({
            type: tableTemplatesActions.UPDATE_TABLE_SUCCESSFUL
        });
        // yield put({
        //     type: tableTemplatesActions.STORE_TABLE_DATA,
        //     id,
        //     response: dto(updateTableAPI.data)
        // });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateTableAPI.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: tableTemplatesActions.UPDATE_TABLE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}
/**
 * Delete Table
 * @param  {Object} id ID of an Table Template
 * @return {Object} Response from API
 */
export function* deleteSingleTable({ promise, id }) {
    try {
        const updateTableAPI = yield restClient.delete(`api/tables/${id}`);
        yield put({
            type: tableTemplatesActions.DELETE_TABLE_SUCCESSFUL
        });
        // yield put({
        //     type: tableTemplatesActions.STORE_TABLE_DATA,
        //     id,
        //     response: dto(updateTableAPI.data)
        // });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateTableAPI.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: tableTemplatesActions.UPDATE_TABLE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}
/**
 * Delete Single Table Template
 * @param  {Object} id ID of an Table Template
 * @return {Object} Response from API
 */
export function* deleteSingleTableTemplate({ promise, id, locationId }) {
    const params = Object.assign(
        {
            'filters[locations.id][equals]': locationId
        },
        {},
        yield select(getListingUrlQueryParams)
    );

    try {
        const deleteSingleTableTemplateAPI = yield restClient.delete(`api/table-templates/${id}`);
        yield put({
            type: tableTemplatesActions.DELETE_SINGLE_TABLE_TEMPLATE_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: deleteSingleTableTemplateAPI.data
        });
        yield put({
            type: listingActions.START_FETCHING_LISTING,
            entity: 'TABLE_TEMPLATES',
            endpoint: 'api/table-templates',
            params: parseParamsForApi(params)
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: tableTemplatesActions.DELETE_SINGLE_TABLE_TEMPLATE_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Merge Tables single data
 * @param  {Object} iris of the tables
 * @return {Object} Response from API
 */
export function* mergeTables({ promise, formData }) {
    try {
        const mergeTablesAPI = yield restClient.post(
            `/api/table-merge-requests?include=canBeGroupedWith`,
            formData
        );
        yield put({
            type: tableTemplatesActions.MERGE_TABLES_SUCCESSFUL
        });
        // yield put({
        //     type: tableTemplatesActions.STORE_TABLE_DATA,
        //     id,
        //     response: dto(updateTableAPI.data)
        // });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: mergeTablesAPI.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: tableTemplatesActions.MERGE_TABLES_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

function takeLatestPerProps(propsOrSelector, pattern, worker, ...args) {
    // Not a generator
    return fork(function* generator() {
        // Fork a generator here to make it work like takeLatest
        const channelsMap = {};
        while (true) {
            const action = yield take(pattern); // yield necessary here
            const propsValue =
                typeof propsOrSelector === 'function'
                    ? propsOrSelector(action)
                    : action[propsOrSelector];
            if (!channelsMap[propsValue]) {
                channelsMap[propsValue] = channel();
                yield takeLatest(channelsMap[propsValue], worker, ...args);
            }
            yield put(channelsMap[propsValue], action);
        }
    });
}

/**
 * Register action to watcher
 */
export const tableTemplatesSaga = [
    takeLatest(tableTemplatesActions.START_FETCHING_SINGLE_TABLE_TEMPLATE, fetchTableTemplate),
    takeLatest(
        tableTemplatesActions.START_FETCHING_SINGLE_TABLE_TEMPLATE_FOR_EVENT,
        fetchEventTableTemplate
    ),
    takeLatest(tableTemplatesActions.START_DUPLICATE_SINGLE_TABLE_TEMPLATE, duplicateTableTemplate),
    takeLatest(tableTemplatesActions.START_CREATE_TABLE_TEMPLATE, createTableTemplate),
    takeLatest(tableTemplatesActions.START_DELETE_TABLE, deleteSingleTable),
    takeLatestPerProps('id', tableTemplatesActions.REQUEST_UPDATE_SINGLE_TABLE, updateSingleTable),
    takeLatest(tableTemplatesActions.START_UPDATE_TABLE, updateSingleTable),
    takeLatest(tableTemplatesActions.START_UPDATE_SINGLE_TABLE_TEMPLATE, updateSingleTableTemplate),
    takeLatest(tableTemplatesActions.START_DELETE_SINGLE_TABLE_TEMPLATE, deleteSingleTableTemplate),
    takeLatest(tableTemplatesActions.START_CREATE_TABLE, createTable),
    takeLatest(
        tableTemplatesActions.START_FETCHING_TABLE_TEMPLATE_TABLES,
        fetchTableTemplateTables
    ),
    takeLatest(tableTemplatesActions.START_MERGE_TABLES, mergeTables),
    takeLatest(tableTemplatesActions.START_FETCH_EVENT_TABLES, fetchEventTables)
];
