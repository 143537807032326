import { createSelector } from 'reselect';
import { createBrowserHistory } from 'history';
import qs from 'qs';

export const getListingFetching = (state, entity) => state[entity]?.listingFetching;

export const getListingResponse = (state, entity) => {
    if (!state[entity] || !state[entity]?.listingResponse) {
        return {
            data: [],
            meta: {}
        };
    }
    return state[entity].listingResponse;
};

export const getBulkActionsIris = (state, entity) => {
    return state?.listing[entity]?.bulkActions?.iris || [];
};

export const getBulkActionsModalIsOpened = (state, entity) => {
    return state?.listing[entity]?.bulkActions?.modalOpened;
};

export const getBulkSelectAll = (state, entity) => {
    return state?.listing[entity]?.bulkActions?.selectAll;
};

export const getQueryParams = (state, settings) => {
    const history = createBrowserHistory();
    const { name: entity, removeCount, addCount } = settings;
    const queryParams = state?.listing[entity]?.queryParams;
    const { listingResponse } = { ...state[entity] };
    if (!removeCount && !addCount) {
        return queryParams;
    }
    if (removeCount) {
        if (
            listingResponse?.data?.length - removeCount < 1 &&
            listingResponse?.meta?.currentPage !== 1
        ) {
            queryParams.page = listingResponse?.meta?.currentPage - 1;
        }
    }
    if (addCount) {
        if (listingResponse?.data?.length + addCount > listingResponse?.meta?.itemsPerPage) {
            const lastPage = Math.ceil(
                listingResponse?.meta?.totalItems / listingResponse?.meta?.itemsPerPage
            );
            queryParams.page = lastPage;
        }
    }

    const urlQueryParams = qs.parse(history.location.search.substr(1));
    if (!urlQueryParams[entity]) {
        urlQueryParams[entity] = {};
    }
    urlQueryParams[entity].page = queryParams.page;
    //  Pushing updated query params to url bar
    history.push({
        pathName: history.location.pathname,
        search: qs.stringify(urlQueryParams, { encodeValuesOnly: true }),
        state: {
            changedByListing: true
        }
    });
    return queryParams;
};

export default createSelector([
    getListingFetching,
    getListingResponse,
    getBulkActionsIris,
    getBulkActionsModalIsOpened,
    getBulkSelectAll,
    getQueryParams
]);
