import { takeEvery, put, call } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import { actions as notificationManagerActions } from 'erputils/NotificationManager/NotificationManager.reducer';
import { actions as salesOverviewActions } from './SalesOverview.reducer';

/**
 * Fetch SalesOverview Data
 * @param  {Object} promise
 * @return {Object} params
 */
export function* fetchData({ promise, widgetId, params = {}, source }) {
    try {
        const fetchDataAPI = yield restClient.get(`api/orders/report`, {
            headers: { Accept: 'application/json' },
            params
        });
        yield put({
            type: salesOverviewActions.FETCHING_SALESOVERVIEW_DATA_SUCCESSFUL,
            widgetId
        });
        yield put({
            type: salesOverviewActions.STORE_SALESOVERVIEW_DATA,
            widgetId,
            source,
            response: fetchDataAPI && fetchDataAPI.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: salesOverviewActions.FETCHING_SALESOVERVIEW_DATA_FAILED,
            widgetId
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error && error.response && error.response.data
        });
        yield call(promise.reject, error && error.response && error.response.data);
    }
}

/**
 * Register action to watcher
 */
export const salesOverviewSaga = [
    takeEvery(salesOverviewActions.START_FETCHING_SALESOVERVIEW_DATA, fetchData)
];
