import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './EventDescription.scss';
import Svg from 'erpcore/components/Svg';
import Button from 'erpcomponents/Button';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import reduxFormErrorMapper from 'erputils/reduxFormErrorMapper';
import { actions as resellersActions } from 'erpcore/screens/Resellers/Resellers.reducer';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import Form, { Textarea } from 'erpcore/components/Form';

class EventDescription extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: true
        };
        this.togglePanel = this.togglePanel.bind(this);
    }

    togglePanel() {
        const { open } = this.state;
        this.setState({ open: !open });
    }

    render() {
        const {
            handleSubmit,
            submitting,
            pristine,
            invalid,
            onSubmit,
            event,
            submitSucceeded
        } = this.props;
        const { open } = this.state;

        return !submitSucceeded ? (
            <div className="event-description">
                <div
                    className="event-description__header"
                    onKeyPress={() => {}}
                    onClick={() => this.togglePanel()}
                    role="button"
                    tabIndex="-1"
                >
                    <span>{event.name && event.name}</span>
                    <Svg className="event-description__svg" icon={open ? 'close' : 'arrowDown'} />
                </div>
                {open ? (
                    <Form
                        onSubmit={handleSubmit(onSubmit)}
                        className="event-description__description"
                    >
                        <Form.Row>
                            <Field
                                name="seo_description"
                                id="seo_description"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="Reseller.SEO.Description"
                                            defaultMessage="Description"
                                        />
                                    ),
                                    clearable: true
                                }}
                                component={Textarea}
                            />
                        </Form.Row>
                        <Form.Row>
                            <Button
                                submit
                                disabled={pristine || invalid}
                                loading={submitting}
                                label={
                                    <FormattedMessage id="Reseller.SEO.Add" defaultMessage="Add" />
                                }
                            />
                        </Form.Row>
                    </Form>
                ) : null}
            </div>
        ) : null;
    }
}

EventDescription.defaultProps = {
    submitting: false,
    pristine: false,
    submitSucceeded: false,
    invalid: false,
    onSubmit: () => {},
    event: {}
};
EventDescription.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    submitSucceeded: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    event: PropTypes.oneOfType([PropTypes.object])
};

EventDescription = reduxForm({
    form: 'EventDescriptionForm',
    enableReinitialize: true
})(EventDescription);

const mapDispatchToProps = (dispatch, ownProps) => ({
    onSubmit: formData => {
        const eventDescription = {
            seo_description: formData.seo_description,
            event: ownProps.reseller.event_description
        };
        const eventDescriptions = ownProps.reseller.event_descriptions
            ? ownProps.reseller.event_descriptions
            : [];
        eventDescriptions.push(eventDescription);
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: resellersActions.START_UPDATE_SINGLE_RESELLER_SEO,
                id: ownProps.reseller.id,
                formData: { event_descriptions: eventDescriptions }
            });
        })
            .then(() => ownProps.fetchResellerData())
            .catch(error => {
                throw new SubmissionError(reduxFormErrorMapper(error));
            });
    }
});

export default connect(
    null,
    mapDispatchToProps
)(EventDescription);
