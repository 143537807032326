import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import reduxFormErrorMapper from 'erputils/reduxFormErrorMapper';
import { Field, reduxForm, SubmissionError, reset } from 'redux-form';
import Form, { Text, Autocomplete, MultiAutocomplete } from 'erpcore/components/Form';
import valueValidation from 'erputils/valueValidation';
import LayoutManager from 'erputils/LayoutManager';
import PageHeader from 'erpcomponents/Layout/PageHeader';
import PageContent from 'erpcomponents/Layout/PageContent';
import Button from 'erpcomponents/Button';
import { actions as groupsActions } from 'erpcore/screens/Groups/Groups.reducer';

class GroupCreate extends Component {
    constructor(props) {
        super(props);

        this.state = {
            event: null
        };
    }

    onChangeEvent = event => {
        this.setState({ event });
    };

    render() {
        const { handleSubmit, submitting, pristine, invalid, onSubmit } = this.props;
        const { event } = this.state;
        const eventId = event && event.match(/\d+/g)[0];

        return (
            <LayoutManager slot="main" className="main--narrow" layoutType="merge">
                <PageHeader
                    title={
                        <FormattedMessage id="GroupCreate.title" defaultMessage="Create Group" />
                    }
                    backButtonLink="/groups"
                />
                <PageContent>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Row>
                            <Field
                                name="name"
                                id="name"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="GroupCreate.Grpup name"
                                            defaultMessage="Group Name"
                                        />
                                    ),
                                    clearable: true
                                }}
                                component={Text}
                                validate={valueValidation([{ validator: 'required' }])}
                                fieldAttr={{ required: true }}
                            />
                        </Form.Row>
                        <Form.Row>
                            <Field
                                name="group_size"
                                id="group_size"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="GroupCreate.GroupSize"
                                            defaultMessage="Group Size"
                                        />
                                    )
                                }}
                                component={Text}
                                fieldAttr={{ required: true }}
                                validate={valueValidation([
                                    { validator: 'numericality' },
                                    { validator: 'required' }
                                ])}
                            />
                        </Form.Row>
                        <Form.Row>
                            <Field
                                name="event"
                                id="event"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="GroupCreate.Event"
                                            defaultMessage="Event"
                                        />
                                    ),
                                    options: {
                                        endpoint: 'api/events?pagination=false',
                                        mapData: {
                                            label: 'name',
                                            value: 'iri'
                                        }
                                    }
                                }}
                                onChange={this.onChangeEvent}
                                component={Autocomplete}
                            />
                        </Form.Row>
                        <Form.Row>
                            <Field
                                name="orders"
                                id="orders"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="GroupCreate.OrderIDs"
                                            defaultMessage="Order IDs"
                                        />
                                    ),
                                    options: {
                                        endpoint: `api/orders?include=event&filters[event.id][equals]=${eventId &&
                                            eventId}&pagination=false`,
                                        mapData: {
                                            label: 'id',
                                            value: 'iri'
                                        }
                                    }
                                }}
                                component={MultiAutocomplete}
                            />
                        </Form.Row>
                        <Form.Row>
                            <Field
                                name="tables"
                                id="tables"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="GroupCreate.Tables"
                                            defaultMessage="Tables"
                                        />
                                    ),
                                    options: {
                                        endpoint: `api/tables?include=event&filters[event.id][equals]=${eventId &&
                                            eventId}&pagination=false`,
                                        mapData: {
                                            label: 'table_number',
                                            value: 'iri'
                                        }
                                    }
                                }}
                                component={Autocomplete}
                            />
                        </Form.Row>

                        <Form.Row>
                            <Button
                                submit
                                disabled={pristine || invalid}
                                loading={submitting}
                                label={
                                    <FormattedMessage
                                        id="GroupCreate.CreateGroup"
                                        defaultMessage="Create Group"
                                    />
                                }
                            />
                        </Form.Row>
                    </Form>
                </PageContent>
            </LayoutManager>
        );
    }
}

GroupCreate.defaultProps = {
    submitting: false,
    pristine: false,
    invalid: false,
    onSubmit: () => {}
};

GroupCreate.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    onSubmit: PropTypes.func
};

GroupCreate = reduxForm({
    form: 'GroupCreateForm',
    enableReinitialize: true
})(GroupCreate);

const mapDispatchToProps = dispatch => ({
    onSubmit: formData => {
        formData.group_size = parseInt(formData.group_size, 10);
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: groupsActions.START_CREATE_GROUP,
                formData
            });
        })
            .then(() => {
                dispatch(reset('GroupCreateForm'));
            })
            .catch(error => {
                throw new SubmissionError(reduxFormErrorMapper(error));
            });
    }
});

export default connect(
    null,
    mapDispatchToProps
)(GroupCreate);
