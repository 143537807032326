import React, { Component } from 'react';
import Form, { Text, Select } from 'erpcore/components/Form';
import { FormattedMessage } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import Button from 'erpcomponents/Button';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { actions as eventCategoriesActions } from 'erpcore/screens/Settings/screens/AccountingCategories/EventCategories/EventCategories.reducer';
import { dtoForm } from 'erputils/dto';
import { diff } from 'deep-object-diff';
import {
    getEventCategoryData,
    getSingleEventCategoryFetching
} from 'erpcore/screens/Settings/screens/AccountingCategories/EventCategories/EventCategories.selectors';
import PageLoader from 'erpcomponents/PageLoader';
import { withRouter } from 'react-router-dom';

class CategoryEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        const { fetchEventCategoryData } = this.props;
        fetchEventCategoryData();
    }

    componentDidUpdate(prevProps) {
        const { fetchEventCategoryData, id } = this.props;
        if (id !== prevProps.id) {
            fetchEventCategoryData();
        }
    }

    onSubmit(formData) {
        const { id, dispatch, initialValues, resetId } = this.props;
        // send only changed data
        formData = diff(initialValues, formData);

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: eventCategoriesActions.START_UPDATE_SINGLE_EVENT_CATEGORY,
                id,
                formData
            })
        )
            .then(() => resetId())
            .catch(error => {
                return error;
            });
    }

    render() {
        const {
            handleSubmit,
            submitting,
            pristine,
            invalid,
            singleFetching,
            categories
        } = this.props;
        return (
            <Form onSubmit={handleSubmit(this.onSubmit)}>
                {singleFetching === true && <PageLoader />}
                <Form.Row>
                    <Form.Columns breakOnMobile>
                        <Form.Column>
                            <Field
                                name="name"
                                id="name"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="Settings.EventCategories.CategoryEdit.CategoryName"
                                            defaultMessage="Category Name"
                                        />
                                    ),
                                    clearable: true,
                                    validation: 'required'
                                }}
                                component={Text}
                            />
                        </Form.Column>
                        <Form.Column>
                            <Field
                                name="parent"
                                id="parent"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="Settings.EventCategories.CategoryEdit.selectParentCategory"
                                            defaultMessage="Choose parent category"
                                        />
                                    ),
                                    options: categories,
                                    clearable: true
                                }}
                                component={Select}
                            />
                        </Form.Column>

                        <Form.Column>
                            <Button
                                submit
                                disabled={pristine || invalid}
                                loading={submitting}
                                label={
                                    <FormattedMessage
                                        id="Settings.EventCategories.CategoryEdit.Update"
                                        defaultMessage="Update"
                                    />
                                }
                            />
                        </Form.Column>
                    </Form.Columns>
                </Form.Row>
            </Form>
        );
    }
}

CategoryEdit.defaultProps = {
    submitting: false,
    pristine: false,
    invalid: false,
    fetchEventCategoryData: () => {},
    singleFetching: false,
    initialValues: {},
    resetId: () => {},
    categories: []
};

CategoryEdit.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    id: PropTypes.oneOfType([PropTypes.object]).isRequired,
    initialValues: PropTypes.oneOfType([PropTypes.object]),
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    fetchEventCategoryData: PropTypes.func,
    singleFetching: PropTypes.bool,
    resetId: PropTypes.func,
    categories: PropTypes.oneOfType([PropTypes.array])
};

CategoryEdit = reduxForm({
    form: 'CategoryEditForm',
    enableReinitialize: true
})(CategoryEdit);

//  Getting initial value populated in the form from the store
const mapStateToProps = (state, ownProps) => ({
    initialValues: dtoForm(getEventCategoryData(state, ownProps.id)),
    singleFetching: getSingleEventCategoryFetching(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchEventCategoryData: () => {
        // Getting included data from API by setting params

        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: eventCategoriesActions.START_FETCHING_SINGLE_EVENT_CATEGORY,
                id: ownProps.id
            });
        }).catch(error => ({ error }));
    }
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(CategoryEdit)
);
