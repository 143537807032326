import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import Form, { Text, Autocomplete, MultiAutocomplete, Switch } from 'erpcore/components/Form';
import valueValidation from 'erputils/valueValidation';
import { dtoForm } from 'erputils/dto';
import { diff } from 'deep-object-diff';
import Modal from 'erpcomponents/Modal';
import Button from 'erpcomponents/Button';
import { actions as groupsActions } from 'erpcore/screens/Groups/Groups.reducer';
import {
    getSingleGroupData,
    getSingleGroupFetching
} from 'erpcore/screens/Groups/Groups.selectors';
import PageLoader from 'erpcomponents/PageLoader';
import GroupSplitForm from 'erpcore/screens/Groups/components/GroupSplitForm';

const childGroupsDto = initialValues => {
    const data = [];
    if (initialValues && initialValues.included && initialValues.children) {
        initialValues.children.forEach(item => {
            const groupData = initialValues.included.find(record => record.iri === item);
            data.push(groupData);
        });
        return { ...initialValues, children: data };
    }
    if (initialValues && initialValues.tables) {
        const [table] = initialValues.tables;
        initialValues.tables = table;
    }
    return initialValues;
};

class GroupEditModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            opened: true,
            groupSplitFormVisible: false
        };

        this.handleModal = this.handleModal.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    // componentDidUpdate(prevProps, prevState) {
    //     const { fetchGroupData } = this.props;
    //     const { opened } = this.state;
    //     if (prevState.opened !== opened) {
    //         fetchGroupData();
    //     }
    // }

    componentDidMount() {
        const { fetchGroupData } = this.props;
        fetchGroupData();
    }

    onSubmit(formData) {
        const { id, dispatch, initialValues, refreshData } = this.props;
        // send only changed data
        formData = diff(initialValues, formData);
        if (formData.children) {
            delete formData.children;
        }
        if (formData.group_size) {
            formData.group_size = parseInt(formData.group_size, 10);
        }

        if (formData.tables) {
            const tables = [formData.tables];
            formData.tables = tables;
        }

        if (!formData.tables) {
            formData.tables = [];
        }

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: groupsActions.START_UPDATE_SINGLE_GROUP,
                id,
                formData
            })
        )
            .then(() => {
                this.handleModal();
                refreshData();
            })
            .catch(error => {
                return error;
            });
    }

    handleGroupSplitForm = () => {
        const { groupSplitFormVisible } = this.state;
        this.setState({ groupSplitFormVisible: !groupSplitFormVisible });
    };

    handleModal() {
        const { opened: isOpen } = this.state;
        this.setState({ opened: !isOpen });
    }

    render() {
        const {
            handleSubmit,
            submitting,
            pristine,
            invalid,
            singleFetching,
            eventId,
            initialValues
        } = this.props;
        const { opened, groupSplitFormVisible } = this.state;
        const hasParent = initialValues && initialValues.parent;
        // eslint-disable-next-line no-unneeded-ternary
        const hasChildren = initialValues && initialValues.children ? true : false;

        return (
            <Modal title="Edit Group" opened={opened} onClose={() => this.handleModal()}>
                {singleFetching === true && <PageLoader />}
                <Form onSubmit={handleSubmit(this.onSubmit)}>
                    <Form.Column>
                        <Form.Row>
                            <Form.Columns>
                                <Form.Column autoWidth>
                                    <Field
                                        name="group_completed"
                                        id="group_completed"
                                        fieldProps={{
                                            label: (
                                                <FormattedMessage
                                                    id="GroupEdit.Completed"
                                                    defaultMessage="Group Complete"
                                                />
                                            ),
                                            on: {
                                                label: 'complete-on',
                                                value: true,
                                                id: 'complete-on'
                                            },
                                            off: {
                                                label: 'complete-off',
                                                value: false,
                                                id: 'complete-off'
                                            },
                                            type: 'single',
                                            clearable: true
                                        }}
                                        component={Switch}
                                    />
                                </Form.Column>
                                <Form.Column>
                                    <Field
                                        name="willing_to_share"
                                        id="willing_to_share"
                                        fieldProps={{
                                            label: (
                                                <FormattedMessage
                                                    id="GroupEdit.WillShare"
                                                    defaultMessage="Will share?"
                                                />
                                            ),
                                            on: {
                                                label: 'share-on',
                                                value: true,
                                                id: 'share-on'
                                            },
                                            off: {
                                                label: 'share-off',
                                                value: false,
                                                id: 'share-off'
                                            },
                                            type: 'single',
                                            clearable: true
                                        }}
                                        component={Switch}
                                    />
                                </Form.Column>
                            </Form.Columns>
                        </Form.Row>

                        <Form.Row>
                            <Field
                                name="name"
                                id="name"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="GroupCreate.Grpup name"
                                            defaultMessage="Group Name"
                                        />
                                    ),
                                    clearable: true
                                }}
                                component={Text}
                                validate={valueValidation([{ validator: 'required' }])}
                                fieldAttr={{ required: true }}
                            />
                        </Form.Row>
                        <Form.Row>
                            <Field
                                name="group_size"
                                id="group_size"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="GroupCreate.GroupSize"
                                            defaultMessage="Group Size"
                                        />
                                    )
                                }}
                                component={Text}
                                fieldAttr={{ required: true }}
                                validate={valueValidation([
                                    { validator: 'numericality' },
                                    { validator: 'numericality' }
                                ])}
                            />
                        </Form.Row>
                        <Form.Row>
                            <Field
                                name="event"
                                id="event"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="GroupCreate.Event"
                                            defaultMessage="Event"
                                        />
                                    ),
                                    options: {
                                        endpoint: 'api/events?pagination=false',
                                        mapData: {
                                            label: 'name',
                                            value: 'iri'
                                        }
                                    }
                                }}
                                component={Autocomplete}
                            />
                        </Form.Row>
                        <Form.Row>
                            <Field
                                name="orders"
                                id="orders"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="GroupCreate.OrderIDs"
                                            defaultMessage="Order IDs"
                                        />
                                    ),
                                    options: {
                                        endpoint: `api/orders?include=event&filters[event.id][equals]=${eventId &&
                                            eventId}&pagination=false`,
                                        mapData: {
                                            label: 'order_number',
                                            value: 'iri'
                                        }
                                    }
                                }}
                                component={MultiAutocomplete}
                            />
                        </Form.Row>
                        <Form.Row>
                            <Field
                                name="tables"
                                id="tables"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="GroupEdit.Table"
                                            defaultMessage="Table"
                                        />
                                    ),
                                    options: {
                                        endpoint: `api/tables?include=event&filters[event.id][equals]=${eventId &&
                                            eventId}&pagination=false`,
                                        mapData: {
                                            label: 'table_number',
                                            value: 'iri'
                                        }
                                    }
                                }}
                                component={Autocomplete}
                            />
                        </Form.Row>

                        <Form.Row>
                            <Form.Columns>
                                <Form.Column autoWidth>
                                    <Button
                                        submit
                                        disabled={pristine || invalid}
                                        loading={submitting}
                                        label={
                                            <FormattedMessage
                                                id="GroupEdit.Update"
                                                defaultMessage="Update"
                                            />
                                        }
                                    />
                                </Form.Column>
                                {!hasParent && (
                                    <Form.Column>
                                        <Button
                                            variation="secondary"
                                            label="Split Group"
                                            onClick={() => this.handleGroupSplitForm()}
                                        />
                                    </Form.Column>
                                )}
                            </Form.Columns>
                            <Form.Row>
                                {(hasChildren || groupSplitFormVisible) && (
                                    <GroupSplitForm initialValues={initialValues} />
                                )}
                            </Form.Row>
                        </Form.Row>
                    </Form.Column>
                </Form>
            </Modal>
        );
    }
}

GroupEditModal.defaultProps = {
    submitting: false,
    pristine: false,
    invalid: false,
    fetchGroupData: () => {},
    refreshData: () => {},
    singleFetching: false,
    initialValues: {},
    id: '',
    eventId: ''
};

GroupEditModal.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    initialValues: PropTypes.oneOfType([PropTypes.object]),
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    fetchGroupData: PropTypes.func,
    refreshData: PropTypes.func,
    singleFetching: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    eventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

GroupEditModal = reduxForm({
    enableReinitialize: false
})(GroupEditModal);

const mapStateToProps = (state, ownProps) => ({
    initialValues: childGroupsDto(dtoForm(getSingleGroupData(state, ownProps.id))),
    singleFetching: getSingleGroupFetching(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchGroupData: () => {
        // Getting included data from API by setting params
        const params = {
            include: 'tables,orders,event,children'
        };
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: groupsActions.START_FETCHING_SINGLE_GROUP,
                id: ownProps.id,
                params
            });
        }).catch(error => ({ error }));
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GroupEditModal);
