import React from 'react';
import PropTypes from 'prop-types';
import './HorizontalLineChart.scss';
import Tooltip from 'erpcomponents/Tooltip';
import { formatCurrency, hexToRGB } from 'erputils/utils';

class HorizontalLineChart extends React.Component {
    constructor(props) {
        super(props);

        this.renderHorizontalLine = this.renderHorizontalLine.bind(this);
    }

    // Render single horizonal chart //
    // Horizontal chart takes item with biggest data number assigned to it, and is used as a refference for other chart length's using formula currentChart / maxNumber * 100% //
    // Top level item ( highest data number ) is width 100% others using formula calculate their width eg: 85%, 30% etc //
    // Tooltip content displays {label}: {data number} //
    renderHorizontalLine(chartsData) {
        const { title, gradientFill } = this.props;
        let maxDataSetValue = 0;
        chartsData.datasets.map(item => {
            if (item.data[0] > maxDataSetValue) {
                maxDataSetValue = Math.round(item.data[0]);
            }
            return null;
        });

        return (
            <div className="horizontal-line-chart">
                {title && <h3 className="horizontal-line-chart__title">{title}</h3>}
                {chartsData.datasets.map(el => {
                    return (
                        <div
                            className="horizontal-line-chart__singleBar"
                            style={{ width: `${(el.data[0] / maxDataSetValue) * 100}%` }}
                        >
                            <Tooltip content={`${el.label}: ${formatCurrency(el.data[0])}`}>
                                <span
                                    style={{
                                        background: gradientFill
                                            ? `linear-gradient(to bottom, ${hexToRGB(
                                                  el.backgroundColor,
                                                  1
                                              )}, ${hexToRGB(el.backgroundColor, 0.55)})`
                                            : el.backgroundColor
                                    }}
                                />
                            </Tooltip>
                        </div>
                    );
                })}
            </div>
        );
    }

    render() {
        const { data: chartData } = this.props;
        return this.renderHorizontalLine(chartData);
    }
}

HorizontalLineChart.defaultProps = {
    data: {},
    title: null,
    gradientFill: false
};

HorizontalLineChart.propTypes = {
    data: PropTypes.oneOfType([PropTypes.object]),
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    gradientFill: PropTypes.bool
};

export default HorizontalLineChart;
