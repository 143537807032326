import React from 'react';
import PropTypes from 'prop-types';
import TooltipPlugin from 'react-tooltip-lite';
import './Tooltip.scss';

const Tooltip = ({ content, children, tipContentHover, direction, mode }) => (
    <TooltipPlugin
        className="tooltip"
        tagName="span"
        padding="8px"
        useDefaultStyles={false}
        content={content}
        direction={direction}
        tipContentHover={tipContentHover}
        tipContentClassName={`tooltip-content ${mode}`}
    >
        {children}
    </TooltipPlugin>
);

Tooltip.defaultProps = {
    content: '',
    children: null,
    tipContentHover: false,
    direction: 'down',
    mode: 'dark'
};

Tooltip.propTypes = {
    content: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.string
    ]),
    tipContentHover: PropTypes.bool,
    direction: PropTypes.string,
    mode: PropTypes.oneOf(['dark', 'light'])
};

export default Tooltip;
