import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'erpcomponents/Modal';
import restClient from 'erpcore/api/restClient';
import { FormattedMessage } from 'react-intl';
import Button from 'erpcomponents/Button';
import { Receiver } from 'react-file-uploader';
import Svg from 'erpcomponents/Svg';
import './ProfilePhoto.scss';
import { getMeData } from 'erputils/AuthManager/AuthManager.selectors';

class ProfilePhoto extends Component {
    constructor(props) {
        super(props);

        this.state = {
            opened: false,
            initialized: false,
            image: null
        };

        this.dropArea = React.createRef();
        this.handleModal = this.handleModal.bind(this);
        this.onRemoveImage = this.onRemoveImage.bind(this);
        this.onImageDrop = this.onImageDrop.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        if (!state.initialized) {
            return {
                uploads: props.meta.initial || [],
                initialized: true
            };
        }
        return {};
    }

    onRemoveImage() {
        const { input } = this.props;
        return this.setState({ image: null }, () => {
            input.onChange(null);
        });
    }

    onImageDrop = (event, files, inputField) => {
        const { input } = this.props;
        const formData = new FormData();
        let preparedFiles = [];

        //  If file is coming from drop or browser menu
        if (inputField) {
            preparedFiles.push({ data: files[0] });
        } else {
            preparedFiles = files;
        }

        //  Go trough all files
        preparedFiles.map(upload => {
            if (upload.data.size > 1000 * 1000) {
                return Object.assign({}, upload, {
                    error: 'file size exceeded 1MB'
                });
            }

            //  Preparing form data
            const fileReader = new FileReader();
            formData.append('file', upload.data);

            //  Send to API (you cannot console out FormData hehe )
            restClient
                .post('api/media-objects', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                })
                .then(response => {
                    this.setState({ image: response.data.data.attributes.content_url }, () => {
                        this.handleModal();
                    });
                    return input.onChange(response.data.data.id);
                })
                .catch(error => {
                    console.error(error);
                });

            return fileReader.readAsDataURL(upload.data);
        });
    };

    handleImagePreview() {
        const { image } = this.state;
        const { input, user } = this.props;
        let imageURL = null;

        if (input.value) {
            imageURL = user && user.image && user.image.content_url;
        }

        if (image) {
            imageURL = image;
        }

        if (imageURL) {
            return <img src={imageURL} alt="Profile" width="100" height="100" />;
        }

        return <Svg icon="profile" />;
    }

    handleModal() {
        const { opened: isOpen } = this.state;
        this.setState({ opened: !isOpen });
    }

    render() {
        const { opened } = this.state;

        return (
            <div className="profile-photo">
                <div className="profile-photo__actions">
                    <button
                        type="button"
                        className="profile-photo__preview"
                        onClick={this.handleModal}
                    >
                        {this.handleImagePreview()}
                    </button>
                    <Button
                        variation="tertiary"
                        size="small"
                        label={
                            <FormattedMessage
                                id="Forms.ProfilePhoto.Remove"
                                defaultMessage="Remove"
                            />
                        }
                        onClick={this.onRemoveImage}
                    />
                    <Button
                        variation="tertiary"
                        size="small"
                        onClick={this.handleModal}
                        label={
                            <FormattedMessage
                                id="Forms.ProfilePhoto.Update"
                                defaultMessage="Update"
                            />
                        }
                    />
                </div>
                <Modal
                    title={
                        <FormattedMessage
                            id="Forms.ProfilePhoto.Modal.title"
                            defaultMessage="Profile Photo Upload"
                        />
                    }
                    opened={opened}
                    onClose={() => this.handleModal()}
                >
                    <Receiver
                        isOpen
                        onDragOver={() =>
                            this.dropArea.current.classList.add('profile-photo__drop-area--active')
                        }
                        onDragEnter={() => {
                            this.dropArea.current.classList.add('profile-photo__drop-area--active');
                        }}
                        onDragLeave={() =>
                            this.dropArea.current.classList.remove(
                                'profile-photo__drop-area--active'
                            )
                        }
                        onFileDrop={this.onImageDrop}
                    >
                        <div className="profile-photo__drop-area" ref={this.dropArea}>
                            <label className="profile-photo__drop-area-button">
                                <input
                                    type="file"
                                    onChange={event =>
                                        this.onImageDrop(event, event.target.files, true)
                                    }
                                />
                            </label>
                            <FormattedMessage
                                id="Forms.ProfilePhoto.Modal.Upload.text"
                                defaultMessage="Browse or Drag & Drop Image"
                            />
                        </div>
                    </Receiver>
                </Modal>
            </div>
        );
    }
}

ProfilePhoto.defaultProps = {
    input: {},
    user: null
};

ProfilePhoto.propTypes = {
    input: PropTypes.oneOfType([PropTypes.object]),
    user: PropTypes.oneOfType([PropTypes.object])
};

const mapStateToProps = state => ({
    user: getMeData(state)
});

export default connect(mapStateToProps)(ProfilePhoto);
