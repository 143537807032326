import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import reduxFormErrorMapper from 'erputils/reduxFormErrorMapper';
import { Field, FieldArray, reduxForm, SubmissionError, reset } from 'redux-form';
import Form, { Text, Repeater } from 'erpcore/components/Form';
import valueValidation from 'erputils/valueValidation';
import Button from 'erpcomponents/Button';
import { actions as tableTemplatesActions } from 'erpcore/screens/Venues/screens/VenuesEdit/TableTemplates/TableTemplates.reducer';
import TableTemplateRepeaterItem from 'erpcore/screens/Events/screens/EventManage/components/TableTemplateRepeaterItem';
import { getNewTables } from 'erpcore/screens/Venues/screens/VenuesEdit/TableTemplates/TableTemplates.selectors';
import { getEventData } from 'erpcore/screens/Events/Events.selectors';
import { actions as eventsActions } from 'erpcore/screens/Events/Events.reducer';
import { getVenueRooms } from 'erpcore/screens/Venues/screens/VenuesEdit/Rooms/Rooms.selectors';

class TableTemplateCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handleSaveNewItem = ({ itemData = {} }) => {
        const { dispatch, eventData } = this.props;

        itemData.max_seats = parseInt(itemData.max_seats, 10);
        itemData.min_seats = parseInt(itemData.min_seats, 10);
        itemData.event = eventData && eventData.iri;
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: tableTemplatesActions.START_CREATE_TABLE,
                formData: itemData
            })
        )
            .then(() => {
                this.handleAfterAnyTableTemplateUpdate();
            })
            .catch(() => {
                // throw new SubmissionError(reduxFormErrorMapper(error));
            });
        // .then(() => {
        //     this.handleAfterAnyTicketApiUpdate();
        // });
    };

    onSubmit = formData => {
        const { dispatch, newTables } = this.props;
        formData.tables = newTables;
        // formData.locations = [`/api/locations/${match.params.id}`];
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: tableTemplatesActions.START_CREATE_TABLE_TEMPLATE,
                formData
            });
        })
            .then(() => {
                dispatch(reset('TableTemplateCreateForm'));
            })
            .catch(error => {
                throw new SubmissionError(reduxFormErrorMapper(error));
            });
    };

    handleRemoveItem = ({ itemData = {} }) => {
        const { dispatch } = this.props;
        const { id } = itemData;
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: tableTemplatesActions.START_DELETE_TABLE,
                id
            })
        )
            .then(() => {
                this.handleAfterAnyTableTemplateUpdate();
            })
            .catch(() => {
                // throw new SubmissionError(reduxFormErrorMapper(error));
            });
        // .then(() => {
        //     this.handleAfterAnyTableTemplateUpdate();
        // });
    };

    handleAfterAnyTableTemplateUpdate() {
        const { fetchTables, match } = this.props;
        fetchTables(match.params.id).then(() => {
            reset();
        });
    }

    render() {
        const {
            handleSubmit,
            submitting,
            pristine,
            invalid,
            match,
            eventData,
            venueRooms
        } = this.props;
        const venueId = eventData && eventData.location && eventData.location.id;

        return (
            <Form onSubmit={handleSubmit(this.onSubmit)}>
                <Form.Row>
                    <Form.Columns>
                        <Form.Column>
                            <Field
                                name="name"
                                id="name"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="TableTemplateForm.TemplateName"
                                            defaultMessage="Template Name"
                                        />
                                    ),
                                    clearable: true
                                }}
                                component={Text}
                                validate={valueValidation([{ validator: 'required' }])}
                            />
                        </Form.Column>
                        <Form.Column>
                            <Button
                                submit
                                disabled={pristine || invalid}
                                loading={submitting}
                                label={
                                    <FormattedMessage
                                        id="TableTemplateForm.Create"
                                        defaultMessage="Add New Table Template"
                                    />
                                }
                            />
                        </Form.Column>
                    </Form.Columns>
                </Form.Row>
                <Form.Row>
                    <Form.SectionTitle>Tables</Form.SectionTitle>
                </Form.Row>
                <Form.Row>
                    <FieldArray
                        name="tables"
                        component={Repeater}
                        RepeatableItem={TableTemplateRepeaterItem}
                        // loading={
                        //     singleEventFetching ||
                        //     singleTicketTypesCreating ||
                        //     singleTicketTypesDeleting
                        // }
                        data={{
                            match,
                            venueId,
                            venueRooms
                        }}
                        deleteModalTitle={
                            <FormattedMessage
                                id="EventTickets.Repeater.deleteModal.title"
                                defaultMessage="Delete Ticket Type"
                            />
                        }
                        deleteModalSubTitle={
                            <FormattedMessage
                                id="EventTickets.Repeater.deleteModal.subTitle"
                                defaultMessage="Are you sure you wanna delete this Ticket Type?"
                            />
                        }
                        onSortEnd={this.handleSort}
                        onSaveNewItem={this.handleSaveNewItem}
                        onRemoveItem={this.handleRemoveItem}
                        addNewLabel="Add New Table"
                    />
                </Form.Row>
            </Form>
        );
    }
}

TableTemplateCreate.defaultProps = {
    submitting: false,
    pristine: false,
    invalid: false,
    // onSubmit: () => {},,
    eventData: {},
    match: {},
    // fetchEventData: () => {},
    fetchTables: () => {},
    venueRooms: []
};

TableTemplateCreate.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    // onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    newTables: PropTypes.oneOfType([PropTypes.array]).isRequired,
    venueRooms: PropTypes.oneOfType([PropTypes.array]),
    eventData: PropTypes.oneOfType([PropTypes.object]),
    match: PropTypes.oneOfType([PropTypes.object]),
    // fetchEventData: PropTypes.func,
    fetchTables: PropTypes.func
};

TableTemplateCreate = reduxForm({
    form: 'TableTemplateCreateForm',
    enableReinitialize: true,
    keepDirtyOnReinitialize: false, // important
    updateUnregisteredFields: false // important
})(TableTemplateCreate);

const mapStateToProps = (state, ownProps) => ({
    newTables: getNewTables(state, 'tableTemplates'),
    eventData: getEventData(state, ownProps.match.params.id),
    venueRooms: getVenueRooms(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchEventData: () => {
        // Getting included data from API by setting params
        const params = {
            include: 'organization,location,location.country,location.state,location.city'
        };
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: eventsActions.START_FETCHING_SINGLE_EVENT,
                id: ownProps.match.params.id,
                params
            });
        }).catch(error => ({ error }));
    },
    fetchTables: eventId => {
        const params = {
            include: 'canBeGroupedWith,room'
        };

        return new Promise((resolve, reject) => {
            dispatch({
                type: tableTemplatesActions.START_FETCH_EVENT_TABLES,
                id: eventId,
                promise: { resolve, reject },
                params
            });
        });
    }
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(TableTemplateCreate)
);
