import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import { GaugeChart } from 'erpcomponents/WidgetCharts';
import ElementLoader from 'erpcomponents/ElementLoader';
import Widget from 'erpcomponents/Widget';

import './TicketTypes.scss';

import { actions as ticketTypesActions } from 'erpcomponents/Widgets/TicketTypes/TicketTypes.reducer';
import { getData, getFetching } from 'erpcomponents/Widgets/TicketTypes/TicketTypes.selectors';
import { chartColors } from 'erpdata/colors';

class TicketTypes extends Component {
    componentDidMount() {
        const { eventSeries, eventID, soldBy } = this.props;
        if (eventSeries || eventID) {
            return this.fetchData({
                eventID,
                eventSeries,
                soldBy
            });
        }
        return null;
    }

    componentWillReceiveProps(nextProps) {
        const {
            eventID: nextEventID,
            eventSeries: nextEventSeries,
            soldBy: nextSoldBy
        } = nextProps;
        const { eventSeries, eventID, soldBy } = this.props;

        if (nextEventID !== eventID || nextEventSeries !== eventSeries || nextSoldBy !== soldBy) {
            return this.fetchData({
                eventID: nextEventID,
                eventSeries: nextEventSeries,
                soldBy: nextSoldBy
            });
        }

        return true;
    }

    fetchData({ eventID, eventSeries, soldBy }) {
        const { dispatch, id } = this.props;

        const params = {
            display_by: 'event'
        };

        if (eventID) {
            params['filters[events]'] = eventID;
        }

        if (eventSeries) {
            params['filters[event_series]'] = eventSeries;
        }

        if (soldBy) {
            params['filters[sold_by]'] = soldBy;
        }

        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: ticketTypesActions.START_FETCHING_TICKET_TYPES_DATA,
                widgetId: id,
                params
            });
        }).catch(error => ({ error }));
    }

    formatWidgetData() {
        const { data: initialData } = this.props;

        const percentageSeated =
            initialData &&
            initialData.totals &&
            initialData.totals.max_attendance &&
            initialData.totals.seated
                ? (initialData.totals.seated / initialData.totals.max_attendance) * 100
                : 0;

        const percentageStanding =
            initialData &&
            initialData.totals &&
            initialData.totals.max_attendance &&
            initialData.totals.standing
                ? (initialData.totals.standing / initialData.totals.max_attendance) * 100
                : 0;

        const widgetData = {
            gaugeChart: {
                percentageStanding,
                percentageSeated,
                data: {},
                options: {
                    tooltips: {
                        mode: 'label',
                        callbacks: {
                            label(tooltipItem, labelData) {
                                if (labelData.datasets && labelData.labels) {
                                    const label = labelData.labels[tooltipItem.index];
                                    const value =
                                        labelData.datasets[tooltipItem.datasetIndex].data[
                                            tooltipItem.index
                                        ];
                                    return [`${label}: ${value.toLocaleString('en-US')}`];
                                }
                                return [];
                            }
                        }
                    }
                }
            },
            totals: {
                standing: (initialData && initialData.totals && initialData.totals.standing) || 0,
                standingRevenue:
                    (initialData && initialData.totals && initialData.totals.standing_revenue) || 0,
                seated: (initialData && initialData.totals && initialData.totals.seated) || 0,
                seatedRevenue:
                    (initialData && initialData.totals && initialData.totals.seated_revenue) || 0,
                currency:
                    (initialData && initialData.totals && initialData.totals.revenue_currency) ||
                    'USD'
            }
        };

        /*
        BALLDROP:
        available_tickets_to_sell: 12850
        max_attendance: 14233
        max_tickets_to_sell: 14233
        revenue: 127453202
        tickets_sold: 1383
         */

        /*
        GLOW:
        attendance: 314700
        expected_attendance: 1170000
        percentage_based_on_expected_attendance: 26.9
        revenue: 605329663
        revenue_currency: "CAD"
        tickets_sold: 267335
         */

        widgetData.gaugeChart.data = {
            labels: ['Seated Tickets', 'Standing Tickets'],
            datasets: [
                {
                    backgroundColor: [chartColors[0], chartColors[1]],
                    data: [
                        (initialData && initialData.totals && initialData.totals.seated) || 0,
                        (initialData && initialData.totals && initialData.totals.standing) || 0
                    ]
                }
            ]
        };

        return widgetData;
    }

    renderAttendance() {
        const widgetData = this.formatWidgetData();

        return (
            <div className="ticket-types__attendance">
                <div className="ticket-types__attendance-chart">
                    <GaugeChart
                        data={widgetData.gaugeChart.data}
                        options={widgetData.gaugeChart.options}
                    />
                    <div className="ticket-types__attendance-chart-content">
                        <div className="ticket-types__attendance-chart-data">
                            <span className="ticket-types__attendance-chart-total">
                                <span>{widgetData.gaugeChart.percentageStanding.toFixed(1)}%</span>
                                <span style={{ color: chartColors[0] }}>
                                    {widgetData.gaugeChart.percentageSeated.toFixed(1)}%
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="ticket-types__attendance-data">
                    <p className="ticket-types__attendance-data-item">
                        <FormattedMessage
                            id="TicketTypes.SeatedTickets"
                            defaultMessage="Total Seated Tickets"
                        />
                        <br />
                        <strong>
                            {widgetData.totals.seated
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        </strong>
                    </p>
                    <p className="ticket-types__attendance-data-item">
                        <FormattedMessage
                            id="TicketTypes.StandingTickets"
                            defaultMessage="Total Standing Tickets"
                        />
                        <br />
                        <strong>
                            {widgetData.totals.standing
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        </strong>
                    </p>
                </div>
            </div>
        );
    }

    renderTotals() {
        const widgetData = this.formatWidgetData();

        return (
            <div className="ticket-types__attendance-data">
                <p className="ticket-types__attendance-data-item">
                    <FormattedMessage
                        id="TicketTypes.SeatedRevenueTickets"
                        defaultMessage="Seated Revenue"
                    />
                    <br />
                    <strong>
                        <span>{widgetData.totals.currency} </span>
                        <FormattedNumber
                            value={Math.round(widgetData.totals.seatedRevenue / 100)}
                        />
                    </strong>
                </p>
                <p className="ticket-types__attendance-data-item">
                    <FormattedMessage
                        id="TicketTypes.StandingRevenue"
                        defaultMessage="Standing Revenue"
                    />
                    <br />
                    <strong>
                        <span>{widgetData.totals.currency} </span>
                        <FormattedNumber
                            value={Math.round(widgetData.totals.standingRevenue / 100)}
                        />
                    </strong>
                </p>
            </div>
        );
    }

    renderContent() {
        return (
            <div className="ticket-types__content">
                {this.renderAttendance()}
                {this.renderTotals()}
            </div>
        );
    }

    render() {
        const { size, fetching } = this.props;

        return (
            <Widget
                title={<FormattedMessage id="TicketTypes.Title" defaultMessage="Ticket Types" />}
                size={size}
                className="ticket-types"
            >
                {fetching && <ElementLoader overlay />}
                {this.renderContent()}
            </Widget>
        );
    }
}

TicketTypes.defaultProps = {
    size: null,
    fetching: false,
    data: {},
    eventSeries: null,
    eventID: null,
    soldBy: null
};

TicketTypes.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    dispatch: PropTypes.func.isRequired,
    fetching: PropTypes.bool,
    data: PropTypes.oneOfType([PropTypes.object]),
    eventSeries: PropTypes.string,
    eventID: PropTypes.string,
    soldBy: PropTypes.string
};

//  Getting initial value populated in the form from the store
const mapStateToProps = (state, ownProps) => {
    return {
        data: getData(state, ownProps.id),
        fetching: getFetching(state, ownProps.id)
    };
};

export default connect(mapStateToProps)(TicketTypes);
