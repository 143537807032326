import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reduxForm, FieldArray } from 'redux-form';
import { FormattedMessage } from 'react-intl';

import Form, { Repeater } from 'erpcore/components/Form';
import Button from 'erpcore/components/Button';
import TableTemplateRepeaterItem from 'erpcore/screens/Events/screens/EventManage/components/TableTemplateRepeaterItem';

import { actions as tableTemplatesActions } from 'erpcore/screens/Venues/screens/VenuesEdit/TableTemplates/TableTemplates.reducer';
import { actions as eventsActions } from 'erpcore/screens/Events/Events.reducer';

import {
    getSingleTableTemplateFetching,
    getNewTables,
    getEventTables
} from 'erpcore/screens/Venues/screens/VenuesEdit/TableTemplates/TableTemplates.selectors';
import { getEventData } from 'erpcore/screens/Events/Events.selectors';
import { getVenueData } from 'erpcore/screens/Venues/Venues.selectors';
import { getVenueRooms } from 'erpcore/screens/Venues/screens/VenuesEdit/Rooms/Rooms.selectors';

const canBeGroupedWithDto = data => {
    const dataCopy = [
        ...data.map(item => {
            const newItem = { ...item };
            if (newItem.room) {
                newItem.room = newItem.room.iri ? newItem.room.iri : newItem.room;
            }
            if (newItem.can_be_grouped_with) {
                newItem.can_be_grouped_with = [
                    ...newItem.can_be_grouped_with.map(table => {
                        if (table === 'string') {
                            return table;
                        }
                        return table.iri;
                    })
                ];
            }
            return newItem;
        })
    ];
    return { tables: dataCopy };
};
class Tables extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fetchingCSV: false
        };
    }

    handleSaveNewItem = ({ itemData = {} }) => {
        const { dispatch, eventData } = this.props;
        itemData.max_seats = parseInt(itemData.max_seats, 10);
        itemData.min_seats = parseInt(itemData.min_seats, 10);
        itemData.event = eventData && eventData.iri;

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: tableTemplatesActions.START_CREATE_TABLE,
                formData: itemData
            })
        )
            .then(() => {
                this.handleAfterAnyTableTemplateUpdate();
            })
            .catch(() => {});
        //
    };

    handleRemoveItem = ({ itemData = {} }) => {
        const { dispatch } = this.props;
        const { id } = itemData;
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: tableTemplatesActions.START_DELETE_TABLE,
                id
            })
        )
            .then(() => {
                this.handleAfterAnyTableTemplateUpdate();
            })
            .catch(() => {
                // throw new SubmissionError(reduxFormErrorMapper(error));
            });
        // .then(() => {
        //     this.handleAfterAnyTableTemplateUpdate();
        // });
    };

    handleAfterAnyTableTemplateUpdate() {
        const { fetchTables, reset, match } = this.props;
        fetchTables(match.params.id).then(() => {
            reset();
        });
    }

    render() {
        const { initialValues, match, eventData, venueRooms, downloadCSV } = this.props;
        const { fetchingCSV } = this.state;
        const venueId = eventData && eventData.location && eventData.location.id;
        return (
            <Form>
                <Form.SectionTitle>Tables</Form.SectionTitle>

                <Form.Row>
                    <FieldArray
                        name="tables"
                        component={Repeater}
                        RepeatableItem={TableTemplateRepeaterItem}
                        uniqueIdentifier="id"
                        deleteModalTitle={
                            <FormattedMessage
                                id="TableTemplates.Repeater.deleteModal.title"
                                defaultMessage="Delete Table"
                            />
                        }
                        data={{ initialValues, match, venueId, venueRooms }}
                        deleteModalSubTitle={
                            <FormattedMessage
                                id="TableTemplates.Repeater.deleteModal.subTitle"
                                defaultMessage="Are you sure you wanna delete this Table?"
                            />
                        }
                        // onSortEnd={this.handleSort}
                        onSaveNewItem={this.handleSaveNewItem}
                        onRemoveItem={this.handleRemoveItem}
                        addNewLabel="Add New Table"
                        canSort={false}
                        customButtonsArea={
                            <React.Fragment>
                                <Button
                                    variation="secondary"
                                    iconName="download"
                                    loading={fetchingCSV}
                                    onClick={() => downloadCSV()}
                                    label={
                                        <FormattedMessage
                                            id="TableTemplates.Repeater.DownloadCSV.label"
                                            defaultMessage="Download .CSV"
                                        />
                                    }
                                />
                            </React.Fragment>
                        }
                    />
                </Form.Row>
            </Form>
        );
    }
}

Tables.defaultProps = {
    // fetchTableTemplateData: () => {},
    // singleFetching: false,
    initialValues: {},
    // fetchEventData: () => {},
    fetchTables: () => {},
    eventData: {},
    venueRooms: [],
    downloadCSV: () => {}
};

Tables.propTypes = {
    // handleSubmit: PropTypes.func.isRequired,
    // onSubmit: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.oneOfType([PropTypes.object]).isRequired,
    initialValues: PropTypes.oneOfType([PropTypes.object]),
    // fetchEventData: PropTypes.func,
    fetchTables: PropTypes.func,
    reset: PropTypes.func.isRequired,
    // newTables: PropTypes.oneOfType([PropTypes.array]).isRequired,
    eventData: PropTypes.oneOfType([PropTypes.object]),
    venueRooms: PropTypes.oneOfType([PropTypes.array]),
    downloadCSV: PropTypes.func
};

Tables = reduxForm({
    form: 'Tables',
    enableReinitialize: true,
    keepDirtyOnReinitialize: false, // important
    updateUnregisteredFields: false // important
})(Tables);

const mapStateToProps = (state, ownProps) => ({
    initialValues: canBeGroupedWithDto(getEventTables(state)),
    singleFetching: getSingleTableTemplateFetching(state),
    // data: dtoForm(getTableTemplateData(state, ownProps.match.params.id)),
    newTables: getNewTables(state, 'tableTemplates'),
    venueData: getVenueData(state, ownProps.match.params.id),
    eventData: getEventData(state, ownProps.match.params.id),
    venueRooms: getVenueRooms(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchEventData: () => {
        // Getting included data from API by setting params
        const params = {
            include: 'organization,location,location.country,location.state,location.city'
        };
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: eventsActions.START_FETCHING_SINGLE_EVENT,
                id: ownProps.match.params.id,
                params
            });
        }).catch(error => ({ error }));
    },
    fetchTables: eventId => {
        const params = {
            include: 'canBeGroupedWith,room'
        };

        return new Promise((resolve, reject) => {
            dispatch({
                type: tableTemplatesActions.START_FETCH_EVENT_TABLES,
                id: eventId,
                promise: { resolve, reject },
                params
            });
        });
    }
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Tables)
);
