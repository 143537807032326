import React, { Component } from 'react';
import arrayUnion from 'erputils/arrayUnion';
import PropTypes from 'prop-types';
import Async from 'react-select/lib/Async';
import { getOptionsByValues } from 'erputils/utils';
import restClient from 'erpcore/api/restClient';
import dto from 'erputils/dto';
import styles from '../Select/Select.styles';
import Input from '../Input';
import '../Select/Select.scss';
import { DropdownIndicator, ClearIndicator, selectPropsMapper } from '../Select';

class MultiAutocomplete extends Component {
    /**
     *
     * @param props
     */
    constructor(props) {
        super(props);
        this.state = {
            labelActive: false,
            options: [],
            menuIsOpen: false
        };
    }

    componentDidUpdate(prevProps, state) {
        const { initialized } = state;
        const { input, fieldProps } = this.props;
        const { input: prevInput } = prevProps;
        if (
            !initialized &&
            input &&
            input.value &&
            input.value.includes(fieldProps.options.endpoint) &&
            input.value !== prevInput.value &&
            input.value
        ) {
            input.onChange(input.value);
            this.loadOptions();
            this.setInitialized(true);
        }
    }

    setMenuVisibility(open = true) {
        this.setState({ menuIsOpen: open });
    }

    setInitialized(state) {
        this.setState({ initialized: state });
    }

    /**
     *
     * @param data
     * @returns {*}
     */
    formatApiValue = data => {
        const { fieldProps } = this.props;
        const formattedOptions = [];
        data.map(item => {
            const labelTemplateMatch = fieldProps.options.mapData.label.match(/[^{]+(?=})/g);
            let labelTemplate = fieldProps.options.mapData.label;
            if (labelTemplateMatch) {
                labelTemplateMatch.map(labelItem => {
                    labelTemplate = labelTemplate.replace(`{${labelItem}}`, item[labelItem] || '');
                    return true;
                });
            }
            formattedOptions.push({
                value: item[fieldProps.options.mapData.value],
                label: labelTemplateMatch ? labelTemplate : item[fieldProps.options.mapData.label]
            });
            return true;
        });
        return formattedOptions;
    };

    /**
     *
     * @param inputValue
     */
    loadOptions = inputValue => {
        const { fieldProps, input } = this.props;
        if (fieldProps.options.endpoint) {
            return new Promise(resolve => {
                const dataArrayKey = fieldProps.options.dataArrayKey
                    ? fieldProps.options.dataArrayKey
                    : 'data';

                let { params } = fieldProps.options;
                if (inputValue) {
                    params = { q: inputValue, ...fieldProps.options.params };
                }
                if (input.value) {
                    params = { selected: input.value, ...params };
                }

                // Do a API call
                restClient
                    .get(fieldProps.options.endpoint, {
                        params
                    })
                    .then(response => {
                        const { options } = this.state;
                        response.data = dto(response.data);
                        const formatedOptions = this.formatApiValue(response.data[dataArrayKey]);
                        const archivedOptions = arrayUnion(
                            options,
                            formatedOptions,
                            (a, b) => a.value === b.value
                        );
                        this.setState({ options: archivedOptions });
                        return resolve(formatedOptions);
                    })
                    .catch(() => resolve());
            });
        }

        return null;
    };

    /**
     *
     * @returns {*}
     */
    render() {
        const { input, fieldProps, fieldAttr, meta, field } = this.props;
        const { labelActive, options, menuIsOpen } = this.state;

        fieldProps.forceLabelActive = labelActive;

        //  Getting select required value from options
        const fieldValues = getOptionsByValues(input.value, options);

        //  Standardizing props for select
        const mappedConf = selectPropsMapper(fieldProps, fieldAttr);

        return (
            <Input
                fieldProps={fieldProps}
                fieldAttr={fieldAttr}
                field={field}
                input={input}
                meta={meta}
            >
                <Async
                    isMulti
                    key={JSON.stringify(fieldProps.options)}
                    styles={styles}
                    components={{ DropdownIndicator, ClearIndicator }}
                    onChange={selectedOptions => {
                        if (selectedOptions && selectedOptions.length) {
                            const selectedValues = [];
                            selectedOptions.forEach(item => {
                                selectedValues.push(item.value);
                            });
                            input.onChange(selectedValues);
                        } else {
                            input.onChange(null);
                        }
                        this.setMenuVisibility(true);
                    }}
                    onInputChange={inputValue =>
                        inputValue !== ''
                            ? this.setState({ labelActive: true })
                            : this.setState({ labelActive: false })
                    }
                    onFocus={() => {
                        input.onBlur();
                        this.setState({ labelActive: true });
                    }}
                    placeholder=""
                    className="react-select react-select--multi"
                    classNamePrefix="react-select"
                    isClearable
                    menuIsOpen={menuIsOpen}
                    onMenuOpen={() => this.setMenuVisibility(true)}
                    onMenuClose={() => this.setMenuVisibility(false)}
                    value={fieldValues}
                    name={input.name}
                    id={input.name}
                    {...mappedConf.fieldAttr}
                    {...mappedConf.fieldProps}
                    defaultOptions
                    cacheOptions
                    loadOptions={this.loadOptions}
                />
            </Input>
        );
    }
}
MultiAutocomplete.defaultProps = {
    fieldProps: {},
    fieldAttr: {},
    field: {},
    input: {},
    meta: {}
};
MultiAutocomplete.propTypes = {
    fieldProps: PropTypes.shape({
        endpoint: PropTypes.string,
        formatApiValue: PropTypes.func,
        params: PropTypes.object
    }),
    fieldAttr: PropTypes.oneOfType([PropTypes.object]),
    field: PropTypes.oneOfType([PropTypes.object]),
    input: PropTypes.oneOfType([PropTypes.object]),
    meta: PropTypes.oneOfType([PropTypes.object])
};
export default MultiAutocomplete;
