import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import Accordion from 'erpcore/components/Accordion';
import Listing from 'erpcore/components/Listing';

import { getYearOverYearFetching } from 'erpcore/screens/Dashboard/screens/YearOverYear/YearOverYear.selectors';

const style = {
    currency: 'currency',
    percent: 'percent'
};

const EventTicketTypeAccordion = ({ event }) => {
    const fetching = useSelector(state => getYearOverYearFetching(state));

    const tableData = () => {
        const table = {};
        const ticketTypesArr = [];
        table.data = [];

        if (event?.eventTicketTypeData?.data) {
            const ticketTypes = Object.keys(event.eventTicketTypeData?.data);
            if (ticketTypes.length) {
                ticketTypes.map(type => {
                    ticketTypesArr.push({
                        label: type,
                        ...event.eventTicketTypeData.data[type]
                    });
                    return ticketTypesArr;
                });
            }

            table.schema = [
                {
                    title: '',
                    field: 'ticket_label',
                    sortable: false
                },
                {
                    title: (
                        <FormattedMessage
                            id="Dashboard.YearOverYear.Table.TicketType.CurrentPrice"
                            defaultMessage="Current Price"
                        />
                    ),
                    field: 'current_price',
                    sortable: false,
                    align: 'right'
                },
                {
                    title: (
                        <FormattedMessage
                            id="Dashboard.YearOverYear.Table.TicketType.Available"
                            defaultMessage="Available"
                        />
                    ),
                    field: 'available_tickets_to_sell',
                    sortable: false,
                    align: 'right'
                },
                {
                    title: (
                        <FormattedMessage
                            id="Dashboard.YearOverYear.Table.TicketType.TicketsSold"
                            defaultMessage="Tickets sold"
                        />
                    ),
                    field: 'tickets_sold',
                    sortable: false,
                    align: 'right'
                },
                {
                    title: (
                        <FormattedMessage
                            id="Dashboard.YearOverYear.Table.TicketType.PercentageSold"
                            defaultMessage="% sold"
                        />
                    ),
                    field: 'percentage_sold',
                    sortable: false,
                    align: 'right'
                },
                {
                    title: (
                        <FormattedMessage
                            id="Dashboard.YearOverYear.Table.TicketType.Revenues"
                            defaultMessage="Revenues"
                        />
                    ),
                    field: 'revenue',
                    sortable: false,
                    align: 'right'
                },
                {
                    title: (
                        <FormattedMessage
                            id="Dashboard.YearOverYear.Table.TicketType.RevenuePerAttendee"
                            defaultMessage="Revenue per Attendee"
                        />
                    ),
                    field: 'revenue_per_attendee',
                    sortable: false,
                    align: 'right'
                },
                {
                    title: (
                        <FormattedMessage
                            id="Dashboard.YearOverYear.Table.TicketType.Delivery"
                            defaultMessage="Delivery"
                        />
                    ),
                    field: 'delivery_cost_amount',
                    sortable: false,
                    align: 'right'
                },
                {
                    title: (
                        <FormattedMessage
                            id="Dashboard.YearOverYear.Table.TicketType.Taxes"
                            defaultMessage="Taxes"
                        />
                    ),
                    field: 'tax_amount',
                    sortable: false,
                    align: 'right'
                },
                {
                    title: (
                        <FormattedMessage
                            id="Dashboard.YearOverYear.Table.TicketType.ServiceFee"
                            defaultMessage="Service Fee"
                        />
                    ),
                    field: 'services_amount',
                    sortable: false,
                    align: 'right'
                }
            ];

            ticketTypesArr.map(item => {
                return table.data.push({
                    id: item.id,
                    ticket_label: item.label,
                    current_price: item.current_price && (
                        <FormattedNumber
                            value={item.current_price / 100}
                            currency={item.revenue_currency}
                            style={style.currency}
                        />
                    ),
                    available_tickets_to_sell: item.available_tickets_to_sell,
                    tickets_sold: item.tickets_sold,
                    percentage_sold:
                        item.tickets_sold && item.max_tickets_to_sell ? (
                            <FormattedNumber
                                value={item.tickets_sold / item.max_tickets_to_sell}
                                style={style.percent}
                                maximumFractionDigits={2}
                            />
                        ) : (
                            <FormattedMessage
                                id="Dashboard.YearOverYear.Table.TicketType.NoSales"
                                defaultMessage="No sales"
                            />
                        ),
                    revenue: item.revenue && (
                        <FormattedNumber
                            value={item.revenue / 100}
                            currency={item.revenue_currency}
                            style={style.currency}
                        />
                    ),
                    revenue_per_attendee: item.revenue && item.attendance && (
                        <FormattedNumber
                            value={item.revenue / 100 / item.attendance}
                            currency={item.revenue_currency}
                            style={style.currency}
                        />
                    ),
                    delivery_cost_amount: (
                        <FormattedNumber
                            value={item.delivery_cost_amount / 100}
                            currency={item.revenue_currency}
                            style={style.currency}
                        />
                    ),
                    tax_amount: item.tax_amount && (
                        <FormattedNumber
                            value={item.tax_amount / 100}
                            currency={item.revenue_currency}
                            style={style.currency}
                        />
                    ),
                    services_amount: item.services_amount && (
                        <FormattedNumber
                            value={item.services_amount / 100}
                            currency={item.revenue_currency}
                            style={style.currency}
                        />
                    )
                });
            });
        }
        return table;
    };

    return (
        <div className="year-over-year__accordion">
            <Accordion
                title={event?.name || ''}
                body={
                    <Listing
                        table={tableData()}
                        loading={fetching}
                        name={`${event?.name}_ticket_type_breakdown`}
                        reducerName="YEAR_OVER_YEAR"
                        hideSearch
                        hideFooter
                        hideFilters
                        hideHeader
                    />
                }
            />
        </div>
    );
};

EventTicketTypeAccordion.defaultProps = {
    event: {}
};

EventTicketTypeAccordion.propTypes = {
    event: PropTypes.oneOfType([PropTypes.object])
};

export default EventTicketTypeAccordion;
