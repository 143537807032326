// TODO: location of this file is not yet defined

import { takeLatest, put, call, all } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import { actions as notificationManagerActions } from 'erputils/NotificationManager/NotificationManager.reducer';
import dto from 'erputils/dto';
import { actions as printCardActions } from 'erpcore/screens/Settings/screens/PrintSettings/PrintCardSettings/PrintCardSettings.reducer';

/**
 * Fetch print settings
 * @param promise {Object}
 * @param id {number} ticketType id
 * @param params {Object}
 * @return {void}
 */
export function* fetchPrintSettings({ promise }) {
    try {
        const responses = yield all([
            restClient.get(`/api/general-settings/print-cards-4x6-width`),
            restClient.get(`/api/general-settings/print-cards-title`)
        ]);

        const fetchPrintSettingsWidthAPI = responses[0];
        const fetchPrintSettingsTitleAPI = responses[1];
        yield all([
            put({
                type: printCardActions.STORE_PRINT_SETTINGS_WIDTH_DATA,
                response: dto(fetchPrintSettingsWidthAPI.data)
            }),
            put({
                type: printCardActions.STORE_PRINT_SETTINGS_TITLE_DATA,
                response: dto(fetchPrintSettingsTitleAPI.data)
            })
        ]);
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: printCardActions.FETCHING_FAILED_PRINT_SETTINGS
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Fetch print settings
 * @param promise {Object}
 * @param id {number} ticketType id
 * @param params {Object}
 * @return {void}
 */
export function* fetchPrintSettings2x35Width({ promise }) {
    try {
        const fetchPrintSettingsWidthAPI = yield restClient.get(
            `/api/general-settings/print-cards-2x35-left`
        );

        yield put({
            type: printCardActions.STORE_PRINT_SETTINGS_2x35_WIDTH_DATA,
            response: dto(fetchPrintSettingsWidthAPI.data)
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: printCardActions.FETCHING_FAILED_PRINT_SETTINGS_2X35_WIDTH
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Fetch print settings
 * @param promise {Object}
 * @param id {number} ticketType id
 * @param params {Object}
 * @return {void}
 */
export function* fetchPrintSettings2x35ContainerWidth({ promise }) {
    try {
        const fetchPrintSettingsWidthAPI = yield restClient.get(
            `/api/general-settings/print-cards-2x35-width`
        );

        yield put({
            type: printCardActions.STORE_PRINT_SETTINGS_2x35_CONTAINER_WIDTH_DATA,
            response: dto(fetchPrintSettingsWidthAPI.data)
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: printCardActions.FETCHING_FAILED_PRINT_SETTINGS_2X35_CONTAINER_WIDTH
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Fetch print settings
 * @param promise {Object}
 * @param id {number} ticketType id
 * @param params {Object}
 * @return {void}
 */
export function* fetchPrintSettings2x35Offset({ promise }) {
    try {
        const fetchPrintSettingsWidthAPI = yield restClient.get(
            `/api/general-settings/print-cards-2x35-offset`
        );

        yield put({
            type: printCardActions.STORE_PRINT_SETTINGS_OFFSET_2x35_DATA,
            response: dto(fetchPrintSettingsWidthAPI.data)
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: printCardActions.FETCHING_FAILED_PRINT_SETTINGS_2X35_OFFSET
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Fetch print settings
 * @param promise {Object}
 * @param id {number} ticketType id
 * @param params {Object}
 * @return {void}
 */
export function* fetchPrintSettings2x35HorizontalOffset({ promise }) {
    try {
        const fetchPrintSettingsWidthAPI = yield restClient.get(
            `/api/general-settings/print-cards-2x35-horizontal-offset`
        );

        yield put({
            type: printCardActions.STORE_PRINT_SETTINGS_HORIZONTAL_OFFSET_2x35_DATA,
            response: dto(fetchPrintSettingsWidthAPI.data)
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: printCardActions.FETCHING_FAILED_PRINT_SETTINGS_2X35_HORIZONTAL_OFFSET
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Fetch print settings
 * @param promise {Object}
 * @param id {number} ticketType id
 * @param params {Object}
 * @return {void}
 */
export function* fetchPrintSettings2x35Height({ promise }) {
    try {
        const fetchPrintSettingsWidthAPI = yield restClient.get(
            `/api/general-settings/print-cards-2x35-top`
        );

        yield put({
            type: printCardActions.STORE_PRINT_SETTINGS_2x35_HEIGHT_DATA,
            response: dto(fetchPrintSettingsWidthAPI.data)
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: printCardActions.FETCHING_FAILED_PRINT_SETTINGS_HEIGHT_2X35
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Update print settings
 * @param  promise {Object}
 * @param  formData {Object}
 * @param  id {number} ticketType id
 * @param  debounceDelay {number} Number of milliseconds to delay execution
 * @return {void}
 */
export function* updatePrintSettings({ promise, formData, property }) {
    try {
        if (property === 'width') {
            const updatePrintSettingsWidthAPI = yield restClient.put(
                `/api/general-settings/print-cards-4x6-width`,
                formData
            );
            yield put({
                type: printCardActions.UPDATE_PRINT_SETTINGS_WIDTH_SUCCESSFUL
            });
            yield put({
                type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                response: updatePrintSettingsWidthAPI.data
            });
            yield put({
                type: printCardActions.STORE_PRINT_SETTINGS_WIDTH_DATA,
                response: dto(updatePrintSettingsWidthAPI.data)
            });
        }
        if (property === 'title') {
            const updatePrintSettingsTitleAPI = yield restClient.put(
                `/api/general-settings/print-cards-title`,
                formData
            );
            yield put({
                type: printCardActions.UPDATE_PRINT_SETTINGS_TITLE_SUCCESSFUL
            });
            yield put({
                type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                response: updatePrintSettingsTitleAPI.data
            });
            yield put({
                type: printCardActions.STORE_PRINT_SETTINGS_TITLE_DATA,
                response: dto(updatePrintSettingsTitleAPI.data)
            });
        }

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: printCardActions.UPDATE_PRINT_SETTINGS_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Update print settings
 * @param  promise {Object}
 * @param  formData {Object}
 * @param  id {number} ticketType id
 * @param  debounceDelay {number} Number of milliseconds to delay execution
 * @return {void}
 */
export function* updatePrintSettings2x35Width({ promise, formData }) {
    try {
        const updatePrintSettingsWidthAPI = yield restClient.put(
            `/api/general-settings/print-cards-2x35-left`,
            formData
        );
        yield put({
            type: printCardActions.UPDATE_PRINT_2x35_SETTINGS_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updatePrintSettingsWidthAPI.data
        });
        yield put({
            type: printCardActions.STORE_PRINT_SETTINGS_2x35_WIDTH_DATA,
            response: dto(updatePrintSettingsWidthAPI.data)
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: printCardActions.UPDATE_PRINT_2x35_SETTINGS_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Update print settings
 * @param  promise {Object}
 * @param  formData {Object}
 * @param  id {number} ticketType id
 * @param  debounceDelay {number} Number of milliseconds to delay execution
 * @return {void}
 */
export function* updatePrintSettings2x35ContainerWidth({ promise, formData }) {
    try {
        const updatePrintSettingsWidthAPI = yield restClient.put(
            `/api/general-settings/print-cards-2x35-width`,
            formData
        );
        yield put({
            type: printCardActions.UPDATE_PRINT_2x35_CONTAINER_WIDTH_SETTINGS_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updatePrintSettingsWidthAPI.data
        });
        yield put({
            type: printCardActions.STORE_PRINT_SETTINGS_2x35_CONTAINER_WIDTH_DATA,
            response: dto(updatePrintSettingsWidthAPI.data)
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: printCardActions.UPDATE_PRINT_2x35_CONTAINER_WIDTH_SETTINGS_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Update print settings
 * @param  promise {Object}
 * @param  formData {Object}
 * @param  id {number} ticketType id
 * @param  debounceDelay {number} Number of milliseconds to delay execution
 * @return {void}
 */
export function* updatePrintSettings2x35Height({ promise, formData }) {
    try {
        const updatePrintSettingsWidthAPI = yield restClient.put(
            `/api/general-settings/print-cards-2x35-top`,
            formData
        );
        yield put({
            type: printCardActions.UPDATE_PRINT_2x35_HEIGHT_SETTINGS_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updatePrintSettingsWidthAPI.data
        });
        yield put({
            type: printCardActions.STORE_PRINT_SETTINGS_2x35_HEIGHT_DATA,
            response: dto(updatePrintSettingsWidthAPI.data)
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: printCardActions.UPDATE_PRINT_2x35_HEIGHT_SETTINGS_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Update print settings
 * @param  promise {Object}
 * @param  formData {Object}
 * @param  id {number} ticketType id
 * @param  debounceDelay {number} Number of milliseconds to delay execution
 * @return {void}
 */
export function* updatePrintSettings2x35Offset({ promise, formData }) {
    try {
        const updatePrintSettingsWidthAPI = yield restClient.put(
            `/api/general-settings/print-cards-2x35-offset`,
            formData
        );
        yield put({
            type: printCardActions.UPDATE_PRINT_2x35_SETTINGS_OFFSET_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updatePrintSettingsWidthAPI.data
        });
        yield put({
            type: printCardActions.STORE_PRINT_SETTINGS_OFFSET_2x35_DATA,
            response: dto(updatePrintSettingsWidthAPI.data)
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: printCardActions.UPDATE_PRINT_2x35_SETTINGS_OFFSET_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Update print settings
 * @param  promise {Object}
 * @param  formData {Object}
 * @param  id {number} ticketType id
 * @param  debounceDelay {number} Number of milliseconds to delay execution
 * @return {void}
 */
export function* updatePrintSettings2x35HorizontalOffset({ promise, formData }) {
    try {
        const updatePrintSettingsWidthAPI = yield restClient.put(
            `/api/general-settings/print-cards-2x35-horizontal-offset`,
            formData
        );
        yield put({
            type: printCardActions.UPDATE_PRINT_2x35_SETTINGS_HORIZONTAL_OFFSET_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updatePrintSettingsWidthAPI.data
        });
        yield put({
            type: printCardActions.STORE_PRINT_SETTINGS_HORIZONTAL_OFFSET_2x35_DATA,
            response: dto(updatePrintSettingsWidthAPI.data)
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: printCardActions.UPDATE_PRINT_2x35_SETTINGS_HORIZONTAL_OFFSET_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Register action to watcher
 */
export const printSettingsSaga = [
    takeLatest(printCardActions.START_FETCHING_PRINT_SETTINGS, fetchPrintSettings),
    takeLatest(printCardActions.START_UPDATE_PRINT_SETTINGS, updatePrintSettings),
    takeLatest(printCardActions.START_UPDATE_PRINT_2X35_SETTINGS, updatePrintSettings2x35Width),
    takeLatest(
        printCardActions.START_FETCHING_PRINT_SETTINGS_2X35_OFFSET,
        fetchPrintSettings2x35Offset
    ),
    takeLatest(
        printCardActions.START_UPDATE_PRINT_2X35_HEIGHT_SETTINGS,
        updatePrintSettings2x35Height
    ),
    takeLatest(
        printCardActions.START_FETCHING_PRINT_SETTINGS_2X35_WIDTH,
        fetchPrintSettings2x35Width
    ),
    takeLatest(
        printCardActions.START_FETCHING_PRINT_SETTINGS_HEIGHT_2X35,
        fetchPrintSettings2x35Height
    ),
    takeLatest(
        printCardActions.START_UPDATE_PRINT_2X35_SETTINGS_OFFSET,
        updatePrintSettings2x35Offset
    ),
    takeLatest(
        printCardActions.START_FETCHING_PRINT_SETTINGS_2X35_HORIZONTAL_OFFSET,
        fetchPrintSettings2x35HorizontalOffset
    ),
    takeLatest(
        printCardActions.START_UPDATE_PRINT_2X35_SETTINGS_HORIZONTAL_OFFSET,
        updatePrintSettings2x35HorizontalOffset
    ),
    takeLatest(
        printCardActions.START_UPDATE_PRINT_2X35_CONTAINER_WIDTH_SETTINGS,
        updatePrintSettings2x35ContainerWidth
    ),
    takeLatest(
        printCardActions.START_FETCHING_PRINT_SETTINGS_2X35_CONTAINER_WIDTH,
        fetchPrintSettings2x35ContainerWidth
    )
];
