import { takeLatest, put, call } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
// import { getListingUrlQueryParams } from 'erputils/RouterManager/RouterManager.selectors';
// import { parseParamsForApi } from 'erputils/utils';
// import { actions as listingActions } from 'erpcomponents/Listing/Listing.reducer';
import { actions as notificationManagerActions } from 'erputils/NotificationManager/NotificationManager.reducer';
import dto from 'erputils/dto';
import { actions as notificationsActions } from './Notifications.reducer';

/**
 * MArk as done
 * @param  {Object} promise
 * @return {string} id Notification id
 */
export function* markAsDone({ promise, id, params }) {
    try {
        const markAsDoneAPI = yield restClient.patch(`api/notifications/${id}/mark-as-done`, {
            params
        });
        yield put({
            type: notificationsActions.MARK_NOTIFICATION_DONE_SUCCESSFUL,
            response: dto(markAsDoneAPI.data)
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: { code: 'notification.itemSuccessfullyUpdated' }
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: notificationsActions.MARK_NOTIFICATION_DONE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Register action to watcher
 */
export const notificationsSaga = [
    takeLatest(notificationsActions.MARK_NOTIFICATION_DONE, markAsDone)
];
