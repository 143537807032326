import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import Form, { Select, DateTime } from 'erpcore/components/Form';
import Button from 'erpcomponents/Button';
import { actions as listingActions } from 'erpcomponents/Listing/Listing.reducer';
import { getListingFetching, getListingResponse } from 'erpcomponents/Listing/Listing.selectors';
import { formatDate } from 'erputils/utils';
import { actions as activityLogActions } from '../../ActivityLog.reducer';

class ActivityLogFilters extends React.Component {
    componentDidMount() {
        const { fetchUsers } = this.props;
        fetchUsers();
    }

    onSubmit = formData => {
        const { entity, id, dispatch } = this.props;

        const params = Object.assign(
            {},
            {
                [`filters[email][equals]`]: formData.user,
                [`filters[createdAt][larger_than_or_equal]`]: formatDate(
                    formData.start_date,
                    'YYYY-MM-DD'
                ),
                [`filters[createdAt][smaller_than_or_equal]`]: formatDate(
                    formData.end_date,
                    'YYYY-MM-DD'
                )
            }
        );

        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: activityLogActions.START_FETCHING_ACTIVITY_LOGS,
                endpoint: `api/${entity}/${id}/audit`,
                entity,
                params,
                id
            });
        }).catch(error => ({ error }));
    };

    render() {
        const { handleSubmit, submitting, usersData } = this.props;
        const options = usersData.data.map(el => {
            return {
                label: `${el.first_name} ${el.last_name}`,
                value: el.email
            };
        });
        return (
            <div className="activity-log__filters">
                <Form onSubmit={handleSubmit(this.onSubmit)}>
                    <Form.Columns>
                        <Form.Column>
                            <Form.Row>
                                <Field
                                    name="user"
                                    id="user"
                                    fieldProps={{
                                        label: (
                                            <FormattedMessage
                                                id="ActivityLog.Filters.Name"
                                                defaultMessage="Name"
                                            />
                                        ),
                                        clearable: true,
                                        options
                                    }}
                                    component={Select}
                                />
                                <Field
                                    name="start_date"
                                    id="start_date"
                                    fieldProps={{
                                        label: (
                                            <FormattedMessage
                                                id="ActivityLog.Filters.StartDate"
                                                defaultMessage="Start Date"
                                            />
                                        ),
                                        clearable: true,
                                        icon: 'date',
                                        iconPosition: 'right'
                                    }}
                                    component={DateTime}
                                />

                                <Field
                                    name="end_date"
                                    id="end_date"
                                    fieldProps={{
                                        label: (
                                            <FormattedMessage
                                                id="ActivityLog.Filters.EndDate"
                                                defaultMessage="End Date"
                                            />
                                        ),
                                        clearable: true,
                                        icon: 'date',
                                        iconPosition: 'right'
                                    }}
                                    component={DateTime}
                                />

                                <Button
                                    submit
                                    loading={submitting}
                                    label={
                                        <FormattedMessage
                                            id="ActivityLog.Filters.Search"
                                            defaultMessage="Search"
                                        />
                                    }
                                />
                            </Form.Row>
                        </Form.Column>
                    </Form.Columns>
                </Form>
            </div>
        );
    }
}

ActivityLogFilters.defaultProps = {
    submitting: false,
    fetchUsers: () => {},
    usersData: {},
    dispatch: () => {}
};

ActivityLogFilters.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    fetchUsers: PropTypes.func,
    usersData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    entity: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    dispatch: PropTypes.func
};

ActivityLogFilters = reduxForm({
    form: 'ActivityLogFilters',
    enableReinitialize: false
})(ActivityLogFilters);

//  Redux
const mapStateToProps = state => ({
    usersListingFetching: getListingFetching(state, 'users'),
    usersData: getListingResponse(state, 'users')
});

const mapDispatchToProps = dispatch => ({
    fetchUsers: () => {
        dispatch({
            type: listingActions.START_FETCHING_LISTING,
            entity: 'USERS',
            endpoint: 'api/users?pagination=false'
        });
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ActivityLogFilters);
