import React from 'react';
import PropTypes from 'prop-types';
import Svg from 'erpcomponents/Svg';
import './PageLoader.scss';

/**
 *
 * @param {*} props
 */
const PageLoader = props => {
    const { className } = props;

    return (
        <div className={`page-loader ${className || ''}`}>
            <Svg icon="pageLoader" />
        </div>
    );
};

PageLoader.defaultProps = {
    className: ''
};

PageLoader.propTypes = {
    className: PropTypes.string
};

export default PageLoader;
