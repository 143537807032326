import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import enviromentVariables from 'erputils/enviromentVariables';
import {
    Field,
    getFormValues,
    getFormInitialValues,
    getFormSubmitErrors,
    change,
    touch,
    initialize
} from 'redux-form';
import Form, { Checkbox, Text, Creatable } from 'erpcore/components/Form';
import valueValidation from 'erputils/valueValidation';
import Geocode from 'react-geocode';
import { getIncludedDataByIri } from 'erputils/dto';
import FullAddress from './components/FullAddress';
import LocationPin from './components/LocationPin';

class Location extends Component {
    constructor(props) {
        super(props);

        this.state = {
            initialized: false
        };

        Geocode.setApiKey(`${enviromentVariables.GOOGLE_MAPS_API_KEY}&language=en`);
        this.dispatchAddressFromAPI = this.dispatchAddressFromAPI.bind(this);
        this.dispatchMapLocation = this.dispatchMapLocation.bind(this);
        this.getFullAddress = this.getFullAddress.bind(this);
        this.manualFieldChange = this.manualFieldChange.bind(this);
        this.timeout = 0;
    }

    //  Filling out Initial Data
    shouldComponentUpdate(prevProps, prevState) {
        const { initialized } = prevState;
        const { meta, formInitialValues, dispatch, input } = prevProps;

        if (!initialized && Object.keys(formInitialValues).length) {
            this.setState({ initialized: true });
            if (formInitialValues[input.name].latitude && formInitialValues[input.name].longitude) {
                formInitialValues[input.name].locationPin = {
                    lat: formInitialValues[input.name].latitude,
                    lng: formInitialValues[input.name].longitude
                };
            }
            formInitialValues[input.name].fullAddress = this.getFullAddress(formInitialValues);
            dispatch(initialize(meta.form, formInitialValues));
        }
        return true;
    }

    getFormValue(field) {
        const { formValues, input } = this.props;
        if (!formValues[input.name]) formValues[input.name] = {};

        if (formValues[field]) {
            return formValues[field];
        }
        return false;
    }

    getFullAddress(initialValues) {
        const { input } = this.props;
        let { formValues } = this.props;

        if (!Object.keys(formValues[input.name]).length) formValues = initialValues;

        const country = getIncludedDataByIri(formValues[input.name].country, formValues);
        const city = getIncludedDataByIri(formValues[input.name].city, formValues);
        const state = getIncludedDataByIri(formValues[input.name].state, formValues);
        const address =
            formValues[input.name].address === 'Unnamed Road' ? '' : formValues[input.name].address;

        return `${address || ''}${
            formValues[input.name].zip ? ` ${formValues[input.name].zip}` : ''
        }${city.name ? ` ${city.name}` : `${city.label ? ` ${city.label}` : ''}`}${
            state.name ? ` ${state.name}` : `${state.label ? ` ${state.label}` : ''}`
        }${country.name ? ` ${country.name}` : `${country.label ? ` ${country.label}` : ''}`}`;
    }

    manualFieldChange() {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            Geocode.fromAddress(this.getFullAddress()).then(address => {
                this.dispatchMapLocation(address.results);
            });
        }, 300);
    }

    /**
     *
     * @param components
     * @param type
     * @param version {string}
     */
    findAddressComponent(components, type, version = 'long_name') {
        const address = components.find(component => {
            return component.types.indexOf(type) !== -1;
        });
        if (address) {
            return address[version];
        }
        return '';
    }

    dispatchMapLocation(address) {
        //  Getting lat and lng
        let locationLat = '';
        let locationLng = '';
        const { location } = address[0].geometry;
        if (typeof location.lng === 'function') {
            locationLat = location.lat();
            locationLng = location.lng();
        } else {
            locationLat = location.lat;
            locationLng = location.lng;
        }

        const { dispatch, meta, input } = this.props;
        dispatch(change(meta.form, `${input.name}.longitude`, locationLng));
        dispatch(change(meta.form, `${input.name}.latitude`, locationLat));
        dispatch(
            change(meta.form, `${input.name}.locationPin`, {
                lat: locationLat,
                lng: locationLng
            })
        );
    }

    dispatchAddressFromAPI(address) {
        const { fieldProps } = this.props;
        //  When the google address is selected we prepare all data and send it to Redux form input
        const components = address[0].address_components;

        //  merging street line 1 to ex 'Ul. Rudolfa Fizira 21' route and streetnumber to one line
        const streetLineOne = `${this.findAddressComponent(components, 'route')}${
            this.findAddressComponent(components, 'street_number')
                ? ` ${this.findAddressComponent(components, 'street_number')}`
                : ''
        }`;

        const locationData = {
            fullAddress: address[0].formatted_address,
            address: streetLineOne,
            zip: this.findAddressComponent(components, 'postal_code')
        };
        //  Adding city object
        if (this.findAddressComponent(components, 'locality')) {
            locationData.city = this.findAddressComponent(components, 'locality');
        } else if (this.findAddressComponent(components, 'neighborhood')) {
            locationData.city = this.findAddressComponent(components, 'neighborhood');
        }
        //  Adding state object
        if (this.findAddressComponent(components, 'administrative_area_level_1')) {
            locationData.state = this.findAddressComponent(
                components,
                'administrative_area_level_1'
            );
        }
        //  Adding country object
        if (this.findAddressComponent(components, 'country')) {
            locationData.country = this.findAddressComponent(components, 'country');
        }

        //  Getting lat and lng
        this.dispatchMapLocation(address);

        const { dispatch, meta, input } = this.props;
        dispatch(change(meta.form, `${input.name}.fullAddress`, locationData.fullAddress || ''));
        dispatch(change(meta.form, `${input.name}.address`, locationData.address || ''));
        dispatch(change(meta.form, `${input.name}.country`, locationData.country || ''));
        dispatch(change(meta.form, `${input.name}.zip`, locationData.zip || ''));
        dispatch(change(meta.form, `${input.name}.city`, locationData.city || ''));
        dispatch(change(meta.form, `${input.name}.state`, locationData.state || ''));

        if (fieldProps.showManualAfterFullAddressSelect || fieldProps.showManual) {
            dispatch(change(meta.form, `${input.name}.fullAddressSelected`, true));
            dispatch(touch(meta.form, `${input.name}.address`));
            dispatch(touch(meta.form, `${input.name}.country`));
            dispatch(touch(meta.form, `${input.name}.zip`));
            dispatch(touch(meta.form, `${input.name}.city`));
        }
    }

    /**
     *
     * @param name
     * @param id
     * @param labels
     */
    renderFullAddress(id) {
        const { input } = this.props;
        const manual = this.getFormValue(input.name).locationManual;
        return (
            <Field
                name={`${input.name}.fullAddress`}
                id={`${id}fullAddress`}
                key="LocationFullAddress"
                fieldProps={{
                    icon: 'location',
                    label: (
                        <FormattedMessage id="Location.FullAddress" defaultMessage="Full Address" />
                    )
                }}
                onAddressSelected={this.dispatchAddressFromAPI}
                component={FullAddress}
                fieldAttr={{ required: true, disabled: manual }}
                validate={valueValidation([{ validator: 'required' }])}
            />
        );
    }

    /**
     *
     * @param name
     * @param id
     * @returns {*}
     */
    renderManual(id) {
        const { fieldAttr, input } = this.props;
        const manual = this.getFormValue(input.name).locationManual;
        return (
            <Fragment>
                <Form.Row>
                    <Field
                        name={`${input.name}.address`}
                        id={`${id}Address`}
                        onChange={() => {
                            this.manualFieldChange();
                        }}
                        fieldProps={{
                            clearable: true,
                            icon: 'location',
                            label: (
                                <FormattedMessage id="Location.Address" defaultMessage="Address" />
                            )
                        }}
                        fieldAttr={{ required: true, disabled: !manual || fieldAttr.disabled }}
                        component={Text}
                        validate={valueValidation([{ validator: 'required' }])}
                    />
                </Form.Row>
                <Form.Row>
                    <Field
                        name={`${input.name}.country`}
                        id={`${id}Country`}
                        key="LocationFieldCountry"
                        onChange={() => {
                            this.manualFieldChange();
                        }}
                        fieldProps={{
                            label: (
                                <FormattedMessage id="Location.Country" defaultMessage="Country" />
                            ),
                            options: {
                                endpoint: '/api/countries',
                                mapData: {
                                    value: 'iri',
                                    label: 'name'
                                }
                            }
                        }}
                        fieldAttr={{ required: true, disabled: !manual || fieldAttr.disabled }}
                        component={Creatable}
                        validate={valueValidation([{ validator: 'required' }])}
                    />
                </Form.Row>
                <Form.Row>
                    <Field
                        name={`${input.name}.city`}
                        id={`${id}City`}
                        key="LocationFieldCity"
                        onChange={() => {
                            this.manualFieldChange();
                        }}
                        fieldProps={{
                            label: <FormattedMessage id="Location.city" defaultMessage="City" />,
                            options: {
                                endpoint: '/api/cities',
                                mapData: {
                                    value: 'iri',
                                    label: 'name'
                                }
                            }
                        }}
                        fieldAttr={{ required: true, disabled: !manual || fieldAttr.disabled }}
                        component={Creatable}
                        validate={valueValidation([{ validator: 'required' }])}
                    />
                    <Field
                        name={`${input.name}.zip`}
                        id={`${id}Zip`}
                        onChange={() => {
                            this.manualFieldChange();
                        }}
                        fieldProps={{
                            label: <FormattedMessage id="Location.Zip" defaultMessage="ZIP" />
                        }}
                        fieldAttr={{ required: true, disabled: !manual || fieldAttr.disabled }}
                        component={Text}
                        validate={valueValidation([{ validator: 'required' }])}
                    />
                </Form.Row>
                <Form.Row>
                    <Field
                        name={`${input.name}.state`}
                        id={`${id}State`}
                        key="LocationFieldState"
                        onChange={() => {
                            this.manualFieldChange();
                        }}
                        fieldProps={{
                            label: <FormattedMessage id="Location.State" defaultMessage="State" />,
                            options: {
                                endpoint: '/api/states',
                                mapData: {
                                    value: 'iri',
                                    label: 'name'
                                }
                            }
                        }}
                        fieldAttr={{
                            disabled: !manual || fieldAttr.disabled
                        }}
                        component={Creatable}
                    />
                </Form.Row>
                <Form.Row>
                    <Field
                        name={`${input.name}.latitude`}
                        id={`${id}Lat`}
                        fieldProps={{
                            label: <FormattedMessage id="Location.Lat" defaultMessage="Latitude" />
                        }}
                        fieldAttr={{ disabled: true }}
                        component={Text}
                    />
                    <Field
                        name={`${input.name}.longitude`}
                        id={`${id}Lng`}
                        fieldProps={{
                            label: <FormattedMessage id="Location.Lng" defaultMessage="Longitude" />
                        }}
                        fieldAttr={{ disabled: true }}
                        component={Text}
                    />
                </Form.Row>
                <Form.Row>
                    <Field
                        name={`${input.name}.locationPin`}
                        id={`${id}LocationPin`}
                        onChange={latLng => {
                            Geocode.fromLatLng(latLng.lat, latLng.lng).then(address =>
                                this.dispatchAddressFromAPI(address.results)
                            );
                        }}
                        disabled={!manual || fieldAttr.disabled}
                        component={LocationPin}
                    />
                </Form.Row>
            </Fragment>
        );
    }

    renderLocationErrors() {
        const { formErrors } = this.props;

        const allowedErrors = ['address', 'country', 'city', 'zip'];

        const filteredErrors = Object.keys(formErrors)
            .filter(key => allowedErrors.includes(key))
            .reduce((obj, key) => {
                obj[key] = formErrors[key];
                return obj;
            }, {});

        return Object.keys(filteredErrors).map(errorKey => (
            <span className="input__helper-text input__helper-text--error">
                {filteredErrors[errorKey].message}
            </span>
        ));
    }

    /**
     *
     * @returns {*}
     */
    render() {
        const { id, fieldProps, input } = this.props;
        const manual = this.getFormValue(input.name).locationManual;
        const { fullAddressSelected } = this.getFormValue(input.name);
        const { fieldAttr } = this.props;

        return (
            <div className="location">
                {!fieldAttr.disabled ? (
                    <Form.Row>
                        <div className="location__checkbox">
                            <Field
                                name={`${input.name}.locationManual`}
                                id={`${id}Manual`}
                                fieldProps={{
                                    id: `${id}Manual`,
                                    value: true,
                                    label: (
                                        <FormattedMessage
                                            id="Location.inputManualy"
                                            defaultMessage="Input Manually"
                                        />
                                    )
                                }}
                                fieldAttr={{
                                    disabled: fieldAttr.disabled
                                }}
                                component={Checkbox}
                            />
                        </div>
                    </Form.Row>
                ) : (
                    ''
                )}
                {!manual && !fieldAttr.disabled ? (
                    <Form.Row>
                        {this.renderFullAddress(id)}
                        {this.renderLocationErrors()}
                    </Form.Row>
                ) : (
                    ''
                )}
                {fullAddressSelected || manual || fieldProps.showManual
                    ? this.renderManual(id)
                    : ''}
            </div>
        );
    }
}

Location.defaultProps = {
    id: '',
    input: {},
    fieldProps: {
        showManualAfterFullAddressSelect: true,
        showManual: false
    },
    fieldAttr: {},
    formValues: {},
    formInitialValues: {},
    formErrors: {},
    meta: {}
};
Location.propTypes = {
    id: PropTypes.string,
    input: PropTypes.oneOfType([PropTypes.object]),
    fieldProps: PropTypes.oneOfType([PropTypes.object]),
    fieldAttr: PropTypes.oneOfType([PropTypes.object]),
    formValues: PropTypes.oneOfType([PropTypes.object]),
    formInitialValues: PropTypes.oneOfType([PropTypes.object]),
    formErrors: PropTypes.oneOfType([PropTypes.object]),
    meta: PropTypes.oneOfType([PropTypes.object]),
    dispatch: PropTypes.func.isRequired
};

const mapStateToProps = (state, props) => ({
    formValues: getFormValues(props.meta.form)(state),
    formErrors: getFormSubmitErrors(props.meta.form)(state),
    formInitialValues: getFormInitialValues(props.meta.form)(state)
});

export default connect(mapStateToProps)(Location);
