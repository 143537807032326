import { takeLatest, put, call, select } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import { getQueryParams } from 'erpcore/components/Listing/Listing.selectors';
import { actions as listingActions } from 'erpcomponents/Listing/Listing.reducer';
import { actions as notificationManagerActions } from 'erputils/NotificationManager/NotificationManager.reducer';
import dto from 'erputils/dto';
import { getUserCurrentOrganization } from 'erputils/AuthManager/AuthManager.selectors';
import { actions as eventsActions } from './Events.reducer';

/**
 * Create Event
 * @param  {Object} promise
 * @return {Object} formData
 */
export function* createEvent({ promise, formData }) {
    try {
        // Prepare data for /events API
        const userCurrentOrganization = yield select(getUserCurrentOrganization);
        if (!userCurrentOrganization) {
            console.error('Missing Organization ID!');
        }
        formData = Object.assign(formData, {
            organization: userCurrentOrganization.iri
        });

        // Create Event
        const createEventAPI = yield restClient.post(`api/events`, formData);
        yield put({
            type: eventsActions.CREATE_EVENT_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: createEventAPI.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: eventsActions.CREATE_EVENT_FAILED,
            response: error.response.data
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Fetch Event
 * @param  {Object} promise
 * @return {string} id Event id
 */
export function* fetchEvent({ promise, id, params }) {
    try {
        const fetchEventAPI = yield restClient.get(`api/events/${id}`, { params });
        yield put({
            type: eventsActions.FETCH_SINGLE_EVENT_SUCCESSFUL
        });
        yield put({
            type: eventsActions.STORE_SINGLE_EVENT_DATA,
            id,
            response: dto(fetchEventAPI.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: eventsActions.FETCH_SINGLE_EVENT_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error && error.response && error.response.data
        });
        yield call(promise.reject, (error && error.response && error.response.data) || error);
    }
}

/**
 * Update event single data
 * @param  {Object} id ID of an event
 * @return {Object} Response from API
 */
export function* updateSingleEvent({ promise, formData, id }) {
    try {
        const updateSingleEventAPI = yield restClient.put(
            `api/events/${id}?include=location,location.country,location.state,location.city,eventSeries.name,ageRequirement,eventCategories,foodTypes,barTypes,timezone`,
            formData
        );
        yield put({
            type: eventsActions.UPDATE_SINGLE_EVENT_SUCCESSFUL
        });
        yield put({
            type: eventsActions.STORE_SINGLE_EVENT_DATA,
            id,
            response: dto(updateSingleEventAPI.data)
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateSingleEventAPI.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: eventsActions.UPDATE_SINGLE_EVENT_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}
/**
 * Update event single data seo
 * @param  {Object} id ID of an event
 * @return {Object} Response from API
 */
export function* updateSingleEventSEO({ promise, formData, id }) {
    try {
        const updateSingleEventAPI = yield restClient.put(
            `api/events/${id}?include=location,location.country,location.state,location.city,event_series`,
            formData
        );
        yield put({
            type: eventsActions.UPDATE_SINGLE_EVENT_SUCCESSFUL_SEO
        });
        yield put({
            type: eventsActions.STORE_SINGLE_EVENT_DATA_SEO,
            id,
            response: dto(updateSingleEventAPI.data)
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateSingleEventAPI.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: eventsActions.UPDATE_SINGLE_EVENT_FAILED_SEO
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}
/**
 * Update event single data 3rd Party Connect
 * @param  {Object} id ID of an event
 * @return {Object} Response from API
 */
export function* updateSingleEvent3RDPC({ promise, formData, id }) {
    try {
        const updateSingleEventAPI = yield restClient.put(
            `api/events/${id}?include=organization,location,location.country,location.state,location.city`,
            formData
        );
        yield put({
            type: eventsActions.UPDATE_SINGLE_EVENT_SUCCESSFUL_3RDPC
        });
        yield put({
            type: eventsActions.STORE_SINGLE_EVENT_DATA_3RDPC,
            id,
            response: dto(updateSingleEventAPI.data)
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateSingleEventAPI.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: eventsActions.UPDATE_SINGLE_EVENT_FAILED_3RDPC
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Delete Single Event
 * @param  {Object} id ID of an Event
 * @return {Object} Response from API
 */
export function* deleteSingleEvent({ promise, id }) {
    try {
        const deleteSingleEventAPI = yield restClient.delete(`api/events/${id}`);
        yield put({
            type: eventsActions.DELETE_SINGLE_EVENT_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: deleteSingleEventAPI.data
        });
        const params = yield select(getQueryParams, { name: 'events' });
        yield put({
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: 'EVENTS',
            name: 'events',
            endpoint: 'api/events'
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: eventsActions.DELETE_SINGLE_EVENT_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Register action to watcher
 */
export const eventsSaga = [
    takeLatest(eventsActions.START_CREATE_EVENT, createEvent),
    takeLatest(eventsActions.START_FETCHING_SINGLE_EVENT, fetchEvent),
    takeLatest(eventsActions.START_UPDATE_SINGLE_EVENT, updateSingleEvent),
    takeLatest(eventsActions.START_UPDATE_SINGLE_EVENT_SEO, updateSingleEventSEO),
    takeLatest(eventsActions.START_UPDATE_SINGLE_EVENT_3RDPC, updateSingleEvent3RDPC),
    takeLatest(eventsActions.START_DELETE_SINGLE_EVENT, deleteSingleEvent)
];
