import {
    required,
    email,
    length,
    numericality,
    date,
    confirmation,
    format,
    acceptance,
    inclusion,
    exclusion,
    absence,
    url,
    file
} from 'redux-form-validators';

/**
 * valueValidation prepares validators for field
 * @param {Array} validators Description of field validation
 * @return {Array} Returns array of validation functions for field
 */
const valueValidation = validators => {
    const preparedValidators = [];
    //  Skip validators maps if they are empty
    if (!validators) {
        return false;
    }
    //  Fill validatiors with arguments
    validators.map(validation => {
        const { validator, args } = validation;
        if (validator === 'required') {
            preparedValidators.push(required(args));
        } else if (validator === 'email') {
            preparedValidators.push(email(args));
        } else if (validator === 'length') {
            preparedValidators.push(length(args));
        } else if (validator === 'numericality') {
            preparedValidators.push(numericality(args));
        } else if (validator === 'date') {
            preparedValidators.push(date(args));
        } else if (validator === 'confirmation') {
            preparedValidators.push(confirmation(args));
        } else if (validator === 'format') {
            preparedValidators.push(format(args));
        } else if (validator === 'acceptance') {
            preparedValidators.push(acceptance(args));
        } else if (validator === 'inclusion') {
            preparedValidators.push(inclusion(args));
        } else if (validator === 'exclusion') {
            preparedValidators.push(exclusion(args));
        } else if (validator === 'absence') {
            preparedValidators.push(absence(args));
        } else if (validator === 'url') {
            preparedValidators.push(url(args));
        } else if (validator === 'file') {
            preparedValidators.push(file(args));
        }
        return true;
    });
    return preparedValidators;
};

export default valueValidation;
