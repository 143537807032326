import React from 'react';
import generateKey from 'erpcore/utils/generateKey';
import PropTypes from 'prop-types';
import Svg from 'erpcore/components/Svg';
import './Pagination.scss';

const Pagination = ({ meta, onChangePagination }) => {
    if (!meta.lastPage) {
        meta.lastPage = Math.ceil(meta.totalItems / meta.itemsPerPage);
    }
    //  Algoritham for generatin pagination structure like < 1 2 3 ... >
    const delta = 2;
    const range = [];
    const rangeWithDots = [];
    let l;
    range.push(1);
    if (meta.lastPage <= 1) {
        return null;
    }
    for (let i = meta.currentPage - delta; i <= meta.currentPage + delta; i += 1) {
        if (i < meta.lastPage && i > 1) {
            range.push(i);
        }
    }
    if (!Number.isNaN(meta.lastPage)) {
        range.push(meta.lastPage);
    }
    for (let i = 0; i < range.length; i += 1) {
        if (l) {
            if (range[i] - l === 2) {
                rangeWithDots.push(l + 1);
            } else if (range[i] - l !== 1) {
                rangeWithDots.push('...');
            }
        }
        rangeWithDots.push(range[i]);
        l = range[i];
    }

    if (rangeWithDots.length > 1) {
        return (
            <div className="pagination">
                {meta.currentPage > 1 && (
                    <button
                        onClick={() =>
                            onChangePagination({
                                page: meta.currentPage - 1 < 1 ? 1 : meta.currentPage - 1
                            })
                        }
                        type="button"
                        className="pagination__button pagination__button--arrow"
                    >
                        <Svg icon="arrowLeft" />
                    </button>
                )}
                {rangeWithDots.map(item => {
                    return (
                        <button
                            onClick={() => onChangePagination({ page: item })}
                            type="button"
                            className={`pagination__button${
                                meta.currentPage === Number(item)
                                    ? ' pagination__button--active'
                                    : ''
                            }${item === '...' ? ' pagination__button--disabled' : ''}`}
                            key={generateKey(`pagination${item}`)}
                        >
                            {item}
                        </button>
                    );
                })}
                {meta.currentPage < meta.lastPage && (
                    <button
                        onClick={() =>
                            onChangePagination({
                                page:
                                    meta.currentPage + 1 > meta.lastPage
                                        ? meta.lastPage
                                        : meta.currentPage + 1
                            })
                        }
                        type="button"
                        className="pagination__button pagination__button--arrow"
                    >
                        <Svg icon="arrowRight" />
                    </button>
                )}
            </div>
        );
    }
    return null;
};

Pagination.defaultProps = {
    onChangePagination: () => {},
    meta: {}
};

Pagination.propTypes = {
    meta: PropTypes.oneOfType([PropTypes.object]),
    onChangePagination: PropTypes.func
};

export default Pagination;
