import React from 'react';
import PropTypes from 'prop-types';
import generateKey from 'erputils/generateKey';
import Checkbox from '../Checkbox';

import './CheckboxGroup.scss';

const CheckboxGroup = ({ input, meta, fieldProps, fieldAttr }) => {
    if ('options' in fieldProps) {
        const { options, inlineLabel } = fieldProps;
        return (
            <div
                className={`input-checkbox-group${
                    inlineLabel ? ' input-checkbox-group--inline-label' : ''
                }`}
            >
                {fieldProps.label && (
                    <span className="input-checkbox-group__label">{fieldProps.label}</span>
                )}
                <div className="input-checkbox-group__content">
                    {options.map(item => (
                        <Checkbox
                            input={input}
                            meta={meta}
                            fieldProps={item}
                            fieldAttr={{
                                disabled: fieldAttr.disabled
                            }}
                            key={generateKey(input.name + item.value)}
                            isGroup
                        />
                    ))}
                </div>
                {meta.touched && meta.error && (
                    <span className="input-checkbox__error">
                        {meta.error.message ? meta.error.message : meta.error}
                    </span>
                )}
            </div>
        );
    }
    return null;
};
CheckboxGroup.defaultProps = {
    fieldProps: {
        label: '',
        options: []
    },
    fieldAttr: {
        disabled: false
    },
    input: {},
    meta: {}
};
CheckboxGroup.propTypes = {
    fieldProps: PropTypes.oneOfType([PropTypes.object]),
    fieldAttr: PropTypes.oneOfType([PropTypes.object]),
    input: PropTypes.oneOfType([PropTypes.object]),
    meta: PropTypes.oneOfType([PropTypes.object])
};
export default CheckboxGroup;
