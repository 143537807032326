import React from 'react';
import PropTypes from 'prop-types';
import Svg from 'erpcomponents/Svg';
import './Accordion.scss';

class Accordion extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            opened: props.open,
            openInitialized: props.open
        };

        this.onOpenInitialized = this.onOpenInitialized.bind(this);
        this.onToggleOccurred = this.onToggleOccurred.bind(this);
    }

    componentDidMount() {
        const { openInitialized } = this.state;
        if (openInitialized) {
            this.onOpenInitialized();
        }
    }

    onOpenInitialized() {
        const { onOpenInitialized } = this.props;
        onOpenInitialized();
    }

    onToggleOccurred() {
        const { onToggleOccurred } = this.props;
        onToggleOccurred();
    }

    handleAccordionState = () => {
        const { opened: oldOpened, openInitialized: oldOpenInitialized } = this.state;
        this.setState({ opened: !oldOpened, openInitialized: true }, () => {
            this.onToggleOccurred();
            if (!oldOpenInitialized) {
                this.onOpenInitialized();
            }
        });
    };

    render() {
        const { title, body, children, variation } = this.props;
        const { opened } = this.state;

        return (
            <div
                className={`accordion-item accordion-item--${variation}${
                    opened ? ' accordion-item--opened' : ''
                }`}
            >
                <button
                    type="button"
                    className="accordion-item__line"
                    onClick={() => this.handleAccordionState()}
                    tabIndex="0"
                >
                    <span className="accordion-item__title">{title}</span>
                    <Svg className="accordion-item__icon" icon="arrowDown" />
                </button>
                <div className="accordion-item__inner">
                    <div className="accordion-item__content">
                        {body && <div className="accordion-item__paragraph">{body}</div>}
                        {children}
                    </div>
                </div>
            </div>
        );
    }
}

Accordion.defaultProps = {
    open: false,
    body: null, // backwards compatibility
    children: null,
    variation: 'default',
    onOpenInitialized: () => {},
    onToggleOccurred: () => {}
};

Accordion.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    open: PropTypes.bool,
    body: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.node]), // backwards compatibility
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
        PropTypes.arrayOf([PropTypes.node])
    ]),
    variation: PropTypes.oneOf(['default', 'border']),
    onOpenInitialized: PropTypes.func,
    onToggleOccurred: PropTypes.func
};

export default Accordion;
