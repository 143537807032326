import React from 'react';
import { Field } from 'redux-form';
import Form, { Switch } from 'erpcore/components/Form';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { actions as resellersActions } from 'erpcore/screens/Resellers/Resellers.reducer';
import './EventRepeaterItem.scss';

export const Span = props => {
    // eslint-disable-next-line react/prop-types
    const { input } = props;
    return <span className="sort-events-repeater-item">{input.value}</span>;
};

class EventRepeaterItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            eventStatus: false
        };
    }

    componentDidMount() {
        const { data } = this.props;
        const eventStatus = !this.handleEventVisibility(data);
        this.setState({ eventStatus });
    }

    handleEventVisibility(data) {
        const { id, hideEvents } = data;

        if (!hideEvents) {
            return false;
        }

        return hideEvents.some(hidden => hidden.id === id);
    }

    handleEventVisibilityChange(visible) {
        this.setState({ eventStatus: visible });
        const {
            data: { iri, resellerId, hideEvents },
            setEventVisibility
        } = this.props;

        if (!hideEvents) {
            return setEventVisibility([iri], visible, resellerId);
        }

        let hiddenEvents = hideEvents.map(hiddenEvent => hiddenEvent.iri);

        if (!visible) {
            hiddenEvents.push(iri);
        } else {
            hiddenEvents = hiddenEvents.filter(hiddenEvent => hiddenEvent !== iri);
        }

        return setEventVisibility(hiddenEvents, visible, resellerId);
    }

    render() {
        const { member } = this.props;
        const { eventStatus } = this.state;

        return (
            <Form.Row>
                <Field name={`${member}.name`} id="label" component={Span} />
                <Field
                    name={`${member}.id`}
                    id="includeEvents"
                    input={{
                        value: eventStatus,
                        onChange: e => this.handleEventVisibilityChange(e)
                    }}
                    fieldProps={{
                        on: {
                            value: true,
                            id: 'switch-on-true'
                        },
                        off: {
                            value: false,
                            id: 'switch-off-false'
                        },
                        type: 'single'
                    }}
                    component={Switch}
                />
            </Form.Row>
        );
    }
}

EventRepeaterItem.defaultProps = {
    member: null,
    data: {},
    setEventVisibility: () => {}
};

EventRepeaterItem.propTypes = {
    member: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    data: PropTypes.oneOfType([PropTypes.object]),
    setEventVisibility: PropTypes.func
};

const mapDispatchToProps = dispatch => ({
    setEventVisibility: (events, visible, resellerId) => {
        const params = {
            pagination: false
        };
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: resellersActions.START_UPDATE_RESELLER_EVENT_VISIBILITY,
                id: resellerId.id,
                formData: { reseller_hide_events: events },
                visible,
                params
            });
        }).catch(error => ({ error }));
    }
});

export default connect(
    null,
    mapDispatchToProps
)(EventRepeaterItem);
