import PropTypes from 'prop-types';
import restClient from 'erpcore/api/restClient';
import dto from 'erputils/dto';

const dtoGallerySort = response => {
    if (response && response.data !== undefined) {
        const { data } = dto(response.data);
        // convert id to int
        data.forEach((item, index) => {
            if (!Number.isNaN(parseInt(item.id, 10))) {
                data[index].id = parseInt(item.id, 10);
            }
        });

        // sort ticket types
        data.sort((firstItem, secondItem) => {
            // if position is not set, treat position as a 999999 (otherwise null position would evaluate as less than any other defined position)
            const firstItemPosition =
                firstItem.position || firstItem.position === 0 ? firstItem.position : 999999;
            const secondItemPosition =
                secondItem.position || secondItem.position === 0 ? secondItem.position : 999999;

            let compare = 0;
            //  0 = order is unchanged
            //  1 = secondItem takes precedence over firstItem
            // -1 = firstItem takes precedence over secondItem
            if (firstItemPosition === secondItemPosition) {
                if (firstItem.id > secondItem.id) {
                    compare = 1;
                } else if (firstItem.id < secondItem.id) {
                    compare = -1;
                }
            } else if (firstItemPosition > secondItemPosition) {
                compare = 1;
            } else if (firstItemPosition < secondItemPosition) {
                compare = -1;
            }
            return compare;
        });

        response.data = data;
    }
    return response;
};

const copyNoInstanceGallery = ({ source, destination }) => {
    return new Promise(resolve => {
        const addMultipleMediaObjectsToGallery = mediaObjectIris => {
            const {
                type: attachedToEntityType,
                iri: attachedToEntityIri
            } = destination.attachedToEntity;

            const formData = {
                [attachedToEntityType]: attachedToEntityIri,
                media_objects: mediaObjectIris,
                group_name: destination.groupName
            };

            return new Promise((resolveAdd, rejectAdd) => {
                restClient
                    .post(`${destination.galleryApi}/multi`, formData)
                    .then(() => {
                        resolveAdd();
                    })
                    .catch(() => {
                        rejectAdd();
                    });
            });
        };

        const fetchImages = (getImagesApi, groupName = null) => {
            return new Promise((resolveFetch, rejectFetch) => {
                restClient
                    .get(`${getImagesApi}`, {
                        params: {
                            'filters[group_name][equals]': groupName,
                            include: 'mediaObject.mediaObjectVersions',
                            pagination: false
                        }
                    })
                    .then(response => {
                        return resolveFetch(response);
                    })
                    .catch(error => {
                        return rejectFetch(error);
                    });
            });
        };

        const removeItem = (id = false) => {
            return new Promise(resolveRemove => {
                restClient.delete(`${destination.galleryApi}/${id}`).finally(() => {
                    resolveRemove();
                });
            });
        };

        // Array of concurrent fetch Promises
        const fetchGalleriesPromises = [
            fetchImages(destination.getImagesApi, destination.groupName), // destination gallery
            fetchImages(source.getImagesApi, source.groupName) // source gallery
        ];

        // Array of concurrent "remove image from gallery" Promises
        const removeGalleryItemsPromises = data => {
            return data.map(item => removeItem(item.id));
        };

        // Array of concurrent "add MediaObject to Gallery" Promises
        const addMediaObjectsToGallery = items => {
            const collection = [];
            items.forEach(galleryItem => {
                if (galleryItem.media_object !== undefined) {
                    const mediaObject = galleryItem.media_object;
                    if (mediaObject && mediaObject.iri !== undefined && mediaObject.iri) {
                        collection.push(mediaObject.iri);
                    }
                }
            });
            return addMultipleMediaObjectsToGallery(collection);
        };

        Promise.all(fetchGalleriesPromises).then(bothGalleryResponses => {
            const destinationGalleryItems = [...dtoGallerySort(bothGalleryResponses[0]).data];
            const sourceGalleryItems = [...dtoGallerySort(bothGalleryResponses[1]).data];

            Promise.all(removeGalleryItemsPromises(destinationGalleryItems)).finally(() => {
                addMediaObjectsToGallery(sourceGalleryItems).finally(() => {
                    resolve();
                });
            });
        });
    });
};
copyNoInstanceGallery.defaultProps = {
    data: {}
};
copyNoInstanceGallery.propTypes = {
    data: PropTypes.shape({
        source: PropTypes.shape({
            getImagesApi: PropTypes.string.isRequired,
            groupName: PropTypes.string.isRequired
        }).isRequired,
        destination: PropTypes.shape({
            getImagesApi: PropTypes.string.isRequired,
            groupName: PropTypes.string.isRequired,
            galleryApi: PropTypes.string.isRequired,
            attachedToEntity: PropTypes.shape({
                type: PropTypes.string.isRequired,
                iri: PropTypes.string.isRequired
            }).isRequired
        }).isRequired
    })
};

export { dtoGallerySort, copyNoInstanceGallery };
