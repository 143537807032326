import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { dtoForm } from 'erputils/dto';
import { diff } from 'deep-object-diff';
import { FormattedMessage } from 'react-intl';
import reduxFormErrorMapper from 'erputils/reduxFormErrorMapper';
import LayoutManager from 'erputils/LayoutManager';
import PageHeader from 'erpcomponents/Layout/PageHeader';
import PageContent from 'erpcomponents/Layout/PageContent';
import ResellerInfo from 'erpcore/screens/Resellers/components/ResellerInfo';
import ResellerEditTabs from 'erpcore/screens/Resellers/components/ResellerEditTabs';
import PageLoader from 'erpcomponents/PageLoader';
import { actions as resellersActions } from 'erpcore/screens/Resellers/Resellers.reducer';
import {
    getResellerData,
    getSingleResellerFetching
} from 'erpcore/screens/Resellers/Resellers.selectors';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import Form, { Text, Phone, Email, Autocomplete } from 'erpcore/components/Form';
import valueValidation from 'erputils/valueValidation';
import Button from 'erpcomponents/Button';

class GeneralInfo extends Component {
    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        const { fetchResellerData } = this.props;
        fetchResellerData();
    }

    onSubmit(formData) {
        const { match, dispatch, initialValues } = this.props;
        // send only changed data
        const data = diff(initialValues, formData);

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: resellersActions.START_UPDATE_SINGLE_RESELLER_GENERAL_INFO,
                id: match.params.id,
                formData: data
            })
        ).catch(error => {
            throw new SubmissionError(reduxFormErrorMapper(error));
        });
    }

    render() {
        const {
            handleSubmit,
            submitting,
            pristine,
            invalid,
            singleFetching,
            fetchResellerData
        } = this.props;
        return (
            <LayoutManager slot="main" className="main--narrow" layoutType="merge">
                <PageHeader
                    title={
                        <FormattedMessage id="ResellerEdit.title" defaultMessage="Edit Reseller" />
                    }
                    backButtonLink="/resellers"
                />
                {singleFetching === true && <PageLoader />}
                <ResellerInfo fetchResellerData={fetchResellerData} />
                <ResellerEditTabs />
                <PageContent>
                    <Form onSubmit={handleSubmit(this.onSubmit)}>
                        <Form.Column>
                            <Form.Row>
                                <Field
                                    name="company"
                                    id="company"
                                    fieldProps={{
                                        label: (
                                            <FormattedMessage
                                                id="ResellerEdit.Company"
                                                defaultMessage="Company"
                                            />
                                        ),
                                        options: {
                                            endpoint: '/api/companies',
                                            mapData: {
                                                value: 'iri',
                                                label: 'name'
                                            }
                                        },
                                        clearable: true
                                    }}
                                    component={Autocomplete}
                                    validate={valueValidation([{ validator: 'required' }])}
                                    fieldAttr={{ required: true }}
                                />
                            </Form.Row>
                            <Form.Row>
                                <Field
                                    name="contact_number"
                                    id="contact_number"
                                    fieldProps={{
                                        label: (
                                            <FormattedMessage
                                                id="ResellerEdit.Contact"
                                                defaultMessage="Contact"
                                            />
                                        ),
                                        clearable: true
                                    }}
                                    component={Phone}
                                    validate={valueValidation([{ validator: 'required' }])}
                                    fieldAttr={{ required: true }}
                                />
                            </Form.Row>
                            <Form.Row>
                                <Field
                                    name="email"
                                    id="email"
                                    fieldProps={{
                                        label: (
                                            <FormattedMessage
                                                id="ResellerEdit.Email"
                                                defaultMessage="Email Address"
                                            />
                                        ),
                                        clearable: true
                                    }}
                                    component={Email}
                                    validate={valueValidation([
                                        { validator: 'required' },
                                        { validator: 'email' }
                                    ])}
                                    fieldAttr={{ required: true }}
                                />
                            </Form.Row>
                            <Form.Row>
                                <Field
                                    name="reseller_name"
                                    id="reseller_name"
                                    fieldProps={{
                                        label: (
                                            <FormattedMessage
                                                id="ResellerEdit.Name"
                                                defaultMessage="Reseller Name"
                                            />
                                        ),
                                        clearable: true
                                    }}
                                    component={Text}
                                    validate={valueValidation([{ validator: 'required' }])}
                                    fieldAttr={{ required: true }}
                                />
                            </Form.Row>
                            <Form.Row>
                                <Button
                                    submit
                                    disabled={pristine || invalid}
                                    loading={submitting}
                                    label={
                                        <FormattedMessage
                                            id="Reseller.Update"
                                            defaultMessage="Update"
                                        />
                                    }
                                />
                            </Form.Row>
                        </Form.Column>
                    </Form>
                </PageContent>
            </LayoutManager>
        );
    }
}

GeneralInfo.defaultProps = {
    submitting: false,
    pristine: false,
    invalid: false,
    fetchResellerData: () => {},
    singleFetching: false,
    initialValues: {}
};

GeneralInfo.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.oneOfType([PropTypes.object]).isRequired,
    initialValues: PropTypes.oneOfType([PropTypes.object]),
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    fetchResellerData: PropTypes.func,
    singleFetching: PropTypes.bool
};

GeneralInfo = reduxForm({
    form: 'GeneralInfoForm',
    enableReinitialize: true
})(GeneralInfo);

//  Getting initial value populated in the form from the store
const mapStateToProps = (state, ownProps) => ({
    initialValues: dtoForm(getResellerData(state, ownProps.match.params.id)),
    singleFetching: getSingleResellerFetching(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchResellerData: () => {
        // Getting included data from API by setting params
        const params = {
            include:
                'company,resellerPrimaryAccount,eventsIncluded,eventsExcluded,eventDescriptions,eventDescriptions.event'
        };
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: resellersActions.START_FETCHING_SINGLE_RESELLER,
                id: ownProps.match.params.id,
                params
            });
        }).catch(error => ({ error }));
    }
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(GeneralInfo)
);
