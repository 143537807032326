import { takeLatest, put, call, all } from 'redux-saga/effects';
import dto from 'erputils/dto';
import restClient from 'erpcore/api/restClient';
import { actions as notificationManagerActions } from 'erputils/NotificationManager/NotificationManager.reducer';
import { actions as getOutApiActions } from './GetOutAPI.reducer';

/**
 * Sync GetOut
 * @param  {Object} promise
 * @return {string} id Event id
 */
export function* syncGetOutApi({ promise }) {
    try {
        const syncGetOutApiAPI = yield restClient.get(`/api/orders/import/crave`);
        yield put({
            type: getOutApiActions.SYNC_GET_OUT_API_SUCCESSFUL
        });
        yield put({
            type: getOutApiActions.STORE_GET_OUT_API_DATA,
            response: syncGetOutApiAPI
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: { code: 'getout.syncSuccessfullyStarted' }
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: getOutApiActions.SYNC_GET_OUT_API_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}
/**
 * Fetch GetOut
 * @param  {Object} promise
 * @return {string} id Event id
 */
export function* fetchGetOutApi({ promise }) {
    try {
        const responses = yield all([
            restClient.get(`/api/general-settings/crave-auto-pull`),
            restClient.get(`/api/general-settings/crave-create-from-import`),
            restClient.get(`/api/general-settings/crave-import-since-last-import`),
            restClient.get(`/api/general-settings/crave-interval-minutes`),
            restClient.get(`/api/general-settings/crave-import-from-date`),
            restClient.get(`/api/general-settings/crave-import-only-events`)
        ]);

        const fetchGetOutApiUrlAPI = responses[0];
        const fetchGetOutApiCreateFromImportAPI = responses[1];
        const fetchGetOutApiImportSinceAPI = responses[2];
        const fetchGetOutApiImportIntervalDataAPI = responses[3];
        const fetchGetOutApiImportFromDataAPI = responses[4];
        const fetchGetOutApiImportOnlyEventsDataAPI = responses[5];

        yield all([
            put({
                type: getOutApiActions.STORE_GET_OUT_API_CREATE_NEW_DATA,
                response: dto(fetchGetOutApiCreateFromImportAPI.data)
            }),
            put({
                type: getOutApiActions.STORE_GET_OUT_API_AUTOMATED_SYNC_DATA,
                response: dto(fetchGetOutApiUrlAPI.data)
            }),
            put({
                type: getOutApiActions.STORE_GET_OUT_API_SINCE_LAST_IMPORT_DATA,
                response: dto(fetchGetOutApiImportSinceAPI.data)
            }),
            put({
                type: getOutApiActions.STORE_GET_OUT_API_IMPORT_INTERVAL_DATA,
                response: dto(fetchGetOutApiImportIntervalDataAPI.data)
            }),
            put({
                type: getOutApiActions.STORE_GET_OUT_API_IMPORT_FROM_DATA,
                response: dto(fetchGetOutApiImportFromDataAPI.data)
            }),
            put({
                type: getOutApiActions.STORE_GET_OUT_API_IMPORT_ONLY_EVENTS_DATA,
                response: dto(fetchGetOutApiImportOnlyEventsDataAPI.data)
            }),
            put({
                type: getOutApiActions.FETCHING_GET_OUT_API_SUCCESSFUL
            })
        ]);

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: getOutApiActions.FETCHING_GET_OUT_API_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}
/**
 * Update GetOut
 * @param  {Object} promise
 * @return {string}
 */
export function* updateGetOutApi({ promise, formData, property }) {
    try {
        if (property === 'autopull') {
            const updateGetOutApiUrlAPI = yield restClient.put(
                `/api/general-settings/crave-auto-pull`,
                formData
            );
            yield put({
                type: getOutApiActions.UPDATE_GET_OUT_API_SUCCESSFUL
            });
            yield put({
                type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                response: updateGetOutApiUrlAPI.data
            });
            yield put({
                type: getOutApiActions.STORE_GET_OUT_API_AUTOMATED_SYNC_DATA,
                response: dto(updateGetOutApiUrlAPI.data)
            });
        }
        if (property === 'createfrom') {
            const updateGetOutApiCreateFromImportAPI = yield restClient.put(
                `/api/general-settings/crave-create-from-import`,
                formData
            );
            yield put({
                type: getOutApiActions.UPDATE_GET_OUT_API_SUCCESSFUL
            });
            yield put({
                type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                response: updateGetOutApiCreateFromImportAPI.data
            });
            yield put({
                type: getOutApiActions.STORE_GET_OUT_API_CREATE_NEW_DATA,
                response: dto(updateGetOutApiCreateFromImportAPI.data)
            });
        }
        if (property === 'importsince') {
            const updateGetOutApiImportSinceAPI = yield restClient.put(
                `/api/general-settings/crave-import-since-last-import`,
                formData
            );
            yield put({
                type: getOutApiActions.UPDATE_GET_OUT_API_SUCCESSFUL
            });
            yield put({
                type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                response: updateGetOutApiImportSinceAPI.data
            });
            yield put({
                type: getOutApiActions.STORE_GET_OUT_API_SINCE_LAST_IMPORT_DATA,
                response: dto(updateGetOutApiImportSinceAPI.data)
            });
        }
        if (property === 'interval') {
            const updateGetOutApiImportIntervalAPI = yield restClient.put(
                `/api/general-settings/crave-interval-minutes`,
                formData
            );
            yield put({
                type: getOutApiActions.UPDATE_GET_OUT_API_SUCCESSFUL
            });
            yield put({
                type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                response: updateGetOutApiImportIntervalAPI.data
            });
            yield put({
                type: getOutApiActions.STORE_GET_OUT_API_IMPORT_INTERVAL_DATA,
                response: dto(updateGetOutApiImportIntervalAPI.data)
            });
        }
        if (property === 'importOnlyEvents') {
            const updateGetOutApiImportOnlyEventsAPI = yield restClient.put(
                `/api/general-settings/crave-import-only-events`,
                formData
            );
            yield put({
                type: getOutApiActions.UPDATE_GET_OUT_API_SUCCESSFUL
            });
            yield put({
                type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                response: updateGetOutApiImportOnlyEventsAPI.data
            });
            yield put({
                type: getOutApiActions.STORE_GET_OUT_API_IMPORT_ONLY_EVENTS_DATA,
                response: dto(updateGetOutApiImportOnlyEventsAPI.data)
            });
        }
        if (property === 'importFrom') {
            const updateGetOutApiImportFromAPI = yield restClient.put(
                `/api/general-settings/crave-import-from-date`,
                formData
            );
            yield put({
                type: getOutApiActions.UPDATE_GET_OUT_API_SUCCESSFUL
            });
            yield put({
                type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                response: updateGetOutApiImportFromAPI.data
            });
            yield put({
                type: getOutApiActions.STORE_GET_OUT_API_IMPORT_FROM_DATA,
                response: dto(updateGetOutApiImportFromAPI.data)
            });
        }

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: getOutApiActions.UPDATE_GET_OUT_API_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

export const getOutApiSaga = [
    takeLatest(getOutApiActions.START_SYNC_GET_OUT_API, syncGetOutApi),
    takeLatest(getOutApiActions.START_UPDATE_GET_OUT_API, updateGetOutApi),
    takeLatest(getOutApiActions.START_FETCHING_GET_OUT_API, fetchGetOutApi)
];
