import React from 'react';
import PropTypes from 'prop-types';
import generateKey from 'erputils/generateKey';
import { Field, reduxForm } from 'redux-form';
import valueValidation from 'erputils/valueValidation';
import Form, {
    Text,
    Textarea,
    TextEditor,
    ImageUpload,
    Hidden,
    Number,
    Email,
    Radio,
    RadioGroup,
    Checkbox,
    CheckboxGroup,
    Password,
    Switch,
    Select,
    DateTime,
    Location,
    Autocomplete,
    MultiAutocomplete
} from 'erpcore/components/Form';
import FilterDateRange from 'erpcore/components/Listing/components/Filter/components/FilterDateRange';

//  List of fields that can be used in Form Generator
export const stringToFormFieldComponent = {
    text: Text,
    email: Email,
    password: Password,
    number: Number,
    select: Select,
    radio: Radio,
    radioGroup: RadioGroup,
    checkbox: Checkbox,
    checkboxGroup: CheckboxGroup,
    switch: Switch,
    hidden: Hidden,
    textarea: Textarea,
    textEditor: TextEditor,
    imageUpload: ImageUpload,
    dateTime: DateTime,
    location: Location,
    autocomplete: Autocomplete,
    multiautocomplete: MultiAutocomplete,
    dateRange: FilterDateRange
};

const FormGenerator = ({ fields, handleSubmit, onSubmit }) => (
    <form onSubmit={handleSubmit(onSubmit)}>
        {Object.keys(fields.properties).map(key => {
            const field = fields.properties[key];
            return (
                <Form.Row key={generateKey(field.component + key)}>
                    <Field
                        name={key}
                        field={field}
                        fieldProps={field.fieldProps}
                        fieldAttr={field.fieldAttr}
                        component={stringToFormFieldComponent[field.component]}
                        validate={valueValidation(field.fieldValidation)}
                    />
                </Form.Row>
            );
        })}
    </form>
);

FormGenerator.defaultProps = {
    fields: {}
};

FormGenerator.propTypes = {
    fields: PropTypes.oneOfType([PropTypes.object]),
    onSubmit: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired
};

export default reduxForm({
    enableReinitialize: true
})(FormGenerator);
