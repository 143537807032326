export const actions = {
    SET_PAGE_NOTIFICATION: 'SET_PAGE_NOTIFICATION',
    REMOVE_PAGE_NOTIFICATION: 'REMOVE_PAGE_NOTIFICATION',
    SET_FLOATING_NOTIFICATION: 'SET_FLOATING_NOTIFICATION',
    ADD_FLOATING_NOTIFICATION: 'ADD_FLOATING_NOTIFICATION',
    REMOVE_FLOATING_NOTIFICATION: 'REMOVE_FLOATING_NOTIFICATION',
    REMOVE_FLOATING_NOTIFICATIONS: 'REMOVE_FLOATING_NOTIFICATIONS'
};

export const initialState = {
    pageNotification: null,
    floatingNotifications: []
};

export default (state = initialState, { type, response, index }) => {
    switch (type) {
        case actions.SET_PAGE_NOTIFICATION: {
            return {
                ...state,
                pageNotification: response
            };
        }
        case actions.REMOVE_PAGE_NOTIFICATION: {
            return {
                ...state,
                pageNotification: null
            };
        }
        case actions.SET_FLOATING_NOTIFICATION: {
            return {
                ...state,
                floatingNotifications: [response]
            };
        }
        case actions.ADD_FLOATING_NOTIFICATION: {
            const floatingNotifications = [...state.floatingNotifications];
            floatingNotifications.unshift(response);
            return {
                ...state,
                floatingNotifications
            };
        }
        case actions.REMOVE_FLOATING_NOTIFICATION: {
            const floatingNotifications = [...state.floatingNotifications];
            floatingNotifications.splice(index, 1);
            return {
                ...state,
                floatingNotifications
            };
        }
        case actions.REMOVE_FLOATING_NOTIFICATIONS: {
            return {
                ...state,
                floatingNotifications: []
            };
        }
        default:
            return state;
    }
};
