import { takeLatest, delay, put, call, fork, take } from 'redux-saga/effects';
import { channel } from 'redux-saga';
import restClient from 'erpcore/api/restClient';
import { actions as notificationManagerActions } from 'erputils/NotificationManager/NotificationManager.reducer';
import { actions as eventsSortActions } from 'erpcore/screens/Settings/screens/ResellerAdmin/SortEvents/SortEvents.reducer';

/**
 * Update EventSort single data
 * @param  promise {Object}
 * @param  formData {Object}
 * @param  id {number} ticketType id
 * @param  debounceDelay {number} Number of milliseconds to delay execution
 * @return {void}
 */
export function* updateEventsSortOrder({ promise, formData, debounceDelay = 0 }) {
    yield delay(debounceDelay);
    yield put({
        type: eventsSortActions.START_UPDATE_EVENTS_SORT_ORDER
    });
    try {
        const updateEventsSortOrderAPI = yield restClient.put(
            `api/general-settings/reseller-events-sort`,
            formData
        );
        yield put({
            type: eventsSortActions.UPDATE_EVENTS_SORT_ORDER_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateEventsSortOrderAPI.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: eventsSortActions.UPDATE_EVENTS_SORT_ORDER_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

function takeLatestPerProps(propsOrSelector, pattern, worker, ...args) {
    // Not a generator
    return fork(function* generator() {
        // Fork a generator here to make it work like takeLatest
        const channelsMap = {};
        while (true) {
            const action = yield take(pattern); // yield necessary here
            const propsValue =
                typeof propsOrSelector === 'function'
                    ? propsOrSelector(action)
                    : action[propsOrSelector];
            if (!channelsMap[propsValue]) {
                channelsMap[propsValue] = channel();
                yield takeLatest(channelsMap[propsValue], worker, ...args);
            }
            yield put(channelsMap[propsValue], action);
        }
    });
}

/**
 * Register action to watcher
 */
export const eventsSortSaga = [
    takeLatestPerProps(
        'id',
        eventsSortActions.REQUEST_UPDATE_EVENTS_SORT_ORDER,
        updateEventsSortOrder
    )
    // takeLatest(headerMenuActions.START_DELETE_SINGLE_TICKET_TYPE, deleteSingleTicketType)
];
