import React from 'react';
import { Field } from 'redux-form';
import Button from 'erpcomponents/Button';
import Form, { Text, Select, MultiSelect } from 'erpcore/components/Form';
import Collapse from 'erpcore/components/Collapse';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import valueValidation from 'erputils/valueValidation';
// import { resolveObjectPathByString } from 'erputils/utils';
// import parseInteger from 'erputils/parseInteger';
import { actions as tableTemplatesActions } from 'erpcore/screens/Venues/screens/VenuesEdit/TableTemplates/TableTemplates.reducer';
import './EventTablesRepeaterItem.scss';
import MergeTablesModal from 'erpcore/screens/Events/screens/EventManage/components/MergeTablesModal';

const tableFilter = (tables, member) => {
    if (member && tables) {
        const id = member.match(/\d+/g)[0];
        const tableToRemove = tables[id] && tables[id];
        if (tableToRemove) {
            return tables
                .filter(table => table.id !== tableToRemove.id)
                .map(t => ({ value: t.iri, label: t.table_number }));
        }
        return tables.map(table => ({ value: table.iri, label: table.table_number }));
    }
    return [];
};

const roomsParser = rooms => {
    if (rooms) {
        return rooms.map(room => ({ value: room.iri, label: room.name }));
    }
    return [];
};

class TableTemplateRepeaterItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pendingUpdates: {},
            mergeTablesModal: false
        };

        this.debounceDelay = 1000;
    }

    handleModal = () => {
        const { mergeTablesModal } = this.state;
        this.setState({ mergeTablesModal: !mergeTablesModal });
    };

    dispatchUpdate = updates => {
        const { dispatch, data } = this.props;
        const { id = false } = data;
        if (updates.min_seats) updates.min_seats = parseInt(updates.min_seats, 10);
        if (updates.max_seats) updates.max_seats = parseInt(updates.max_seats, 10);
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: tableTemplatesActions.REQUEST_UPDATE_SINGLE_TABLE,
                formData: updates,
                id,
                debounceDelay: this.debounceDelay
            })
        )
            .catch(() => {
                this.removePendingUpdates();
            })
            .then(() => {
                this.removePendingUpdates();
            });
    };

    handleChange = (event, newValue, previousValue, name) => {
        const { member, isNewRepeaterItem } = this.props;

        if (!isNewRepeaterItem) {
            let nameForApi = name.replace(`${member}.`, '');
            if (nameForApi === 'room.iri') {
                nameForApi = 'room';
            }
            const updates = { [nameForApi]: newValue };
            const { pendingUpdates } = this.state;
            const mergedUpdates = { ...pendingUpdates, ...updates };
            this.setState({ pendingUpdates: mergedUpdates });
            this.dispatchUpdate(mergedUpdates);
        }
    };

    removePendingUpdates() {
        this.setState({ pendingUpdates: {} });
    }

    isValid(validatorFunctions = [], value) {
        const errors = validatorFunctions.map(validatorFunction => {
            return validatorFunction(value);
        });
        const isNotValid = errors.find(item => item !== undefined);

        return !isNotValid;
    }

    render() {
        const { member, isNewRepeaterItem, data } = this.props;
        // const { match } = data;
        const { venueRooms } = data;
        const { initialValues } = data;
        const { mergeTablesModal } = this.state;
        const allTables = initialValues && initialValues.tables && initialValues.tables;
        return (
            <Collapse initiallyExpanded={!!isNewRepeaterItem} hideToggle={!!isNewRepeaterItem}>
                {!!isNewRepeaterItem && <h3>Add New Table</h3>}
                <Collapse.Visible>
                    {mergeTablesModal && (
                        <MergeTablesModal allTables={allTables && allTables} member={member} />
                    )}
                    <Form.Row>
                        <Form.Columns breakOnMobile>
                            <Form.Column>
                                <Field
                                    name={`${member}.table_number`}
                                    id="name"
                                    fieldProps={{
                                        label: 'Table Name'
                                    }}
                                    fieldAttr={{ required: true }}
                                    component={Text}
                                    // validate={valueValidation([{ validator: 'required' }])}
                                    onChange={(event, newValue, previousValue, name) => {
                                        /*
                                         * redux-form validation is started after the Field onChange is done,
                                         * so the validity of the field has to be checked manually
                                         * before handling the change data further
                                         */
                                        if (
                                            this.isValid(
                                                valueValidation([{ validator: 'required' }]),
                                                newValue
                                            )
                                        ) {
                                            this.handleChange(event, newValue, previousValue, name);
                                        }
                                    }}
                                />
                            </Form.Column>

                            <Form.Column autoWidth>
                                <Field
                                    name={`${member}.min_seats`}
                                    id="min_seats"
                                    fieldProps={{
                                        label: 'Minimum Seats'
                                    }}
                                    component={Text}
                                    validate={valueValidation([{ validator: 'numericality' }])}
                                    onChange={(event, newValue, previousValue, name) => {
                                        /*
                                         * redux-form validation is started after the Field onChange is done,
                                         * so the validity of the field has to be checked manually
                                         * before handling the change data further
                                         */
                                        if (
                                            this.isValid(
                                                valueValidation([
                                                    { validator: 'numericality' },
                                                    { validator: 'required' }
                                                ]),
                                                newValue
                                            )
                                        ) {
                                            this.handleChange(event, newValue, previousValue, name);
                                        }
                                    }}
                                    fieldAttr={{ required: true }}
                                />
                                <Field
                                    name={`${member}.max_seats`}
                                    id="max_seats"
                                    fieldProps={{
                                        label: 'Maximum Seats'
                                    }}
                                    component={Text}
                                    validate={valueValidation([{ validator: 'numericality' }])}
                                    onChange={(event, newValue, previousValue, name) => {
                                        /*
                                         * redux-form validation is started after the Field onChange is done,
                                         * so the validity of the field has to be checked manually
                                         * before handling the change data further
                                         */
                                        if (
                                            this.isValid(
                                                valueValidation([
                                                    {
                                                        validator: 'numericality'
                                                    },
                                                    {
                                                        validator: 'required'
                                                    }
                                                ]),
                                                newValue
                                            )
                                        ) {
                                            this.handleChange(event, newValue, previousValue, name);
                                        }
                                    }}
                                    fieldAttr={{ required: true }}
                                />
                            </Form.Column>
                            <Form.Column>
                                <Field
                                    name={`${member}.room`}
                                    id="room"
                                    component={Select}
                                    fieldProps={{
                                        label: 'Room',
                                        options: roomsParser(venueRooms)
                                    }}
                                    onChange={this.handleChange}
                                />
                            </Form.Column>
                        </Form.Columns>
                    </Form.Row>
                </Collapse.Visible>
                <Collapse.Expandable>
                    <Form.Columns>
                        <Form.Column className="event-tables-repeater-item__can-be-grouped-with">
                            <Field
                                name={`${member}.can_be_grouped_with`}
                                id="can_be_grouped_with"
                                variation="row"
                                component={MultiSelect}
                                fieldProps={{
                                    label: 'Can be grouped with',
                                    options: tableFilter(allTables, member)
                                }}
                                onChange={this.handleChange}
                            />
                        </Form.Column>
                        {!isNewRepeaterItem && (
                            <Form.Column>
                                <Button
                                    variation="tertiary"
                                    className="event-tables-repeater-item__merge-tables__button"
                                    size="small"
                                    label="Merge"
                                    labelOnlyAria
                                    iconName="merge"
                                    onClick={() => this.handleModal()}
                                />
                            </Form.Column>
                        )}
                    </Form.Columns>
                </Collapse.Expandable>
            </Collapse>
        );
    }
}

TableTemplateRepeaterItem.defaultProps = {
    member: null,
    isNewRepeaterItem: null,
    data: null
};

TableTemplateRepeaterItem.propTypes = {
    dispatch: PropTypes.func.isRequired,
    member: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isNewRepeaterItem: PropTypes.bool,
    data: PropTypes.oneOfType([PropTypes.object])
};

export default connect()(TableTemplateRepeaterItem);
