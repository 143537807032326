import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reduxForm, Field } from 'redux-form';
import { dtoForm } from 'erputils/dto';
import { FormattedMessage } from 'react-intl';
import Form, { MultiAutocomplete } from 'erpcore/components/Form';
import LayoutManager from 'erputils/LayoutManager';
import PageHeader from 'erpcomponents/Layout/PageHeader';
import PageContent from 'erpcomponents/Layout/PageContent';
import EventInfo from 'erpcore/screens/Events/components/EventInfo';
import EventManageTabs from 'erpcore/screens/Events/components/EventManageTabs';
import PageLoader from 'erpcomponents/PageLoader';
import { actions as eventsActions } from 'erpcore/screens/Events/Events.reducer';
import { getEventData, getSingleEventFetching } from 'erpcore/screens/Events/Events.selectors';
import Button from 'erpcomponents/Button';

class EventProduction extends Component {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        const { fetchEventData } = this.props;
        fetchEventData();
    }

    onChange(formData) {
        const { match, dispatch } = this.props;

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: eventsActions.START_UPDATE_SINGLE_EVENT,
                id: match.params.id,
                formData
            })
        ).catch(error => {
            return error;
        });
    }

    render() {
        const { singleFetching, pristine, invalid, submitting } = this.props;

        return (
            <LayoutManager slot="main" className="main--narrow" layoutType="merge">
                <PageHeader
                    title={
                        <FormattedMessage
                            id="EventEdit.Production.title"
                            defaultMessage="Edit Event"
                        />
                    }
                />
                {singleFetching === true && <PageLoader />}
                <EventInfo />
                <EventManageTabs />
                <PageContent>
                    <Form>
                        <Form.SectionTitle>
                            <FormattedMessage
                                id="EventEdit.Production.SectionTitle.AssignTableManagers"
                                defaultMessage="Assign Table Managers"
                            />
                        </Form.SectionTitle>
                        <Form.Row>
                            <Field
                                name="assign_table_managers"
                                id="assign_table_managers"
                                fieldProps={{
                                    label: 'Table Managers',
                                    options: {
                                        endpoint: '/api/users',
                                        mapData: {
                                            value: 'id',
                                            label: '{first_name} {last_name}'
                                        }
                                    },
                                    clearable: true
                                }}
                                component={MultiAutocomplete}
                            />
                        </Form.Row>
                        <Form.SectionTitle>
                            <FormattedMessage
                                id="EventEdit.Production.SectionTitle.AssignEventManagers"
                                defaultMessage="Assign Event Managers"
                            />
                        </Form.SectionTitle>
                        <Form.Row>
                            <Field
                                name="assign_event_managers"
                                id="assign_event_managers"
                                fieldProps={{
                                    label: 'Event Managers',
                                    options: {
                                        endpoint: '/api/users',
                                        mapData: {
                                            value: 'id',
                                            label: '{first_name} {last_name}'
                                        }
                                    },
                                    clearable: true
                                }}
                                component={MultiAutocomplete}
                            />
                        </Form.Row>
                    </Form>
                    <Form.Row>
                        <Button
                            submit
                            disabled={pristine || invalid}
                            loading={submitting}
                            label={
                                <FormattedMessage
                                    id="EventEdit.Production.Update"
                                    defaultMessage="Update"
                                />
                            }
                        />
                    </Form.Row>
                </PageContent>
            </LayoutManager>
        );
    }
}

EventProduction.defaultProps = {
    fetchEventData: () => {},
    singleFetching: false,
    submitting: false,
    pristine: false,
    invalid: false
};

EventProduction.propTypes = {
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.oneOfType([PropTypes.object]).isRequired,
    fetchEventData: PropTypes.func,
    singleFetching: PropTypes.bool,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool
};

EventProduction = reduxForm({
    form: 'EventProductionForm',
    enableReinitialize: true
})(EventProduction);

//  Getting initial value populated in the form from the store
const mapStateToProps = (state, ownProps) => ({
    initialValues: dtoForm(getEventData(state, ownProps.match.params.id)),
    singleFetching: getSingleEventFetching(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchEventData: () => {
        // Getting included data from API by setting params
        const params = {
            include: 'organization,location,location.country,location.state,location.city'
        };
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: eventsActions.START_FETCHING_SINGLE_EVENT,
                id: ownProps.match.params.id,
                params
            });
        }).catch(error => ({ error }));
    }
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(EventProduction)
);
