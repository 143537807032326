/* eslint-disable no-bitwise */
import React from 'react';
import PropTypes from 'prop-types';
// import { defaults } from 'react-chartjs-2';
import { FormattedNumber } from 'react-intl';
import Tooltip from 'erpcore/components/Tooltip';
import DoughnutChart from './Doughnut';
import PieChart from './Pie';
import './Chart.scss';

// Default charts options
// Don't change global options, there are multiple use cases of chart.js, options should be forwarded to each use case individually
// defaults.global.tooltips.enabled = false;
// defaults.global.legend.display = false;
// defaults.doughnut.cutoutPercentage = 70;

const formattedNumberStyle = {
    currency: 'currency',
    percent: 'percent'
};

const handleStringToColor = labels => {
    const finalColor = [];
    labels.forEach(e => {
        let hash = 0;
        for (let i = 0; i < e.length; i += 1) {
            hash = e.charCodeAt(i) + ((hash << 5) - hash);
        }
        let color = '#';
        for (let i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.substr(-2);
        }
        finalColor.push(color);
    });
    return finalColor;
};

const handleGetColor = (color, labels) => {
    switch (color) {
        case 'primary':
            return ['#de102d', '#f47575'];
        case 'secondary':
            return ['#779ee0', '#cad9f3'];
        case 'tertiary':
            return ['#81dbbe', '#d0f2e7'];
        default:
            return handleStringToColor(labels);
    }
};

const handlePercentage = data => {
    const total = data.reduce((a, b) => a + b, 0);
    const percentages = [];
    data.forEach(e => {
        percentages.push(parseFloat(((e * 100) / total).toFixed(2)));
    });

    return percentages;
};

const handleChartData = (data, labels, color, showPercentage) => {
    let wholeData = data;
    if (showPercentage) {
        wholeData = handlePercentage(wholeData);
    }

    const [partNumber, wholeNumber] = wholeData;
    const chartColors = handleGetColor(color, labels);

    const chartData = {
        labels,
        datasets: [
            {
                data: [partNumber, wholeNumber - partNumber],
                backgroundColor: chartColors
            }
        ]
    };

    return chartData;
};

const handleTooltipContent = (data, labels, tooltipTitle, isCurrency) => {
    const [partNumber, wholeNumber] = data;

    const content = (
        <div className="chart-tooltip">
            {tooltipTitle && <h4 className="chart-tooltip__title">{tooltipTitle}</h4>}
            <div className="chart-tooltip__body">
                <div className="chart-tooltip__data-container">
                    <div className="chart-tooltip__label">
                        <span>{labels[0]}</span>
                    </div>
                    <div className="chart-tooltip__data">
                        <h1 style={{ marginBottom: 0 }}>
                            {isCurrency ? (
                                <FormattedNumber
                                    value={partNumber}
                                    style={formattedNumberStyle.currency}
                                    currency="USD"
                                />
                            ) : (
                                partNumber
                            )}
                            {wholeNumber && (
                                <span className="font-4">
                                    /
                                    {isCurrency ? (
                                        <FormattedNumber
                                            value={wholeNumber}
                                            style={formattedNumberStyle.currency}
                                            currency="USD"
                                        />
                                    ) : (
                                        wholeNumber
                                    )}
                                </span>
                            )}
                        </h1>
                    </div>
                </div>
                {wholeNumber && (
                    <div className="chart-tooltip__data-container">
                        <div className="chart-tooltip__label">
                            <p className="font-list" style={{ marginBottom: 0 }}>
                                {labels[1]}
                            </p>
                        </div>
                        <div className="chart-tooltip__data">
                            <h1 style={{ marginBottom: 0 }}>
                                <FormattedNumber
                                    value={partNumber / wholeNumber}
                                    style={formattedNumberStyle.percent}
                                    maximumFractionDigits={2}
                                />
                            </h1>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );

    return content;
};

const handleChartLegend = (data, isCurrency, showPercentage) => {
    const [partNumber, wholeNumber] = data;

    if (isCurrency) {
        return (
            <FormattedNumber
                value={partNumber}
                style={formattedNumberStyle.currency}
                currency="USD"
            />
        );
    }
    if (showPercentage) {
        return (
            <FormattedNumber
                value={partNumber / wholeNumber}
                style={formattedNumberStyle.percent}
                maximumFractionDigits={2}
            />
        );
    }
    return <FormattedNumber value={partNumber} />;
};
const handleGenerateChart = chartData => {
    const { chartDataSets, type } = chartData;
    switch (type) {
        case 'pie':
            return <PieChart data={chartDataSets} />;
        case 'doughnut':
            return <DoughnutChart data={chartDataSets} />;
        default:
            return <DoughnutChart data={chartDataSets} />;
    }
};

const Chart = ({
    data,
    labels,
    type,
    showLegend,
    color,
    showPercentage,
    tooltipTitle,
    isCurrency,
    tooltipMode
}) => {
    const chartDataSets = handleChartData(data, labels, color, showPercentage);
    const tooltipContent = handleTooltipContent(data, labels, tooltipTitle, isCurrency);
    const legendData = handleChartLegend(data, isCurrency, showPercentage);

    return (
        <Tooltip content={tooltipContent} mode={tooltipMode}>
            <div className="chart__pie-container">
                {handleGenerateChart({ chartDataSets, type })}
                {showLegend && <span className="chart__legend">{legendData}</span>}
            </div>
        </Tooltip>
    );
};

Chart.defaultProps = {
    type: 'doughnut',
    showLegend: true,
    color: '',
    showPercentage: false,
    tooltipTitle: '',
    isCurrency: false,
    tooltipMode: 'light'
};

Chart.propTypes = {
    data: PropTypes.arrayOf(PropTypes.number).isRequired,
    labels: PropTypes.arrayOf(PropTypes.string).isRequired,
    type: PropTypes.oneOf(['pie', 'doughnut']),
    showLegend: PropTypes.bool,
    color: PropTypes.string,
    showPercentage: PropTypes.bool,
    tooltipTitle: PropTypes.string,
    isCurrency: PropTypes.bool,
    tooltipMode: PropTypes.oneOf(['dark', 'light'])
};

export default Chart;
