import React, { Component } from 'react';
import Form, { Text } from 'erpcore/components/Form';
import { FormattedMessage } from 'react-intl';
import { Field, reduxForm, SubmissionError, reset } from 'redux-form';
import Button from 'erpcomponents/Button';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import reduxFormErrorMapper from 'erputils/reduxFormErrorMapper';
import { actions as eventSeriesActions } from 'erpcore/screens/Settings/screens/AccountingCategories/EventSeries/EventSeries.reducer';

class EventSeriesCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { handleSubmit, submitting, onSubmit, pristine, invalid } = this.props;
        return (
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Row>
                    <Form.Columns breakOnMobile>
                        <Form.Column>
                            <Field
                                name="name"
                                id="name"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="Settings.EventSeries.EventSeries"
                                            defaultMessage="Event Series Name"
                                        />
                                    ),
                                    clearable: true,
                                    validation: 'required'
                                }}
                                component={Text}
                            />
                        </Form.Column>

                        <Form.Column>
                            <Button
                                submit
                                disabled={pristine || invalid}
                                loading={submitting}
                                label={
                                    <FormattedMessage
                                        id="Settings.EventSeries.FormButton.AddNew"
                                        defaultMessage="Add New"
                                    />
                                }
                            />
                        </Form.Column>
                    </Form.Columns>
                </Form.Row>
            </Form>
        );
    }
}

EventSeriesCreate.defaultProps = {
    submitting: false,
    pristine: false,
    invalid: false,
    onSubmit: () => {}
};

EventSeriesCreate.propTypes = {
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    onSubmit: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired
};

EventSeriesCreate = reduxForm({
    form: 'EventSeriesCreateForm',
    enableReinitialize: true
})(EventSeriesCreate);

const mapDispatchToProps = dispatch => ({
    onSubmit: formData => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: eventSeriesActions.START_CREATE_EVENT_SERIES,
                formData
            });
        })
            .then(() => {
                dispatch(reset('EventSeriesCreateForm'));
            })
            .catch(error => {
                throw new SubmissionError(reduxFormErrorMapper(error));
            });
    }
});

export default connect(
    null,
    mapDispatchToProps
)(EventSeriesCreate);
