import React from 'react';
import PropTypes from 'prop-types';
import generateKey from 'erpcore/utils/generateKey';
import './ShowingResults.scss';

const ShowingResults = ({ meta, onChangeResultsPerPage }) => {
    const options = [10];

    if (meta.totalItems > 10) {
        options.push(50);

        if (meta.totalItems > 50) {
            options.push(100);
        }
    }

    // Do not display block if there are less than 10 results
    if ((meta.totalItems && meta.totalItems <= 10) || !meta.totalItems) {
        return null;
    }

    return (
        <div className="showing-results">
            Showing
            <div className="showing-results__per-page">
                {options.map(option => (
                    <button
                        key={generateKey(option)}
                        onClick={() => onChangeResultsPerPage({ limit: option, page: 1 })}
                        type="button"
                        className={`showing-results__per-page-button${
                            String(meta.itemsPerPage) === String(option)
                                ? ' showing-results__per-page-button--active'
                                : ''
                        }`}
                    >
                        {option}
                    </button>
                ))}
            </div>
            <span className="showing-results__per-page-text">Results out of {meta.totalItems}</span>
        </div>
    );
};

ShowingResults.defaultProps = {
    onChangeResultsPerPage: () => {},
    meta: {}
};

ShowingResults.propTypes = {
    onChangeResultsPerPage: PropTypes.func,
    meta: PropTypes.oneOfType([PropTypes.object])
};

export default ShowingResults;
