import { createSelector } from 'reselect';

export const getData = (state, id) =>
    state.widgets && state.widgets.totalTickets24h && state.widgets.totalTickets24h[id];

export const getFetching = (state, id) =>
    state.widgets &&
    state.widgets.totalTickets24h &&
    state.widgets.totalTickets24h[id] &&
    state.widgets.totalTickets24h[id].fetching;

export default createSelector([getData, getFetching]);
