export const actions = {
    START_FETCHING_SCANNED_TICKETS_DATA: 'START_FETCHING_SCANNED_TICKETS_DATA',
    FETCHING_SCANNED_TICKETS_DATA_SUCCESSFUL: 'FETCHING_SCANNED_TICKETS_DATA_SUCCESSFUL',
    FETCHING_SCANNED_TICKETS_DATA_FAILED: 'FETCHING_SCANNED_TICKETS_DATA_FAILED',
    STORE_SCANNED_TICKETS_DATA: 'STORE_SCANNED_TICKETS_DATA'
};

export const initialState = {};

export default (state = initialState, { type, response, widgetId }) => {
    switch (type) {
        case actions.START_FETCHING_SCANNED_TICKETS_DATA: {
            return {
                ...state,
                [widgetId]: { ...state[widgetId], ...{ fetching: true } }
            };
        }
        case actions.FETCHING_SCANNED_TICKETS_DATA_SUCCESSFUL:
            return {
                ...state,
                [widgetId]: { ...state[widgetId], ...{ fetching: false } }
            };
        case actions.FETCHING_SCANNED_TICKETS_DATA_FAILED: {
            return {
                ...state,
                [widgetId]: { ...state[widgetId], ...{ fetching: false } }
            };
        }
        case actions.STORE_SCANNED_TICKETS_DATA: {
            return {
                ...state,
                [widgetId]: { ...state[widgetId], ...{ data: response } }
            };
        }
        default:
            return state;
    }
};
