import React, { Component } from 'react';
import LayoutManager from 'erputils/LayoutManager';
import PageHeader from 'erpcomponents/Layout/PageHeader';
import PageContent from 'erpcomponents/Layout/PageContent';
import { withRouter } from 'react-router-dom';
import SettingsTabs from 'erpcore/screens/Settings/components/SettingsTabs';
import PropTypes from 'prop-types';
import Listing from 'erpcomponents/Listing';
import { FormattedMessage } from 'react-intl';
import ListingTableActions from 'erpcomponents/Listing/components/TableActions';
import { connect } from 'react-redux';
import { getListingFetching, getListingResponse } from 'erpcomponents/Listing/Listing.selectors';
import { actions as listingActions } from 'erpcomponents/Listing/Listing.reducer';
import CategoryCreate from 'erpcore/screens/Settings/components/CategoryCreate';
import CategoryDelete from 'erpcore/screens/Settings/components/CategoryDelete';
import CategoryEdit from 'erpcore/screens/Settings/components/CategoryEdit';
import EventCategoryEdit from 'erpcore/screens/Settings/components/EventCategoryEdit';

const reducerName = 'eventCategories';

class EventCategories extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categoryId: null
        };
        this.toggleEdit = this.toggleEdit.bind(this);
        this.resetCategoryId = this.resetCategoryId.bind(this);
    }

    sortCategoriesByOrder = items => {
        const data = [];
        const mainCategories = [];

        items.map(el => {
            if (!el.parent) {
                return mainCategories.push(el);
            }
            return false;
        });

        mainCategories.map(el => {
            const categoryID = el.iri;
            data.push(el);

            items.map(element => {
                if (element.parent && element.parent === categoryID) {
                    return data.push(element);
                }
                return false;
            });
            return false;
        });
        return data;
    };

    prepareForSelect = data => {
        const updatedData = data.map(
            record => record.parent === null && { label: record.name, value: record.iri }
        );
        return updatedData.filter(record => record);
    };

    toggleEdit(id) {
        this.setState({
            categoryId: id
        });
    }

    resetCategoryId() {
        this.setState({ categoryId: null });
    }

    tableData() {
        const { categoriesData } = this.props;
        const table = {};
        table.data = [];

        // Table Schema
        table.schema = [
            {
                title: (
                    <FormattedMessage
                        id="Settings.EventCategory.Table.CategoryName"
                        defaultMessage="Category Name"
                    />
                ),
                field: 'eventName',
                sortable: 'name'
            },
            {
                title: (
                    <FormattedMessage
                        id="Settings.EventCategories.Table.Actions"
                        defaultMessage="Actions"
                    />
                ),
                field: 'actions'
            }
        ];

        const data = this.sortCategoriesByOrder(categoriesData.data);

        data.map(row => {
            return table.data.push({
                id: row.id,
                eventName: row.name,
                parent: row.parent ? row.parent : null,
                actions: (
                    <ListingTableActions>
                        <ListingTableActions.Action>
                            <EventCategoryEdit
                                id={row.id}
                                key={`EventEdit_${row.id}`}
                                form="EventEditForm"
                                parent={row.parent}
                                categoriesData={categoriesData}
                                categories={this.prepareForSelect(categoriesData.data)}
                                iri={row.iri}
                            />
                        </ListingTableActions.Action>
                        <ListingTableActions.Action>
                            <CategoryDelete
                                id={row.id}
                                key={`EventDelete_${row.id}`}
                                form="EventDeleteForm"
                                parent={row.parent}
                                categoriesData={categoriesData}
                                iri={row.iri}
                            />
                        </ListingTableActions.Action>
                    </ListingTableActions>
                )
            });
        });

        return table;
    }

    render() {
        const { fetchCategories, categoriesListingFetching, categoriesData } = this.props;
        const { categoryId } = this.state;
        return (
            <LayoutManager slot="main" className="main--narrow" layoutType="merge">
                <PageHeader title="Settings - Categories" />
                <SettingsTabs />
                <PageContent>
                    {!categoryId ? (
                        <CategoryCreate
                            categories={
                                categoriesData.data && this.prepareForSelect(categoriesData.data)
                            }
                        />
                    ) : (
                        <CategoryEdit
                            categories={
                                categoriesData.data && this.prepareForSelect(categoriesData.data)
                            }
                            resetId={this.resetCategoryId}
                            id={categoryId}
                        />
                    )}
                    <Listing
                        name={reducerName}
                        reducerName={reducerName}
                        loading={categoriesListingFetching}
                        hideSearch
                        meta={categoriesData.meta}
                        table={this.tableData()}
                        onListingConfigUpdate={params => fetchCategories(params)}
                    />
                </PageContent>
            </LayoutManager>
        );
    }
}

EventCategories.defaultProps = {
    categoriesData: {},
    categoriesListingFetching: false
};

EventCategories.propTypes = {
    categoriesData: PropTypes.oneOfType([PropTypes.object]),
    fetchCategories: PropTypes.func.isRequired,
    categoriesListingFetching: PropTypes.bool
};

const mapStateToProps = state => ({
    categoriesListingFetching: getListingFetching(state, 'eventCategories'),
    categoriesData: getListingResponse(state, 'eventCategories')
});

const mapDispatchToProps = dispatch => ({
    fetchCategories: params => {
        const listingParams = Object.assign({ pagination: false }, params);

        dispatch({
            type: listingActions.START_FETCHING_LISTING,
            params: listingParams,
            name: reducerName,
            entity: 'EVENT_CATEGORIES',
            endpoint: 'api/event-categories'
        });
    }
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(EventCategories)
);
