import React, { Component } from 'react';
import LayoutManager from 'erputils/LayoutManager';
import PageHeader from 'erpcomponents/Layout/PageHeader';
import PageContent from 'erpcomponents/Layout/PageContent';
import { withRouter } from 'react-router-dom';
import { FieldArray, reduxForm } from 'redux-form';
import SortEventsRepeaterItem from 'erpcore/screens/Settings/screens/ResellerAdmin/components/SortEventsRepeaterItem';
import ResellerAdminTabs from 'erpcore/screens/Settings/components/ResellerAdminTabs';
import PropTypes from 'prop-types';
import { dtoForm } from 'erputils/dto';
import Form, { Repeater } from 'erpcore/components/Form';
import NotificationManager from 'erputils/NotificationManager';
import { connect } from 'react-redux';
import { getListingFetching, getListingResponse } from 'erpcomponents/Listing/Listing.selectors';
import { actions as listingActions } from 'erpcomponents/Listing/Listing.reducer';
import { actions as eventsSortActions } from 'erpcore/screens/Settings/screens/ResellerAdmin/SortEvents/SortEvents.reducer';
// import reduxFormErrorMapper from 'erputils/reduxFormErrorMapper';
import { fetchingEventsSort } from 'erpcore/screens/Settings/screens/ResellerAdmin/SortEvents/SortEvents.selectors';

class SortEvents extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount = () => {
        const { fetchEventsSort } = this.props;
        fetchEventsSort();
    };

    handleSortEnd = formData => {
        const { dispatch } = this.props;
        const value = formData.allData.map((record, index) => ({
            event: record.event.data.id,
            position: index
        }));

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: eventsSortActions.REQUEST_UPDATE_EVENTS_SORT_ORDER,
                formData: {
                    event_sorts: value
                }
            })
        ).then(() => {
            this.handleAfterAnyEventsSortApiUpdate();
        });

        // .catch(error => {
        //     throw new SubmissionError(reduxFormErrorMapper(error));
        // });
    };

    handleAfterAnyEventsSortApiUpdate = () => {
        const { fetchEventsSort, reset } = this.props;

        fetchEventsSort();
        reset();
    };

    render() {
        const { sortEventsListingFetching, initialValues, error, fetching } = this.props;
        return (
            <LayoutManager slot="main" className="main--narrow" layoutType="merge">
                <PageHeader title="Settings - Reseller Admin" />
                <ResellerAdminTabs />
                <PageContent>
                    {error && error.code && <NotificationManager code={error.code} />}

                    <Form>
                        <FieldArray
                            name="value"
                            id="value"
                            canAdd={false}
                            canDelete={false}
                            component={Repeater}
                            RepeatableItem={SortEventsRepeaterItem}
                            loading={sortEventsListingFetching || fetching}
                            data={initialValues}
                            onSortStart={() => {}}
                            onSaveSortOrder={this.handleSortEnd}
                            uniqueIdentifier="position"
                            saveSortOrderLabel="Save order"
                            canSaveAllSortOrder
                        />
                    </Form>
                </PageContent>
            </LayoutManager>
        );
    }
}

SortEvents.defaultProps = {
    // headerMenusData: {},
    sortEventsListingFetching: false,
    fetching: false,
    error: null
};

SortEvents.propTypes = {
    // headerMenusData: PropTypes.oneOfType([PropTypes.object]),
    fetchEventsSort: PropTypes.func.isRequired,
    sortEventsListingFetching: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    initialValues: PropTypes.oneOfType([PropTypes.object]).isRequired,
    error: PropTypes.oneOfType([PropTypes.object]),
    fetching: PropTypes.bool
};

SortEvents = reduxForm({
    form: 'SortEventsForm',
    enableReinitialize: true,
    keepDirtyOnReinitialize: false, // important
    updateUnregisteredFields: false // important
})(SortEvents);

const mapStateToProps = state => ({
    sortEventsListingFetching: getListingFetching(state, 'eventsSort'),
    sortEventsData: dtoForm(getListingResponse(state, 'eventsSort')),
    initialValues: dtoForm(getListingResponse(state, 'eventsSort')),
    fetching: fetchingEventsSort(state, 'eventsSort')
});

const mapDispatchToProps = dispatch => ({
    fetchEventsSort: params => {
        const listingParams = Object.assign({}, params);

        dispatch({
            type: listingActions.START_FETCHING_LISTING,
            params: listingParams,
            entity: 'EVENTS_SORT',
            endpoint: '/api/general-settings/reseller-events-sort'
        });
    }
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(SortEvents)
);
