export const actions = {
    START_FETCHING: 'START_FETCHING',
    FETCHING_SUCCESSFUL: 'FETCHING_SUCCESSFUL',
    FETCHING_FAILED: 'FETCHING_FAILED',
    START_FETCHING_LISTING: 'START_FETCHING_LISTING',
    FETCHING_SUCCESSFUL_LISTING: 'FETCHING_SUCCESSFUL_LISTING',
    FETCHING_FAILED_LISTING: 'FETCHING_FAILED_LISTING',
    UPDATE_BULK_ACTIONS_IRIS: 'UPDATE_BULK_ACTIONS_IRIS',
    TOGGLE_BULK_ACTIONS_MODAL: 'TOGGLE_BULK_ACTIONS_MODAL',
    SET_QUERY_PARAMS: 'SET_QUERY_PARAMS'
};

export const initialState = {};

export default (state = initialState, { type, response, name, params }) => {
    switch (type) {
        case actions.SET_QUERY_PARAMS: {
            return {
                ...state,
                queryParams: response
            };
        }
        case actions.START_FETCHING_LISTING: {
            return {
                ...state,
                [name]: {
                    queryParams: params,
                    bulkActions: {
                        modalOpened: false,
                        selectAll: false,
                        iris: []
                    }
                }
            };
        }
        case actions.UPDATE_BULK_ACTIONS_IRIS: {
            const newState = Object.assign({}, state[name]);

            if (!Array.isArray(response)) {
                let newIris = newState?.bulkActions?.iris.slice();
                if (newIris?.includes(response)) {
                    newIris = newIris.filter(item => item !== response);
                } else {
                    newIris.push(response);
                }
                newState.bulkActions.iris = newIris;
                if (!newIris.length) {
                    newState.bulkActions.selectAll = false;
                }
            } else {
                newState.bulkActions.iris = response;
                if (response.length) {
                    newState.bulkActions.selectAll = true;
                } else {
                    newState.bulkActions.selectAll = false;
                }
            }

            return {
                ...state,
                [name]: newState
            };
        }
        case actions.TOGGLE_BULK_ACTIONS_MODAL: {
            const newState = Object.assign({}, state[name]);
            return {
                ...state,
                [name]: {
                    ...newState,
                    ...{ bulkActions: { ...newState.bulkActions, ...{ modalOpened: response } } }
                }
            };
        }
        default:
            return state;
    }
};
