import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import LayoutManager from 'erputils/LayoutManager';
import PageHeader from 'erpcomponents/Layout/PageHeader';
import Listing from 'erpcomponents/Listing';
import ListingTableActions from 'erpcomponents/Listing/components/TableActions';
import Button from 'erpcomponents/Button';
import Svg from 'erpcomponents/Svg';
import Tooltip from 'erpcomponents/Tooltip';
import VenueDelete from 'erpcore/screens/Venues/components/VenueDelete';
import { actions as listingActions } from 'erpcomponents/Listing/Listing.reducer';
import { getListingFetching, getListingResponse } from 'erpcomponents/Listing/Listing.selectors';
import { stringOperations } from 'erpdata/filterOperations';

import './VenuesListing.scss';

const reducerName = 'venues';

class VenuesListing extends Component {
    tableData() {
        const { locationsData } = this.props;
        const table = {};
        table.data = [];

        // Advanced Filters
        table.filters = [
            {
                name: 'venue_name',
                label: <FormattedMessage id="Venues.Table.Name.Label" defaultMessage="Name" />,
                filterFields: {
                    operator: {
                        component: 'select',
                        fieldProps: {
                            clearable: false,
                            label: (
                                <FormattedMessage
                                    id="Venues.Table.Name.Operator"
                                    defaultMessage="Operator"
                                />
                            ),
                            options: stringOperations
                        },
                        fieldValidation: [{ validator: 'required' }]
                    },
                    value: {
                        component: 'text',
                        fieldProps: {
                            label: <FormattedMessage id="Venues.Table.Name" defaultMessage="Name" />
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            }
        ];
        // Table Schema
        table.schema = [
            {
                title: <FormattedMessage id="Venues.Table.VenueName" defaultMessage="Venue Name" />,
                field: 'venue_name',
                sortable: 'venue_name'
            },
            {
                title: <FormattedMessage id="Venues.Table.Address" defaultMessage="Address" />,
                field: 'address',
                sortable: 'address'
            },
            {
                title: <FormattedMessage id="Venues.Table.Events" defaultMessage="Events" />,
                field: 'events',
                sortable: 'totalEvents',
                align: 'right'
            },
            {
                title: (
                    <FormattedMessage
                        id="Venues.Table.HasSeating"
                        defaultMessage="Has seating templates"
                    />
                ),
                field: 'seating',
                align: 'center'
            },
            {
                title: <FormattedMessage id="Venues.Table.Actions" defaultMessage="Actions" />,
                field: 'actions'
            }
        ];
        // Table Data
        locationsData.data.map(row => {
            return table.data.push({
                id: row.id,
                venue_name: row.venue_name,
                address: `${row.address}, ${row.city.name}, ${row.zip}`,
                events: row.total_events,
                seating: row.has_table_templates ? (
                    <Svg icon="checkmark" className="venues__has-seating-icon" />
                ) : (
                    <Svg icon="close" className="venues__has-seating-icon" />
                ),
                actions: (
                    <ListingTableActions>
                        <ListingTableActions.Action>
                            <Tooltip
                                content={
                                    <FormattedMessage
                                        id="Venues.Table.Actions.Edit"
                                        defaultMessage="Edit Venue"
                                    />
                                }
                            >
                                <NavLink
                                    to={`/venues/${row.id.replace(
                                        '/api/venues/',
                                        ''
                                    )}/edit/general-info`}
                                >
                                    <Svg icon="edit" />
                                </NavLink>
                            </Tooltip>
                        </ListingTableActions.Action>
                        <ListingTableActions.Action>
                            <VenueDelete
                                id={row.id}
                                key={`VenueDelete_${row.id}`}
                                form="VenueDeleteForm"
                            />
                        </ListingTableActions.Action>
                    </ListingTableActions>
                )
            });
        });

        return table;
    }

    render() {
        const { locationsListingFetching, locationsData, fetchLocations } = this.props;

        return (
            <LayoutManager slot="main" layoutType="merge">
                <PageHeader
                    title={<FormattedMessage id="Locations.title" defaultMessage="Venues" />}
                >
                    <Button
                        href="/venues/create"
                        label={<FormattedMessage id="Locations.AddNew" defaultMessage="Add New" />}
                    />
                </PageHeader>

                <Listing
                    name={reducerName}
                    reducerName={reducerName}
                    loading={locationsListingFetching}
                    meta={locationsData.meta}
                    table={this.tableData()}
                    onListingConfigUpdate={params => fetchLocations(params)}
                />
            </LayoutManager>
        );
    }
}

VenuesListing.defaultProps = {
    locationsListingFetching: false,
    locationsData: {}
};

VenuesListing.propTypes = {
    fetchLocations: PropTypes.func.isRequired,
    locationsListingFetching: PropTypes.bool,
    locationsData: PropTypes.oneOfType([PropTypes.object])
};

//  Redux
const mapStateToProps = state => ({
    locationsListingFetching: getListingFetching(state, 'venues'),
    locationsData: getListingResponse(state, 'venues')
});

const mapDispatchToProps = dispatch => ({
    fetchLocations: params => {
        // Getting included data from API by setting defaultParams
        const defaultParams = {
            include: 'city'
        };
        const listingParams = Object.assign({}, defaultParams, params);

        dispatch({
            type: listingActions.START_FETCHING_LISTING,
            params: listingParams,
            name: reducerName,
            entity: 'VENUES',
            endpoint: 'api/locations'
        });
    }
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(VenuesListing)
);
