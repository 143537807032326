import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import LayoutManager from 'erputils/LayoutManager';
import PageLoader from 'erpcomponents/PageLoader';
import Comments from 'erpcomponents/Comments';
import PageHeader from 'erpcomponents/Layout/PageHeader';
import SingleOrderTabs from 'erpcore/screens/Orders/components/SingleOrderTabs';
import PageContent from 'erpcomponents/Layout/PageContent';
import { actions as ordersActions } from 'erpsrc/screens/Orders/Orders.reducer';
import { getSingleOrderFetching, getSingleOrderData } from 'erpsrc/screens/Orders/Orders.selectors';

const OrderComments = ({ match }) => {
    const dispatch = useDispatch();
    const id = match && match.params && match.params.id;
    const singleFetching = useSelector(state => getSingleOrderFetching(state));
    const order = useSelector(state => getSingleOrderData(state, id));

    const fetchOrderData = () => {
        // Getting included data from API by setting params
        const params = {};
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: ordersActions.START_FETCHING_SINGLE_ORDER,
                id,
                params
            });
        }).catch(error => ({ error }));
    };

    useEffect(() => {
        fetchOrderData();
    }, []);

    return (
        <LayoutManager slot="main" layoutType="merge" className="main--narrow">
            <PageHeader title={`Order ${order && order.order_number}`} backButtonLink="/orders" />
            <SingleOrderTabs />
            {singleFetching === true && <PageLoader />}
            <PageContent>
                <Comments entity="orders" />
            </PageContent>
        </LayoutManager>
    );
};

OrderComments.defaultProps = {
    match: {}
};

OrderComments.propTypes = {
    match: PropTypes.oneOfType([PropTypes.object])
};

export default withRouter(OrderComments);
