import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reduxForm, Field } from 'redux-form';
import { getIdFromIri } from 'erputils/dto';
import { FormattedMessage } from 'react-intl';
import Form, { Autocomplete, FileUploader } from 'erpcore/components/Form';
import LayoutManager from 'erputils/LayoutManager';
import PageHeader from 'erpcomponents/Layout/PageHeader';
import PageContent from 'erpcomponents/Layout/PageContent';
import Button from 'erpcomponents/Button';
import { actions as tableTemplatesActions } from 'erpcore/screens/Venues/screens/VenuesEdit/TableTemplates/TableTemplates.reducer';

import EventInfo from 'erpcore/screens/Events/components/EventInfo';
import EventManageTabs from 'erpcore/screens/Events/components/EventManageTabs';
import TableFloorPlans from 'erpcore/screens/Events/screens/EventManage/components/TableFloorPlans';
import PageLoader from 'erpcomponents/PageLoader';
import ElementLoader from 'erpcomponents/ElementLoader';
import {
    getEventTableTemplates,
    getSingleTableTemplateFetching,
    getEventTables
    // getNewTables
} from 'erpcore/screens/Venues/screens/VenuesEdit/TableTemplates/TableTemplates.selectors';
import {
    getVenueRooms
    // getNewTables
} from 'erpcore/screens/Venues/screens/VenuesEdit/Rooms/Rooms.selectors';
import { getEventData, getSingleEventFetching } from 'erpcore/screens/Events/Events.selectors';
import TableTemplateName from 'erpsrc/screens/Events/screens/EventManage/components/TableTemplateName';
// import TemplateNameForm from 'erpcore/screens/Events/screens/EventManage/components/TemplateNameForm';
import TableTemplateCreateForm from 'erpcore/screens/Events/screens/EventManage/components/TableTemplateCreateForm';
import Tables from 'erpcore/screens/Events/screens/EventManage/components/Tables';
import './EventTables.scss';
import { actions as eventsActions } from 'erpcore/screens/Events/Events.reducer';
import { actions as roomsActions } from 'erpcore/screens/Venues/screens/VenuesEdit/Rooms/Rooms.reducer';
import restClient from 'erpsrc/api/restClient';
import { actions as notificationManagerActions } from 'erputils/NotificationManager/NotificationManager.reducer';
import enviromentVariables from 'erputils/enviromentVariables';
import Modal from 'erpcomponents/Modal';

class EventTables extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // id: null,
            // isFinished: false,
            uploaderModalActive: false,
            newTemplate: false
        };

        this.tableFloorPlansRef = React.createRef();
        this.downloadCSV = this.downloadCSV.bind(this);
    }

    componentDidMount = () => {
        const { fetchEventData, fetchTables, match } = this.props;
        fetchEventData().then(response => {
            if (
                response !== undefined &&
                response.data !== undefined &&
                response.data.location !== undefined &&
                response.data.location.id !== undefined
            ) {
                const { fetchRooms } = this.props;
                fetchRooms(response.data.location.id);
            }
        });
        fetchTables(match.params.id);
    };

    onTableTemplateSelect = tableTemplateIri => {
        this.setState({ newTemplate: false });

        const tableTemplateId = getIdFromIri(tableTemplateIri);

        this.copyTables(tableTemplateId).then(() => {
            this.copyGallery(tableTemplateId);
        });
    };

    handleNewTemplate = () => {
        this.setState({ newTemplate: true });
    };

    copyTables(tableTemplateId) {
        const { dispatch } = this.props;
        const params = {
            include: 'tables,tables.room,location,tables.canBeGroupedWith'
        };
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: tableTemplatesActions.START_FETCHING_SINGLE_TABLE_TEMPLATE_FOR_EVENT,
                id: tableTemplateId,
                params
            });
        })
            .then(() => {
                const { initialValues, match } = this.props;
                const tables = initialValues.tables && initialValues.tables;
                const tableIris = tables.map(table => table.iri);
                return new Promise((resolve, reject) =>
                    dispatch({
                        type: eventsActions.START_UPDATE_SINGLE_EVENT,
                        promise: { resolve, reject },
                        id: match.params.id,
                        formData: { tables: tableIris }
                    })
                );
            })
            .catch(error => ({ error }));
    }

    copyGallery(tableTemplateId) {
        const { eventData } = this.props;

        const isEventLocationDataDefined =
            eventData &&
            eventData.location !== undefined &&
            eventData.location &&
            eventData.location.id !== undefined;

        if (isEventLocationDataDefined) {
            const eventLocationId = eventData.location.id;
            if (
                this.tableFloorPlansRef.current !== undefined &&
                this.tableFloorPlansRef.current &&
                this.tableFloorPlansRef.current.wrappedInstance !== undefined &&
                this.tableFloorPlansRef.current.wrappedInstance &&
                typeof this.tableFloorPlansRef.current.wrappedInstance.getGalleryInstance !==
                    'undefined'
            ) {
                const source = {
                    getImagesApi: `/api/locations/${eventLocationId}/images`,
                    groupName: `table-template-${tableTemplateId}`
                };

                this.tableFloorPlansRef.current.wrappedInstance
                    .getGalleryInstance()
                    .rewriteWithOtherGallery(source);
            }
        }
    }

    downloadCSV() {
        const { dispatch, match } = this.props;

        if (match && match.params && match.params.id) {
            this.setState({ fetchingSampleCsv: true });

            restClient
                .get(
                    `api/tables/export/${match.params.id}/csv`,
                    {},
                    {
                        responseType: 'arraybuffer'
                    }
                )
                .then(response => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `event-${match.params.id}-tables.csv`);
                    link.click();
                    setTimeout(() => {
                        this.setState({ fetchingSampleCsv: false });
                    }, 500);
                })
                .catch(() => {
                    this.setState({ fetchingSampleCsv: false });
                    dispatch({
                        type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                        response: { code: 'error500' }
                    });
                });
        }
    }

    toggleUploaderModal(nextState = false) {
        this.setState({ uploaderModalActive: nextState });
    }

    renderUploader() {
        const { match, dispatch } = this.props;
        const { fetchingSampleCsv, uploaderModalActive } = this.state;

        const eventId = match && match.params && match.params.id;

        if (eventId) {
            return (
                <Modal
                    root="body"
                    onClose={() => this.toggleUploaderModal(false)}
                    opened={uploaderModalActive}
                    title="Import Tables"
                >
                    <h4 style={{ margin: '8px' }}>1. Download sample import file:</h4>
                    <Button
                        variation="secondary"
                        size="small"
                        iconName="download"
                        loading={fetchingSampleCsv}
                        onClick={() => this.downloadCSV()}
                        label="Download .CSV"
                    />
                    <br />
                    <h4 style={{ margin: '8px' }}>2. Fill data.</h4>
                    <h4 style={{ margin: '8px' }}>3. Upload import file:</h4>
                    <FileUploader
                        endpoint={`${
                            enviromentVariables.REST_API
                        }/api/tables/import/${eventId}/csv`}
                        fieldProps={{
                            itemLimit: 1,
                            allowedExtensions: ['csv'],
                            onAllComplete: ({ succeeded = [] /* failed = [] */ }) => {
                                const { eventData, fetchRooms, fetchTables } = this.props;
                                if (succeeded.length > 0) {
                                    if (
                                        eventData &&
                                        eventData.location !== undefined &&
                                        eventData.location &&
                                        eventData.location.id !== undefined &&
                                        eventData.location.id
                                    ) {
                                        fetchRooms(eventData.location.id);
                                    }
                                    fetchTables(eventId);
                                    dispatch({
                                        type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                                        response: { code: 'event.itemSuccessfulyUpdated' }
                                    });
                                    this.toggleUploaderModal(false);
                                }
                                /*
                                if (failed.length > 0) {
                                    dispatch({
                                        type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                                        response: {
                                            code: 'errorCustom',
                                            data: {
                                                customText: 'Something went wrong.'
                                            }
                                        }
                                    });
                                    this.toggleUploaderModal(false);
                                }
                                */
                            },
                            onError: ({ errorReason = '', xhrData }) => {
                                let responseData = null;

                                if (xhrData && xhrData.response) {
                                    if (typeof xhrData.response === 'string') {
                                        responseData = { ...JSON.parse(xhrData.response) };
                                    } else {
                                        responseData = xhrData.response;
                                    }
                                }

                                if (responseData && responseData.code !== undefined) {
                                    this.toggleUploaderModal(false);
                                    return dispatch({
                                        type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                                        response: {
                                            code: responseData.code
                                        }
                                    });
                                }

                                this.toggleUploaderModal(false);
                                return dispatch({
                                    type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                                    response: {
                                        code: 'errorCustom',
                                        data: { customText: errorReason }
                                    }
                                });
                            }
                        }}
                    />
                </Modal>
            );
        }

        return null;
    }

    // resetForm = () => {
    //     this.setState({ isFinished: true, newTemplate: false });
    // };

    render() {
        const { singleFetching, singleEventFetching, eventData, eventTables, match } = this.props;
        const { newTemplate, fetchingSampleCsv } = this.state;

        const isEventLocationDataDefined =
            eventData &&
            eventData.location !== undefined &&
            eventData.location &&
            eventData.location.id !== undefined;

        return (
            <LayoutManager slot="main" className="main--narrow" layoutType="merge">
                <PageHeader
                    title={
                        <FormattedMessage id="EventEdit.Tables.title" defaultMessage="Edit Event" />
                    }
                />
                {(singleFetching || fetchingSampleCsv) && <PageLoader />}
                <EventInfo />
                <EventManageTabs />
                <PageContent>
                    {eventTables && eventTables.length > 0 ? ( // if tables connected to event exist display them and optinal 'Save new table template' form
                        <>
                            <TableTemplateName />
                            <TableFloorPlans
                                ref={this.tableFloorPlansRef}
                                eventId={match.params.id}
                            />
                            <Tables downloadCSV={this.downloadCSV} />
                            <Form.Row>
                                {
                                    <Button
                                        label="Import Tables"
                                        onClick={() => this.toggleUploaderModal(true)}
                                    />
                                }
                            </Form.Row>
                        </>
                    ) : (
                        <>
                            {!newTemplate && (
                                <Form.Row>
                                    <Form.Columns breakOnMobile>
                                        {!isEventLocationDataDefined && !!singleEventFetching && (
                                            <Form.Column>
                                                <ElementLoader />
                                            </Form.Column>
                                        )}
                                        {isEventLocationDataDefined && (
                                            <React.Fragment>
                                                <Form.Column>
                                                    <Field
                                                        name="name"
                                                        id="name"
                                                        fieldProps={{
                                                            clearable: true,
                                                            label:
                                                                'Choose from the venue templates',
                                                            options: {
                                                                endpoint: `api/table-templates?filters[locations.id][equals]=${
                                                                    eventData.location.id
                                                                }&pagination=false`,
                                                                mapData: {
                                                                    label: 'name',
                                                                    value: 'iri'
                                                                }
                                                            }
                                                        }}
                                                        onChange={value =>
                                                            this.onTableTemplateSelect(value)
                                                        }
                                                        component={Autocomplete}
                                                    />
                                                </Form.Column>
                                                <Form.Column autoWidth>
                                                    <span className="event-tables__text">or</span>
                                                </Form.Column>
                                            </React.Fragment>
                                        )}
                                        <Form.Column>
                                            <Button
                                                submit
                                                onClick={this.handleNewTemplate}
                                                label={
                                                    <FormattedMessage
                                                        id="EventEdit.Tables.StartFresh"
                                                        defaultMessage="Start Fresh"
                                                    />
                                                }
                                            />
                                        </Form.Column>
                                    </Form.Columns>
                                </Form.Row>
                            )}

                            {newTemplate && <TableTemplateCreateForm />}

                            <Form.Row>
                                {
                                    <Button
                                        label="Import Tables"
                                        onClick={() => this.toggleUploaderModal(true)}
                                    />
                                }
                            </Form.Row>
                        </>
                    )}
                    {this.renderUploader()}
                </PageContent>
            </LayoutManager>
        );
    }
}

EventTables.defaultProps = {
    fetchEventData: () => {},
    fetchTables: () => {},
    fetchRooms: () => {},
    singleFetching: false,
    initialValues: {},
    singleEventFetching: false,
    eventData: {},
    match: {},
    eventTables: []
    // venueRooms: []
};

EventTables.propTypes = {
    dispatch: PropTypes.func.isRequired,
    // match: PropTypes.oneOfType([PropTypes.object]).isRequired,
    fetchEventData: PropTypes.func,
    fetchRooms: PropTypes.func,
    fetchTables: PropTypes.func,
    initialValues: PropTypes.oneOfType([PropTypes.object]),
    singleEventFetching: PropTypes.bool,
    eventData: PropTypes.oneOfType([PropTypes.object]),
    match: PropTypes.oneOfType([PropTypes.object]),
    eventTables: PropTypes.oneOfType([PropTypes.array]),
    // venueRooms: PropTypes.oneOfType([PropTypes.array]),
    singleFetching: PropTypes.bool
};

EventTables = reduxForm({
    form: 'EventTablesForm',
    enableReinitialize: true
})(EventTables);

//  Getting initial value populated in the form from the store
const mapStateToProps = (state, ownProps) => ({
    initialValues: getEventTableTemplates(state),
    singleFetching: getSingleTableTemplateFetching(state),
    singleEventFetching: getSingleEventFetching(state),
    eventData: getEventData(state, ownProps.match.params.id),
    eventTables: getEventTables(state),
    venueRooms: getVenueRooms(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchEventData: () => {
        // Getting included data from API by setting params
        const params = {
            include: 'organization,location,location.country,location.state,location.city'
        };
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: eventsActions.START_FETCHING_SINGLE_EVENT,
                id: ownProps.match.params.id,
                params
            });
        }).catch(error => ({ error }));
    },
    fetchTables: eventId => {
        const params = {
            include: 'canBeGroupedWith,room'
        };

        return new Promise((resolve, reject) => {
            dispatch({
                type: tableTemplatesActions.START_FETCH_EVENT_TABLES,
                id: eventId,
                promise: { resolve, reject },
                params
            });
        });
    },
    fetchRooms: venueId => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: roomsActions.START_FETCHING_ROOMS_FOR_VENUE,
                promise: { resolve, reject },
                id: venueId
            });
        }).catch(error => ({
            error
        }));
    }
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(EventTables)
);
