import { createSelector } from 'reselect';

export const getData = (state, id) =>
    state.widgets &&
    state.widgets.ticketSocketTickets24h &&
    state.widgets.ticketSocketTickets24h[id];

export const getFetching = (state, id) =>
    state.widgets &&
    state.widgets.ticketSocketTickets24h &&
    state.widgets.ticketSocketTickets24h[id] &&
    state.widgets.ticketSocketTickets24h[id].fetching;

export const getDifferenceData = state =>
    state.widgets &&
    state.widgets.ticketSocketTickets24h &&
    state.widgets.ticketSocketTickets24h['24hDifference'] &&
    state.widgets.ticketSocketTickets24h['24hDifference'];

export default createSelector([getData, getFetching, getDifferenceData]);
