import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reduxForm, FieldArray } from 'redux-form';
import { dtoForm } from 'erputils/dto';
import { diff } from 'deep-object-diff';
import { FormattedMessage } from 'react-intl';
import Form, { Repeater } from 'erpcore/components/Form';
// import Button from 'erpcomponents/Button';
import TableTemplateRepeaterItem from 'erpcore/screens/Venues/screens/VenuesEdit/TableTemplates/components/TableTemplateRepeaterItem';
import { actions as tableTemplatesActions } from 'erpcore/screens/Venues/screens/VenuesEdit/TableTemplates/TableTemplates.reducer';
import {
    getTableTemplateData,
    getSingleTableTemplateFetching,
    getNewTables,
    getTableTemplateTables
} from 'erpcore/screens/Venues/screens/VenuesEdit/TableTemplates/TableTemplates.selectors';
import { getVenueData } from 'erpcore/screens/Venues/Venues.selectors';

const canBeGroupedWithDto = data => {
    if (data) {
        if (data && data.length) {
            data.forEach((item, index) => {
                if (data[index].room) {
                    data[index].room = data[index].room.iri
                        ? data[index].room.iri
                        : data[index].room;
                }
                if (data[index].can_be_grouped_with && data[index].can_be_grouped_with) {
                    if (!data[index].can_be_grouped_with.every(i => typeof i === 'string')) {
                        data[index].can_be_grouped_with = data[index].can_be_grouped_with.map(
                            record => record.iri
                        );
                    }
                }
            });
        }
    }
    return { tables: data };
};
class TablesForm extends Component {
    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(formData) {
        const { match, dispatch, initialValues } = this.props;
        formData = diff(initialValues, formData);

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: tableTemplatesActions.START_UPDATE_SINGLE_TABLE_TEMPLATE,
                id: match.params.id,
                formData
            })
        ).catch(error => {
            return error;
        });
    }

    handleSaveNewItem = ({ itemData = {} }) => {
        const { dispatch, match } = this.props;
        itemData.max_seats = parseInt(itemData.max_seats, 10);
        itemData.min_seats = parseInt(itemData.min_seats, 10);
        itemData.table_template = `/api/table-templates/${match.params.tid}`;
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: tableTemplatesActions.START_CREATE_TABLE,
                formData: itemData
            })
        )
            .then(() => {
                this.handleAfterAnyTableTemplateUpdate();
            })
            .catch(() => {});
    };

    handleRemoveItem = ({ itemData = {} }) => {
        const { dispatch } = this.props;
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: tableTemplatesActions.START_DELETE_TABLE,
                id: itemData && itemData.id
            })
        )
            .then(() => {
                this.handleAfterAnyTableTemplateUpdate();
            })
            .catch(() => {
                // throw new SubmissionError(reduxFormErrorMapper(error));
            });
    };

    handleAfterAnyTableTemplateUpdate() {
        const { reset, fetchTableTemplateTables, match } = this.props;
        fetchTableTemplateTables(match.params.tid).then(() => reset());
        // fetchTableTemplateData().then(() => {
        //     reset();
        // });
    }

    render() {
        const { handleSubmit, initialValues, match, venueRooms } = this.props;
        const tables =
            initialValues &&
            initialValues.tables &&
            initialValues.tables.map(table => {
                return { label: table.table_number, value: table.iri };
            });

        return (
            <Form onSubmit={handleSubmit(this.onSubmit)}>
                <Form.Row>
                    <FieldArray
                        name="tables"
                        component={Repeater}
                        RepeatableItem={TableTemplateRepeaterItem}
                        uniqueIdentifier="id"
                        deleteModalTitle={
                            <FormattedMessage
                                id="TableTemplates.Repeater.deleteModal.title"
                                defaultMessage="Delete Table"
                            />
                        }
                        data={{ initialValues, match, tables, venueRooms }}
                        deleteModalSubTitle={
                            <FormattedMessage
                                id="TableTemplates.Repeater.deleteModal.subTitle"
                                defaultMessage="Are you sure you wanna delete this Table?"
                            />
                        }
                        // onSortEnd={this.handleSort}
                        onSaveNewItem={this.handleSaveNewItem}
                        onRemoveItem={this.handleRemoveItem}
                        addNewLabel="Add New Table"
                        canSort={false}
                    />
                </Form.Row>
            </Form>
        );
    }
}

TablesForm.defaultProps = {
    // fetchTableTemplateData: () => {},
    // singleFetching: false,
    initialValues: {},
    venueRooms: [],
    // fetchTableTemplateData: () => {},
    fetchTableTemplateTables: () => {}
};

TablesForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.oneOfType([PropTypes.object]).isRequired,
    initialValues: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    fetchTableTemplateTables: PropTypes.func,
    // fetchTableTemplateData: PropTypes.func,
    reset: PropTypes.func.isRequired,
    // newTables: PropTypes.oneOfType([PropTypes.array]).isRequired,
    venueRooms: PropTypes.oneOfType([PropTypes.array])
};

TablesForm = reduxForm({
    form: 'TablesForm',
    enableReinitialize: true,
    keepDirtyOnReinitialize: false, // important
    updateUnregisteredFields: false // important
})(TablesForm);

const mapStateToProps = (state, ownProps) => ({
    initialValues: canBeGroupedWithDto(getTableTemplateTables(state)),
    singleFetching: getSingleTableTemplateFetching(state),
    data: dtoForm(getTableTemplateData(state, ownProps.match.params.tid)),
    newTables: getNewTables(state, 'tableTemplates'),
    venueData: getVenueData(state, ownProps.match.params.id),
    tableTemplateTables: getTableTemplateTables(state)
});

//  Getting initial value populated in the form from the store

export default withRouter(
    connect(
        mapStateToProps,
        null
    )(TablesForm)
);
