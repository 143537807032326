import { createSelector } from 'reselect';

export const getData = (state, id) =>
    state.widgets &&
    state.widgets.salesOverview &&
    state.widgets.salesOverview[id] &&
    state.widgets.salesOverview[id];

export const getFetching = (state, id) =>
    state.widgets &&
    state.widgets.salesOverview &&
    state.widgets.salesOverview[id] &&
    state.widgets.salesOverview[id].fetching;

export default createSelector([getData, getFetching]);
