import React, { Component } from 'react';
import LayoutManager from 'erputils/LayoutManager';
import PageHeader from 'erpcomponents/Layout/PageHeader';
import PageContent from 'erpcomponents/Layout/PageContent';
import { withRouter } from 'react-router-dom';
import SettingsTabs from 'erpcore/screens/Settings/components/SettingsTabs';
import PropTypes from 'prop-types';
import Listing from 'erpcomponents/Listing';
import { FormattedMessage } from 'react-intl';
import ListingTableActions from 'erpcomponents/Listing/components/TableActions';
import { connect } from 'react-redux';
import { getListingFetching, getListingResponse } from 'erpcomponents/Listing/Listing.selectors';
import { actions as listingActions } from 'erpcomponents/Listing/Listing.reducer';
import AgeRequirementCreate from 'erpcore/screens/Settings/components/AgeRequirementCreate';
import AgeRequirementDelete from 'erpcore/screens/Settings/components/AgeRequirementDelete';
import AgeRequirementEdit from 'erpcore/screens/Settings/components/AgeRequirementEdit';

const reducerName = 'ageRequirements';

class AgeRequirements extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ageRequirementId: null
        };
        this.toggleEdit = this.toggleEdit.bind(this);
        this.resetAgeRequirementId = this.resetAgeRequirementId.bind(this);
    }

    toggleEdit(id) {
        this.setState({
            ageRequirementId: id
        });
    }

    resetAgeRequirementId() {
        this.setState({ ageRequirementId: null });
    }

    tableData() {
        const { ageRequirementsData } = this.props;
        const table = {};
        table.data = [];

        // Table Schema
        table.schema = [
            {
                title: (
                    <FormattedMessage
                        id="Settings.AgeRequirements.Table"
                        defaultMessage="Age Requirements"
                    />
                ),
                field: 'eventName',
                sortable: 'name'
            },
            {
                title: (
                    <FormattedMessage
                        id="Settings.AgeRequirements.Table.Actions"
                        defaultMessage="Actions"
                    />
                ),
                field: 'actions'
            }
        ];

        ageRequirementsData.data.map(row => {
            return table.data.push({
                id: row.id,
                eventName: row.name,
                actions: (
                    <ListingTableActions>
                        <ListingTableActions.Action>
                            <AgeRequirementEdit
                                id={row.id}
                                key={`AgeRequirementEdit_${row.id}`}
                                form="AgeRequirementEditForm"
                            />
                        </ListingTableActions.Action>
                        <ListingTableActions.Action>
                            <AgeRequirementDelete
                                id={row.id}
                                key={`AgeRequirementDelete_${row.id}`}
                                form="AgeRequirementDeleteForm"
                            />
                        </ListingTableActions.Action>
                    </ListingTableActions>
                )
            });
        });

        return table;
    }

    render() {
        const {
            fetchAgeRequirements,
            ageRequirementsListingFetching,
            ageRequirementsData
        } = this.props;
        const { ageRequirementId } = this.state;
        return (
            <LayoutManager slot="main" className="main--narrow" layoutType="merge">
                <PageHeader title="Settings - Categories" />
                <SettingsTabs />
                <PageContent>
                    {!ageRequirementId ? (
                        <AgeRequirementCreate />
                    ) : (
                        <AgeRequirementEdit
                            resetId={this.resetAgeRequirementId}
                            id={ageRequirementId}
                        />
                    )}
                    <Listing
                        name={reducerName}
                        reducerName={reducerName}
                        loading={ageRequirementsListingFetching}
                        hideSearch
                        meta={ageRequirementsData.meta}
                        table={this.tableData()}
                        onListingConfigUpdate={params => fetchAgeRequirements(params)}
                    />
                </PageContent>
            </LayoutManager>
        );
    }
}

AgeRequirements.defaultProps = {
    ageRequirementsData: {},
    ageRequirementsListingFetching: false
};

AgeRequirements.propTypes = {
    ageRequirementsData: PropTypes.oneOfType([PropTypes.object]),
    fetchAgeRequirements: PropTypes.func.isRequired,
    ageRequirementsListingFetching: PropTypes.bool
};

const mapStateToProps = state => ({
    ageRequirementsListingFetching: getListingFetching(state, 'ageRequirements'),
    ageRequirementsData: getListingResponse(state, 'ageRequirements')
});

const mapDispatchToProps = dispatch => ({
    fetchAgeRequirements: params => {
        // Getting included data from API by setting defaultParams
        const listingParams = Object.assign({ pagination: false }, params);

        dispatch({
            type: listingActions.START_FETCHING_LISTING,
            params: listingParams,
            name: reducerName,
            entity: 'AGE_REQUIREMENTS',
            endpoint: 'api/age-requirements'
        });
    }
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(AgeRequirements)
);
