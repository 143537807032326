export const actions = {
    START_FETCHING_TABLES_DND: 'START_FETCHING_TABLES_DND',
    FETCHING_SUCCESSFUL_TABLES_DND: 'FETCHING_SUCCESSFUL_TABLES_DND',
    FETCHING_FAILED_TABLES_DND: 'FETCHING_FAILED_TABLES_DND',
    REQUEST_SORT_TABLES_DND: 'REQUEST_SORT_TABLES_DND',
    START_SORT_TABLES_DND: 'START_SORT_TABLES_DND',
    SORT_SUCCESSFUL_TABLES_DND: 'SORT_SUCCESSFUL_TABLES_DND',
    SORT_FAILED_TABLES_DND: 'SORT_FAILED_TABLES_DND',
    START_CREATE_TABLE_DND: 'START_CREATE_TABLE_DND',
    CREATE_TABLE_DND_SUCCESSFUL: 'CREATE_TABLE_DND_SUCCESSFUL',
    CREATE_TABLE_DND_FAILED: 'CREATE_TABLE_DND_FAILED',
    START_FETCHING_SINGLE_TABLE_DND: 'START_FETCHING_SINGLE_TABLE_DND',
    FETCH_SINGLE_TABLE_DND_SUCCESSFUL: 'FETCH_SINGLE_TABLE_DND_SUCCESSFUL',
    FETCH_SINGLE_TABLE_DND_FAILED: 'FETCH_SINGLE_TABLE_DND_FAILED',
    REQUEST_UPDATE_SINGLE_TABLE_DND: 'REQUEST_UPDATE_SINGLE_TABLE_DND',
    START_UPDATE_SINGLE_TABLE_DND: 'START_UPDATE_SINGLE_TABLE_DND',
    UPDATE_SINGLE_TABLE_DND_SUCCESSFUL: 'UPDATE_SINGLE_TABLE_DND_SUCCESSFUL',
    UPDATE_SINGLE_TABLE_DND_FAILED: 'UPDATE_SINGLE_TABLE_DND_FAILED',
    START_DELETE_SINGLE_TABLE_DND: 'START_DELETE_SINGLE_TABLE_DND',
    DELETE_SINGLE_TABLE_DND_SUCCESSFUL: 'DELETE_SINGLE_TABLE_DND_SUCCESSFUL',
    DELETE_SINGLE_TABLE_DND_FAILED: 'DELETE_SINGLE_TABLE_DND_FAILED',
    STORE_SINGLE_TABLE_DND_DATA: 'STORE_SINGLE_TABLE_DND_DATA',
    STORE_TABLE_FOOTER_ELEMENT_SIZE: 'STORE_TABLE_FOOTER_ELEMENT_SIZE',
    TOGGLE_TABLE_MODAL_REMOVE_GROUP: 'TOGGLE_TABLE_MODAL_REMOVE_GROUP',
    TOGGLE_TABLE_MODAL_GROUP_INFO: 'TOGGLE_TABLE_MODAL_GROUP_INFO',
    TOGGLE_TABLE_MODAL_SPLIT_TABLE: 'TOGGLE_TABLE_MODAL_SPLIT_TABLE',
    TOGGLE_TABLE_MODAL_NOTES: 'TOGGLE_TABLE_MODAL_NOTES',

    START_EXPORTING_ALLOTMENT_CSV: 'START_EXPORTING_ALLOTMENT_CSV',
    EXPORTING_ALLOTMENT_CSV_SUCCESSFUL: 'EXPORTING_ALLOTMENT_CSV_SUCCESSFUL',
    EXPORTING_ALLOTMENT_CSV_FAILED: 'EXPORTING_ALLOTMENT_CSV_FAILED'
};

export const initialState = {
    listingFetching: false,
    listingSorting: false,
    listingData: [],
    listingMeta: {},
    listingError: [],
    singleFetching: false,
    singleCreating: false,
    singleDeleting: false,
    singleUpdating: false,
    footerElementSize: null,
    modalRemoveGroup: false,
    modalGroupInfo: false,
    modalSplitTable: false,
    modalTableNotes: false
};

export default (state = initialState, { type, id, response, footerElementSize, modalIndex }) => {
    switch (type) {
        case actions.START_FETCHING_TABLES_DND: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_TABLES_DND: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_TABLES_DND: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        case actions.START_SORT_TABLES_DND: {
            return {
                ...state,
                listingSorting: true
            };
        }
        case actions.SORT_SUCCESSFUL_TABLES_DND: {
            return {
                ...state,
                listingSorting: false
                // listingResponse: response
            };
        }
        case actions.SORT_FAILED_TABLES_DND: {
            return {
                ...state,
                listingSorting: false
                // listingError: response
            };
        }
        case actions.START_CREATE_TABLE_DND: {
            return {
                ...state,
                singleCreating: true
            };
        }
        case actions.CREATE_TABLE_DND_SUCCESSFUL: {
            return {
                ...state,
                singleCreating: false
            };
        }
        case actions.CREATE_TABLE_DND_FAILED: {
            return {
                ...state,
                singleCreating: false
            };
        }
        case actions.START_DELETE_SINGLE_TABLE_DND: {
            return {
                ...state,
                singleDeleting: true
            };
        }
        case actions.DELETE_SINGLE_TABLE_DND_SUCCESSFUL: {
            return {
                ...state,
                singleDeleting: false
            };
        }
        case actions.DELETE_SINGLE_TABLE_DND_FAILED: {
            return {
                ...state,
                singleDeleting: false
            };
        }
        case actions.START_FETCHING_SINGLE_TABLE_DND: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.FETCH_SINGLE_TABLE_DND_SUCCESSFUL: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.FETCH_SINGLE_TABLE_DND_FAILED: {
            return {
                ...state,
                singleFetching: false,
                singleError: response
            };
        }

        case actions.START_UPDATE_SINGLE_TABLE_DND: {
            return {
                ...state,
                singleUpdating: true
            };
        }
        case actions.UPDATE_SINGLE_TABLE_DND_SUCCESSFUL: {
            return {
                ...state,
                singleUpdating: false
            };
        }
        case actions.UPDATE_SINGLE_TABLE_DND_FAILED: {
            return {
                ...state,
                singleUpdating: false,
                singleError: response
            };
        }
        case actions.STORE_SINGLE_TABLE_DND_DATA: {
            return Object.assign({}, state, {
                [id]: response.data
            });
        }
        case actions.STORE_TABLE_FOOTER_ELEMENT_SIZE: {
            return {
                ...state,
                footerElementSize
            };
        }
        case actions.TOGGLE_TABLE_MODAL_REMOVE_GROUP: {
            return {
                ...state,
                modalRemoveGroup: modalIndex,
                modalGroupInfo: false,
                modalTableNotes: false
            };
        }
        case actions.TOGGLE_TABLE_MODAL_GROUP_INFO: {
            return {
                ...state,
                modalRemoveGroup: false,
                modalGroupInfo: modalIndex,
                modalTableNotes: false
            };
        }
        case actions.TOGGLE_TABLE_MODAL_NOTES: {
            return {
                ...state,
                modalRemoveGroup: false,
                modalGroupInfo: false,
                modalTableNotes: modalIndex
            };
        }
        case actions.TOGGLE_TABLE_MODAL_SPLIT_TABLE: {
            return {
                ...state,
                modalSplitTable: modalIndex
            };
        }
        default:
            return state;
    }
};
