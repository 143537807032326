/**
 * Collection of Widget specific helper functions
 */
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import concat from 'lodash/concat';

const getBoundaryAdjustedData = (dataSets = [], defaultValue = {}) => {
    if (!dataSets || !dataSets.length) return false;

    const identifiers = [];

    dataSets.forEach((collection, index) => {
        identifiers[index] = Object.keys(collection.data);
    });

    const mergedSortedIdentifiers = sortBy(uniq(concat(...identifiers)), date => new Date(date));

    const sets = dataSets.map(collection => {
        // const collectionIdentifier = collection.identifier;
        // const collectionData = collection.data;
        const collectionWithPopulatedMissingData = {};
        mergedSortedIdentifiers.forEach(identifier => {
            if (collection.data[identifier] !== undefined) {
                collectionWithPopulatedMissingData[identifier] = collection.data[identifier];
            } else {
                collectionWithPopulatedMissingData[identifier] = defaultValue;
            }
        });
        return {
            id: collection.id,
            data: collectionWithPopulatedMissingData
        };
    });

    return {
        identifiers: mergedSortedIdentifiers,
        sets
    };
};

export { getBoundaryAdjustedData }; // eslint-disable-line import/prefer-default-export
