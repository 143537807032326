import { takeLatest, put, call, select } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import { actions as listingActions } from 'erpcomponents/Listing/Listing.reducer';
import { getQueryParams } from 'erpcore/components/Listing/Listing.selectors';
import { parseParamsForApi } from 'erpcore/components/Listing/Listing.utils';
import { actions as notificationManagerActions } from 'erputils/NotificationManager/NotificationManager.reducer';
import dto from 'erputils/dto';
import { actions as resellersActions } from './Resellers.reducer';

/**
 * Create Reseller
 * @param  {Object} promise
 * @return {Object} formData
 */
export function* createReseller({ promise, formData }) {
    try {
        // Prepare data for /resellers API

        formData = Object.assign(formData, {
            micro_site_status: 'Unpublished'
        });

        // Create Reseller
        const createResellerAPI = yield restClient.post(`api/resellers`, formData);
        yield put({
            type: resellersActions.CREATE_RESELLER_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: createResellerAPI.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: resellersActions.CREATE_RESELLER_FAILED,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Fetch Reseller
 * @param  {Object} promise
 * @return {string}
 */
export function* fetchReseller({ promise, id, params }) {
    try {
        const fetchResellerAPI = yield restClient.get(`api/resellers/${id}`, { params });
        yield put({
            type: resellersActions.FETCH_SINGLE_RESELLER_SUCCESSFUL
        });
        yield put({
            type: resellersActions.STORE_SINGLE_RESELLER_DATA,
            id,
            response: dto(fetchResellerAPI.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: resellersActions.FETCH_SINGLE_RESELLER_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Update reseller single data
 * @param  {Object} id ID of an reseller
 * @return {Object} Response from API
 */
export function* updateSingleResellerGeneralInfo({ promise, formData, id }) {
    try {
        const updateSingleResellerAPI = yield restClient.put(
            `api/resellers/${id}?include=company,resellerPrimaryAccount,eventsExcluded,eventsIncluded,`,
            formData
        );
        yield put({
            type: resellersActions.UPDATE_SINGLE_RESELLER_GENERAL_INFO_SUCCESSFUL
        });
        yield put({
            type: resellersActions.STORE_SINGLE_RESELLER_DATA,
            id,
            response: dto(updateSingleResellerAPI.data)
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateSingleResellerAPI.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: resellersActions.UPDATE_SINGLE_RESELLER_GENERAL_INFO_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}
export function* updateSingleResellerSEO({ promise, formData, id }) {
    try {
        const updateSingleResellerAPI = yield restClient.put(
            `api/resellers/${id}?include=company,event,primaryResellerAccount,eventDescriptions`,
            formData
        );

        yield put({
            type: resellersActions.UPDATE_SINGLE_RESELLER_SEO_SUCCESSFUL
        });
        yield put({
            type: resellersActions.STORE_SINGLE_RESELLER_DATA,
            id,
            response: dto(updateSingleResellerAPI.data)
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: updateSingleResellerAPI.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: resellersActions.UPDATE_SINGLE_RESELLER_SEO_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}
export function* updateSingleResellerEvents({ promise, formData, id }) {
    try {
        const updateSingleResellerAPI = yield restClient.put(
            `api/resellers/${id}?include=sortedEvents`,
            formData
        );
        yield put({
            type: resellersActions.UPDATE_SINGLE_RESELLER_EVENTS_SUCCESSFUL
        });
        yield put({
            type: resellersActions.STORE_SINGLE_RESELLER_DATA,
            id,
            response: dto(updateSingleResellerAPI.data)
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateSingleResellerAPI.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: resellersActions.UPDATE_SINGLE_RESELLER_EVENTS_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

export function* updateSingleResellerStatus({ promise, formData, id }) {
    try {
        const updateSingleResellerAPI = yield restClient.put(
            `api/resellers/${id}?include=primaryResellerAccount`,
            formData
        );
        yield put({
            type: resellersActions.UPDATE_SINGLE_RESELLER_STATUS_SUCCESSFUL
        });
        yield put({
            type: resellersActions.STORE_SINGLE_RESELLER_DATA,
            id,
            response: dto(updateSingleResellerAPI.data)
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: updateSingleResellerAPI.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: resellersActions.UPDATE_SINGLE_RESELLER_STATUS_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Delete Single Reseller
 * @param  {Object} id ID of an Reseller
 * @return {Object} Response from API
 */
export function* deleteSingleReseller({ promise, id }) {
    try {
        // Merge default with current params for listing refresh purpose
        const params = yield select(getQueryParams, {
            name: 'resellers',
            include: 'company,company.name,resellerPrimaryAccount'
        });
        const deleteSingleResellerAPI = yield restClient.delete(`api/resellers/${id}`);
        yield put({
            type: resellersActions.DELETE_SINGLE_RESELLER_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: deleteSingleResellerAPI.data
        });
        yield put({
            type: listingActions.START_FETCHING_LISTING,
            name: 'resellers',
            entity: 'RESELLERS',
            endpoint: 'api/resellers',
            params: parseParamsForApi(params)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: resellersActions.DELETE_SINGLE_RESELLER_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

export function* fetchResellerEvents({ promise, id, params }) {
    try {
        const fetchResellerAPI = yield restClient.get(`api/resellers/${id}/events`, { params });
        yield put({
            type: resellersActions.FETCH_RESELLER_EVENTS_SUCCESSFUL
        });
        yield put({
            type: resellersActions.STORE_RESELLER_EVENTS,
            id,
            response: dto(fetchResellerAPI.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: resellersActions.FETCH_RESELLER_EVENTS_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

export function* startUpdateEventVisibility({ promise, id, formData }) {
    try {
        const updateSingleResellerAPI = yield restClient.put(`api/resellers/${id}`, formData);
        yield put({
            type: resellersActions.STORE_SINGLE_RESELLER_DATA,
            id,
            response: dto(updateSingleResellerAPI.data)
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateSingleResellerAPI.data
        });
        yield put({
            type: resellersActions.UPDATE_RESELLER_EVENT_VISIBILITY_SUCCESSFUL
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: resellersActions.UPDATE_RESELLER_EVENT_VISIBILITY_FAILED,
            response: error.response.data
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Bulk Actions - Merge resellers
 * @param  {Object} iri of the reseller
 * @return {Object} Response from API
 */
export function* startBatchUpdateResellers({
    promise,
    formData,
    endpoint = '/api/resellers/merge'
}) {
    try {
        const params = yield select(getQueryParams, {
            name: 'resellers',
            include: 'company,company.name,resellerPrimaryAccount'
        });
        const batchUpdateResellersAPI = yield restClient.post(endpoint, formData);
        yield put({
            type: resellersActions.BULK_ACTIONS_UPDATE_RESELLERS_SUCCESS
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: {
                data: batchUpdateResellersAPI?.data,
                code: batchUpdateResellersAPI?.code
                    ? batchUpdateResellersAPI.code
                    : 'reseller.batchUpdateSuccessful'
            }
        });
        yield put({
            type: listingActions.UPDATE_BULK_ACTIONS_IRIS,
            response: [],
            name: 'resellers'
        });
        yield put({
            type: listingActions.START_FETCHING_LISTING,
            name: 'resellers',
            entity: 'RESELLERS',
            endpoint: 'api/resellers',
            params: parseParamsForApi(params)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: resellersActions.BULK_ACTIONS_UPDATE_RESELLERS_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
export const resellersSaga = [
    takeLatest(resellersActions.START_CREATE_RESELLER, createReseller),
    takeLatest(resellersActions.START_FETCHING_SINGLE_RESELLER, fetchReseller),
    takeLatest(
        resellersActions.START_UPDATE_SINGLE_RESELLER_GENERAL_INFO,
        updateSingleResellerGeneralInfo
    ),
    takeLatest(resellersActions.START_UPDATE_SINGLE_RESELLER_EVENTS, updateSingleResellerEvents),
    takeLatest(resellersActions.START_UPDATE_SINGLE_RESELLER_SEO, updateSingleResellerSEO),
    takeLatest(resellersActions.START_UPDATE_SINGLE_RESELLER_STATUS, updateSingleResellerStatus),
    takeLatest(resellersActions.START_DELETE_SINGLE_RESELLER, deleteSingleReseller),
    takeLatest(resellersActions.START_FETCHING_RESELLER_EVENTS, fetchResellerEvents),
    takeLatest(resellersActions.START_UPDATE_RESELLER_EVENT_VISIBILITY, startUpdateEventVisibility),
    takeLatest(resellersActions.START_BULK_ACTIONS_UPDATE_RESELLERS, startBatchUpdateResellers)
];
