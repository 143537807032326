export const actions = {
    START_FETCHING_TICKETS_PER_TICKET_TYPE_DATA: 'START_FETCHING_TICKETS_PER_TICKET_TYPE_DATA',
    FETCHING_TICKETS_PER_TICKET_TYPE_DATA_SUCCESSFUL:
        'FETCHING_TICKETS_PER_TICKET_TYPE_DATA_SUCCESSFUL',
    FETCHING_TICKETS_PER_TICKET_TYPE_DATA_FAILED: 'FETCHING_TICKETS_PER_TICKET_TYPE_DATA_FAILED',
    STORE_TICKETS_PER_TICKET_TYPE_DATA: 'STORE_TICKETS_PER_TICKET_TYPE_DATA'
};

export const initialState = {};

export default (state = initialState, { type, response, widgetId }) => {
    switch (type) {
        case actions.START_FETCHING_TICKETS_PER_TICKET_TYPE_DATA: {
            return {
                ...state,
                [widgetId]: { ...state[widgetId], ...{ fetching: true } }
            };
        }
        case actions.FETCHING_TICKETS_PER_TICKET_TYPE_DATA_SUCCESSFUL:
            return {
                ...state,
                [widgetId]: { ...state[widgetId], ...{ fetching: false } }
            };
        case actions.FETCHING_TICKETS_PER_TICKET_TYPE_DATA_FAILED: {
            return {
                ...state,
                [widgetId]: { ...state[widgetId], ...{ fetching: false } }
            };
        }
        case actions.STORE_TICKETS_PER_TICKET_TYPE_DATA: {
            return {
                ...state,
                [widgetId]: { ...state[widgetId], ...{ data: response } }
            };
        }
        default:
            return state;
    }
};
