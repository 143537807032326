import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// import { FormattedMessage } from 'react-intl';
import { actions as activityLogActions } from 'erpcomponents/ActivityLog/ActivityLog.reducer';
import {
    getActivityLogData,
    getActivityLogFetching
} from 'erpcomponents/ActivityLog/ActivityLog.selectors';
import Accordion from 'erpcomponents/Accordion';
import ActivityLogFilters from 'erpcomponents/ActivityLog/components/ActivityLogFilters';
import PageLoader from 'erpcomponents/PageLoader';
import generateKey from 'erputils/generateKey';
import NotificationManager from 'erputils/NotificationManager';

import './ActivityLog.scss';

class ActivityLog extends Component {
    componentDidMount() {
        const { fetchActivityLogs, entity, match } = this.props;
        fetchActivityLogs(entity, match);
    }

    filterDropdownData = value => {
        const result = {};
        const parsedData = JSON.parse(value);
        const keys = Object.keys(parsedData);
        keys.map(key =>
            Object.assign(result, {
                [key]: parsedData[key]
            })
        );

        return result;
    };

    renderMessages = value => {
        const keys = Object.keys(value);

        return keys.map(key => {
            const label = key.replace(/^\w/, firstChar => firstChar.toUpperCase());
            return (
                <p>
                    <b>{`${label}`}: </b>
                    {`${value[key]}`}
                </p>
            );
        });
    };

    renderSingleDropdownContent = differences => {
        const keys = Object.keys(differences);
        const entries = Object.values(differences);

        if (keys.length) {
            return (
                <React.Fragment>
                    {keys.map((value, loopIndex) => (
                        <div
                            key={`ActivityLogItem-${generateKey(loopIndex)}`}
                            className="accordion__content__one activity-log__content"
                        >
                            {value && value[0] && (
                                <p>
                                    <b>{value.toUpperCase()}</b>
                                </p>
                            )}
                            {this.renderMessages(entries[loopIndex])}
                        </div>
                    ))}
                </React.Fragment>
            );
        }
        return null;
    };

    /**
     *  Render ActivityLogs
     */
    renderActivityLogs() {
        const { activityLog } = this.props;

        if (activityLog.data && activityLog.data.length) {
            return activityLog.data.map((el, i) => {
                const diffs = this.filterDropdownData(el.diffs);
                const body = this.renderSingleDropdownContent(diffs, i);
                return (
                    <Accordion
                        body={body}
                        title={
                            <div className="activity-log__title">
                                {el.created_at && <span>{el.created_at}</span>}
                                {el.blame_user &&
                                    el.blame_user.first_name &&
                                    el.blame_user.last_name && (
                                        <span>
                                            {`${el.blame_user.first_name} ${
                                                el.blame_user.last_name
                                            }`}
                                        </span>
                                    )}
                            </div>
                        }
                        key={generateKey(i)}
                    />
                );
            });
        }
        return null;
    }

    render() {
        const { entity, match, activityLogFetching } = this.props;
        const activityLogEntries = this.renderActivityLogs();
        return (
            <div className="activity-log">
                {activityLogFetching && <PageLoader />}
                {!activityLogFetching && !activityLogEntries && (
                    <NotificationManager code="listingNoData" />
                )}
                {!activityLogFetching && activityLogEntries && (
                    <React.Fragment>
                        <ActivityLogFilters entity={entity} id={match.params.id} />
                        <div className="activity-log__entries">{activityLogEntries}</div>
                    </React.Fragment>
                )}
            </div>
        );
    }
}

ActivityLog.defaultProps = {
    fetchActivityLogs: () => {},
    activityLog: [],
    activityLogFetching: false
};

ActivityLog.propTypes = {
    entity: PropTypes.string.isRequired,
    match: PropTypes.oneOfType([PropTypes.object]).isRequired,
    fetchActivityLogs: PropTypes.func,
    activityLog: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    activityLogFetching: PropTypes.bool
};

const mapDispatchToProps = dispatch => ({
    fetchActivityLogs: (entity, match) => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: activityLogActions.START_FETCHING_ACTIVITY_LOGS,
                entity,
                id: match.params.id
            });
        }).catch(error => ({ error }));
    }
});

//  Getting initial value populated in the form from the store
const mapStateToProps = (state, ownProps) => ({
    activityLog:
        getActivityLogData(state, ownProps.entity, ownProps.match.params.id) ||
        ownProps.activityLog,
    activityLogFetching: getActivityLogFetching(state)
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ActivityLog)
);
