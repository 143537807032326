/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedDate } from 'react-intl';
import Cardbox from 'erpcomponents/Cardbox';
import StatusBadge from 'erpcomponents/StatusBadge';
import './OrderInfoCard.scss';

const handleDate = date => {
    return <FormattedDate value={date} />;
};

// const handleShipmentStatus = shipmentStatus => {
//     const { status } = shipmentStatus;

//     return <StatusBadge text={status} />;
// };

const handleOrderStatus = orderStatus => {
    const { status } = orderStatus;
    let type = '';

    switch (status) {
        case 'Active':
            type = 'fulfilled';
            break;
        case 'Declined':
            type = 'new';
            break;
        default:
            type = 'voided';
            break;
    }

    return <StatusBadge text={status} type={type} />;
};

const handleCustomerName = customer => {
    const { first_name: firstName, last_name: lastName } = customer;
    const customerName = `${firstName} ${lastName}`;
    return customerName;
};

const handleOrderInfo = data => {
    const includedData = data && data.included;
    const orderInfo = [];
    if (includedData) {
        includedData.forEach(el => {
            orderInfo[el.type] = el;
        });
    }
    const customer = orderInfo && orderInfo.Customer && handleCustomerName(orderInfo.Customer);
    const orderStatus = orderInfo && orderInfo.Status && handleOrderStatus(orderInfo.Status);
    const orderDate = data && data.order_date && handleDate(data.order_date);
    const resellerName = orderInfo && orderInfo.Reseller && orderInfo.Reseller.reseller_name;
    const ticketSource = data && data.source;
    const shipmentStatus = data && data.shipped;

    const info = [
        { title: 'Purchase Date', data: orderDate },
        { title: 'Source', data: ticketSource },
        { title: 'Sold by', data: resellerName },
        { title: 'Customer', data: customer },
        { title: 'Order Status', data: orderStatus },
        { title: 'Shipment Status', data: shipmentStatus }
    ];

    return (
        <div className="order-info">
            <div className="order-info__container">
                {info.map(o => {
                    return (
                        <div className="order-info__data" key={o.title}>
                            <span className="order-info__data--header">{o.title}</span>
                            <span className="order-info__data--body">{o.data || '-'}</span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

const OrderInfoCard = ({ data }) => {
    const orderInfoData = data && handleOrderInfo(data);
    return orderInfoData && <Cardbox body={orderInfoData} />;
};

OrderInfoCard.propTypes = {
    data: PropTypes.oneOfType([PropTypes.object]).isRequired
};

export default OrderInfoCard;
