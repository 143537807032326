export const actions = {
    START_FETCHING_VENDORS: 'START_FETCHING_VENDORS',
    FETCHING_SUCCESSFUL_VENDORS: 'FETCHING_SUCCESSFUL_VENDORS',
    FETCHING_FAILED_VENDORS: 'FETCHING_FAILED_VENDORS',
    START_CREATE_VENDOR: 'START_CREATE_VENDOR',
    CREATE_VENDOR_SUCCESSFUL: 'CREATE_VENDOR_SUCCESSFUL',
    CREATE_VENDOR_FAILED: 'CREATE_VENDOR_FAILED',
    START_FETCHING_SINGLE_VENDOR: 'START_FETCHING_SINGLE_VENDOR',
    FETCH_SINGLE_VENDOR_SUCCESSFUL: 'FETCH_SINGLE_VENDOR_SUCCESSFUL',
    FETCH_SINGLE_VENDOR_FAILED: 'FETCH_SINGLE_VENDOR_FAILED',
    START_UPDATE_SINGLE_VENDOR: 'START_UPDATE_SINGLE_VENDOR',
    UPDATE_SINGLE_VENDOR_SUCCESSFUL: 'UPDATE_SINGLE_VENDOR_SUCCESSFUL',
    UPDATE_SINGLE_VENDOR_FAILED: 'UPDATE_SINGLE_VENDOR_FAILED',
    START_DELETE_SINGLE_VENDOR: 'START_DELETE_SINGLE_VENDOR',
    DELETE_SINGLE_VENDOR_SUCCESSFUL: 'DELETE_SINGLE_VENDOR_SUCCESSFUL',
    DELETE_SINGLE_VENDOR_FAILED: 'DELETE_SINGLE_VENDOR_FAILED',
    START_FETCHING_SINGLE_VENDOR_COMMENTS: 'START_FETCHING_SINGLE_VENDOR_COMMENTS',
    FETCH_SINGLE_VENDOR_COMMENTS_SUCCESSFUL: 'FETCH_SINGLE_VENDOR_COMMENTS_SUCCESSFUL',
    FETCH_SINGLE_VENDOR_COMMENTS_FAILED: 'FETCH_SINGLE_VENDOR_COMMENTS_FAILED',
    STORE_SINGLE_VENDOR_COMMENTS_DATA: 'STORE_SINGLE_VENDOR_COMMENTS_DATA',
    STORE_SINGLE_VENDOR_DATA: 'STORE_SINGLE_VENDOR_DATA',
    START_POSTING_SINGLE_VENDOR_COMMENT: 'START_POSTING_SINGLE_VENDOR_COMMENT',
    START_FETCHING_MANAGEMENT_WIDGET_DATA: 'START_FETCHING_MANAGEMENT_WIDGET_DATA',
    FETCH_MANAGEMENT_WIDGET_DATA_SUCCESSFUL: 'FETCH_MANAGEMENT_WIDGET_DATA_SUCCESSFUL',
    FETCH_MANAGEMENT_WIDGET_DATA_FAILED: 'FETCH_MANAGEMENT_WIDGET_DATA_FAILED'
};

export const initialState = {
    listingFetching: false,
    listingResponse: {
        data: [],
        meta: {},
        included: []
    },
    listingError: [],
    singleFetching: false,
    singleError: null,
    deleteSingleError: null,
    comments: {},
    managementWidget: {
        new: {
            fetching: false,
            count: null
        },
        contractPending: {
            fetching: false,
            count: null
        },
        paymentPending: {
            fetching: false,
            count: null
        },
        approved: {
            fetching: false,
            count: null
        }
    }
};

export default (state = initialState, { type, id, response, markingType }) => {
    switch (type) {
        case actions.START_FETCHING_VENDORS: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_VENDORS: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_VENDORS: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        case actions.START_FETCHING_SINGLE_VENDOR: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.FETCH_SINGLE_VENDOR_SUCCESSFUL: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.FETCH_SINGLE_VENDOR_FAILED: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.UPDATE_SINGLE_VENDOR_FAILED: {
            return {
                ...state,
                singleError: response
            };
        }
        case actions.DELETE_SINGLE_VENDOR_FAILED: {
            return {
                ...state,
                deleteSingleError: response
            };
        }
        case actions.START_FETCHING_SINGLE_VENDOR_COMMENTS: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.FETCH_SINGLE_VENDOR_COMMENTS_SUCCESSFUL: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.FETCH_SINGLE_VENDOR_COMMENTS_FAILED: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.STORE_SINGLE_VENDOR_COMMENTS_DATA: {
            // Updating the store
            return Object.assign({}, state, {
                comments: { [id]: response }
            });
        }
        case actions.STORE_SINGLE_VENDOR_DATA: {
            // Updating the store
            return Object.assign({}, state, {
                [id]: response.data
            });
        }
        case actions.START_FETCHING_MANAGEMENT_WIDGET_DATA: {
            return {
                ...state,
                managementWidget: {
                    ...state.managementWidget,
                    [markingType]: { ...state.managementWidget[markingType], ...{ fetching: true } }
                }
            };
        }
        case actions.FETCH_MANAGEMENT_WIDGET_DATA_SUCCESSFUL: {
            return {
                ...state,
                managementWidget: {
                    ...state.managementWidget,
                    [markingType]: {
                        ...state.managementWidget[markingType],
                        ...{ fetching: false, count: response }
                    }
                }
            };
        }
        case actions.FETCH_MANAGEMENT_WIDGET_DATA_FAILED: {
            return {
                ...state,
                managementWidget: {
                    ...state.managementWidget,
                    [markingType]: {
                        ...state.managementWidget[markingType],
                        ...{ fetching: false, count: '-' }
                    }
                }
            };
        }
        default:
            return state;
    }
};
