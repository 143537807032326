import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Tabs from 'erpcomponents/Tabs';

const EventEditTabs = ({ match }) => {
    const links = [
        {
            link: `/events/${match.params.id}/edit/event-details`,
            label: (
                <FormattedMessage id="EventEdit.Tabs.EventDetails" defaultMessage="Event Details" />
            )
        },
        {
            link: `/events/${match.params.id}/edit/seo`,
            label: <FormattedMessage id="EventEdit.Tabs.SEO" defaultMessage="SEO" />
        },
        {
            link: `/events/${match.params.id}/edit/thirdpartyconnect`,
            label: <FormattedMessage id="EventEdit.Tabs.3RDPC" defaultMessage="3rd Party Connect" />
        },
        {
            link: `/events/${match.params.id}/edit/tickets`,
            label: <FormattedMessage id="EventEdit.Tabs.Tickets" defaultMessage="Ticket Types" />
        },
        {
            link: `/events/${match.params.id}/edit/images`,
            label: <FormattedMessage id="EventEdit.Tabs.Images" defaultMessage="Images" />
        }
    ];

    return <Tabs links={links} />;
};

EventEditTabs.defaultProps = {
    match: {}
};

EventEditTabs.propTypes = {
    match: PropTypes.oneOfType([PropTypes.object])
};

export default withRouter(EventEditTabs);
