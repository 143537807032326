import { createSelector } from 'reselect';

export const getTicketTypesData = (state, id) =>
    state.ticketTypes && state.ticketTypes[id] && state.ticketTypes[id];

export const getSingleTicketTypesFetching = state => state.ticketTypes.singleFetching;

export const getSingleTicketTypesCreating = state => state.ticketTypes.singleCreating;

export const getSingleTicketTypesDeleting = state => state.ticketTypes.singleDeleting;

export const getSingleTicketTypesUpdating = state => state.ticketTypes.singleUpdating;

export default createSelector([
    getTicketTypesData,
    getSingleTicketTypesFetching,
    getSingleTicketTypesCreating,
    getSingleTicketTypesDeleting,
    getSingleTicketTypesUpdating
]);
