import React from 'react';
import { Doughnut } from 'react-chartjs-2';

const DoughnutChart = chartData => {
    return (
        <Doughnut
            data={chartData.data}
            width={60}
            height={60}
            options={{
                tooltips: {
                    enabled: false
                },
                legend: {
                    display: false
                },
                cutoutPercentage: 70
            }}
        />
    );
};

export default DoughnutChart;
