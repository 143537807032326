import React, { Component } from 'react';
import LayoutManager from 'erputils/LayoutManager';
import PageHeader from 'erpcomponents/Layout/PageHeader';
import PageContent from 'erpcomponents/Layout/PageContent';
import { withRouter } from 'react-router-dom';
import { FieldArray, reduxForm, SubmissionError } from 'redux-form';
import ResellerFilterRepeaterItem from 'erpcore/screens/Settings/screens/ResellerAdmin/components/ResellerFilterRepeaterItem';
import ResellerAdminTabs from 'erpcore/screens/Settings/components/ResellerAdminTabs';
import PropTypes from 'prop-types';
import { dtoForm } from 'erputils/dto';
import Form, { Repeater } from 'erpcore/components/Form';
import NotificationManager from 'erputils/NotificationManager';
import { connect } from 'react-redux';
import { getListingFetching, getListingResponse } from 'erpcomponents/Listing/Listing.selectors';
import { actions as listingActions } from 'erpcomponents/Listing/Listing.reducer';
import { actions as resellerFilterActions } from 'erpcore/screens/Settings/screens/ResellerAdmin/ResellerFilter/ResellerFilter.reducer';
import reduxFormErrorMapper from 'erputils/reduxFormErrorMapper';
import {
    getSingleResellerFilterCreating,
    getSingleResellerFilterDeleting,
    getSingleResellerFilterUpdating
} from 'erpcore/screens/Settings/screens/ResellerAdmin/ResellerFilter/ResellerFilter.selectors';

class ResellerFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount = () => {
        const { fetchResellerFilters } = this.props;
        fetchResellerFilters();
    };

    handleSaveNewItem = ({ itemData = {} }) => {
        const { dispatch, initialValues } = this.props;

        delete initialValues.included;
        let { value } = initialValues;
        itemData.filter_name = !itemData.filter_name ? 'event_series' : itemData.filter_name;
        const lastPosition =
            value && value.length >= 1
                ? value.reduce((a, b) => (a.position > b.position ? a : b)).position
                : 0;
        value = value
            ? [...value, { ...itemData, position: value.length === 0 ? 0 : lastPosition + 1 }]
            : [{ ...itemData, position: lastPosition }];
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: resellerFilterActions.START_CREATE_RESELLER_FILTER,
                formData: {
                    filter_sorts: value
                }
            })
        )
            .then(() => {
                this.handleAfterAnyResellerFilterApiUpdate();
            })
            .catch(() => {
                // throw new SubmissionError(reduxFormErrorMapper(error));
            });
    };

    handleSortEnd = formData => {
        const { dispatch } = this.props;
        const value =
            formData.allData &&
            formData.allData.map((record, index) => ({ ...record, position: index }));
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: resellerFilterActions.REQUEST_UPDATE_SINGLE_RESELLER_FILTER,
                formData: {
                    filter_sorts: value
                }
            })
        ).catch(error => {
            throw new SubmissionError(reduxFormErrorMapper(error));
        });
    };

    handleRemoveItem = ({ itemData = {} }) => {
        const { dispatch, initialValues } = this.props;
        const value = initialValues.value.filter(record => record.position !== itemData.position);

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: resellerFilterActions.START_DELETE_SINGLE_RESELLER_FILTER,
                formData: {
                    filter_sorts: value
                }
            })
        )
            .then(() => {
                this.handleAfterAnyResellerFilterApiUpdate();
            })
            .catch(() => {
                // throw new SubmissionError(reduxFormErrorMapper(error));
            });
    };

    handleAfterAnyResellerFilterApiUpdate() {
        const { fetchResellerFilters, reset } = this.props;
        fetchResellerFilters().then(() => {
            reset();
        });
    }

    render() {
        const {
            resellerFiltersListingFetching,
            initialValues,
            error,
            singleResellerFilterCreating,
            singleResellerFilterDeleting,
            singleResellerFilterUpdating
        } = this.props;
        return (
            <LayoutManager slot="main" className="main--narrow" layoutType="merge">
                <PageHeader title="Settings - Reseller Admin" />
                <ResellerAdminTabs />
                <PageContent>
                    {error && error.code && <NotificationManager code={error.code} />}

                    <Form>
                        <FieldArray
                            name="value"
                            id="value"
                            component={Repeater}
                            RepeatableItem={ResellerFilterRepeaterItem}
                            loading={
                                resellerFiltersListingFetching ||
                                singleResellerFilterCreating ||
                                singleResellerFilterDeleting ||
                                singleResellerFilterUpdating
                            }
                            data={initialValues}
                            onSortStart={() => {}}
                            onSortEnd={this.handleSortEnd}
                            onSaveNewItem={this.handleSaveNewItem}
                            uniqueIdentifier="position"
                            onRemoveItem={this.handleRemoveItem}
                        />
                    </Form>
                </PageContent>
            </LayoutManager>
        );
    }
}

ResellerFilter.defaultProps = {
    // headerMenusData: {},
    resellerFiltersListingFetching: false,
    error: null,
    singleResellerFilterCreating: false,
    singleResellerFilterDeleting: false,
    singleResellerFilterUpdating: false
};

ResellerFilter.propTypes = {
    // headerMenusData: PropTypes.oneOfType([PropTypes.object]),
    fetchResellerFilters: PropTypes.func.isRequired,
    resellerFiltersListingFetching: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    initialValues: PropTypes.oneOfType([PropTypes.object]).isRequired,
    error: PropTypes.oneOfType([PropTypes.object]),
    singleResellerFilterCreating: PropTypes.bool,
    singleResellerFilterDeleting: PropTypes.bool,
    singleResellerFilterUpdating: PropTypes.bool
};

ResellerFilter = reduxForm({
    form: 'ResellerFilterForm',
    enableReinitialize: true,
    keepDirtyOnReinitialize: false, // important
    updateUnregisteredFields: false // important
})(ResellerFilter);

const mapStateToProps = state => ({
    resellerFiltersListingFetching: getListingFetching(state, 'resellerFilters'),
    resellerFiltersData: dtoForm(getListingResponse(state, 'resellerFilters')),
    initialValues: dtoForm(getListingResponse(state, 'resellerFilters')),
    singleResellerFilterCreating: getSingleResellerFilterCreating(state),
    singleResellerFilterDeleting: getSingleResellerFilterDeleting(state),
    singleResellerFilterUpdating: getSingleResellerFilterUpdating(state)
});

const mapDispatchToProps = dispatch => ({
    fetchResellerFilters: params => {
        const listingParams = Object.assign({ pagination: false }, params);

        dispatch({
            type: listingActions.START_FETCHING_LISTING,
            params: listingParams,
            entity: 'RESELLER_FILTERS',
            endpoint: '/api/general-settings/reseller-filters-sort'
        });
    }
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ResellerFilter)
);
