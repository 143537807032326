import { takeLatest, takeEvery, put, call, select } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import { getListingUrlQueryParams } from 'erputils/RouterManager/RouterManager.selectors';
import { parseParamsForApi } from 'erputils/utils';
import { actions as listingActions } from 'erpcomponents/Listing/Listing.reducer';
import { actions as notificationManagerActions } from 'erputils/NotificationManager/NotificationManager.reducer';
import dto from 'erputils/dto';
import { actions as vendorsActions } from './Vendors.reducer';

/**
 * Create Vendor
 * @param  {Object} promise
 * @return {Object} formData
 */

export function* createVendor({ promise, formData }) {
    try {
        const createVendorAPI = yield restClient.post(`api/vendors/applicant`, formData);
        yield put({
            type: vendorsActions.CREATE_VENDOR_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: createVendorAPI.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: vendorsActions.CREATE_VENDOR_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Fetch Vendor
 * @param  {Object} promise
 * @return {string} id Vendor id
 */
export function* fetchVendor({ promise, id, params }) {
    try {
        const fetchVendorAPI = yield restClient.get(`api/vendors/${id}`, { params });
        yield put({
            type: vendorsActions.FETCH_SINGLE_VENDOR_SUCCESSFUL
        });
        yield put({
            type: vendorsActions.STORE_SINGLE_VENDOR_DATA,
            id,
            response: dto(fetchVendorAPI.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: vendorsActions.FETCH_SINGLE_VENDOR_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Update Vendor single data
 * @param  {Object} id ID of an Vendor
 * @return {Object} Response from API
 */
export function* updateSingleVendor({ promise, formData, id }) {
    try {
        const updateSingleVendorAPI = yield restClient.put(`api/vendors/${id}`, formData);
        yield put({
            type: vendorsActions.UPDATE_SINGLE_VENDOR_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: updateSingleVendorAPI.data
        });
        yield put({
            type: vendorsActions.STORE_SINGLE_VENDOR_DATA,
            id,
            response: dto(updateSingleVendorAPI.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: vendorsActions.UPDATE_SINGLE_VENDOR_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Delete Single Vendor
 * @param  {Object} id ID of an Vendor
 * @return {Object} Response from API
 */
export function* deleteSingleVendor({ promise, id }) {
    try {
        // Merge default with current params for listing refresh purpose
        const params = Object.assign(
            {},
            { include: 'event,company' },
            yield select(getListingUrlQueryParams)
        );

        const deleteSingleVendorAPI = yield restClient.delete(`api/vendors/${id}`);
        yield put({
            type: vendorsActions.DELETE_SINGLE_VENDOR_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: deleteSingleVendorAPI.data
        });
        yield put({
            type: listingActions.START_FETCHING_LISTING,
            entity: 'VENDORS',
            endpoint: 'api/vendors',
            params: parseParamsForApi(params)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: vendorsActions.DELETE_SINGLE_VENDOR_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Fetch Vendor Management Widget Data
 * @param  {Object} filters
 * @return {Integer} Total count of vendors for marking/status
 */
export function* fetchVendorsManagementDataByType({ promise, params, markingType }) {
    try {
        const fetchData = yield restClient.get('api/vendors', { params });
        yield put({
            type: vendorsActions.FETCH_MANAGEMENT_WIDGET_DATA_SUCCESSFUL,
            markingType,
            response:
                fetchData.data.meta !== undefined && fetchData.data.meta.totalItems !== undefined
                    ? fetchData.data.meta.totalItems
                    : null
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: vendorsActions.FETCH_MANAGEMENT_WIDGET_DATA_FAILED,
            markingType
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Fetch Vendor Comments
 * @param  {Object} promise
 * @return {string} id Vendor id
 */
export function* fetchVendorComments({ promise, id, params }) {
    try {
        const fetchVendorCOmmentsAPI = yield restClient.get(`api/vendors/${id}/comments`, {
            params
        });
        yield put({
            type: vendorsActions.FETCH_SINGLE_VENDOR_COMMENTS_SUCCESSFUL
        });
        yield put({
            type: vendorsActions.STORE_SINGLE_VENDOR_COMMENTS_DATA,
            id,
            response: dto(fetchVendorCOmmentsAPI.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: vendorsActions.FETCH_SINGLE_VENDOR_COMMENTS_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Register action to watcher
 */
export const vendorsSaga = [
    takeLatest(vendorsActions.START_CREATE_VENDOR, createVendor),
    takeLatest(vendorsActions.START_FETCHING_SINGLE_VENDOR, fetchVendor),
    takeLatest(vendorsActions.START_UPDATE_SINGLE_VENDOR, updateSingleVendor),
    takeLatest(vendorsActions.START_DELETE_SINGLE_VENDOR, deleteSingleVendor),
    takeLatest(vendorsActions.START_FETCHING_SINGLE_VENDOR_COMMENTS, fetchVendorComments),
    takeEvery(
        vendorsActions.START_FETCHING_MANAGEMENT_WIDGET_DATA,
        fetchVendorsManagementDataByType
    )
];
