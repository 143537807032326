import { takeLatest, call, put } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import dto from 'erputils/dto';
import { actions as dashboardActions } from './Dashboard.reducer';

/**
 * Fetch Ticketzone event ID
 * @param  {Object} promise
 * @return {Object} params
 */
export function* fetchTicketzoneEventID({ promise, eventID, date }) {
    try {
        const fetchTicketzoneEventIDAPI = yield restClient.get(
            `api/ticketzone-events?event=/api/events/${eventID}&ticketzoneDate=${date}`
        );

        const ticketZoneEventID =
            fetchTicketzoneEventIDAPI &&
            fetchTicketzoneEventIDAPI.data &&
            fetchTicketzoneEventIDAPI.data.data &&
            fetchTicketzoneEventIDAPI.data.data[0] &&
            fetchTicketzoneEventIDAPI.data.data[0].attributes.ticketzone_id;

        if (ticketZoneEventID) {
            yield put({
                type: dashboardActions.FETCHING_TICKETZONE_EVENT_ID_SUCCESSFUL,
                id: ticketZoneEventID
            });
        }
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: dashboardActions.FETCHING_TICKETZONE_EVENT_ID_FAILED
        });
        yield call(promise.reject, error && error.response && error.response.data);
    }
}

/**
 * Fetch Event Series
 * @param  {Object} promise
 * @return {Object} params
 */
export function* fetchEventSeries({ promise }) {
    try {
        const fetchEventSeriesAPI = yield restClient.get(`api/event-series?pagination=false`);
        yield put({
            type: dashboardActions.FETCHING_DASHBOARD_EVENT_SERIES_SUCCESSFUL,
            response: dto(fetchEventSeriesAPI.data)
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: dashboardActions.FETCHING_DASHBOARD_EVENT_SERIES_FAILED,
            response:
                error && error.response !== undefined && error.response.data
                    ? error.response.data
                    : null
        });
        yield call(promise.reject, error && error.response !== undefined && error.response.data);
    }
}

/**
 * Fetch Ticket Type
 * @param  {Object} promise
 * @return {Object} params
 */
export function* fetchTicketTypeData({ promise, iri }) {
    try {
        const fetchTicketTypeDataAPI = yield restClient.get(
            `api/ticket-types/report?filters[ticket_types]=${iri}`,
            {
                headers: { Accept: ' application/json' }
            }
        );
        yield put({
            type: dashboardActions.FETCHING_TICKET_TYPE_DATA_SUCCESSFUL
        });
        yield put({
            type: dashboardActions.STORE_TICKET_TYPE_DATA,
            response: dto(fetchTicketTypeDataAPI.data),
            iri
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: dashboardActions.FETCHING_TICKET_TYPE_DATA_FAILED,
            response:
                error && error.response !== undefined && error.response.data
                    ? error.response.data
                    : null
        });
        yield call(promise.reject, error && error.response !== undefined && error.response.data);
    }
}

/**
 * Register action to watcher
 */
export const dashboardsSaga = [
    takeLatest(dashboardActions.START_FETCHING_TICKETZONE_EVENT_ID, fetchTicketzoneEventID),
    takeLatest(dashboardActions.START_FETCHING_DASHBOARD_EVENT_SERIES, fetchEventSeries),
    takeLatest(dashboardActions.START_FETCHING_TICKET_TYPE_DATA, fetchTicketTypeData)
];
