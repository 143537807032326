import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import 'erpcore/screens/Tables/screens/TableAllotments/PrintGuestlistPage.scss';

class PrintPage extends React.Component {
    getTitle() {
        const { eventData } = this.props;
        return `${eventData && eventData.name ? `${eventData.name} - ` : ''}
                ${
                    eventData &&
                    eventData.location !== undefined &&
                    eventData.location.venue_name !== undefined &&
                    eventData.location.venue_name
                        ? `${eventData.location.venue_name} - `
                        : ''
                }
                Guest List by Table #`;
    }

    getColumnCount() {
        const { eventData } = this.props;

        let count = 8;

        if (
            eventData &&
            eventData.tickets_include &&
            Object.keys(eventData.tickets_include).length
        ) {
            Object.keys(eventData.tickets_include).forEach(includeKey => {
                if (eventData.tickets_include[includeKey] === true) {
                    count++; // eslint-disable-line no-plusplus
                }
            });
        }

        return count;
    }

    getTableHeaderIncludes(response = 'content') {
        const { eventData } = this.props;

        const output = [];

        if (
            eventData &&
            eventData.tickets_include &&
            Object.keys(eventData.tickets_include).length
        ) {
            Object.keys(eventData.tickets_include).forEach(includeKey => {
                if (eventData.tickets_include[includeKey] === true) {
                    if (response === 'keys') {
                        output.push(includeKey);
                    } else {
                        output.push(<th key={includeKey}>{includeKey}</th>);
                    }
                }
            });
        }

        return output;
    }

    getTableHeader() {
        return (
            <thead>
                <tr className="print-guestlist-page__data-title">
                    <th colSpan={this.getColumnCount()}>{this.getTitle()}</th>
                </tr>
                <tr className="print-guestlist-page__data-header">
                    <th>Table</th>
                    <th>Location</th>
                    <th>Last</th>
                    <th>First</th>
                    <th>Size</th>
                    <th>Type</th>
                    <th>Order</th>
                    {this.getTableHeaderIncludes()}
                    <th style={{ minWidth: '150px' }}>Notes</th>
                </tr>
            </thead>
        );
    }

    getTableFooter() {
        return (
            <tfoot className="print-guestlist-page__data-footer">
                <tr>
                    <td colSpan={this.getColumnCount()}>
                        <p className="print-guestlist-page__data-footer-counter" />
                    </td>
                </tr>
            </tfoot>
        );
    }

    getTableGroups(table) {
        const { getGroupById } = this.props;
        const output = [];

        if (table.groups && table.groups.length) {
            table.groups.forEach(groupItem => {
                if (groupItem.id) {
                    const group = getGroupById(groupItem.id);
                    if (group) {
                        const ticketTypes = [];
                        const orderNumbers = [];
                        let customerLastName = '';
                        let customerFirstName = '';
                        if (group.orders && group.orders.length) {
                            // customer
                            if (
                                group.orders[0].customer !== undefined &&
                                group.orders[0].customer
                            ) {
                                if (group.orders[0].customer.last_name) {
                                    customerLastName = group.orders[0].customer.last_name;
                                }
                                if (group.orders[0].customer.last_name) {
                                    customerFirstName = group.orders[0].customer.first_name;
                                }
                            }
                            // orders
                            group.orders.forEach(order => {
                                if (order.order_number !== undefined && order.order_number.length) {
                                    orderNumbers.push(order.order_number);
                                }
                                if (order.ticket_types !== undefined && order.ticket_types.length) {
                                    order.ticket_types.forEach(ticketType => {
                                        if (
                                            ticketType.name !== undefined &&
                                            ticketType.name &&
                                            !ticketTypes.includes(ticketType.name)
                                        ) {
                                            ticketTypes.push(ticketType.name);
                                        }
                                    });
                                }
                            });
                        }
                        output.push({
                            key: `${table.id}-${group.id}`,
                            ...group,
                            ticketTypes,
                            orderNumbers,
                            customerLastName,
                            customerFirstName
                        });
                    }
                }
            });
        }

        return output;
    }

    getTableItem(table) {
        const groups = this.getTableGroups(table);
        const output = [];

        if (groups.length) {
            groups.forEach(group => {
                output.push(
                    <tr key={group.key} className="print-guestlist-page__data-table">
                        <td className="print-guestlist-page__data-table-cell-bold">
                            {table.table_number && table.table_number}
                        </td>
                        <td className="print-guestlist-page__data-table-cell-bold">
                            {table.room && table.room.name}
                        </td>
                        <td>{group.customerLastName}</td>
                        <td>{group.customerFirstName}</td>
                        <td>{group.group_size && group.group_size}</td>
                        <td>
                            {group.ticketTypes.map((ticketType, i) => [i > 0 && ', ', ticketType])}
                        </td>
                        <td>
                            {group.orderNumbers.map((orderNumber, i) => [
                                i > 0 && ', ',
                                orderNumber
                            ])}
                        </td>
                        {this.getTableHeaderIncludes('keys').map(includeKey => {
                            return (
                                <td className="print-guestlist-page__data-table-cell-borders">
                                    {group.includes !== undefined &&
                                    group.includes &&
                                    group.includes[includeKey] !== undefined &&
                                    group.includes[includeKey]
                                        ? group.includes[includeKey]
                                        : '0'}
                                </td>
                            );
                        })}
                        <td className="print-guestlist-page__data-table-cell-borders">
                            {table.note ? (
                                <div dangerouslySetInnerHTML={{ __html: table.note }} /> // eslint-disable-line react/no-danger
                            ) : (
                                ''
                            )}
                        </td>
                    </tr>
                );
            });
        } else {
            output.push(
                <tr
                    key={table.id}
                    className="print-guestlist-page__data-table print-guestlist-page__data-table--empty"
                >
                    <td>{table.table_number && table.table_number}</td>
                    <td>{table.room && table.room.name}</td>
                    <td />
                    <td />
                    <td>0/{table.max_seats}</td>
                    <td />
                    <td />
                    {this.getTableHeaderIncludes('keys').map(() => (
                        <td className="print-guestlist-page__data-table-cell-borders" />
                    ))}
                    <td className="print-guestlist-page__data-table-cell-borders">
                        {table.note ? (
                            <div dangerouslySetInnerHTML={{ __html: table.note }} /> // eslint-disable-line react/no-danger
                        ) : (
                            ''
                        )}
                    </td>
                </tr>
            );
        }

        return output;
    }

    render() {
        const { tablesData } = this.props;

        return (
            <div className="print-guestlist-page">
                <Helmet>
                    <title>{this.getTitle()}</title>
                </Helmet>
                <table className="print-guestlist-page__data">
                    {this.getTableHeader()}
                    <tbody>{tablesData && tablesData.map(table => this.getTableItem(table))}</tbody>
                    {this.getTableFooter()}
                </table>
            </div>
        );
    }
}

PrintPage.defaultProps = {
    eventData: null,
    tablesData: null,
    getGroupById: () => {}
};

PrintPage.propTypes = {
    eventData: PropTypes.oneOfType([PropTypes.object]),
    tablesData: PropTypes.oneOfType([PropTypes.array]),
    getGroupById: PropTypes.func
};

export default PrintPage;
