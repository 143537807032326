import { createSelector } from 'reselect';

export const getSignedIn = state => state.authManager.isSignedIn;

export const getMeData = state => state.authManager.user;

export const getUserCurrentOrganization = state =>
    state.authManager.userOrganization && state.authManager.userOrganization;

export const getUserRole = state =>
    state.authManager.isSignedIn === true ? state.authManager.user.role : '';

export default createSelector([getSignedIn, getMeData, getUserRole, getUserCurrentOrganization]);
