import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import './DoughnutChart.scss';

class DoughnutChart extends React.Component {
    // If used as callback to send data to <BarChart /> , canvas argument is usable, its used for creating gradients for fill amongst other things //
    getChartData = () => {
        const { data } = this.props;

        const defaultChartData = {
            datasets: [
                {
                    fill: false,
                    lineTension: 0.1,
                    borderColor: '#fff',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: '#fff',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10
                }
            ]
        };

        const chartData = { ...defaultChartData, ...data };

        return chartData;
    };

    getChartOptions() {
        // Options are passed as props if needed, options include yAxis and xAxis labels, responsivness, tooltip data and style etc... //
        const { options } = this.props;

        const defaultOptions = {
            responsive: true,
            maintainAspectRatio: false,
            cutoutPercentage: 75,
            legend: { display: false, labels: { usePointStyle: true } }
        };

        const chartOptions = { ...defaultOptions, ...options };

        return chartOptions;
    }

    render() {
        const { legend, getElementAtEvent, getDatasetAtEvent } = this.props;

        return (
            <div className="doughnut-chart">
                <Doughnut
                    data={this.getChartData}
                    options={this.getChartOptions()}
                    legend={legend}
                    getElementAtEvent={getElementAtEvent}
                    getDatasetAtEvent={getDatasetAtEvent}
                />
            </div>
        );
    }
}

DoughnutChart.defaultProps = {
    data: {},
    options: {},
    legend: {},
    getElementAtEvent: () => {},
    getDatasetAtEvent: () => {}
};

DoughnutChart.propTypes = {
    data: PropTypes.oneOfType([PropTypes.object]),
    options: PropTypes.oneOfType([PropTypes.object]),
    legend: PropTypes.oneOfType([PropTypes.object]),
    getElementAtEvent: PropTypes.func,
    getDatasetAtEvent: PropTypes.func
};

export default DoughnutChart;
