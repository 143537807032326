import React from 'react';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Tabs from 'erpcomponents/Tabs';

const IntegrationsTabs = () => {
    const links = [
        // {
        //     link: `/settings/integrations/ticketsocket-feed`,
        //     label: (
        //         <FormattedMessage
        //             id="IntegrationsTabs.TicketsocketFeed"
        //             defaultMessage="Ticketsocket Feed"
        //         />
        //     )
        // },
        {
            link: `/settings/integrations/ticketsocket-api`,
            label: (
                <FormattedMessage
                    id="IntegrationsTabs.TickesocketApi"
                    defaultMessage="Ticketsocket API"
                />
            )
        },
        {
            link: '/settings/integrations/getout-api',
            label: <FormattedMessage id="IntegrationsTabs.GetOutApi" defaultMessage="GetOut API" />
        },
        {
            link: '/settings/integrations/importer-logs',
            label: (
                <FormattedMessage
                    id="IntegrationsTabs.ImporterLogs"
                    defaultMessage="Importer Logs"
                />
            )
        }
    ];

    return <Tabs links={links} />;
};

export default withRouter(IntegrationsTabs);
