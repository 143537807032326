import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import 'erpcore/screens/Tables/screens/TableAllotments/PrintGuestlistPage.scss';

class PrintPage extends React.Component {
    getTitle() {
        const { eventData } = this.props;
        return `${eventData && eventData.name ? `${eventData.name} - ` : ''}
                ${
                    eventData &&
                    eventData.location !== undefined &&
                    eventData.location.venue_name !== undefined &&
                    eventData.location.venue_name
                        ? `${eventData.location.venue_name} - `
                        : ''
                }
                Guest List by Last Name`;
    }

    getColumnCount() {
        const { eventData } = this.props;

        let count = 8;

        if (
            eventData &&
            eventData.tickets_include &&
            Object.keys(eventData.tickets_include).length
        ) {
            Object.keys(eventData.tickets_include).forEach(includeKey => {
                if (eventData.tickets_include[includeKey] === true) {
                    count++; // eslint-disable-line no-plusplus
                }
            });
        }

        return count;
    }

    getTableHeaderIncludes(response = 'content') {
        const { eventData } = this.props;

        const output = [];

        if (
            eventData &&
            eventData.tickets_include &&
            Object.keys(eventData.tickets_include).length
        ) {
            Object.keys(eventData.tickets_include).forEach(includeKey => {
                if (eventData.tickets_include[includeKey] === true) {
                    if (response === 'keys') {
                        output.push(includeKey);
                    } else {
                        output.push(<th key={includeKey}>{includeKey}</th>);
                    }
                }
            });
        }

        return output;
    }

    getTableHeader() {
        return (
            <thead>
                <tr className="print-guestlist-page__data-title">
                    <th colSpan={this.getColumnCount()}>{this.getTitle()}</th>
                </tr>
                <tr className="print-guestlist-page__data-header">
                    <th>Last</th>
                    <th>First</th>
                    <th>Table</th>
                    <th>Location</th>
                    <th>Size</th>
                    <th>Type</th>
                    <th>Order</th>
                    {this.getTableHeaderIncludes()}
                    <th style={{ minWidth: '150px' }}>Notes</th>
                </tr>
            </thead>
        );
    }

    getTableFooter() {
        return (
            <tfoot className="print-guestlist-page__data-footer">
                <tr>
                    <td colSpan={this.getColumnCount()}>
                        <p className="print-guestlist-page__data-footer-counter" />
                    </td>
                </tr>
            </tfoot>
        );
    }

    getCustomersListData() {
        const { tablesData, getGroupById } = this.props;

        const customers = [];

        if (tablesData && tablesData.length) {
            tablesData.forEach(table => {
                if (table.groups && table.groups.length) {
                    table.groups.forEach(groupItem => {
                        if (groupItem.id) {
                            const group = getGroupById(groupItem.id);
                            if (group) {
                                const ticketTypes = [];
                                const orderNumbers = [];
                                if (group.orders && group.orders.length) {
                                    group.orders.forEach(order => {
                                        if (
                                            order.id !== undefined &&
                                            order.customer !== undefined &&
                                            order.customer &&
                                            order.customer.id !== undefined
                                        ) {
                                            // order numbers
                                            if (
                                                order.order_number !== undefined &&
                                                order.order_number.length
                                            ) {
                                                orderNumbers.push(order.order_number);
                                            }
                                            // ticket types
                                            if (
                                                order.ticket_types !== undefined &&
                                                order.ticket_types.length
                                            ) {
                                                order.ticket_types.forEach(ticketType => {
                                                    if (
                                                        ticketType.name !== undefined &&
                                                        ticketType.name &&
                                                        !ticketTypes.includes(ticketType.name)
                                                    ) {
                                                        ticketTypes.push(ticketType.name);
                                                    }
                                                });
                                            }
                                            // add customer to list with table and group data
                                            customers.push({
                                                key: `${table.id}-${group.id}-${order.id}-${
                                                    order.customer.id
                                                }`,
                                                data: order.customer,
                                                table,
                                                group: {
                                                    ...group,
                                                    ticketTypes,
                                                    orderNumbers
                                                }
                                            });
                                        }
                                    });
                                }
                            }
                        }
                    });
                }
            });
        }

        customers.sort((a, b) => {
            const aName = a.last_name || '';
            const bName = b.last_name || '';
            if (aName.toLowerCase() < bName.toLowerCase()) return -1;
            if (aName.toLowerCase() > bName.toLowerCase()) return 1;
            return 0;
        });

        return customers;
    }

    getCustomerItem(customer) {
        // customer.table, customer.group and customer.data is never undefined at this point
        return (
            <tr key={customer.data.key} className="print-guestlist-page__data-table">
                <td>{customer.data.last_name && customer.data.last_name}</td>
                <td>{customer.data.first_name && customer.data.first_name}</td>
                <td className="print-guestlist-page__data-table-cell-bold">
                    {customer.table.table_number && customer.table.table_number}
                </td>
                <td className="print-guestlist-page__data-table-cell-bold">
                    {customer.table.room && customer.table.room.name}
                </td>
                <td>{customer.group.group_size && customer.group.group_size}</td>
                <td>
                    {customer.group.ticketTypes.map((ticketType, i) => [i > 0 && ', ', ticketType])}
                </td>
                <td>
                    {customer.group.orderNumbers.map((orderNumber, i) => [
                        i > 0 && ', ',
                        orderNumber
                    ])}
                </td>
                {this.getTableHeaderIncludes('keys').map(includeKey => {
                    return (
                        <td className="print-guestlist-page__data-table-cell-borders">
                            {customer.group.includes !== undefined &&
                            customer.group.includes &&
                            customer.group.includes[includeKey] !== undefined &&
                            customer.group.includes[includeKey]
                                ? customer.group.includes[includeKey]
                                : '0'}
                        </td>
                    );
                })}
                <td className="print-guestlist-page__data-table-cell-borders">
                    {customer.table.note ? (
                        <div dangerouslySetInnerHTML={{ __html: customer.table.note }} /> // eslint-disable-line react/no-danger
                    ) : (
                        ''
                    )}
                </td>
            </tr>
        );
    }

    render() {
        const customers = this.getCustomersListData();

        return (
            <div className="print-guestlist-page">
                <Helmet>
                    <title>{this.getTitle()}</title>
                </Helmet>
                <table className="print-guestlist-page__data">
                    {this.getTableHeader()}
                    <tbody>
                        {customers && customers.map(customer => this.getCustomerItem(customer))}
                    </tbody>
                    {this.getTableFooter()}
                </table>
            </div>
        );
    }
}

PrintPage.defaultProps = {
    eventData: null,
    tablesData: null,
    getGroupById: () => {}
};

PrintPage.propTypes = {
    eventData: PropTypes.oneOfType([PropTypes.object]),
    tablesData: PropTypes.oneOfType([PropTypes.array]),
    getGroupById: PropTypes.func
};

export default PrintPage;
