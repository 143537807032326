import React, { Component } from 'react';
import LayoutManager from 'erputils/LayoutManager';
import PageHeader from 'erpcomponents/Layout/PageHeader';
import PageContent from 'erpcomponents/Layout/PageContent';
import { withRouter } from 'react-router-dom';
import SettingsTabs from 'erpcore/screens/Settings/components/SettingsTabs';
import PropTypes from 'prop-types';
import Listing from 'erpcomponents/Listing';
import { FormattedMessage } from 'react-intl';
import ListingTableActions from 'erpcomponents/Listing/components/TableActions';
import { connect } from 'react-redux';
import { getListingFetching, getListingResponse } from 'erpcomponents/Listing/Listing.selectors';
import { actions as listingActions } from 'erpcomponents/Listing/Listing.reducer';
import BarTypeCreate from 'erpcore/screens/Settings/components/BarTypeCreate';
import BarTypeDelete from 'erpcore/screens/Settings/components/BarTypeDelete';
import BarTypeEdit from 'erpcore/screens/Settings/components/BarTypeEdit';

const reducerName = 'barTypes';

class BarTypes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            barTypeId: null
        };
        this.toggleEdit = this.toggleEdit.bind(this);
        this.resetBarTypeId = this.resetBarTypeId.bind(this);
    }

    toggleEdit(id) {
        this.setState({
            barTypeId: id
        });
    }

    resetBarTypeId() {
        this.setState({ barTypeId: null });
    }

    tableData() {
        const { barTypesData } = this.props;
        const table = {};
        table.data = [];

        // Table Schema
        table.schema = [
            {
                title: <FormattedMessage id="Settings.BarTypes.Table" defaultMessage="Bar Types" />,
                field: 'eventName',
                sortable: 'name'
            },
            {
                title: (
                    <FormattedMessage
                        id="Settings.BarTypes.Table.Actions"
                        defaultMessage="Actions"
                    />
                ),
                field: 'actions'
            }
        ];

        barTypesData.data.map(row => {
            return table.data.push({
                id: row.id,
                eventName: row.name,
                actions: (
                    <ListingTableActions>
                        <ListingTableActions.Action>
                            <BarTypeEdit
                                id={row.id}
                                key={`BarTypeEdit_${row.id}`}
                                form="BarTypeEditForm"
                            />
                        </ListingTableActions.Action>
                        <ListingTableActions.Action>
                            <BarTypeDelete
                                id={row.id}
                                key={`BarTypeDelete_${row.id}`}
                                form="BarTypeDeleteForm"
                            />
                        </ListingTableActions.Action>
                    </ListingTableActions>
                )
            });
        });

        return table;
    }

    render() {
        const { fetchBarTypes, barTypesListingFetching, barTypesData } = this.props;
        const { barTypeId } = this.state;

        return (
            <LayoutManager slot="main" className="main--narrow" layoutType="merge">
                <PageHeader title="Settings - Categories" />
                <SettingsTabs />
                <PageContent>
                    {!barTypeId ? (
                        <BarTypeCreate />
                    ) : (
                        <BarTypeEdit resetId={this.resetBarTypeId} id={barTypeId} />
                    )}
                    <Listing
                        name={reducerName}
                        reducerName={reducerName}
                        loading={barTypesListingFetching}
                        hideSearch
                        meta={barTypesData.meta}
                        table={this.tableData()}
                        onListingConfigUpdate={params => fetchBarTypes(params)}
                    />
                </PageContent>
            </LayoutManager>
        );
    }
}

BarTypes.defaultProps = {
    barTypesData: {},
    barTypesListingFetching: false
};

BarTypes.propTypes = {
    barTypesData: PropTypes.oneOfType([PropTypes.object]),
    fetchBarTypes: PropTypes.func.isRequired,
    barTypesListingFetching: PropTypes.bool
};

const mapStateToProps = state => ({
    barTypesListingFetching: getListingFetching(state, 'barTypes'),
    barTypesData: getListingResponse(state, 'barTypes')
});

const mapDispatchToProps = dispatch => ({
    fetchBarTypes: params => {
        // Getting included data from API by setting defaultParams
        const listingParams = Object.assign({ pagination: false }, params);

        dispatch({
            type: listingActions.START_FETCHING_LISTING,
            params: listingParams,
            name: reducerName,
            entity: 'BAR_TYPES',
            endpoint: 'api/bar-types'
        });
    }
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(BarTypes)
);
