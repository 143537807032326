import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LayoutManager from 'erputils/LayoutManager';
import PageHeader from 'erpcomponents/Layout/PageHeader';
import PageContent from 'erpcomponents/Layout/PageContent';
import SingleOrderTabs from 'erpcore/screens/Orders/components/SingleOrderTabs';
import { dtoForm } from 'erputils/dto';
import {
    getSingleOrderData,
    getSingleOrderFetching
} from 'erpcore/screens/Orders/Orders.selectors';
import { actions as ordersActions } from 'erpcore/screens/Orders/Orders.reducer';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PageLoader from 'erpcomponents/PageLoader';
import OrderInfoCard from 'erpcore/screens/Orders/components/OrderInfoCard';
import PaymentInfoCard from 'erpcore/screens/Orders/components/PaymentInfoCard';
import CustomerInfoCard from 'erpcore/screens/Orders/components/CustomerInfoCard';
import CustomerInfoAccordion from 'erpcore/screens/Orders/components/CustomerInfoAccordion';
import CustomerQuestions from 'erpcore/screens/Orders/components/CustomerQuestions';
import GroupEditModal from 'erpcore/screens/Groups/components/GroupEditModal';
import CustomerEditModal from 'erpcore/screens/Customers/components/CustomerEditModal';
import GroupMergeModal from 'erpcore/screens/Groups/components/GroupMergeModal';

import './OrderInfo.scss';

class OrderInfo extends Component {
    constructor() {
        super();
        this.state = {
            editGroupModal: false,
            editCustomerModal: false,
            mergeGroupModal: false
        };
    }

    componentDidMount() {
        const { fetchOrders } = this.props;
        fetchOrders();
    }

    handleGroupEditModal = () => {
        const { editGroupModal } = this.state;
        this.setState({ editGroupModal: !editGroupModal });
    };

    handleCustomerEditModal = () => {
        const { editCustomerModal } = this.state;
        this.setState({ editCustomerModal: !editCustomerModal });
    };

    handleMergeGroupModal = () => {
        const { mergeGroupModal } = this.state;
        this.setState({ mergeGroupModal: !mergeGroupModal });
    };

    render() {
        const { initialValues, singleFetching, fetchOrders } = this.props;
        const { editGroupModal, editCustomerModal, mergeGroupModal } = this.state;
        const groupId =
            initialValues && initialValues.group && initialValues.group.match(/\d+/g)[0];
        const customerId =
            initialValues && initialValues.customer && initialValues.customer.match(/\d+/g)[0];
        const eventId =
            initialValues && initialValues.event && initialValues.event.match(/\d+/g)[0];
        const mainGroup =
            initialValues &&
            initialValues.included &&
            initialValues.included.find(record => record.iri === initialValues.group);
        return (
            <LayoutManager slot="main" className="main" layoutType="merge">
                <PageHeader
                    title={initialValues && `Order ${initialValues.order_number}`}
                    backButtonLink="/orders"
                />
                {singleFetching ? <PageLoader /> : <SingleOrderTabs />}
                {initialValues && (
                    <PageContent>
                        {editGroupModal && (
                            <GroupEditModal
                                id={groupId && groupId}
                                eventId={eventId && eventId}
                                form={`GroupEditModalForm${groupId}`}
                                refreshData={fetchOrders}
                            />
                        )}
                        {editCustomerModal && (
                            <CustomerEditModal
                                id={customerId && customerId}
                                form={`CustomerEditModalForm${customerId}`}
                                refreshData={fetchOrders}
                            />
                        )}
                        {mergeGroupModal && (
                            <GroupMergeModal
                                refreshData={fetchOrders}
                                eventId={eventId && eventId}
                                initialValues={{ primary_group_name: mainGroup.name, ...mainGroup }}
                            />
                        )}
                        <div className="order">
                            <OrderInfoCard data={initialValues} />
                        </div>
                        <div className="info">
                            <div className="info__payment">
                                <div className="info__title">Payment Info</div>
                                <PaymentInfoCard data={initialValues} />
                                <div className="questions">
                                    <div className="questions__title">Customer Questions</div>
                                    <CustomerQuestions data={initialValues} />
                                </div>
                            </div>
                            <div className="info__customer">
                                <div className="info__title">Customer Info</div>
                                <div className="info__body">
                                    <div className="info__body--card">
                                        <CustomerInfoCard
                                            data={initialValues}
                                            handleCustomerEditModal={this.handleCustomerEditModal}
                                        />
                                    </div>
                                    <div className="info__body--accordion">
                                        <CustomerInfoAccordion
                                            data={initialValues}
                                            handleGroupEditModal={this.handleGroupEditModal}
                                            handleMergeGroupModal={this.handleMergeGroupModal}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </PageContent>
                )}
            </LayoutManager>
        );
    }
}

OrderInfo.defaultProps = {
    initialValues: {},
    fetchOrders: () => {},
    singleFetching: false
};

OrderInfo.propTypes = {
    fetchOrders: PropTypes.func,
    initialValues: PropTypes.oneOfType([PropTypes.object]),
    singleFetching: PropTypes.bool
};

//  Getting initial value populated in the form from the store
const mapStateToProps = (state, ownProps) => ({
    initialValues: dtoForm(getSingleOrderData(state, ownProps.match.params.id)),
    singleFetching: getSingleOrderFetching(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchOrders: params => {
        // Getting included data from API by setting defaultParams
        const defaultParams = {
            include:
                'status,deliveryStatus,reseller,tickets,customer,customer.city,customer.state,customer.country,event,group,order.company,group.tables'
        };
        const orderParams = Object.assign({}, defaultParams, params);

        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: ordersActions.START_FETCHING_SINGLE_ORDER,
                id: ownProps.match.params.id,
                params: orderParams
            });
        }).catch(error => ({ error }));
    },
    syncHubspot: orderId => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: ordersActions.START_SYNC_HUBSPOT,
                formData: { order_id: orderId }
            });
        }).catch(error => ({ error }));
    }
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(OrderInfo)
);
