import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { stringOperations } from 'erpdata/filterOperations';
import LayoutManager from 'erputils/LayoutManager';
import Listing from 'erpcomponents/Listing';
import ListingTableActions from 'erpcomponents/Listing/components/TableActions';
import PageHeader from 'erpcomponents/Layout/PageHeader';
import Button from 'erpcomponents/Button';
import Svg from 'erpcomponents/Svg';
import Tooltip from 'erpcomponents/Tooltip';
import { actions as listingActions } from 'erpcomponents/Listing/Listing.reducer';
import { getListingFetching, getListingResponse } from 'erpcomponents/Listing/Listing.selectors';
import CompanyDelete from '../../components/CompanyDelete';

const reducerName = 'companies';

class CompaniesListing extends Component {
    tableData() {
        const { companiesData } = this.props;
        const table = {};
        table.data = [];

        // Advanced Filters
        table.filters = [
            {
                name: 'name',
                label: (
                    <FormattedMessage
                        id="Companies.Table.Filters.Name.Label"
                        defaultMessage="Company Name"
                    />
                ),
                filterFields: {
                    operator: {
                        component: 'select',
                        fieldProps: {
                            clearable: false,
                            label: (
                                <FormattedMessage
                                    id="Users.Table.Filters.Name.Operator"
                                    defaultMessage="Operator"
                                />
                            ),
                            options: stringOperations
                        },
                        fieldValidation: [{ validator: 'required' }]
                    },
                    value: {
                        component: 'text',
                        fieldProps: {
                            label: (
                                <FormattedMessage
                                    id="Users.Table.Filters.Name"
                                    defaultMessage="Company Name"
                                />
                            )
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            },
            {
                name: 'city.name',
                label: (
                    <FormattedMessage id="Users.Table.Filter.City.Label" defaultMessage="City" />
                ),
                filterFields: {
                    operator: {
                        component: 'select',
                        fieldProps: {
                            clearable: false,
                            label: (
                                <FormattedMessage
                                    id="Users.Table.Filter.City.Operator"
                                    defaultMessage="Operator"
                                />
                            ),
                            options: stringOperations
                        },
                        fieldValidation: [{ validator: 'required' }]
                    },
                    value: {
                        component: 'text',
                        fieldProps: {
                            label: (
                                <FormattedMessage
                                    id="Users.Table.Filter.City"
                                    defaultMessage="City"
                                />
                            )
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            },
            {
                name: 'state.name',
                label: (
                    <FormattedMessage id="Users.Table.Filter.State.Label" defaultMessage="State" />
                ),
                filterFields: {
                    operator: {
                        component: 'select',
                        fieldProps: {
                            clearable: false,
                            label: (
                                <FormattedMessage
                                    id="Users.Table.Filter.State.Operator"
                                    defaultMessage="Operator"
                                />
                            ),
                            options: stringOperations
                        },
                        fieldValidation: [{ validator: 'required' }]
                    },
                    value: {
                        component: 'text',
                        fieldProps: {
                            label: (
                                <FormattedMessage
                                    id="Users.Table.Filter.State"
                                    defaultMessage="State"
                                />
                            )
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            },
            {
                name: 'country.name',
                label: (
                    <FormattedMessage
                        id="Users.Table.Filter.Country.Label"
                        defaultMessage="Country"
                    />
                ),
                filterFields: {
                    operator: {
                        component: 'select',
                        fieldProps: {
                            clearable: false,
                            label: (
                                <FormattedMessage
                                    id="Users.Table.Filter.Country.Operator"
                                    defaultMessage="Operator"
                                />
                            ),
                            options: stringOperations
                        },
                        fieldValidation: [{ validator: 'required' }]
                    },
                    value: {
                        component: 'text',
                        fieldProps: {
                            label: (
                                <FormattedMessage
                                    id="Users.Table.Filter.Country"
                                    defaultMessage="Country"
                                />
                            )
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            }
        ];
        // Table Schema
        table.schema = [
            {
                title: (
                    <FormattedMessage
                        id="Companies.Table.CompanyName"
                        defaultMessage="Company Name"
                    />
                ),
                field: 'name',
                sortable: 'name'
            },
            {
                title: <FormattedMessage id="Companies.Table.City" defaultMessage="City" />,
                field: 'city',
                sortable: 'city.name'
            },
            {
                title: <FormattedMessage id="Companies.Table.Address" defaultMessage="Address" />,
                field: 'address',
                sortable: 'address'
            },
            {
                title: (
                    <FormattedMessage
                        id="Companies.Table.PrimaryPhone"
                        defaultMessage="Primary Phone"
                    />
                ),
                field: 'phone',
                sortable: false
            },
            {
                title: (
                    <FormattedMessage
                        id="Companies.Table.PrimaryEmail"
                        defaultMessage="Primary Email"
                    />
                ),
                field: 'email',
                sortable: false
            },
            {
                title: <FormattedMessage id="Companies.Table.Actions" defaultMessage="Actions" />,
                field: 'actions'
            }
        ];
        // Table Data
        companiesData.data.map(row => {
            return table.data.push({
                id: row.id,
                name: row.name,
                city: row.city.name,
                address: row.address,
                phone: row.phone_number,
                email: row.email,
                actions: (
                    <ListingTableActions>
                        <ListingTableActions.Action>
                            <Tooltip
                                content={
                                    <FormattedMessage
                                        id="Companies.Table.Actions.Edit"
                                        defaultMessage="Edit Company"
                                    />
                                }
                            >
                                <NavLink to={`/companies/${row.id}/edit`}>
                                    <Svg icon="edit" />
                                </NavLink>
                            </Tooltip>
                        </ListingTableActions.Action>
                        <ListingTableActions.Action>
                            <CompanyDelete id={row.id} key={`CompanyDelete ${row.id}`} />
                        </ListingTableActions.Action>
                    </ListingTableActions>
                )
            });
        });

        return table;
    }

    render() {
        const { companiesListingFetching, companiesData, fetchCompanies } = this.props;

        return (
            <LayoutManager slot="main" layoutType="merge">
                <PageHeader
                    title={
                        <FormattedMessage id="CompaniesListing.title" defaultMessage="Companies" />
                    }
                >
                    <Button
                        label={
                            <FormattedMessage
                                id="Companies.Table.AddNew"
                                defaultMessage="Add New"
                            />
                        }
                        href="/companies/create"
                    />
                </PageHeader>
                <Listing
                    name={reducerName}
                    reducerName={reducerName}
                    loading={companiesListingFetching}
                    meta={companiesData.meta}
                    table={this.tableData()}
                    onListingConfigUpdate={params => fetchCompanies(params)}
                />
            </LayoutManager>
        );
    }
}

CompaniesListing.defaultProps = {
    companiesListingFetching: false,
    companiesData: {}
};

CompaniesListing.propTypes = {
    fetchCompanies: PropTypes.func.isRequired,
    companiesListingFetching: PropTypes.bool,
    companiesData: PropTypes.oneOfType([PropTypes.object])
};

//  Redux
const mapStateToProps = state => ({
    companiesListingFetching: getListingFetching(state, 'companies'),
    companiesData: getListingResponse(state, 'companies')
});

const mapDispatchToProps = dispatch => ({
    fetchCompanies: params => {
        // Getting included data from API by setting defaultParams
        const defaultParams = {
            include: 'city,contacts'
        };
        const listingParams = Object.assign({}, defaultParams, params);

        dispatch({
            type: listingActions.START_FETCHING_LISTING,
            params: listingParams,
            name: reducerName,
            entity: 'COMPANIES',
            endpoint: 'api/companies'
        });
    }
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(CompaniesListing)
);
