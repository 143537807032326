export const actions = {
    START_FETCHING_EVENTS: 'START_FETCHING_EVENTS',
    FETCHING_SUCCESSFUL_EVENTS: 'FETCHING_SUCCESSFUL_EVENTS',
    FETCHING_FAILED_EVENTS: 'FETCHING_FAILED_EVENTS',
    START_CREATE_EVENT: 'START_CREATE_EVENT',
    CREATE_EVENT_SUCCESSFUL: 'CREATE_EVENT_SUCCESSFUL',
    CREATE_EVENT_FAILED: 'CREATE_EVENT_FAILED',
    EVENT_CREATE_LOCATION_SUCCESSFUL: 'EVENT_CREATE_LOCATION_SUCCESSFUL',
    EVENT_CREATE_LOCATION_FAILED: 'EVENT_CREATE_LOCATION_FAILED',
    START_FETCHING_SINGLE_EVENT: 'START_FETCHING_SINGLE_EVENT',
    FETCH_SINGLE_EVENT_SUCCESSFUL: 'FETCH_SINGLE_EVENT_SUCCESSFUL',
    FETCH_SINGLE_EVENT_FAILED: 'FETCH_SINGLE_EVENT_FAILED',
    START_UPDATE_SINGLE_EVENT: 'START_UPDATE_SINGLE_EVENT',
    START_UPDATE_SINGLE_EVENT_SEO: 'START_UPDATE_SINGLE_EVENT_SEO',
    START_UPDATE_SINGLE_EVENT_3RDPC: 'START_UPDATE_SINGLE_EVENT_3RDPC',
    UPDATE_SINGLE_EVENT_SUCCESSFUL: 'UPDATE_SINGLE_EVENT_SUCCESSFUL',
    UPDATE_SINGLE_EVENT_SUCCESSFUL_SEO: 'UPDATE_SINGLE_EVENT_SUCCESSFUL_SEO',
    UPDATE_SINGLE_EVENT_SUCCESSFUL_3RDPC: 'UPDATE_SINGLE_EVENT_SUCCESSFUL_3RDPC',
    UPDATE_SINGLE_EVENT_FAILED: 'UPDATE_SINGLE_EVENT_FAILED',
    UPDATE_SINGLE_EVENT_FAILED_SEO: 'UPDATE_SINGLE_EVENT_FAILED_SEO',
    UPDATE_SINGLE_EVENT_FAILED_3RDPC: 'UPDATE_SINGLE_EVENT_FAILED_3RDPC',
    START_DELETE_SINGLE_EVENT: 'START_DELETE_SINGLE_EVENT',
    DELETE_SINGLE_EVENT_SUCCESSFUL: 'DELETE_SINGLE_EVENT_SUCCESSFUL',
    DELETE_SINGLE_EVENT_FAILED: 'DELETE_SINGLE_EVENT_FAILED',
    STORE_SINGLE_EVENT_DATA: 'STORE_SINGLE_EVENT_DATA',
    STORE_SINGLE_EVENT_DATA_SEO: 'STORE_SINGLE_EVENT_DATA_SEO',
    STORE_SINGLE_EVENT_DATA_3RDPC: 'STORE_SINGLE_EVENT_DATA_3RDPC'
};

export const initialState = {
    listingFetching: false,
    listingData: [],
    listingMeta: {},
    listingError: [],
    singleFetching: false,
    events: []
};

export default (state = initialState, { type, id, response }) => {
    switch (type) {
        case actions.START_FETCHING_EVENTS: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_EVENTS: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_EVENTS: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        case actions.START_CREATE_EVENT: {
            return state;
        }
        case actions.START_FETCHING_SINGLE_EVENT: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.FETCH_SINGLE_EVENT_SUCCESSFUL: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.FETCH_SINGLE_EVENT_FAILED: {
            return {
                ...state,
                singleFetching: false,
                singleError: response
            };
        }
        case actions.UPDATE_SINGLE_EVENT_FAILED: {
            return {
                ...state,
                singleError: response
            };
        }
        case actions.UPDATE_SINGLE_EVENT_FAILED_SEO: {
            return {
                ...state,
                singleError: response
            };
        }
        case actions.UPDATE_SINGLE_EVENT_FAILED_3RDPC: {
            return {
                ...state,
                singleError: response
            };
        }
        case actions.STORE_SINGLE_EVENT_DATA: {
            return Object.assign({}, state, {
                [id]: response.data
            });
        }
        case actions.STORE_SINGLE_EVENT_DATA_SEO: {
            return Object.assign({}, state, {
                [id]: response.data
            });
        }
        case actions.STORE_SINGLE_EVENT_DATA_3RDPC: {
            return Object.assign({}, state, {
                [id]: response.data
            });
        }
        default:
            return state;
    }
};
