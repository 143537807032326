import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import './Tabs.scss';

const Tabs = ({ links, lessPadding }) => {
    if (!links) {
        return false;
    }

    return (
        <nav className={`tabs ${lessPadding ? 'tabs__less-padding' : ''}`}>
            <ul className="tabs__list">
                {links.map(item => {
                    const { link, label } = item;
                    return (
                        <li key={link} className="tabs__list-item">
                            <NavLink
                                to={link}
                                className="tabs__link"
                                activeClassName="tabs__link--active"
                            >
                                {label}
                            </NavLink>
                        </li>
                    );
                })}
            </ul>
        </nav>
    );
};

Tabs.defaultProps = {
    links: null,
    lessPadding: false
};

Tabs.propTypes = {
    links: PropTypes.arrayOf(
        PropTypes.shape({
            link: PropTypes.string,
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
        })
    ),
    lessPadding: PropTypes.bool
};

export default Tabs;
