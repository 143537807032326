import { takeLatest, put, call } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import { actions as notificationManagerActions } from 'erputils/NotificationManager/NotificationManager.reducer';
import dto from 'erputils/dto';
import { setToken } from 'erpcore/api';
import { actions as authActions } from 'erputils/AuthManager/AuthManager.reducer';
import { actions as routerManagerActions } from 'erputils/RouterManager/RouterManager.reducer';
import { actions as signUpActions } from './SignUp.reducer';

/**
 * Create Account Saga
 * @param  {Object} credentials Email and Password
 * @param  {Object} promise Promises
 * @return {Object} Response from API
 */
export function* createAccount({ formData, promise }) {
    try {
        const createAccountAPI = yield restClient.post('/api/users', formData);
        yield put({
            type: signUpActions.CREATE_ACCOUNT_SUCCESSFUL
        });
        // Continue with Sign In
        const { token } = createAccountAPI.data.data.attributes;

        // store token
        yield setToken(token);

        // Get user data
        try {
            const getMe = yield restClient.get(
                '/api/me?include=organization,organizations,city,image'
            );
            yield put({ type: authActions.STORE_USER_DATA, response: dto(getMe.data) });
            // redirect user to Dashboard
            yield put({ type: routerManagerActions.SET_REDIRECT_URL, response: '/dashboard' });
            yield put({ type: authActions.SIGN_IN_SUCCESSFUL, response: token });

            yield put({
                type: notificationManagerActions.SET_PAGE_NOTIFICATION,
                response: { code: 'user.createdSuccessfuly' }
            });
            yield call(promise.resolve);
        } catch (error) {
            yield put({
                type: authActions.SIGN_IN_FAILED
            });
            yield put({
                type: notificationManagerActions.SET_PAGE_NOTIFICATION,
                response: error.response.data
            });
            yield call(promise.reject, error.response.data);
        }
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: signUpActions.CREATE_ACCOUNT_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Fetch Invitation
 * @param  {Object} promise Promises
 * @return {Object} Response from API
 */
export function* fetchInvitation({ promise, token }) {
    try {
        const fetchInvitationAPI = yield restClient.get(`invitation/${token}`);
        yield put({
            type: signUpActions.FETCH_INVITATION_SUCCESSFUL,
            token,
            response: fetchInvitationAPI
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: signUpActions.FETCH_INVITATION_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Decline Invitation
 * @param  {Object} token
 * @param  {Object} promise Promises
 * @return {Object} Response from API
 */
export function* declineInvitation({ token, promise }) {
    try {
        yield restClient.delete(`invitation/${token}`);
        yield put({
            type: signUpActions.DECLINE_INVITATION_SUCCESSFUL
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: signUpActions.DECLINE_INVITATION_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Register action to watcher
 */
export const signUpSaga = [
    takeLatest(signUpActions.START_FETCHING_INVITATION, fetchInvitation),
    takeLatest(signUpActions.START_CREATE_ACCOUNT, createAccount),
    takeLatest(signUpActions.START_DECLINING_INVITATION, declineInvitation)
];
