import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage, FormattedDate, FormattedTime } from 'react-intl';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { diff } from 'deep-object-diff';

import PageHeader from 'erpcomponents/Layout/PageHeader';
import PageContent from 'erpcomponents/Layout/PageContent';
import Button from 'erpcomponents/Button';
import Form, { Switch, Text, DateTime } from 'erpcore/components/Form';
import PageLoader from 'erpcomponents/PageLoader';

import LayoutManager from 'erputils/LayoutManager';

import IntegrationsTabs from 'erpcore/screens/Settings/components/IntegrationsTabs';
import { actions as getOutApiActions } from 'erpcore/screens/Settings/screens/Integrations/GetOutAPI/GetOutAPI.reducer';
import {
    getOutApiSingleFetching,
    getGetOutInitialData
} from 'erpcore/screens/Settings/screens/Integrations/GetOutAPI/GetOutAPI.selectors';
import { actions as listingActions } from 'erpcomponents/Listing/Listing.reducer';
import { getListingFetching, getListingResponse } from 'erpcomponents/Listing/Listing.selectors';

const reducerName = 'orders';

class GetOutAPI extends Component {
    componentDidMount() {
        const { fetchGetOutApiData, fetchOrders } = this.props;
        fetchGetOutApiData();
        fetchOrders();
    }

    getLastOrderTimestamp() {
        const { ordersData } = this.props;
        if (
            ordersData &&
            ordersData.data &&
            ordersData.data.length > 0 &&
            ordersData.data[0] &&
            ordersData.data[0].source === 'Crave'
        ) {
            return ordersData.data[0].order_date;
        }
        return null;
    }

    handleAutomatedSyncChange = formData => {
        const { dispatch } = this.props;
        if (formData === true) {
            return new Promise((resolve, reject) =>
                dispatch({
                    promise: { resolve, reject },
                    type: getOutApiActions.START_UPDATE_GET_OUT_API,
                    property: 'autopull',
                    formData: { value: 'true' }
                })
            );
        }

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: getOutApiActions.START_UPDATE_GET_OUT_API,
                property: 'autopull',
                formData: { value: 'false' }
            })
        );
    };

    handleCreateNewChange = formData => {
        const { dispatch } = this.props;
        if (formData === true) {
            return new Promise((resolve, reject) =>
                dispatch({
                    promise: { resolve, reject },
                    type: getOutApiActions.START_UPDATE_GET_OUT_API,
                    property: 'createfrom',
                    formData: { value: 'true' }
                })
            );
        }

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: getOutApiActions.START_UPDATE_GET_OUT_API,
                property: 'createfrom',
                formData: { value: 'false' }
            })
        );
    };

    handleSinceLastImport = formData => {
        const { dispatch } = this.props;
        if (formData === true) {
            return new Promise((resolve, reject) =>
                dispatch({
                    promise: { resolve, reject },
                    type: getOutApiActions.START_UPDATE_GET_OUT_API,
                    property: 'importsince',
                    formData: { value: 'true' }
                })
            );
        }

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: getOutApiActions.START_UPDATE_GET_OUT_API,
                property: 'importsince',
                formData: { value: 'false' }
            })
        );
    };

    startSyncGetOut = () => {
        const { dispatch } = this.props;
        return new Promise((resolve, reject) =>
            dispatch({
                type: getOutApiActions.START_SYNC_GET_OUT_API,
                promise: { resolve, reject }
            })
        );
    };

    handleIntervalChange = formData => {
        const { dispatch, fetchGetOutApiData, initialValues } = this.props;
        formData = diff(initialValues, formData);
        if (formData.importIntervalGetOut) {
            new Promise((resolve, reject) =>
                dispatch({
                    promise: { resolve, reject },
                    type: getOutApiActions.START_UPDATE_GET_OUT_API,
                    property: 'interval',
                    formData: { value: formData.importIntervalGetOut }
                })
            )
                .then(() => fetchGetOutApiData())
                .catch(error => error);
        }
        if (formData.importFromGetOut) {
            new Promise((resolve, reject) =>
                dispatch({
                    promise: { resolve, reject },
                    type: getOutApiActions.START_UPDATE_GET_OUT_API,
                    property: 'importFrom',
                    formData: { value: formData.importFromGetOut }
                })
            )
                .then(() => fetchGetOutApiData())
                .catch(error => error);
        }
        return null;
    };

    handleImportOnlyEvents = formData => {
        const { dispatch } = this.props;
        if (formData === true) {
            return new Promise((resolve, reject) =>
                dispatch({
                    promise: { resolve, reject },
                    type: getOutApiActions.START_UPDATE_GET_OUT_API,
                    property: 'importOnlyEvents',
                    formData: { value: 'true' }
                })
            );
        }

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: getOutApiActions.START_UPDATE_GET_OUT_API,
                property: 'importOnlyEvents',
                formData: { value: 'false' }
            })
        );
    };

    handleChange = (event, newValue, previousValue, name) => {
        switch (name) {
            case 'automatedSyncGetOut':
                this.handleAutomatedSyncChange(newValue);
                break;
            case 'createNewDataGetOut':
                this.handleCreateNewChange(newValue);
                break;
            case 'sinceLastImportGetOut':
                this.handleSinceLastImport(newValue);
                break;
            case 'importOnlyEventsGetOut':
                this.handleImportOnlyEvents(newValue);
                break;
            default:
                break;
        }
    };

    render() {
        // const { onSyncNow, getData } = this.props;
        const {
            handleSubmit,
            submitting,
            pristine,
            invalid,
            singleFetching,
            ordersFetching
        } = this.props;
        const orderTimestamp = this.getLastOrderTimestamp();

        return (
            <LayoutManager slot="main" className="main--narrow" layoutType="merge">
                <PageHeader title="Settings" />
                <IntegrationsTabs />
                <PageContent>
                    {(singleFetching || ordersFetching || !orderTimestamp) && <PageLoader />}
                    <Form onSubmit={handleSubmit(this.handleIntervalChange)}>
                        <Form.SectionTitle>
                            <FormattedMessage
                                id="Settings.GetOutAPI.Title"
                                defaultMessage="GetOut API Settings"
                            />
                        </Form.SectionTitle>
                        <Form.Row>
                            <Field
                                name="automatedSyncGetOut"
                                id="automatedSyncGetOut"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="Settings.GetOutAPI.ImportOrders"
                                            defaultMessage="Automated Sync of Orders"
                                        />
                                    ),
                                    on: {
                                        label: 'automated-on',
                                        value: true,
                                        id: 'automated-on'
                                    },
                                    off: {
                                        label: 'automated-off',
                                        value: false,
                                        id: 'automated-off'
                                    },
                                    type: 'single',
                                    clearable: true,
                                    validation: 'required'
                                }}
                                component={Switch}
                                onChange={(event, newValue, previousValue, name) => {
                                    this.handleChange(event, newValue, previousValue, name);
                                }}
                            />
                        </Form.Row>
                        <Form.Row>
                            <Field
                                name="createNewDataGetOut"
                                id="createNewDataGetOut"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="Settings.GetOutAPI.CreateEvents"
                                            defaultMessage="Create new Events and Ticket Types Automatically"
                                        />
                                    ),

                                    on: {
                                        label: 'create-new-on',
                                        value: true,
                                        id: 'create-new-on'
                                    },
                                    off: {
                                        label: 'create-new-off',
                                        value: false,
                                        id: 'create-new-off'
                                    },
                                    type: 'single',
                                    clearable: true,
                                    validation: 'required'
                                }}
                                component={Switch}
                                onChange={(event, newValue, previousValue, name) => {
                                    this.handleChange(event, newValue, previousValue, name);
                                }}
                            />
                        </Form.Row>
                        <Form.Row>
                            <Field
                                name="sinceLastImportGetOut"
                                id="sinceLastImportGetOut"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="Settings.GetOutAPI.SinceLast"
                                            defaultMessage="Import only since last import"
                                        />
                                    ),

                                    on: {
                                        label: 'import-on',
                                        value: true,
                                        id: 'import-on'
                                    },
                                    off: {
                                        label: 'import-off',
                                        value: false,
                                        id: 'import-off'
                                    },
                                    type: 'single',
                                    clearable: true,
                                    validation: 'required'
                                }}
                                component={Switch}
                                onChange={(event, newValue, previousValue, name) => {
                                    this.handleChange(event, newValue, previousValue, name);
                                }}
                            />
                        </Form.Row>
                        <Form.Row>
                            <Field
                                name="importOnlyEventsGetOut"
                                id="importOnlyEventsGetOut"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="Settings.GtOutAPI.ImportEventsOnly"
                                            defaultMessage="Import only Events"
                                        />
                                    ),

                                    on: {
                                        label: 'on',
                                        value: true,
                                        id: 'only-events-on'
                                    },
                                    off: {
                                        label: 'off',
                                        value: false,
                                        id: 'only-events-off'
                                    },
                                    type: 'single',
                                    clearable: true,
                                    validation: 'required'
                                }}
                                component={Switch}
                                onChange={(event, newValue, previousValue, name) => {
                                    this.handleChange(event, newValue, previousValue, name);
                                }}
                            />
                        </Form.Row>
                        <Form.Row>
                            <Form.Columns>
                                <Form.Column>
                                    <Field
                                        name="importIntervalGetOut"
                                        id="importIntervalGetOut"
                                        fieldProps={{
                                            label: (
                                                <FormattedMessage
                                                    id="Settings.GetOut.Interval"
                                                    defaultMessage="GetOut Api Import Interval in minutes"
                                                />
                                            ),
                                            clearable: true
                                        }}
                                        component={Text}
                                    />
                                </Form.Column>
                                <Form.Column />
                            </Form.Columns>
                        </Form.Row>
                        <Form.Row>
                            <Form.Columns>
                                <Form.Column>
                                    <Field
                                        name="importFromGetOut"
                                        id="importFromGetOut"
                                        fieldProps={{
                                            label: (
                                                <FormattedMessage
                                                    id="Settings.GetOutAPI.ImportFrom"
                                                    defaultMessage="TicketSocket API Import From"
                                                />
                                            ),
                                            clearable: true
                                        }}
                                        component={DateTime}
                                    />
                                </Form.Column>
                                <Form.Column>
                                    <Button
                                        disabled={pristine || invalid}
                                        loading={submitting}
                                        submit
                                        label={
                                            <FormattedMessage
                                                id="Settings.FormButton.Interval"
                                                defaultMessage="Save"
                                            />
                                        }
                                    />
                                </Form.Column>
                            </Form.Columns>
                        </Form.Row>
                    </Form>
                    {orderTimestamp && (
                        <p>
                            <FormattedMessage
                                id="Settings.GetOutAPI.LastOrderStamp"
                                defaultMessage="Last order from Crave on: {date} {time}"
                                values={{
                                    date: (
                                        <FormattedDate
                                            value={orderTimestamp}
                                            day="numeric"
                                            month="long"
                                            year="numeric"
                                        />
                                    ),
                                    time: (
                                        <FormattedTime
                                            value={orderTimestamp}
                                            hour12
                                            hour="numeric"
                                            minute="numeric"
                                            second="numeric"
                                        />
                                    )
                                }}
                            />
                        </p>
                    )}
                    <Button label="Sync now" onClick={() => this.startSyncGetOut()} />
                </PageContent>
            </LayoutManager>
        );
    }
}

GetOutAPI.defaultProps = {
    // getData: [],
    submitting: false,
    pristine: false,
    invalid: false,
    singleFetching: false,
    initialValues: {},
    fetchOrders: () => {},
    ordersFetching: false,
    ordersData: {}
};

GetOutAPI.propTypes = {
    // categoriesListingFetching: PropTypes.bool
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
    // onSyncNow: PropTypes.func.isRequired,
    initialValues: PropTypes.oneOfType([PropTypes.object]),
    fetchGetOutApiData: PropTypes.func.isRequired,
    singleFetching: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    fetchOrders: PropTypes.func,
    ordersFetching: PropTypes.bool,
    ordersData: PropTypes.oneOfType([PropTypes.object])
};

GetOutAPI = reduxForm({
    form: 'GetOutAPIForm',
    enableReinitialize: true
})(GetOutAPI);

const mapStateToProps = state => ({
    // getData: getImportStatus(state),
    initialValues: getGetOutInitialData(state),
    singleFetching: getOutApiSingleFetching(state),
    ordersListingFetching: getListingFetching(state, reducerName),
    ordersData: getListingResponse(state, reducerName)
});

const mapDispatchToProps = dispatch => ({
    fetchGetOutApiData: () => {
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: getOutApiActions.START_FETCHING_GET_OUT_API
            })
        ).catch(error => error);
    },
    fetchOrders: params => {
        // Getting included data from API by setting defaultParams
        params = {
            'order_by[orderDate]': 'DESC',
            include:
                'status,deliveryStatus,reseller,tickets,customer,event,eventSeries,event.ticketTypes,event.company,group',
            'filters[source][equals]': 'Crave',
            limit: 1
        };

        dispatch({
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: 'ORDERS',
            name: reducerName,
            endpoint: 'api/orders'
        });
    }
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(GetOutAPI)
);
