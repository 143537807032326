export const actions = {
    START_FETCHING_CUSTOMERS: 'START_FETCHING_CUSTOMERS',
    FETCHING_SUCCESSFUL_CUSTOMERS: 'FETCHING_SUCCESSFUL_CUSTOMERS',
    FETCHING_FAILED_CUSTOMERS: 'FETCHING_FAILED_CUSTOMERS',
    START_CREATE_CUSTOMER: 'START_CREATE_CUSTOMER',
    CREATE_CUSTOMER_SUCCESSFUL: 'CREATE_CUSTOMER_SUCCESSFUL',
    CREATE_CUSTOMER_FAILED: 'CREATE_CUSTOMER_FAILED',
    START_FETCHING_SINGLE_CUSTOMER: 'START_FETCHING_SINGLE_CUSTOMER',
    FETCH_SINGLE_CUSTOMER_SUCCESSFUL: 'FETCH_SINGLE_CUSTOMER_SUCCESSFUL',
    FETCH_SINGLE_CUSTOMER_FAILED: 'FETCH_SINGLE_CUSTOMER_FAILED',
    START_UPDATE_SINGLE_CUSTOMER: 'START_UPDATE_SINGLE_CUSTOMER',
    UPDATE_SINGLE_CUSTOMER_SUCCESSFUL: 'UPDATE_SINGLE_CUSTOMER_SUCCESSFUL',
    UPDATE_SINGLE_CUSTOMER_FAILED: 'UPDATE_SINGLE_CUSTOMER_FAILED',
    START_DELETE_SINGLE_CUSTOMER: 'START_DELETE_SINGLE_CUSTOMER',
    DELETE_SINGLE_CUSTOMER_SUCCESSFUL: 'DELETE_SINGLE_CUSTOMER_SUCCESSFUL',
    DELETE_SINGLE_CUSTOMER_FAILED: 'DELETE_SINGLE_CUSTOMER_FAILED',
    STORE_SINGLE_CUSTOMER_DATA: 'STORE_SINGLE_CUSTOMER_DATA'
};

export const initialState = {
    listingFetching: false,
    listingResponse: {
        data: [],
        meta: {},
        included: []
    },
    listingError: [],
    singleFetching: false,
    singleError: null,
    deleteSingleError: null,
    packages: []
};

export default (state = initialState, { type, id, response }) => {
    switch (type) {
        case actions.START_FETCHING_CUSTOMERS: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_CUSTOMERS: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_CUSTOMERS: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        case actions.START_FETCHING_SINGLE_CUSTOMER: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.FETCH_SINGLE_CUSTOMER_SUCCESSFUL: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.FETCH_SINGLE_CUSTOMER_FAILED: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.UPDATE_SINGLE_CUSTOMER_FAILED: {
            return {
                ...state,
                singleError: response
            };
        }
        case actions.DELETE_SINGLE_CUSTOMER_FAILED: {
            return {
                ...state,
                deleteSingleError: response
            };
        }
        case actions.STORE_SINGLE_CUSTOMER_DATA: {
            // Updating the store
            return Object.assign({}, state, {
                [id]: response.data
            });
        }
        default:
            return state;
    }
};
