import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import Form, { Text } from 'erpcore/components/Form';
import { dtoForm } from 'erputils/dto';
import Button from 'erpcomponents/Button';
import Svg from 'erpcomponents/Svg';
import Tooltip from 'erpcomponents/Tooltip';
import PageLoader from 'erpcomponents/PageLoader';
import Modal from 'erpcomponents/Modal';
import { actions as eventSeriesActions } from 'erpcore/screens/Settings/screens/AccountingCategories/EventSeries/EventSeries.reducer';
import {
    getEventSeriesData,
    getSingleEventSeriesFetching
} from 'erpcore/screens/Settings/screens/AccountingCategories/EventSeries/EventSeries.selectors';

class EventSeriesEdit extends Component {
    constructor(props) {
        super(props);

        this.state = {
            opened: false
        };

        this.handleModal = this.handleModal.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        const { fetchEventSeriesData } = this.props;
        const { opened } = this.state;
        if (prevState.opened !== opened) {
            fetchEventSeriesData();
        }
    }

    onSubmit(formData) {
        const { id, dispatch } = this.props;

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: eventSeriesActions.START_UPDATE_SINGLE_EVENT_SERIES,
                id,
                formData
            })
        )
            .then(() => this.handleModal())
            .catch(error => {
                return error;
            });
    }

    handleModal() {
        const { opened: isOpen } = this.state;
        this.setState({ opened: !isOpen });
    }

    render() {
        const { handleSubmit, submitting, singleFetching, pristine, invalid } = this.props;
        const { opened } = this.state;
        return (
            <Fragment>
                <Tooltip
                    content={
                        <FormattedMessage
                            id="Settings.EventSeries.Table.Edit"
                            defaultMessage="Edit Event Series"
                        />
                    }
                >
                    <button type="button" onClick={() => this.handleModal()}>
                        <Svg icon="edit" />
                    </button>
                </Tooltip>
                <Modal
                    variation="small"
                    opened={opened}
                    onClose={() => this.handleModal()}
                    title={
                        <FormattedMessage
                            id="Settings.EventSeries.EditModal.title"
                            defaultMessage="Edit Event Series"
                        />
                    }
                >
                    {singleFetching && <PageLoader />}
                    <Form onSubmit={handleSubmit(this.onSubmit)}>
                        <Form.Row>
                            <Field
                                name="name"
                                id="name"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="Settings.EventSeries.SeriesName"
                                            defaultMessage="Series Name"
                                        />
                                    ),
                                    clearable: true,
                                    validation: 'required'
                                }}
                                component={Text}
                            />
                        </Form.Row>

                        <Button
                            submit
                            loading={submitting}
                            disabled={pristine || invalid}
                            label={
                                <FormattedMessage
                                    id="Settings.EventSeries.Table.Actions.Edit.Modal.Update"
                                    defaultMessage="Update"
                                />
                            }
                        />
                        <Button
                            onClick={() => this.handleModal()}
                            variation="secondary"
                            label={
                                <FormattedMessage
                                    id="Settings.EventSeries.Table.Actions.Edit.Modal.Cancel"
                                    defaultMessage="Cancel"
                                />
                            }
                        />
                    </Form>
                </Modal>
            </Fragment>
        );
    }
}

EventSeriesEdit.defaultProps = {
    submitting: false,
    id: null,
    dispatch: () => {},
    // parent: null
    pristine: false,
    invalid: false,
    singleFetching: false,
    fetchEventSeriesData: () => {}
};

EventSeriesEdit.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    dispatch: PropTypes.func,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    singleFetching: PropTypes.bool,
    fetchEventSeriesData: PropTypes.func

    // iri: PropTypes.string.isRequired
};

EventSeriesEdit = reduxForm({
    form: 'EventSeriesEditForm',
    enableReinitialize: true
})(EventSeriesEdit);

const mapStateToProps = (state, ownProps) => ({
    initialValues: dtoForm(getEventSeriesData(state, ownProps.id)),
    singleFetching: getSingleEventSeriesFetching(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchEventSeriesData: () => {
        // Getting included data from API by setting params

        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: eventSeriesActions.START_FETCHING_SINGLE_EVENT_SERIES,
                id: ownProps.id
            });
        }).catch(error => ({ error }));
    }
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(EventSeriesEdit)
);
