import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import LayoutManager from 'erputils/LayoutManager';
import PageHeader from 'erpcomponents/Layout/PageHeader';
import PageContent from 'erpcomponents/Layout/PageContent';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { actions as eventsActions } from 'erpcore/screens/Events/Events.reducer';
import { getEventData } from 'erpcore/screens/Events/Events.selectors';
import { actions as dashboardActions } from 'erpcore/screens/Dashboard/Dashboard.reducer';
import { getTicketTypeData } from 'erpcore/screens/Dashboard/Dashboard.selectors';
import TicketTypesReport from 'erpcomponents/Widgets/TicketTypesReport';
import ElementLoader from 'erpcomponents/ElementLoader';

class OverallSalesPerTicketType extends Component {
    componentDidMount() {
        const { fetchEventData, fetchTicketTypeData } = this.props;

        fetchEventData();
        fetchTicketTypeData();
    }

    render() {
        const { eventData, ticketTypeData, match } = this.props;
        const ticketTypeDataID = match.params && match.params.ticketTypeID;
        const ticketTypeName =
            (ticketTypeData &&
                ticketTypeData.data &&
                ticketTypeData.data[ticketTypeDataID] &&
                ticketTypeData.data[ticketTypeDataID][0] &&
                ticketTypeData.data[ticketTypeDataID][0].name) ||
            '';

        const eventName = eventData && eventData.name;

        return (
            <LayoutManager slot="main" layoutType="merge">
                <PageHeader
                    title={
                        <FormattedMessage
                            id="Dashboards.OverallSalesPerTicketType.title"
                            defaultMessage="Ticket Type Report - {eventName} {ticketTypeName}"
                            values={{
                                eventName,
                                ticketTypeName: ticketTypeName ? (
                                    `- ${ticketTypeName}`
                                ) : (
                                    <ElementLoader />
                                )
                            }}
                        />
                    }
                    backButtonLink={`/dashboard/overall-sales/${match &&
                        match.params &&
                        match.params.id}`}
                />
                <PageContent>
                    <PageContent.Row>
                        <TicketTypesReport
                            id="ticketTypesReport"
                            ticketTypeID={match.params.ticketTypeID}
                            size="12"
                        />
                    </PageContent.Row>
                </PageContent>
            </LayoutManager>
        );
    }
}

OverallSalesPerTicketType.defaultProps = {
    match: {},
    eventData: {},
    ticketTypeData: {}
};

OverallSalesPerTicketType.propTypes = {
    match: PropTypes.oneOfType([PropTypes.object]),
    eventData: PropTypes.oneOfType([PropTypes.object]),
    ticketTypeData: PropTypes.oneOfType([PropTypes.object]),
    fetchEventData: PropTypes.func.isRequired,
    fetchTicketTypeData: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => ({
    eventData: getEventData(state, ownProps.match.params.id),
    ticketTypeData: getTicketTypeData(
        state,
        `/api/ticket-types/${ownProps.match.params.ticketTypeID}`
    )
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchEventData: () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: eventsActions.START_FETCHING_SINGLE_EVENT,
                id: ownProps.match.params.id
            });
        }).catch(error => ({ error }));
    },
    fetchTicketTypeData: () => {
        const id = ownProps.match && ownProps.match.params && ownProps.match.params.ticketTypeID;
        const ticketTypeIri = `/api/ticket-types/${id}`;
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: dashboardActions.START_FETCHING_TICKET_TYPE_DATA,
                iri: ticketTypeIri
            });
        });
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OverallSalesPerTicketType);
