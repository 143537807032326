// TODO: location of this file is not yet defined
export const actions = {
    START_FETCHING_PRINT_SETTINGS_WIDTH: 'START_FETCHING_PRINT_SETTINGS_WIDTH',
    FETCHING_SUCCESSFUL_PRINT_SETTINGS_WIDTH: 'FETCHING_SUCCESSFUL_PRINT_SETTINGS_WIDTH',
    FETCHING_FAILED_PRINT_SETTINGS_WIDTH: 'FETCHING_FAILED_PRINT_SETTINGS_WIDTH',
    START_UPDATE_PRINT_SETTINGS_WIDTH: 'START_UPDATE_PRINT_SETTINGS_WIDTH',
    UPDATE_PRINT_SETTINGS_WIDTH_SUCCESSFUL: 'UPDATE_PRINT_SETTINGS_WIDTH_SUCCESSFUL',
    UPDATE_PRINT_SETTINGS_WIDTH_FAILED: 'UPDATE_PRINT_SETTINGS_WIDTH_FAILED',
    STORE_PRINT_SETTINGS_WIDTH_DATA: 'STORE_PRINT_SETTINGS_WIDTH_DATA',

    START_FETCHING_PRINT_SETTINGS_TITLE: 'START_FETCHING_PRINT_SETTINGS_TITLE',
    FETCHING_SUCCESSFUL_PRINT_SETTINGS_TITLE: 'FETCHING_SUCCESSFUL_PRINT_SETTINGS_TITLE',
    FETCHING_FAILED_PRINT_SETTINGS_TITLE: 'FETCHING_FAILED_PRINT_SETTINGS_TITLE',
    START_UPDATE_PRINT_SETTINGS_TITLE: 'START_UPDATE_PRINT_SETTINGS_TITLE',
    UPDATE_PRINT_SETTINGS_TITLE_SUCCESSFUL: 'UPDATE_PRINT_SETTINGS_TITLE_SUCCESSFUL',
    UPDATE_PRINT_SETTINGS_TITLE_FAILED: 'UPDATE_PRINT_SETTINGS_TITLE_FAILED',
    STORE_PRINT_SETTINGS_TITLE_DATA: 'STORE_PRINT_SETTINGS_TITLE_DATA',

    START_FETCHING_PRINT_SETTINGS: 'START_FETCHING_PRINT_SETTINGS',
    START_UPDATE_PRINT_SETTINGS: 'START_UPDATE_PRINT_SETTINGS',
    FETCHING_FAILED_PRINT_SETTINGS: 'FETCHING_FAILED_PRINT_SETTINGS',
    UPDATE_PRINT_SETTINGS_FAILED: 'UPDATE_PRINT_SETTINGS_FAILED',

    START_FETCHING_PRINT_SETTINGS_2X35_WIDTH: 'START_FETCHING_PRINT_SETTINGS_2X35_WIDTH',
    FETCHING_SUCCESSFUL_PRINT_SETTINGS_2X35_WIDTH: 'FETCHING_SUCCESSFUL_PRINT_SETTINGS_2X35_WIDTH',
    FETCHING_FAILED_PRINT_SETTINGS_2X35_WIDTH: 'FETCHING_FAILED_PRINT_SETTINGS_2X35_WIDTH',

    START_UPDATE_PRINT_2X35_SETTINGS: 'START_UPDATE_PRINT_2X35_SETTINGS',
    UPDATE_PRINT_2x35_SETTINGS_SUCCESSFUL: 'UPDATE_PRINT_3x35_SETTINGS_SUCCESSFUL',
    UPDATE_PRINT_2x35_SETTINGS_FAILED: 'UPDATE_PRINT_3x35_SETTINGS_FAILED',
    STORE_PRINT_SETTINGS_2x35_WIDTH_DATA: 'STORE_PRINT_SETTINGS_2x35_WIDTH_DATA',

    START_FETCHING_PRINT_SETTINGS_2X35_CONTAINER_WIDTH:
        'START_FETCHING_PRINT_SETTINGS_2X35_CONTAINER_WIDTH',
    FETCHING_SUCCESSFUL_PRINT_SETTINGS_2X35_CONTAINER_WIDTH:
        'FETCHING_SUCCESSFUL_PRINT_SETTINGS_2X35_CONTAINER_WIDTH',
    FETCHING_FAILED_PRINT_SETTINGS_2X35_CONTAINER_WIDTH:
        'FETCHING_FAILED_PRINT_SETTINGS_2X35_CONTAINER_WIDTH',

    START_UPDATE_PRINT_2X35_CONTAINER_WIDTH_SETTINGS:
        'START_UPDATE_PRINT_2X35_CONTAINER_WIDTH_SETTINGS',
    UPDATE_PRINT_2x35_CONTAINER_WIDTH_SETTINGS_SUCCESSFUL:
        'UPDATE_PRINT_3x35_CONTAINER_WIDTH_SETTINGS_SUCCESSFUL',
    UPDATE_PRINT_2x35_CONTAINER_WIDTH_SETTINGS_FAILED:
        'UPDATE_PRINT_3x35_S_CONTAINER_WIDTHETTINGS_FAILED',
    STORE_PRINT_SETTINGS_2x35_CONTAINER_WIDTH_DATA:
        'STORE_PRINT_SETTINGS_2x35_WIDTHCONTAINER__DATA',

    START_FETCHING_PRINT_SETTINGS_HEIGHT_2X35: 'START_FETCHING_PRINT_SETTINGS_HEIGHT_2X35',
    FETCHING_SUCCESSFUL_PRINT_SETTINGS_HEIGHT_2X35:
        'FETCHING_SUCCESSFUL_PRINT_SETTINGS_HEIGHT_2X35',
    FETCHING_FAILED_PRINT_SETTINGS_HEIGHT_2X35: 'FETCHING_FAILED_PRINT_SETTINGS_HEIGHT_2X35',

    START_UPDATE_PRINT_2X35_HEIGHT_SETTINGS: 'START_UPDATE_PRINT_2X35_HEIGHT_SETTINGS',
    UPDATE_PRINT_2x35_HEIGHT_SETTINGS_SUCCESSFUL: 'UPDATE_PRINT_2x35_SETTINGS_SUCCESSFUL',
    UPDATE_PRINT_2x35_HEIGHT_SETTINGS_FAILED: 'UPDATE_PRINT_2x35_SETTINGS_FAILED',
    STORE_PRINT_SETTINGS_2x35_HEIGHT_DATA: 'STORE_PRINT_SETTINGS_2x35_HEIGHT_DATA',

    START_FETCHING_PRINT_SETTINGS_2X35_OFFSET: 'START_FETCHING_PRINT_SETTINGS_2X35_OFFSET',
    FETCHING_SUCCESSFUL_PRINT_SETTINGS_2X35_OFFSET:
        'FETCHING_SUCCESSFUL_PRINT_SETTINGS_2X35_OFFSET',
    FETCHING_FAILED_PRINT_SETTINGS_2X35_OFFSET: 'FETCHING_FAILED_PRINT_SETTINGS_2X35_OFFSET',

    START_UPDATE_PRINT_2X35_SETTINGS_OFFSET: 'START_UPDATE_PRINT_2X35_SETTINGS_OFFSET',
    UPDATE_PRINT_2x35_SETTINGS_OFFSET_SUCCESSFUL: 'UPDATE_PRINT_3x35_SETTINGS_OFFSET_SUCCESSFUL',
    UPDATE_PRINT_2x35_SETTINGS_OFFSET_FAILED: 'UPDATE_PRINT_3x35_SETTINGS_OFFSET_FAILED',
    STORE_PRINT_SETTINGS_OFFSET_2x35_DATA: 'STORE_PRINT_SETTINGS_OFFSET_2x35_DATA',

    START_FETCHING_PRINT_SETTINGS_2X35_HORIZONTAL_OFFSET:
        'START_FETCHING_PRINT_SETTINGS_2X35_HORIZONTAL_OFFSET',
    FETCHING_SUCCESSFUL_PRINT_SETTINGS_2X35_HORIZONTAL_OFFSET:
        'FETCHING_SUCCESSFUL_PRINT_SETTINGS_2X35_HORIZONTAL_OFFSET',
    FETCHING_FAILED_PRINT_SETTINGS_2X35_HORIZONTAL_OFFSET:
        'FETCHING_FAILED_PRINT_SETTINGS_2X35_HORIZONTAL_OFFSET',

    START_UPDATE_PRINT_2X35_SETTINGS_HORIZONTAL_OFFSET:
        'START_UPDATE_PRINT_2X35_SETTINGS_HORIZONTAL_OFFSET',
    UPDATE_PRINT_2x35_SETTINGS_HORIZONTAL_OFFSET_SUCCESSFUL:
        'UPDATE_PRINT_3x35_SETTINGS_HORIZONTAL_OFFSET_SUCCESSFUL',
    UPDATE_PRINT_2x35_SETTINGS_HORIZONTAL_OFFSET_FAILED:
        'UPDATE_PRINT_3x35_SETTINGS_HORIZONTAL_OFFSET_FAILED',
    STORE_PRINT_SETTINGS_HORIZONTAL_OFFSET_2x35_DATA:
        'STORE_PRINT_SETTINGS_HORIZONTAL_OFFSET_2x35_DATA'
};

export const initialState = {
    singleFetching: false,
    singleError: false,
    printSettings: null,
    width: '',
    title: '',
    width2x35: '',
    height2x35: '',
    offset2x35: '',
    horizontalOffset2x35: '',
    containerWidth2x35: ''
};

export default (state = initialState, { type, response }) => {
    switch (type) {
        case actions.START_FETCHING_PRINT_SETTINGS: {
            return {
                ...state,
                singleFetching: true
            };
        }

        case actions.START_FETCHING_PRINT_SETTINGS_2X35_WIDTH: {
            return {
                ...state,
                singleFetching: true
            };
        }

        case actions.START_FETCHING_PRINT_SETTINGS_2X35_CONTAINER_WIDTH: {
            return {
                ...state,
                singleFetching: true
            };
        }

        case actions.START_FETCHING_PRINT_SETTINGS_2X35_OFFSET: {
            return {
                ...state,
                singleFetching: true
            };
        }

        case actions.START_FETCHING_PRINT_SETTINGS_2X35_HORIZONTAL_OFFSET: {
            return {
                ...state,
                singleFetching: true
            };
        }

        case actions.START_FETCHING_PRINT_SETTINGS_HEIGHT_2X35: {
            return {
                ...state,
                singleFetching: true
            };
        }

        case actions.FETCHING_SUCCESSFUL_PRINT_SETTINGS_2X35_WIDTH: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.FETCHING_SUCCESSFUL_PRINT_SETTINGS_2X35_CONTAINER_WIDTH: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.FETCHING_SUCCESSFUL_PRINT_SETTINGS_2X35_OFFSET: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.FETCHING_SUCCESSFUL_PRINT_SETTINGS_2X35_HORIZONTAL_OFFSET: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.FETCHING_SUCCESSFUL_PRINT_SETTINGS_HEIGHT_2X35: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.FETCHING_FAILED_PRINT_SETTINGS: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.START_FETCHING_PRINT_SETTINGS_WIDTH: {
            return {
                ...state,
                singleFetching: true
            };
        }

        case actions.FETCHING_SUCCESSFUL_PRINT_SETTINGS_WIDTH: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.FETCHING_FAILED_PRINT_SETTINGS_WIDTH: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.FETCHING_FAILED_PRINT_SETTINGS_2X35_WIDTH: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.FETCHING_FAILED_PRINT_SETTINGS_2X35_CONTAINER_WIDTH: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.FETCHING_FAILED_PRINT_SETTINGS_2X35_OFFSET: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.FETCHING_FAILED_PRINT_SETTINGS_2X35_HORIZONTAL_OFFSET: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.FETCHING_FAILED_PRINT_SETTINGS_HEIGHT_2X35: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.START_UPDATE_PRINT_SETTINGS_WIDTH: {
            return {
                ...state,
                singleUpdating: true
            };
        }
        case actions.START_UPDATE_PRINT_2X35_SETTINGS: {
            return {
                ...state,
                singleUpdating: true
            };
        }
        case actions.START_UPDATE_PRINT_2X35_CONTAINER_WIDTH_SETTINGS: {
            return {
                ...state,
                singleUpdating: true
            };
        }
        case actions.START_UPDATE_PRINT_2X35_SETTINGS_OFFSET: {
            return {
                ...state,
                singleUpdating: true
            };
        }
        case actions.START_UPDATE_PRINT_2X35_SETTINGS_HORIZONTAL_OFFSET: {
            return {
                ...state,
                singleUpdating: true
            };
        }
        case actions.START_UPDATE_PRINT_2X35_HEIGHT_SETTINGS: {
            return {
                ...state,
                singleUpdating: true
            };
        }
        case actions.UPDATE_PRINT_SETTINGS_WIDTH_SUCCESSFUL: {
            return {
                ...state,
                singleUpdating: false
            };
        }
        case actions.UPDATE_PRINT_2x35_SETTINGS_SUCCESSFUL: {
            return {
                ...state,
                singleUpdating: false
            };
        }
        case actions.UPDATE_PRINT_2x35_CONTAINER_WIDTH_SETTINGS_SUCCESSFUL: {
            return {
                ...state,
                singleUpdating: false
            };
        }
        case actions.UPDATE_PRINT_2x35_SETTINGS_OFFSET_SUCCESSFUL: {
            return {
                ...state,
                singleUpdating: false
            };
        }
        case actions.UPDATE_PRINT_2x35_SETTINGS_HORIZONTAL_OFFSET_SUCCESSFUL: {
            return {
                ...state,
                singleUpdating: false
            };
        }
        case actions.UPDATE_PRINT_2x35_HEIGHT_SETTINGS_SUCCESSFUL: {
            return {
                ...state,
                singleUpdating: false
            };
        }
        case actions.UPDATE_PRINT_SETTINGS_WIDTH_FAILED: {
            return {
                ...state,
                singleUpdating: false,
                singleError: response
            };
        }
        case actions.UPDATE_PRINT_2x35_SETTINGS_FAILED: {
            return {
                ...state,
                singleUpdating: false,
                singleError: response
            };
        }
        case actions.UPDATE_PRINT_2x35_CONTAINER_WIDTH_SETTINGS_FAILED: {
            return {
                ...state,
                singleUpdating: false,
                singleError: response
            };
        }
        case actions.UPDATE_PRINT_2x35_SETTINGS_OFFSET_FAILED: {
            return {
                ...state,
                singleUpdating: false,
                singleError: response
            };
        }
        case actions.UPDATE_PRINT_2x35_HEIGHT_SETTINGS_FAILED: {
            return {
                ...state,
                singleUpdating: false,
                singleError: response
            };
        }

        case actions.STORE_PRINT_SETTINGS_WIDTH_DATA: {
            return {
                ...state,
                singleFetching: false,
                width: response.data
            };
        }
        case actions.STORE_PRINT_SETTINGS_2x35_WIDTH_DATA: {
            return {
                ...state,
                singleFetching: false,
                width2x35: response.data
            };
        }
        case actions.STORE_PRINT_SETTINGS_2x35_CONTAINER_WIDTH_DATA: {
            return {
                ...state,
                singleFetching: false,
                containerWidth2x35: response.data
            };
        }
        case actions.STORE_PRINT_SETTINGS_OFFSET_2x35_DATA: {
            return {
                ...state,
                singleFetching: false,
                offset2x35: response.data
            };
        }
        case actions.STORE_PRINT_SETTINGS_HORIZONTAL_OFFSET_2x35_DATA: {
            return {
                ...state,
                singleFetching: false,
                horizontalOffset2x35: response.data
            };
        }
        case actions.STORE_PRINT_SETTINGS_2x35_HEIGHT_DATA: {
            return {
                ...state,
                singleFetching: false,
                height2x35: response.data
            };
        }
        case actions.START_FETCHING_PRINT_SETTINGS_TITLE: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.START_UPDATE_PRINT_SETTINGS_TITLE: {
            return {
                ...state,
                singleUpdating: true
            };
        }
        case actions.UPDATE_PRINT_SETTINGS_TITLE_SUCCESSFUL: {
            return {
                ...state,
                singleUpdating: false
            };
        }
        case actions.UPDATE_PRINT_SETTINGS_TITLE_FAILED: {
            return {
                ...state,
                singleUpdating: false,
                singleError: response
            };
        }

        case actions.STORE_PRINT_SETTINGS_TITLE_DATA: {
            return {
                ...state,
                title: response.data,
                singleFetching: false
            };
        }
        default:
            return state;
    }
};
