import { createSelector } from 'reselect';

export const getCheckedResellers = state => state.resellers && state.resellers.checked;

export const getResellerData = (state, id) =>
    state.resellers && state.resellers[id] && state.resellers[id];

export const getSingleResellerFetching = state => state.resellers.singleFetching;

export const getSingleResellerUpdating = state => state.resellers.singleUpdating;

export const getResellers = state => state.resellers && state.resellers.listingResponse.data;

export const submittingBulkAction = state =>
    state.resellers && state.resellers.submittingBulkAction && state.resellers.submittingBulkAction;

export const getEventDescriptionData = (state, id, descriptionId) =>
    state.resellers &&
    state.resellers[id] &&
    state.resellers[id].event_descriptions &&
    state.resellers[id].event_descriptions.find(el => el.id === descriptionId);

export const getEventData = (state, id, eventDescId) =>
    state.resellers &&
    state.resellers[id] &&
    state.resellers[id].event_descriptions.find(el => el.id === eventDescId);

export const getResellerEvents = state => state.resellers && state.resellers.events;

export const getSingleEventDescriptionFetching = state => state.resellers.singleFetching;

export default createSelector([
    getResellerData,
    getSingleResellerFetching,
    getEventDescriptionData,
    getSingleEventDescriptionFetching,
    getEventData,
    getResellers,
    submittingBulkAction
]);
