import React from 'react';
import PropTypes from 'prop-types';
import './Form.scss';

/**
 * Form Component
 */
function Form({ children, onSubmit, className }) {
    return (
        <form onSubmit={onSubmit} className={`form ${className || ''}`}>
            {children}
        </form>
    );
}

Form.defaultProps = {
    children: null,
    onSubmit: null,
    className: ''
};

Form.propTypes = {
    children: PropTypes.node,
    onSubmit: PropTypes.func,
    className: PropTypes.string
};

/**
 * Form Columns Component
 */
Form.Columns = function Columns({ children, breakOnMobile }) {
    return (
        <div
            className={`form__columns${
                breakOnMobile === true ? ' form__columns--break-on-mobile' : ''
            } `}
        >
            {children}
        </div>
    );
};

Form.Columns.defaultProps = {
    children: null,
    breakOnMobile: false
};

Form.Columns.propTypes = {
    children: PropTypes.node,
    breakOnMobile: PropTypes.bool
};

/**
 * Form Column Component
 */
Form.Column = function Column({ children, autoWidth, className }) {
    return (
        <div
            className={`form__column${
                autoWidth === true ? ' form__column--auto-width' : ''
            } ${className}`}
        >
            {children}
        </div>
    );
};

Form.Column.defaultProps = {
    children: null,
    autoWidth: false,
    className: ''
};

Form.Column.propTypes = {
    children: PropTypes.node,
    autoWidth: PropTypes.bool,
    className: PropTypes.string
};

/**
 * Form Section Component
 */
Form.Section = function Section({ children }) {
    return <div className="form__section">{children}</div>;
};

Form.Section.defaultProps = {
    children: null
};

Form.Section.propTypes = {
    children: PropTypes.node
};

/**
 * Form Section Component
 */
Form.SectionTitle = function Title({ children }) {
    return <h3 className="form__section-title">{children}</h3>;
};

Form.SectionTitle.defaultProps = {
    children: null
};

Form.SectionTitle.propTypes = {
    children: PropTypes.node
};

/**
 * Form Row Component
 */
Form.Row = function Row({ children, verticalCenter }) {
    return (
        <div className={`form__row${verticalCenter === true ? ' form__row--vertical-center' : ''}`}>
            {children}
        </div>
    );
};

Form.Row.defaultProps = {
    children: null,
    verticalCenter: false
};

Form.Row.propTypes = {
    children: PropTypes.node,
    verticalCenter: PropTypes.bool
};

/**
 * Form Row Responsive Component
 */
Form.RowColResponsive = function RowColResponsive({ children, className }) {
    return <div className={`form__row-column-responsive ${className}`}>{children}</div>;
};

Form.RowColResponsive.defaultProps = {
    children: null,
    className: ''
};

Form.RowColResponsive.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string
};

export default Form;
