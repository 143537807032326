import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Accordion from 'erpcore/components/Accordion';
import Svg from 'erpcomponents/Svg';
import Button from 'erpcore/components/Button';
import './CustomerInfoAccordion.scss';
import Tooltip from 'erpcomponents/Tooltip';
import { connect } from 'react-redux';
import { actions as ordersActions } from 'erpcore/screens/Orders/Orders.reducer';
import { getSyncingHubspot } from 'erpcore/screens/Orders/Orders.selectors';

const LineBreak = () => {
    return <div className="break" />;
};

const handleCardholderData = cardholder => {
    const { email, phone, address, city, state, country, zip } = cardholder;
    const cardholderName = `${cardholder.first_name} ${cardholder.last_name}`;

    return (
        <div className="cardholder-info-accordion">
            <span className="cardholder-info-accordion__name">{cardholderName}</span>
            <span>{email}</span>
            <span>{phone}</span>
            <span>{address}</span>
            <span>
                {city && city.name}
                {city && city.name && ((state && state.name) || zip) && <span>, </span>}{' '}
                {state && state.name} {zip}
            </span>
            <span>{country && country.name}</span>
        </div>
    );
};

const handleGroupData = (group, handleGroupEditModal, handleMergeGroupModal) => {
    const { name, group_size: groupSize, sold, tables } = group;
    const availableSeats = groupSize - sold;

    return (
        <Fragment>
            <div className="group-info-accordion">
                <div className="group-info-accordion__column">
                    <div className="group-info-accordion__title">People in Group</div>
                    <div className="group-info-accordion__rectangle">{groupSize}</div>
                    <div className="group-info-accordion__available">
                        <span className="group-info-accordion__available--bold">
                            {availableSeats}
                        </span>{' '}
                        more available
                    </div>
                </div>
                <div className="group-info-accordion__column">
                    <div className="group-info-accordion__id">
                        <div className="group-info-accordion__title">Group ID</div>
                        <span className="group-info-accordion__data">
                            {name}
                            <Tooltip content="Edit Group Details">
                                <button
                                    type="button"
                                    onClick={() => handleGroupEditModal()}
                                    className="group-info__name--link"
                                >
                                    <Svg icon="editLink" className="group-info__name--icon" />
                                </button>
                            </Tooltip>
                        </span>
                    </div>
                    <div className="group-info-accordion__table">
                        <div className="group-info-accordion__title">Table Allotted</div>
                        <span className="group-info-accordion__data">
                            {tables && tables[0].table_number ? `${tables[0].table_number}` : '-'}
                        </span>
                    </div>
                </div>
            </div>
            <div className="group-info-accordion__merge-groups">
                <Button
                    label="Merge Groups"
                    iconName="merge"
                    size="small"
                    variation="tertiary"
                    onClick={() => handleMergeGroupModal()}
                    className="group-info-accordion__merge-groups-button"
                />
            </div>
        </Fragment>
    );
};

const handlePaymentData = () => {
    return (
        <div className="payment-info-accordion">
            <div className="payment-info-accordion__status">
                <span className="payment-info-accordion__status--text">
                    Authorization Signed{' '}
                    <Svg icon="checkmark" className="payment-info-accordion__status--icon" />
                </span>
            </div>
            <div className="payment-info-accordion__button">
                <Button variation="secondary" size="small" label="Request Authorization" />
            </div>
            <div className="payment-info-accordion__button">
                <Button variation="secondary" size="small" label="View Authorization Form" />
            </div>
        </div>
    );
};

const handleHubspotData = (syncHubspot, orderId, hubspotSyncing) => {
    return (
        <div className="hubspot-accordion">
            <p>
                When <q>Sync Hubspot</q> button is clicked, ERP will take the customer email and
                will try and match it with a contact in Hubspot. If they match, and if that contact
                has active deal, it will add additional parameters into the deal related to current
                order, and it will push it to <q>Won</q> state within Hubspot
            </p>
            <Button
                label="Sync Hubspot"
                onClick={() => syncHubspot(orderId)}
                loading={hubspotSyncing}
            />
        </div>
    );
};

const CustomerInfoAccordion = ({
    data,
    handleGroupEditModal,
    handleMergeGroupModal,
    syncHubspot,
    hubspotSyncing
}) => {
    const includedData = data && data.included;
    const customerInfo = [];
    includedData.forEach(el => {
        customerInfo[el.type] = el;
    });
    const orderId = data && parseInt(data.id, 10);

    // Need to check if there is Cardholder information instead of Customer
    const cardholderData =
        customerInfo && customerInfo.Customer && handleCardholderData(customerInfo.Customer);
    const groupData =
        customerInfo &&
        customerInfo.Group &&
        handleGroupData(customerInfo.Group, handleGroupEditModal, handleMergeGroupModal);
    const paymentData = handlePaymentData();
    const hubspotData = handleHubspotData(syncHubspot, orderId, hubspotSyncing);

    return (
        <div className="customer-info-accordion">
            <Accordion title="Cardholder Info" body={cardholderData} />
            {customerInfo.Group && (
                <div>
                    <LineBreak />
                    <Accordion title="Group & Table Allotment" body={groupData} open />
                </div>
            )}
            <LineBreak />
            <Accordion title="Payment Authorization" body={paymentData} />
            <LineBreak />
            <Accordion title="Hubspot Integration" body={hubspotData} />
        </div>
    );
};
CustomerInfoAccordion.defaultProps = {
    hubspotSyncing: false
};

CustomerInfoAccordion.propTypes = {
    data: PropTypes.oneOfType([PropTypes.object]).isRequired,
    handleGroupEditModal: PropTypes.func.isRequired,
    handleMergeGroupModal: PropTypes.func.isRequired,
    syncHubspot: PropTypes.func.isRequired,
    hubspotSyncing: PropTypes.bool
};

const mapDispatchToProps = dispatch => ({
    syncHubspot: orderId => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: ordersActions.START_SYNC_HUBSPOT,
                formData: { order_id: orderId }
            });
        }).catch(error => ({ error }));
    }
});

const mapStateToProps = state => ({
    hubspotSyncing: getSyncingHubspot(state)
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomerInfoAccordion);
