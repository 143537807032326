import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import valueValidation from 'erputils/valueValidation';
import Form, { Text, Email, Password } from 'erpcore/components/Form';
import LayoutManager from 'erputils/LayoutManager';
import frontLayout from 'erputils/LayoutManager/frontLayout';
import PageLoader from 'erpcomponents/PageLoader';
import PageNotification from 'erpcomponents/Layout/PageNotification';
import Button from 'erpcomponents/Button';
import { actions as signUpActions } from 'erpcore/screens/SignUp/SignUp.reducer';
import { getPageNotification } from 'erputils/NotificationManager/NotificationManager.selectors';
import { getInvitationData, getInvitationFetching } from 'erpcore/screens/SignUp/SignUp.selectors';

class CreateAccount extends Component {
    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        const { fetchInvitationData } = this.props;
        fetchInvitationData();
    }

    onSubmit(formData) {
        const { dispatch, match } = this.props;
        formData = { ...formData, ...{ confirmation_token: match.params.token } };

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: signUpActions.START_CREATE_ACCOUNT,
                formData
            })
        ).catch(error => {
            return error;
        });
    }

    render() {
        const {
            handleSubmit,
            submitting,
            pristine,
            invalid,
            singleFetching,
            pageNotification,
            match,
            initialValues
        } = this.props;

        return (
            <LayoutManager layout={frontLayout} slot="main" layoutType="merge">
                {/* Content that goes into the "main" slot */}
                {singleFetching === true && <PageLoader />}
                <h2 className="front-main__title">
                    <FormattedMessage id="CreateAccount.title" defaultMessage="Create Account" />
                </h2>

                <p className="front-main__subtitle">
                    <FormattedMessage
                        id="CreateAccount.subtitle"
                        defaultMessage="You have been invited by {companyName} to create an account."
                        values={{ companyName: initialValues.organization_name }}
                    />
                </p>

                {pageNotification && pageNotification.code && (
                    <PageNotification code={pageNotification.code} />
                )}

                <Form onSubmit={handleSubmit(this.onSubmit)}>
                    <Form.Row>
                        <Field
                            name="first_name"
                            id="first_name"
                            fieldProps={{
                                label: (
                                    <FormattedMessage
                                        id="CreateAccount.FirstName"
                                        defaultMessage="First Name"
                                    />
                                ),
                                clearable: true
                            }}
                            component={Text}
                            validate={valueValidation([{ validator: 'required' }])}
                        />
                        <Field
                            name="last_name"
                            id="last_name"
                            fieldProps={{
                                label: (
                                    <FormattedMessage
                                        id="CreateAccount.LastName"
                                        defaultMessage="Last Name"
                                    />
                                ),
                                clearable: true
                            }}
                            component={Text}
                            validate={valueValidation([{ validator: 'required' }])}
                        />
                    </Form.Row>
                    <Form.Row>
                        <Field
                            name="email"
                            id="email"
                            fieldProps={{
                                label: (
                                    <FormattedMessage
                                        id="CreateAccount.Email"
                                        defaultMessage="Email"
                                    />
                                ),
                                clearable: true
                            }}
                            fieldAttr={{ disabled: true }}
                            component={Email}
                        />
                    </Form.Row>
                    <Form.Row>
                        <Field
                            name="password"
                            id="password"
                            fieldProps={{
                                label: (
                                    <FormattedMessage
                                        id="CreateAccount.Password"
                                        defaultMessage="Password"
                                    />
                                )
                            }}
                            component={Password}
                            validate={valueValidation([
                                { validator: 'length', args: { min: 8 } },
                                { validator: 'required' }
                            ])}
                        />
                    </Form.Row>
                    <Form.Row verticalCenter>
                        <Button
                            submit
                            disabled={pristine || invalid}
                            loading={submitting}
                            label={
                                <FormattedMessage
                                    id="CreateAccount.CreateAccount"
                                    defaultMessage="Create Account"
                                />
                            }
                        />
                        <span>
                            {' '}
                            <FormattedMessage
                                id="CreateAccount.text"
                                defaultMessage="I don’t want to open an account?"
                            />{' '}
                            <Link to={`/decline/${match.params.token}`}>
                                <FormattedMessage
                                    id="CreateAccount.Decline"
                                    defaultMessage="Decline"
                                />
                            </Link>
                        </span>
                    </Form.Row>
                </Form>
            </LayoutManager>
        );
    }
}

CreateAccount.defaultProps = {
    submitting: false,
    pristine: false,
    invalid: false,
    fetchInvitationData: () => {},
    initialValues: {},
    singleFetching: false,
    pageNotification: null
};

CreateAccount.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.oneOfType([PropTypes.object]).isRequired,
    fetchInvitationData: PropTypes.func,
    initialValues: PropTypes.oneOfType([PropTypes.object]),
    singleFetching: PropTypes.bool,
    pageNotification: PropTypes.oneOfType([PropTypes.object])
};

CreateAccount = reduxForm({
    form: 'CreateAccountForm',
    enableReinitialize: true
})(CreateAccount);

const mapStateToProps = (state, ownProps) => ({
    initialValues: getInvitationData(state, ownProps.match.params.token),
    singleFetching: getInvitationFetching(state),
    pageNotification: getPageNotification(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchInvitationData: () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: signUpActions.START_FETCHING_INVITATION,
                token: ownProps.match.params.token
            });
        }).catch(error => ({ error }));
    }
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(CreateAccount)
);
