import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import Form, { Text } from 'erpcore/components/Form';
import { valueValidation } from 'erpcore/components/Form/Form.utils';

import Button from 'erpcore/components/Button';

const PermissionForm = ({ handleSubmit, submitting, pristine, invalid, onSubmit, onCancel }) => {
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
                <Field
                    name="name"
                    id="name"
                    fieldProps={{
                        label: 'Permissions',
                        clearable: true
                    }}
                    component={Text}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Button submit disabled={pristine || invalid} loading={submitting} label="Save" />
            {onCancel && <Button variation="secondary" label="Cancel" onClick={() => onCancel()} />}
        </Form>
    );
};

PermissionForm.defaultProps = {
    onSubmit: () => {},
    onCancel: null,
    submitting: false,
    pristine: false,
    invalid: false
};

PermissionForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool
};

export default reduxForm({
    form: 'PermissionForm',
    enableReinitialize: true
})(PermissionForm);
