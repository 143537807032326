import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, FormattedDate, FormattedTime } from 'react-intl';
import { Field, reduxForm, reset } from 'redux-form';
import Form, { TextEditor, RadioGroup } from 'erpcore/components/Form';
import valueValidation from 'erputils/valueValidation';
import Notification from 'erpcomponents/Notification';
import Button from 'erpcomponents/Button';
import CommentDelete from 'erpcomponents/Comments/components/CommentDelete';
import Svg from 'erpcomponents/Svg';
import ElementLoader from 'erpcomponents/ElementLoader';
import './Comments.scss';
import { actions as commentsActions } from './Comments.reducer';
import { getCommentsData, getCommentsFetching } from './Comments.selectors';

/**
 *
 * @param {*} props
 */
class Comments extends Component {
    constructor(props) {
        super(props);

        this.state = {
            params: {
                include: 'user',
                'order_by[createdAt]': 'DESC'
            },
            viewAllButtonShow: true,
            viewAllStyle: {}
        };

        this.viewAll = this.viewAll.bind(this);
        this.postComment = this.postComment.bind(this);
    }

    componentDidMount() {
        this.fetchComments();
    }

    fetchComments() {
        const { dispatch, match, entity } = this.props;
        const { params } = this.state;

        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: commentsActions.START_FETCHING_COMMENTS,
                entity,
                id: match.params.id,
                params
            });
        }).catch(error => ({ error }));
    }

    postComment(formData) {
        const { match, dispatch, entity } = this.props;
        const { params } = this.state;

        if (formData.public && formData.public === 'false') {
            formData.public = false;
        }

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: commentsActions.START_POSTING_SINGLE_COMMENT,
                entity,
                id: match.params.id,
                params,
                formData
            })
        )
            .then(() => {
                dispatch(reset('CommentsForm'));
            })
            .catch(error => {
                return error;
            });
    }

    /**
     * Displays all Comments
     */
    viewAll() {
        const { params } = this.state;

        return this.setState(
            {
                params: { ...params, ...{ pagination: false } },
                viewAllStyle: { maxHeight: 'none' },
                viewAllButtonShow: false
            },
            () => this.fetchComments()
        );
    }

    renderComments() {
        const { comments, fetching, entity, match } = this.props;
        const { data, meta } = comments;
        const { viewAllStyle, params } = this.state;

        if (data && meta && meta.totalItems && meta.totalItems > 0) {
            return (
                <Fragment>
                    {fetching && <ElementLoader />}
                    <ul className="comments__content" style={viewAllStyle}>
                        {data.map(comment => {
                            return (
                                <li className="comments__comment" key={`comment-${comment.id}`}>
                                    <h3 className="comments__comment-user">
                                        {comment.public === false && (
                                            <Svg
                                                icon="lock"
                                                className="comments__comment-user-icon"
                                            />
                                        )}
                                        {comment.user &&
                                            `${comment.user.first_name} ${comment.user.last_name}`}
                                        <span className="comments__comment-date">
                                            <FormattedDate
                                                value={comment.created_at}
                                                day="numeric"
                                                month="short"
                                                year="numeric"
                                            />{' '}
                                            <FormattedMessage
                                                id="Comments.Comment.date.text"
                                                defaultMessage="at"
                                            />{' '}
                                            <FormattedTime value={comment.created_at} />
                                        </span>
                                    </h3>
                                    <div
                                        className="comments__comment-content"
                                        dangerouslySetInnerHTML={{ __html: comment.content }}
                                    />
                                    <CommentDelete
                                        iri={comment.iri}
                                        params={params}
                                        entity={entity}
                                        entityId={match.params.id}
                                    />
                                </li>
                            );
                        })}
                    </ul>
                </Fragment>
            );
        }

        if (!fetching) {
            return (
                <Notification
                    key="Comments.NoData"
                    text={
                        <FormattedMessage
                            id="Comments.Notification.NoData.text"
                            defaultMessage="There are no Comments to display!"
                        />
                    }
                    title={
                        <FormattedMessage
                            id="Comments.Notification.NoData.title"
                            defaultMessage="No Results"
                        />
                    }
                    type="warning"
                />
            );
        }

        return null;
    }

    renderCommentForm() {
        const { handleSubmit, submitting, pristine, invalid } = this.props;

        return (
            <Form onSubmit={handleSubmit(this.postComment)}>
                <Form.SectionTitle>
                    <FormattedMessage
                        id="Comments.Form.SectionTitle"
                        defaultMessage="Leave a new comment"
                    />
                </Form.SectionTitle>
                <Form.Row>
                    <Field
                        name="content"
                        id="content"
                        fieldProps={{
                            label: (
                                <FormattedMessage
                                    id="Comments.Form.Comment"
                                    defaultMessage="Add Comment..."
                                />
                            ),
                            clearable: true
                        }}
                        component={TextEditor}
                        validate={valueValidation([{ validator: 'required' }])}
                    />
                </Form.Row>
                <Form.Row>
                    <Field
                        name="public"
                        id="public"
                        fieldProps={{
                            options: [
                                {
                                    label: (
                                        <FormattedMessage
                                            id="Comments.Form.Public"
                                            defaultMessage="Public"
                                        />
                                    ),
                                    id: 'radio1',
                                    value: true
                                },
                                {
                                    label: (
                                        <FormattedMessage
                                            id="Comments.Form.Private"
                                            defaultMessage="Private"
                                        />
                                    ),
                                    id: 'radio2',
                                    value: false
                                }
                            ]
                        }}
                        component={RadioGroup}
                    />
                </Form.Row>
                <Form.Row>
                    <Button
                        submit
                        disabled={pristine || invalid}
                        loading={submitting}
                        label={
                            <FormattedMessage
                                id="Comments.Form.Post"
                                defaultMessage="Post a comment"
                            />
                        }
                    />
                </Form.Row>
            </Form>
        );
    }

    render() {
        const { className, comments } = this.props;
        const { viewAllButtonShow } = this.state;

        return (
            <Fragment>
                <div className={`comments ${className || ''}`}>
                    {this.renderComments()}

                    {viewAllButtonShow &&
                    comments.meta &&
                    comments.meta.totalItems &&
                    comments.meta.totalItems > 10 ? (
                        <button type="button" className="comments__view-all" onClick={this.viewAll}>
                            <FormattedMessage
                                id="Comments.ViewAllComments"
                                defaultMessage="View All Comments"
                            />
                        </button>
                    ) : null}
                </div>

                {this.renderCommentForm()}
            </Fragment>
        );
    }
}

Comments.defaultProps = {
    className: '',
    comments: {},
    submitting: false,
    pristine: false,
    invalid: false,
    fetching: false
};

Comments.propTypes = {
    entity: PropTypes.string.isRequired,
    className: PropTypes.string,
    dispatch: PropTypes.func.isRequired,
    comments: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    match: PropTypes.oneOfType([PropTypes.object]).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    fetching: PropTypes.bool
};

Comments = reduxForm({
    form: 'CommentsForm',
    enableReinitialize: false,
    initialValues: {
        public: true
    }
})(Comments);

//  Getting initial value populated in the form from the store
const mapStateToProps = (state, ownProps) => ({
    comments: getCommentsData(state, ownProps.entity, ownProps.match.params.id),
    fetching: getCommentsFetching(state)
});

export default withRouter(connect(mapStateToProps)(Comments));
