import React from 'react';
import PropTypes from 'prop-types';
// import { FormattedNumber } from 'react-intl';
import Cardbox from 'erpcore/components/Cardbox';
// import Svg from 'erpcomponents/Svg';
import './CustomerQuestions.scss';

const handleCardBody = data => {
    const tickets = data && data.tickets;
    const includedTicketsData = tickets.map(ticketiri =>
        data.included.find(record => record.iri === ticketiri)
    );
    let customerQuestions = [];

    if (includedTicketsData) {
        includedTicketsData.forEach(ticket => {
            if (ticket.customer_questions) {
                const questionsArray = ticket.customer_questions.map(question => question);
                customerQuestions = [...customerQuestions, ...questionsArray];
            }
        });
    }
    return (
        <div className="questions-info-body">
            {customerQuestions &&
                customerQuestions.map(record => (
                    <div key={record.question}>
                        <div className="questions-info-body__question">
                            <span>{record.question}</span>
                        </div>
                        <div className="questions-info-body__answer">
                            <div className="questions-info-body__details">{record.answerText}</div>
                        </div>
                    </div>
                ))}
        </div>
    );
};

const CustomerQuestions = ({ data }) => {
    const bodyCardData = handleCardBody(data);

    return <Cardbox body={bodyCardData} />;
};

CustomerQuestions.defaultProps = {
    // tickets: null
};

CustomerQuestions.propTypes = {
    data: PropTypes.oneOfType([PropTypes.object]).isRequired
    // tickets: PropTypes.oneOfType([PropTypes.array])
};

export default CustomerQuestions;
