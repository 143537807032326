import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reduxForm, Field } from 'redux-form';
import { dtoForm } from 'erputils/dto';
import { diff } from 'deep-object-diff';
import { FormattedMessage } from 'react-intl';

import Form, { MultiSelect, SwitchCard } from 'erpcore/components/Form';
import Button from 'erpcore/components/Button';
import LayoutManager from 'erputils/LayoutManager';
import PageHeader from 'erpcomponents/Layout/PageHeader';
import PageContent from 'erpcomponents/Layout/PageContent';
import EventInfo from 'erpcore/screens/Events/components/EventInfo';
import EventManageTabs from 'erpcore/screens/Events/components/EventManageTabs';
import PageLoader from 'erpcomponents/PageLoader';
import { actions as eventsActions } from 'erpcore/screens/Events/Events.reducer';
import { getEventData, getSingleEventFetching } from 'erpcore/screens/Events/Events.selectors';

const includesDto = data => {
    if (data && data.tickets_include) {
        const keys = Object.keys(data.tickets_include);
        const updatedIncludes = keys.map(key => key);
        if (updatedIncludes.length > 0) {
            data.tickets_include = updatedIncludes;
        }
    }
    return data;
};
class EventSettings extends Component {
    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        const { fetchEventData } = this.props;
        fetchEventData();
    }

    onSubmit(formData) {
        const { match, dispatch, initialValues } = this.props;
        const data = diff(initialValues, formData);
        if (data.tickets_include) {
            let ticketIncludeObj = {};
            Object.keys(formData.tickets_include).forEach(key => {
                ticketIncludeObj = {
                    ...ticketIncludeObj,
                    [formData.tickets_include[key]]: true
                };
            });
            data.tickets_include = { ...ticketIncludeObj };
        }

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: eventsActions.START_UPDATE_SINGLE_EVENT,
                id: match.params.id,
                formData: data
            })
        ).catch(error => {
            return error;
        });
    }

    render() {
        const {
            singleFetching,
            pristine,
            invalid,
            submitting,
            handleSubmit,
            initialValues
        } = this.props;

        return (
            <LayoutManager slot="main" className="main--narrow" layoutType="merge">
                <PageHeader
                    title={
                        <FormattedMessage
                            id="EventEdit.Settings.title"
                            defaultMessage="Edit Event"
                        />
                    }
                />
                {singleFetching && <PageLoader />}
                <EventInfo />
                <EventManageTabs />
                <PageContent>
                    <Form onSubmit={handleSubmit(this.onSubmit)}>
                        <Form.Columns>
                            <Form.Column>
                                <Form.Row>
                                    <Field
                                        name="display_in_filter"
                                        id="display_in_filter"
                                        fieldProps={{
                                            label: 'Display your event in the Filter',
                                            onValue: true,
                                            offValue: false
                                        }}
                                        fieldAttr={{
                                            input: {
                                                value:
                                                    initialValues && initialValues.display_in_filter
                                            }
                                        }}
                                        component={SwitchCard}
                                    />
                                </Form.Row>
                                <Form.Row>
                                    <Field
                                        name="allow_affiliate"
                                        id="allow_affiliate"
                                        fieldProps={{
                                            label: 'Allow affiliate program for this event',
                                            onValue: true,
                                            offValue: false
                                        }}
                                        fieldAttr={{
                                            input: {
                                                value:
                                                    initialValues && initialValues.allow_affiliate
                                            }
                                        }}
                                        onChange={this.handleAllowAffiliate}
                                        component={SwitchCard}
                                    />
                                </Form.Row>
                                <Form.Row>
                                    <Field
                                        name="list_on_website"
                                        id="list_on_website"
                                        fieldProps={{
                                            label: 'List your event on throughout the website',
                                            onValue: true,
                                            offValue: false
                                        }}
                                        fieldAttr={{
                                            input: {
                                                value:
                                                    initialValues && initialValues.list_on_website
                                            }
                                        }}
                                        component={SwitchCard}
                                    />
                                </Form.Row>
                            </Form.Column>
                        </Form.Columns>
                        <Form.SectionTitle>
                            <FormattedMessage
                                id="Event.Includes.Title"
                                defaultMessage="Event Includes"
                            />
                        </Form.SectionTitle>
                        <Form.Row>
                            <Field
                                name="tickets_include"
                                id="tickets_include"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="EventEdit.Details.Includes"
                                            defaultMessage="Includes"
                                        />
                                    ),
                                    options: [
                                        { label: 'Vodka', value: 'Vodka' },
                                        { label: 'Champagne', value: 'Champagne' },
                                        { label: 'Prosecco', value: 'Prosecco' },
                                        { label: 'Beer Bucket', value: 'BeerbBucket' },
                                        { label: 'Sparkling Wine', value: 'SparklingWine' },
                                        { label: 'Cider', value: 'Cider' },
                                        {
                                            label: 'Red / White Wine',
                                            value: 'RedWhiteWine'
                                        },
                                        { label: 'Platter of Food', value: 'PlatterOfFood' }
                                    ],
                                    clearable: true
                                }}
                                component={MultiSelect}
                            />
                        </Form.Row>
                        <Form.Row>
                            <Button
                                submit
                                loading={submitting}
                                disabled={pristine || invalid}
                                label="Update"
                            />
                        </Form.Row>
                    </Form>
                </PageContent>
            </LayoutManager>
        );
    }
}

EventSettings.defaultProps = {
    fetchEventData: () => {},
    handleSubmit: () => {},
    singleFetching: false,
    initialValues: {},
    pristine: false,
    invalid: false,
    submitting: false
};

EventSettings.propTypes = {
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.oneOfType([PropTypes.object]).isRequired,
    fetchEventData: PropTypes.func,
    handleSubmit: PropTypes.func,
    initialValues: PropTypes.oneOfType([PropTypes.object]),
    singleFetching: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    submitting: PropTypes.bool
};

EventSettings = reduxForm({
    form: 'EventSettingsForm',
    enableReinitialize: true
})(EventSettings);

//  Getting initial value populated in the form from the store
const mapStateToProps = (state, ownProps) => ({
    initialValues: includesDto(dtoForm(getEventData(state, ownProps.match.params.id))),
    singleFetching: getSingleEventFetching(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchEventData: () => {
        // Getting included data from API by setting params
        const params = {
            include: 'organization,location,location.country,location.state,location.city'
        };
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: eventsActions.START_FETCHING_SINGLE_EVENT,
                id: ownProps.match.params.id,
                params
            });
        }).catch(error => ({ error }));
    }
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(EventSettings)
);
