import { createSelector } from 'reselect';

export const getSingleHeaderMenuFetching = state => state.headerMenus.singleFetching;

export const getSingleHeaderMenuCreating = state => state.headerMenus.singleCreating;

export const getSingleHeaderMenuDeleting = state => state.headerMenus.singleDeleting;

export const getSingleHeaderMenuUpdating = state => state.headerMenus.singleUpdating;

export default createSelector([
    getSingleHeaderMenuFetching,
    getSingleHeaderMenuCreating,
    getSingleHeaderMenuDeleting,
    getSingleHeaderMenuUpdating
]);
