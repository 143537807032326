import React from 'react';
import { FieldArray, reduxForm } from 'redux-form';
import Form, { Gallery } from 'erpcore/components/Form';
import PropTypes from 'prop-types';

class TableTemplateFloorPlans extends React.Component {
    constructor(props) {
        super(props);

        this.test = false;
    }

    render() {
        const { entityId, tableTemplateId } = this.props;
        return (
            <Form.Row>
                <h4>Floor Plans</h4>
                <FieldArray
                    name="floorPlans"
                    id="table-template-floor-plans"
                    component={Gallery}
                    useImageManager={false}
                    useLightbox
                    featuredKey={false}
                    versionName="entityVersion"
                    attachedToEntity={{
                        type: 'location',
                        iri: `/api/locations/${entityId}`,
                        id: entityId
                    }}
                    getImagesApi={`/api/locations/${entityId}/images`}
                    galleryApi="/api/location-images"
                    groupName={`table-template-${tableTemplateId}`}
                />
            </Form.Row>
        );
    }
}

TableTemplateFloorPlans.defaultProps = {
    entityId: null,
    tableTemplateId: null
};

TableTemplateFloorPlans.propTypes = {
    entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    tableTemplateId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

TableTemplateFloorPlans = reduxForm({
    form: 'VenueTableTemplateFloorPlan',
    enableReinitialize: true
    // keepDirtyOnReinitialize: false, // important
    // updateUnregisteredFields: false // important
})(TableTemplateFloorPlans);

export default TableTemplateFloorPlans;
