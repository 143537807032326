import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, FormattedDate } from 'react-intl';
import LayoutManager from 'erputils/LayoutManager';
import PageHeader from 'erpcomponents/Layout/PageHeader';
import Listing from 'erpcomponents/Listing';
import StatusBadge from 'erpcomponents/StatusBadge';
import ListingTableActions from 'erpcomponents/Listing/components/TableActions';
import InvitationDelete from 'erpcore/screens/Users/components/InvitationDelete';
import InvitationResend from 'erpcore/screens/Users/components/InvitationResend';
import { actions as listingActions } from 'erpcomponents/Listing/Listing.reducer';
import { getListingFetching } from 'erpcomponents/Listing/Listing.selectors';
import { getInvitationsData } from 'erpcore/screens/Users/Users.selectors';

const reducerName = 'users';

class UserInvites extends Component {
    tableData() {
        const { invitationsData } = this.props;
        const table = {};
        table.data = [];

        // Table Schema
        table.schema = [
            {
                title: <FormattedMessage id="UserInvites.Table.Name" defaultMessage="Name" />,
                field: 'name'
            },
            {
                title: <FormattedMessage id="UserInvites.Table.Email" defaultMessage="Email" />,
                field: 'email'
            },
            {
                title: <FormattedMessage id="UserInvites.Table.Phone" defaultMessage="Phone" />,
                field: 'phone_number'
            },
            {
                title: (
                    <FormattedMessage
                        id="UserInvites.Table.InvitedAt"
                        defaultMessage="Invited At"
                    />
                ),
                field: 'invitedAt'
            },
            {
                title: <FormattedMessage id="UserInvites.Table.Status" defaultMessage="Status" />,
                field: 'status'
            },
            {
                title: <FormattedMessage id="UserInvites.Table.Actions" defaultMessage="Actions" />,
                field: 'actions'
            }
        ];
        // Table Data
        if (invitationsData?.data) {
            invitationsData.data.map(row => {
                const name = row.data ? `${row.data.first_name} ${row.data.last_name}` : '';
                return table.data.push({
                    id: row.id,
                    name,
                    email: row.email && row.email,
                    phone_number: row.data && row.data.phone_number,
                    invitedAt: row.created_at ? <FormattedDate value={row.created_at} /> : 'null',
                    status: row?.marking && <StatusBadge type={row.marking} text={row.marking} />,
                    actions: (
                        <ListingTableActions>
                            <ListingTableActions.Action>
                                <InvitationResend
                                    token={row.token}
                                    key={`InvitationResend ${row.token}`}
                                />
                            </ListingTableActions.Action>
                            <ListingTableActions.Action>
                                <InvitationDelete
                                    token={row.token}
                                    key={`InvitationDelete ${row.id}`}
                                />
                            </ListingTableActions.Action>
                        </ListingTableActions>
                    )
                });
            });
        }
        return table;
    }

    render() {
        const { invitationsListingFetching, invitationsData, fetchInvitations } = this.props;

        return (
            <LayoutManager slot="main" layoutType="merge">
                <PageHeader
                    title={
                        <FormattedMessage id="UserInvites.title" defaultMessage="User Invites" />
                    }
                />
                <Listing
                    name={reducerName}
                    reducerName={reducerName}
                    loading={invitationsListingFetching}
                    meta={invitationsData.meta}
                    table={this.tableData()}
                    onListingConfigUpdate={params => fetchInvitations(params)}
                />
            </LayoutManager>
        );
    }
}

UserInvites.defaultProps = {
    invitationsListingFetching: false,
    invitationsData: {}
};

UserInvites.propTypes = {
    fetchInvitations: PropTypes.func.isRequired,
    invitationsListingFetching: PropTypes.bool,
    invitationsData: PropTypes.oneOfType([PropTypes.object])
};

//  Redux
const mapStateToProps = state => ({
    invitationsListingFetching: getListingFetching(state, 'users'),
    invitationsData: getInvitationsData(state, 'users')
});

const mapDispatchToProps = dispatch => ({
    fetchInvitations: params => {
        // Getting included data from API by setting defaultParams
        const defaultParams = {
            include: 'user'
        };
        const listingParams = Object.assign({}, defaultParams, params);

        dispatch({
            type: listingActions.START_FETCHING_LISTING,
            params: listingParams,
            entity: 'USER_INVITES',
            name: reducerName,
            endpoint: 'api/invitations'
        });
    }
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(UserInvites)
);
