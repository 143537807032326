import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm, SubmissionError, FieldArray } from 'redux-form';
import valueValidation from 'erputils/valueValidation';
import { dtoForm } from 'erputils/dto';
import { diff } from 'deep-object-diff';
import { FormattedMessage } from 'react-intl';
import reduxFormErrorMapper from 'erputils/reduxFormErrorMapper';
import Form, {
    Text,
    DateTime,
    Autocomplete,
    TextEditor,
    MultiCreatable,
    Repeater
} from 'erpcore/components/Form';
import LayoutManager from 'erputils/LayoutManager';
import PageHeader from 'erpcomponents/Layout/PageHeader';
import PageContent from 'erpcomponents/Layout/PageContent';
import EventInfo from 'erpcore/screens/Events/components/EventInfo';
import EventEditTabs from 'erpcore/screens/Events/components/EventEditTabs';
import Button from 'erpcomponents/Button';
import PageLoader from 'erpcomponents/PageLoader';
import { actions as eventsActions } from 'erpcore/screens/Events/Events.reducer';
import { getEventData, getSingleEventFetching } from 'erpcore/screens/Events/Events.selectors';

import RecommendationRepeaterItem from 'erpcore/screens/Events/screens/EventEdit/components/RecommendationRepeaterItem';
import './EventDetails.scss';

class EventDetails extends Component {
    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        const { fetchEventData } = this.props;
        fetchEventData();
    }

    onSubmit(formData) {
        const { match, dispatch, initialValues } = this.props;
        // send only changed data
        const data = diff(initialValues, formData);

        // send all data for multi fields
        if (data.event_series) {
            data.event_series = formData.event_series;
        }
        if (data.event_categories) {
            data.event_categories = formData.event_categories;
        }
        if (data.bar_types) {
            data.bar_types = formData.bar_types;
        }
        if (data.food_types) {
            data.food_types = formData.food_types;
        }
        if (data.max_attendance) {
            const maxAttendance = parseInt(data.max_attendance, 10);
            data.max_attendance = maxAttendance;
        }

        if (data.why_we_recommend) {
            data.why_we_recommend = [...formData.why_we_recommend];
        }

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: eventsActions.START_UPDATE_SINGLE_EVENT,
                id: match.params.id,
                formData: data
            })
        ).catch(error => {
            throw new SubmissionError(reduxFormErrorMapper(error));
        });
    }

    handleSortEnd = formData => {
        const { dispatch, match } = this.props;
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: eventsActions.START_UPDATE_SINGLE_EVENT,
                id: match.params.id,
                formData: { why_we_recommend: [...formData?.allData] }
            })
        ).catch(error => {
            throw new SubmissionError(reduxFormErrorMapper(error));
        });
    };

    handleRemoveItem = ({ itemData = {} }) => {
        const { dispatch, initialValues, match } = this.props;
        const value =
            initialValues &&
            initialValues.why_we_recommend.filter(record => record?.value !== itemData?.value);

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: eventsActions.START_UPDATE_SINGLE_EVENT,
                id: match.params.id,
                formData: {
                    why_we_recommend: value
                }
            })
        ).catch(error => {
            throw new SubmissionError(reduxFormErrorMapper(error));
        });
    };

    render() {
        const {
            handleSubmit,
            submitting,
            pristine,
            invalid,
            singleFetching,
            initialValues
        } = this.props;

        return (
            <LayoutManager slot="main" className="main--narrow" layoutType="merge">
                <PageHeader
                    title={<FormattedMessage id="EventEdit.title" defaultMessage="Edit Event" />}
                    backButtonLink="/events"
                />
                {singleFetching === true && <PageLoader />}
                <EventInfo />
                <EventEditTabs />
                <PageContent>
                    <Form onSubmit={handleSubmit(this.onSubmit)}>
                        <Form.Row>
                            <Field
                                name="name"
                                id="name"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="EventEdit.Details.EventName"
                                            defaultMessage="Event Name"
                                        />
                                    ),
                                    clearable: true
                                }}
                                fieldAttr={{ required: true }}
                                component={Text}
                                validate={valueValidation([{ validator: 'required' }])}
                            />
                        </Form.Row>
                        <Form.Columns>
                            <Form.Column>
                                <Form.Row>
                                    <Field
                                        name="start"
                                        id="start"
                                        fieldProps={{
                                            label: (
                                                <FormattedMessage
                                                    id="EventEdit.Details.Starts"
                                                    defaultMessage="Starts"
                                                />
                                            ),
                                            showTimeSelect: false,
                                            clearable: true
                                        }}
                                        fieldAttr={{ required: true }}
                                        onChange={this.handleChangeStart}
                                        component={DateTime}
                                        validate={valueValidation([{ validator: 'required' }])}
                                    />
                                </Form.Row>
                                <Form.Row>
                                    <Field
                                        name="end"
                                        id="end"
                                        fieldProps={{
                                            label: (
                                                <FormattedMessage
                                                    id="EventEdit.Details.Ends"
                                                    defaultMessage="Ends"
                                                />
                                            ),
                                            showTimeSelect: false,
                                            clearable: true
                                        }}
                                        fieldAttr={{ required: true }}
                                        component={DateTime}
                                        validate={valueValidation([{ validator: 'required' }])}
                                    />
                                </Form.Row>
                            </Form.Column>
                            <Form.Column>
                                <Form.Row>
                                    <Field
                                        name="start"
                                        id="start"
                                        fieldProps={{
                                            label: (
                                                <FormattedMessage
                                                    id="EventEdit.Details.Starts"
                                                    defaultMessage="Starts"
                                                />
                                            ),
                                            showTimeSelect: true,
                                            showTimeSelectOnly: true,
                                            clearable: true
                                        }}
                                        fieldAttr={{ required: true }}
                                        onChange={this.handleChangeStart}
                                        component={DateTime}
                                        validate={valueValidation([{ validator: 'required' }])}
                                    />
                                </Form.Row>
                                <Form.Row>
                                    <Field
                                        name="end"
                                        id="end"
                                        fieldProps={{
                                            label: (
                                                <FormattedMessage
                                                    id="EventEdit.Details.Ends"
                                                    defaultMessage="Ends"
                                                />
                                            ),
                                            showTimeSelect: true,
                                            showTimeSelectOnly: true,
                                            clearable: true
                                        }}
                                        fieldAttr={{ required: true }}
                                        component={DateTime}
                                        validate={valueValidation([{ validator: 'required' }])}
                                    />
                                </Form.Row>
                            </Form.Column>
                        </Form.Columns>

                        <Form.Row>
                            <Field
                                name="timezone"
                                id="timezone"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="EventEdit.Details.Timezone"
                                            defaultMessage="Timezone"
                                        />
                                    ),
                                    options: {
                                        endpoint: '/api/timezones?pagination=false',
                                        mapData: {
                                            value: 'iri',
                                            label: 'title'
                                        }
                                    },
                                    clearable: true
                                }}
                                fieldAttr={{ required: true }}
                                component={Autocomplete}
                                validate={valueValidation([{ validator: 'required' }])}
                            />
                        </Form.Row>
                        <Form.Row>
                            <Field
                                name="location"
                                id="location"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="EventEdit.Details.VenueName"
                                            defaultMessage="Venue Name"
                                        />
                                    ),
                                    options: {
                                        endpoint: '/api/locations?pagination=false',
                                        mapData: {
                                            value: 'iri',
                                            label: 'venue_name'
                                        }
                                    },
                                    clearable: true,
                                    actionButton: {
                                        edit: {
                                            url: {
                                                prefix: '/venues/',
                                                suffix: '/edit/general-info'
                                            }
                                        },
                                        create: {
                                            url: '/venues/create'
                                        }
                                    }
                                }}
                                component={Autocomplete}
                                validate={valueValidation([{ validator: 'required' }])}
                                fieldAttr={{ required: true }}
                            />
                        </Form.Row>
                        <Form.Row>
                            <Field
                                name="max_attendance"
                                id="max_attendance"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="EventEdit.Details.MaximumAttendance"
                                            defaultMessage="Maximum Attendance"
                                        />
                                    ),
                                    clearable: true
                                }}
                                component={Text}
                                fieldAttr={{ required: true }}
                                validate={valueValidation([
                                    { validator: 'required' },
                                    { validator: 'numericality' }
                                ])}
                            />
                        </Form.Row>
                        <Form.Row>
                            <Field
                                name="event_series"
                                id="event_series"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="EventEdit.Details.EventSeries"
                                            defaultMessage="Event Series"
                                        />
                                    ),
                                    options: {
                                        endpoint: '/api/event-series?pagination=false',
                                        mapData: {
                                            value: 'iri',
                                            label: 'name'
                                        }
                                    },
                                    clearable: true
                                }}
                                component={MultiCreatable}
                            />
                        </Form.Row>

                        <Form.Row>
                            <Field
                                name="event_categories"
                                id="event_categories"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="EventEdit.Details.EventCategory"
                                            defaultMessage="Event Category"
                                        />
                                    ),
                                    options: {
                                        endpoint: '/api/event-categories?pagination=false',
                                        mapData: {
                                            value: 'iri',
                                            label: 'name'
                                        }
                                    },
                                    clearable: true
                                }}
                                component={MultiCreatable}
                            />
                        </Form.Row>

                        <Form.Row>
                            <Field
                                name="age_requirement"
                                id="age_requirement"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="EventEdit.Details.AgeRequirements"
                                            defaultMessage="Age Requirements"
                                        />
                                    ),
                                    options: {
                                        endpoint: '/api/age-requirements?pagination=false',
                                        mapData: {
                                            value: 'iri',
                                            label: 'name'
                                        }
                                    },
                                    clearable: true
                                }}
                                component={Autocomplete}
                            />
                        </Form.Row>
                        <Form.Row>
                            <Field
                                name="bar_types"
                                id="bar_types"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="EventEdit.Details.BarType"
                                            defaultMessage="Bar Type"
                                        />
                                    ),
                                    options: {
                                        endpoint: '/api/bar-types?pagination=false',
                                        mapData: {
                                            value: 'iri',
                                            label: 'name'
                                        }
                                    },
                                    clearable: true
                                }}
                                component={MultiCreatable}
                            />
                        </Form.Row>
                        <Form.Row>
                            <Field
                                name="food_types"
                                id="food_types"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="EventEdit.Details.FoodType"
                                            defaultMessage="Food Type"
                                        />
                                    ),
                                    options: {
                                        endpoint: '/api/food-types?pagination=false',
                                        mapData: {
                                            value: 'iri',
                                            label: 'name'
                                        }
                                    },
                                    clearable: true
                                }}
                                component={MultiCreatable}
                            />
                        </Form.Row>
                        <Form.Row>
                            <Field
                                name="bar_times"
                                id="bar_times"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="EventEdit.Details.BarTimes"
                                            defaultMessage="Bar Times"
                                        />
                                    ),
                                    clearable: true
                                }}
                                component={Text}
                            />
                        </Form.Row>
                        <Form.Row>
                            <Field
                                name="food_times"
                                id="food_times"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="EventEdit.Details.FoodTimes"
                                            defaultMessage="Food Times"
                                        />
                                    ),
                                    clearable: true
                                }}
                                component={Text}
                            />
                        </Form.Row>
                        <Form.Row>
                            <Field
                                name="slogan"
                                id="Slogan"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="EventEdit.Details.EventSlogan"
                                            defaultMessage="Event Slogan"
                                        />
                                    ),
                                    clearable: true
                                }}
                                component={Text}
                            />
                        </Form.Row>
                        <Form.SectionTitle>
                            <FormattedMessage
                                id="EventEdit.Details.EventDescription"
                                defaultMessage="Event Description"
                            />
                        </Form.SectionTitle>
                        <Form.Row>
                            <Field
                                name="description"
                                id="description"
                                fieldProps={{
                                    clearable: true
                                }}
                                component={TextEditor}
                            />
                        </Form.Row>
                        <div className="event-info__recommendations">
                            <Form.SectionTitle>
                                <FormattedMessage
                                    id="EventEdit.Details.EventRecommendation"
                                    defaultMessage="Why we recommend?"
                                />
                            </Form.SectionTitle>
                            <Form.Row>
                                <FieldArray
                                    name="why_we_recommend"
                                    id="why-we-recommend"
                                    component={Repeater}
                                    RepeatableItem={RecommendationRepeaterItem}
                                    loading={singleFetching}
                                    data={initialValues}
                                    onSortEnd={this.handleSortEnd}
                                    onRemoveItem={this.handleRemoveItem}
                                    onSaveNewItem={handleSubmit(this.onSubmit)}
                                    uniqueIdentifier="EventEdit.Details.WhyWeRecommend"
                                />
                            </Form.Row>
                        </div>
                        <Form.Row>
                            <Button
                                submit
                                disabled={pristine || invalid}
                                loading={submitting}
                                label={
                                    <FormattedMessage
                                        id="EventEdit.Details.Update"
                                        defaultMessage="Update"
                                    />
                                }
                            />
                        </Form.Row>
                    </Form>
                </PageContent>
            </LayoutManager>
        );
    }
}

EventDetails.defaultProps = {
    submitting: false,
    pristine: false,
    invalid: false,
    fetchEventData: () => {},
    singleFetching: false,
    initialValues: {}
};

EventDetails.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.oneOfType([PropTypes.object]).isRequired,
    initialValues: PropTypes.oneOfType([PropTypes.object]),
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    fetchEventData: PropTypes.func,
    singleFetching: PropTypes.bool
};

EventDetails = reduxForm({
    form: 'EventDetailsForm',
    enableReinitialize: true
})(EventDetails);

//  Getting initial value populated in the form from the store
const mapStateToProps = (state, ownProps) => ({
    initialValues: dtoForm(getEventData(state, ownProps.match.params.id)),
    singleFetching: getSingleEventFetching(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchEventData: () => {
        // Getting included data from API by setting params
        const params = {
            include:
                'location,location.country,location.state,location.city,ageRequirement,eventSeries,eventCategories,foodTypes,barTypes,timezone'
        };
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: eventsActions.START_FETCHING_SINGLE_EVENT,
                id: ownProps.match.params.id,
                params
            });
        }).catch(error => ({ error }));
    }
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(EventDetails)
);
