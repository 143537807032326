import React, { Component } from 'react';
import LayoutManager from 'erputils/LayoutManager';
import PageHeader from 'erpcomponents/Layout/PageHeader';
import PageContent from 'erpcomponents/Layout/PageContent';
import { withRouter } from 'react-router-dom';
import LocationEditTabs from 'erpcore/screens/Venues/components/LocationEditTabs';
import PropTypes from 'prop-types';
import Listing from 'erpcomponents/Listing';
import { FormattedMessage } from 'react-intl';
import ListingTableActions from 'erpcomponents/Listing/components/TableActions';
import { connect } from 'react-redux';
import { getListingFetching, getListingResponse } from 'erpcomponents/Listing/Listing.selectors';
import { actions as listingActions } from 'erpcomponents/Listing/Listing.reducer';
import { actions as venuesActions } from 'erpcore/screens/Venues/Venues.reducer';
import RoomCreate from 'erpcore/screens/Venues/screens/VenuesEdit/Rooms/components/RoomCreate';
import RoomDelete from 'erpcore/screens/Venues/screens/VenuesEdit/Rooms/components/RoomDelete';
import RoomEdit from 'erpcore/screens/Venues/screens/VenuesEdit/Rooms/components/RoomEdit';
import { getVenueName, getVenueData } from 'erpcore/screens/Venues/Venues.selectors';

const reducerName = 'rooms';

class RoomsListing extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        const { venueData, fetchVenueData } = this.props;
        if (Object.keys(venueData) <= 0) {
            fetchVenueData();
        }
    }

    tableData() {
        const { roomsData } = this.props;
        const table = {};
        table.data = [];

        // Table Schema
        table.schema = [
            {
                title: <FormattedMessage id="Venues.Rooms.Table" defaultMessage="Room" />,
                field: 'room',
                sortable: 'name'
            },
            {
                title: (
                    <FormattedMessage id="Venues.Rooms.Table.Actions" defaultMessage="Actions" />
                ),
                field: 'actions'
            }
        ];

        roomsData.data.map(row => {
            return table.data.push({
                id: row.id,
                room: row.name,
                actions: (
                    <ListingTableActions>
                        <ListingTableActions.Action>
                            <RoomEdit id={row.id} key={`RoomEdit_${row.id}`} form="RoomEditForm" />
                        </ListingTableActions.Action>
                        <ListingTableActions.Action>
                            <RoomDelete
                                id={row.id}
                                location={row.location && row.location.id}
                                key={`RoomDelete_${row.id}`}
                                form="RoomDeleteForm"
                            />
                        </ListingTableActions.Action>
                    </ListingTableActions>
                )
            });
        });

        return table;
    }

    render() {
        const { fetchRooms, roomsListingFetching, roomsData, venueName, venueData } = this.props;
        const venue = venueName && ` - ${venueName}`;

        return (
            <LayoutManager slot="main" className="main--narrow" layoutType="merge">
                <PageHeader
                    title={
                        <FormattedMessage
                            id="Venues.Rooms.title"
                            defaultMessage="Edit Venue {venue}"
                            values={{ venue }}
                        />
                    }
                    backButtonLink="/venues"
                />
                <LocationEditTabs lessPadding />
                <PageContent>
                    <RoomCreate venueData={venueData} />
                    <Listing
                        name={reducerName}
                        reducerName={reducerName}
                        loading={roomsListingFetching}
                        hideSearch
                        meta={roomsData.meta}
                        table={this.tableData()}
                        onListingConfigUpdate={params => fetchRooms(params)}
                    />
                </PageContent>
            </LayoutManager>
        );
    }
}

RoomsListing.defaultProps = {
    // pristine: false,
    // invalid: false
    roomsData: {},
    roomsListingFetching: false,
    venueName: '',
    venueData: {}
};

RoomsListing.propTypes = {
    // pristine: PropTypes.bool,
    // invalid: PropTypes.bool
    roomsData: PropTypes.oneOfType([PropTypes.object]),
    fetchRooms: PropTypes.func.isRequired,
    fetchVenueData: PropTypes.func.isRequired,
    roomsListingFetching: PropTypes.bool,
    venueName: PropTypes.string,
    venueData: PropTypes.oneOfType([PropTypes.object])
};

const mapStateToProps = (state, ownProps) => ({
    roomsListingFetching: getListingFetching(state, 'rooms'),
    roomsData: getListingResponse(state, 'rooms'),
    venueName: getVenueName(state),
    venueData: getVenueData(state, ownProps.match.params.id)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchRooms: params => {
        // Getting included data from API by setting defaultParams

        const listingParams = Object.assign(
            {
                'filters[location.id][equals]': ownProps.match.params.id
            },
            params
        );

        dispatch({
            type: listingActions.START_FETCHING_LISTING,
            params: listingParams,
            name: reducerName,
            entity: 'ROOMS',
            endpoint: `api/rooms`
        });
    },
    fetchVenueData: () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: venuesActions.START_FETCHING_SINGLE_VENUE,
                id: ownProps.match.params.id
            });
        }).catch(error => ({ error }));
    }
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(RoomsListing)
);
