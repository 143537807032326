/**
 * Handling Location Form Data
 * @param {object} containing form data
 * @returns {object} containing payload for the API and initial values to be combined with the API response in the saga
 */
const formatLocationFormData = formData => {
    // Set inital values
    const data = {
        payload: {},
        initialValues: formData
    };
    let venueName = {};
    const payload = Object.assign({}, formData);
    payload.location = Object.assign({}, formData.location);

    if (payload.venue_name) {
        venueName = { venue_name: payload.venue_name };
    }
    if (payload.location && payload.location.state === '') {
        payload.location.state = null;
    }

    if (payload.included) {
        delete payload.included;
    }
    if (payload.location && payload.location.fullAddress) {
        delete payload.location.fullAddress;
    }
    if (payload.location && payload.location.locationPin) {
        delete payload.location.locationPin;
    }
    if (Object.keys(payload.location).length === 0 && payload.constructor === Object) {
        delete payload.location;
    }

    data.payload = Object.assign({}, payload.location, venueName);

    return data;
};

export default formatLocationFormData;
