import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import Form from 'erpcore/components/Form';
import Button from 'erpcomponents/Button';
import Svg from 'erpcomponents/Svg';
import Tooltip from 'erpcomponents/Tooltip';
import Modal from 'erpcomponents/Modal';
import { actions as ordersActions } from 'erpcore/screens/Orders/Orders.reducer';

class OrderDelete extends Component {
    constructor(props) {
        super(props);

        this.state = {
            opened: false
        };

        this.handleModal = this.handleModal.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit() {
        const { id, dispatch } = this.props;

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: ordersActions.START_DELETE_SINGLE_ORDER,
                id
            })
        )
            .then(() => {
                this.setState({ opened: false });
            })
            .catch(error => {
                this.setState({ opened: false });
                return error;
            });
    }

    handleModal() {
        const { opened: isOpen } = this.state;
        this.setState({ opened: !isOpen });
    }

    render() {
        const { handleSubmit, submitting } = this.props;
        const { opened } = this.state;

        return (
            <Fragment>
                <Tooltip
                    content={
                        <FormattedMessage
                            id="Orders.Table.Actions.Delete"
                            defaultMessage="Delete Order"
                        />
                    }
                >
                    <button type="button" onClick={() => this.handleModal()}>
                        <Svg icon="remove" />
                    </button>
                </Tooltip>
                <Modal
                    variation="small"
                    opened={opened}
                    onClose={() => this.handleModal()}
                    title={
                        <FormattedMessage
                            id="Orders.Table.Actions.Delete.Modal.title"
                            defaultMessage="Delete Order"
                        />
                    }
                    subtitle={
                        <FormattedMessage
                            id="Orders.Table.Actions.Delete.Modal.subtitle"
                            defaultMessage="Are you sure you wanna delete this Order?"
                        />
                    }
                >
                    <Form onSubmit={handleSubmit(this.onSubmit)}>
                        <Button
                            submit
                            loading={submitting}
                            label={
                                <FormattedMessage
                                    id="Orders.Table.Actions.Delete.Modal.Delete"
                                    defaultMessage="Delete"
                                />
                            }
                        />
                        <Button
                            variation="secondary"
                            label={
                                <FormattedMessage
                                    id="Orders.Table.Actions.Delete.Modal.Cancel"
                                    defaultMessage="Cancel"
                                />
                            }
                            onClick={() => this.handleModal()}
                        />
                    </Form>
                </Modal>
            </Fragment>
        );
    }
}

OrderDelete.defaultProps = {
    submitting: false,
    id: null,
    dispatch: () => {}
};

OrderDelete.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    dispatch: PropTypes.func
};

export default reduxForm({
    enableReinitialize: true
})(OrderDelete);
