import { takeLatest, put, call, select } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import { setToken } from 'erpcore/api';
import { actions as authActions } from 'erputils/AuthManager/AuthManager.reducer';
import { actions as notificationManagerActions } from 'erputils/NotificationManager/NotificationManager.reducer';
import dto from 'erputils/dto';
import { getMeData } from 'erputils/AuthManager/AuthManager.selectors';
import { actions as myAccountActions } from './MyAccount.reducer';

/**
 * Update current user data
 * @param  {Object} promise {resolve, reject}
 * @return {Object} formData from the form
 */
export function* updateMyAccount({ promise, formData }) {
    try {
        // use selector for current user IRI
        const currentUser = yield select(getMeData);

        const myAccountAPICall = yield restClient.put(
            `${currentUser.iri}?include=organizations,city,image`,
            formData
        );
        yield put({
            type: myAccountActions.UPDATE_USER_SUCCESSFUL
        });
        // Store User Data
        // Overriding message code to use different than the message code for the User entity
        yield put({ type: authActions.UPDATE_USER_DATA, response: dto(myAccountAPICall.data) });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: Object.assign({}, myAccountAPICall.data, {
                code: 'user.currentUserSuccessfulyUpdated'
            })
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: myAccountActions.UPDATE_USER_FAILED,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Change Password
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* changePassword({ promise, formData }) {
    if (formData.new_password === formData.new_password_confirmation) {
        try {
            const myAccountChangePasswordAPICall = yield restClient.patch(
                `api/users/${formData.id}/password`,
                {
                    new_password: formData.new_password,
                    old_password: formData.old_password
                }
            );
            yield put({
                type: myAccountActions.CHANGE_PASSWORD_SUCCESFUL
            });
            yield setToken(myAccountChangePasswordAPICall.data.token);
            yield put({
                type: notificationManagerActions.SET_PAGE_NOTIFICATION,
                response: myAccountChangePasswordAPICall.data
            });
            yield call(promise.resolve);
        } catch (error) {
            yield put({
                type: myAccountActions.CHANGE_PASSWORD_FAILED
            });
            yield put({
                type: notificationManagerActions.SET_PAGE_NOTIFICATION,
                response: error.response.data
            });
            yield call(promise.reject, error.response.data);
        }
    } else {
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: {
                code: 'user.ownerNewPassordConfirmationWrongMatch'
            }
        });
        yield call(promise.reject);
    }
}

/**
 * Register action to watcher
 */
export const myAccountSaga = [
    takeLatest(myAccountActions.START_UPDATE_USER, updateMyAccount),
    takeLatest(myAccountActions.START_CHANGE_PASSWORD, changePassword)
];
