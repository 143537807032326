import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import { FormattedMessage } from 'react-intl';
import { FieldArray } from 'redux-form';
import Form, { Repeater } from 'erpcore/components/Form';
// import Button from 'erpcomponents/Button';
import { actions as groupsActions } from 'erpcore/screens/Groups/Groups.reducer';
import {
    // getSingleGroupData,
    getSingleGroupFetching
} from 'erpcore/screens/Groups/Groups.selectors';
import GroupSplitRepeaterItem from 'erpcore/screens/Groups/components/GroupSplitRepeaterItem';
import './GroupSplitForm.scss';

class GroupSplitForm extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        const { onSaveAll, initialValues, eventId, deleteItem } = this.props;
        return (
            <div className="group-split-form">
                <Form.SectionTitle>Group Splitting</Form.SectionTitle>
                <Form.Column>
                    <Form.Row>
                        <FieldArray
                            name="children"
                            component={Repeater}
                            RepeatableItem={GroupSplitRepeaterItem}
                            saveButtonIntent="all"
                            canAddMultiple
                            data={{ primaryGroup: initialValues, eventId }}
                            addNewButtonVariation="right"
                            canSort={false}
                            onRemoveItem={item => {
                                deleteItem(item);
                            }}
                            onSaveAll={({ oldItems, oldItemsDiff, newItems }) =>
                                onSaveAll(oldItems, oldItemsDiff, newItems)
                            }
                        />
                    </Form.Row>
                </Form.Column>
            </div>
        );
    }
}

GroupSplitForm.defaultProps = {
    // submitting: false,
    // pristine: false,
    // invalid: false,
    // fetchGroupData: () => {},
    initialValues: {},
    onSaveAll: () => {},
    eventId: null,
    // onRemoveChild: () => {}
    // id: '',
    deleteItem: () => {}
};

GroupSplitForm.propTypes = {
    // onRemoveChild: PropTypes.func,
    onSaveAll: PropTypes.func,
    initialValues: PropTypes.oneOfType([PropTypes.object]),
    eventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    deleteItem: PropTypes.func
    // submitting: PropTypes.bool,
    // pristine: PropTypes.bool,
    // invalid: PropTypes.bool,
    // fetchGroupData: PropTypes.func,
    // id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

const mapStateToProps = state => ({
    singleFetching: getSingleGroupFetching(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchGroupData: () => {
        // Getting included data from API by setting params
        const params = {
            include: 'table'
        };
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: groupsActions.START_FETCHING_SINGLE_GROUP,
                id: ownProps.id,
                params
            });
        }).catch(error => ({ error }));
    },

    deleteItem: item => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                id: item.itemData.id,
                type: groupsActions.START_DELETE_SINGLE_GROUP
            });
        });
    },

    onSaveAll: (oldItems, oldItemsDiff, newItems) => {
        const { initialValues } = ownProps;
        const name = initialValues && initialValues.name;
        const { id } = initialValues;

        let previousIndex = 0;

        const children = [];

        if (oldItems.length > 0) {
            oldItems.forEach((item, index) => {
                const obj = {
                    name: item.name ? item.name : `${name}-${index + 1}`,
                    group_size: item.group_size && parseInt(item.group_size, 10),
                    id: item.id && item.id
                };
                if (item.tables) {
                    Object.assign(obj, {
                        ...obj,
                        tables: item.tables instanceof Array ? [item.tables[0].iri] : [item.tables]
                    });
                }
                previousIndex = index + 1;

                children.push(obj);
            });
        }

        if (newItems.length > 0) {
            newItems.forEach((item, index) => {
                const obj = {
                    name: item.name
                        ? item.name
                        : `${name.split('-')[0]}-${previousIndex + index + 1}`,
                    group_size: item.group_size && parseInt(item.group_size, 10)
                };

                if (item.tables) {
                    Object.assign(obj, {
                        ...obj,
                        tables: item.tables && [item.tables]
                    });
                }

                children.push(obj);
            });
        }

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: groupsActions.START_SPLIT_SINGLE_GROUP,
                id,
                formData: { children }
            })
        )
            .then(() => ownProps.fetchGroupData(ownProps.initialValues.id))
            .catch(error => {
                return error;
            });
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GroupSplitForm);
