import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Widget.scss';

class Widget extends Component {
    handleClassName() {
        const { className, size } = this.props;

        let classNames = 'widget';

        if (className) {
            classNames = `${classNames} ${className}`;
        }

        // Size
        if (size) {
            classNames = `${classNames} widget--${size}`;
        } else {
            classNames = classNames.replace(` widget--${size}`, '');
        }

        return classNames;
    }

    render() {
        const { title, subtitle, filters, headerAside, children } = this.props;

        return (
            <div className={this.handleClassName()}>
                {(title || subtitle || filters || headerAside) && (
                    <header className="widget__header">
                        {(title || subtitle || filters) && (
                            <div className="widget__header-main">
                                {title && <h3 className="widget__title">{title}</h3>}
                                {subtitle && <p className="widget__subtitle">{subtitle}</p>}
                                {filters && <div className="widget__filters">{filters}</div>}
                            </div>
                        )}
                        {headerAside && <div className="widget__header-aside">{headerAside}</div>}
                    </header>
                )}
                {children && <div className="widget__content">{children}</div>}
            </div>
        );
    }
}

Widget.defaultProps = {
    className: '',
    size: null,
    title: null,
    subtitle: null,
    filters: null,
    headerAside: null,
    children: null
};

Widget.propTypes = {
    className: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    filters: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    headerAside: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};

export default Widget;
