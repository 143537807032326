import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import ElementLoader from 'erpcomponents/ElementLoader';

import {
    getData,
    getFetching
} from 'erpcomponents/Widgets/TicketTypesReport/TicketTypesReport.selectors';
import Listing from 'erpcomponents/Listing';

class TicketTypesReport extends Component {
    tableData() {
        const { data, ticketTypeID } = this.props;
        const table = {};
        table.data = [];

        // Table Schema
        table.schema = [
            {
                title: (
                    <FormattedMessage id="TicketTypesReport.Table.Price" defaultMessage="Price" />
                ),
                field: 'price'
            },
            {
                title: (
                    <FormattedMessage
                        id="TicketTypesReport.Table.LastDateSold"
                        defaultMessage="Last Date Sold"
                    />
                ),
                field: 'last_date_sold'
            },
            {
                title: <FormattedMessage id="TicketTypesReport.Table.Sold" defaultMessage="Sold" />,
                field: 'sold'
            },
            {
                title: (
                    <FormattedMessage id="TicketTypesReport.Table.Sales" defaultMessage="Sales" />
                ),
                field: 'sales'
            },
            {
                title: <FormattedMessage id="TicketTypesReport.Table.Fees" defaultMessage="Fees" />,
                field: 'fees'
            },
            {
                title: (
                    <FormattedMessage
                        id="TicketTypesReport.Table.Delivery"
                        defaultMessage="Delivery"
                    />
                ),
                align: 'right',
                field: 'delivery'
            }
        ];

        let soldSum = 0;
        let currencyTotals = null;
        let salesSum = 0;
        let feesSum = 0;
        let deliverySum = 0;

        if (data.data && data.data[ticketTypeID]) {
            data.data[ticketTypeID].map(el => {
                soldSum += parseInt(el.sold, 0);
                currencyTotals = el.currency;
                salesSum += parseInt(el.sales, 0);
                feesSum += parseInt(el.fees, 0);
                deliverySum += parseInt(el.delivery, 0);

                return table.data.push({
                    price: ((style = 'currency') => {
                        return (
                            <FormattedNumber
                                value={parseInt(el.price, 10) / 100}
                                currency={el.currency}
                                style={style}
                                minimumFractionDigits={2}
                                maximumFractionDigits={2}
                            />
                        );
                    })(),
                    last_date_sold: el.last_date_sold,
                    sold: el.sold,
                    sales: ((style = 'currency') => {
                        return (
                            <FormattedNumber
                                value={parseInt(el.sales, 10) / 100}
                                currency={el.currency}
                                style={style}
                                minimumFractionDigits={2}
                                maximumFractionDigits={2}
                            />
                        );
                    })(),
                    fees: ((style = 'currency') => {
                        return (
                            <FormattedNumber
                                value={parseInt(el.fees, 10) / 100}
                                currency={el.currency}
                                style={style}
                                minimumFractionDigits={2}
                                maximumFractionDigits={2}
                            />
                        );
                    })(),
                    delivery: ((style = 'currency') => {
                        return (
                            <FormattedNumber
                                value={parseInt(el.delivery, 10) / 100}
                                currency={el.currency}
                                style={style}
                                minimumFractionDigits={2}
                                maximumFractionDigits={2}
                            />
                        );
                    })()
                });
            });
        }

        table.data.push({
            price: (
                <strong>
                    <FormattedMessage id="EventSales.Totals" defaultMessage="Totals:" />
                </strong>
            ),
            last_date_sold: '',
            sold: (
                <strong>
                    <FormattedNumber value={soldSum} />
                </strong>
            ),
            sales: ((style = 'currency') => {
                return (
                    <strong>
                        <FormattedNumber
                            value={salesSum / 100}
                            currency={currencyTotals}
                            style={style}
                            minimumFractionDigits={2}
                            maximumFractionDigits={2}
                        />
                    </strong>
                );
            })(),
            fees: ((style = 'currency') => {
                return (
                    <strong>
                        <FormattedNumber
                            value={feesSum / 100}
                            currency={currencyTotals}
                            style={style}
                            minimumFractionDigits={2}
                            maximumFractionDigits={2}
                        />
                    </strong>
                );
            })(),
            delivery: ((style = 'currency') => {
                return (
                    <strong>
                        {' '}
                        <FormattedNumber
                            value={deliverySum / 100}
                            currency={currencyTotals}
                            style={style}
                            minimumFractionDigits={2}
                            maximumFractionDigits={2}
                        />
                    </strong>
                );
            })()
        });

        return table;
    }

    render() {
        const { fetching } = this.props;

        return (
            <div className="ticket-types-report">
                {fetching && <ElementLoader overlay />}

                <Listing
                    table={this.tableData()}
                    loading={fetching}
                    hideSearch
                    hideFooter
                    hideFilters
                    hideHeader
                    disableMobileLayout
                />
            </div>
        );
    }
}

TicketTypesReport.defaultProps = {
    fetching: false,
    data: {},
    ticketTypeID: null
};

TicketTypesReport.propTypes = {
    fetching: PropTypes.bool,
    data: PropTypes.oneOfType([PropTypes.object]),
    ticketTypeID: PropTypes.string
};

//  Getting initial value populated in the form from the store
const mapStateToProps = (state, ownProps) => {
    return {
        data: getData(state, ownProps.id),
        fetching: getFetching(state, ownProps.id)
    };
};

export default connect(mapStateToProps)(TicketTypesReport);
