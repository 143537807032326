import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { SubmissionError } from 'redux-form';
import { reduxFormErrorMapper } from 'erpcore/components/Form/Form.utils';

import LayoutManager from 'erpcore/utils/LayoutManager';
import PageContent from 'erpcore/components/Layout/PageContent';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import PageLoader from 'erpcore/components/PageLoader';
import RoleForm from 'erpcore/screens/Settings/screens/Roles/components/RoleForm';

import { actions as rolesActions } from 'erpcore/screens/Settings/screens/Roles/Roles.reducer';
import {
    getRoleData,
    getRoleFetching
} from 'erpcore/screens/Settings/screens/Roles/Roles.selectors';
import { dtoForm } from 'erpcore/utils/dto';

const RoleEdit = ({ match }) => {
    const dispatch = useDispatch();
    const roleData = dtoForm(useSelector(state => getRoleData(state, match?.params?.id)));
    const fetching = useSelector(getRoleFetching);

    const fetchRoleData = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: rolesActions.START_FETCHING_ROLE,
                id: match?.params?.id
            });
        }).catch(error => ({ error }));
    };

    useEffect(() => {
        fetchRoleData();
    }, []);

    const onSubmitRoleEditForm = formData => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: rolesActions.START_UPDATE_ROLE,
                id: match.params.id,
                formData
            });
        }).catch(error => {
            throw new SubmissionError(reduxFormErrorMapper(error));
        });
    };

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <PageHeader title={`Role Edit - ${roleData?.name || ''}`} />
            {fetching === true && <PageLoader />}
            <PageContent>
                <RoleForm
                    onSubmit={onSubmitRoleEditForm}
                    form="RoleEditForm"
                    initialValues={roleData}
                    submitLabel="Update"
                />
            </PageContent>
        </LayoutManager>
    );
};

RoleEdit.defaultProps = {
    match: {}
};

RoleEdit.propTypes = {
    match: PropTypes.oneOfType([PropTypes.object])
};

export default withRouter(RoleEdit);
