export const actions = {
    START_FETCHING_YEAR_OVER_YEAR_EVENTS: 'START_FETCHING_YEAR_OVER_YEAR_EVENTS',
    FETCHING_YEAR_OVER_YEAR_EVENTS_SUCCESSFUL: 'FETCHING_YEAR_OVER_YEAR_EVENTS_SUCCESSFUL',
    FETCHING_YEAR_OVER_YEAR_EVENTS_FAILED: 'FETCHING_YEAR_OVER_YEAR_EVENTS_FAILED',

    START_FETCHING_YEAR_OVER_YEAR_CHART_DATA: 'START_FETCHING_YEAR_OVER_YEAR_CHART_DATA',
    FETCHING_YEAR_OVER_YEAR_CHART_DATA_SUCCESSFUL: 'FETCHING_YEAR_OVER_YEAR_CHART_DATA_SUCCESSFUL',
    FETCHING_YEAR_OVER_YEAR_CHART_DATA_FAILED: 'FETCHING_YEAR_OVER_YEAR_CHART_DATA_FAILED',

    START_FETCHING_TICKETTYPE_BREAKDOWN: 'START_FETCHING_TICKETTYPE_BREAKDOWN',
    FETCHING_TICKETTYPE_BREAKDOWN_SUCCESSFUL: 'FETCHING_TICKETTYPE_BREAKDOWN_SUCCESSFUL',
    FETCHING_TICKETTYPE_BREAKDOWN_FAILED: 'FETCHING_TICKETTYPE_BREAKDOWN_FAILED'
};

export const initialState = {
    fetching: false,
    events: [],
    chartData: {}
};

export default (state = initialState, { type, response, eventIri }) => {
    switch (type) {
        case actions.START_FETCHING_YEAR_OVER_YEAR_EVENTS: {
            return {
                ...state,
                fetching: true
            };
        }
        case actions.START_FETCHING_YEAR_OVER_YEAR_CHART_DATA: {
            return {
                ...state,
                fetching: true
            };
        }
        case actions.FETCHING_YEAR_OVER_YEAR_EVENTS_FAILED: {
            return {
                ...state,
                fetching: false
            };
        }
        case actions.FETCHING_YEAR_OVER_YEAR_CHART_DATA_FAILED: {
            return {
                ...state,
                fetching: false
            };
        }
        case actions.FETCHING_YEAR_OVER_YEAR_EVENTS_SUCCESSFUL: {
            return {
                ...state,
                fetching: false,
                events: response.data
            };
        }
        case actions.FETCHING_YEAR_OVER_YEAR_CHART_DATA_SUCCESSFUL: {
            return {
                ...state,
                fetching: false,
                chartData: {
                    ...state.chartData,
                    [eventIri]: {
                        data: response?.data,
                        totals: response?.totals
                    }
                }
            };
        }
        case actions.START_FETCHING_TICKETTYPE_BREAKDOWN: {
            return {
                ...state,
                fetching: true
            };
        }
        case actions.FETCHING_TICKETTYPE_BREAKDOWN_SUCCESSFUL: {
            return {
                ...state,
                fetching: false,
                ticketTypeBreakdown: {
                    ...state.ticketTypeBreakdown,
                    [eventIri]: {
                        data: response?.data,
                        totals: response?.totals
                    }
                }
            };
        }
        case actions.FETCHING_TICKETTYPE_BREAKDOWN_FAILED: {
            return {
                ...state,
                fetching: false
            };
        }

        default:
            return state;
    }
};
