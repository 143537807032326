import { createSelector } from 'reselect';

export const getCheckedGroups = state => state.groups && state.groups.checked;

export const getGroupData = (state, id) =>
    state.groups && state.groups.groups && state.groups.groups.find(group => group.id === id);

export const getSingleGroupFetching = state => state.groups.singleFetching;

export const getSingleGroupData = (state, id) =>
    state.groups && state.groups[id] && state.groups[id];

export const getGroups = state => state.groups && state.groups.groups;

export const getGroupDataFromIri = (state, iris) => {
    const groups = [];
    if (state.groups) {
        const matchingGroup = iris.map(iri => state.groups.find(group => group.iri === iri));
        groups.push(matchingGroup);
    }
    return groups;
};

export const submittingBulkAction = state =>
    state.groups && state.groups.submittingBulkAction && state.groups.submittingBulkAction;

export default createSelector([
    getGroupData,
    getSingleGroupFetching,
    getGroups,
    getSingleGroupData,
    getGroupDataFromIri,
    submittingBulkAction
]);
