import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FieldArray, reduxForm } from 'redux-form';
import Form, { Gallery } from 'erpcore/components/Form';
import { FormattedMessage } from 'react-intl';
import LayoutManager from 'erputils/LayoutManager';
import PageHeader from 'erpcomponents/Layout/PageHeader';
import PageContent from 'erpcomponents/Layout/PageContent';
import EventInfo from 'erpcore/screens/Events/components/EventInfo';
import EventEditTabs from 'erpcore/screens/Events/components/EventEditTabs';
import NotificationManager from 'erputils/NotificationManager';
import { getEventData, getSingleEventFetching } from 'erpcore/screens/Events/Events.selectors';
import { actions as eventsActions } from 'erpcore/screens/Events/Events.reducer';
import PageLoader from 'erpcomponents/PageLoader';

class EventImages extends Component {
    componentDidMount() {
        const { fetchEventData } = this.props;
        fetchEventData();
    }

    render() {
        const { error, eventData, match, singleFetching } = this.props;

        return (
            <LayoutManager slot="main" className="main--narrow" layoutType="merge">
                <PageHeader
                    title={<FormattedMessage id="EventImages.title" defaultMessage="Edit Event" />}
                    backButtonLink="/events"
                />
                {singleFetching === true && <PageLoader />}
                <EventInfo {...eventData} />
                <EventEditTabs />
                <PageContent>
                    {error && error.code && <NotificationManager code={error.code} />}
                    <Form onSubmit={() => {}}>
                        <h4>Featured Images</h4>
                        <Form.Row>
                            <FieldArray
                                name="galleryFeaturedImages"
                                id="event-gallery-featured"
                                component={Gallery}
                                featuredKey="featured"
                                versionName="entityVersion"
                                attachedToEntity={{
                                    type: 'event',
                                    iri: `/api/events/${match.params.id}`,
                                    id: match.params.id
                                }}
                                getImagesApi={`/api/events/${match.params.id}/images`}
                                galleryApi="/api/event-images"
                                groupName="featured_images"
                            />
                        </Form.Row>

                        <br />

                        <h4>Event Photos</h4>
                        <Form.Row>
                            <FieldArray
                                name="galleryEventPhotos"
                                id="event-gallery-photos"
                                component={Gallery}
                                featuredKey="featured"
                                versionName="entityVersion"
                                attachedToEntity={{
                                    type: 'event',
                                    iri: `/api/events/${match.params.id}`,
                                    id: match.params.id
                                }}
                                getImagesApi={`/api/events/${match.params.id}/images`}
                                galleryApi="/api/event-images"
                                groupName="event_photos"
                            />
                        </Form.Row>

                        <br />

                        <h4>Flyers + Marketing</h4>
                        <Form.Row>
                            <FieldArray
                                name="galleryFlyersMarketing"
                                id="event-marketing-photos"
                                component={Gallery}
                                featuredKey="featured"
                                versionName="entityVersion"
                                attachedToEntity={{
                                    type: 'event',
                                    iri: `/api/events/${match.params.id}`,
                                    id: match.params.id
                                }}
                                getImagesApi={`/api/events/${match.params.id}/images`}
                                galleryApi="/api/event-images"
                                groupName="flyers_marketing"
                            />
                        </Form.Row>
                    </Form>
                </PageContent>
            </LayoutManager>
        );
    }
}

EventImages.defaultProps = {
    error: null,
    match: {},
    fetchEventData: () => {},
    singleFetching: false,
    eventData: null
};

EventImages.propTypes = {
    error: PropTypes.oneOfType([PropTypes.object]),
    match: PropTypes.oneOfType([PropTypes.object]),
    fetchEventData: PropTypes.func,
    singleFetching: PropTypes.bool,
    eventData: PropTypes.oneOfType([PropTypes.object])
};

EventImages = reduxForm({
    form: 'EventImages',
    enableReinitialize: true,
    initialValues: {
        galleryFeaturedImages: [],
        galleryEventPhotos: [],
        galleryFlyersMarketing: []
    }
})(EventImages);

//  Getting initial value populated in the form from the store
const mapStateToProps = (state, ownProps) => ({
    eventData: getEventData(state, ownProps.match.params.id),
    singleFetching: getSingleEventFetching(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchEventData: () => {
        // Getting included data from API by setting params
        const params = {
            include: 'organization,location,location.country,location.state,location.city'
        };
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: eventsActions.START_FETCHING_SINGLE_EVENT,
                id: ownProps.match.params.id,
                params
            });
        }).catch(error => ({ error }));
    }
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(EventImages)
);
