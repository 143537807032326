import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import { GaugeChart } from 'erpcomponents/WidgetCharts';
import ElementLoader from 'erpcomponents/ElementLoader';
import Widget from 'erpcomponents/Widget';

import './SalesCycle.scss';

import { actions as salesCycleActions } from 'erpcomponents/Widgets/SalesCycle/SalesCycle.reducer';
import { getData, getFetching } from 'erpcomponents/Widgets/SalesCycle/SalesCycle.selectors';
import { chartColors } from 'erpdata/colors';

class SalesCycle extends Component {
    componentDidMount() {
        const { eventSeries, eventID, soldBy } = this.props;
        if (eventSeries || eventID) {
            return this.fetchData({
                eventID,
                eventSeries,
                soldBy
            });
        }
        return null;
    }

    componentWillReceiveProps(nextProps) {
        const {
            eventID: nextEventID,
            eventSeries: nextEventSeries,
            soldBy: nextSoldBy
        } = nextProps;
        const { eventSeries, eventID, soldBy } = this.props;

        if (
            (nextEventID && nextEventID !== eventID) ||
            (nextEventSeries && nextEventSeries !== eventSeries) ||
            (nextSoldBy && nextSoldBy !== soldBy)
        ) {
            return this.fetchData({
                eventID: nextEventID,
                eventSeries: nextEventSeries,
                soldBy: nextSoldBy
            });
        }

        return true;
    }

    fetchData({ eventID, eventSeries, soldBy }) {
        const { dispatch, id } = this.props;

        const params = {
            display_by: 'event'
        };

        if (eventID) {
            params['filters[events]'] = eventID;
        }

        if (eventSeries) {
            params['filters[event_series]'] = eventSeries;
        }

        if (soldBy) {
            params['filters[sold_by]'] = soldBy;
        }

        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: salesCycleActions.START_FETCHING_SALES_CYCLE_DATA,
                widgetId: id,
                params
            });
        }).catch(error => ({ error }));
    }

    formatWidgetData() {
        const { data: initialData } = this.props;

        const percentage =
            initialData &&
            initialData.totals &&
            initialData.totals.max_attendance &&
            initialData.totals.attendance
                ? (initialData.totals.attendance / initialData.totals.max_attendance) * 100
                : 0;

        const widgetData = {
            gaugeChart: {
                percentage,
                data: {},
                options: {
                    tooltips: {
                        mode: 'label',
                        callbacks: {
                            label(tooltipItem, labelData) {
                                if (labelData.datasets && labelData.labels) {
                                    const label = labelData.labels[tooltipItem.index];
                                    const value =
                                        labelData.datasets[tooltipItem.datasetIndex].data[
                                            tooltipItem.index
                                        ];
                                    return [`${label}: ${value.toLocaleString('en-US')}`];
                                }
                                return [];
                            }
                        }
                    }
                }
            },
            attendances: {
                attendance:
                    (initialData && initialData.totals && initialData.totals.attendance) || 0,
                expected:
                    (initialData && initialData.totals && initialData.totals.max_attendance) || 0
            },
            totalRevenue: {
                value: (initialData && initialData.totals && initialData.totals.revenue) || 0,
                currency:
                    (initialData && initialData.totals && initialData.totals.revenue_currency) ||
                    'USD'
            }
        };

        /*
        BALLDROP:
        available_tickets_to_sell: 12850
        max_attendance: 14233
        max_tickets_to_sell: 14233
        revenue: 127453202
        tickets_sold: 1383
         */

        /*
        GLOW:
        attendance: 314700
        expected_attendance: 1170000
        percentage_based_on_expected_attendance: 26.9
        revenue: 605329663
        revenue_currency: "CAD"
        tickets_sold: 267335
         */

        // gaugeChart
        const unattended =
            initialData &&
            initialData.totals &&
            initialData.totals.max_attendance &&
            initialData.totals.attendance
                ? initialData.totals.max_attendance - initialData.totals.attendance
                : 0;

        widgetData.gaugeChart.data = {
            labels: ['Sold Tickets', 'Unsold Tickets'],
            datasets: [
                {
                    backgroundColor: [chartColors[0], chartColors[1]],
                    data: [
                        (initialData && initialData.totals && initialData.totals.attendance) || 0,
                        unattended
                    ]
                }
            ]
        };

        return widgetData;
    }

    renderAttendance() {
        const widgetData = this.formatWidgetData();

        return (
            <div className="sales-cycle__attendance">
                <div className="sales-cycle__attendance-chart">
                    <GaugeChart
                        data={widgetData.gaugeChart.data}
                        options={widgetData.gaugeChart.options}
                    />
                    <div className="sales-cycle__attendance-chart-content">
                        <div className="sales-cycle__attendance-chart-data">
                            <span className="sales-cycle__attendance-chart-total">
                                {widgetData.gaugeChart.percentage.toFixed(1)}%
                            </span>
                        </div>
                    </div>
                </div>
                <div className="sales-cycle__attendance-data">
                    <p className="sales-cycle__attendance-data-item">
                        <FormattedMessage
                            id="SalesCycle.Attendance"
                            defaultMessage="Tickets sold"
                        />
                        <br />
                        <strong>
                            {widgetData.attendances.attendance
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        </strong>
                    </p>
                    <p className="sales-cycle__attendance-data-item">
                        <FormattedMessage id="SalesCycle.Expected" defaultMessage="Expected" />
                        <br />
                        <strong>
                            {widgetData.attendances.expected
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        </strong>
                    </p>
                </div>
            </div>
        );
    }

    renderTotals() {
        const widgetData = this.formatWidgetData();

        return (
            <div className="sales-cycle__attendance-data">
                <p className="sales-cycle__attendance-data-item">
                    <FormattedMessage id="SalesCycle.TotalRevenue" defaultMessage="Total Revenue" />
                    <br />
                    <strong>
                        {widgetData.totalRevenue && widgetData.totalRevenue.currency ? (
                            <React.Fragment>
                                <span>{widgetData.totalRevenue.currency} </span>
                                <FormattedNumber
                                    value={Math.round(widgetData.totalRevenue.value / 100)}
                                />
                            </React.Fragment>
                        ) : (
                            Math.round(widgetData.totalRevenue.value / 100)
                        )}
                    </strong>
                </p>
            </div>
        );
    }

    renderContent() {
        return (
            <div className="sales-cycle__content">
                {this.renderAttendance()}
                {this.renderTotals()}
            </div>
        );
    }

    render() {
        const { size, fetching } = this.props;

        return (
            <Widget
                title={<FormattedMessage id="SalesCycle.Title" defaultMessage="Sales Cycle" />}
                size={size}
                className="sales-cycle"
            >
                {fetching && <ElementLoader overlay />}
                {this.renderContent()}
            </Widget>
        );
    }
}

SalesCycle.defaultProps = {
    size: null,
    fetching: false,
    data: {},
    eventSeries: null,
    eventID: null,
    soldBy: null
};

SalesCycle.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    dispatch: PropTypes.func.isRequired,
    fetching: PropTypes.bool,
    data: PropTypes.oneOfType([PropTypes.object]),
    eventSeries: PropTypes.string,
    eventID: PropTypes.string,
    soldBy: PropTypes.string
};

//  Getting initial value populated in the form from the store
const mapStateToProps = (state, ownProps) => {
    return {
        data: getData(state, ownProps.id),
        fetching: getFetching(state, ownProps.id)
    };
};

export default connect(mapStateToProps)(SalesCycle);
