import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './Number.scss';

class Number extends Component {
    /**
     *
     * @param props
     */
    constructor(props) {
        super(props);
        this.state = {
            value: props.input.value || 0
        };

        this.onChange = this.onChange.bind(this);
    }

    /**
     *
     * @param ev {event}
     * @param operator {string}
     */
    onBtnClick(ev, operator) {
        ev.preventDefault();
        const { value } = this.state;
        const { input, fieldProps } = this.props;

        if (operator === 'plus') {
            const newValue = parseInt(value, 10) + 1;
            if (newValue >= fieldProps.max) {
                this.setState({ value: fieldProps.max });
                if ('onChange' in input) {
                    input.onChange(fieldProps.max);
                }
            } else {
                this.setState({ value: newValue });
                if ('onChange' in input) {
                    input.onChange(newValue);
                }
            }
        } else if (operator === 'minus') {
            const newValue = parseInt(value, 10) - 1;
            if (newValue <= fieldProps.min) {
                this.setState({ value: fieldProps.min });
                if ('onChange' in input) {
                    input.onChange(fieldProps.min);
                }
            } else {
                this.setState({ value: newValue });
                if ('onChange' in input) {
                    input.onChange(newValue.currentTarget);
                }
            }
        }
    }

    /**
     *
     * @param ev
     */
    onChange(ev) {
        const { input, fieldProps } = this.props;
        const newValue = ev.target.value;

        if (newValue >= fieldProps.max) {
            this.setState({ value: fieldProps.max });
            if ('onChange' in input) {
                input.onChange(fieldProps.max);
            }
        } else if (newValue <= fieldProps.min) {
            this.setState({ value: fieldProps.min });
            if ('onChange' in input) {
                input.onChange(fieldProps.max);
            }
        } else {
            this.setState({ value: newValue });
            if ('onChange' in input) {
                input.onChange(newValue);
            }
        }
        ev.preventDefault();
    }

    /**
     *
     * @returns {*}
     */
    render() {
        const { value } = this.state;
        const { fieldAttr, meta, input } = this.props;

        return (
            <div className="input-number">
                <div className="input-number__col is-btn">
                    <span
                        role="button"
                        aria-label={`Decrease ${input.name} Field`}
                        tabIndex={0}
                        onClick={ev => this.onBtnClick(ev, 'minus')}
                        onKeyPress={ev => this.onBtnClick(ev, 'minus')}
                        className="input-number__btn"
                    >
                        -
                    </span>
                </div>
                <div className="input-number__col">
                    <input
                        type="number"
                        className="input-number__field"
                        aria-label={`${input.name}`}
                        id={fieldAttr.id ? fieldAttr.id : ''}
                        name={fieldAttr.name ? fieldAttr.name : ''}
                        onChange={this.onChange}
                        value={value}
                    />
                </div>
                <div className="input-number__col is-btn">
                    <span
                        role="button"
                        aria-label={`Increase ${input.name} Field`}
                        tabIndex={0}
                        onClick={ev => this.onBtnClick(ev, 'plus')}
                        onKeyPress={ev => this.onBtnClick(ev, 'plus')}
                        className="input-number__btn"
                    >
                        +
                    </span>
                </div>
                {meta.touched && meta.error && (
                    <span className="input-number__error">
                        {meta.error.message ? meta.error.message : meta.error}
                    </span>
                )}
            </div>
        );
    }
}

Number.defaultProps = {
    fieldProps: {
        min: 0,
        max: 999
    },
    fieldAttr: {},
    input: {},
    meta: {}
};

Number.propTypes = {
    fieldProps: PropTypes.shape({
        min: PropTypes.number,
        max: PropTypes.number
    }),
    input: PropTypes.oneOfType([PropTypes.object]),
    meta: PropTypes.oneOfType([PropTypes.object]),
    fieldAttr: PropTypes.oneOfType([PropTypes.object])
};

export default Number;
