import React, { Component } from 'react';
import { connect } from 'react-redux';
import arrayUnion from 'erputils/arrayUnion';
import dto, { formatSelectDataForIncluded, getIdFromIri } from 'erputils/dto';
import PropTypes from 'prop-types';
import AsyncCreatableSelect from 'react-select/lib/AsyncCreatable';
import restClient from 'erpcore/api/restClient';
import { getOptionByValue } from 'erputils/utils';
import ElementLoader from 'erpcomponents/ElementLoader';
import {
    DropdownIndicator,
    ClearIndicator,
    selectPropsMapper
} from 'erpcore/components/Form/components/Select';
import { getFormValues, change } from 'redux-form';
import Button from 'erpcomponents/Button';
import styles from './Creatable.styles';
import Input from '../Input';
import '../Select/Select.scss';

class SelectDropdown extends Component {
    /**
     *
     * @param props
     */
    constructor(props) {
        super(props);
        this.state = {
            labelActive: false,
            loading: false,
            options: []
        };

        this.creatableRef = React.createRef();
        this.updateInput = this.updateInput.bind(this);
        this.pushOptions = this.pushOptions.bind(this);
        this.syncIncluded = this.syncIncluded.bind(this);
    }

    componentDidMount() {
        const { input, fieldProps } = this.props;
        if (input.value && !input.value.includes(fieldProps.options.endpoint)) {
            if (this.creatableRef.current) {
                this.setLoading(true);
                this.creatableRef.current.props.loadOptions(input.value).then(existingCities => {
                    if (existingCities.length === 0) {
                        this.creatableRef.current.props.onChange(
                            { value: input.value },
                            { action: 'create-option' }
                        );
                    } else {
                        this.creatableRef.current.props.onChange(existingCities[0]);
                    }
                    this.setLoading(false);
                });
            }
        }
    }

    componentDidUpdate(prevProps) {
        const { options } = this.state;
        const { input, fieldProps } = this.props;
        const { input: prevInput } = prevProps;
        if (
            input.value.includes(fieldProps.options.endpoint) &&
            input.value !== prevInput.value &&
            input.value
        ) {
            this.setLoading(true);
            restClient.get(input.value).then(response => {
                const option = {
                    value: response.data.data.id,
                    label: response.data.data.attributes.name
                };
                options.push(option);
                this.pushOptions(options);
                this.creatableRef.current.props.onChange(option);
                this.setLoading(false);
            });
        } else if (input.value !== prevInput.value && input.value) {
            if (this.creatableRef.current) {
                this.setLoading(true);
                this.creatableRef.current.props.loadOptions(input.value).then(existingCities => {
                    if (existingCities.length === 0) {
                        this.creatableRef.current.props.onChange(
                            { value: input.value },
                            { action: 'create-option' }
                        );
                    } else {
                        this.creatableRef.current.props.onChange(existingCities[0]);
                    }
                    this.setLoading(false);
                });
            }
        }
    }

    setLoading(loading) {
        this.setState({ loading });
    }

    /**
     *
     * @param data
     * @returns {*}
     */
    formatApiValue = data => {
        const { fieldProps } = this.props;
        const formattedOptions = [];
        data.map(item =>
            formattedOptions.push({
                value: item[fieldProps.options.mapData.value],
                label: item[fieldProps.options.mapData.label]
            })
        );
        return formattedOptions;
    };

    /**
     *
     * @param inputValue
     */
    loadOptions = inputValue => {
        const { fieldProps, input } = this.props;
        if (fieldProps.options.endpoint) {
            return new Promise(resolve => {
                let { params } = fieldProps.options;
                if (inputValue) {
                    params = { q: inputValue, ...fieldProps.options.params };
                }
                if (input.value) {
                    params = { selected: input.value, ...fieldProps.options.params };
                }
                // Do a API call
                restClient
                    .get(fieldProps.options.endpoint, {
                        params
                    })
                    .then(response => {
                        response.data = dto(response.data);
                        const formatedOptions = this.formatApiValue(response.data.data);
                        this.pushOptions(formatedOptions);
                        return resolve(formatedOptions);
                    })
                    .catch(() => resolve());
            });
        }

        return null;
    };

    pushOptions(formatedOptions) {
        const { options } = this.state;
        const archivedOptions = arrayUnion(options, formatedOptions, (a, b) => a.value === b.value);
        this.setState({ options: archivedOptions });
    }

    syncIncluded(option) {
        const { formValues, dispatch, meta } = this.props;
        dispatch(
            change(
                meta.form,
                'included',
                formatSelectDataForIncluded([option], formValues.included)
            )
        );
    }

    updateInput(option) {
        const { input } = this.props;
        input.onChange(option);
    }

    /**
     *
     * @param fieldValue
     * @param actionButtonData {object}
     * @return {null|*}
     */
    renderActionButton(fieldValue, actionButtonData = null) {
        if (!actionButtonData) return null;

        const { edit, create } = actionButtonData;
        const { value, label } = fieldValue;
        let buttonLabel = `Edit ${label}` || 'Edit';
        let buttonIconName = 'edit';
        let buttonUrl = null;
        let buttonDisabled = !value;

        // Edit
        if (edit) {
            // If component provided
            if (edit.component) {
                return edit.component;
            }
            // If url object provided
            if (edit.url) {
                const { prefix, suffix } = edit.url;
                let id = null;

                if (value) {
                    id = getIdFromIri(value);
                }

                buttonUrl = `${prefix}${id}${suffix}`;
            }

            // If entity archived, missing label
            if (!label) {
                buttonDisabled = true;
            }
        }

        // No value in the field
        if (!value) {
            // By defining buttonDisabled var we set it to disable
            // If create object provided
            if (create) {
                // If component provided
                if (create.component) {
                    return create.component;
                }
                // If url is provided
                if (create.url) {
                    buttonIconName = null;
                    buttonUrl = create.url;
                    buttonDisabled = false;
                    buttonIconName = 'plus';
                    buttonLabel = 'Create';
                }
            }
        }

        return (
            <Button
                disabled={buttonDisabled}
                variation="action"
                iconName={buttonIconName}
                href={buttonUrl}
                label={buttonLabel}
                labelOnlyAria
            />
        );
    }

    render() {
        const { input, fieldProps, fieldAttr, meta, field, displayActionButton } = this.props;
        const { labelActive, options, loading } = this.state;
        fieldProps.forceLabelActive = labelActive;

        //  Getting select required value from options
        const fieldValue = getOptionByValue(input.value, options);

        //  Standardizing props for select
        const mappedConf = selectPropsMapper(fieldProps, fieldAttr);

        return (
            <Input
                fieldProps={fieldProps}
                fieldAttr={fieldAttr}
                field={field}
                input={input}
                meta={meta}
            >
                <AsyncCreatableSelect
                    ref={this.creatableRef}
                    styles={styles}
                    components={{ DropdownIndicator, ClearIndicator }}
                    onChange={(option, actionMeta) => {
                        if (actionMeta && actionMeta.action === 'create-option') {
                            restClient
                                .post(fieldProps.options.endpoint, { name: option.value })
                                .then(response => {
                                    const selectedOption = {
                                        value: response.data.data.id,
                                        label: response.data.data.attributes.name
                                    };
                                    this.syncIncluded(selectedOption);
                                    options.push(selectedOption);
                                    this.pushOptions(options);
                                    input.onChange(response.data.data.id);
                                });
                        } else if (option) {
                            this.syncIncluded(option);
                            options.push(option);
                            this.pushOptions(options);
                            input.onChange(option.value);
                        } else {
                            input.onChange('');
                        }
                    }}
                    onInputChange={inputValue =>
                        inputValue !== ''
                            ? this.setState({ labelActive: true })
                            : this.setState({ labelActive: false })
                    }
                    onFocus={() => this.setState({ labelActive: true })}
                    placeholder=""
                    isLoading={loading}
                    className={`react-select${displayActionButton ? ' react-select--action' : ''} ${
                        loading ? ' react-select--loading ' : ''
                    }`}
                    classNamePrefix="react-select"
                    isClearable
                    value={fieldValue}
                    name={input.name}
                    id={input.name}
                    {...mappedConf.fieldAttr}
                    {...mappedConf.fieldProps}
                    defaultOptions
                    loadOptions={this.loadOptions}
                />
                {loading && (
                    <ElementLoader
                        className={`react-select__loader${
                            fieldAttr.disabled ? ' react-select__loader--disabled' : ''
                        }`}
                    />
                )}
                {fieldProps.actionButton &&
                    this.renderActionButton(fieldValue, fieldProps.actionButton)}
            </Input>
        );
    }
}

SelectDropdown.defaultProps = {
    fieldProps: {},
    fieldAttr: {},
    field: {},
    input: {},
    meta: {},
    formValues: {},
    displayActionButton: false
};

SelectDropdown.propTypes = {
    fieldProps: PropTypes.oneOfType([PropTypes.object]),
    fieldAttr: PropTypes.oneOfType([PropTypes.object]),
    field: PropTypes.oneOfType([PropTypes.object]),
    input: PropTypes.oneOfType([PropTypes.object]),
    meta: PropTypes.oneOfType([PropTypes.object]),
    formValues: PropTypes.oneOfType([PropTypes.object]),
    displayActionButton: PropTypes.bool,
    dispatch: PropTypes.func.isRequired
};

const mapStateToProps = (state, props) => ({
    formValues: getFormValues(props.meta.form)(state)
});

export default connect(mapStateToProps)(SelectDropdown);
