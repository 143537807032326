import { takeLatest, put, call, select } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import { actions as notificationManagerActions } from 'erputils/NotificationManager/NotificationManager.reducer';
import { parseParamsForApi } from 'erputils/utils';
import dto from 'erputils/dto';
import { actions as listingActions } from 'erpcomponents/Listing/Listing.reducer';
import { getListingUrlQueryParams } from 'erputils/RouterManager/RouterManager.selectors';
import { actions as barTypesActions } from './BarTypes.reducer';

/**
 * Create Bar Type
 * @param  {Object} promise
 * @return {Object} formData
 */
export function* createBarType({ promise, formData }) {
    try {
        // Create Bar Type
        const createBarTypes = yield restClient.post(`api/bar-types`, formData);
        yield put({
            type: barTypesActions.CREATE_BAR_TYPE_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createBarTypes.data
        });
        yield put({
            type: listingActions.START_FETCHING_LISTING,
            entity: 'BAR_TYPES',
            endpoint: 'api/bar-types',
            params: { pagination: false }
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: barTypesActions.CREATE_BAR_TYPE_FAILED,
            response: error.response.data
        });

        yield call(promise.reject, error.response.data);
    }
}

/**
 * Fetch Bar Types
 * @param  {Object} promise
 * @return {string} id Event id
 */
export function* fetchBarType({ promise, id }) {
    try {
        const fetchBarTypesAPI = yield restClient.get(`api/bar-types/${id}`);
        yield put({
            type: barTypesActions.FETCH_SINGLE_BAR_TYPE_SUCCESSFUL
        });
        yield put({
            type: barTypesActions.STORE_SINGLE_BAR_TYPE_DATA,
            id,
            response: dto(fetchBarTypesAPI.data)
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: barTypesActions.FETCH_SINGLE_BAR_TYPE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}
/**
 * Update event series single data
 * @param  {Object} id ID of an event
 * @return {Object} Response from API
 */
export function* updateSingleBarType({ promise, formData, id }) {
    try {
        const updateSingleBarTypeAPI = yield restClient.put(`api/bar-types/${id}`, formData);
        yield put({
            type: barTypesActions.UPDATE_SINGLE_BAR_TYPE_SUCCESSFUL
        });
        yield put({
            type: barTypesActions.STORE_SINGLE_BAR_TYPE_DATA,
            id,
            response: dto(updateSingleBarTypeAPI.data)
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateSingleBarTypeAPI.data
        });
        yield put({
            type: listingActions.START_FETCHING_LISTING,
            entity: 'BAR_TYPES',
            endpoint: 'api/bar-types',
            params: { pagination: false }
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: barTypesActions.UPDATE_SINGLE_BAR_TYPE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Delete Single Bar Type
 * @param  {Object} id ID of an Event
 * @return {Object} Response from API
 */
export function* deleteSingleBarType({ promise, id }) {
    try {
        // Merge default with current params for listing refresh purpose
        const params = Object.assign(
            {},
            { include: 'location.city' },
            yield select(getListingUrlQueryParams)
        );

        const deleteSingleBarTypeAPI = yield restClient.delete(`api/bar-types/${id}`);
        yield put({
            type: barTypesActions.DELETE_SINGLE_BAR_TYPE_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteSingleBarTypeAPI.data
        });
        yield put({
            type: listingActions.START_FETCHING_LISTING,
            entity: 'BAR_TYPES',
            endpoint: 'api/bar-types',
            params: parseParamsForApi(params)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: barTypesActions.DELETE_SINGLE_BAR_TYPE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

export const barTypesSaga = [
    takeLatest(barTypesActions.START_CREATE_BAR_TYPE, createBarType),
    takeLatest(barTypesActions.START_DELETE_SINGLE_BAR_TYPE, deleteSingleBarType),
    takeLatest(barTypesActions.START_FETCHING_SINGLE_BAR_TYPE, fetchBarType),
    takeLatest(barTypesActions.START_UPDATE_SINGLE_BAR_TYPE, updateSingleBarType)
];
