export const actions = {
    START_FETCHING_SALESOVERVIEW_DATA: 'START_FETCHING_SALESOVERVIEW_DATA',
    FETCHING_SALESOVERVIEW_DATA_SUCCESSFUL: 'FETCHING_SALESOVERVIEW_DATA_SUCCESSFUL',
    FETCHING_SALESOVERVIEW_DATA_FAILED: 'FETCHING_SALESOVERVIEW_DATA_FAILED',
    STORE_SALESOVERVIEW_DATA: 'STORE_SALESOVERVIEW_DATA'
};

export const initialState = {};

export default (state = initialState, { type, widgetId, source, response }) => {
    switch (type) {
        case actions.START_FETCHING_SALESOVERVIEW_DATA: {
            return {
                ...state,
                [widgetId]: { ...state[widgetId], ...{ fetching: true } }
            };
        }
        case actions.FETCHING_SALESOVERVIEW_DATA_SUCCESSFUL: {
            return {
                ...state,
                [widgetId]: { ...state[widgetId] }
            };
        }
        case actions.FETCHING_SALESOVERVIEW_DATA_FAILED: {
            return {
                ...state,
                [widgetId]: { ...state[widgetId], ...{ fetching: false } }
            };
        }
        case actions.STORE_SALESOVERVIEW_DATA: {
            return {
                ...state,
                [widgetId]: {
                    ...state[widgetId],
                    ...{
                        [source]: { ...state[widgetId][source], ...{ data: response } }
                    },
                    ...{ fetching: false }
                }
            };
        }
        default:
            return state;
    }
};
