// TODO: location of this file is not yet defined

import { takeLatest, delay, put, call, fork, take } from 'redux-saga/effects';
import { channel } from 'redux-saga';
import restClient from 'erpcore/api/restClient';
import { actions as notificationManagerActions } from 'erputils/NotificationManager/NotificationManager.reducer';
import dto from 'erputils/dto';
import { actions as resellerFilterActions } from 'erpcore/screens/Settings/screens/ResellerAdmin/ResellerFilter/ResellerFilter.reducer';

/**
 * Create resellerFilter
 * @param  promise {Object}
 * @param  formData {Object}
 * @return {void}
 */
export function* createResellerFilter({ promise, formData }) {
    try {
        // Create headerMenu
        const createResellerFilterAPI = yield restClient.put(
            `api/general-settings/reseller-filters-sort`,
            formData
        );
        yield put({
            type: resellerFilterActions.CREATE_RESELLER_FILTER_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: {
                ...createResellerFilterAPI.data,
                code: 'resellerfilter.itemSuccessfulyCreated'
            }
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: resellerFilterActions.CREATE_RESELLER_FILTER_FAILED,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Fetch resellerFilter
 * @param promise {Object}
 * @param id {number} ticketType id
 * @param params {Object}
 * @return {void}
 */
export function* fetchResellerFilter({ promise, id, params }) {
    try {
        const fetchTicketTypeAPI = yield restClient.get(`api/ticket-types/${id}`, { params });
        yield put({
            type: resellerFilterActions.FETCH_SINGLE_RESELLER_FILTER_SUCCESSFUL
        });
        yield put({
            type: resellerFilterActions.STORE_SINGLE_RESELLER_FILTER_DATA,
            id,
            response: dto(fetchTicketTypeAPI.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: resellerFilterActions.FETCH_SINGLE_RESELLER_FILTER_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Update ticketType single data
 * @param  promise {Object}
 * @param  formData {Object}
 * @param  id {number} ticketType id
 * @param  debounceDelay {number} Number of milliseconds to delay execution
 * @return {void}
 */
export function* updateSingleResellerFilter({ promise, formData, id, debounceDelay = 0 }) {
    yield delay(debounceDelay);
    yield put({
        type: resellerFilterActions.START_UPDATE_SINGLE_RESELLER_FILTER
    });
    try {
        const updateSingleResellerFilterAPI = yield restClient.put(
            `api/general-settings/reseller-filters-sort`,
            formData
        );
        yield put({
            type: resellerFilterActions.UPDATE_SINGLE_RESELLER_FILTER_SUCCESSFUL
        });
        yield put({
            type: resellerFilterActions.STORE_SINGLE_RESELLER_FILTER_DATA,
            id,
            response: dto(updateSingleResellerFilterAPI.data)
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: {
                ...updateSingleResellerFilterAPI.data,
                code: 'resellerfilter.itemSuccessfulyUpdated'
            }
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: resellerFilterActions.UPDATE_SINGLE_RESELLER_FILTER_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Delete Single ticketType
 * @param  id {number} ticketType id
 * @param  promise {Object}
 * @return {void}
 */
export function* deleteSingleResellerFilter({ promise, formData }) {
    try {
        const deleteSingleResellerFilterAPI = yield restClient.put(
            `api/general-settings/reseller-filters-sort`,
            formData
        );
        yield put({
            type: resellerFilterActions.DELETE_SINGLE_RESELLER_FILTER_SUCCESSFUL
        });
        yield put({
            type: resellerFilterActions.STORE_SINGLE_RESELLER_FILTER_DATA,
            response: dto(deleteSingleResellerFilterAPI.data)
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: {
                ...deleteSingleResellerFilterAPI.data,
                code: 'resellerfilter.itemSuccessfulyRemoved'
            }
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: resellerFilterActions.DELETE_SINGLE_RESELLER_FILTER_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

function takeLatestPerProps(propsOrSelector, pattern, worker, ...args) {
    // Not a generator
    return fork(function* generator() {
        // Fork a generator here to make it work like takeLatest
        const channelsMap = {};
        while (true) {
            const action = yield take(pattern); // yield necessary here
            const propsValue =
                typeof propsOrSelector === 'function'
                    ? propsOrSelector(action)
                    : action[propsOrSelector];
            if (!channelsMap[propsValue]) {
                channelsMap[propsValue] = channel();
                yield takeLatest(channelsMap[propsValue], worker, ...args);
            }
            yield put(channelsMap[propsValue], action);
        }
    });
}

/**
 * Register action to watcher
 */
export const resellerFilterSaga = [
    takeLatest(resellerFilterActions.START_CREATE_RESELLER_FILTER, createResellerFilter),
    // takeLatest(resellerFilterActions.START_FETCHING_SINGLE_TICKET_TYPE, fetchTicketType),
    takeLatestPerProps(
        'id',
        resellerFilterActions.REQUEST_UPDATE_SINGLE_RESELLER_FILTER,
        updateSingleResellerFilter
    ),
    takeLatest(
        resellerFilterActions.START_DELETE_SINGLE_RESELLER_FILTER,
        deleteSingleResellerFilter
    )
];
