import { takeLatest, put, call, select } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import { actions as notificationManagerActions } from 'erputils/NotificationManager/NotificationManager.reducer';
import { parseParamsForApi } from 'erputils/utils';
import dto from 'erputils/dto';
import { actions as listingActions } from 'erpcomponents/Listing/Listing.reducer';
import { getListingUrlQueryParams } from 'erputils/RouterManager/RouterManager.selectors';
import { actions as foodTypesActions } from './FoodTypes.reducer';

/**
 * Create Food Type
 * @param  {Object} promise
 * @return {Object} formData
 */
export function* createFoodType({ promise, formData }) {
    try {
        // Create Food Type
        const createFoodTypes = yield restClient.post(`api/food-types`, formData);
        yield put({
            type: foodTypesActions.CREATE_FOOD_TYPE_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createFoodTypes.data
        });
        yield put({
            type: listingActions.START_FETCHING_LISTING,
            entity: 'FOOD_TYPES',
            endpoint: 'api/food-types',
            params: { pagination: false }
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: foodTypesActions.CREATE_FOOD_TYPE_FAILED,
            response: error.response.data
        });

        yield call(promise.reject, error.response.data);
    }
}

/**
 * Fetch Food Types
 * @param  {Object} promise
 * @return {string} id Event id
 */
export function* fetchFoodType({ promise, id }) {
    try {
        const fetchFoodTypesAPI = yield restClient.get(`api/food-types/${id}`);
        yield put({
            type: foodTypesActions.FETCH_SINGLE_FOOD_TYPE_SUCCESSFUL
        });
        yield put({
            type: foodTypesActions.STORE_SINGLE_FOOD_TYPE_DATA,
            id,
            response: dto(fetchFoodTypesAPI.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: foodTypesActions.FETCH_SINGLE_FOOD_TYPE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}
/**
 * Update event series single data
 * @param  {Object} id ID of an event
 * @return {Object} Response from API
 */
export function* updateSingleFoodType({ promise, formData, id }) {
    try {
        const updateSingleFoodTypeAPI = yield restClient.put(`api/food-types/${id}`, formData);
        yield put({
            type: foodTypesActions.UPDATE_SINGLE_FOOD_TYPE_SUCCESSFUL
        });
        yield put({
            type: foodTypesActions.STORE_SINGLE_FOOD_TYPE_DATA,
            id,
            response: dto(updateSingleFoodTypeAPI.data)
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateSingleFoodTypeAPI.data
        });
        yield put({
            type: listingActions.START_FETCHING_LISTING,
            entity: 'FOOD_TYPES',
            endpoint: 'api/food-types',
            params: { pagination: false }
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: foodTypesActions.UPDATE_SINGLE_FOOD_TYPE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Delete Single Food Type
 * @param  {Object} id ID of an Event
 * @return {Object} Response from API
 */
export function* deleteSingleFoodType({ promise, id }) {
    try {
        // Merge default with current params for listing refresh purpose
        const params = Object.assign(
            {},
            { include: 'location.city' },
            yield select(getListingUrlQueryParams)
        );

        const deleteSingleFoodTypeAPI = yield restClient.delete(`api/food-types/${id}`);
        yield put({
            type: foodTypesActions.DELETE_SINGLE_FOOD_TYPE_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteSingleFoodTypeAPI.data
        });
        yield put({
            type: listingActions.START_FETCHING_LISTING,
            entity: 'FOOD_TYPES',
            endpoint: 'api/food-types',
            params: parseParamsForApi(params)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: foodTypesActions.DELETE_SINGLE_FOOD_TYPE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

export const foodTypesSaga = [
    takeLatest(foodTypesActions.START_CREATE_FOOD_TYPE, createFoodType),
    takeLatest(foodTypesActions.START_DELETE_SINGLE_FOOD_TYPE, deleteSingleFoodType),
    takeLatest(foodTypesActions.START_FETCHING_SINGLE_FOOD_TYPE, fetchFoodType),
    takeLatest(foodTypesActions.START_UPDATE_SINGLE_FOOD_TYPE, updateSingleFoodType)
];
