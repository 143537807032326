import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import Form, { Password } from 'erpcore/components/Form';
import Button from 'erpcomponents/Button';
import Svg from 'erpcomponents/Svg';
import Tooltip from 'erpcomponents/Tooltip';
import Modal from 'erpcomponents/Modal';
import { actions as usersActions } from 'erpcore/screens/Users/Users.reducer';
import valueValidation from 'erputils/valueValidation';

class ChangePassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            opened: false
        };

        this.handleModal = this.handleModal.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(formData) {
        const { id, dispatch } = this.props;

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: usersActions.START_CHANGE_PASSWORD_SINGLE_USER,
                formData,
                id
            })
        )
            .then(() => {
                this.setState({ opened: false });
            })
            .catch(error => {
                this.setState({ opened: false });
                return error;
            });
    }

    handleModal() {
        const { opened: isOpen } = this.state;
        this.setState({ opened: !isOpen });
    }

    render() {
        const { handleSubmit, submitting, name } = this.props;
        const { opened } = this.state;

        return (
            <Fragment>
                <Tooltip
                    content={
                        <FormattedMessage
                            id="Users.Table.Actions.ChangePassword"
                            defaultMessage="Change Password"
                        />
                    }
                >
                    <button type="button" onClick={() => this.handleModal()}>
                        <Svg icon="lock" />
                    </button>
                </Tooltip>
                <Modal
                    variation="small"
                    opened={opened}
                    onClose={() => this.handleModal()}
                    title={
                        <FormattedMessage
                            id="Users.Table.Actions.ChangePassword.Modal.title"
                            defaultMessage="Change Password"
                        />
                    }
                    subtitle={
                        <Fragment>
                            <FormattedMessage
                                id="Users.Table.Actions.ChangePassword.Modal.subtitle"
                                defaultMessage="Password update for"
                            />{' '}
                            <em>{name}</em>
                        </Fragment>
                    }
                >
                    <Form onSubmit={handleSubmit(this.onSubmit)}>
                        <Form.Row>
                            <Field
                                name="new_password"
                                id="new_password"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="Users.Table.Actions.ChangePassword.Modal.NewPassword"
                                            defaultMessage="New Password"
                                        />
                                    )
                                }}
                                component={Password}
                                validate={valueValidation([{ validator: 'required' }])}
                            />
                        </Form.Row>
                        <Button
                            submit
                            loading={submitting}
                            label={
                                <FormattedMessage
                                    id="Users.Table.Actions.ChangePassword.Modal.Update"
                                    defaultMessage="Update"
                                />
                            }
                        />
                        <Button
                            variation="secondary"
                            label={
                                <FormattedMessage
                                    id="Users.Table.Actions.ChangePassword.Modal.Cancel"
                                    defaultMessage="Cancel"
                                />
                            }
                            onClick={() => this.handleModal()}
                        />
                    </Form>
                </Modal>
            </Fragment>
        );
    }
}

ChangePassword.defaultProps = {
    submitting: false,
    id: null,
    name: null,
    dispatch: () => {}
};

ChangePassword.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    dispatch: PropTypes.func
};

export default reduxForm({
    form: 'ChangePasswordForm',
    enableReinitialize: true
})(ChangePassword);
