import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import Form, { Email } from 'erpcore/components/Form';
import Button from 'erpcomponents/Button';
import PageNotification from 'erpcomponents/Layout/PageNotification';
import { getPageNotification } from 'erputils/NotificationManager/NotificationManager.selectors';
import { actions as forgotPasswordActions } from 'erpcore/screens/ForgotPassword/ForgotPassword.reducer';

class ForgotPasswordForm extends Component {
    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(formData) {
        const { dispatch, nextStep } = this.props;

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: forgotPasswordActions.START_FORGOT_PASSWORD,
                formData
            })
        )
            .then(() => {
                nextStep();
            })
            .catch(error => {
                return error;
            });
    }

    render() {
        const { handleSubmit, submitting, pristine, pageNotification } = this.props;

        return (
            <Fragment>
                <h2 className="front-main__title">
                    <FormattedMessage
                        id="ForgotPassword.title"
                        defaultMessage="Reset Your Password"
                    />
                </h2>

                <p className="front-main__subtitle">
                    <FormattedMessage
                        id="ForgotPassword.subtitle"
                        defaultMessage="Enter your email and we'll send you a password reset email."
                    />
                </p>

                {pageNotification && pageNotification.code && (
                    <PageNotification code={pageNotification.code} />
                )}

                <Form onSubmit={handleSubmit(this.onSubmit)}>
                    <Form.Row>
                        <Field
                            name="email"
                            id="email"
                            fieldProps={{
                                label: (
                                    <FormattedMessage
                                        id="ForgotPassword.Email"
                                        defaultMessage="Email"
                                    />
                                ),
                                clearable: true
                            }}
                            component={Email}
                        />
                    </Form.Row>
                    <Form.Row verticalCenter>
                        <Form.RowColResponsive>
                            <Button
                                submit
                                disabled={pristine}
                                loading={submitting}
                                label={
                                    <FormattedMessage
                                        id="ForgotPassword.Send"
                                        defaultMessage="Send"
                                    />
                                }
                            />
                        </Form.RowColResponsive>
                        <Form.RowColResponsive>
                            <span className="form__help-text">
                                <FormattedMessage
                                    id="ForgotPassword.text"
                                    defaultMessage="Need help or more information?"
                                />
                                <br />
                                <FormattedMessage
                                    id="ForgotPassword.text.contact"
                                    defaultMessage="Contact:"
                                />{' '}
                                <a href="mailto:josh@balldrop.com">josh@balldrop.com</a>
                            </span>
                        </Form.RowColResponsive>
                    </Form.Row>
                </Form>
            </Fragment>
        );
    }
}

ForgotPasswordForm.defaultProps = {
    submitting: false,
    pristine: false,
    pageNotification: null
};

ForgotPasswordForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    nextStep: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    pageNotification: PropTypes.oneOfType([PropTypes.object])
};

ForgotPasswordForm = reduxForm({
    form: 'ForgotPasswordForm',
    enableReinitialize: true
})(ForgotPasswordForm);

const mapStateToProps = state => ({
    pageNotification: getPageNotification(state)
});

export default connect(mapStateToProps)(ForgotPasswordForm);
