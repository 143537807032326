import React, { Component } from 'react';
import './EventTicketsInfo.scss';
import PropTypes from 'prop-types';

class EventTicketsInfo extends Component {
    state = {};

    render() {
        const { event, orders } = this.props;
        const ticketTypes = orders[0] && orders[0].ticket_types ? orders[0].ticket_types : [];
        const tickets = ticketTypes.length && ticketTypes.map(type => type.name);
        return (
            <div className="groups-table-event-info">
                <span className="groups-table-event-info__name">{event.name}</span>
                {tickets &&
                    tickets.map(ticket => (
                        <span className="groups-table-event-info__ticket" key={ticket}>
                            {ticket}
                        </span>
                    ))}
            </div>
        );
    }
}
EventTicketsInfo.defaultProps = {
    event: {},
    orders: []
};

EventTicketsInfo.propTypes = {
    event: PropTypes.oneOfType([PropTypes.object]),
    orders: PropTypes.oneOfType([PropTypes.array])
};

export default EventTicketsInfo;
