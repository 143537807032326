import { getOutTickets24hSaga } from 'erpcomponents/Widgets/GetOutTickets24h/GetOutTickets24h.saga';
import { eventSalesSaga } from 'erpcomponents/Widgets/EventSales/EventSales.saga';
import { eventSalesPerTicketTypeSaga } from 'erpcomponents/Widgets/EventSalesPerTicketType/EventSalesPerTicketType.saga';
import { salesCycleSaga } from 'erpcomponents/Widgets/SalesCycle/SalesCycle.saga';
import { salesOverviewSaga } from 'erpcomponents/Widgets/SalesOverview/SalesOverview.saga';
import { scannedTicketsSaga } from 'erpcomponents/Widgets/ScannedTickets/ScannedTickets.saga';
import { ticketsOverviewSaga } from 'erpcomponents/Widgets/TicketsOverview/TicketsOverview.saga';
import { totalSalesByEventSaga } from 'erpcomponents/Widgets/TotalSalesByEvent/TotalSalesByEvent.saga';
import { totalTickets24hSaga } from 'erpcomponents/Widgets/TotalTickets24h/TotalTickets24h.saga';
import { totalSalesByEventBarSaga } from 'erpcomponents/Widgets/TotalSalesByEventBar/TotalSalesByEventBar.saga';
import { totalSalesByTicketTypeSaga } from 'erpcomponents/Widgets/TotalSalesByTicketType/TotalSalesByTicketType.saga';
import { ticketsPerTimeslotSaga } from 'erpcomponents/Widgets/TicketsPerTimeslot/TicketsPerTimeslot.saga';
import { ticketsPerTicketTypeSaga } from 'erpcomponents/Widgets/TicketsPerTicketType/TicketsPerTicketType.saga';
import { ticketSocketTickets24hSaga } from 'erpcomponents/Widgets/TicketSocketTickets24h/TicketSocketTickets24h.saga';
import { ticketTypesReportSaga } from 'erpcomponents/Widgets/TicketTypesReport/TicketTypesReport.saga';
import { ticketTypesSaga } from 'erpcomponents/Widgets/TicketTypes/TicketTypes.saga';

export default [
    ...getOutTickets24hSaga,
    ...eventSalesSaga,
    ...eventSalesPerTicketTypeSaga,
    ...salesCycleSaga,
    ...salesOverviewSaga,
    ...scannedTicketsSaga,
    ...ticketsOverviewSaga,
    ...totalSalesByEventSaga,
    ...totalTickets24hSaga,
    ...totalSalesByEventBarSaga,
    ...totalSalesByTicketTypeSaga,
    ...ticketsPerTimeslotSaga,
    ...ticketsPerTicketTypeSaga,
    ...ticketSocketTickets24hSaga,
    ...ticketTypesReportSaga,
    ...ticketTypesSaga
];
