import React, { Component } from 'react';
import './EventTicketsTableInfo.scss';
import PropTypes from 'prop-types';

class ResellerTableInfo extends Component {
    state = {};

    render() {
        const { event, tickets } = this.props;
        return (
            <div className="resellers-table-reseller-info">
                <span className="resellers-table-reseller-info__name">{event.name}</span>
                {tickets.map(ticket => (
                    <span
                        className="resellers-table-reseller-info__phone"
                        key={`ticket${ticket.fee && ticket.fee.amount}${ticket.price &&
                            ticket.price.amount}${event && event.name}`}
                    >
                        {ticket.quantity && ticket.quantity}x {ticket.name}
                    </span>
                ))}
            </div>
        );
    }
}
ResellerTableInfo.defaultProps = {
    event: {},
    tickets: []
};

ResellerTableInfo.propTypes = {
    event: PropTypes.oneOfType([PropTypes.object]),
    tickets: PropTypes.oneOfType([PropTypes.array])
};

export default ResellerTableInfo;
