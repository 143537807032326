// TODO: location of this file is not yet defined
export const actions = {
    START_FETCHING_EVENTS_SORT: 'START_FETCHING_EVENTS_SORT',
    FETCHING_SUCCESSFUL_EVENTS_SORT: 'FETCHING_SUCCESSFUL_EVENTS_SORT',
    FETCHING_FAILED_EVENTS_SORT: 'FETCHING_FAILED_EVENTS_SORT',
    REQUEST_UPDATE_EVENTS_SORT_ORDER: 'REQUEST_UPDATE_EVENTS_SORT_ORDER',
    START_UPDATE_EVENTS_SORT_ORDER: 'START_UPDATE_EVENTS_SORT_ORDER',
    UPDATE_EVENTS_SORT_ORDER_SUCCESSFUL: 'UPDATE_EVENTS_SORT_ORDER_SUCCESSFUL',
    UPDATE_EVENTS_SORT_ORDER_FAILED: 'UPDATE_EVENTS_SORT_ORDER_FAILED'
};

export const initialState = {
    listingFetching: false,
    listingData: [],
    listingMeta: {},
    listingError: [],
    singleFetching: false,
    singleCreating: false,
    singleDeleting: false,
    singleUpdating: false,
    eventsSort: []
};

export default (state = initialState, { type, response }) => {
    switch (type) {
        case actions.START_FETCHING_EVENTS_SORT: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_EVENTS_SORT: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response.data
            };
        }
        case actions.FETCHING_FAILED_EVENTS_SORT: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        case actions.START_UPDATE_EVENTS_SORT_ORDER: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.UPDATE_EVENTS_SORT_ORDER_SUCCESSFUL: {
            return {
                ...state,
                listingFetching: false
            };
        }
        case actions.UPDATE_EVENTS_SORT_ORDER_FAILED: {
            return {
                ...state,
                listingFetching: false,
                singleError: response
            };
        }

        default:
            return state;
    }
};
