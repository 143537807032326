import { createSelector } from 'reselect';

export const getSingleResellerFilterFetching = state => state.resellerFilters.singleFetching;

export const getSingleResellerFilterCreating = state => state.resellerFilters.singleCreating;

export const getSingleResellerFilterDeleting = state => state.resellerFilters.singleDeleting;

export const getSingleResellerFilterUpdating = state => state.resellerFilters.singleUpdating;

export default createSelector([
    getSingleResellerFilterFetching,
    getSingleResellerFilterCreating,
    getSingleResellerFilterDeleting,
    getSingleResellerFilterUpdating
]);
