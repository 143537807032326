import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import valueValidation from 'erputils/valueValidation';
import { diff } from 'deep-object-diff';
import { FormattedMessage } from 'react-intl';
import Form, { Text, Location } from 'erpcore/components/Form';
import LayoutManager from 'erputils/LayoutManager';
import PageHeader from 'erpcomponents/Layout/PageHeader';
import PageContent from 'erpcomponents/Layout/PageContent';
import Button from 'erpcomponents/Button';
import PageLoader from 'erpcomponents/PageLoader';
import LocationEditTabs from 'erpcore/screens/Venues/components/LocationEditTabs';
import { actions as venuesActions } from 'erpcore/screens/Venues/Venues.reducer';
import { getVenueFormData, getSingleVenueFetching } from 'erpcore/screens/Venues/Venues.selectors';

class VenuesEdit extends Component {
    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        const { fetchLocationData } = this.props;
        fetchLocationData();
    }

    onSubmit(formData) {
        const { match, dispatch, initialValues } = this.props;
        formData = diff(initialValues, formData);
        // Always send fullAddress and locationPin data for the sake of address field functionality
        // formData = Object.assign({}, formData, {
        //     location: {
        //         fullAddress: initialValues.location.fullAddress,
        //         locationPin: initialValues.location.locationPin
        //     }
        // });

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: venuesActions.START_UPDATE_SINGLE_VENUE,
                id: match.params.id,
                formData
            })
        ).catch(error => {
            return error;
        });
    }

    render() {
        const { handleSubmit, submitting, invalid, singleFetching, initialValues } = this.props;
        const events = initialValues.location && initialValues.location.total_events;
        const venue = initialValues.venue_name && ` - ${initialValues.venue_name}`;

        return (
            <LayoutManager slot="main" className="main--narrow" layoutType="merge">
                <PageHeader
                    title={
                        <FormattedMessage
                            id="VenueEdit.title"
                            defaultMessage="Edit Venue {venue}"
                            values={{ venue }}
                        />
                    }
                    subtitle={
                        <FormattedMessage
                            id="VenueEdit.connectedEvents"
                            defaultMessage="This venue is connected to {events} events."
                            values={{ events }}
                        />
                    }
                    backButtonLink="/venues"
                />
                <LocationEditTabs lessPadding />
                <PageContent>
                    {singleFetching === true && <PageLoader />}
                    <Form onSubmit={handleSubmit(this.onSubmit)}>
                        <Form.Row>
                            <Field
                                name="venue_name"
                                id="venue_name"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="VenueEdit.venueName"
                                            defaultMessage="Venue Name"
                                        />
                                    ),
                                    clearable: true
                                }}
                                component={Text}
                                validate={valueValidation([{ validator: 'required' }])}
                            />
                        </Form.Row>
                        <Form.Row>
                            <Field
                                name="location"
                                id="location"
                                component={Location}
                                fieldProps={{
                                    showManual: true
                                }}
                            />
                        </Form.Row>
                        <Form.Row>
                            <Button
                                submit
                                disabled={invalid}
                                loading={submitting}
                                label={
                                    <FormattedMessage
                                        id="VenueEdit.Update"
                                        defaultMessage="Update"
                                    />
                                }
                            />
                        </Form.Row>
                    </Form>
                </PageContent>
            </LayoutManager>
        );
    }
}

VenuesEdit.defaultProps = {
    submitting: false,
    invalid: false,
    fetchLocationData: () => {},
    singleFetching: false,
    initialValues: {}
};

VenuesEdit.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.oneOfType([PropTypes.object]).isRequired,
    initialValues: PropTypes.oneOfType([PropTypes.object]),
    invalid: PropTypes.bool,
    fetchLocationData: PropTypes.func,
    singleFetching: PropTypes.bool
};

VenuesEdit = reduxForm({
    form: 'VenueEditForm',
    enableReinitialize: true
})(VenuesEdit);

//  Getting initial value populated in the form from the store
const mapStateToProps = (state, ownProps) => {
    return {
        initialValues: getVenueFormData(state, ownProps.match.params.id),
        singleFetching: getSingleVenueFetching(state)
    };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchLocationData: () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: venuesActions.START_FETCHING_SINGLE_VENUE,
                id: ownProps.match.params.id
            });
        }).catch(error => ({ error }));
    }
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(VenuesEdit)
);
