import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import './SwitchCard.scss';

class SwitchCard extends Component {
    /**
     *
     * @param props
     */
    constructor(props) {
        super(props);
        this.state = {
            active: false
        };
    }

    static getDerivedStateFromProps(nextProps) {
        const { fieldProps, input } = nextProps;
        if (input.value && fieldProps.onValue && input.value === fieldProps.onValue) {
            return {
                active: true
            };
        }
        return null;
    }

    /**
     *
     * @param ev
     */
    toggleSwitch(ev) {
        ev.preventDefault();
        const { active } = this.state;
        const { input, fieldProps } = this.props;

        this.setState({ active: !active });

        const onValue = fieldProps.onValue !== undefined ? fieldProps.onValue : true;
        const offValue = fieldProps.offValue !== undefined ? fieldProps.offValue : false;

        input.onChange(active ? offValue : onValue);
    }

    /**
     *
     * @returns {*}
     */
    render() {
        const { fieldProps, fieldAttr, input, meta, variation } = this.props;
        const { active } = this.state;

        return (
            <Fragment>
                <label
                    className={`switch-card${
                        variation === 'small-padding' ? ' switch-card--small-padding' : ''
                    }`}
                    onClick={ev => this.toggleSwitch(ev)}
                    onKeyPress={ev => this.toggleSwitch(ev)}
                >
                    <h3 className="switch-card__title">{fieldProps.label}</h3>
                    <div className="switch-card__action">
                        <div className="switch-card__input">
                            <span
                                role="button"
                                aria-label={`${input.name} switch`}
                                tabIndex={0}
                                onClick={ev => this.toggleSwitch(ev)}
                                onKeyPress={ev => this.toggleSwitch(ev)}
                                className={`switch-card__input-toggle ${
                                    active
                                        ? 'switch-card__input-toggle--active switch-card__input-toggle--green'
                                        : ''
                                }`}
                            />
                            <input
                                type="checkbox"
                                className="switch-card__input-checkbox"
                                aria-label={`${input.name} field`}
                                defaultChecked={active}
                                name={input.name}
                                {...fieldAttr}
                            />
                        </div>
                    </div>
                    {fieldProps.description && (
                        <div className="switch-card__description">{fieldProps.description}</div>
                    )}
                </label>

                {meta.touched && meta.error && (
                    <span className="switch-card__input-error">
                        {meta.error.message ? meta.error.message : meta.error}
                    </span>
                )}
            </Fragment>
        );
    }
}

SwitchCard.defaultProps = {
    input: {},
    meta: {},
    fieldProps: {
        label: ' ',
        description: null,
        onValue: true,
        offValue: false
    },
    fieldAttr: {},
    variation: 'default'
};
SwitchCard.propTypes = {
    input: PropTypes.oneOfType([PropTypes.object]),
    meta: PropTypes.oneOfType([PropTypes.object]),
    fieldProps: PropTypes.shape({
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
        description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
        onValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
        offValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number])
    }),
    fieldAttr: PropTypes.oneOfType([PropTypes.object]),
    variation: PropTypes.oneOf(['default', 'small-padding'])
};
export default SwitchCard;
