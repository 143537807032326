import React from 'react';
import LayoutManager from 'erputils/LayoutManager';
import PageHeader from 'erpcomponents/Layout/PageHeader';
import PageContent from 'erpcomponents/Layout/PageContent';
import Chart from 'erpcomponents/Chart';
import Form, { Text } from 'erpcore/components/Form';
import Button from 'erpcomponents/Button';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { FormattedMessage } from 'react-intl';

class ChartTestPage extends React.Component {
    constructor() {
        super();

        this.state = {
            ticketsSoldNumber: 25,
            ticketsSoldLabel: 'Sold Tickets',
            maxTicketsNumber: 100,
            maxTicketsLabel: 'Left Tickets',
            showLegend: false
        };
    }

    handleTicketsSoldNumber = a => {
        this.setState({ ticketsSoldNumber: parseFloat(a, 10) });
    };

    handleMaxTicketsNumber = a => {
        this.setState({ maxTicketsNumber: parseFloat(a, 10) });
    };

    handleTicketsSoldLabel = a => {
        this.setState({ ticketsSoldLabel: a });
    };

    handleMaxTicketsLabel = a => {
        this.setState({ maxTicketsLabel: a });
    };

    handleLegendToggle = () => {
        this.setState(prevState => ({
            showLegend: !prevState.showLegend
        }));
    };

    render() {
        const {
            ticketsSoldNumber,
            maxTicketsNumber,
            ticketsSoldLabel,
            maxTicketsLabel,
            showLegend
        } = this.state;

        return (
            <LayoutManager slot="main" className="main--narrow" layoutType="merge">
                <PageHeader title="Chart Test Page" />
                <PageContent>
                    <Form>
                        <Form.Row>
                            <Field
                                name="ChartTest.SoldTickets"
                                id="ChartTest.SoldTickets"
                                onChange={e => this.handleTicketsSoldNumber(e.target.value)}
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="SoldTicketsNumber"
                                            defaultMessage="Sold tickets"
                                        />
                                    )
                                }}
                                component={Text}
                            />
                            <Field
                                name="ChartTest.SoldTicketsLabel"
                                id="ChartTest.SoldTicketsLabel"
                                onChange={e => this.handleTicketsSoldLabel(e.target.value)}
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="SoldTicketsLabel"
                                            defaultMessage="Sold tickets label"
                                        />
                                    )
                                }}
                                component={Text}
                            />
                        </Form.Row>
                        <Form.Row>
                            <Field
                                name="ChartTest.TotalTickets"
                                id="ChartTest.TotalTickets"
                                onChange={e => this.handleMaxTicketsNumber(e.target.value)}
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="TotalTickets"
                                            defaultMessage="Total tickets number"
                                        />
                                    )
                                }}
                                component={Text}
                            />
                            <Field
                                name="ChartTest.TotalTicketsLabel"
                                id="ChartTest.TotalTicketsLabel"
                                onChange={e => this.handleMaxTicketsLabel(e.target.value)}
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="ChartTest.TotalTicketsLabel"
                                            defaultMessage="Total tickets label"
                                        />
                                    )
                                }}
                                component={Text}
                            />
                        </Form.Row>
                        <Form.Row>
                            <Button
                                onClick={() => this.handleLegendToggle()}
                                label={
                                    <FormattedMessage
                                        id="ChartTest.ToggleLegend"
                                        defaultMessage="Toggle legend"
                                    />
                                }
                            />
                        </Form.Row>
                        <Form.Row>
                            <Chart
                                type="doughnut"
                                data={[ticketsSoldNumber, maxTicketsNumber]}
                                labels={[ticketsSoldLabel, maxTicketsLabel]}
                                showLegend={showLegend}
                                color="primary"
                                showPercentage
                                tooltipTitle="Tickets in %"
                            />
                            <Chart
                                type="doughnut"
                                data={[ticketsSoldNumber, maxTicketsNumber]}
                                labels={[ticketsSoldLabel, maxTicketsLabel]}
                                showLegend={showLegend}
                                color="secondary"
                                tooltipMode="dark"
                            />
                            <Chart
                                type="pie"
                                data={[ticketsSoldNumber, maxTicketsNumber]}
                                labels={[ticketsSoldLabel, maxTicketsLabel]}
                                showLegend={showLegend}
                                color="tertiary"
                                showPercentage
                                tooltipMode="dark"
                            />
                            <Chart
                                type="pie"
                                data={[ticketsSoldNumber, maxTicketsNumber]}
                                labels={[ticketsSoldLabel, maxTicketsLabel]}
                                showLegend={showLegend}
                                tooltipTitle="Tickets"
                            />
                        </Form.Row>
                    </Form>
                </PageContent>
            </LayoutManager>
        );
    }
}

ChartTestPage = reduxForm({
    form: 'ChartTestPageForm',
    enableReinitialize: true
})(ChartTestPage);

export default connect(
    null,
    null
)(ChartTestPage);
