export const actions = {
    START_FETCHING_FOOD_TYPES: 'START_FETCHING_FOOD_TYPES',
    FETCHING_SUCCESSFUL_FOOD_TYPES: 'FETCHING_SUCCESSFUL_FOOD_TYPES',
    FETCHING_FAILED_FOOD_TYPES: 'FETCHING_FAILED_FOOD_TYPES',
    START_CREATE_FOOD_TYPE: 'START_CREATE_FOOD_TYPE',
    CREATE_FOOD_TYPE_SUCCESSFUL: 'CREATE_FOOD_TYPE_SUCCESSFUL',
    CREATE_FOOD_TYPE_FAILED: 'CREATE_FOOD_TYPE_FAILED',
    START_DELETE_SINGLE_FOOD_TYPE: 'START_DELETE_SINGLE_FOOD_TYPE',
    DELETE_SINGLE_FOOD_TYPE_SUCCESSFUL: 'DELETE_SINGLE_FOOD_TYPE_SUCCESSFUL',
    DELETE_SINGLE_FOOD_TYPE_FAILED: 'DELETE_SINGLE_FOOD_TYPE_FAILED',
    STORE_SINGLE_FOOD_TYPE_DATA: 'STORE_SINGLE_FOOD_TYPE_DATA',
    START_UPDATE_SINGLE_FOOD_TYPE: 'START_UPDATE_SINGLE_FOOD_TYPE',
    START_FETCHING_SINGLE_FOOD_TYPE: 'START_FETCHING_SINGLE_FOOD_TYPE',
    FETCH_SINGLE_FOOD_TYPE_SUCCESSFUL: 'FETCH_SINGLE_FOOD_TYPE_SUCCESSFUL',
    FETCH_SINGLE_FOOD_TYPE_FAILED: 'FETCH_SINGLE_FOOD_TYPE_FAILED',
    UPDATE_SINGLE_FOOD_TYPE_SUCCESSFUL: 'UPDATE_SINGLE_FOOD_TYPE_SUCCESSFUL',
    UPDATE_SINGLE_FOOD_TYPE_FAILED: 'UPDATE_SINGLE_FOOD_TYPE_FAILED'
};

export const initialState = {
    listingFetching: false,
    listingData: [],
    listingMeta: {},
    listingError: [],
    singleFetching: false,
    foodTypes: []
};

export default (state = initialState, { type, id, response }) => {
    switch (type) {
        case actions.START_FETCHING_FOOD_TYPES: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_FOOD_TYPES: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_FOOD_TYPES: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        case actions.START_CREATE_FOOD_TYPES: {
            return state;
        }
        case actions.START_FETCHING_SINGLE_FOOD_TYPE: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.FETCH_SINGLE_FOOD_TYPE_SUCCESSFUL: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.FETCH_SINGLE_FOOD_TYPE_FAILED: {
            return {
                ...state,
                singleFetching: false,
                singleError: response
            };
        }
        case actions.UPDATE_SINGLE_FOOD_TYPE_FAILED: {
            return {
                ...state,
                singleError: response
            };
        }
        case actions.STORE_SINGLE_FOOD_TYPE_DATA: {
            return Object.assign({}, state, {
                [id]: response.data
            });
        }
        default:
            return state;
    }
};
