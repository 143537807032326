import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Form, { Select, DateTime, MultiSelect } from 'erpcomponents/Form';
import { Field, reduxForm } from 'redux-form';
import valueValidation from 'erputils/valueValidation';

const YearOverYearFilterForm = ({ options, disabledField }) => {
    return (
        <Form className="year-over-year__filter-form">
            <Form.Columns>
                <Form.Column>
                    <Field
                        name="start_date"
                        id="start_date"
                        fieldProps={{
                            label: 'Start Date'
                        }}
                        fieldAttr={{ disabled: disabledField?.startDate }}
                        component={DateTime}
                    />
                    <Field
                        name="end_date"
                        id="end_date"
                        fieldProps={{
                            label: 'End Date'
                        }}
                        fieldAttr={{ disabled: disabledField?.endDate }}
                        component={DateTime}
                    />
                    <Field
                        name="date_range"
                        id="date_range"
                        fieldProps={{
                            label: (
                                <FormattedMessage
                                    id="Dashboards.YearOverYearFilterForm.Filter.DateRange"
                                    defaultMessage="Date Range"
                                />
                            ),
                            clearable: false,
                            options: [
                                {
                                    label: (
                                        <FormattedMessage
                                            id="Dashboards.YearOverYearFilterForm.Filter.DateRange.Last24h"
                                            defaultMessage="Last 24h"
                                        />
                                    ),
                                    value: 1
                                },
                                {
                                    label: (
                                        <FormattedMessage
                                            id="Dashboards.YearOverYearFilterForm.Filter.DateRange.Last7Days"
                                            defaultMessage="Last 7 Days"
                                        />
                                    ),
                                    value: 7
                                },
                                {
                                    label: (
                                        <FormattedMessage
                                            id="Dashboards.YearOverYearFilterForm.Filter.DateRange.Last30Days"
                                            defaultMessage="Last 30 Days"
                                        />
                                    ),
                                    value: 30
                                },
                                {
                                    label: (
                                        <FormattedMessage
                                            id="Dashboards.YearOverYearFilterForm.Filter.DateRange.Last3Months"
                                            defaultMessage="Last 3 Months"
                                        />
                                    ),
                                    value: 90
                                },
                                {
                                    label: (
                                        <FormattedMessage
                                            id="Dashboards.YearOverYearFilterForm.Filter.DateRange.Last6Months"
                                            defaultMessage="Last 6 Months"
                                        />
                                    ),
                                    value: 180
                                },
                                {
                                    label: (
                                        <FormattedMessage
                                            id="Dashboards.YearOverYearFilterForm.Filter.DateRange.Last12Months"
                                            defaultMessage="Last 12 Months"
                                        />
                                    ),
                                    value: 365
                                },
                                {
                                    label: (
                                        <FormattedMessage
                                            id="Dashboards.YearOverYearFilterForm.Filter.DateRange.CustomDate"
                                            defaultMessage="Custom Date"
                                        />
                                    ),
                                    value: 'Custom Date'
                                },
                                {
                                    label: (
                                        <FormattedMessage
                                            id="Dashboards.YearOverYearFilterForm.Filter.DateRange.AllTime"
                                            defaultMessage="All Time"
                                        />
                                    ),
                                    value: Infinity
                                }
                            ]
                        }}
                        component={Select}
                    />
                </Form.Column>
            </Form.Columns>
            <Form.Row>
                <Field
                    name="eventSeriesFilter"
                    id="eventSeriesFilter"
                    fieldProps={{
                        label: (
                            <FormattedMessage
                                id="Dashboards.YearOverYearFilterForm.Filter.EventSeries"
                                defaultMessage="Events or Event Series"
                            />
                        ),
                        clearable: false,
                        options
                    }}
                    component={MultiSelect}
                    validate={valueValidation([
                        {
                            validator: 'length',
                            args: { max: 6, msg: 'Maximum number of events to compare is 6.' }
                        }
                    ])}
                />
            </Form.Row>
        </Form>
    );
};

YearOverYearFilterForm.defaultProps = {
    disabledField: {},
    options: []
};

YearOverYearFilterForm.propTypes = {
    options: PropTypes.oneOfType([PropTypes.array]),
    disabledField: PropTypes.oneOfType([PropTypes.object])
};

export default reduxForm({
    form: 'YearOverYearFilterForm',
    enableReinitialize: true
})(YearOverYearFilterForm);
