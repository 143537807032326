import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import { FormattedMessage, FormattedDate, FormattedTime } from 'react-intl';
import LayoutManager from 'erputils/LayoutManager';
import PageHeader from 'erpcomponents/Layout/PageHeader';
import Listing from 'erpcomponents/Listing';
import ListingTableActions from 'erpcomponents/Listing/components/TableActions';
import Svg from 'erpcomponents/Svg';
import Tooltip from 'erpcomponents/Tooltip';
import ButtonDropdown from 'erpcomponents/ButtonDropdown';
import { initialOrderBy } from 'erputils/utils';
import { parseParamsForApi } from 'erpcomponents/Listing/Listing.utils';
import { actions as listingActions } from 'erpcomponents/Listing/Listing.reducer';
import {
    getListingFetching,
    getListingResponse,
    getQueryParams
} from 'erpcomponents/Listing/Listing.selectors';
import { intOperations } from 'erpdata/filterOperations';
import CustomerTableInfo from 'erpcore/screens/Orders/components/CustomerTableInfo';
import EventTicketsTableInfo from 'erpcore/screens/Orders/components/EventTicketsTableInfo';
import OrderNumberAndInfo from 'erpcore/screens/Orders/components/OrderNumberAndInfo';
import StatusBadge from 'erpcomponents/StatusBadge';
import TableCheckbox from 'erpcore/screens/Orders/components/TableCheckbox';
import { getCheckedOrders } from 'erpcore/screens/Orders/Orders.selectors';
import OrderDelete from 'erpcore/screens/Orders/components/OrderDelete';
import ManageGroupModal from 'erpcore/screens/Orders/components/ManageGroupModal';
import sources from 'erpcore/data/sources';
import { actions as notificationManagerActions } from 'erputils/NotificationManager/NotificationManager.reducer';
import restClient from 'erpcore/api/restClient';

import DeleteOrders from 'erpcore/screens/Orders/screens/OrdersListing/BulkActions/DeleteOrders';
import PageLoader from 'erpcomponents/PageLoader';

const reducerName = 'orders';

class OrdersListing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fetchingCSV: false
        };
    }

    downloadCSV() {
        // eslint-disable-next-line no-unused-vars
        const { dispatch, listingParams } = this.props;
        this.setState({ fetchingCSV: true });

        restClient
            .get(`api/orders/export`, {
                params: parseParamsForApi(listingParams),
                responseType: 'arraybuffer',
                headers: {
                    Accept: 'text/csv'
                }
            })
            .then(response => {
                this.setState({ fetchingCSV: false });
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `orders-export.csv`);
                link.click();
            })
            .catch(() => {
                this.setState({ fetchingCSV: false });
                dispatch({
                    type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                    response: { code: 'error500' }
                });
            });
    }

    tableData() {
        const { ordersData } = this.props;
        const table = {};
        table.data = [];

        // Advanced Filters
        table.filters = [
            {
                name: 'event',
                label: (
                    <FormattedMessage
                        id="Orders.Table.EventName.Label"
                        defaultMessage="Event Name"
                    />
                ),
                filterFields: {
                    value: {
                        component: 'multiautocomplete',
                        fieldProps: {
                            label: (
                                <FormattedMessage
                                    id="Orders.Table.EventName"
                                    defaultMessage="Event Name"
                                />
                            ),
                            options: {
                                endpoint: 'api/events',
                                mapData: {
                                    label: 'name',
                                    value: 'iri'
                                }
                            }
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            },
            {
                name: 'status.status',
                label: <FormattedMessage id="Orders.Table.Status.Label" defaultMessage="Status" />,
                filterFields: {
                    value: {
                        component: 'select',
                        fieldProps: {
                            label: (
                                <FormattedMessage
                                    id="Orders.Table.Status"
                                    defaultMessage="Status"
                                />
                            ),
                            options: [
                                { label: 'Active', value: 'Active' },
                                { label: 'Error', value: 'Error' },
                                { label: 'Refund', value: 'Refund' }
                            ]
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            },
            {
                name: 'ticket_types',
                label: (
                    <FormattedMessage
                        id="Orders.Table.TicketTypes.Label"
                        defaultMessage="Ticket Types"
                    />
                ),
                defaultOperator: 'in',
                filterFields: {
                    value: {
                        component: 'multiautocomplete',
                        fieldProps: {
                            label: (
                                <FormattedMessage
                                    id="Orders.Table.TicketTypes"
                                    defaultMessage="Ticket Types"
                                />
                            ),
                            options: {
                                endpoint: 'api/ticket-types',
                                mapData: {
                                    label: 'name',
                                    value: 'iri'
                                }
                            }
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            },
            {
                name: 'total.amount',
                label: (
                    <FormattedMessage
                        id="Orders.Table.OrderValue.Label"
                        defaultMessage="Order Value"
                    />
                ),
                filterFields: {
                    operator: {
                        component: 'select',
                        fieldProps: {
                            clearable: false,
                            label: (
                                <FormattedMessage
                                    id="Events.Table.Orders.Operator"
                                    defaultMessage="Operator"
                                />
                            ),
                            options: intOperations
                        },
                        fieldValidation: [{ validator: 'required' }]
                    },
                    value: {
                        component: 'number',
                        fieldProps: {
                            label: (
                                <FormattedMessage
                                    id="Orders.Table.OrderValue"
                                    defaultMessage="OrderValue"
                                />
                            )
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            },
            {
                name: 'reseller',
                label: (
                    <FormattedMessage
                        id="Orders.Table.Filter.Affiliate.Label"
                        defaultMessage="Affiliate"
                    />
                ),
                filterFields: {
                    value: {
                        component: 'autocomplete',
                        fieldProps: {
                            label: (
                                <FormattedMessage
                                    id="Orders.Table.Affiliate"
                                    defaultMessage="Affiliate"
                                />
                            ),
                            options: {
                                endpoint: 'api/resellers',
                                mapData: {
                                    label: 'reseller_name',
                                    value: 'iri'
                                }
                            }
                        },

                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            },
            {
                name: 'group',
                label: (
                    <FormattedMessage id="Orders.Table.Filter.Group.Label" defaultMessage="Group" />
                ),
                filterFields: {
                    value: {
                        component: 'autocomplete',
                        fieldProps: {
                            label: (
                                <FormattedMessage
                                    id="Orders.Table.Groups"
                                    defaultMessage="Groups"
                                />
                            ),
                            options: {
                                endpoint: 'api/groups',
                                mapData: {
                                    label: 'name',
                                    value: 'iri'
                                }
                            }
                        },

                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            },
            {
                name: 'event.event_series',
                label: (
                    <FormattedMessage
                        id="Orders.Table.Filter.EventSeries.Label"
                        defaultMessage="Event Series"
                    />
                ),
                defaultOperator: 'in',
                filterFields: {
                    value: {
                        component: 'multiautocomplete',
                        fieldProps: {
                            label: (
                                <FormattedMessage
                                    id="Orders.Table.EventSeries"
                                    defaultMessage="Event Series"
                                />
                            ),
                            options: {
                                endpoint: 'api/event-series',
                                mapData: {
                                    label: 'name',
                                    value: 'iri'
                                }
                            }
                        },

                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            },
            {
                name: 'has_note',
                label: (
                    <FormattedMessage
                        id="Orders.Table.Filter.HasNote.Label"
                        defaultMessage="Has Note"
                    />
                ),
                filterFields: {
                    value: {
                        component: 'switch',
                        fieldProps: {
                            label: (
                                <FormattedMessage
                                    id="Orders.Table.HasNote"
                                    defaultMessage="Has Note"
                                />
                            ),
                            on: { label: 'On', value: true, id: 'switch-on-true' },
                            off: { label: 'Off', value: false, id: 'switch-off-false' },
                            type: 'single'
                        },

                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            },
            {
                name: 'order_date',
                label: (
                    <FormattedMessage
                        id="Orders.Table.Filter.OrderDate.Label"
                        defaultMessage="Order Date"
                    />
                ),
                defaultOperator: 'equals',
                filterFields: {
                    value: {
                        component: 'dateTime',
                        fieldProps: {
                            label: (
                                <FormattedMessage
                                    id="Orders.Table.OrderDate"
                                    defaultMessage="Order Date"
                                />
                            ),
                            options: {}
                        },

                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            },
            {
                name: 'order_number',
                label: (
                    <FormattedMessage
                        id="Orders.Table.Filter.OrderNumber.Label"
                        defaultMessage="Order Number"
                    />
                ),
                defaultOperator: 'in',
                filterFields: {
                    value: {
                        component: 'multiautocomplete',
                        fieldProps: {
                            label: (
                                <FormattedMessage
                                    id="Orders.Table.OrderNumber"
                                    defaultMessage="Order #"
                                />
                            ),
                            options: {
                                endpoint: 'api/orders',
                                mapData: {
                                    label: 'order_number',
                                    value: 'order_number'
                                }
                            }
                        },

                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            },
            {
                name: 'third_party_status',
                label: (
                    <FormattedMessage
                        id="Orders.Table.Filter.3rdPartyStatus.Label"
                        defaultMessage="3rd Party Status"
                    />
                ),
                filterFields: {
                    value: {
                        component: 'switch',
                        fieldProps: {
                            label: (
                                <FormattedMessage
                                    id="Orders.Table.3rdPartyStatus"
                                    defaultMessage="3rd Party Status"
                                />
                            ),
                            on: { label: 'On', value: true, id: 'switch-on-true' },
                            off: { label: 'Off', value: false, id: 'switch-off-false' },
                            type: 'single'
                        },

                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            },
            {
                name: 'company',
                label: (
                    <FormattedMessage id="Orders.Table.Company.Label" defaultMessage="Company" />
                ),
                filterFields: {
                    value: {
                        component: 'autocomplete',
                        fieldProps: {
                            label: (
                                <FormattedMessage
                                    id="Orders.Table.Company"
                                    defaultMessage="Company"
                                />
                            ),
                            options: {
                                endpoint: `/api/companies`,
                                mapData: {
                                    value: 'iri',
                                    label: 'name'
                                }
                            },
                            clearable: false
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            },
            {
                name: 'is_request_sent_status',
                label: (
                    <FormattedMessage
                        id="Orders.Table.Filter.PaymentAuthFormStatus.Label"
                        defaultMessage="Payment Auth Form Status"
                    />
                ),
                filterFields: {
                    value: {
                        component: 'switch',
                        fieldProps: {
                            label: (
                                <FormattedMessage
                                    id="Orders.Table.PaymentAuthFormStatus"
                                    defaultMessage="Payment Auth Form Status"
                                />
                            ),
                            on: { label: 'Have payment auth', value: true, id: 'switch-on-true' },
                            off: {
                                label: 'Do not have payment auth form',
                                value: false,
                                id: 'switch-off-false'
                            },
                            type: 'dual'
                        },

                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            },
            {
                name: 'source',
                label: (
                    <FormattedMessage
                        id="Orders.Table.Filter.Source.Label"
                        defaultMessage="Source"
                    />
                ),
                filterFields: {
                    value: {
                        component: 'select',
                        fieldProps: {
                            label: (
                                <FormattedMessage
                                    id="Orders.Table.Source"
                                    defaultMessage="Source"
                                />
                            ),
                            options: sources
                        },

                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            },
            {
                name: 'event.start',
                label: (
                    <FormattedMessage
                        id="Orders.Table.Filter.DateRange.Label"
                        defaultMessage="Date Range"
                    />
                ),
                defaultOperator: 'between',
                filterFields: {
                    value: {
                        component: 'dateRange',
                        fieldProps: {
                            label: (
                                <FormattedMessage
                                    id="Orders.Table.DateRange"
                                    defaultMessage="Date Range"
                                />
                            ),
                            options: {}
                        },

                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            }
        ];
        // bulk actions

        table.bulkActions = {
            name: 'orders',
            actions: [{ label: 'Delete Orders', component: <DeleteOrders /> }]
        };

        // Table Schema
        table.schema = [
            {
                title: <FormattedMessage id="Orders.Table.OrderNumber" defaultMessage="Order #" />,
                field: 'orderNumber',
                sortable: 'orderNumber',
                mobile: 'title'
            },
            {
                title: <FormattedMessage id="Orders.Table.Date" defaultMessage="Date" />,
                field: 'orderDate',
                sortable: 'orderDate'
            },
            {
                title: <FormattedMessage id="Orders.Table.Status" defaultMessage="Status" />,
                field: 'status',
                mobile: 'status'
            },
            {
                title: <FormattedMessage id="Orders.Table.Customer" defaultMessage="Customer" />,
                field: 'customer',
                mobile: 'subtitle'
            },

            {
                title: (
                    <FormattedMessage
                        id="Orders.Table.EventTickets"
                        defaultMessage="Event/Tickets"
                    />
                ),
                field: 'eventTickets',
                mobile: 'subtitle'
            },
            {
                title: <FormattedMessage id="Orders.Table.Total" defaultMessage="Total" />,
                field: 'total',
                mobile: 'subtitle',
                sortable: 'total.amount'
            },
            {
                title: <FormattedMessage id="Orders.Table.Actions" defaultMessage="Actions" />,
                field: 'actions'
            }
        ];

        ordersData.data.map(row => {
            const name = row.customer && `${row.customer.first_name} ${row.customer.last_name}`;
            const status = row.status && row.status.status;
            let type = '';
            if (status === 'Pending') type = 'pending';
            if (status === 'Active') type = 'fulfilled';
            if (status === 'Error') type = 'voided';
            if (status === 'Refund') type = 'special';
            if (status === 'Declined') type = 'voided';
            return table.data.push({
                id: row.id,
                iri: row.iri,
                orderNumber: (
                    <OrderNumberAndInfo
                        orderNumber={row.order_number}
                        shipped={row.shipped}
                        orderId={row.id}
                    />
                ),
                checked: <TableCheckbox id={row.id} />,
                orderDate: (
                    <Fragment>
                        <FormattedDate value={row.order_date} />,{' '}
                        <FormattedTime value={row.order_date} />
                    </Fragment>
                ),
                status: <StatusBadge type={type} text={status} />,
                customer: (
                    <CustomerTableInfo
                        name={name}
                        email={row.customer && row.customer.email}
                        phone={row.customer && row.customer.phone}
                    />
                ),
                eventTickets: (
                    <EventTicketsTableInfo event={row.event} tickets={row.ticket_types} />
                ),
                total: `$ ${row.total.amount / 100}`,
                actions: (
                    <ListingTableActions>
                        <ListingTableActions.Action>
                            <Tooltip
                                content={
                                    <FormattedMessage
                                        id="Orders.Table.Actions.View"
                                        defaultMessage="View Order"
                                    />
                                }
                            >
                                <NavLink to={`/orders/${row.id}/order-info`}>
                                    <Svg icon="passShow" />
                                </NavLink>
                            </Tooltip>
                        </ListingTableActions.Action>
                        <ListingTableActions.Action>
                            <Tooltip
                                content={
                                    <FormattedMessage
                                        id="Orders.Table.Actions.ResendMail"
                                        defaultMessage="Resend Confirmation Mail"
                                    />
                                }
                            >
                                <NavLink to="/">
                                    <Svg icon="resendEmail" />
                                </NavLink>
                            </Tooltip>
                        </ListingTableActions.Action>
                        {row.group && (
                            <ListingTableActions.Action>
                                <ManageGroupModal
                                    id={row.group.id}
                                    eventId={row.event && row.event.id}
                                    form={`GroupEditModalForm${row.group.id}`}
                                />
                            </ListingTableActions.Action>
                        )}
                        <ListingTableActions.Action>
                            <OrderDelete
                                id={row.id}
                                key={`OrderDelete_${row.id}`}
                                form="OrderDeleteForm"
                            />
                        </ListingTableActions.Action>
                    </ListingTableActions>
                )
            });
        });

        return table;
    }

    render() {
        const { ordersListingFetching, ordersData, fetchOrders, checked } = this.props;
        const { fetchingCSV } = this.state;

        return (
            <LayoutManager slot="main" layoutType="merge">
                <PageHeader
                    title={<FormattedMessage id="Orders.title" defaultMessage="Orders" />}
                />
                {fetchingCSV && <PageLoader />}
                <Listing
                    name={reducerName}
                    reducerName={reducerName}
                    loading={ordersListingFetching}
                    meta={ordersData.meta}
                    table={this.tableData()}
                    onListingConfigUpdate={params => fetchOrders(params)}
                    ordersListingBulkActions={
                        checked && checked.length > 0 ? checked.length > 0 : false
                    }
                >
                    <ButtonDropdown
                        placeholder={
                            <FormattedMessage
                                id="Orders.listing.button.export"
                                defaultMessage="Export"
                            />
                        }
                        options={[
                            {
                                id: 'export-orders-csv',
                                label: (
                                    <FormattedMessage
                                        id="Orders.listing.button.option.export.csv"
                                        defaultMessage="Export Orders CSV"
                                    />
                                ),
                                loading: fetchingCSV,
                                onClick: () => this.downloadCSV()
                            }
                        ]}
                    />
                </Listing>
            </LayoutManager>
        );
    }
}

OrdersListing.defaultProps = {
    ordersListingFetching: false,
    ordersData: {},
    checked: [],
    dispatch: () => {},
    listingParams: {}
};

OrdersListing.propTypes = {
    fetchOrders: PropTypes.func.isRequired,
    ordersListingFetching: PropTypes.bool,
    ordersData: PropTypes.oneOfType([PropTypes.object]),
    checked: PropTypes.oneOfType([PropTypes.array]),
    dispatch: PropTypes.func,
    listingParams: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

//  Redux
// ovo provjerit na seo
const mapStateToProps = state => ({
    ordersListingFetching: getListingFetching(state, reducerName),
    ordersData: getListingResponse(state, reducerName),
    checked: getCheckedOrders(state),
    listingParams: getQueryParams(state, { name: reducerName })
});

const mapDispatchToProps = dispatch => ({
    fetchOrders: params => {
        params = initialOrderBy(params, {
            'order_by[orderDate]': 'DESC'
        });
        // Getting included data from API by setting defaultParams
        const defaultParams = {
            include:
                'status,deliveryStatus,reseller,tickets,customer,event,eventSeries,event.ticketTypes,event.company,group'
        };
        const listingParams = Object.assign({}, defaultParams, params);

        dispatch({
            type: listingActions.START_FETCHING_LISTING,
            params: listingParams,
            entity: 'ORDERS',
            name: reducerName,
            endpoint: 'api/orders'
        });
    }
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(OrdersListing)
);
