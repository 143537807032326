import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { reduxForm, getFormValues, change, Field, getFormMeta, untouch } from 'redux-form';
import Form, { Select, TextEditorClass, Text } from 'erpcore/components/Form';
import valueValidation from 'erputils/valueValidation';
import { FormattedMessage } from 'react-intl';
import LayoutManager from 'erputils/LayoutManager';
import PageHeader from 'erpcomponents/Layout/PageHeader';
import PageContent from 'erpcomponents/Layout/PageContent';
import NotificationManager from 'erputils/NotificationManager';
import { actions as eventsActions } from 'erpcore/screens/Events/Events.reducer';
import dto, { getIdFromIri } from 'erputils/dto';
import PageLoader from 'erpcomponents/PageLoader';
import ElementLoader from 'erpcomponents/ElementLoader';
import restClient from 'erpcore/api/restClient';
import { actions as listingActions } from 'erpcomponents/Listing/Listing.reducer';
import { getListingFetching, getListingResponse } from 'erpcomponents/Listing/Listing.selectors';
import { getEventData, getSingleEventFetching } from 'erpsrc/screens/Events/Events.selectors';
import StatusBadge from 'erpcomponents/StatusBadge';
import {
    getFooterElementSize,
    getModalRemoveGroup,
    getModalGroupInfo,
    getModalSplitTable,
    getSingleTablesUpdating,
    getModalTableNotes
} from 'erpsrc/screens/Tables/Tables.selectors';
import FloorPlans from 'erpcore/screens/Tables/components/FloorPlans';
import TableList from 'erpcore/screens/Tables/components/TableList';
import GroupsFooter from 'erpsrc/screens/Tables/components/GroupsFooter';
import { DragDropContext } from 'react-beautiful-dnd';
import './TableAllotments.scss';
import Modal from 'erpcomponents/Modal';
import Button from 'erpcomponents/Button';
import { actions as tablesActions } from 'erpsrc/screens/Tables/Tables.reducer';
import TableFilters from 'erpsrc/screens/Tables/components/TableFilters';
import ButtonDropdown from 'erpcomponents/ButtonDropdown';
import { actions as notificationManagerActions } from 'erputils/NotificationManager/NotificationManager.reducer';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

// https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/about/examples.md
// https://codesandbox.io/s/ql08j35j3q

const initialTableFilterValues = {
    filterTablesSearch: null,
    filterTablesRoom: null,
    filterTablesSortBy: 'default',
    filterTablesAvailable: false,
    filterTablesShared: false
};
const initialGroupFilterValues = {
    filterGroupsIdSearch: null,
    filterGroupsOrderSearch: null,
    filterGroupsCompletion: 'any',
    filterGroupsVip: false,
    filterGroupsSeated: false
    // filterGroupsSeated: true
};

class TableAllotments extends React.Component {
    constructor(props) {
        super(props);

        this.grid = 8;

        this.state = {
            tablesData: [],
            groupsData: [],
            eventNoTables: false,
            eventNoGroups: false,
            eventDropdownOptions: [],
            eventDropdownOptionsFetching: false,
            generalUpdateInProgress: false,
            modalMoveGroupConfirmation: {
                active: false,
                reason: [],
                data: null,
                scheduledWork: null
            },
            printLoading: false,
            fetchingCSV: false
        };

        const { tablesData, groupsData } = this.props;
        if (tablesData && tablesData.data !== undefined && tablesData.data.length) {
            this.state.tablesData = tablesData.data;
        }
        if (groupsData && groupsData.data !== undefined && groupsData.data.length) {
            this.state.groupsData = groupsData.data;
        }
        this.state.eventNoTables = this.isListingResponseEmpty(tablesData);
        this.state.eventNoGroups = this.isListingResponseEmpty(groupsData);

        this.getGroupById = this.getGroupById.bind(this);
        this.getTableById = this.getTableById.bind(this);
        this.handleFullscreen = this.handleFullscreen.bind(this);

        this.modalTableNotes = React.createRef();
    }

    componentWillMount() {
        const { match, initialize } = this.props;
        if (match && match.params && match.params.eventId) {
            initialize({
                ...initialTableFilterValues,
                ...initialGroupFilterValues,
                event: `/api/events/${match.params.eventId}`
            });
            this.fetchAllData(match.params.eventId);
        }
    }

    componentDidMount() {
        this.setEventDropdownOptions();
        document.addEventListener('fullscreenchange', this.handleFullscreen);
    }

    componentWillReceiveProps(nextProps) {
        const { match: nextMatch, tablesData, groupsData } = nextProps;
        const { match } = this.props;

        const nextEventUrlID =
            nextMatch &&
            nextMatch.params !== undefined &&
            nextMatch.params &&
            nextMatch.params.eventId
                ? nextMatch.params.eventId
                : false;
        const eventUrlID =
            match && match.params !== undefined && match.params && match.params.eventId
                ? match.params.eventId
                : false;

        // Table and Group data can be found in the store even if the Event is not selected.
        if (!nextEventUrlID) {
            // If no Event is selected, remove Table and Groups from local state
            this.setState({
                tablesData: [],
                groupsData: [],
                eventNoTables: false,
                eventNoGroups: false
            });
        } else {
            // Set Table and Groups local state
            const eventNoTables = this.isListingResponseEmpty(tablesData);
            if (tablesData && tablesData.data !== undefined) {
                if (tablesData.data.length) {
                    this.setState({
                        tablesData: tablesData.data,
                        eventNoTables
                    });
                } else {
                    this.setState({
                        tablesData: [],
                        eventNoTables
                    });
                }
            }
            const eventNoGroups = this.isListingResponseEmpty(groupsData);
            if (groupsData && groupsData.data !== undefined) {
                if (groupsData.data.length) {
                    this.setState({
                        groupsData: groupsData.data,
                        eventNoGroups
                    });
                } else {
                    this.setState({
                        groupsData: [],
                        eventNoGroups
                    });
                }
            }
        }

        if (nextEventUrlID && nextEventUrlID !== eventUrlID) {
            this.fetchAllData(nextEventUrlID);
        }

        return null;
    }

    // eslint-disable-next-line react/sort-comp
    fetchAllData(eventId = null) {
        if (eventId) {
            const { fetchEventData, fetchTables, fetchGroups, setFormValue } = this.props;

            // reset filters
            setFormValue('filterTablesSearch', null);
            setFormValue('filterTablesSortBy', 'default');
            setFormValue('filterTablesRoom', null);
            setFormValue('filterGroupsIdSearch', null);
            setFormValue('filterGroupsOrderSearch', null);
            setFormValue('filterGroupsCompletion', 'any');

            // fetch event
            fetchEventData(eventId);

            // fetch event tables
            fetchTables({
                'filters[event.id][equals]': eventId
            });

            // fetch event groups
            fetchGroups({
                'filters[event.id][equals]': eventId
            });
        }

        return null;
    }

    // eslint-disable-next-line react/sort-comp
    changeEventUrl(eventId = null) {
        if (eventId) {
            const { history } = this.props;
            history.push(`/table-allotments/${eventId}`);
        }
    }

    componentWillUnmount() {
        this.handleFullscreenOff();
        if (document.fullscreenElement) {
            document.exitFullscreen();
        }
        document.removeEventListener('fullscreenchange', this.handleFullscreen);
    }

    /**
     * react-beautiful-dnd onDragEnd Responder
     * https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/guides/responders.md
     */
    onDragEnd = dragResult => {
        const { source, destination, draggableId } = dragResult;

        if (!destination) {
            return false;
        }

        if (
            source &&
            destination &&
            source.droppableId !== undefined &&
            destination.droppableId !== undefined
        ) {
            if (source.droppableId === destination.droppableId) {
                return false;
            }

            // group id
            const groupId = draggableId.replace(/[^0-9.]+/g, '');

            // new table id
            const newTableId =
                destination.droppableId === 'groups-footer'
                    ? 'remove-only'
                    : destination.droppableId.replace(/[^0-9.]+/g, '');

            const moveValidation = this.moveGroupValidation({ groupId, newTableId });

            if (moveValidation.allowed === 'ask-confirmation') {
                this.handleModalMoveGroupConfirmation(
                    true,
                    moveValidation.reason,
                    moveValidation.data,
                    () => {
                        this.moveGroup({
                            groupId,
                            newTableId,
                            newTableGroupIndex: destination.index
                        });
                    }
                );
            } else {
                this.moveGroup({
                    groupId,
                    newTableId,
                    newTableGroupIndex: destination.index
                });
            }
        }

        return false;
    };

    // eslint-disable-next-line react/sort-comp
    moveGroupValidation({ groupId, newTableId }) {
        const output = {
            allowed: true,
            reason: [],
            data: null
        };

        if (groupId && newTableId) {
            const { tablesData, groupsData } = this.state;

            const tables = [...tablesData];
            const groups = [...groupsData];

            // group reference
            const groupReference = !groupId.toString()
                ? null
                : groups.find(group => group.id.toString() === groupId.toString());

            // new table reference
            const newTable =
                newTableId === null
                    ? null
                    : tables.find(table => table.id.toString() === newTableId.toString());

            if (newTable && groupReference) {
                const blockers = [];
                let occupiedSeats = 0;
                const { max_seats: maxSeats } = newTable;
                let privateGroupCount = 0;
                let tablesCurrentGroupCount = 0;

                if (maxSeats) {
                    // calculate CURRENT table occupancy
                    if (
                        newTable.groups !== undefined &&
                        newTable.groups &&
                        newTable.groups.length
                    ) {
                        tablesCurrentGroupCount = newTable.groups.length;
                        newTable.groups.forEach(group => {
                            const groupData = this.getGroupById(group.id);
                            if (groupData) {
                                if (groupData.group_size !== undefined && groupData.group_size) {
                                    occupiedSeats += groupData.group_size;
                                }
                                if (
                                    groupData.willing_to_share !== undefined &&
                                    !groupData.willing_to_share
                                ) {
                                    privateGroupCount += 1;
                                }
                            }
                        });
                    }

                    // calculate FUTURE table occupancy
                    if (groupReference.group_size) {
                        occupiedSeats += groupReference.group_size;
                    }

                    // SET confirmation requirements

                    // over-occupied confirmation requirement
                    if (occupiedSeats > maxSeats) {
                        blockers.push('over-occupied');
                    }

                    if (tablesCurrentGroupCount) {
                        // table is "private" if it has at least one private group
                        const isTablePrivate = privateGroupCount > 0;
                        const isGroupPrivate =
                            groupReference.willing_to_share !== undefined &&
                            !groupReference.willing_to_share;
                        if (isTablePrivate && !isGroupPrivate) {
                            // shared-to-private-conflict confirmation requirement
                            blockers.push('shared-to-private-conflict');
                        } else if (!isTablePrivate && isGroupPrivate) {
                            // private-to-shared-conflict confirmation requirement
                            blockers.push('private-to-shared-conflict');
                        }
                    }
                }

                // validation object
                if (blockers.length) {
                    output.allowed = 'ask-confirmation';
                    output.reason = blockers;
                    output.data = {
                        groupName: groupReference.name,
                        tableName: newTable.table_number,
                        occupied: occupiedSeats,
                        maxSeats
                    };
                }
            }
        }

        return output;
    }

    // eslint-disable-next-line react/sort-comp
    moveGroup({ groupId, newTableId, newTableGroupIndex = 0 }) {
        const { tablesData, groupsData } = this.state;

        const tables = [...tablesData];
        const groups = [...groupsData];

        // group reference
        const groupReference = !groupId.toString()
            ? null
            : groups.find(group => group.id.toString() === groupId.toString());

        // new table reference
        const newTable =
            newTableId === null
                ? null
                : tables.find(table => table.id.toString() === newTableId.toString());

        /**
         * Removes object with matching id property from array.
         * Does not return modified array, it mutates the provided array.
         *
         * @param list {array} Array reference to mutate
         * @param matchingId {string|integer} Id to match against
         * @return {boolean} Returns true if object with matching id is found and removed. Otherwise returns false
         */
        const removeFromList = (list, matchingId) => {
            // eslint-disable-next-line no-plusplus
            for (let i = list.length - 1; i >= 0; i--) {
                if (list[i].id.toString() === matchingId.toString()) {
                    list.splice(i, 1);
                    return true;
                    // break;
                }
            }
            return false;
        };

        // START:Local state changes

        // table.group updates will be collected here
        const scheduleApiTableUpdates = [];

        // LOCAL UPDATE 1. remove group from all known old tables
        if (
            groupReference &&
            groupReference.tables !== undefined &&
            groupReference.tables &&
            groupReference.tables.length
        ) {
            // loop through group.tables
            groupReference.tables.forEach(tableInGroup => {
                // find matching table reference in table state
                const matchingTable = tables.find(
                    table => table.id.toString() === tableInGroup.id.toString()
                );
                if (
                    matchingTable &&
                    matchingTable.groups !== undefined &&
                    matchingTable.groups &&
                    matchingTable.groups.length
                ) {
                    // remove group from table (in local state)
                    if (removeFromList(matchingTable.groups, groupId)) {
                        // if removed locally, schedule API update
                        scheduleApiTableUpdates.push({
                            id: matchingTable.id,
                            groups: [...matchingTable.groups]
                        });
                    }
                }
            });
        }

        // LOCAL UPDATE 2. add group to new table
        if (newTable) {
            if (newTable.groups === undefined || !newTable.groups) {
                newTable.groups = [];
            }
            const groupData = this.getGroupById(groupId);
            // add group to table at specified index
            newTable.groups.splice(newTableGroupIndex, 0, groupData);
            // schedule API update
            scheduleApiTableUpdates.push({
                id: newTable.id,
                groups: [...newTable.groups]
            });
        }

        // LOCAL UPDATE 3.1 if group is moved to footer -> remove all tables from group
        if (groupId === 'remove-only' && groupReference.tables !== null) {
            groupReference.tables.length = 0; // because reference mutation
        }

        // LOCAL UPDATE 3.2 add new table to group (and remove other tables)
        if (newTable && groupReference) {
            groupReference.tables = [newTable]; // match with API call
        }

        // Store local updates
        this.setState({
            tablesData: tables,
            groupsData: groups,
            generalUpdateInProgress: true
        });

        // END:Local state changes

        // continue with remote API updates

        const { updateTable, fetchTables, fetchGroups } = this.props;
        const eventIri = this.getCurrentEventIri();

        // Set of Concurrent updateTable() Promises
        Promise.all(
            scheduleApiTableUpdates.map(table => {
                const formData = {
                    groups: table.groups.map(group => group.iri)
                };
                return updateTable(table.id, formData);
            })
        ).then(() => {
            // after all scheduled tables have been concurrently/simultaneously updated

            const eventId = getIdFromIri(eventIri);

            // fetch event tables
            fetchTables({
                'filters[event.id][equals]': eventId
            });

            // fetch event groups
            fetchGroups({
                'filters[event.id][equals]': eventId
            });

            this.setState({ generalUpdateInProgress: false });
        });
    }

    handleModalSplitTableHelper = arg => {
        const { handleModalSplitTable, setFormValue, untouchField } = this.props;
        handleModalSplitTable(arg);
        setFormValue('table_to_split_name', null);
        setFormValue('table_to_split_seats', null);
        untouchField('table_to_split_seats');
        untouchField('table_to_split_name');
    };

    handleModalMoveGroupConfirmation(
        active = false,
        reason = [],
        data = null,
        scheduledWork = null
    ) {
        this.setState({
            modalMoveGroupConfirmation: {
                active,
                reason,
                data,
                scheduledWork
            }
        });
    }

    getCurrentEventIri() {
        const { match } = this.props;
        return match && match.params !== undefined && match.params && match.params.eventId
            ? `/api/events/${match.params.eventId}`
            : null;
    }

    getGroupById = (id = false, property = false) => {
        const { groupsData } = this.state;

        let output = null;

        if (groupsData && groupsData.length) {
            output = groupsData.find(item => id === item.id);

            if (property && output && output.property !== undefined) {
                output = output.property;
            }
        }

        return output;
    };

    getTableById = (id = false, property = false) => {
        const { tablesData } = this.state;

        let output = null;

        if (tablesData && tablesData.length) {
            output = tablesData.find(item => id.toString() === item.id.toString());
            if (property && output && output.property !== undefined) {
                output = output.property;
            }
        }

        return output;
    };

    getTableAllotmentsStyle() {
        const { footerElementSize } = this.props;

        if (footerElementSize && footerElementSize.height !== undefined) {
            return {
                paddingBottom: footerElementSize.height
            };
        }

        return null;
    }

    getFormValue(field = false) {
        const { formValues } = this.props;
        if (!formValues || !field || formValues[field] === undefined) return null;

        return formValues[field];
    }

    printPdf = (size, eventIri) => {
        const { dispatch, event } = this.props;
        this.setState({ printLoading: true });
        const eventName = event && event.name;
        let pageSize = '';
        switch (size) {
            case 'small':
                pageSize = 'A6';
                break;
            case 'large':
                pageSize = 'Letter';
                break;
            case 'smallest':
                pageSize = 'A4';
                break;
            default:
                pageSize = '';
                break;
        }

        restClient
            .post(
                `api/print-table-cards`,
                {
                    event: eventIri && eventIri,
                    pageSize
                },
                {
                    responseType: 'arraybuffer'
                }
            )

            .then(response => {
                this.setState({ printLoading: false });
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                let cardName = '';
                switch (size) {
                    case 'small':
                        cardName = '4x6 Table Card';
                        break;
                    case 'large':
                        cardName = '8.5x11 Table Card';
                        break;
                    case 'smallest':
                        cardName = '2x3.5 Table Card';
                        break;
                    default:
                        cardName = '';
                        break;
                }
                link.setAttribute('download', `${eventName && eventName} - ${cardName}.pdf`);
                link.click();
            })
            .catch(() => {
                this.setState({ printLoading: false });
                dispatch({
                    type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                    response: { code: 'printcard.notFound' }
                });
            });
    };

    resetFilters = (type = null) => {
        const { setFormValue } = this.props;

        if (type === 'tables') {
            Object.keys(initialTableFilterValues).forEach(formField =>
                setFormValue(formField, initialTableFilterValues[formField])
            );
            return false;
        }

        if (type === 'groups') {
            Object.keys(initialGroupFilterValues).forEach(formField =>
                setFormValue(formField, initialGroupFilterValues[formField])
            );
            return false;
        }

        Object.keys(initialTableFilterValues).forEach(formField =>
            setFormValue(formField, initialTableFilterValues[formField])
        );

        Object.keys(initialGroupFilterValues).forEach(formField =>
            setFormValue(formField, initialGroupFilterValues[formField])
        );

        return false;
    };

    openNewTab(url = null) {
        if (!url) return null;

        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.click();

        return null;
    }

    isListingResponseEmpty(listingData) {
        let isEmpty = false;
        if (
            listingData &&
            listingData.meta !== undefined &&
            listingData.meta.totalItems !== undefined &&
            (listingData.meta.totalItems === 0 || listingData.meta.totalItems === '0')
        ) {
            isEmpty = true;
        }
        return isEmpty;
    }

    handleFullscreen() {
        if (document.fullscreenElement) {
            this.handleFullscreenOn();
        } else {
            this.handleFullscreenOff();
        }
    }

    handleFullscreenOn() {
        document.body.classList.add('table-allotments-fullscreen');
    }

    handleFullscreenOff() {
        document.body.classList.remove('table-allotments-fullscreen');
    }

    splitTable = primaryTableId => {
        const {
            updateTable,
            createTable,
            fetchTables,
            handleModalSplitTable,
            setFormValue,
            untouchField
        } = this.props;

        const eventIri = this.getCurrentEventIri();

        const newTableMaxSeats = this.getFormValue('table_to_split_seats');
        const primaryTableData = this.getTableById(primaryTableId);
        const eventId = getIdFromIri(eventIri);
        const newTableName =
            this.getFormValue('table_to_split_name') ||
            `${primaryTableData && primaryTableData.table_number} / 2`;

        if (primaryTableData) {
            const formData = {
                max_seats: primaryTableData.max_seats - parseInt(newTableMaxSeats, 10)
            };
            const newTableFormData = {
                table_number: newTableName,
                max_seats: parseInt(newTableMaxSeats, 10),
                min_seats: 1,
                room: primaryTableData.room && primaryTableData.room.iri,
                can_be_grouped_with:
                    primaryTableData.can_be_grouped_with &&
                    primaryTableData.can_be_grouped_with.map(table => table.iri),
                event: primaryTableData.event && primaryTableData.event.iri,
                note: primaryTableData.note && primaryTableData.note,
                locked: primaryTableData.locked && primaryTableData.locked,
                position: primaryTableData.position && primaryTableData.position
            };
            updateTable(primaryTableId, formData)
                .then(() => {
                    handleModalSplitTable();
                    createTable(newTableFormData).then(() => {
                        setFormValue('table_to_split_name', null);
                        setFormValue('table_to_split_seats', null);
                        untouchField('table_to_split_seats');
                        untouchField('table_to_split_name');
                    });
                })
                .then(() => {
                    fetchTables({
                        'filters[event.id][equals]': eventId
                    });
                });
        }
    };

    setEventDropdownOptions() {
        this.setState({ eventDropdownOptionsFetching: true });
        this.fetchAllEvents()
            .then(events => {
                this.setState({
                    eventDropdownOptionsFetching: false,
                    eventDropdownOptions: events.map(event => {
                        return {
                            value: event.iri,
                            label: event.name
                        };
                    })
                });
            })
            .catch(() => {
                this.setState({
                    eventDropdownOptionsFetching: false
                });
            });
    }

    fetchAllEvents() {
        return new Promise((resolve, reject) => {
            const params = {
                'filters[end_utc][upcoming]': true,
                'order_by[name]': 'ASC',
                pagination: false
            };
            restClient
                .get('/api/events', {
                    params
                })
                .then(response => {
                    if (response && response.data !== undefined && response.data) {
                        const events = dto(response.data);
                        if (events && events.data) {
                            return resolve(events.data);
                        }
                    }
                    return reject();
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    renderTotals() {
        const { tablesListingFetching } = this.props;
        const { tablesData } = this.state;

        if (tablesListingFetching || (tablesData && tablesData.length)) {
            const totalTables = tablesData.length;
            let totalSeats = 0;
            let seats = 0;
            let vipPrivateTables = 0;
            let completed = 0;
            let notCompleted = 0;

            tablesData.forEach(table => {
                const { groups, max_seats: tableMaxSeats } = table;

                if (tableMaxSeats) {
                    totalSeats += tableMaxSeats;
                }

                let tableSeatsOccupied = 0;
                let privateGroupCount = 0;

                if (groups && groups.length) {
                    groups.forEach(group => {
                        if (group.id) {
                            const groupData = this.getGroupById(group.id);
                            if (groupData) {
                                if (groupData.group_size !== undefined && groupData.group_size) {
                                    seats += groupData.group_size;
                                    tableSeatsOccupied += groupData.group_size;
                                }
                                if (
                                    groupData.willing_to_share !== undefined &&
                                    !groupData.willing_to_share
                                ) {
                                    privateGroupCount += 1;
                                }
                            }
                        }
                    });
                }

                // table is "private" if it has at least one private group
                if (privateGroupCount > 0) {
                    vipPrivateTables += 1;
                }

                if (tableSeatsOccupied < tableMaxSeats) {
                    notCompleted += 1;
                } else {
                    completed += 1;
                }
            });

            const sharedTables = totalTables - vipPrivateTables;

            return (
                <div className="table-allotments__totals">
                    {tablesListingFetching ? (
                        <div className="table-allotments__totals-item">
                            <span className="table-allotments__totals-item-value">
                                <ElementLoader />
                            </span>
                        </div>
                    ) : (
                        <React.Fragment>
                            <div className="table-allotments__totals-col">
                                <p className="table-allotments__totals-item">
                                    <span className="table-allotments__totals-item-subject">
                                        Total Tables
                                    </span>
                                    <span className="table-allotments__totals-item-value">
                                        {totalTables}
                                    </span>
                                </p>
                                <p className="table-allotments__totals-item">
                                    <span className="table-allotments__totals-item-subject">
                                        Total Seats
                                    </span>
                                    <span className="table-allotments__totals-item-value">
                                        {totalSeats}
                                    </span>
                                </p>
                                <p className="table-allotments__totals-item">
                                    <span className="table-allotments__totals-item-subject">
                                        Seats
                                    </span>
                                    <span className="table-allotments__totals-item-value">
                                        {seats}
                                    </span>
                                </p>
                                <p className="table-allotments__totals-item">
                                    <span className="table-allotments__totals-item-subject">
                                        VIP
                                    </span>
                                    <span className="table-allotments__totals-item-value">
                                        {vipPrivateTables}
                                    </span>
                                </p>
                                <p className="table-allotments__totals-item">
                                    <span className="table-allotments__totals-item-subject">
                                        Shared
                                    </span>
                                    <span className="table-allotments__totals-item-value">
                                        {sharedTables}
                                    </span>
                                </p>
                            </div>
                            <div className="table-allotments__totals-col">
                                <p className="table-allotments__totals-item table-allotments__totals-item--positive">
                                    <span className="table-allotments__totals-item-subject">
                                        Completed
                                    </span>
                                    <span className="table-allotments__totals-item-value">
                                        {completed}
                                    </span>
                                </p>
                                <p className="table-allotments__totals-item table-allotments__totals-item--negative">
                                    <span className="table-allotments__totals-item-subject">
                                        Not Completed
                                    </span>
                                    <span className="table-allotments__totals-item-value">
                                        {notCompleted}
                                    </span>
                                </p>
                            </div>
                        </React.Fragment>
                    )}
                </div>
            );
        }

        return null;
    }

    renderModalGroupInfo(id) {
        if (!!id || id === 0) {
            const group = this.getGroupById(id);
            if (group) {
                const { orders } = group;
                const ticketTypes = [];
                if (orders && orders.length) {
                    orders.forEach(order => {
                        if (order.ticket_types !== undefined && order.ticket_types.length) {
                            order.ticket_types.forEach(ticketType => {
                                if (
                                    ticketType.name !== undefined &&
                                    ticketType.name &&
                                    !ticketTypes.includes(ticketType.name)
                                ) {
                                    ticketTypes.push(ticketType.name);
                                }
                            });
                        }
                    });
                }

                return (
                    <div className="group-modal-content">
                        <div className="table-allotments__totals">
                            <div className="table-allotments__totals-col">
                                <p className="table-allotments__totals-item">
                                    <span className="table-allotments__totals-item-subject">
                                        Group Name
                                        <Link
                                            className="table-allotments__totals-item-value"
                                            to={`/groups/${group.id}/edit`}
                                        >
                                            {group.name}
                                        </Link>
                                    </span>
                                </p>
                            </div>
                            <div className="table-allotments__totals-col">
                                <p className="table-allotments__totals-item table-allotments__totals-item--positive">
                                    <span className="table-allotments__totals-item-subject">
                                        Group Size
                                    </span>
                                    <span className="table-allotments__totals-item-value">
                                        {group.group_size !== undefined && group.group_size
                                            ? group.group_size
                                            : ''}
                                    </span>
                                </p>
                            </div>
                        </div>
                        {orders && !!orders.length && (
                            <div>
                                {orders.map(order => (
                                    <div style={{ display: 'flex', margin: '0 0 16px' }}>
                                        <div style={{ flex: '1 1 33%' }}>
                                            <h5 className="font-label" style={{ marginBottom: 0 }}>
                                                Order Number
                                            </h5>
                                            <Link to={`/orders/${order.id}/order-info`}>
                                                {order.order_number}
                                            </Link>
                                        </div>
                                        <div style={{ flex: '1 1 33%' }}>
                                            <h5 className="font-label" style={{ marginBottom: 0 }}>
                                                Ticket Type
                                            </h5>
                                            {ticketTypes.map((ticketType, i) => [
                                                i > 0 && ', ',
                                                <i>{ticketType}</i>
                                            ])}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                );
            }
        }
        return null;
    }

    renderModalRemoveGroupContent(id) {
        const { handleModalRemoveGroup } = this.props;
        if (!!id || id === 0) {
            const group = this.getGroupById(id);
            const groupName = group && group.name !== undefined ? group.name : '';
            const tableName =
                group && group.tables !== undefined && group.tables.length
                    ? group.tables[0].table_number
                    : '';
            return (
                <div className="group-modal-content">
                    <p>
                        Remove Group <strong style={{ whiteSpace: 'nowrap' }}>{groupName}</strong>{' '}
                        from Table <strong style={{ whiteSpace: 'nowrap' }}>{tableName}</strong>.
                    </p>
                    <div>
                        <Button
                            label="Remove"
                            onClick={() => {
                                this.moveGroup({
                                    groupId: id,
                                    newTableId: 'remove-only'
                                });
                                handleModalRemoveGroup(false);
                            }}
                        />
                        <Button
                            label="Cancel"
                            onClick={() => handleModalRemoveGroup(false)}
                            variation="secondary"
                        />
                    </div>
                </div>
            );
        }
        return null;
    }

    renderModalMoveGroupConfirmation() {
        const { modalMoveGroupConfirmation } = this.state;
        const { active, reason, data, scheduledWork } = modalMoveGroupConfirmation;

        if (active && reason.length && data) {
            const { groupName, tableName, occupied, maxSeats } = data;

            const messages = [];

            if (reason.includes('over-occupied')) {
                messages.push(
                    <p>
                        Moving Group <strong style={{ whiteSpace: 'nowrap' }}>{groupName}</strong>{' '}
                        to Table <strong style={{ whiteSpace: 'nowrap' }}>{tableName}</strong> will
                        exceed Table occupancy by{' '}
                        <StatusBadge type="voided" text={occupied - maxSeats} /> seats ({occupied}/
                        {maxSeats}).
                    </p>
                );
            }

            if (reason.includes('shared-to-private-conflict')) {
                messages.push(
                    <p>
                        You are trying to add a non-private Group{' '}
                        <strong style={{ whiteSpace: 'nowrap' }}>{groupName}</strong> to a Table
                        with private groups{' '}
                        <strong style={{ whiteSpace: 'nowrap' }}>{tableName}</strong>.
                    </p>
                );
            }

            if (reason.includes('private-to-shared-conflict')) {
                messages.push(
                    <p>
                        You are trying to add a private Group{' '}
                        <strong style={{ whiteSpace: 'nowrap' }}>{groupName}</strong> to a Table
                        with non-private groups{' '}
                        <strong style={{ whiteSpace: 'nowrap' }}>{tableName}</strong>.
                    </p>
                );
            }

            return (
                <div className="group-modal-content">
                    {messages.map(message => message)}
                    <p>Are you sure you want to proceed?</p>
                    <div>
                        <Button
                            label="Move Group"
                            onClick={() => {
                                if (typeof scheduledWork === 'function') {
                                    scheduledWork();
                                }
                                this.handleModalMoveGroupConfirmation();
                            }}
                        />
                        <Button
                            label="Cancel"
                            onClick={() => this.handleModalMoveGroupConfirmation()}
                            variation="secondary"
                        />
                    </div>
                </div>
            );
        }

        return null;
    }

    renderModalSplitTable() {
        const { modalSplitTable, tableUpdating, invalid, pristine, formMeta } = this.props;
        // if (formMeta && formMeta.table_to_split_name && formMeta.table_to_split_name.visited) {
        //     touchField('table_to_split_name');
        // }
        const tableNameActive =
            formMeta && formMeta.table_to_split_name && formMeta.table_to_split_name.active;
        const primaryTable = this.getTableById(modalSplitTable);
        if (primaryTable) {
            return (
                <div>
                    <h2>{primaryTable.table_number}</h2>
                    <Form.Row>
                        {`Currenty you have ${
                            primaryTable.max_seats
                        } seats on this table. How do you want to split it?`}
                    </Form.Row>
                    <Form.Row>
                        Number of seats on primary table:
                        {primaryTable.max_seats - this.getFormValue('table_to_split_seats')}
                    </Form.Row>
                    <Form.Row>
                        <Field
                            name="table_to_split_seats"
                            id="table_to_split_seats"
                            fieldProps={{
                                label: 'Num. of seats to split to new table'
                            }}
                            // fieldAttr={{
                            //     required: true,
                            //     disabled: eventFetching || tablesListingFetching || groupsListingFetching
                            // }}
                            component={Text}
                            validate={valueValidation([
                                { validator: 'required' },
                                {
                                    validator: 'numericality',
                                    args: {
                                        '<=': primaryTable.max_seats - 1,
                                        msg: `Max. number of seats you can assign to the new table is ${primaryTable.max_seats -
                                            1} `
                                    }
                                },
                                {
                                    validator: 'numericality',
                                    args: {
                                        '>': 0,
                                        msg: 'Number of seats must be at least 1'
                                    }
                                }
                            ])}
                            fieldAttr={{ required: true }}
                        />
                    </Form.Row>
                    <Form.Row>
                        <Field
                            name="table_to_split_name"
                            id="table_to_split_name"
                            fieldProps={{
                                label: 'New Table name'
                            }}
                            // fieldAttr={{
                            //     required: true,
                            //     disabled: eventFetching || tablesListingFetching || groupsListingFetching
                            // }}
                            format={value => {
                                if (!value && !tableNameActive) {
                                    return `${primaryTable.table_number} / 2`;
                                }
                                return value;
                            }}
                            normalize={value => {
                                if (!value && !tableNameActive) {
                                    return `${primaryTable.table_number} / 2`;
                                }
                                return value;
                            }}
                            component={Text}
                        />
                    </Form.Row>

                    <Form.Row>
                        <Button
                            loading={tableUpdating}
                            label="Split Table"
                            onClick={() => this.splitTable(modalSplitTable)}
                            disabled={pristine || invalid}
                        />
                    </Form.Row>
                </div>
            );
        }
        return null;
    }

    renderModalTableNotes() {
        const { modalTableNotes, updateTable, handleModalTableNotes } = this.props;

        if (modalTableNotes !== null && modalTableNotes !== false) {
            const table = this.getTableById(modalTableNotes);

            if (table) {
                const eventIri = this.getCurrentEventIri();

                return (
                    <div className="group-modal-content">
                        <h4>{table.table_number}</h4>
                        <TextEditorClass
                            ref={this.modalTableNotes}
                            input={{
                                onChange: () => {},
                                onFocus: () => {},
                                onBlur: () => {}
                            }}
                            meta={{
                                initial: table.note ? table.note : ''
                            }}
                        />
                        <br />
                        <div>
                            <Button
                                label="Save"
                                onClick={() => {
                                    let content = '';
                                    if (
                                        this.modalTableNotes.current &&
                                        this.modalTableNotes.current.state
                                    ) {
                                        content = draftToHtml(
                                            convertToRaw(
                                                this.modalTableNotes.current.state.editorState.getCurrentContent()
                                            )
                                        );
                                    }
                                    if (
                                        content.replace(/(\r\n|\n|\r)/gm, '') === '' ||
                                        content.replace(/(\r\n|\n|\r)/gm, '') === '<p></p>'
                                    ) {
                                        content = null;
                                    }
                                    this.setState({ generalUpdateInProgress: true });
                                    updateTable(modalTableNotes, {
                                        note: content
                                    }).finally(() => {
                                        this.setState({ generalUpdateInProgress: false });
                                        const { fetchTables } = this.props;
                                        fetchTables({
                                            'filters[event.id][equals]': getIdFromIri(eventIri)
                                        });
                                    });
                                }}
                            />
                            <Button
                                label="Cancel"
                                onClick={() => handleModalTableNotes()}
                                variation="secondary"
                            />
                        </div>
                    </div>
                );
            }
        }

        return null;
    }

    renderCustomStyle() {
        return `
            .page-content {
                height: 100%;
                padding: 0;
            }
        `;
    }

    downloadCSVFile(file) {
        const { event } = this.props;
        this.setState({ fetchingCSV: true });

        const link = document.createElement('a');
        const CSVFile = new Blob([file], {
            type: 'text/csv;charset=utf-8'
        });
        link.href = URL.createObjectURL(CSVFile);
        link.download = `${event.name}.csv`;
        document.body.appendChild(link); // Required for this to work in FireFox
        link.click();
        setTimeout(() => {
            this.setState({ fetchingCSV: false });
        }, 500);
    }

    exportAllotment() {
        const { dispatch, match } = this.props;

        const id = match && match.params && match.params.eventId;

        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: tablesActions.START_EXPORTING_ALLOTMENT_CSV,
                id
            });
        })
            .then(file => {
                this.downloadCSVFile(file);
            })
            .catch(() => {
                this.setState({ fetchingCSV: false });
                dispatch({
                    type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                    response: { code: 'error500' }
                });
            });
    }

    render() {
        const {
            error,
            eventFetching,
            // tableUpdating,
            tablesListingFetching,
            groupsListingFetching,
            handleModalRemoveGroup,
            handleModalGroupInfo,
            handleModalTableNotes,
            modalRemoveGroup,
            modalGroupInfo,
            modalSplitTable,
            modalTableNotes
        } = this.props;

        const {
            eventDropdownOptions,
            eventDropdownOptionsFetching,
            tablesData,
            groupsData,
            eventNoTables,
            eventNoGroups,
            generalUpdateInProgress,
            modalMoveGroupConfirmation,
            printLoading,
            fetchingCSV
        } = this.state;

        const eventIri = this.getCurrentEventIri();

        return (
            <LayoutManager slot="main" className="main--table-allotments" layoutType="merge">
                <style>{this.renderCustomStyle()}</style>

                <PageHeader
                    title={
                        <FormattedMessage
                            id="TableAllotments.title"
                            defaultMessage="Table Allotments"
                        />
                    }
                >
                    {!!document.fullscreenEnabled && (
                        <React.Fragment>
                            <Button
                                className="table-allotments-fullscreen-button table-allotments-fullscreen-button--on"
                                onClick={() => document.body.requestFullscreen()}
                                variation="secondary"
                                label={
                                    <FormattedMessage
                                        id="TableAllotments.goFullscreen"
                                        defaultMessage="Go Fullscreen"
                                    />
                                }
                            />
                            <Button
                                className="table-allotments-fullscreen-button table-allotments-fullscreen-button--off"
                                onClick={() => document.exitFullscreen()}
                                variation="secondary"
                                label={
                                    <FormattedMessage
                                        id="TableAllotments.exitFullscreen"
                                        defaultMessage="Exit Fullscreen"
                                    />
                                }
                            />
                        </React.Fragment>
                    )}
                </PageHeader>
                {(!!eventFetching ||
                    !!generalUpdateInProgress ||
                    !!tablesListingFetching ||
                    !!groupsListingFetching) && <PageLoader />}
                <PageContent>
                    {error && error.code && <NotificationManager code={error.code} />}
                    <div
                        id="table-allotments"
                        className="table-allotments"
                        style={this.getTableAllotmentsStyle()}
                    >
                        <div className="table-allotments__event">
                            {eventDropdownOptionsFetching && <ElementLoader overlay />}
                            <Select
                                name="event"
                                id="event"
                                input={{
                                    onChange: value => {
                                        this.changeEventUrl(getIdFromIri(value));
                                    },
                                    onBlur: () => {},
                                    value: eventIri || null
                                }}
                                fieldProps={{
                                    label: 'Choose Event',
                                    options: eventDropdownOptions,
                                    clearable: false
                                }}
                                fieldAttr={{
                                    required: true,
                                    disabled:
                                        eventDropdownOptionsFetching ||
                                        eventFetching ||
                                        tablesListingFetching ||
                                        groupsListingFetching
                                }}
                                validate={valueValidation([{ validator: 'required' }])}
                            />
                        </div>

                        {eventIri && !!tablesData && !!tablesData.length && (
                            <div className="table-allotments__gallery">
                                <FloorPlans eventId={getIdFromIri(eventIri)} />
                            </div>
                        )}

                        {this.renderTotals()}

                        {eventIri && !!tablesData && !!tablesData.length && (
                            <div className="table-allotments__print-button">
                                {printLoading && <PageLoader />}
                                <ButtonDropdown
                                    // size="small"
                                    disabled={printLoading}
                                    placeholder="Print table card"
                                    options={[
                                        {
                                            id: 'print-1',
                                            label: 'Table card 4x6 inch',
                                            onClick: () =>
                                                this.printPdf('small', eventIri && eventIri)
                                        },
                                        {
                                            id: 'print-2',
                                            label: 'Table card 8.5x11 inch',
                                            onClick: () =>
                                                this.printPdf('large', eventIri && eventIri)
                                        },
                                        {
                                            id: 'print-3',
                                            label: 'Table card 2x3.5 inch',
                                            onClick: () =>
                                                this.printPdf('smallest', eventIri && eventIri)
                                        }
                                    ]}
                                />
                                <ButtonDropdown
                                    placeholder="Print Guest List"
                                    options={[
                                        {
                                            id: 'print-guest-list-table',
                                            label: 'Print Guest List by Table',
                                            onClick: () =>
                                                this.openNewTab(
                                                    `/table-allotments/${getIdFromIri(
                                                        eventIri
                                                    )}/print-by-table`
                                                )
                                        },
                                        {
                                            id: 'print-guest-list-customer',
                                            label: 'Print Guest List by Name',
                                            onClick: () =>
                                                this.openNewTab(
                                                    `/table-allotments/${getIdFromIri(
                                                        eventIri
                                                    )}/print-by-name`
                                                )
                                        }
                                    ]}
                                />
                                <Button
                                    variation="secondary"
                                    label="Download .CSV"
                                    iconName="download"
                                    onClick={() => this.exportAllotment()}
                                    loading={fetchingCSV}
                                />
                            </div>
                        )}

                        <TableFilters tablesData={tablesData} disabled={tablesListingFetching} />

                        <DragDropContext onDragEnd={this.onDragEnd}>
                            <TableList
                                eventId={getIdFromIri(eventIri)}
                                tablesData={tablesData}
                                eventNoTables={eventNoTables}
                                getGroupById={this.getGroupById}
                                resetFilters={this.resetFilters}
                                filterTablesSearch={this.getFormValue('filterTablesSearch')}
                                filterTablesRoom={this.getFormValue('filterTablesRoom')}
                                filterTablesSortBy={this.getFormValue('filterTablesSortBy')}
                                filterTablesAvailable={this.getFormValue('filterTablesAvailable')}
                                filterTablesShared={this.getFormValue('filterTablesShared')}
                            />
                            <GroupsFooter
                                groupsData={groupsData}
                                eventNoGroups={eventNoGroups}
                                groupsListingFetching={groupsListingFetching}
                                resetFilters={this.resetFilters}
                                getTableById={this.getTableById}
                                filterGroupsIdSearch={this.getFormValue('filterGroupsIdSearch')}
                                filterGroupsOrderSearch={this.getFormValue(
                                    'filterGroupsOrderSearch'
                                )}
                                filterGroupsCompletion={this.getFormValue('filterGroupsCompletion')}
                                filterGroupsVip={this.getFormValue('filterGroupsVip')}
                                filterGroupsSeated={this.getFormValue('filterGroupsSeated')}
                            />
                        </DragDropContext>

                        <Modal
                            root="body"
                            variation="small"
                            onClose={() => handleModalRemoveGroup(false)}
                            opened={!!modalRemoveGroup || modalRemoveGroup === 0}
                            title="Remove Group"
                        >
                            <React.Fragment>
                                {this.renderModalRemoveGroupContent(modalRemoveGroup)}
                            </React.Fragment>
                        </Modal>

                        <Modal
                            root="body"
                            onClose={() => handleModalGroupInfo(false)}
                            opened={!!modalGroupInfo || modalGroupInfo === 0}
                            title="Group Details"
                        >
                            {this.renderModalGroupInfo(modalGroupInfo)}
                        </Modal>

                        <Modal
                            root="body"
                            variation="small"
                            onClose={() => this.handleModalMoveGroupConfirmation()}
                            opened={modalMoveGroupConfirmation.active}
                            title="Confirmation required"
                        >
                            {this.renderModalMoveGroupConfirmation()}
                        </Modal>

                        <Modal
                            root="body"
                            onClose={() => handleModalTableNotes()}
                            opened={!!modalTableNotes || modalTableNotes === 0}
                            title="Table Note"
                        >
                            {this.renderModalTableNotes()}
                        </Modal>

                        {(!!modalSplitTable || modalSplitTable === 0) && (
                            <Modal
                                root="body"
                                variation="small"
                                onClose={() => this.handleModalSplitTableHelper(false)}
                                opened={!!modalSplitTable || modalSplitTable === 0}
                                title="Split Table"
                            >
                                <React.Fragment>
                                    {this.renderModalSplitTable(modalSplitTable)}
                                </React.Fragment>
                            </Modal>
                        )}
                    </div>
                </PageContent>
            </LayoutManager>
        );
    }
}

TableAllotments.defaultProps = {
    history: null,
    error: null,
    formValues: null,
    match: null,
    event: null,
    eventFetching: false,
    tablesListingFetching: false,
    tablesData: {},
    groupsListingFetching: false,
    groupsData: {},
    tableUpdating: false,
    // tableUpdating: false,
    initialize: () => {},
    setFormValue: () => {},
    fetchEventData: () => {},
    updateTable: () => {},
    fetchTables: () => {},
    fetchGroups: () => {},
    handleModalRemoveGroup: () => {},
    handleModalGroupInfo: () => {},
    handleModalTableNotes: () => {},
    modalRemoveGroup: false,
    modalGroupInfo: false,
    footerElementSize: null,
    handleModalSplitTable: () => {},
    modalSplitTable: false,
    createTable: () => {},
    modalTableNotes: false,
    pristine: false,
    invalid: false,
    formMeta: {},
    untouchField: () => {}
};

TableAllotments.propTypes = {
    history: PropTypes.any, // eslint-disable-line react/forbid-prop-types
    dispatch: PropTypes.func.isRequired,
    error: PropTypes.oneOfType([PropTypes.object]),
    formValues: PropTypes.oneOfType([PropTypes.object]),
    match: PropTypes.oneOfType([PropTypes.object]),
    event: PropTypes.oneOfType([PropTypes.object]),
    eventFetching: PropTypes.bool,
    tablesListingFetching: PropTypes.bool,
    tablesData: PropTypes.oneOfType([PropTypes.object]),
    groupsListingFetching: PropTypes.bool,
    groupsData: PropTypes.oneOfType([PropTypes.object]),
    tableUpdating: PropTypes.bool,
    // tableUpdating: PropTypes.bool,
    initialize: PropTypes.func,
    setFormValue: PropTypes.func,
    fetchEventData: PropTypes.func,
    updateTable: PropTypes.func,
    fetchTables: PropTypes.func,
    fetchGroups: PropTypes.func,
    handleModalRemoveGroup: PropTypes.func,
    handleModalGroupInfo: PropTypes.func,
    handleModalTableNotes: PropTypes.func,
    modalRemoveGroup: PropTypes.bool,
    modalGroupInfo: PropTypes.bool,
    footerElementSize: PropTypes.oneOfType([PropTypes.object]),
    handleModalSplitTable: PropTypes.func,
    modalSplitTable: PropTypes.bool,
    createTable: PropTypes.func,
    modalTableNotes: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number]),
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    formMeta: PropTypes.oneOfType([PropTypes.object]),
    untouchField: PropTypes.func
};

TableAllotments = reduxForm({
    form: 'TableAllotmentsForm',
    pure: true,
    enableReinitialize: true,
    // defining initial values / reset button will reset to this values
    initialValues: { ...initialTableFilterValues, ...initialGroupFilterValues }
})(TableAllotments);

const mapStateToProps = (state, ownProps) => ({
    formValues: getFormValues('TableAllotmentsForm')(state),
    event: getEventData(state, ownProps.match.params.eventId),
    eventFetching: getSingleEventFetching(state),
    tablesListingFetching: getListingFetching(state, 'tables'),
    tablesData: getListingResponse(state, 'tables'),
    groupsListingFetching: getListingFetching(state, 'groups'),
    groupsData: getListingResponse(state, 'groups'),
    tableUpdating: getSingleTablesUpdating(state, 'groups'),
    modalRemoveGroup: getModalRemoveGroup(state),
    modalGroupInfo: getModalGroupInfo(state),
    footerElementSize: getFooterElementSize(state),
    modalSplitTable: getModalSplitTable(state),
    modalTableNotes: getModalTableNotes(state),
    formMeta: getFormMeta('TableAllotmentsForm')(state)
});

const mapDispatchToProps = dispatch => ({
    setFormValue: (field, value) => dispatch(change('TableAllotmentsForm', field, value)),
    fetchEventData: id => {
        // Getting included data from API by setting params
        const params = {};
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: eventsActions.START_FETCHING_SINGLE_EVENT,
                id,
                params
            });
        }).catch(error => ({ error }));
    },
    createTable: formData => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: tablesActions.START_CREATE_TABLE_DND,
                formData
            });
        }).catch(error => ({ error }));
    },
    updateTable: (id, formData) => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: tablesActions.REQUEST_UPDATE_SINGLE_TABLE_DND,
                id,
                formData
            });
        }).catch(error => ({ error }));
    },
    fetchTables: params => {
        // Getting included data from API by setting defaultParams
        const defaultParams = {
            pagination: false,
            include: 'room,groups,event,events',
            'order_by[table_number]': 'ASC'
        };
        const listingParams = Object.assign({}, defaultParams, params);
        dispatch({
            type: listingActions.START_FETCHING_LISTING,
            params: listingParams,
            entity: 'TABLES_DND',
            endpoint: 'api/tables'
        });
    },
    fetchGroups: params => {
        // Getting included data from API by setting defaultParams
        const defaultParams = {
            pagination: false,
            'filters[has_children][equals]': false,
            include: 'events,event,event.name,ticketTypes,orders,orders.tables,tables,table'
        };
        const listingParams = Object.assign({}, defaultParams, params);
        dispatch({
            type: listingActions.START_FETCHING_LISTING,
            params: listingParams,
            entity: 'GROUPS',
            endpoint: 'api/groups'
        });
    },
    handleModalRemoveGroup: index =>
        dispatch({
            type: tablesActions.TOGGLE_TABLE_MODAL_REMOVE_GROUP,
            modalIndex: index
        }),
    handleModalGroupInfo: index =>
        dispatch({
            type: tablesActions.TOGGLE_TABLE_MODAL_GROUP_INFO,
            modalIndex: index
        }),
    handleModalSplitTable: index =>
        dispatch({
            type: tablesActions.TOGGLE_TABLE_MODAL_SPLIT_TABLE,
            modalIndex: index
        }),
    handleModalTableNotes: index =>
        dispatch({
            type: tablesActions.TOGGLE_TABLE_MODAL_NOTES,
            modalIndex: index
        }),
    untouchField: field => dispatch(untouch('TableAllotmentsForm', field))
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(TableAllotments)
);
