import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { dtoForm } from 'erputils/dto';
import { diff } from 'deep-object-diff';
import { FormattedMessage } from 'react-intl';
import LayoutManager from 'erputils/LayoutManager';
import PageHeader from 'erpcomponents/Layout/PageHeader';
import PageContent from 'erpcomponents/Layout/PageContent';
import ResellerInfo from 'erpcore/screens/Resellers/components/ResellerInfo';
import ResellerEditTabs from 'erpcore/screens/Resellers/components/ResellerEditTabs';
import PageLoader from 'erpcomponents/PageLoader';
import { actions as resellersActions } from 'erpcore/screens/Resellers/Resellers.reducer';
import {
    getResellerData,
    getSingleResellerFetching
} from 'erpcore/screens/Resellers/Resellers.selectors';
import { Field, reduxForm } from 'redux-form';
import Form, { Autocomplete } from 'erpcore/components/Form';
// import valueValidation from 'erputils/valueValidation';
import Button from 'erpcomponents/Button';
import EventDescription from 'erpcore/screens/Resellers/components/EventDescription';
import UpdateEventDescription from 'erpcore/screens/Resellers/components/UpdateEventDescription';

class SEO extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reseller: null,
            event: null
        };
        this.onSubmit = this.onSubmit.bind(this);
        this.handleAddDescription = this.handleAddDescription.bind(this);
        this.resetReseller = this.resetReseller.bind(this);
    }

    // componentDidMount() {
    //     const { fetchResellerData, fetchEventsData } = this.props;
    //     fetchResellerData();
    //     fetchEventsData();
    // }

    onSubmit(formData) {
        const { match, dispatch, initialValues } = this.props;
        // send only changed data
        formData = diff(initialValues, formData);

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: resellersActions.START_UPDATE_SINGLE_RESELLER_SEO,
                id: match.params.id,
                formData
            })
        ).catch(error => {
            return error;
        });
    }

    handleAddDescription(formData) {
        this.setState({ reseller: formData, event: formData && formData.event_description });
    }

    resetReseller() {
        this.setState({ reseller: null });
    }

    render() {
        const {
            handleSubmit,
            submitting,
            pristine,
            invalid,
            singleFetching,
            match,
            initialValues,
            fetchResellerData
        } = this.props;
        const { reseller, event } = this.state;
        const eventDescriptionIris = initialValues && initialValues.event_descriptions;
        const eventDescriptions =
            eventDescriptionIris &&
            initialValues.included.filter(el => eventDescriptionIris.includes(el.iri));
        const chosenEvent =
            initialValues &&
            initialValues.included &&
            initialValues.included.find(record => record.iri === event);
        return (
            <LayoutManager slot="main" className="main--narrow" layoutType="merge">
                <PageHeader
                    title={
                        <FormattedMessage id="Reseller.SEO.title" defaultMessage="Edit Reseller" />
                    }
                    backButtonLink="/resellers"
                />
                {singleFetching === true && <PageLoader />}
                <ResellerInfo fetchResellerData={fetchResellerData} />
                <ResellerEditTabs />

                <PageContent>
                    <Form onSubmit={handleSubmit(this.handleAddDescription)}>
                        <Form.SectionTitle>
                            <FormattedMessage
                                id="Reseller.SEO.AddCustomDescription"
                                defaultMessage="Add custom description for every event"
                            />
                        </Form.SectionTitle>
                        <Form.Row>
                            <Form.Columns breakOnMobile>
                                <Form.Column>
                                    <Field
                                        name="event_description"
                                        id="event_description"
                                        fieldProps={{
                                            label: (
                                                <FormattedMessage
                                                    id="Reseller.SEO.AddDesc"
                                                    defaultMessage="Add Custom Description"
                                                />
                                            ),
                                            options: {
                                                endpoint: `api/resellers/${match.params.id}/events`,
                                                mapData: {
                                                    label: 'name',
                                                    value: 'iri'
                                                }
                                            },
                                            clearable: true
                                        }}
                                        component={Autocomplete}
                                    />
                                </Form.Column>
                                <Form.Column>
                                    <Button
                                        onClick={() => this.handleAddDescription()}
                                        submit
                                        disabled={pristine || invalid}
                                        loading={submitting}
                                        label={
                                            <FormattedMessage
                                                id="Reseller.SEO.AddDesc"
                                                defaultMessage="Add Custom Description"
                                            />
                                        }
                                    />
                                </Form.Column>
                            </Form.Columns>
                        </Form.Row>
                    </Form>
                    <Form.Row>
                        {reseller && (
                            <EventDescription
                                fetchResellerData={fetchResellerData}
                                reseller={reseller}
                                event={chosenEvent}
                            />
                        )}
                    </Form.Row>
                    {eventDescriptions &&
                        eventDescriptions.map(desc => (
                            <Form.Row key={desc.id}>
                                <UpdateEventDescription
                                    form={`UpdateEventDescriptionForm${desc.id}`}
                                    event={desc}
                                    fetchResellerData={fetchResellerData}
                                />
                            </Form.Row>
                        ))}
                </PageContent>
            </LayoutManager>
        );
    }
}

SEO.defaultProps = {
    submitting: false,
    pristine: false,
    invalid: false,
    fetchResellerData: () => {},
    // fetchEventsData: () => {},
    singleFetching: false,
    initialValues: {}
};

SEO.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.oneOfType([PropTypes.object]).isRequired,
    initialValues: PropTypes.oneOfType([PropTypes.object]),
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    fetchResellerData: PropTypes.func,
    // fetchEventsData: PropTypes.func,
    singleFetching: PropTypes.bool
};

SEO = reduxForm({
    form: 'SEOForm',
    enableReinitialize: false
})(SEO);

//  Getting initial value populated in the form from the store
const mapStateToProps = (state, ownProps) => ({
    initialValues: dtoForm(getResellerData(state, ownProps.match.params.id)),
    singleFetching: getSingleResellerFetching(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchResellerData: () => {
        // Getting included data from API by setting params
        const params = {
            include:
                'company,resellerPrimaryAccount,event,eventsIncluded,eventsExcluded,eventDescriptions,eventDescriptions.event'
        };
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: resellersActions.START_FETCHING_SINGLE_RESELLER,
                id: ownProps.match.params.id,
                params
            });
        }).catch(error => ({ error }));
    }
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(SEO)
);
