import Sidebar from 'erpcomponents/Layout/Sidebar';
import MainNavigation from 'erpcomponents/Layout/MainNavigation';
import Main from 'erpcomponents/Layout/Main';

const defaultLayout = {
    data: [
        {
            slotName: 'leftSidebar',
            component: Sidebar,
            position: 0,
            content: {
                data: [
                    {
                        slotName: 'mainNavigation',
                        component: MainNavigation,
                        position: 1
                    }
                ]
            }
        },
        {
            slotName: 'main',
            component: Main,
            position: 0
        }
    ]
};

export default defaultLayout;
