import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import reduxFormErrorMapper from 'erputils/reduxFormErrorMapper';
import { Field, reduxForm, SubmissionError, reset } from 'redux-form';
import Form, { Text, DateTime, Autocomplete, TextEditor } from 'erpcore/components/Form';
import valueValidation from 'erputils/valueValidation';
import LayoutManager from 'erputils/LayoutManager';
import PageHeader from 'erpcomponents/Layout/PageHeader';
import PageContent from 'erpcomponents/Layout/PageContent';
import Button from 'erpcomponents/Button';
import { actions as eventsActions } from 'erpcore/screens/Events/Events.reducer';

class EventCreate extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // minDate: new Date(),
            startDate: new Date()
        };

        this.handleChangeStart = this.handleChangeStart.bind(this);
    }

    handleChangeStart(value) {
        return this.setState({
            startDate: value
        });
    }

    render() {
        const { handleSubmit, submitting, pristine, invalid, onSubmit } = this.props;
        const { startDate } = this.state;

        return (
            <LayoutManager slot="main" className="main--narrow" layoutType="merge">
                <PageHeader
                    title={
                        <FormattedMessage id="EventCreate.title" defaultMessage="Create Event" />
                    }
                    backButtonLink="/events"
                />
                <PageContent>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Column>
                            <Form.SectionTitle>
                                <FormattedMessage
                                    id="EventCreate.SectionTitle.EventDetails"
                                    defaultMessage="Event Details"
                                />
                            </Form.SectionTitle>
                            <Form.Row>
                                <Field
                                    name="name"
                                    id="name"
                                    fieldProps={{
                                        label: (
                                            <FormattedMessage
                                                id="EventCreate.EventName"
                                                defaultMessage="Event Name"
                                            />
                                        ),
                                        clearable: true
                                    }}
                                    component={Text}
                                    validate={valueValidation([{ validator: 'required' }])}
                                    fieldAttr={{ required: true }}
                                />
                            </Form.Row>
                            <Form.Row>
                                <Form.Columns breakOnMobile>
                                    <Form.Column>
                                        <Field
                                            name="start"
                                            id="start"
                                            fieldProps={{
                                                label: (
                                                    <FormattedMessage
                                                        id="EventCreate.Starts"
                                                        defaultMessage="Starts"
                                                    />
                                                ),
                                                // minDate,
                                                showTimeSelect: true,
                                                clearable: true,
                                                icon: 'date',
                                                iconPosition: 'right'
                                            }}
                                            onChange={this.handleChangeStart}
                                            component={DateTime}
                                            validate={valueValidation([{ validator: 'required' }])}
                                            fieldAttr={{ required: true }}
                                        />
                                    </Form.Column>
                                    <Form.Column>
                                        <Field
                                            name="end"
                                            id="end"
                                            fieldProps={{
                                                label: (
                                                    <FormattedMessage
                                                        id="EventCreate.Ends"
                                                        defaultMessage="Ends"
                                                    />
                                                ),
                                                showTimeSelect: true,
                                                startDate,
                                                minDate: startDate,
                                                clearable: true,
                                                icon: 'date',
                                                iconPosition: 'right'
                                            }}
                                            component={DateTime}
                                            validate={valueValidation([{ validator: 'required' }])}
                                            fieldAttr={{ required: true }}
                                        />
                                    </Form.Column>
                                </Form.Columns>
                            </Form.Row>
                            <Form.Row>
                                <Field
                                    name="timezone"
                                    id="timezone"
                                    fieldProps={{
                                        label: (
                                            <FormattedMessage
                                                id="EventCreate.Details.Timezone"
                                                defaultMessage="Timezone"
                                            />
                                        ),
                                        options: {
                                            endpoint: '/api/timezones?pagination=false',
                                            mapData: {
                                                value: 'iri',
                                                label: 'title'
                                            }
                                        },
                                        clearable: true
                                    }}
                                    fieldAttr={{ required: true }}
                                    component={Autocomplete}
                                    validate={valueValidation([{ validator: 'required' }])}
                                />
                            </Form.Row>
                            <Form.Row>
                                <Field
                                    name="max_attendance"
                                    id="max_attendance"
                                    fieldProps={{
                                        label: (
                                            <FormattedMessage
                                                id="EventCreate.MaxAttendance"
                                                defaultMessage="Maximum Attendance"
                                            />
                                        ),
                                        clearable: true
                                    }}
                                    component={Text}
                                    validate={valueValidation([
                                        { validator: 'required' },
                                        { validator: 'numericality' }
                                    ])}
                                    fieldAttr={{ required: true }}
                                />
                            </Form.Row>
                            <Form.SectionTitle>
                                <FormattedMessage
                                    id="EventCreate.SectionTitle.Location"
                                    defaultMessage="Location"
                                />
                            </Form.SectionTitle>
                            <Form.Row>
                                <Field
                                    name="location"
                                    id="location"
                                    fieldProps={{
                                        label: (
                                            <FormattedMessage
                                                id="EventCreate.VenueName"
                                                defaultMessage="Venue Name"
                                            />
                                        ),
                                        options: {
                                            endpoint: '/api/locations',
                                            mapData: {
                                                value: 'iri',
                                                label: 'venue_name'
                                            }
                                        },
                                        clearable: true
                                    }}
                                    component={Autocomplete}
                                    validate={valueValidation([{ validator: 'required' }])}
                                    fieldAttr={{ required: true }}
                                />
                            </Form.Row>
                            <Form.SectionTitle>
                                <FormattedMessage
                                    id="EventCreate.EventDescription"
                                    defaultMessage="Event Description"
                                />
                            </Form.SectionTitle>
                            <Form.Row>
                                <Field
                                    name="description"
                                    id="description"
                                    fieldProps={{
                                        clearable: true
                                    }}
                                    component={TextEditor}
                                />
                            </Form.Row>
                            <Form.Row>
                                <Button
                                    submit
                                    disabled={pristine || invalid}
                                    loading={submitting}
                                    label={
                                        <FormattedMessage
                                            id="EventCreate.Create"
                                            defaultMessage="Create"
                                        />
                                    }
                                />
                            </Form.Row>
                        </Form.Column>
                    </Form>
                </PageContent>
            </LayoutManager>
        );
    }
}

EventCreate.defaultProps = {
    submitting: false,
    pristine: false,
    invalid: false,
    onSubmit: () => {}
};

EventCreate.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool
};

EventCreate = reduxForm({
    form: 'EventCreateForm',
    enableReinitialize: true
})(EventCreate);

const mapDispatchToProps = dispatch => ({
    onSubmit: formData => {
        if (formData.max_attendance) {
            const maxAttendance = parseInt(formData.max_attendance, 10);
            formData.max_attendance = maxAttendance;
        }
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: eventsActions.START_CREATE_EVENT,
                formData: { ...formData, status: 'Offline' }
            });
        })
            .then(() => {
                dispatch(reset('EventCreateForm'));
            })
            .catch(error => {
                throw new SubmissionError(reduxFormErrorMapper(error));
            });
    }
});

export default connect(
    null,
    mapDispatchToProps
)(EventCreate);
