import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
// import reduxFormErrorMapper from 'erputils/reduxFormErrorMapper';
import { Field, reduxForm } from 'redux-form';
import Form, { Text, Autocomplete } from 'erpcore/components/Form';
import valueValidation from 'erputils/valueValidation';
import Modal from 'erpcomponents/Modal';
import Button from 'erpcomponents/Button';
import { actions as groupsActions } from 'erpcore/screens/Groups/Groups.reducer';

class GroupMergeModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            opened: true
        };
        this.handleModal = this.handleModal.bind(this);
    }

    onSubmit = formData => {
        const { dispatch, refreshData } = this.props;
        const data = {
            to_merge_groups: [formData.iri],
            primary_group: formData.group_to_merge
        };
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: groupsActions.START_MERGE_GROUPS,
                formData: data
            })
        )
            .then(() => {
                refreshData();
                this.handleModal();
            })
            .catch(error => {
                return error;
            });
    };

    handleModal() {
        const { opened: isOpen } = this.state;
        this.setState({ opened: !isOpen });
    }

    render() {
        const { handleSubmit, submitting, pristine, invalid, eventId, initialValues } = this.props;
        const { opened } = this.state;
        return (
            <Modal
                title={`Merge group ${initialValues && initialValues.name}`}
                opened={opened}
                onClose={() => this.handleModal()}
            >
                <p>Choose another group to merge this group with. </p>
                <Form onSubmit={handleSubmit(this.onSubmit)}>
                    <Form.Column>
                        <Form.Row>
                            <Field
                                name="primary_group_name"
                                id="primary_group_name"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="Groups.Merge.Primary"
                                            defaultMessage="Primary Group"
                                        />
                                    ),
                                    clearable: true
                                }}
                                component={Text}
                                validate={valueValidation([{ validator: 'required' }])}
                                fieldAttr={{ disabled: true }}
                            />
                        </Form.Row>
                        <Form.Row>
                            <Field
                                name="group_to_merge"
                                id="group_to_merge"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="Groups.MergeText"
                                            defaultMessage="Group To Merge"
                                        />
                                    ),
                                    options: {
                                        endpoint: `api/groups?include=event&filters[event.id][equals]=${eventId}&filters[id][not_equals]=${initialValues &&
                                            initialValues.id}`,
                                        mapData: {
                                            label: 'name',
                                            value: 'iri'
                                        }
                                    }
                                }}
                                component={Autocomplete}
                                validate={valueValidation([{ validator: 'required' }])}
                            />
                        </Form.Row>
                        <Form.Columns>
                            <Form.Column autoWidth>
                                <Button
                                    submit
                                    disabled={pristine || invalid}
                                    loading={submitting}
                                    label={
                                        <FormattedMessage
                                            id="Groups.MergeButton"
                                            defaultMessage="Merge"
                                        />
                                    }
                                />
                            </Form.Column>
                            <Form.Column autoWidth>
                                <Button
                                    onClick={() => this.handleModal()}
                                    variation="secondary"
                                    label={
                                        <FormattedMessage
                                            id="Groups.CancelButton"
                                            defaultMessage="Cancel"
                                        />
                                    }
                                />
                            </Form.Column>
                        </Form.Columns>
                    </Form.Column>
                </Form>
            </Modal>
        );
    }
}

GroupMergeModal.defaultProps = {
    submitting: false,
    pristine: false,
    invalid: false,
    // fetchEventData: () => {},
    initialValues: null,
    // member: '',
    eventId: '',
    refreshData: () => {}
};

GroupMergeModal.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    // fetchEventData: PropTypes.func,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    initialValues: PropTypes.oneOfType([PropTypes.object]),
    // match: PropTypes.oneOfType([PropTypes.object]).isRequired
    // member: PropTypes.string,
    eventId: PropTypes.string,
    refreshData: PropTypes.func
};

GroupMergeModal = reduxForm({
    form: 'GroupMergeModalForm',
    enableReinitialize: true
})(GroupMergeModal);

const mapDispatchToProps = () => ({
    // fetchEventData: () => {
    //     // Getting included data from API by setting params
    //     const params = {
    //         include: 'ticketTypes'
    //     };
    //     return new Promise((resolve, reject) => {
    //         dispatch({
    //             promise: { resolve, reject },
    //             type: eventsActions.START_FETCHING_SINGLE_EVENT,
    //             id: ownProps.match.params.id,
    //             params
    //         });
    //     }).catch(error => ({ error }));
    // }
});

export default withRouter(
    connect(
        null,
        mapDispatchToProps
    )(GroupMergeModal)
);
