import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues, change } from 'redux-form';
import { FormattedMessage } from 'react-intl';

import { HorizontalBarChart } from 'erpcomponents/WidgetCharts';
import ElementLoader from 'erpcomponents/ElementLoader';
import { Select } from 'erpcore/components/Form';
import Widget from 'erpcomponents/Widget';
import Button from 'erpcomponents/Button';

import './TotalSalesByEventBar.scss';

import { actions as totalSalesByEventBar } from 'erpcomponents/Widgets/TotalSalesByEventBar/TotalSalesByEventBar.reducer';
import {
    getData,
    getFetching
} from 'erpcomponents/Widgets/TotalSalesByEventBar/TotalSalesByEventBar.selectors';
import { chartColors } from 'erpdata/colors';
import { kFormatter } from 'erputils/utils';

class TotalSalesByEventBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewBy: 'attendance'
        };
    }

    componentDidMount() {
        const { overallSalesFormValues, setFormValue } = this.props;

        setFormValue('filter', overallSalesFormValues?.filter);

        return this.fetchData();
    }

    componentDidUpdate(prevProps) {
        const { overallSalesFormValues, setFormValue, filterValues } = this.props;
        const { overallSalesFormValues: prevOverallSalesFormValues } = prevProps;

        if (filterValues.filter !== prevProps.filterValues.filter) {
            setFormValue('filter', filterValues.filter);
            return this.fetchData();
        }

        if (prevOverallSalesFormValues !== overallSalesFormValues) {
            setFormValue('filter', overallSalesFormValues.filter);
            return this.fetchData();
        }

        return true;
    }

    fetchData() {
        const { dispatch, id, eventID, filterValues, overallSalesFormValues } = this.props;

        const params = {
            display_by: 'event'
        };

        const { filter: dateFilter } = filterValues;
        const { soldByFilter, eventSeriesFilter } = overallSalesFormValues;

        if (dateFilter && dateFilter !== Infinity) {
            const now = new Date();
            const startDate = new Date(now.getTime() - dateFilter * 24 * 60 * 60 * 1000);
            params['filters[start_date]'] = startDate;
            params['filters[end_date]'] = now;
        }

        if (eventSeriesFilter) {
            params['filters[event_series]'] = eventSeriesFilter;
        }

        if (eventID) {
            params['filters[events]'] = eventID;
        }

        if (soldByFilter) {
            params['filters[sold_by]'] = soldByFilter;
        }

        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: totalSalesByEventBar.START_FETCHING_TOTALSALESBYEVENTBAR_DATA,
                widgetId: id,
                params
            });
        }).catch(error => ({ error }));
    }

    changeView(view) {
        this.setState({
            viewBy: view
        });
    }

    formatWidgetData() {
        const { data: initialData } = this.props;

        const widgetData = {
            barChart: {
                data: {
                    labels: [],
                    datasets: [
                        {
                            label: 'Attendance',
                            fill: false,
                            lineTension: 0.1,
                            backgroundColor: chartColors[0],
                            borderColor: chartColors[0],
                            borderCapStyle: 'butt',
                            borderDash: [],
                            borderDashOffset: 0.0,
                            borderJoinStyle: 'miter',
                            pointBorderColor: chartColors[0],
                            pointBackgroundColor: '#fff',
                            pointBorderWidth: 1,
                            pointHoverRadius: 5,
                            pointHoverBackgroundColor: chartColors[0],
                            pointHoverBorderColor: 'rgba(220,220,220,1)',
                            pointHoverBorderWidth: 2,
                            pointRadius: 1,
                            pointHitRadius: 10,
                            data: [],
                            tooltipData: []
                        }
                    ]
                },
                options: {
                    legend: false,
                    maintainAspectRatio: false,
                    scales: {
                        xAxes: [
                            {
                                type: 'linear',
                                display: true,
                                position: 'left',
                                labels: {
                                    show: true
                                },
                                ticks: {
                                    beginAtZero: true,
                                    callback: value => kFormatter(value)
                                }
                            }
                        ]
                    }
                }
            }
        };

        // barChart
        // Create object with event names
        let events = {};
        if (initialData && initialData.eventsData) {
            initialData.eventsData.map(event => {
                events = { ...events, ...{ [event.id]: event.attributes.name } };
                return events;
            });
        }
        // Prepare chart data
        if (initialData && initialData.data) {
            Object.keys(initialData.data).map(key => {
                widgetData.barChart.data.datasets[0].data.push(initialData.data[key].attendance);
                if (events && events[key]) {
                    widgetData.barChart.data.labels.push(events[key]);
                } else {
                    widgetData.barChart.data.labels.push(key);
                }
                // tooltip
                const itemRevenueCurrency = initialData.data[key].revenue_currency
                    ? initialData.data[key].revenue_currency
                    : '';
                const itemRevenue = initialData.data[key].revenue
                    ? Math.round(initialData.data[key].revenue / 100)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    : 0;
                widgetData.barChart.data.datasets[0].tooltipData.push({
                    ticketsSold: initialData.data[key].attendance
                        ? initialData.data[key].attendance
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        : 0,
                    revenue: `${itemRevenueCurrency} ${itemRevenue}`,
                    attendance: initialData.data[key].attendance
                        ? initialData.data[key].attendance
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        : 0
                });

                return widgetData;
            });
        }

        // Prepare tooltip Data
        const tooltips = {
            callbacks: {
                title() {
                    return null;
                },
                label(tooltipItem) {
                    const label = tooltipItem.label || '';

                    return label;
                },
                afterLabel(tooltipItem, data) {
                    const { tooltipData } = data.datasets[0];

                    return [`Attendance: ${tooltipData[tooltipItem.index].attendance}`];
                }
            }
        };

        widgetData.barChart.options = { ...widgetData.barChart.options, ...{ tooltips } };

        return widgetData;
    }

    formatRevenueWidgetData() {
        const { data: initialData } = this.props;

        const widgetData = {
            barChart: {
                data: {
                    labels: [],
                    datasets: [
                        {
                            label: 'Revenue',
                            fill: false,
                            lineTension: 0.1,
                            backgroundColor: chartColors[0],
                            borderColor: chartColors[0],
                            borderCapStyle: 'butt',
                            borderDash: [],
                            borderDashOffset: 0.0,
                            borderJoinStyle: 'miter',
                            pointBorderColor: chartColors[0],
                            pointBackgroundColor: '#fff',
                            pointBorderWidth: 1,
                            pointHoverRadius: 5,
                            pointHoverBackgroundColor: chartColors[0],
                            pointHoverBorderColor: 'rgba(220,220,220,1)',
                            pointHoverBorderWidth: 2,
                            pointRadius: 1,
                            pointHitRadius: 10,
                            data: [],
                            tooltipData: []
                        }
                    ]
                },
                options: {
                    legend: false,
                    maintainAspectRatio: false,
                    scales: {
                        xAxes: [
                            {
                                type: 'linear',
                                display: true,
                                position: 'left',
                                labels: {
                                    show: true
                                },
                                ticks: {
                                    beginAtZero: true,
                                    callback: value => kFormatter(value)
                                }
                            }
                        ]
                    }
                }
            }
        };

        // barChart
        // Create object with event names
        let events = {};
        if (initialData && initialData.eventsData) {
            initialData.eventsData.map(event => {
                events = { ...events, ...{ [event.id]: event.attributes.name } };
                return events;
            });
        }
        // Prepare chart data
        if (initialData && initialData.data) {
            Object.keys(initialData.data).map(key => {
                widgetData.barChart.data.datasets[0].data.push(
                    Math.round(initialData.data[key].revenue / 100)
                );
                if (events && events[key]) {
                    widgetData.barChart.data.labels.push(events[key]);
                } else {
                    widgetData.barChart.data.labels.push(key);
                }
                // tooltip
                const itemRevenueCurrency = initialData.data[key].revenue_currency
                    ? initialData.data[key].revenue_currency
                    : '';
                const itemRevenue = initialData.data[key].revenue
                    ? Math.round(initialData.data[key].revenue / 100)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    : 0;
                widgetData.barChart.data.datasets[0].tooltipData.push({
                    ticketsSold: initialData.data[key].attendance
                        ? initialData.data[key].attendance
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        : 0,
                    revenue: `${itemRevenueCurrency} ${itemRevenue}`,
                    attendance: initialData.data[key].attendance
                        ? initialData.data[key].attendance
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        : 0
                });

                return widgetData;
            });
        }

        // Prepare tooltip Data
        const tooltips = {
            callbacks: {
                title() {
                    return null;
                },
                label(tooltipItem) {
                    const label = tooltipItem.label || '';

                    return label;
                },
                afterLabel(tooltipItem, data) {
                    const { tooltipData } = data.datasets[0];

                    return [
                        `Revenue: ${tooltipData[tooltipItem.index].revenue
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
                    ];
                }
            }
        };

        widgetData.barChart.options = { ...widgetData.barChart.options, ...{ tooltips } };

        return widgetData;
    }

    renderFilters() {
        return (
            <Field
                name="filter"
                id="filter"
                fieldProps={{
                    label: (
                        <FormattedMessage
                            id="TotalSalesByEventBar.Filter.DateRange"
                            defaultMessage="Date Range: "
                        />
                    ),
                    clearable: false,
                    options: [
                        {
                            label: (
                                <FormattedMessage
                                    id="TotalSalesByEventBar.Filter.DateRange.Last24h"
                                    defaultMessage="Last 24h"
                                />
                            ),
                            value: 1
                        },
                        {
                            label: (
                                <FormattedMessage
                                    id="TotalSalesByEventBar.Filter.DateRange.Last7Days"
                                    defaultMessage="Last 7 Days"
                                />
                            ),
                            value: 7
                        },
                        {
                            label: (
                                <FormattedMessage
                                    id="TotalSalesByEventBar.Filter.DateRange.Last30Days"
                                    defaultMessage="Last 30 Days"
                                />
                            ),
                            value: 30
                        },
                        {
                            label: (
                                <FormattedMessage
                                    id="TotalSalesByEventBar.Filter.DateRange.Last3Months"
                                    defaultMessage="Last 3 Months"
                                />
                            ),
                            value: 90
                        },
                        {
                            label: (
                                <FormattedMessage
                                    id="TotalSalesByEventBar.Filter.DateRange.Last6Months"
                                    defaultMessage="Last 6 Months"
                                />
                            ),
                            value: 180
                        },
                        {
                            label: (
                                <FormattedMessage
                                    id="TotalSalesByEventBar.Filter.DateRange.Last12Months"
                                    defaultMessage="Last 12 Months"
                                />
                            ),
                            value: 365
                        },
                        {
                            label: (
                                <FormattedMessage
                                    id="TotalSalesByEventBar.Filter.DateRange.AllTime"
                                    defaultMessage="All Time"
                                />
                            ),
                            value: Infinity
                        }
                    ]
                }}
                component={Select}
            />
        );
    }

    renderEvents() {
        const { viewBy } = this.state;
        const widgetData =
            viewBy === 'attendance' ? this.formatWidgetData() : this.formatRevenueWidgetData();

        return (
            <HorizontalBarChart
                data={widgetData.barChart.data}
                options={widgetData.barChart.options}
            />
        );
    }

    renderContent() {
        const { viewBy } = this.state;

        return (
            <div className="total-sales-by-event-bar-widget__content">
                <div className="total-sales-by-event-bar-widget__toggles">
                    <Button
                        className={`total-sales-by-event-bar-widget__toggles-button ${viewBy ===
                            'attendance' &&
                            'total-sales-by-event-bar-widget__toggles-button--active'}`}
                        label="Attendance"
                        onClick={() => this.changeView('attendance')}
                        variation="tertiary"
                    />
                    <Button
                        className={`total-sales-by-event-bar-widget__toggles-button ${viewBy ===
                            'revenue' &&
                            'total-sales-by-event-bar-widget__toggles-button--active'}`}
                        label="Revenue"
                        onClick={() => this.changeView('revenue')}
                        variation="tertiary"
                    />
                </div>
                <div className="total-sales-by-event-bar-widget__main">{this.renderEvents()}</div>
            </div>
        );
    }

    render() {
        const { size, fetching } = this.props;

        return (
            <Widget
                title={
                    <FormattedMessage
                        id="TotalSalesByEventBar.Title"
                        defaultMessage="Total Sales by Event"
                    />
                }
                filters={this.renderFilters()}
                size={size}
                className="total-sales-by-event-bar-widget"
            >
                {fetching && <ElementLoader overlay />}
                {this.renderContent()}
            </Widget>
        );
    }
}

TotalSalesByEventBar.defaultProps = {
    size: null,
    fetching: false,
    data: {},
    filterValues: { filter: Infinity },
    setFormValue: () => {},
    eventID: null,
    overallSalesFormValues: {}
};

TotalSalesByEventBar.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    dispatch: PropTypes.func.isRequired,
    fetching: PropTypes.bool,
    data: PropTypes.oneOfType([PropTypes.object]),
    filterValues: PropTypes.oneOfType([PropTypes.object]),
    setFormValue: PropTypes.func,
    eventID: PropTypes.string,
    overallSalesFormValues: PropTypes.oneOfType([PropTypes.object])
};

//  Getting initial value populated in the form from the store
const mapStateToProps = (state, ownProps) => {
    const overallSalesFormValues = getFormValues('OverallSalesForm')(state);
    return {
        data: getData(state, ownProps.id),
        fetching: getFetching(state, ownProps.id),
        filterValues: getFormValues('TotalSalesByEventBarForm')(state),
        overallSalesFormValues
    };
};

TotalSalesByEventBar = reduxForm(
    {
        form: 'TotalSalesByEventBarForm',
        destroyOnUnmount: false
    },
    mapStateToProps
)(TotalSalesByEventBar);

const mapDispatchToProps = dispatch => ({
    setFormValue: (field, value) => dispatch(change('TotalSalesByEventBarForm', field, value))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TotalSalesByEventBar);
