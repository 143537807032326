/**
 * Generate keys for React Components
 * @param {String} string Param that is used for the key
 * @return {String} Returns generated key
 */

const generateKey = string => {
    let theKey = '';

    // if FormattedMessage object
    if (string.constructor === Object) {
        if (string.props && string.props.defaultMessage) {
            string = string.props.defaultMessage;
        }
    }

    // generate key
    theKey = string
        .toString()
        .split('')
        .reduce(
            // eslint-disable-next-line
            (prevHash, currVal) => ((prevHash << 5) - prevHash + currVal.charCodeAt(0)) | 0,
            0
        );

    return theKey;
};

export default generateKey;
