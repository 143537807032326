import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import LayoutManager from 'erputils/LayoutManager';
import PageHeader from 'erpcomponents/Layout/PageHeader';
import Listing from 'erpcomponents/Listing';
import ListingTableActions from 'erpcomponents/Listing/components/TableActions';
import Button from 'erpcomponents/Button';
import Svg from 'erpcomponents/Svg';
import Tooltip from 'erpcomponents/Tooltip';
import StatusBadge from 'erpcomponents/StatusBadge';
import { actions as listingActions } from 'erpcomponents/Listing/Listing.reducer';
import { getListingFetching, getListingResponse } from 'erpcomponents/Listing/Listing.selectors';
import { stringOperations, intOperations, existOperations } from 'erpdata/filterOperations';
import { getCheckedGroups } from 'erpcore/screens/Groups/Groups.selectors';
import EventTicketsInfo from 'erpcore/screens/Groups/components/EventTicketsInfo';
import GroupDelete from 'erpcore/screens/Groups/components/GroupDelete';

// bulk actions components
import MergeGroups from 'erpcore/screens/Groups/screens/GroupsListing/BulkActions/MergeGroups';
import DeleteGroups from 'erpcore/screens/Groups/screens/GroupsListing/BulkActions/DeleteGroups';
import './GroupsListing.scss';

const reducerName = 'groups';

const orderNumbersList = orders => {
    return (
        <span className="groups-listing__order-ids">
            {orders.map(order => (
                <NavLink
                    className="groups-listing__order-ids__link"
                    to={`orders/${order.id}/order-info`}
                >
                    {order.order_number}
                    {orders.length > 1 && ','}
                </NavLink>
            ))}
        </span>
    );
};
class GroupsListing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            createGroupModal: false
        };
    }

    handleModal = () => {
        const { createGroupModal } = this.state;
        this.setState({ createGroupModal: !createGroupModal });
    };

    tableData() {
        const { groupsData } = this.props;

        const table = {};
        table.data = [];

        // Advanced Filters
        table.filters = [
            {
                name: 'orders.order_number',
                label: (
                    <FormattedMessage
                        id="Groups.Table.OrderNumber.Label"
                        defaultMessage="Order Number"
                    />
                ),
                filterFields: {
                    operator: {
                        component: 'select',
                        fieldProps: {
                            clearable: false,
                            label: (
                                <FormattedMessage
                                    id="Groups.Table.OrderNumber.Operator"
                                    defaultMessage="Operator"
                                />
                            ),
                            options: stringOperations
                        },
                        fieldValidation: [{ validator: 'required' }]
                    },
                    value: {
                        component: 'text',
                        fieldProps: {
                            label: (
                                <FormattedMessage
                                    id="Groups.Table.OrderNumber"
                                    defaultMessage="Order Number"
                                />
                            )
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            },
            {
                name: 'event',
                label: <FormattedMessage id="Groups.Table.Event.Label" defaultMessage="Event" />,
                defaultOperator: 'in',
                filterFields: {
                    value: {
                        component: 'multiautocomplete',
                        fieldProps: {
                            label: (
                                <FormattedMessage id="Groups.Table.Event" defaultMessage="Event" />
                            ),
                            options: {
                                endpoint: '/api/events',
                                mapData: {
                                    label: 'name',
                                    value: 'iri'
                                }
                            }
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            },
            {
                name: 'willing_to_share',
                label: (
                    <FormattedMessage
                        id="Groups.Table.WillShare.Label"
                        defaultMessage="Will Share?"
                    />
                ),
                defaultOperator: 'equals',
                filterFields: {
                    value: {
                        component: 'switch',
                        fieldProps: {
                            label: (
                                <FormattedMessage
                                    id="Groups.Table.WillShare"
                                    defaultMessage="Will Share?"
                                />
                            ),
                            on: { label: 'Yes', value: 'true', id: 'switch-on-true' },
                            off: {
                                label: 'No',
                                value: 'false',
                                id: 'switch-off-false'
                            },
                            type: 'dual'
                        },

                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            },
            {
                name: 'group_completed',
                label: (
                    <FormattedMessage
                        id="Groups.Table.GroupCompleted.Label"
                        defaultMessage="Group Status"
                    />
                ),
                defaultOperator: 'equals',
                filterFields: {
                    value: {
                        component: 'switch',
                        fieldProps: {
                            label: (
                                <FormattedMessage
                                    id="Groups.Table.GroupCompleted"
                                    defaultMessage="Group Status"
                                />
                            ),
                            on: { label: 'Complete', value: 'true', id: 'switch-on-true' },
                            off: {
                                label: 'Incomplete',
                                value: 'false',
                                id: 'switch-off-false'
                            },
                            type: 'dual'
                        },

                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            },
            {
                name: 'group_size',
                label: (
                    <FormattedMessage
                        id="Groups.Table.GroupSize.Label"
                        defaultMessage="Group Size"
                    />
                ),
                filterFields: {
                    operator: {
                        component: 'select',
                        fieldProps: {
                            clearable: false,
                            label: (
                                <FormattedMessage
                                    id="Groups.Table.GroupSize.Operator"
                                    defaultMessage="Operator"
                                />
                            ),
                            options: intOperations
                        },
                        fieldValidation: [{ validator: 'required' }]
                    },
                    value: {
                        component: 'number',
                        fieldProps: {
                            label: (
                                <FormattedMessage
                                    id="Groups.Table.GroupSize"
                                    defaultMessage="Group Size"
                                />
                            )
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            },
            {
                name: 'tables.table_number',
                label: <FormattedMessage id="Groups.Table.Seated.Label" defaultMessage="Seated" />,
                hideValue: true,
                filterFields: {
                    defaultValue: 1,
                    operator: {
                        component: 'select',
                        fieldProps: {
                            clearable: false,
                            label: (
                                <FormattedMessage
                                    id="Vendors.Table.Name.Operator"
                                    defaultMessage="Operator"
                                />
                            ),
                            options: existOperations
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            }
        ];

        table.bulkActions = {
            name: 'groups',
            actions: [
                { label: 'Merge Groups', component: <MergeGroups /> },
                { label: 'Delete Groups', component: <DeleteGroups /> }
            ]
        };

        // Table Schema
        table.schema = [
            {
                title: <FormattedMessage id="Groups.Table.Name" defaultMessage="Name" />,
                field: 'name',
                mobile: 'title'
            },
            {
                title: <FormattedMessage id="Groups.Table.GroupSize" defaultMessage="Group Size" />,
                field: 'group_size',
                mobile: 'subtitle'
            },
            {
                title: <FormattedMessage id="Groups.Table.Sold" defaultMessage="Sold" />,
                field: 'sold'
            },
            {
                title: (
                    <FormattedMessage
                        id="Groups.Table.EventTickets"
                        defaultMessage="Event/Tickets"
                    />
                ),
                field: 'event',
                mobile: 'subtitle'
            },
            {
                title: <FormattedMessage id="Groups.Table.Tickets" defaultMessage="Tickets" />,
                field: 'tickets'
            },
            {
                title: (
                    <FormattedMessage id="Groups.Table.OrderIDs" defaultMessage="Order number" />
                ),
                field: 'order_ids',
                mobile: 'subtitle',
                sortable: 'orders.order_number'
            },
            {
                title: (
                    <FormattedMessage
                        id="Groups.Table.TableStatus"
                        defaultMessage="Tables/Slot Status"
                    />
                ),
                field: 'tables'
            },
            {
                title: (
                    <FormattedMessage id="Groups.Table.GroupStatus" defaultMessage="Group Status" />
                ),
                field: 'status',
                mobile: 'status'
            },
            {
                title: <FormattedMessage id="Groups.Table.Actions" defaultMessage="Actions" />,
                field: 'actions'
            }
        ];

        groupsData.data.map(row => {
            const orders = row.orders && row.orders;

            return table.data.push({
                iri: row.iri,
                id: row.id,
                name: row.name,
                sold: row.sold,
                group_size: row.group_size,
                order_ids: orders && orderNumbersList(orders),
                event: (
                    <EventTicketsInfo
                        event={row.event && row.event}
                        orders={row.orders && row.orders}
                    />
                ),
                tables: `${row.sold} / ${row.group_size}`,
                tickets: row.number_of_ticket_types,
                status: (
                    <StatusBadge
                        type={row.group_completed ? 'fulfilled' : 'special'}
                        text={row.group_completed ? 'Complete' : 'Incomplete'}
                    />
                ),
                actions: (
                    <ListingTableActions>
                        <ListingTableActions.Action>
                            <Tooltip
                                content={
                                    <FormattedMessage
                                        id="Groups.Table.Actions.Edit"
                                        defaultMessage="Edit Group"
                                    />
                                }
                            >
                                <NavLink to={`/groups/${row.id}/edit`}>
                                    <Svg icon="edit" />
                                </NavLink>
                            </Tooltip>
                        </ListingTableActions.Action>
                        {/* <ListingTableActions.Action>
                            <GroupEditModal form={`GroupEditModalForm${row.id}`} id={row.id} />
                        </ListingTableActions.Action> */}
                        <ListingTableActions.Action>
                            <Tooltip
                                content={
                                    <FormattedMessage
                                        id="Groups.Table.Actions.Email"
                                        defaultMessage="Group Details Request Email"
                                    />
                                }
                            >
                                <NavLink to="/">
                                    <Svg icon="settings" />
                                </NavLink>
                            </Tooltip>
                        </ListingTableActions.Action>
                        <ListingTableActions.Action>
                            <GroupDelete
                                id={row.id}
                                key={`GroupDelete_${row.id}`}
                                form="GroupDeleteForm"
                            />
                        </ListingTableActions.Action>
                    </ListingTableActions>
                )
            });
        });

        return table;
    }

    render() {
        const { groupsListingFetching, groupsData, fetchGroups, checked } = this.props;
        // const { createGroupModal } = this.state;
        return (
            <LayoutManager slot="main" layoutType="merge">
                <PageHeader title={<FormattedMessage id="Groups.title" defaultMessage="Groups" />}>
                    {/* <Button
                        onClick={() => this.handleModal()}
                        label={
                            <FormattedMessage
                                id="Groups.CreateGroupModal"
                                defaultMessage="Create Group Modal"
                            />
                        }
                    /> */}
                    <Button
                        href="/groups/create"
                        label={
                            <FormattedMessage
                                id="Groups.CreateGroup"
                                defaultMessage="Create Group"
                            />
                        }
                    />
                </PageHeader>
                {/* {createGroupModal && <GroupCreateModal />} */}
                <Listing
                    name={reducerName}
                    reducerName={reducerName}
                    loading={groupsListingFetching}
                    meta={groupsData.meta}
                    table={this.tableData()}
                    onListingConfigUpdate={params => fetchGroups(params)}
                    groupsListingBulkActions={
                        checked && checked.length > 0 ? checked.length > 0 : false
                    }
                />
                {/* <GroupCreateModal /> */}
            </LayoutManager>
        );
    }
}

GroupsListing.defaultProps = {
    groupsListingFetching: false,
    groupsData: {},
    checked: []
};

GroupsListing.propTypes = {
    fetchGroups: PropTypes.func.isRequired,
    groupsListingFetching: PropTypes.bool,
    groupsData: PropTypes.oneOfType([PropTypes.object]),
    checked: PropTypes.oneOfType([PropTypes.array])
};

//  Redux
const mapStateToProps = state => ({
    groupsListingFetching: getListingFetching(state, 'groups'),
    groupsData: getListingResponse(state, 'groups'),
    checked: getCheckedGroups(state)
});

const mapDispatchToProps = dispatch => ({
    fetchGroups: params => {
        // Getting included data from API by setting defaultParams
        const defaultParams = {
            include: 'event,event.name,ticketTypes,orders,orders.tables,tables'
        };
        const listingParams = Object.assign({}, defaultParams, params);

        dispatch({
            type: listingActions.START_FETCHING_LISTING,
            params: listingParams,
            name: reducerName,
            entity: 'GROUPS',
            endpoint: 'api/groups'
        });
    }
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(GroupsListing)
);
