// TODO: location of this file is not yet defined

import { takeLatest, delay, put, call, fork, take } from 'redux-saga/effects';
import { channel } from 'redux-saga';
import restClient from 'erpcore/api/restClient';
import { actions as notificationManagerActions } from 'erputils/NotificationManager/NotificationManager.reducer';
import dto from 'erputils/dto';
import { actions as homepageSliderActions } from 'erpcore/screens/Settings/screens/ResellerAdmin/HomepageSlider/HomepageSlider.reducer';

/**
 * Create headerMenu
 * @param  promise {Object}
 * @param  formData {Object}
 * @return {void}
 */
export function* createHomepageSlider({ promise, formData }) {
    try {
        // Create headerMenu
        const createHomepageSliderAPI = yield restClient.put(
            `api/general-settings/reseller-homepage-slider`,
            formData
        );
        yield put({
            type: homepageSliderActions.CREATE_HOMEPAGE_SLIDER_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: {
                ...createHomepageSliderAPI.data,
                code: 'homepageslider.itemSuccessfulyCreated'
            }
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: homepageSliderActions.CREATE_HOMEPAGE_SLIDER_FAILED,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Fetch ticketType
 * @param promise {Object}
 * @param id {number} ticketType id
 * @param params {Object}
 * @return {void}
 */
export function* fetchTicketType({ promise, id, params }) {
    try {
        const fetchTicketTypeAPI = yield restClient.get(`api/ticket-types/${id}`, { params });
        yield put({
            type: homepageSliderActions.FETCH_SINGLE_TICKET_TYPE_SUCCESSFUL
        });
        yield put({
            type: homepageSliderActions.STORE_SINGLE_TICKET_TYPE_DATA,
            id,
            response: dto(fetchTicketTypeAPI.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: homepageSliderActions.FETCH_SINGLE_TICKET_TYPE_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Update ticketType single data
 * @param  promise {Object}
 * @param  formData {Object}
 * @param  id {number} ticketType id
 * @param  debounceDelay {number} Number of milliseconds to delay execution
 * @return {void}
 */
export function* updateSingleHomepageSlider({ promise, formData, id, debounceDelay = 0 }) {
    yield delay(debounceDelay);
    yield put({
        type: homepageSliderActions.START_UPDATE_SINGLE_HOMEPAGE_SLIDER
    });
    try {
        const updateSingleHomepageSliderAPI = yield restClient.put(
            `api/general-settings/reseller-homepage-slider`,
            formData
        );
        yield put({
            type: homepageSliderActions.UPDATE_SINGLE_HOMEPAGE_SLIDER_SUCCESSFUL
        });
        yield put({
            type: homepageSliderActions.STORE_SINGLE_HOMEPAGE_SLIDER_DATA,
            id,
            response: dto(updateSingleHomepageSliderAPI.data)
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: {
                ...updateSingleHomepageSliderAPI.data,
                code: 'homepageslider.itemSuccessfulyUpdated'
            }
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: homepageSliderActions.UPDATE_SINGLE_HOMEPAGE_SLIDER_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Delete Single ticketType
 * @param  id {number} ticketType id
 * @param  promise {Object}
 * @return {void}
 */
export function* deleteSingleHomepageSlider({ promise, formData }) {
    try {
        const updateSingleHomepageSliderAPI = yield restClient.put(
            `api/general-settings/reseller-homepage-slider`,
            formData
        );
        yield put({
            type: homepageSliderActions.DELETE_SINGLE_HOMEPAGE_SLIDER_SUCCESSFUL
        });
        yield put({
            type: homepageSliderActions.STORE_SINGLE_HOMEPAGE_SLIDER_DATA,
            response: dto(updateSingleHomepageSliderAPI.data)
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: {
                ...updateSingleHomepageSliderAPI.data,
                code: 'homepageslider.itemSuccessfulyRemoved'
            }
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: homepageSliderActions.DELETE_SINGLE_HOMEPAGE_SLIDER_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

function takeLatestPerProps(propsOrSelector, pattern, worker, ...args) {
    // Not a generator
    return fork(function* generator() {
        // Fork a generator here to make it work like takeLatest
        const channelsMap = {};
        while (true) {
            const action = yield take(pattern); // yield necessary here
            const propsValue =
                typeof propsOrSelector === 'function'
                    ? propsOrSelector(action)
                    : action[propsOrSelector];
            if (!channelsMap[propsValue]) {
                channelsMap[propsValue] = channel();
                yield takeLatest(channelsMap[propsValue], worker, ...args);
            }
            yield put(channelsMap[propsValue], action);
        }
    });
}

/**
 * Register action to watcher
 */
export const homepageSliderSaga = [
    takeLatest(homepageSliderActions.START_CREATE_HOMEPAGE_SLIDER, createHomepageSlider),
    // takeLatest(homepageSliderActions.START_FETCHING_SINGLE_TICKET_TYPE, fetchTicketType),
    takeLatestPerProps(
        'id',
        homepageSliderActions.REQUEST_UPDATE_SINGLE_HOMEPAGE_SLIDER,
        updateSingleHomepageSlider
    ),
    takeLatest(
        homepageSliderActions.START_DELETE_SINGLE_HOMEPAGE_SLIDER,
        deleteSingleHomepageSlider
    )
];
