export const actions = {
    START_FETCHING_GETOUTTICKETS24H_DATA: 'START_FETCHING_GETOUTTICKETS24H_DATA',
    FETCHING_GETOUTTICKETS24H_DATA_SUCCESSFUL: 'FETCHING_GETOUTTICKETS24H_DATA_SUCCESSFUL',
    FETCHING_GETOUTTICKETS24H_DATA_FAILED: 'FETCHING_GETOUTTICKETS24H_DATA_FAILED',
    STORE_GETOUTTICKETS24H_DATA: 'STORE_GETOUTTICKETS24H_DATA'
};

export const initialState = {};

export default (state = initialState, { type, widgetId, response, source }) => {
    switch (type) {
        case actions.START_FETCHING_GETOUTTICKETS24H_DATA: {
            return {
                ...state,
                [widgetId]: { ...state[widgetId], ...{ fetching: true } }
            };
        }
        case actions.FETCHING_GETOUTTICKETS24H_DATA_SUCCESSFUL: {
            return {
                ...state,
                [widgetId]: { ...state[widgetId], ...{ fetching: false } }
            };
        }
        case actions.FETCHING_GETOUTTICKETS24H_DATA_FAILED: {
            return {
                ...state,
                [widgetId]: { ...state[widgetId], ...{ fetching: false } }
            };
        }
        case actions.STORE_GETOUTTICKETS24H_DATA: {
            return {
                ...state,
                [widgetId]: {
                    ...state[widgetId],
                    ...{
                        [source]: { ...state[widgetId][source], ...{ data: response } }
                    }
                }
            };
        }

        default:
            return state;
    }
};
