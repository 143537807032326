import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import LayoutManager from 'erputils/LayoutManager';
import PageHeader from 'erpcomponents/Layout/PageHeader';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import Listing from 'erpcomponents/Listing';
import ListingTableActions from 'erpcomponents/Listing/components/TableActions';
import Button from 'erpcomponents/Button';
import Svg from 'erpcomponents/Svg';
import Tooltip from 'erpcomponents/Tooltip';
import ChangePassword from 'erpcore/screens/Users/components/ChangePassword';
import UserDelete from 'erpcore/screens/Users/components/UsersDelete';
import { actions as listingActions } from 'erpcomponents/Listing/Listing.reducer';
import { getListingFetching, getListingResponse } from 'erpcomponents/Listing/Listing.selectors';
import { stringOperations } from 'erpdata/filterOperations';

const reducerName = 'users';

class UsersListing extends Component {
    tableData() {
        const { usersData } = this.props;
        const table = {};
        table.data = [];

        // Advanced Filters
        table.filters = [
            {
                name: 'city.name',
                label: <FormattedMessage id="Users.Table.City.Label" defaultMessage="City" />,
                filterFields: {
                    operator: {
                        component: 'select',
                        fieldProps: {
                            clearable: false,
                            label: (
                                <FormattedMessage
                                    id="Users.Table.City.Operator"
                                    defaultMessage="Operator"
                                />
                            ),
                            options: stringOperations
                        },
                        fieldValidation: [{ validator: 'required' }]
                    },
                    value: {
                        component: 'text',
                        fieldProps: {
                            label: <FormattedMessage id="Users.Table.City" defaultMessage="City" />
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            }
        ];
        // Table Schema
        table.schema = [
            {
                title: <FormattedMessage id="Users.Table.Name" defaultMessage="Name" />,
                field: 'name',
                sortable: 'firstName'
            },
            {
                title: <FormattedMessage id="Users.Table.Email" defaultMessage="Email" />,
                field: 'email',
                sortable: 'email'
            },
            {
                title: <FormattedMessage id="Users.Table.Phone" defaultMessage="Phone" />,
                field: 'phone_number',
                sortable: 'phoneNumber'
            },
            {
                title: <FormattedMessage id="Users.Table.City" defaultMessage="City" />,
                field: 'city',
                sortable: 'city.name'
            },
            {
                title: <FormattedMessage id="Users.Table.Actions" defaultMessage="Actions" />,
                field: 'actions'
            }
        ];
        // Table Data
        usersData.data.map(row => {
            let cityName = null;
            if (row.city && row.city.name) {
                cityName = row.city.name;
            }
            return table.data.push({
                id: row.id,
                name: `${row.first_name} ${row.last_name}`,
                email: row.email,
                phone_number: row.phone_number,
                city: cityName,
                actions: (
                    <ListingTableActions>
                        <ListingTableActions.Action>
                            <Tooltip
                                content={
                                    <FormattedMessage
                                        id="Users.Table.Actions.Edit"
                                        defaultMessage="Edit User"
                                    />
                                }
                            >
                                <NavLink to={`/users/${row.id}/edit/details`}>
                                    <Svg icon="edit" />
                                </NavLink>
                            </Tooltip>
                        </ListingTableActions.Action>
                        <ListingTableActions.Action>
                            <ChangePassword
                                id={row.id}
                                name={`${row.first_name} ${row.last_name}`}
                                key={`ChangePassword_${row.id}`}
                            />
                        </ListingTableActions.Action>
                        <ListingTableActions.Action>
                            <UserDelete id={row.id} key={`UserDelete ${row.id}`} />
                        </ListingTableActions.Action>
                    </ListingTableActions>
                )
            });
        });

        return table;
    }

    render() {
        const { usersListingFetching, usersData, fetchUsers } = this.props;

        return (
            <LayoutManager slot="main" layoutType="merge">
                <HeadMeta title="Users" />
                <PageHeader title={<FormattedMessage id="Users.title" defaultMessage="Users" />}>
                    <Button
                        href="/users/create"
                        label={
                            <FormattedMessage id="Users.CreateUser" defaultMessage="Create User" />
                        }
                    />
                </PageHeader>

                <Listing
                    name={reducerName}
                    reducerName={reducerName}
                    loading={usersListingFetching}
                    meta={usersData.meta}
                    table={this.tableData()}
                    onListingConfigUpdate={params => fetchUsers(params)}
                />
            </LayoutManager>
        );
    }
}

UsersListing.defaultProps = {
    usersListingFetching: false,
    usersData: {}
};

UsersListing.propTypes = {
    fetchUsers: PropTypes.func.isRequired,
    usersListingFetching: PropTypes.bool,
    usersData: PropTypes.oneOfType([PropTypes.object])
};

//  Redux
const mapStateToProps = state => ({
    usersListingFetching: getListingFetching(state, 'users'),
    usersData: getListingResponse(state, 'users')
});

const mapDispatchToProps = dispatch => ({
    fetchUsers: params => {
        // Getting included data from API by setting defaultParams
        const defaultParams = {
            include: 'city'
        };
        const listingParams = Object.assign({}, defaultParams, params);

        dispatch({
            type: listingActions.START_FETCHING_LISTING,
            params: listingParams,
            entity: 'USERS',
            name: reducerName,
            endpoint: 'api/users'
        });
    }
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(UsersListing)
);
