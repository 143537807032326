import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Svg from 'erpcore/components/Svg';
import Select from 'react-select';
import styles from 'erpcore/components/Form/components/Select/Select.styles';
import './BulkActions.scss';

import { BulkActionsModal } from 'erpcore/components/Listing/components/BulkActions';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import {
    getBulkActionsModalIsOpened,
    getBulkActionsIris
} from 'erpcore/components/Listing/Listing.selectors';

const BulkActionsDropdown = ({ bulkActionsData, className, reducerName }) => {
    const dispatch = useDispatch();
    const isOpen = useSelector(state => getBulkActionsModalIsOpened(state, reducerName));
    const bulkActionsIris = useSelector(state => getBulkActionsIris(state, reducerName));
    const [activeOption, setActiveOption] = useState(null);
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [action, setAction] = useState(null);
    const [actionIndex, setActionIndex] = useState(null);

    const mainElement = React.createRef();

    const handleOutsideClick = ev => {
        if (mainElement.current && !mainElement.current.contains(ev.target)) {
            setMenuIsOpen(false);
        }
    };

    const handleModal = () => {
        return dispatch({
            type: listingActions.TOGGLE_BULK_ACTIONS_MODAL,
            response: !isOpen,
            name: reducerName
        });
    };

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick, false);
    }, []);

    const onChange = selectedOption => {
        if (!selectedOption) {
            return null;
        }
        const { value } = selectedOption;
        let onChangeAction = null;

        // Get Bulk Action Component
        if (bulkActionsData?.actions[value]?.component) {
            onChangeAction = bulkActionsData.actions[value].component;
            setActiveOption(false);
            setAction(onChangeAction);
            setActionIndex(value);
            handleModal();
        } else if (bulkActionsData?.actions[value]?.triggerFunction) {
            bulkActionsData.actions[value].triggerFunction(bulkActionsIris);
        }
        return true;
    };

    const getDropdown = () => {
        if (!bulkActionsData) {
            return null;
        }

        const selectOptions = [];

        if (bulkActionsData.actions) {
            bulkActionsData.actions.forEach((item, index) => {
                const { label } = item;
                selectOptions.push({
                    value: index,
                    label
                });
            });
        }

        return (
            <Select
                styles={styles}
                onChange={selectedOption => {
                    onChange(selectedOption);
                }}
                placeholder=""
                className="react-select"
                classNamePrefix="react-select"
                isClearable={false}
                hideSelectedOptions={false}
                isSearchable={false}
                menuPortalTarget={mainElement.current || null}
                menuIsOpen={menuIsOpen}
                onMenuOpen={() => setMenuIsOpen(true)}
                onMenuClose={() => setMenuIsOpen(false)}
                value={activeOption}
                options={selectOptions}
            />
        );
    };

    const handleVersions = () => {
        className = `bulk-actions-dropdown ${className}`;

        if (menuIsOpen) {
            className = `${className}bulk-actions-dropdown--open`;
        } else {
            className = className.replace(' bulk-actions-dropdown--open', '');
        }

        return className;
    };

    const renderToggle = () => {
        return (
            <button
                type="button"
                disabled={!bulkActionsIris.length}
                aria-haspopup="true"
                className="bulk-actions-dropdown__toggle"
                onClick={() => {
                    setMenuIsOpen(!menuIsOpen);
                }}
            >
                <span className="bulk-actions-dropdown__toggle-label">Bulk Actions</span>
                <Svg className="bulk-actions-dropdown__toggle-icon" icon="arrowDown" />
            </button>
        );
    };

    const renderModal = () => {
        const bulkActionData =
            bulkActionsData.actions && bulkActionsData.actions[actionIndex]
                ? bulkActionsData.actions[actionIndex]
                : null;
        const modalVariation = (bulkActionData && bulkActionData.modalVariation) || 'small';

        const modalTitle = (bulkActionData && bulkActionData.label) || null;

        const modal = action ? (
            <BulkActionsModal
                modalVariation={modalVariation}
                handleModal={handleModal}
                title={modalTitle}
                reducerName={reducerName}
            >
                {action}
            </BulkActionsModal>
        ) : null;

        return modal;
    };

    handleVersions();

    return bulkActionsIris.length ? (
        <div ref={mainElement} className={className || ''}>
            {renderToggle()}
            {getDropdown()}
            {renderModal()}
        </div>
    ) : null;
};

BulkActionsDropdown.defaultProps = {
    bulkActionsData: {
        name: null,
        actions: []
    },
    className: ''
};

BulkActionsDropdown.propTypes = {
    bulkActionsData: PropTypes.oneOfType([PropTypes.object]),
    reducerName: PropTypes.string.isRequired,
    className: PropTypes.string
};

export default BulkActionsDropdown;
