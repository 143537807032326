import { chartColors } from 'erpdata/colors';

/**
 * Removes year from date eg: 2019-5-2 -> 5-2
 * @param  {String} date
 * @return {String} date
 */
const formatDateWithoutYear = date => {
    let formatedDate = null;

    if (date) {
        const splitDate = date.split('-');
        formatedDate = `${splitDate[1]}-${splitDate[2]}`;
    }

    return formatedDate;
};

/**
 * Converts date into int
 * @param  {String} date
 * @return {Integer} number
 */
const getDateToInt = date => {
    let int = null;

    if (date) {
        const number = date.split('-').join('');

        int = parseInt(number, 10);
    }

    return int;
};

/**
 * Return year
 * @param  {String} date
 * @return {Integer} year
 */
const getYear = date => {
    let int = null;

    if (date) {
        const number = date.split('-');

        int = parseInt(number[0], 10);
    }

    return int;
};

/**
 * Formats chart data
 * @param  {Object} chartData
 * @param  {Object} selectedEvents - current user entered formValues
 * @param  {Array} eventsData
 * @param  {Object} fieldDisplayed - take "which" data object ( revenue, tickets_sold, etc... )
 * @return {Object} formated chart data
 */
const formLineChartData = (chartData, selectedEvents, eventsData, fieldDisplayed) => {
    const lineChartData = {
        labels: [],
        datasets: []
    };
    let startDate = null;
    let endDate = null;

    if (selectedEvents?.eventSeriesFilter) {
        const mergedDates = {};

        // First merge dates of all selected events in form
        selectedEvents.eventSeriesFilter.forEach(event => {
            if (chartData?.[event]?.data && Object.keys(chartData?.[event]?.data).length > 0) {
                const chartDataEventDates = Object.keys(chartData?.[event]?.data);
                // set start and end dates //
                if (!startDate && !endDate) {
                    [startDate] = chartDataEventDates;
                    endDate = chartDataEventDates[chartDataEventDates?.length - 1];
                }

                if (
                    getDateToInt(formatDateWithoutYear(chartDataEventDates[0])) <
                    getDateToInt(formatDateWithoutYear(startDate))
                ) {
                    [startDate] = chartDataEventDates;
                }

                if (
                    getDateToInt(
                        formatDateWithoutYear(chartDataEventDates[chartDataEventDates?.length - 1])
                    ) > getDateToInt(formatDateWithoutYear(endDate))
                ) {
                    endDate = chartDataEventDates[chartDataEventDates?.length - 1];
                }

                chartDataEventDates.forEach(key => {
                    // No duplicates //
                    if (!(key in mergedDates)) {
                        Object.assign(mergedDates, {
                            [key]: chartData?.[event]?.data?.[key]
                        });
                    }
                });
            }
        });

        // Sort Dates - from lower to higher //
        const dateKeys = Object.keys(mergedDates);

        dateKeys.sort((a, b) => {
            const dateA =
                getYear(startDate) < getYear(a)
                    ? `10${formatDateWithoutYear(a)}`
                    : formatDateWithoutYear(a);

            const intA = getDateToInt(dateA);
            const intB = getDateToInt(formatDateWithoutYear(b));

            return intA - intB;
        });

        dateKeys[0] = startDate;
        dateKeys[dateKeys?.length - 1] = endDate;

        // Go trough each event and each date and assign data to that date for that event //
        selectedEvents.eventSeriesFilter.forEach((event, i) => {
            if (chartData?.[event]?.data) {
                const eventData = eventsData.find(eventIri => event === eventIri.iri);
                const chartDataDates = Object.keys(chartData?.[event]?.data).map(key =>
                    formatDateWithoutYear(key)
                );
                let lastRecordedValue = 0;
                let currency = null;

                const data = [];

                if (dateKeys?.length > 0) {
                    dateKeys.forEach(date => {
                        if (!currency && chartData?.[event]?.data?.[date]?.['revenue_currency']) {
                            currency = chartData[event].data[date].revenue_currency;
                        }
                        const dateWithoutYear = formatDateWithoutYear(date);

                        if (fieldDisplayed === 'per_event') {
                            if (chartDataDates.indexOf(dateWithoutYear) > -1) {
                                if (chartData?.[event]?.data?.[date]?.['revenue']) {
                                    const { event_count: eventCount } = { ...eventData };
                                    const revenueOnDate =
                                        chartData?.[event]?.data?.[date]?.['revenue'];

                                    const revenuePerEvent =
                                        revenueOnDate / 100 / eventData?.[eventCount];

                                    data.push(revenuePerEvent + lastRecordedValue);
                                    lastRecordedValue = revenuePerEvent + lastRecordedValue;
                                } else {
                                    data.push(lastRecordedValue);
                                }
                            } else {
                                data.push(lastRecordedValue);
                            }
                        } else if (fieldDisplayed === '%Sold') {
                            if (chartDataDates.indexOf(dateWithoutYear) > -1) {
                                if (
                                    chartData?.[event]?.data?.[date]?.['tickets_sold'] &&
                                    chartData?.[event]?.totals?.attendance
                                ) {
                                    const totalAttendance = chartData[event].totals.attendance;
                                    const ticketsSold = chartData[event].data[date].tickets_sold;

                                    const percentageSold = (ticketsSold / totalAttendance) * 100;

                                    data.push(percentageSold + lastRecordedValue);
                                    lastRecordedValue = percentageSold + lastRecordedValue;
                                } else {
                                    data.push(lastRecordedValue);
                                }
                            } else {
                                data.push(lastRecordedValue);
                            }
                        } else if (chartDataDates.indexOf(dateWithoutYear) > -1) {
                            if (
                                chartData?.[event]?.data?.[date]?.[fieldDisplayed] &&
                                chartData?.[event]?.data?.[date]?.[fieldDisplayed] !== 0
                            ) {
                                data.push(
                                    chartData[event].data[date][fieldDisplayed] + lastRecordedValue
                                );
                                lastRecordedValue =
                                    chartData[event].data[date][fieldDisplayed] + lastRecordedValue;
                            } else {
                                data.push(lastRecordedValue);
                            }
                        } else {
                            data.push(lastRecordedValue);
                        }

                        if (lineChartData.labels.indexOf(dateWithoutYear) < 0) {
                            lineChartData.labels.push(dateWithoutYear);
                        }
                    });
                }

                lineChartData.datasets.push({
                    data,
                    label: eventData && eventData.name,
                    fill: false,
                    lineTension: 0.3,
                    backgroundColor: chartColors[i],
                    borderColor: chartColors[i],
                    pointBorderColor: chartColors[i],
                    pointBackgroundColor: '#fff',
                    pointHoverBackgroundColor: chartColors[1],
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    currency
                });
            }
        });
    }

    return lineChartData;
};

export default formLineChartData;
