import { combineReducers } from 'redux';
import eventSales from 'erpcomponents/Widgets/EventSales/EventSales.reducer';
import eventSalesPerTicketType from 'erpcomponents/Widgets/EventSalesPerTicketType/EventSalesPerTicketType.reducer';
import getOutTickets24h from 'erpcomponents/Widgets/GetOutTickets24h/GetOutTickets24h.reducer';
import salesCycle from 'erpcomponents/Widgets/SalesCycle/SalesCycle.reducer';
import salesOverview from 'erpcomponents/Widgets/SalesOverview/SalesOverview.reducer';
import scannedTickets from 'erpcomponents/Widgets/ScannedTickets/ScannedTickets.reducer';
import ticketsOverview from 'erpcomponents/Widgets/TicketsOverview/TicketsOverview.reducer';
import totalSalesByEvent from 'erpcomponents/Widgets/TotalSalesByEvent/TotalSalesByEvent.reducer';
import totalTickets24h from 'erpcomponents/Widgets/TotalTickets24h/TotalTickets24h.reducer';
import totalSalesByEventBar from 'erpcomponents/Widgets/TotalSalesByEventBar/TotalSalesByEventBar.reducer';
import totalSalesByTicketType from 'erpcomponents/Widgets/TotalSalesByTicketType/TotalSalesByTicketType.reducer';
import ticketSocketTickets24h from 'erpcomponents/Widgets/TicketSocketTickets24h/TicketSocketTickets24h.reducer';
import ticketsPerTimeslot from 'erpcomponents/Widgets/TicketsPerTimeslot/TicketsPerTimeslot.reducer';
import ticketsPerTicketType from 'erpcomponents/Widgets/TicketsPerTicketType/TicketsPerTicketType.reducer';
import ticketTypeReport from 'erpcomponents/Widgets/TicketTypesReport/TicketTypesReport.reducer';
import ticketTypes from 'erpcomponents/Widgets/TicketTypes/TicketTypes.reducer';

const widgets = combineReducers({
    eventSales,
    eventSalesPerTicketType,
    getOutTickets24h,
    salesCycle,
    salesOverview,
    scannedTickets,
    ticketsOverview,
    totalSalesByEvent,
    totalTickets24h,
    totalSalesByEventBar,
    totalSalesByTicketType,
    ticketsPerTimeslot,
    ticketsPerTicketType,
    ticketSocketTickets24h,
    ticketTypeReport,
    ticketTypes
});

export default widgets;
