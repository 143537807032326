export const actions = {
    START_FETCHING_RESELLERS: 'START_FETCHING_RESELLERS',
    FETCHING_SUCCESSFUL_RESELLERS: 'FETCHING_SUCCESSFUL_RESELLERS',
    FETCHING_FAILED_RESELLERS: 'FETCHING_FAILED_RESELLERS',
    START_CREATE_RESELLER: 'START_CREATE_RESELLER',
    CREATE_RESELLER_SUCCESSFUL: 'CREATE_RESELLER_SUCCESSFUL',
    CREATE_RESELLER_FAILED: 'CREATE_RESELLER_FAILED',
    START_FETCHING_SINGLE_RESELLER: 'START_FETCHING_SINGLE_RESELLER',
    FETCH_SINGLE_RESELLER_SUCCESSFUL: 'FETCH_SINGLE_RESELLER_SUCCESSFUL',
    FETCH_SINGLE_RESELLER_FAILED: 'FETCH_SINGLE_RESELLER_FAILED',
    START_UPDATE_SINGLE_RESELLER_GENERAL_INFO: 'START_UPDATE_SINGLE_RESELLER_GENERAL_INFO',
    UPDATE_SINGLE_RESELLER_GENERAL_INFO_SUCCESSFUL:
        'UPDATE_SINGLE_RESELLER_GENERAL_INFO_SUCCESSFUL',
    UPDATE_SINGLE_RESELLER_GENERAL_INFO_FAILED: 'UPDATE_SINGLE_RESELLER_GENERAL_INFO_FAILED',
    START_UPDATE_SINGLE_RESELLER_SEO: 'START_UPDATE_SINGLE_RESELLER_SEO',
    UPDATE_SINGLE_RESELLER_SEO_SUCCESSFUL: 'UPDATE_SINGLE_RESELLER_SEO_SUCCESSFUL',
    UPDATE_SINGLE_RESELLER_SEO_FAILED: 'UPDATE_SINGLE_RESELLER_SEO_FAILED',
    START_DELETE_SINGLE_RESELLER: 'START_DELETE_SINGLE_RESELLER',
    DELETE_SINGLE_RESELLER_SUCCESSFUL: 'DELETE_SINGLE_RESELLER_SUCCESSFUL',
    DELETE_SINGLE_RESELLER_FAILED: 'DELETE_SINGLE_RESELLER_FAILED',
    START_UPDATE_SINGLE_RESELLER_STATUS: 'START_UPDATE_SINGLE_RESELLER_STATUS',
    UPDATE_SINGLE_RESELLER_STATUS_SUCCESSFUL: 'UPDATE_SINGLE_RESELLER_STATUS_SUCCESSFUL',
    UPDATE_SINGLE_RESELLER_STATUS_FAILED: 'UPDATE_SINGLE_RESELLER_STATUS_FAILED',
    STORE_SINGLE_RESELLER_DATA: 'STORE_SINGLE_RESELLER_DATA',
    TOGGLE_RESELLER_HIDE_EVENTS: 'TOGGLE_RESELLER_HIDE_EVENTS',
    FETCH_RESELLER_EVENTS: 'FETCH_RESELLER_EVENTS',
    STORE_RESELLER_EVENTS: 'STORE_RESELLER_EVENTS',
    START_UPDATE_SINGLE_RESELLER_EVENTS: 'START_UPDATE_SINGLE_RESELLER_EVENTS',
    UPDATE_SINGLE_RESELLER_EVENTS_SUCCESSFUL: 'UPDATE_SINGLE_RESELLER_EVENTS_SUCCESSFUL',
    UPDATE_SINGLE_RESELLER_EVENTS_FAILED: 'UPDATE_SINGLE_RESELLER_EVENTS_FAILED',
    START_UPDATE_RESELLER_EVENT_VISIBILITY: 'START_UPDATE_RESELLER_EVENT_VISIBILITY',
    UPDATE_RESELLER_EVENT_VISIBILITY_SUCCESSFUL: 'UPDATE_RESELLER_EVENT_VISIBILITY_SUCCESSFUL',
    UPDATE_RESELLER_EVENT_VISIBILITY_FAILED: 'UPDATE_RESELLER_EVENT_VISIBILITY_FAILED',
    START_FETCHING_RESELLER_EVENTS: 'START_FETCHING_RESELLER_EVENTS',
    FETCH_RESELLER_EVENTS_SUCCESSFUL: 'FETCH_RESELLER_EVENTS_SUCCESSFUL',
    FETCH_RESELLER_EVENTS_FAILED: 'FETCH_RESELLER_EVENTS_FAILED',

    START_BULK_ACTIONS_UPDATE_RESELLERS: 'START_BULK_ACTIONS_UPDATE_RESELLERS',
    BULK_ACTIONS_UPDATE_RESELLERS_SUCCESS: 'BULK_ACTIONS_UPDATE_RESELLERS_SUCCESS',
    BULK_ACTIONS_UPDATE_RESELLERS_FAILED: 'BULK_ACTIONS_UPDATE_RESELLERS_FAILED'
};

export const initialState = {
    listingFetching: false,
    listingData: [],
    listingMeta: {},
    listingError: [],
    singleFetching: false,
    resellers: [],
    submittingBulkAction: false
};

export default (state = initialState, { type, id, response }) => {
    switch (type) {
        case actions.START_FETCHING_RESELLERS: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.START_BULK_ACTIONS_UPDATE_RESELLERS: {
            return {
                ...state,
                submittingBulkAction: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_RESELLERS: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_RESELLERS: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }

        case actions.START_CREATE_RESELLER: {
            return state;
        }
        case actions.START_FETCHING_SINGLE_RESELLER: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.FETCH_SINGLE_RESELLER_SUCCESSFUL: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.FETCH_SINGLE_RESELLER_FAILED: {
            return {
                ...state,
                singleFetching: false,
                singleError: response
            };
        }
        case actions.START_FETCHING_RESELLER_EVENTS: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.FETCH_RESELLER_EVENTS_SUCCESSFUL: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.FETCH_RESELLER_EVENTS_FAILED: {
            return {
                ...state,
                singleFetching: false,
                singleError: response
            };
        }
        case actions.UPDATE_SINGLE_RESELLER_STATUS_FAILED: {
            return {
                ...state,
                singleError: response
            };
        }
        case actions.BULK_ACTIONS_UPDATE_RESELLERS_SUCCESS: {
            return {
                ...state,
                submittingBulkAction: false
            };
        }
        case actions.BULK_ACTIONS_UPDATE_RESELLERS_FAILED: {
            return {
                ...state,
                submittingBulkAction: false
            };
        }
        case actions.UPDATE_SINGLE_RESELLER_GENERAL_INFO_FAILED: {
            return {
                ...state,
                singleError: response
            };
        }
        case actions.UPDATE_SINGLE_RESELLER_SEO_FAILED: {
            return {
                ...state,
                singleError: response
            };
        }
        case actions.START_UPDATE_RESELLER_EVENT_VISIBILITY: {
            return {
                ...state,
                singleUpdating: true
            };
        }
        case actions.UPDATE_RESELLER_EVENT_VISIBILITY_SUCCESSFUL: {
            return {
                ...state,
                singleUpdating: false
            };
        }
        case actions.UPDATE_RESELLER_EVENT_VISIBILITY_FAILED: {
            return {
                ...state,
                singleUpdating: false,
                singleError: response
            };
        }
        case actions.START_UPDATE_SINGLE_RESELLER_EVENTS: {
            return {
                ...state,
                singleUpdating: true
            };
        }
        case actions.UPDATE_SINGLE_RESELLER_EVENTS_SUCCESSFUL: {
            return {
                ...state,
                singleUpdating: false
            };
        }
        case actions.UPDATE_SINGLE_RESELLER_EVENTS_FAILED: {
            return {
                ...state,
                singleUpdating: false,
                singleError: response
            };
        }
        case actions.STORE_SINGLE_RESELLER_DATA: {
            return Object.assign({}, state, {
                [id]: response.data
            });
        }
        case actions.STORE_RESELLER_EVENTS: {
            return Object.assign({}, state, {
                events: { list: response.data }
            });
        }

        default:
            return state;
    }
};
