import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import Svg from 'erpcomponents/Svg';
import PropTypes from 'prop-types';
import enviromentVariables from 'erputils/enviromentVariables';
import GoogleMapLoader from 'react-google-maps-loader';
import GooglePlacesSuggest from 'react-google-places-suggest';
import Input from '../../../Input';
import '../../Location.scss';

class FullAddress extends Component {
    /**
     *
     * @param props
     */
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            typing: false,
            cursor: 0
        };
    }

    // region Events

    /**
     *
     * @param ev
     * @param place
     */
    onSuggestionSelect(ev, place) {
        ev.preventDefault();
        this.getInfoFromPlaceId(place.place_id);
        this.setState({
            typing: false
        });
        this.getInfoFromPlaceId = this.getInfoFromPlaceId.bind(this);
    }

    // endregion

    // region Callbacks

    /**
     *
     * @param placeId
     */
    getInfoFromPlaceId(placeId) {
        const { google } = window;
        const { onAddressSelected } = this.props;
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ placeId }, results => onAddressSelected(results));
    }

    // endregion

    // region Renders

    /**
     *
     * @param props
     * @param state
     * @returns {*}
     */
    renderFullAddress(props, state) {
        const { input, meta, fieldProps, fieldAttr, field } = props;
        const { typing, cursor } = state;

        let resultLength = 0;
        let results = [];

        const hasValue =
            (input.value && (input.value.length > 0 || input.value)) || input.value === 0;

        return (
            <Input
                meta={meta}
                input={input}
                field={field}
                fieldProps={fieldProps}
                fieldAttr={fieldAttr}
            >
                <GoogleMapLoader
                    params={{
                        libraries: 'places,geocode,geometry',
                        key: enviromentVariables.GOOGLE_MAPS_API_KEY
                    }}
                    render={googleMaps =>
                        googleMaps && (
                            <GooglePlacesSuggest
                                googleMaps={googleMaps}
                                displayPoweredByGoogle={false}
                                autocompletionRequest={{ input: input.value }}
                                customContainerRender={items => {
                                    results = items;
                                    resultLength = items.length;
                                    return (
                                        <Fragment>
                                            {typing && (
                                                <ul className="location__dropdown">
                                                    {items.map((item, index) => {
                                                        return (
                                                            <li
                                                                key={item.id}
                                                                className={`location__dropdown-item ${
                                                                    cursor === index
                                                                        ? 'location__dropdown-item--active'
                                                                        : ''
                                                                }`}
                                                            >
                                                                <button
                                                                    type="button"
                                                                    className="location__dropdown-btn"
                                                                    onClick={ev =>
                                                                        this.onSuggestionSelect(
                                                                            ev,
                                                                            item
                                                                        )
                                                                    }
                                                                    onKeyPress={ev =>
                                                                        this.onSuggestionSelect(
                                                                            ev,
                                                                            item
                                                                        )
                                                                    }
                                                                >
                                                                    {item.description}
                                                                </button>
                                                            </li>
                                                        );
                                                    })}
                                                    {!resultLength && (
                                                        <li
                                                            key="ganoresaults"
                                                            className="location__dropdown-message"
                                                        >
                                                            <FormattedMessage
                                                                id="FullAddress.NoResults"
                                                                defaultMessage="No Results!"
                                                            />
                                                        </li>
                                                    )}
                                                </ul>
                                            )}
                                        </Fragment>
                                    );
                                }}
                            >
                                <input
                                    type="text"
                                    {...input}
                                    {...fieldAttr}
                                    id={input.name}
                                    value={input.value}
                                    className="input__field"
                                    aria-label={`${input.name}`}
                                    autoComplete="off"
                                    onChange={ev =>
                                        input.onChange(() => {
                                            const { value } = ev.currentTarget;
                                            this.setState({
                                                typing: true
                                            });
                                            return value;
                                        })
                                    }
                                    onBlur={() => {
                                        input.onBlur(() => {
                                            setTimeout(() => {
                                                this.setState({
                                                    cursor: 0,
                                                    typing: false
                                                });
                                            }, 500);
                                        });
                                    }}
                                    onKeyDown={ev => {
                                        if (ev.keyCode === 38 && cursor > 0) {
                                            this.setState(prevState => ({
                                                cursor: prevState.cursor - 1
                                            }));
                                        } else if (ev.keyCode === 40 && cursor < resultLength - 1) {
                                            this.setState(prevState => ({
                                                cursor: prevState.cursor + 1
                                            }));
                                        } else if (ev.keyCode === 13) {
                                            this.onSuggestionSelect(ev, results[cursor]);
                                        }
                                    }}
                                />
                                {hasValue && (
                                    <span
                                        role="button"
                                        aria-label={`Clear ${input.name}`}
                                        tabIndex="-1"
                                        className="input__action input__action--clear"
                                        onKeyPress={() => input.onChange('')}
                                        onClick={() => input.onChange('')}
                                    >
                                        <Svg icon="close" />
                                    </span>
                                )}
                            </GooglePlacesSuggest>
                        )
                    }
                />
            </Input>
        );
    }

    /**
     *
     * @returns {*}
     */
    render() {
        return <Fragment>{this.renderFullAddress(this.props, this.state)}</Fragment>;
    }

    // endregion
}

FullAddress.defaultProps = {
    fieldProps: {},
    fieldAttr: {},
    field: {},
    input: {},
    meta: {},
    onAddressSelected: () => {}
};
FullAddress.propTypes = {
    fieldProps: PropTypes.oneOfType([PropTypes.object]),
    fieldAttr: PropTypes.oneOfType([PropTypes.object]),
    field: PropTypes.oneOfType([PropTypes.object]),
    input: PropTypes.oneOfType([PropTypes.object]),
    meta: PropTypes.oneOfType([PropTypes.object]),
    onAddressSelected: PropTypes.func
};
export default FullAddress;
