import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import reduxFormErrorMapper from 'erputils/reduxFormErrorMapper';
import { Field, reduxForm, SubmissionError, reset } from 'redux-form';
import Form, { Text } from 'erpcore/components/Form';
import valueValidation from 'erputils/valueValidation';
import LayoutManager from 'erputils/LayoutManager';
import PageHeader from 'erpcomponents/Layout/PageHeader';
import PageContent from 'erpcomponents/Layout/PageContent';
import Button from 'erpcomponents/Button';
import { actions as tableTemplatesActions } from 'erpcore/screens/Venues/screens/VenuesEdit/TableTemplates/TableTemplates.reducer';
import {
    getNewTables,
    getCreatedTableTemplate
} from 'erpcore/screens/Venues/screens/VenuesEdit/TableTemplates/TableTemplates.selectors';
import LocationEditTabs from 'erpcore/screens/Venues/components/LocationEditTabs';
import { getVenueName } from 'erpcore/screens/Venues/Venues.selectors';

class TableTemplateCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false
        };
    }

    handleSaveNewItem = ({ itemData = {} }) => {
        const { dispatch } = this.props;

        itemData.max_seats = parseInt(itemData.max_seats, 10);
        itemData.min_seats = parseInt(itemData.min_seats, 10);
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: tableTemplatesActions.START_CREATE_TABLE,
                formData: itemData
            })
        ).catch(() => {
            // throw new SubmissionError(reduxFormErrorMapper(error));
        });
        // .then(() => {
        //     this.handleAfterAnyTicketApiUpdate();
        // });
    };

    onSubmit = formData => {
        const { dispatch, newTables, match } = this.props;
        formData.tables = newTables;
        formData.locations = [`/api/locations/${match.params.id}`];
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: tableTemplatesActions.START_CREATE_TABLE_TEMPLATE,
                formData,
                venueId: match.params.id
            });
        })
            .then(() => {
                this.setState({ redirect: true });
                dispatch(reset('TableTemplateCreateForm'));
            })
            .catch(error => {
                throw new SubmissionError(reduxFormErrorMapper(error));
            });
    };

    render() {
        const {
            handleSubmit,
            submitting,
            pristine,
            invalid,
            match,
            venueName,
            createdTemplate,
            history
        } = this.props;
        const { redirect } = this.state;
        const venue = venueName && ` - ${venueName}`;
        const id = createdTemplate.data && createdTemplate.data.id;
        if (redirect) {
            history.push(`${id}/edit`);
            this.setState({ redirect: false });
        }
        return (
            <LayoutManager slot="main" className="main--narrow" layoutType="merge">
                <PageHeader
                    title={
                        <FormattedMessage
                            id="TableTemplateForm.title"
                            defaultMessage="Edit Venue {venue}"
                            values={{ venue }}
                        />
                    }
                    backButtonLink={`/venues/${match.params.id}/edit/table-templates`}
                />
                <PageContent>
                    <LocationEditTabs />
                    <Form onSubmit={handleSubmit(this.onSubmit)}>
                        <Form.Row>
                            <Field
                                name="name"
                                id="name"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="TableTemplateForm.TemplateName"
                                            defaultMessage="Template Name"
                                        />
                                    ),
                                    clearable: true
                                }}
                                component={Text}
                                validate={valueValidation([{ validator: 'required' }])}
                            />
                        </Form.Row>
                        {/* <Form.Row>
                            <Form.SectionTitle>Tables</Form.SectionTitle>
                        </Form.Row>
                        <Form.Row>
                            <FieldArray
                                name="tables"
                                component={Repeater}
                                RepeatableItem={TableTemplateRepeaterItem}
                                // loading={
                                //     singleEventFetching ||
                                //     singleTicketTypesCreating ||
                                //     singleTicketTypesDeleting
                                // }
                                data={{
                                    match
                                }}
                                deleteModalTitle={
                                    <FormattedMessage
                                        id="EventTickets.Repeater.deleteModal.title"
                                        defaultMessage="Delete Ticket Type"
                                    />
                                }
                                deleteModalSubTitle={
                                    <FormattedMessage
                                        id="EventTickets.Repeater.deleteModal.subTitle"
                                        defaultMessage="Are you sure you wanna delete this Ticket Type?"
                                    />
                                }
                                onSortEnd={this.handleSort}
                                onSaveNewItem={this.handleSaveNewItem}
                                onRemoveItem={this.handleRemoveItem}
                                addNewLabel="Add New Table"
                            />
                        </Form.Row> */}
                        <Form.Row>
                            <Button
                                submit
                                disabled={pristine || invalid}
                                loading={submitting}
                                label={
                                    <FormattedMessage
                                        id="TableTemplateForm.Create"
                                        defaultMessage="Add New Table Template"
                                    />
                                }
                            />
                        </Form.Row>
                    </Form>
                </PageContent>
            </LayoutManager>
        );
    }
}

TableTemplateCreate.defaultProps = {
    submitting: false,
    pristine: false,
    invalid: false,
    venueName: '',
    createdTemplate: {}
};

TableTemplateCreate.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    newTables: PropTypes.oneOfType([PropTypes.array]).isRequired,
    match: PropTypes.oneOfType([PropTypes.object]).isRequired,
    venueName: PropTypes.string,
    createdTemplate: PropTypes.oneOfType([PropTypes.object]),
    history: PropTypes.oneOfType([PropTypes.object]).isRequired
};

TableTemplateCreate = reduxForm({
    form: 'TableTemplateCreateForm',
    enableReinitialize: true,
    keepDirtyOnReinitialize: false, // important
    updateUnregisteredFields: false // important
})(TableTemplateCreate);

const mapStateToProps = state => ({
    newTables: getNewTables(state, 'tableTemplates'),
    venueName: getVenueName(state, 'venues'),
    createdTemplate: getCreatedTableTemplate(state)
});

const mapDispatchToProps = dispatch => ({
    onSubmit: formData => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: tableTemplatesActions.START_CREATE_TABLE_TEMPLATE,
                formData
            });
        })
            .then(() => {
                dispatch(reset('TableTemplateCreateForm'));
            })
            .catch(error => {
                throw new SubmissionError(reduxFormErrorMapper(error));
            });
    }
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(TableTemplateCreate)
);
