import React, { Component } from 'react';
import LayoutManager from 'erputils/LayoutManager';
import PageHeader from 'erpcomponents/Layout/PageHeader';
import PageContent from 'erpcomponents/Layout/PageContent';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { diff } from 'deep-object-diff';
import Button from 'erpcomponents/Button';
import { FormattedMessage } from 'react-intl';
import { Field, reduxForm /* SubmissionError, reset */ } from 'redux-form';
import Form, { Text } from 'erpcore/components/Form';
import { connect } from 'react-redux';
// import reduxFormErrorMapper from 'erputils/reduxFormErrorMapper';
import PageLoader from 'erpcomponents/PageLoader';
import { actions as printCardActions } from 'erpcore/screens/Settings/screens/PrintSettings/PrintCardSettings/PrintCardSettings.reducer';
import {
    getPrintSettings,
    getPrintSettingsFetching
} from 'erpcore/screens/Settings/screens/PrintSettings/PrintCardSettings/PrintCardSettings.selectors';
import valueValidation from 'erpsrc/utils/valueValidation';
import Card2x3Width from '../components/Card2x3.5Width';
import Card2x3Height from '../components/Card2x3.5Height';
import Card2x35Offset from '../components/Card2x3.5Offset';
import Card2x35HorizontalOffset from '../components/Card2x3.5HorizontalOffset';
import Card2x35ContainerWidth from '../components/Card2x3.5ContainerWidth';

class PrintCardSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        const { fetchPrintSettings } = this.props;
        fetchPrintSettings();
    }

    onSubmit(formData) {
        const { dispatch, fetchPrintSettings, initialValues } = this.props;
        formData = diff(initialValues, formData);
        return new Promise((resolve, reject) => {
            if (formData.title) {
                dispatch({
                    promise: { resolve, reject },
                    type: printCardActions.START_UPDATE_PRINT_SETTINGS,
                    property: 'title',
                    formData: { value: formData.title }
                });
            }
            if (formData.width) {
                dispatch({
                    promise: { resolve, reject },
                    type: printCardActions.START_UPDATE_PRINT_SETTINGS,
                    property: 'width',
                    formData: { value: formData.width }
                });
            }
        }).then(() => fetchPrintSettings());
    }

    render() {
        // const { onSyncNow, getData } = this.props;
        const { handleSubmit, singleFetching, submitting, pristine, invalid } = this.props;
        return (
            <LayoutManager slot="main" className="main--narrow" layoutType="merge">
                <PageHeader title="Settings" />
                <PageContent>
                    <Form onSubmit={handleSubmit(this.onSubmit)}>
                        {singleFetching === true && <PageLoader />}

                        <Form.SectionTitle>
                            <FormattedMessage
                                id="Settings.PrintCardSettings.Title"
                                defaultMessage="Print Settings - 4x6 width"
                            />
                        </Form.SectionTitle>
                        <p>Card container width is represented in %. Maximum value is 100%. </p>
                        <Form.Row>
                            <Field
                                name="width"
                                id="width"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="Settings.PrintCardSettings.Margin"
                                            defaultMessage="4x6 Card container width in %"
                                        />
                                    ),

                                    clearable: true
                                }}
                                validate={valueValidation([
                                    {
                                        validator: 'numericality',
                                        args: { '<': 101, msg: 'Max. value is 100' }
                                    }
                                ])}
                                component={Text}
                            />
                        </Form.Row>
                        <Form.Row>
                            <Field
                                name="title"
                                id="title"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="Settings.PrintCardSettings.Name"
                                            defaultMessage="4x6 Card Title"
                                        />
                                    ),

                                    clearable: true,
                                    validation: 'required'
                                }}
                                component={Text}
                            />
                        </Form.Row>
                        <Button
                            disabled={pristine || invalid}
                            loading={submitting}
                            label="Save"
                            submit
                        />
                    </Form>

                    <Card2x3Width />
                    <Card2x3Height />
                    <Card2x35Offset />
                    <Card2x35HorizontalOffset />
                    <Card2x35ContainerWidth />
                </PageContent>
            </LayoutManager>
        );
    }
}

PrintCardSettings.defaultProps = {
    submitting: false,
    pristine: false,
    invalid: false,
    singleFetching: false,
    fetchPrintSettings: () => {},
    initialValues: {}
};

PrintCardSettings.propTypes = {
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
    singleFetching: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    fetchPrintSettings: PropTypes.func,
    initialValues: PropTypes.oneOfType([PropTypes.object])
};

PrintCardSettings = reduxForm({
    form: 'PrintCardSettingsForm',
    enableReinitialize: true
})(PrintCardSettings);

const mapStateToProps = state => ({
    initialValues: getPrintSettings(state),
    singleFetching: getPrintSettingsFetching(state)
});

const mapDispatchToProps = dispatch => ({
    fetchPrintSettings: () => {
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: printCardActions.START_FETCHING_PRINT_SETTINGS
            })
        ).catch(error => error);
    }
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(PrintCardSettings)
);
