import React from 'react';
import { Field } from 'redux-form';
// import Button from 'erpcomponents/Button';
import Form, { Text, Autocomplete } from 'erpcore/components/Form';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import valueValidation from 'erputils/valueValidation';

class GroupSplitRepeaterItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            touched: false
        };
        this.debounceDelay = 1000;
    }

    handleChange = (event, newValue, previousValue, name) => {
        const { member, isNewRepeaterItem } = this.props;

        if (!isNewRepeaterItem) {
            let nameForApi = name.replace(`${member}.`, '');
            if (nameForApi === 'room.iri') {
                nameForApi = 'room';
            }
            const updates = { [nameForApi]: newValue };
            const { pendingUpdates } = this.state;
            const mergedUpdates = { ...pendingUpdates, ...updates };
            this.setState({ pendingUpdates: mergedUpdates });
            this.dispatchUpdate(mergedUpdates);
        }
    };

    onTouched = () => {
        this.setState({ touched: true });
    };

    isValid(validatorFunctions = [], value) {
        const errors = validatorFunctions.map(validatorFunction => {
            return validatorFunction(value);
        });
        const isNotValid = errors.find(item => item !== undefined);

        return !isNotValid;
    }

    removePendingUpdates() {
        this.setState({ pendingUpdates: {} });
    }

    render() {
        const { member, data } = this.props;
        const { touched } = this.state;
        const { primaryGroup, eventId } = data;
        const index = parseInt(member.match(/\d+/g)[0], 10);

        return (
            <Form.Row>
                <Form.Columns breakOnMobile>
                    <Form.Column>
                        <Field
                            name={`${member}.name`}
                            id="name"
                            fieldProps={{
                                label: 'Group Name'
                            }}
                            fieldAttr={{ required: true }}
                            component={Text}
                            format={() => {
                                return `${primaryGroup.name}-${index + 1}`;
                            }}
                            normalize={() => {
                                return `${primaryGroup.name}-${index + 1}`;
                            }}
                            onChange={() => this.onTouched()}
                            // validate={valueValidation([{ validator: 'required' }])}
                        />
                    </Form.Column>

                    <Form.Column autoWidth>
                        <Field
                            name={`${member}.group_size`}
                            id="group_size"
                            fieldProps={{
                                label: 'Number of people'
                            }}
                            component={Text}
                        />
                        <Field
                            name={`${member}.tables`}
                            id="table"
                            format={value => {
                                if (value instanceof Array && value[0] && !touched) {
                                    return value[0].table_number;
                                }

                                return value;
                            }}
                            fieldProps={{
                                label: 'Table',
                                clearable: false,
                                options: {
                                    endpoint: `api/tables?include=event&filters[event.id][equals]=${eventId &&
                                        eventId}&pagination=false`,
                                    mapData: {
                                        label: 'table_number',
                                        value: 'iri'
                                    }
                                }
                            }}
                            component={Autocomplete}
                        />
                    </Form.Column>
                </Form.Columns>
            </Form.Row>
        );
    }
}

GroupSplitRepeaterItem.defaultProps = {
    member: null,
    isNewRepeaterItem: null,
    data: null
};

GroupSplitRepeaterItem.propTypes = {
    // dispatch: PropTypes.func.isRequired,
    member: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isNewRepeaterItem: PropTypes.bool,
    data: PropTypes.oneOfType([PropTypes.object])
};

export default connect()(GroupSplitRepeaterItem);
