import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import LayoutManager from 'erputils/LayoutManager';
import PageHeader from 'erpcomponents/Layout/PageHeader';
import PageContent from 'erpcomponents/Layout/PageContent';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import reduxFormErrorMapper from 'erputils/reduxFormErrorMapper';
import { Field, reduxForm, SubmissionError, reset } from 'redux-form';
import Form, {
    Text,
    Switch,
    MultiCreatable,
    Autocomplete,
    Email,
    Phone
} from 'erpcore/components/Form';
import valueValidation from 'erputils/valueValidation';
import Button from 'erpcomponents/Button';
import { actions as resellersActions } from 'erpcore/screens/Resellers/Resellers.reducer';
import './ResellerCreate.scss';

class ResellerCreate extends Component {
    constructor(props) {
        super(props);
        this.state = { eventsIncluded: false };
        this.toggleEventsIncluded = this.toggleEventsIncluded.bind(this);
    }

    toggleEventsIncluded() {
        const { eventsIncluded } = this.state;

        this.setState({ eventsIncluded: !eventsIncluded });
    }

    render() {
        const { eventsIncluded } = this.state;
        const { handleSubmit, submitting, pristine, invalid, onSubmit } = this.props;
        return (
            <LayoutManager slot="main" className="main--narrow" layoutType="merge">
                <PageHeader
                    title={
                        <FormattedMessage
                            id="ResellerCreate.title"
                            defaultMessage="Create Reseller Site"
                        />
                    }
                    backButtonLink="/resellers"
                />
                <PageContent>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Column>
                            <Form.Row>
                                <Field
                                    name="reseller_primary_account"
                                    id="reseller_primary_account"
                                    fieldProps={{
                                        label: (
                                            <FormattedMessage
                                                id="ResellerCreate.PrimaryAccount"
                                                defaultMessage="Reseller Primary Account"
                                            />
                                        ),
                                        options: {
                                            endpoint: '/api/users',
                                            mapData: {
                                                value: 'iri',
                                                label: 'first_name'
                                            }
                                        },
                                        clearable: true
                                    }}
                                    component={Autocomplete}
                                    validate={valueValidation([{ validator: 'required' }])}
                                    fieldAttr={{ required: true }}
                                />
                            </Form.Row>
                            <Form.Row>
                                <Field
                                    name="company"
                                    id="company"
                                    fieldProps={{
                                        label: (
                                            <FormattedMessage
                                                id="ResellerCreate.Company"
                                                defaultMessage="Company"
                                            />
                                        ),
                                        options: {
                                            endpoint: '/api/companies',
                                            mapData: {
                                                value: 'iri',
                                                label: 'name'
                                            }
                                        },
                                        clearable: true
                                    }}
                                    component={Autocomplete}
                                    validate={valueValidation([{ validator: 'required' }])}
                                    fieldAttr={{ required: true }}
                                />
                            </Form.Row>
                            <Form.Row>
                                <Field
                                    name="contact_number"
                                    id="contact_number"
                                    fieldProps={{
                                        label: (
                                            <FormattedMessage
                                                id="ResellerCreate.Contact"
                                                defaultMessage="Contact"
                                            />
                                        ),
                                        clearable: true
                                    }}
                                    component={Phone}
                                    validate={valueValidation([{ validator: 'required' }])}
                                    fieldAttr={{ required: true }}
                                />
                            </Form.Row>
                            <Form.Row>
                                <Field
                                    name="email"
                                    id="email"
                                    fieldProps={{
                                        label: (
                                            <FormattedMessage
                                                id="ResellerCreate.Email"
                                                defaultMessage="Email Address"
                                            />
                                        ),
                                        clearable: true
                                    }}
                                    component={Email}
                                    validate={valueValidation([
                                        { validator: 'required' },
                                        { validator: 'email' }
                                    ])}
                                    fieldAttr={{ required: true }}
                                />
                            </Form.Row>
                            <Form.Row>
                                <Field
                                    name="reseller_name"
                                    id="reseller_name"
                                    fieldProps={{
                                        label: (
                                            <FormattedMessage
                                                id="ResellerCreate.Name"
                                                defaultMessage="Reseller Name"
                                            />
                                        ),
                                        clearable: true
                                    }}
                                    component={Text}
                                    validate={valueValidation([{ validator: 'required' }])}
                                    fieldAttr={{ required: true }}
                                />
                            </Form.Row>
                            <Form.Row>
                                <Field
                                    name="nye_sub_domain"
                                    id="nye_sub_domain"
                                    fieldProps={{
                                        label: (
                                            <FormattedMessage
                                                id="ResellerCreate.MicrositeUrl"
                                                defaultMessage="Microsite URL"
                                            />
                                        ),
                                        clearable: true
                                    }}
                                    component={Text}
                                    format={value => {
                                        const mask = '.nye.com';
                                        return value === null || value === undefined
                                            ? mask
                                            : `${value}${mask}`;
                                    }}
                                    normalize={value => {
                                        const mask = '.nye.com';
                                        return value === null || value === undefined
                                            ? null
                                            : value.replace(mask, '');
                                    }}
                                />
                            </Form.Row>
                            <Form.Row>
                                <span>
                                    You can only use lowercase letters, hyphen and numbers for
                                    Microsite URL. Microsite URL will be deployed to .nye.com.
                                </span>
                            </Form.Row>

                            <div className="events-toggle">
                                <span
                                    className={`events-toggle${
                                        !eventsIncluded ? '__enabled' : '__disabled'
                                    }`}
                                >
                                    Events excluded
                                </span>
                                <Field
                                    name="includeEvents"
                                    id="includeEvents"
                                    input={{
                                        value: eventsIncluded,
                                        onChange: this.toggleEventsIncluded
                                    }}
                                    fieldProps={{
                                        on: {
                                            label: 'Events included',
                                            value: true,
                                            id: 'switch-on-true'
                                        },
                                        off: {
                                            label: 'Events excldued',
                                            value: false,
                                            id: 'switch-off-false'
                                        },
                                        type: 'single'
                                    }}
                                    component={Switch}
                                />
                                <span
                                    className={`events-toggle${
                                        eventsIncluded ? '__enabled' : '__disabled'
                                    }`}
                                >
                                    Events included
                                </span>
                            </div>
                            {eventsIncluded ? (
                                <Form.Row>
                                    <Field
                                        name="events_included"
                                        id="events_included"
                                        fieldProps={{
                                            options: {
                                                endpoint: '/api/events',
                                                mapData: {
                                                    value: 'iri',
                                                    label: 'name'
                                                }
                                            },
                                            clearable: true
                                        }}
                                        component={MultiCreatable}
                                    />
                                </Form.Row>
                            ) : (
                                <Form.Row>
                                    <Field
                                        name="events_excluded"
                                        id="events_excluded"
                                        fieldProps={{
                                            options: {
                                                endpoint: '/api/events',
                                                mapData: {
                                                    value: 'iri',
                                                    label: 'name'
                                                }
                                            },
                                            clearable: true
                                        }}
                                        component={MultiCreatable}
                                    />
                                </Form.Row>
                            )}

                            <Form.Row>
                                <Button
                                    submit
                                    disabled={pristine || invalid}
                                    loading={submitting}
                                    label={
                                        <FormattedMessage
                                            id="Reseller.Create"
                                            defaultMessage="Create"
                                        />
                                    }
                                />
                            </Form.Row>
                        </Form.Column>
                    </Form>
                </PageContent>
            </LayoutManager>
        );
    }
}

ResellerCreate.defaultProps = {
    submitting: false,
    pristine: false,
    invalid: false,
    onSubmit: () => {}
};

ResellerCreate.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool
};

ResellerCreate = reduxForm({
    form: 'ResellerCreateForm',
    enableReinitialize: true
})(ResellerCreate);

const mapDispatchToProps = dispatch => ({
    onSubmit: formData => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: resellersActions.START_CREATE_RESELLER,
                formData
            });
        })
            .then(() => {
                dispatch(reset('ResellerCreateForm'));
            })
            .catch(error => {
                throw new SubmissionError(reduxFormErrorMapper(error));
            });
    }
});

export default connect(
    null,
    mapDispatchToProps
)(ResellerCreate);
