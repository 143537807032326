import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import StatusBadge from 'erpcomponents/StatusBadge';
import './EventInfo.scss';
import { FormattedMessage, FormattedDate } from 'react-intl';
import Button from 'erpcomponents/Button';
import { getEventData, getSingleEventFetching } from 'erpcore/screens/Events/Events.selectors';
import { actions as eventsActions } from 'erpcore/screens/Events/Events.reducer';
import ElementLoader from 'erpcomponents/ElementLoader';

class EventInfo extends Component {
    componentDidMount() {
        const { event, fetchEventData } = this.props;
        if (!Object.keys(event).length) {
            fetchEventData();
        }
        if (event.location && !event.location.name) {
            fetchEventData();
        }
    }

    componentDidUpdate(prevProps) {
        const { event, fetchEventData } = this.props;
        if (event && event.location && prevProps.event && prevProps.event.location) {
            if (
                Object.keys(event.location).length !== Object.keys(prevProps.event.location).length
            ) {
                fetchEventData();
            }
        }
    }

    // componentDidUpdate(prevProps) {
    //     console.log('prevProps', prevProps);
    //     const { fetchEventData, event } = this.props;
    //     console.log('this.props', this.props);
    //     if (event && event.location && !event.location.address) {
    //         console.log('uslo');
    //         fetchEventData();
    //     }
    // }

    formatDate(date) {
        return (
            <Fragment>
                <FormattedDate value={date} weekday="long" />
                {', '}
                <FormattedDate value={date} day="numeric" month="long" year="numeric" />
            </Fragment>
        );
    }

    render() {
        const {
            event,
            className,
            setToLive,
            setToNotifyMe,
            fetchEventData,
            singleFetching,
            match
        } = this.props;

        const { id, name, location, start, end, status } = event;
        let statusLabel = '';
        let statusBadge = '';

        switch (status) {
            case 'Live':
                statusLabel = 'Live';
                statusBadge = 'fulfilled';
                break;
            case 'Notify Me':
                statusLabel = 'Notify Me';
                statusBadge = 'new';
                break;

            default:
                statusLabel = 'Offline';
                statusBadge = 'voided';
                break;
        }

        let venue = '';
        if (location) {
            const { venue_name: venueName, zip, city, country } = location;
            let { address } = location;

            if (!address) {
                address = '';
            } else {
                address = `${location.address} |`;
            }

            venue = `${venueName} | ${address} ${city && city.name}, ${zip} | ${country &&
                country.name}`;
        }

        return (
            <div className={`event-info ${className || ''}`}>
                <h2 className="event-info__title">{name}</h2>
                <div className="event-info__status">
                    <StatusBadge
                        type={statusBadge}
                        // eslint-disable-next-line no-unneeded-ternary
                        text={statusLabel}
                    />
                </div>
                <div className="event-info__content">
                    <p className="event-info__venue">{venue}</p>
                    <p className="event-info__date">
                        {start && this.formatDate(start)}
                        {end && ' - '}
                        {end && this.formatDate(end)}
                    </p>
                </div>
                {singleFetching && <ElementLoader />}

                <div className="event-info__actions">
                    <div className="event-info__actions-primary">
                        <Button
                            href={`/events/${id}/edit/event-details`}
                            variation="secondary"
                            size="small"
                            label={<FormattedMessage id="EventInfo.Edit" defaultMessage="Edit" />}
                            disabled={match.url.includes('edit')}
                        />
                        <Button
                            href={`/events/${id}/manage/event-settings`}
                            variation="secondary"
                            size="small"
                            label={
                                <FormattedMessage id="EventInfo.Manage" defaultMessage="Manage" />
                            }
                            disabled={match.url.includes('manage')}
                        />
                    </div>
                    <div className="event-info__actions-secondary">
                        {statusLabel && statusLabel === 'Notify Me' && (
                            <React.Fragment>
                                <Button
                                    onClick={() => setToLive('Live', fetchEventData)}
                                    variation="tertiary"
                                    size="small"
                                    label={
                                        <FormattedMessage
                                            id="EventInfo.SetToLive"
                                            defaultMessage="Set To Live"
                                        />
                                    }
                                />
                                <Button
                                    onClick={() => setToLive('Offline', fetchEventData)}
                                    variation="tertiary"
                                    size="small"
                                    label={
                                        <FormattedMessage
                                            id="EventInfo.SetToOffline"
                                            defaultMessage="Set To Offline"
                                        />
                                    }
                                />
                            </React.Fragment>
                        )}
                        {statusLabel && statusLabel === 'Offline' && (
                            <React.Fragment>
                                <Button
                                    onClick={() => setToLive('Live', fetchEventData)}
                                    variation="tertiary"
                                    size="small"
                                    label={
                                        <FormattedMessage
                                            id="EventInfo.SetToLive"
                                            defaultMessage="Set To Live"
                                        />
                                    }
                                />
                                <Button
                                    onClick={() => setToNotifyMe('Notify Me', fetchEventData)}
                                    variation="tertiary"
                                    size="small"
                                    label={
                                        <FormattedMessage
                                            id="EventInfo.SetToNotifyMe"
                                            defaultMessage="Set To Notify Me"
                                        />
                                    }
                                />
                            </React.Fragment>
                        )}
                        {statusLabel && statusLabel === 'Live' && (
                            <React.Fragment>
                                <Button
                                    onClick={() => setToLive('Offline', fetchEventData)}
                                    variation="tertiary"
                                    size="small"
                                    label={
                                        <FormattedMessage
                                            id="EventInfo.SetToOffline"
                                            defaultMessage="Set To Offline"
                                        />
                                    }
                                />
                                <Button
                                    onClick={() => setToNotifyMe('Notify Me', fetchEventData)}
                                    variation="tertiary"
                                    size="small"
                                    label={
                                        <FormattedMessage
                                            id="EventInfo.SetToNotifyMe"
                                            defaultMessage="Set To Notify Me"
                                        />
                                    }
                                />
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

EventInfo.defaultProps = {
    className: '',
    event: {},
    match: {},
    singleFetching: false
};

EventInfo.propTypes = {
    className: PropTypes.string,
    event: PropTypes.oneOfType([PropTypes.object]),
    match: PropTypes.oneOfType([PropTypes.object]),
    fetchEventData: PropTypes.func.isRequired,
    setToLive: PropTypes.func.isRequired,
    setToNotifyMe: PropTypes.func.isRequired,
    singleFetching: PropTypes.bool
};

//  Getting initial value populated in the form from the store
const mapStateToProps = (state, ownProps) => ({
    event: getEventData(state, ownProps.match.params.id),
    singleFetching: getSingleEventFetching(state, ownProps.match.params.id)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchEventData: () => {
        // Getting included data from API by setting params
        const params = {
            include:
                'organization,location,location.country,location.state,location.city,ageRequirement,eventSeries,eventCategories,foodTypes,barTypes,timezone'
        };
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: eventsActions.START_FETCHING_SINGLE_EVENT,
                id: ownProps.match.params.id,
                params
            });
        }).catch(error => ({ error }));
    },
    setToLive: (status, refetchData) => {
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: eventsActions.START_UPDATE_SINGLE_EVENT,
                id: ownProps.match.params.id,
                formData: { status }
            })
        )
            .then(() => refetchData())
            .catch(error => {
                return error;
            });
    },
    setToNotifyMe: (status, refetchData) => {
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: eventsActions.START_UPDATE_SINGLE_EVENT,
                id: ownProps.match.params.id,
                formData: { status }
            })
        )
            .then(() => refetchData())
            .catch(error => {
                return error;
            });
    }
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(EventInfo)
);
