import { takeLatest, put, call, select } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import { actions as notificationManagerActions } from 'erputils/NotificationManager/NotificationManager.reducer';
import { getQueryParams } from 'erpcore/components/Listing/Listing.selectors';
import { actions as listingActions } from 'erpcomponents/Listing/Listing.reducer';
import dto from 'erputils/dto';
import { actions as customersActions } from './Customers.reducer';

/**
 * Create Customer
 * @param  {Object} promise
 * @return {Object} formData
 */

export function* createCustomer({ promise, formData }) {
    try {
        const createCustomerAPI = yield restClient.post(`api/customers`, formData);
        yield put({
            type: customersActions.CREATE_CUSTOMER_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: createCustomerAPI.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: customersActions.CREATE_CUSTOMER_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Fetch Customer
 * @param  {Object} promise
 * @return {string} id Customer id
 */
export function* fetchCustomer({ promise, id, params }) {
    try {
        const fetchCustomerAPI = yield restClient.get(`api/customers/${id}`, { params });
        yield put({
            type: customersActions.FETCH_SINGLE_CUSTOMER_SUCCESSFUL
        });
        yield put({
            type: customersActions.STORE_SINGLE_CUSTOMER_DATA,
            id,
            response: dto(fetchCustomerAPI.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: customersActions.FETCH_SINGLE_CUSTOMER_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Update Customer single data
 * @param  {Object} id ID of an Customer
 * @return {Object} Response from API
 */
export function* updateSingleCustomer({ promise, formData, id }) {
    try {
        const updateSingleCustomerAPI = yield restClient.put(`api/customers/${id}`, formData);
        yield put({
            type: customersActions.UPDATE_SINGLE_CUSTOMER_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateSingleCustomerAPI.data
        });
        yield put({
            type: customersActions.STORE_SINGLE_CUSTOMER_DATA,
            id,
            response: dto(updateSingleCustomerAPI.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: customersActions.UPDATE_SINGLE_CUSTOMER_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Delete Single Customer
 * @param  {Object} id ID of an Customer
 * @return {Object} Response from API
 */
export function* deleteSingleCustomer({ promise, id }) {
    try {
        const deleteSingleCustomerAPI = yield restClient.delete(`api/customers/${id}`);
        yield put({
            type: customersActions.DELETE_SINGLE_CUSTOMER_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: deleteSingleCustomerAPI.data
        });
        const params = yield select(getQueryParams, { name: 'customers' });
        yield put({
            type: listingActions.START_FETCHING_LISTING,
            entity: 'CUSTOMERS',
            name: 'customers',
            endpoint: 'api/customers',
            params
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: customersActions.DELETE_SINGLE_CUSTOMER_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Register action to watcher
 */
export const customersSaga = [
    takeLatest(customersActions.START_CREATE_CUSTOMER, createCustomer),
    takeLatest(customersActions.START_FETCHING_SINGLE_CUSTOMER, fetchCustomer),
    takeLatest(customersActions.START_UPDATE_SINGLE_CUSTOMER, updateSingleCustomer),
    takeLatest(customersActions.START_DELETE_SINGLE_CUSTOMER, deleteSingleCustomer)
];
