import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Tabs from 'erpcomponents/Tabs';

import { getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import { hasPermission } from 'erpcore/utils/RolesManager';

const ResellerEditTabs = ({ match }) => {
    const meData = useSelector(getMeData) || {};
    const { id } = match?.params;
    const links = [];

    if (
        hasPermission('CAN_MANAGE_RESELLER_BUNDLE_RESELLER', meData) ||
        hasPermission('CAN_UPDATE_RESELLER_BUNDLE_RESELLER', meData)
    ) {
        links.push({
            link: `/resellers/${id}/edit/general-info`,
            label: (
                <FormattedMessage
                    id="ResellerEdit.Tabs.GeneralInfo"
                    defaultMessage="General Info"
                />
            )
        });
    }

    if (
        hasPermission('CAN_MANAGE_RESELLER_BUNDLE_RESELLER', meData) ||
        hasPermission('CAN_UPDATE_RESELLER_BUNDLE_RESELLER', meData)
    ) {
        links.push({
            link: `/resellers/${id}/edit/events`,
            label: <FormattedMessage id="ResellerEdit.Tabs.Events" defaultMessage="Events" />
        });
    }

    if (
        hasPermission('CAN_MANAGE_RESELLER_BUNDLE_RESELLER', meData) ||
        hasPermission('CAN_UPDATE_RESELLER_BUNDLE_RESELLER', meData)
    ) {
        links.push({
            link: `/resellers/${id}/edit/seo`,
            label: <FormattedMessage id="ResellerEdit.Tabs.SEO" defaultMessage="SEO" />
        });
    }

    if (hasPermission('CAN_MANAGE_RESELLER_BUNDLE_RESELLER', meData)) {
        links.push({
            link: `/resellers/${id}/edit/third-party-connect`,
            label: (
                <FormattedMessage
                    id="ResellerEdit.Tabs.ResellerSettings"
                    defaultMessage="Reseller Settings"
                />
            )
        });
    }

    if (hasPermission('CAN_MANAGE_RESELLER_BUNDLE_RESELLER', meData)) {
        links.push({
            link: `/resellers/${id}/edit/notes`,
            label: <FormattedMessage id="ResellerEdit.Tabs.Notes" defaultMessage="Notes" />
        });
    }

    return <Tabs links={links} />;
};

ResellerEditTabs.defaultProps = {
    match: {}
};

ResellerEditTabs.propTypes = {
    match: PropTypes.oneOfType([PropTypes.object])
};

export default withRouter(ResellerEditTabs);
