import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import LayoutManager from 'erputils/LayoutManager';
import PageLoader from 'erpcomponents/PageLoader';
import Comments from 'erpcomponents/Comments';
import PageHeader from 'erpcomponents/Layout/PageHeader';
import ResellerInfo from 'erpcore/screens/Resellers/components/ResellerInfo';
import ResellerEditTabs from 'erpcore/screens/Resellers/components/ResellerEditTabs';
import PageContent from 'erpcomponents/Layout/PageContent';
import { actions as resellersActions } from 'erpsrc/screens/Resellers/Resellers.reducer';
import { getSingleResellerFetching } from 'erpsrc/screens/Resellers/Resellers.selectors';

const ResellerNotes = ({ match }) => {
    const dispatch = useDispatch();
    const id = match && match.params && match.params.id;
    const singleFetching = useSelector(state => getSingleResellerFetching(state));

    const fetchResellerData = () => {
        // Getting included data from API by setting params
        const params = {
            include:
                'company,resellerPrimaryAccount,eventsIncluded,eventsExcluded,eventDescriptions,eventDescriptions.event'
        };
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: resellersActions.START_FETCHING_SINGLE_RESELLER,
                id,
                params
            });
        }).catch(error => ({ error }));
    };

    useEffect(() => {
        fetchResellerData();
    }, []);

    return (
        <LayoutManager slot="main" layoutType="merge" className="main--narrow">
            <PageHeader
                title={
                    <FormattedMessage
                        id="ResellerEdit.Notes.title"
                        defaultMessage="Edit Reseller"
                    />
                }
                backButtonLink="/resellers"
            />
            <ResellerInfo fetchResellerData={fetchResellerData} />
            <ResellerEditTabs />
            {singleFetching === true && <PageLoader />}
            <PageContent>
                <Comments entity="resellers" />
            </PageContent>
        </LayoutManager>
    );
};

ResellerNotes.defaultProps = {
    match: {}
};

ResellerNotes.propTypes = {
    match: PropTypes.oneOfType([PropTypes.object])
};

export default withRouter(ResellerNotes);
