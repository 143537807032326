import { takeLatest, call, put } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import { actions as ticketTypesReportActions } from './TicketTypesReport.reducer';

/**
 * Fetch Ticket Type
 * @param  {Object} promise
 * @return {Object} params
 */
export function* fetchTicketTypeReportData({ promise, iri, widgetId }) {
    try {
        const fetchTicketTypeReportDataAPI = yield restClient.get(
            `api/ticket-types/report?filters[ticket_types]=${iri}`,
            {
                headers: { Accept: ' application/json' }
            }
        );
        yield put({
            type: ticketTypesReportActions.FETCHING_TICKET_TYPES_REPORT_DATA_SUCCESSFUL,
            widgetId
        });
        yield put({
            type: ticketTypesReportActions.STORE_TICKET_TYPES_REPORT_DATA,
            widgetId,
            response: fetchTicketTypeReportDataAPI.data && fetchTicketTypeReportDataAPI.data
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: ticketTypesReportActions.FETCHING_TICKET_TYPES_REPORT_DATA_FAILED,
            response:
                error && error.response !== undefined && error.response.data
                    ? error.response.data
                    : null
        });
        yield call(promise.reject, error && error.response !== undefined && error.response.data);
    }
}

/**
 * Register action to watcher
 */
export const ticketTypesReportSaga = [
    takeLatest(
        ticketTypesReportActions.START_FETCHING_TICKET_TYPES_REPORT_DATA,
        fetchTicketTypeReportData
    )
];
