import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LayoutManager from 'erputils/LayoutManager';
import { dtoForm } from 'erputils/dto';
import ActivityLog from 'erpcomponents/ActivityLog';
import PageHeader from 'erpcomponents/Layout/PageHeader';
import SingleOrderTabs from 'erpcore/screens/Orders/components/SingleOrderTabs';
import PageLoader from 'erpcomponents/PageLoader';
import PageContent from 'erpcomponents/Layout/PageContent';
import { actions as ordersActions } from 'erpcore/screens/Orders/Orders.reducer';
import {
    getSingleOrderData,
    getSingleOrderFetching
} from 'erpcore/screens/Orders/Orders.selectors';

class OrderActivityLog extends Component {
    componentDidMount() {
        const { fetchOrderData } = this.props;
        fetchOrderData();
    }

    render() {
        const { singleFetching, initialValues } = this.props;

        return (
            <LayoutManager slot="main" layoutType="merge" className="main--narrow">
                <PageHeader
                    title={initialValues && `Order ${initialValues.order_number}`}
                    backButtonLink="/orders"
                />
                {singleFetching ? <PageLoader /> : <SingleOrderTabs />}
                <PageContent>
                    <ActivityLog entity="orders" />
                </PageContent>
            </LayoutManager>
        );
    }
}

OrderActivityLog.defaultProps = {
    initialValues: {},
    fetchOrderData: () => {},
    singleFetching: false
};

OrderActivityLog.propTypes = {
    fetchOrderData: PropTypes.func,
    initialValues: PropTypes.oneOfType([PropTypes.object]),
    singleFetching: PropTypes.bool
};

//  Getting initial value populated in the form from the store
const mapStateToProps = (state, ownProps) => ({
    initialValues: dtoForm(getSingleOrderData(state, ownProps.match.params.id)),
    singleFetching: getSingleOrderFetching(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchOrderData: () => {
        // Getting included data from API by setting params
        const params = {
            include: 'event,company,contacts,contract,comments'
        };
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: ordersActions.START_FETCHING_SINGLE_ORDER,
                id: ownProps.match.params.id,
                params
            });
        }).catch(error => ({ error }));
    }
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(OrderActivityLog)
);
