import React, { Component } from 'react';
import LayoutManager from 'erputils/LayoutManager';
import PageHeader from 'erpcomponents/Layout/PageHeader';
import PageContent from 'erpcomponents/Layout/PageContent';
import { withRouter } from 'react-router-dom';
import { FieldArray, reduxForm, SubmissionError } from 'redux-form';
import HomepageSliderRepeaterItem from 'erpcore/screens/Settings/screens/ResellerAdmin/components/HomepageSliderRepeaterItem';
import ResellerAdminTabs from 'erpcore/screens/Settings/components/ResellerAdminTabs';
import PropTypes from 'prop-types';
import { dtoForm } from 'erputils/dto';
import Form, { Repeater } from 'erpcore/components/Form';
import NotificationManager from 'erputils/NotificationManager';
import { connect } from 'react-redux';
import { getListingFetching, getListingResponse } from 'erpcomponents/Listing/Listing.selectors';
import { actions as listingActions } from 'erpcomponents/Listing/Listing.reducer';
import { actions as homepageSliderActions } from 'erpcore/screens/Settings/screens/ResellerAdmin/HomepageSlider/HomepageSlider.reducer';
import reduxFormErrorMapper from 'erputils/reduxFormErrorMapper';
import {
    getSingleHomepageSliderCreating,
    getSingleHomepageSliderDeleting,
    getSingleHomepageSliderUpdating
} from 'erpcore/screens/Settings/screens/ResellerAdmin/HomepageSlider/HomepageSlider.selectors';

class HomepageSlider extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount = () => {
        const { fetchHomepageSliders } = this.props;
        fetchHomepageSliders();
    };

    handleSaveNewItem = ({ itemData = {} }) => {
        const { dispatch, initialValues } = this.props;

        delete initialValues.included;
        let { value } = initialValues;
        const lastPosition =
            value && value.length >= 1
                ? value.reduce((a, b) => (a.position > b.position ? a : b)).position
                : 0;
        value = value
            ? [...value, { ...itemData, position: value.length === 0 ? 0 : lastPosition + 1 }]
            : [{ ...itemData, position: lastPosition }];
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: homepageSliderActions.START_CREATE_HOMEPAGE_SLIDER,
                formData: {
                    slides: value
                }
            })
        )
            .then(() => {
                this.handleAfterAnyHomepageSliderApiUpdate();
            })
            .catch(() => {});
    };

    handleSortEnd = formData => {
        const { dispatch } = this.props;
        const value = formData.allData.map((record, index) => ({ ...record, position: index }));
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: homepageSliderActions.REQUEST_UPDATE_SINGLE_HOMEPAGE_SLIDER,
                formData: {
                    slides: value
                }
            })
        ).catch(error => {
            throw new SubmissionError(reduxFormErrorMapper(error));
        });
    };

    handleRemoveItem = ({ itemData = {} }) => {
        const { dispatch, initialValues } = this.props;
        const value = initialValues.value.filter(record => record.position !== itemData.position);

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: homepageSliderActions.START_DELETE_SINGLE_HOMEPAGE_SLIDER,
                formData: {
                    slides: value
                }
            })
        )
            .then(() => {
                this.handleAfterAnyHomepageSliderApiUpdate();
            })
            .catch(() => {
                // throw new SubmissionError(reduxFormErrorMapper(error));
            });
    };

    handleAfterAnyHomepageSliderApiUpdate() {
        const { fetchHomepageSliders, reset } = this.props;
        fetchHomepageSliders().then(() => {
            reset();
        });
    }

    render() {
        const {
            homepageSlidersListingFetching,
            initialValues,
            error,
            singleHomepageSliderCreating,
            singleHomepageSliderDeleting,
            singleHomepageSliderUpdating
        } = this.props;
        return (
            <LayoutManager slot="main" className="main--narrow" layoutType="merge">
                <PageHeader title="Settings - Reseller Admin" />
                <ResellerAdminTabs />
                <PageContent>
                    {error && error.code && <NotificationManager code={error.code} />}

                    <Form>
                        <FieldArray
                            name="value"
                            id="value"
                            component={Repeater}
                            RepeatableItem={HomepageSliderRepeaterItem}
                            loading={
                                homepageSlidersListingFetching ||
                                singleHomepageSliderCreating ||
                                singleHomepageSliderDeleting ||
                                singleHomepageSliderUpdating
                            }
                            data={initialValues}
                            onSortStart={() => {}}
                            onSortEnd={this.handleSortEnd}
                            onSaveNewItem={this.handleSaveNewItem}
                            uniqueIdentifier="position"
                            onRemoveItem={this.handleRemoveItem}
                        />
                    </Form>
                </PageContent>
            </LayoutManager>
        );
    }
}

HomepageSlider.defaultProps = {
    // headerMenusData: {},
    homepageSlidersListingFetching: false,
    error: null,
    singleHomepageSliderCreating: false,
    singleHomepageSliderDeleting: false,
    singleHomepageSliderUpdating: false
};

HomepageSlider.propTypes = {
    // headerMenusData: PropTypes.oneOfType([PropTypes.object]),
    fetchHomepageSliders: PropTypes.func.isRequired,
    homepageSlidersListingFetching: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    initialValues: PropTypes.oneOfType([PropTypes.object]).isRequired,
    error: PropTypes.oneOfType([PropTypes.object]),
    singleHomepageSliderCreating: PropTypes.bool,
    singleHomepageSliderDeleting: PropTypes.bool,
    singleHomepageSliderUpdating: PropTypes.bool
};

HomepageSlider = reduxForm({
    form: 'HomepageSliderForm',
    enableReinitialize: true,
    keepDirtyOnReinitialize: false, // important
    updateUnregisteredFields: false // important
})(HomepageSlider);

const mapStateToProps = state => ({
    homepageSlidersListingFetching: getListingFetching(state, 'homepageSlider'),
    homepageSlidersData: dtoForm(getListingResponse(state, 'homepageSlider')),
    initialValues: dtoForm(getListingResponse(state, 'homepageSlider')),
    singleHomepageSliderCreating: getSingleHomepageSliderCreating(state),
    singleHomepageSliderDeleting: getSingleHomepageSliderDeleting(state),
    singleHomepageSliderUpdating: getSingleHomepageSliderUpdating(state)
});

const mapDispatchToProps = dispatch => ({
    fetchHomepageSliders: params => {
        const listingParams = Object.assign({ pagination: false }, params);

        dispatch({
            type: listingActions.START_FETCHING_LISTING,
            params: listingParams,
            entity: 'HOMEPAGE_SLIDERS',
            endpoint: '/api/general-settings/reseller-homepage-slider'
        });
    }
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(HomepageSlider)
);
