// TODO: location of this file is not yet defined
export const actions = {
    START_FETCHING_TICKET_TYPES: 'START_FETCHING_TICKET_TYPES',
    FETCHING_SUCCESSFUL_TICKET_TYPES: 'FETCHING_SUCCESSFUL_TICKET_TYPES',
    FETCHING_FAILED_TICKET_TYPES: 'FETCHING_FAILED_TICKET_TYPES',
    REQUEST_SORT_TICKET_TYPES: 'REQUEST_SORT_TICKET_TYPES',
    START_SORT_TICKET_TYPES: 'START_SORT_TICKET_TYPES',
    SORT_SUCCESSFUL_TICKET_TYPES: 'SORT_SUCCESSFUL_TICKET_TYPES',
    SORT_FAILED_TICKET_TYPES: 'SORT_FAILED_TICKET_TYPES',
    START_CREATE_TICKET_TYPE: 'START_CREATE_TICKET_TYPE',
    CREATE_TICKET_TYPE_SUCCESSFUL: 'CREATE_TICKET_TYPE_SUCCESSFUL',
    CREATE_TICKET_TYPE_FAILED: 'CREATE_TICKET_TYPE_FAILED',
    START_FETCHING_SINGLE_TICKET_TYPE: 'START_FETCHING_SINGLE_TICKET_TYPE',
    FETCH_SINGLE_TICKET_TYPE_SUCCESSFUL: 'FETCH_SINGLE_TICKET_TYPE_SUCCESSFUL',
    FETCH_SINGLE_TICKET_TYPE_FAILED: 'FETCH_SINGLE_TICKET_TYPE_FAILED',
    REQUEST_UPDATE_SINGLE_TICKET_TYPE: 'REQUEST_UPDATE_SINGLE_TICKET_TYPE',
    START_UPDATE_SINGLE_TICKET_TYPE: 'START_UPDATE_SINGLE_TICKET_TYPE',
    UPDATE_SINGLE_TICKET_TYPE_SUCCESSFUL: 'UPDATE_SINGLE_TICKET_TYPE_SUCCESSFUL',
    UPDATE_SINGLE_TICKET_TYPE_FAILED: 'UPDATE_SINGLE_TICKET_TYPE_FAILED',
    START_DELETE_SINGLE_TICKET_TYPE: 'START_DELETE_SINGLE_TICKET_TYPE',
    DELETE_SINGLE_TICKET_TYPE_SUCCESSFUL: 'DELETE_SINGLE_TICKET_TYPE_SUCCESSFUL',
    DELETE_SINGLE_TICKET_TYPE_FAILED: 'DELETE_SINGLE_TICKET_TYPE_FAILED',
    STORE_SINGLE_TICKET_TYPE_DATA: 'STORE_SINGLE_TICKET_TYPE_DATA',

    START_MERGE_TICKET_TYPES: 'START_MERGE_TICKET_TYPES',
    MERGE_TICKET_TYPES_SUCCESSFUL: 'MERGE_TICKET_TYPES_SUCCESSFUL',
    MERGE_TICKET_TYPES_FAILED: 'MERGE_TICKET_TYPES_FAILED'
};

export const initialState = {
    listingFetching: false,
    listingSorting: false,
    listingData: [],
    listingMeta: {},
    listingError: [],
    singleFetching: false,
    singleCreating: false,
    singleDeleting: false,
    singleUpdating: false,
    ticketTypes: [],
    submittingBulkAction: false
};

export default (state = initialState, { type, id, response }) => {
    switch (type) {
        case actions.START_FETCHING_TICKET_TYPES: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_TICKET_TYPES: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_TICKET_TYPES: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        case actions.START_SORT_TICKET_TYPES: {
            return {
                ...state,
                listingSorting: true
            };
        }
        case actions.SORT_SUCCESSFUL_TICKET_TYPES: {
            return {
                ...state,
                listingSorting: false
                // listingResponse: response
            };
        }
        case actions.SORT_FAILED_TICKET_TYPES: {
            return {
                ...state,
                listingSorting: false
                // listingError: response
            };
        }
        case actions.START_CREATE_TICKET_TYPE: {
            return {
                ...state,
                singleCreating: true
            };
        }
        case actions.CREATE_TICKET_TYPE_SUCCESSFUL: {
            return {
                ...state,
                singleCreating: false
            };
        }
        case actions.CREATE_TICKET_TYPE_FAILED: {
            return {
                ...state,
                singleCreating: false
            };
        }
        case actions.START_DELETE_SINGLE_TICKET_TYPE: {
            return {
                ...state,
                singleDeleting: true
            };
        }
        case actions.DELETE_SINGLE_TICKET_TYPE_SUCCESSFUL: {
            return {
                ...state,
                singleDeleting: false
            };
        }
        case actions.DELETE_SINGLE_TICKET_TYPE_FAILED: {
            return {
                ...state,
                singleDeleting: false
            };
        }
        case actions.START_FETCHING_SINGLE_TICKET_TYPE: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.FETCH_SINGLE_TICKET_TYPE_SUCCESSFUL: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.FETCH_SINGLE_TICKET_TYPE_FAILED: {
            return {
                ...state,
                singleFetching: false,
                singleError: response
            };
        }

        case actions.START_UPDATE_SINGLE_TICKET_TYPE: {
            return {
                ...state,
                singleUpdating: true
            };
        }
        case actions.UPDATE_SINGLE_TICKET_TYPE_SUCCESSFUL: {
            return {
                ...state,
                singleUpdating: false
            };
        }
        case actions.UPDATE_SINGLE_TICKET_TYPE_FAILED: {
            return {
                ...state,
                singleUpdating: false,
                singleError: response
            };
        }
        case actions.START_MERGE_TICKET_TYPES: {
            return {
                ...state,
                submittingBulkAction: true
            };
        }
        case actions.MERGE_TICKET_TYPES_SUCCESSFUL: {
            return {
                ...state,
                submittingBulkAction: false
            };
        }
        case actions.MERGE_TICKET_TYPES_FAILED: {
            return {
                ...state,
                submittingBulkAction: false
            };
        }
        case actions.STORE_SINGLE_TICKET_TYPE_DATA: {
            return Object.assign({}, state, {
                [id]: response.data
            });
        }
        default:
            return state;
    }
};
