export const actions = {
    START_FETCHING_ROOMS: 'START_FETCHING_ROOMS',
    FETCHING_SUCCESSFUL_ROOMS: 'FETCHING_SUCCESSFUL_ROOMS',
    FETCHING_FAILED_ROOMS: 'FETCHING_FAILED_ROOMS',
    START_CREATE_ROOM: 'START_CREATE_ROOM',
    CREATE_ROOM_SUCCESSFUL: 'CREATE_ROOM_SUCCESSFUL',
    CREATE_ROOM_FAILED: 'CREATE_ROOM_FAILED',
    START_DELETE_SINGLE_ROOM: 'START_DELETE_SINGLE_ROOM',
    DELETE_SINGLE_ROOM_SUCCESSFUL: 'DELETE_SINGLE_ROOM_SUCCESSFUL',
    DELETE_SINGLE_ROOM_FAILED: 'DELETE_SINGLE_ROOM_FAILED',
    STORE_SINGLE_ROOM_DATA: 'STORE_SINGLE_ROOM_DATA',
    START_UPDATE_SINGLE_ROOM: 'START_UPDATE_SINGLE_ROOM',
    START_FETCHING_SINGLE_ROOM: 'START_FETCHING_SINGLE_ROOM',
    FETCH_SINGLE_ROOM_SUCCESSFUL: 'FETCH_SINGLE_ROOM_SUCCESSFUL',
    FETCH_SINGLE_ROOM_FAILED: 'FETCH_SINGLE_ROOM_FAILED',
    UPDATE_SINGLE_ROOM_SUCCESSFUL: 'UPDATE_SINGLE_ROOM_SUCCESSFUL',
    UPDATE_SINGLE_ROOM_FAILED: 'UPDATE_SINGLE_EVENT_SERIES_FAILED',
    START_FETCHING_ROOMS_FOR_VENUE: 'START_FETCHING_ROOMS_FOR_VENUE',
    FETCHING_ROOMS_FOR_VENUE_SUCCESSFUL: 'FETCHING_ROOMS_FOR_VENUE_SUCCESSFUL',
    FETCHING_ROOMS_FOR_VENUE_FAILED: 'FETCHING_ROOMS_FOR_VENUE_FAILED'
};

export const initialState = {
    listingFetching: false,
    listingData: [],
    listingMeta: {},
    listingError: [],
    singleFetching: false,
    rooms: [],
    venueRooms: null
};

export default (state = initialState, { type, id, response }) => {
    switch (type) {
        case actions.START_FETCHING_ROOMS: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_ROOMS: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_ROOMS: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        case actions.START_CREATE_ROOM: {
            return state;
        }
        case actions.START_FETCHING_SINGLE_ROOM: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.FETCH_SINGLE_ROOM_SUCCESSFUL: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.FETCH_SINGLE_ROOM_FAILED: {
            return {
                ...state,
                singleFetching: false,
                singleError: response
            };
        }
        case actions.UPDATE_SINGLE_ROOM_FAILED: {
            return {
                ...state,
                singleError: response
            };
        }

        case actions.STORE_SINGLE_ROOM_DATA: {
            return Object.assign({}, state, {
                [id]: response.data
            });
        }
        case actions.START_FETCHING_ROOMS_FOR_VENUE: {
            return {
                ...state
            };
        }
        case actions.FETCHING_ROOMS_FOR_VENUE_SUCCESSFUL: {
            return {
                ...state,
                venueRooms: response.data
            };
        }
        case actions.ETCHING_ROOMS_FOR_VENUE_FAILED: {
            return {
                ...state
            };
        }
        default:
            return state;
    }
};
