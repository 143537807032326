// default spacing for app
const spacing = 8;

// max-width breakpoints
// current mobile breakpoint key: 'tablet'
const breakpoints = {
    small: 480,
    mobile: 850,
    tablet: 1030,
    desktop: 1200,
    large: 1600
};

/**
 * Check if you hit mobile resolution
 * @param currentWidth {number}
 * @returns {boolean}
 */
const ifMobile = currentWidth => {
    return currentWidth < breakpoints.tablet + 1;
};

/**
 * Check if you hit tablet resolution
 * @param currentWidth {number}
 * @returns {boolean}
 */
const ifTablet = currentWidth => {
    return currentWidth < breakpoints.desktop + 1;
};

export { spacing, ifMobile, ifTablet };
export default breakpoints;
