import React, { Component } from 'react';
import LayoutManager from 'erputils/LayoutManager';
import PageHeader from 'erpcomponents/Layout/PageHeader';
import PageContent from 'erpcomponents/Layout/PageContent';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from 'erpcomponents/Button';
import { FormattedMessage } from 'react-intl';
import { Field, reduxForm, SubmissionError, reset } from 'redux-form';
import Form, { Text } from 'erpcore/components/Form';
import { connect } from 'react-redux';
import { diff } from 'deep-object-diff';
import reduxFormErrorMapper from 'erputils/reduxFormErrorMapper';
import { actions as ticketsocketActions } from 'erpcore/screens/Settings/screens/Integrations/TicketSocketFeed/TicketSocketFeed.reducer';
import {
    getImportStatus,
    getTicketSocketFeedData,
    ticketSocketFeedSingleFetching
} from 'erpcore/screens/Settings/screens/Integrations/TicketSocketFeed/TicketSocketFeed.selectors';
import PageLoader from 'erpcomponents/PageLoader';
import IntegrationsTabs from 'erpcore/screens/Settings/components/IntegrationsTabs';

class TicketSocketFeed extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        const { fetchData } = this.props;
        fetchData();
    }

    onSubmit(formData) {
        const { dispatch, initialValues } = this.props;
        formData = diff(initialValues, formData);
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: ticketsocketActions.START_UPDATE_TICKET_SOCKET_FEED,
                formData
            });
        })
            .then(() => {
                dispatch(reset('TicketSocketFeedForm'));
            })
            .catch(error => {
                throw new SubmissionError(reduxFormErrorMapper(error));
            });
    }

    render() {
        const { onSyncNow, getData } = this.props;
        const { handleSubmit, submitting, pristine, invalid, singleFetching } = this.props;
        return (
            <LayoutManager slot="main" className="main--narrow" layoutType="merge">
                <PageHeader title="Settings" />
                <IntegrationsTabs />
                {getData.length > 0 && <span>{getData}</span>}
                <PageContent>
                    <Form onSubmit={handleSubmit(this.onSubmit)}>
                        {singleFetching === true && <PageLoader />}

                        <Form.Column>
                            <Form.Row>
                                <Field
                                    name="url"
                                    id="url"
                                    fieldProps={{
                                        label: (
                                            <FormattedMessage
                                                id="Settings.TicketSocketFeed.URL"
                                                defaultMessage="Ticketsocket Feed URL"
                                            />
                                        ),
                                        clearable: true,
                                        validation: 'required'
                                    }}
                                    component={Text}
                                />
                            </Form.Row>

                            <Form.Row>
                                <Field
                                    name="interval"
                                    id="interval"
                                    fieldProps={{
                                        label: (
                                            <FormattedMessage
                                                id="Settings.TicketSocketFeed.Interval"
                                                defaultMessage="Tickesocket Feed Interval in minutes"
                                            />
                                        ),
                                        clearable: true
                                    }}
                                    component={Text}
                                />
                            </Form.Row>
                            <Button
                                submit
                                disabled={pristine || invalid}
                                loading={submitting}
                                label={
                                    <FormattedMessage
                                        id="Settings.FormButton.Save"
                                        defaultMessage="Save"
                                    />
                                }
                            />
                            {/* <Button
                                label={
                                    <FormattedMessage
                                        id="Settings.FormButton.Import"
                                        defaultMessage="Import now"
                                    />
                                }
                                variation="secondary"
                            /> */}
                        </Form.Column>
                    </Form>
                    <Button label="Sync now" onClick={onSyncNow} />
                </PageContent>
            </LayoutManager>
        );
    }
}

TicketSocketFeed.defaultProps = {
    getData: [],
    submitting: false,
    pristine: false,
    invalid: false,
    singleFetching: false,
    initialValues: {}
};

TicketSocketFeed.propTypes = {
    // categoriesListingFetching: PropTypes.bool
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
    onSyncNow: PropTypes.func.isRequired,
    getData: PropTypes.oneOfType([PropTypes.object]),
    fetchData: PropTypes.func.isRequired,
    singleFetching: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    initialValues: PropTypes.oneOfType([PropTypes.object])
};

TicketSocketFeed = reduxForm({
    form: 'TicketSocketFeedForm',
    enableReinitialize: true
})(TicketSocketFeed);

const mapStateToProps = state => ({
    getData: getImportStatus(state),
    initialValues: getTicketSocketFeedData(state),
    singleFetching: ticketSocketFeedSingleFetching(state)
});

const mapDispatchToProps = dispatch => ({
    onSyncNow: () => {
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: ticketsocketActions.START_SYNC_TICKET_SOCKET_FEED
            })
        ).catch(error => {
            return error;
        });
    },

    fetchData: () => {
        // Getting included data from API by setting params
        const params = {};
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: ticketsocketActions.START_FETCHING_TICKET_SOCKET_FEED,
                params
            });
        }).catch(error => ({ error }));
    }
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(TicketSocketFeed)
);
