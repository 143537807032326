import React from 'react';
import PropTypes from 'prop-types';
import enviromentVariables from 'erputils/enviromentVariables';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import './LocationPin.scss';

const GoogleMapWrapper = withScriptjs(
    withGoogleMap(({ onClick, locationPin, onDragEnd, defaultCenter, center, disabled }) => (
        <GoogleMap
            onClick={onClick}
            draggable={false}
            zoom={Object.entries(locationPin).length ? 15 : 2}
            center={center || defaultCenter}
            zoomOnClick={false}
            clickableIcons={false}
            options={{
                cursor: 'pointer'
            }}
        >
            {Object.entries(locationPin).length !== 0 && (
                <Marker draggable={!disabled} position={locationPin} onDragEnd={onDragEnd} />
            )}
        </GoogleMap>
    ))
);
GoogleMapWrapper.defaultProps = {
    googleMapURL: `https://maps.googleapis.com/maps/api/js?v=3.exp&key=${
        enviromentVariables.GOOGLE_MAPS_API_KEY
    }&libraries=geometry,drawing,places`,
    loadingElement: <div className="location-pin__loading" />,
    containerElement: <div className="location-pin__container" />,
    mapElement: <div className="location-pin__map" />,
    center: {},
    defaultCenter: { lat: 39.9636615, lng: -100.5430035 },
    locationPin: {},
    disabled: false,
    onClick: () => {}
};
GoogleMapWrapper.propTypes = {
    disabled: PropTypes.bool,
    defaultCenter: PropTypes.shape({
        lat: PropTypes.number,
        lng: PropTypes.number
    }),
    locationPin: PropTypes.shape({
        lat: PropTypes.number,
        lng: PropTypes.number
    }),
    onClick: PropTypes.func,
    googleMapURL: PropTypes.string,
    loadingElement: PropTypes.node,
    containerElement: PropTypes.node,
    mapElement: PropTypes.node
};

const LocationPin = ({ input, disabled, fieldProps }) => {
    return (
        <div className={`location-pin${disabled ? ' location-pin--disabled' : ''}`}>
            <GoogleMapWrapper
                onClick={event => {
                    if (!disabled) {
                        input.onChange({ lat: event.latLng.lat(), lng: event.latLng.lng() });
                    }
                }}
                locationPin={input.value || {}}
                center={input.value}
                disabled={disabled}
                onDragEnd={event => {
                    if (!disabled) {
                        input.onChange({ lat: event.latLng.lat(), lng: event.latLng.lng() });
                    }
                }}
            />
            {fieldProps.helperText && (
                <span className="input__helper-text">{fieldProps.helperText}</span>
            )}
        </div>
    );
};
LocationPin.defaultProps = {
    input: {},
    fieldProps: {},
    disabled: false
};
LocationPin.propTypes = {
    input: PropTypes.oneOfType([PropTypes.object]),
    fieldProps: PropTypes.oneOfType([PropTypes.object]),
    disabled: PropTypes.bool
};
export default LocationPin;
