import { createSelector } from 'reselect';

export const getRoomData = (state, id) => state.rooms && state.rooms[id] && state.rooms[id];

export const getSingleRoomFetching = state => state.rooms.singleFetching;

export const getVenueRooms = state =>
    state.rooms && state.rooms.venueRooms && state.rooms.venueRooms;

export default createSelector([getRoomData, getSingleRoomFetching, getVenueRooms]);
