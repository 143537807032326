import { takeLatest, put, call, select } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import { parseParamsForApi } from 'erputils/utils';
import { getListingUrlQueryParams } from 'erputils/RouterManager/RouterManager.selectors';
import { actions as notificationManagerActions } from 'erputils/NotificationManager/NotificationManager.reducer';
import { actions as listingActions } from 'erpcomponents/Listing/Listing.reducer';
import dto from 'erputils/dto';
import { actions as roomsActions } from './Rooms.reducer';

/**
 * Create Room
 * @param  {Object} promise
 * @return {Object} formData
 */
export function* createRoom({ promise, formData }) {
    const params = Object.assign(
        {
            'filters[location.id][equals]': formData.location.match(/\d+/g)[0]
        },
        {},
        yield select(getListingUrlQueryParams)
    );
    try {
        // Create Room
        const createRoomAPI = yield restClient.post(`api/rooms`, formData);
        yield put({
            type: roomsActions.CREATE_ROOM_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createRoomAPI.data
        });
        yield put({
            type: listingActions.START_FETCHING_LISTING,
            entity: 'ROOMS',
            endpoint: 'api/rooms',
            params: parseParamsForApi(params)
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: roomsActions.CREATE_EVENT_SERIES_FAILED,
            response: error.response.data
        });

        yield call(promise.reject, error.response.data);
    }
}

/**
 * Fetch Room
 * @param  {Object} promise
 * @return {string} id
 */
export function* fetchRoom({ promise, id }) {
    try {
        const fetchRoomAPI = yield restClient.get(`api/rooms/${id}`);
        yield put({
            type: roomsActions.FETCH_SINGLE_ROOM_SUCCESSFUL
        });
        yield put({
            type: roomsActions.STORE_SINGLE_ROOM_DATA,
            id,
            response: dto(fetchRoomAPI.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: roomsActions.FETCH_SINGLE_ROOM_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}
/**
 * Fetch Room
 * @param  {Object} promise
 * @return {string} id
 */
export function* fetchVenueRooms({ promise, id }) {
    try {
        const fetchRoomAPI = yield restClient.get(
            `/api/rooms?pagination=false&include=location&filters[location.id][equals]=${id}`
        );
        yield put({
            type: roomsActions.FETCHING_ROOMS_FOR_VENUE_SUCCESSFUL,
            response: dto(fetchRoomAPI.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: roomsActions.FETCHING_ROOMS_FOR_VENUE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Update room
 * @param  {Object} id ID of an room
 * @return {Object} Response from API
 */
export function* updateSingleRoom({ promise, formData, id }) {
    const params = Object.assign(
        {
            'filters[location.id][equals]': formData.location.match(/\d+/g)[0]
        },
        {},
        yield select(getListingUrlQueryParams)
    );
    try {
        const updateSingleRoomAPI = yield restClient.put(`api/rooms/${id}`, formData);

        yield put({
            type: roomsActions.UPDATE_SINGLE_ROOM_SUCCESSFUL
        });
        yield put({
            type: roomsActions.STORE_SINGLE_ROOM_DATA,
            id,
            response: dto(updateSingleRoomAPI.data)
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateSingleRoomAPI.data
        });
        yield put({
            type: listingActions.START_FETCHING_LISTING,
            entity: 'ROOMS',
            endpoint: 'api/rooms',
            params: parseParamsForApi(params)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: roomsActions.UPDATE_SINGLE_ROOM_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Delete Room
 * @param  {Object} id ID
 * @return {Object} Response from API
 */
export function* deleteSingleRoom({ promise, id, location }) {
    const params = Object.assign(
        {
            'filters[location.id][equals]': location
        },
        {},
        yield select(getListingUrlQueryParams)
    );

    try {
        const deleteSingleRoomAPI = yield restClient.delete(`api/rooms/${id}`);
        yield put({
            type: roomsActions.DELETE_SINGLE_ROOM_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteSingleRoomAPI.data
        });
        yield put({
            type: listingActions.START_FETCHING_LISTING,
            entity: 'ROOMS',
            endpoint: 'api/rooms',
            params: parseParamsForApi(params)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: roomsActions.DELETE_SINGLE_ROOM_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

export const roomsSaga = [
    takeLatest(roomsActions.START_CREATE_ROOM, createRoom),
    takeLatest(roomsActions.START_DELETE_SINGLE_ROOM, deleteSingleRoom),
    takeLatest(roomsActions.START_FETCHING_SINGLE_ROOM, fetchRoom),
    takeLatest(roomsActions.START_UPDATE_SINGLE_ROOM, updateSingleRoom),
    takeLatest(roomsActions.START_FETCHING_ROOMS_FOR_VENUE, fetchVenueRooms)
];
