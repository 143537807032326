import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// import { withRouter } from 'react-router-dom';
import './Notification.scss';
import { FormattedMessage, FormattedDate, FormattedTime } from 'react-intl';
import Button from 'erpcomponents/Button';
import Cardbox from 'erpcomponents/Cardbox';
import StatusBadge from 'erpcomponents/StatusBadge';
import Svg from 'erpcomponents/Svg';
import { actions as notificationsActions } from 'erpcore/screens/Notifications/Notifications.reducer';

const handleCardBody = (data, markAsDone) => {
    return (
        <>
            <div className="notif__notification-content">
                <p className="notif__updated">
                    <FormattedDate value={data.created_at} />
                    {','} <FormattedTime value={data.created_at} />
                </p>
                {data.actionable && (
                    <StatusBadge
                        type={data.status === 'pending' ? 'pending' : 'fulfilled'}
                        text={data.status === 'pending' ? 'pending' : 'done'}
                    />
                )}
            </div>
            <div>
                <h2 className="notif__title">{data.title}</h2>
                <h4 className="notif__subtitle">{data.summary}</h4>
            </div>
            {data.actionable && (
                <>
                    <div className="notif__action-info">
                        <Svg icon="settings" />
                        <span className="notif__action-info_bold">{data.action_required}</span>
                        <span>{data.action}</span>
                    </div>
                    <div className="notif__actions">
                        {data.ticketing === 'ticket_socket' ? (
                            <div className="notif__actions-secondary">
                                <Button
                                    variation="tertiary"
                                    size="small"
                                    label="Edit on Ticket Socket"
                                />
                            </div>
                        ) : (
                            <Button variation="tertiary" size="small" label="Edit on Get Out" />
                        )}
                        <div className="notif__actions-primary">
                            {data.status === 'pending' && (
                                <Button
                                    variation="secondary"
                                    size="small"
                                    onClick={() => markAsDone(data.id)}
                                    label={
                                        <FormattedMessage
                                            id="Notification.Edit"
                                            defaultMessage="Mark as done"
                                        />
                                    }
                                />
                            )}
                        </div>
                    </div>
                </>
            )}
        </>
    );
};
class Notification extends Component {
    state = {};

    markAsDone = id => {
        const { dispatch } = this.props;
        return new Promise((resolve, reject) =>
            dispatch({
                type: notificationsActions.MARK_NOTIFICATION_DONE,
                promise: { resolve, reject },
                id
            })
        );
    };

    render() {
        const { data } = this.props;
        const handleNotificationBody = handleCardBody(data, this.markAsDone);
        return <Cardbox body={handleNotificationBody} />;
    }
}
Notification.defaultProps = {
    data: {},
    dispatch: () => {}
};
Notification.propTypes = {
    data: PropTypes.oneOfType([PropTypes.object]),
    dispatch: PropTypes.func
    // className: PropTypes.string,
    // event: PropTypes.oneOfType([PropTypes.object])
};

//  Getting initial value populated in the form from the store
export default connect()(Notification);
