import React from 'react';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Tabs from 'erpcomponents/Tabs';

const ResellerAdminTabs = () => {
    const links = [
        {
            link: `/settings/reseller/header-menu`,
            label: (
                <FormattedMessage id="ResellerAdminTabs.HeaderMenu" defaultMessage="Header Menu" />
            )
        },
        {
            link: `/settings/reseller/homepage-slider`,
            label: (
                <FormattedMessage
                    id="ResellerAdminTabs.HomepageSlider"
                    defaultMessage="Homepage Slider"
                />
            )
        },
        {
            link: `/settings/reseller/sort-events`,
            label: (
                <FormattedMessage id="ResellerAdminTabs.SortEvents" defaultMessage="Sort Events" />
            )
        },
        {
            link: `/settings/reseller/filter`,
            label: <FormattedMessage id="ResellerAdminTabs.Filter" defaultMessage="Filter" />
        }
    ];

    return <Tabs links={links} />;
};

export default withRouter(ResellerAdminTabs);
