import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import reduxFormErrorMapper from 'erputils/reduxFormErrorMapper';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import Form, { Email, Password } from 'erpcore/components/Form';
import Button from 'erpcomponents/Button';
import PageNotification from 'erpcomponents/Layout/PageNotification';
import { actions as authActions } from 'erputils/AuthManager/AuthManager.reducer';
import { Link } from 'react-router-dom';
import valueValidation from 'erputils/valueValidation';
import { getPageNotification } from 'erputils/NotificationManager/NotificationManager.selectors';

let SignInForm = ({ handleSubmit, submitting, pristine, invalid, onSubmit, pageNotification }) => {
    return (
        <Fragment>
            <h2 className="front-main__title">
                <FormattedMessage id="SignIn.title" defaultMessage="Sign In" />
            </h2>

            {pageNotification && pageNotification.code && (
                <PageNotification code={pageNotification.code} />
            )}

            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Row>
                    <Field
                        name="email"
                        id="email"
                        fieldProps={{
                            label: <FormattedMessage id="SignIn.Email" defaultMessage="Email" />,
                            clearable: true
                        }}
                        component={Email}
                        validate={valueValidation([
                            { validator: 'email' },
                            { validator: 'required' }
                        ])}
                    />
                </Form.Row>
                <Form.Row>
                    <Field
                        name="password"
                        id="password"
                        fieldProps={{
                            label: (
                                <FormattedMessage id="SignIn.Password" defaultMessage="Password" />
                            )
                        }}
                        component={Password}
                        validate={valueValidation([{ validator: 'required' }])}
                    />
                </Form.Row>
                <Form.Row>
                    <Link to="/forgot-password">
                        <FormattedMessage
                            id="SignIn.ForgotPassword"
                            defaultMessage="Forgot Password?"
                        />
                    </Link>
                </Form.Row>
                <Form.Row verticalCenter>
                    <Button
                        submit
                        disabled={pristine || invalid}
                        loading={submitting}
                        label={<FormattedMessage id="SignIn.SignIn" defaultMessage="Sign In" />}
                    />
                </Form.Row>
            </Form>
        </Fragment>
    );
};

SignInForm.defaultProps = {
    submitting: false,
    pristine: false,
    invalid: false,
    onSubmit: (credentials, dispatch) =>
        new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: authActions.START_SIGN_IN,
                credentials
            })
        ).catch(error => {
            throw new SubmissionError(reduxFormErrorMapper(error));
        }),
    pageNotification: null
};

SignInForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    pageNotification: PropTypes.oneOfType([PropTypes.object])
};

SignInForm = reduxForm({
    form: 'SignInForm',
    enableReinitialize: true
})(SignInForm);

const mapStateToProps = state => ({
    pageNotification: getPageNotification(state)
});

export default connect(mapStateToProps)(SignInForm);
