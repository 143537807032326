export const actions = {
    START_FETCHING_ACTIVITY_LOGS: 'START_FETCHING_ACTIVITY_LOGS',
    FETCHING_SUCCESSFUL_ACTIVITY_LOGS: 'FETCHING_SUCCESSFUL_ACTIVITY_LOGS',
    FETCHING_FAILED_ACTIVITY_LOGS: 'FETCHING_FAILED_ACTIVITY_LOGS',
    STORE_ACTIVITY_LOG_DATA: 'STORE_ACTIVITY_LOG_DATA'
};

export const initialState = {
    fetching: false
};

export default (state = initialState, { type, id, entity, response }) => {
    switch (type) {
        case actions.START_FETCHING_ACTIVITY_LOGS: {
            return {
                ...state,
                fetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_ACTIVITY_LOGS: {
            return {
                ...state,
                fetching: false
            };
        }
        case actions.FETCHING_FAILED_ACTIVITY_LOGS: {
            return {
                ...state,
                fetching: false
            };
        }
        case actions.STORE_ACTIVITY_LOG_DATA: {
            // Updating the store
            return Object.assign({}, state, {
                [entity]: { [id]: response }
            });
        }
        default:
            return state;
    }
};
