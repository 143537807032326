import React from 'react';
import { Bar } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import './StackedBarChart.scss';

class StackedBarChart extends React.Component {
    // If used as callback to send data to <StackedBarChart /> , canvas argument is usable, its used for creating gradients for fill amongst other things //
    getChartData = () => {
        const { data: chartData } = this.props;

        return chartData;
    };

    render() {
        // Options are passed as props if needed, options include yAxis and xAxis labels, responsivness, toolpit data and style etc... //
        const { legend, getElementAtEvent, getDatasetAtEvent } = this.props;
        let { options } = this.props;

        // Take implemented options and assign stacked to them so bar becomes stacked bar //
        if (Object.keys(options).length > 0 && options.constructor === Object) {
            options = {
                ...options,
                scales: {
                    ...options.scales,
                    xAxes: [
                        options.scales && options.scales.xAxes
                            ? options.scales.xAxes.map(el => {
                                  return {
                                      ...el,
                                      stacked: true
                                  };
                              })
                            : { stacked: true }
                    ],
                    yAxes: [
                        options.scales && options.scales.yAxes
                            ? options.scales.yAxes.map(el => {
                                  return {
                                      ...el,
                                      stacked: true
                                  };
                              })
                            : { stacked: true }
                    ]
                }
            };
        }

        return (
            <div className="stacked-bar-chart">
                <Bar
                    data={this.getChartData}
                    options={options}
                    legend={legend}
                    getElementAtEvent={getElementAtEvent}
                    getDatasetAtEvent={getDatasetAtEvent}
                />
            </div>
        );
    }
}

StackedBarChart.defaultProps = {
    data: {},
    options: {},
    legend: {},
    getElementAtEvent: () => {},
    getDatasetAtEvent: () => {}
};

StackedBarChart.propTypes = {
    data: PropTypes.oneOfType([PropTypes.object]),
    options: PropTypes.oneOfType([PropTypes.object]),
    legend: PropTypes.oneOfType([PropTypes.object]),
    getElementAtEvent: PropTypes.func,
    getDatasetAtEvent: PropTypes.func
};

export default StackedBarChart;
