import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { reduxForm } from 'redux-form';
import Form from 'erpcore/components/Form';
import Button from 'erpcomponents/Button';
import PageNotification from 'erpcomponents/Layout/PageNotification';
import { getPageNotification } from 'erputils/NotificationManager/NotificationManager.selectors';
import { actions as forgotPasswordActions } from 'erpcore/screens/ForgotPassword/ForgotPassword.reducer';
import { getForgotPasswordEmail } from 'erpcore/screens/ForgotPassword/ForgotPassword.selectors';

class ResendResetPasword extends Component {
    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit() {
        const { dispatch, email } = this.props;

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: forgotPasswordActions.START_RESEND_EMAIL_RESET_PASSWORD,
                email
            })
        ).catch(error => {
            return error;
        });
    }

    render() {
        const { handleSubmit, submitting, pageNotification } = this.props;

        return (
            <Fragment>
                <h2 className="front-main__title">
                    <FormattedMessage
                        id="ResendResetPasword.title"
                        defaultMessage="Check Your Inbox"
                    />
                </h2>
                <p className="front-main__subtitle">
                    <FormattedMessage
                        id="ResendResetPasword.subtitle"
                        defaultMessage="Check your inbox to reset your password."
                    />
                </p>

                {pageNotification && pageNotification.code && (
                    <PageNotification code={pageNotification.code} />
                )}

                <Form onSubmit={handleSubmit(this.onSubmit)}>
                    <Form.Row verticalCenter>
                        <Form.RowColResponsive>
                            <Button
                                submit
                                loading={submitting}
                                label={
                                    <FormattedMessage
                                        id="ResendResetPasword.Resend"
                                        defaultMessage="Resend Reset Password"
                                    />
                                }
                            />
                        </Form.RowColResponsive>
                        <Form.RowColResponsive>
                            <span className="form__help-text">
                                <FormattedMessage
                                    id="ResendResetPasword.text"
                                    defaultMessage="Need help or more information?"
                                />
                                <br />
                                <FormattedMessage
                                    id="ResendResetPasword.text.contact"
                                    defaultMessage="Contact:"
                                />{' '}
                                <a href="mailto:daryl@glowgardens.com">daryl@glowgardens.com</a>
                            </span>
                        </Form.RowColResponsive>
                    </Form.Row>
                </Form>
            </Fragment>
        );
    }
}

ResendResetPasword.defaultProps = {
    submitting: false,
    pageNotification: null,
    email: ''
};

ResendResetPasword.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    pageNotification: PropTypes.oneOfType([PropTypes.object]),
    email: PropTypes.string
};

const mapStateToProps = state => ({
    pageNotification: getPageNotification(state),
    email: getForgotPasswordEmail(state)
});

ResendResetPasword = reduxForm({
    form: 'ResendResetPaswordForm',
    enableReinitialize: true
})(ResendResetPasword);

export default connect(mapStateToProps)(ResendResetPasword);
