import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import './DateTime.scss';
import Input from '../Input';

const DateTime = ({ input, meta, fieldProps, fieldAttr, field }) => {
    //  Preparing specific date format
    let dateFormat = 'MMMM d, yyyy';
    if (fieldProps.showTimeSelect) {
        dateFormat = 'M/d/yyyy   h:mm aa';

        if (fieldProps.showTimeSelectOnly) {
            dateFormat = 'h:mm aa';
        }
    }

    return (
        <Input
            fieldProps={fieldProps}
            fieldAttr={fieldAttr}
            field={field}
            input={input}
            meta={meta}
        >
            <DatePicker
                className="input__field"
                {...fieldAttr}
                {...fieldProps}
                dateFormat={dateFormat}
                selected={input.value ? new Date(input.value) : ''}
                onChange={value => input.onChange(value)}
            />
        </Input>
    );
};
DateTime.defaultProps = {
    fieldProps: {},
    fieldAttr: {},
    field: {},
    input: {},
    meta: {}
};
DateTime.propTypes = {
    fieldProps: PropTypes.oneOfType([PropTypes.object]),
    fieldAttr: PropTypes.oneOfType([PropTypes.object]),
    field: PropTypes.oneOfType([PropTypes.object]),
    input: PropTypes.oneOfType([PropTypes.object]),
    meta: PropTypes.oneOfType([PropTypes.object])
};
export default DateTime;
