// TODO: location of this file is not yet defined
export const actions = {
    START_FETCHING_RESELLER_FILTERS: 'START_FETCHING_RESELLER_FILTERS',
    FETCHING_SUCCESSFUL_RESELLER_FILTERS: 'FETCHING_SUCCESSFUL_RESELLER_FILTERS',
    FETCHING_FAILED_RESELLER_FILTERS: 'FETCHING_FAILED_RESELLER_FILTERS',
    START_CREATE_RESELLER_FILTER: 'START_CREATE_RESELLER_FILTER',
    CREATE_RESELLER_FILTER_SUCCESSFUL: 'CREATE_RESELLER_FILTER_SUCCESSFUL',
    CREATE_RESELLER_FILTER_FAILED: 'CREATE_RESELLER_FILTER_FAILED',
    START_FETCHING_SINGLE_RESELLER_FILTER: 'START_FETCHING_SINGLE_RESELLER_FILTER',
    FETCH_SINGLE_RESELLER_FILTER_SUCCESSFUL: 'FETCH_SINGLE_RESELLER_FILTER_SUCCESSFUL',
    FETCH_SINGLE_RESELLER_FILTER_FAILED: 'FETCH_SINGLE_RESELLER_FILTER_FAILED',
    REQUEST_UPDATE_SINGLE_RESELLER_FILTER: 'REQUEST_UPDATE_SINGLE_RESELLER_FILTER',
    START_UPDATE_SINGLE_RESELLER_FILTER: 'START_UPDATE_SINGLE_RESELLER_FILTER',
    UPDATE_SINGLE_RESELLER_FILTER_SUCCESSFUL: 'UPDATE_SINGLE_RESELLER_FILTER_SUCCESSFUL',
    UPDATE_SINGLE_RESELLER_FILTER_FAILED: 'UPDATE_SINGLE_RESELLER_FILTER_FAILED',
    START_DELETE_SINGLE_RESELLER_FILTER: 'START_DELETE_SINGLE_RESELLER_FILTER',
    DELETE_SINGLE_RESELLER_FILTER_SUCCESSFUL: 'DELETE_SINGLE_RESELLER_FILTER_SUCCESSFUL',
    DELETE_SINGLE_RESELLER_FILTER_FAILED: 'DELETE_SINGLE_RESELLER_FILTER_FAILED',
    STORE_SINGLE_RESELLER_FILTER_DATA: 'STORE_SINGLE_RESELLER_FILTER_DATA'
};

export const initialState = {
    listingFetching: false,
    listingData: [],
    listingMeta: {},
    listingError: [],
    singleFetching: false,
    singleCreating: false,
    singleDeleting: false,
    singleUpdating: false,
    resellerFilters: []
};

export default (state = initialState, { type, id, response }) => {
    switch (type) {
        case actions.START_FETCHING_RESELLER_FILTERS: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_RESELLER_FILTERS: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response.data
            };
        }
        case actions.FETCHING_FAILED_RESELLER_FILTERS: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        case actions.START_CREATE_RESELLER_FILTER: {
            return {
                ...state,
                singleCreating: true
            };
        }
        case actions.CREATE_RESELLER_FILTER_SUCCESSFUL: {
            return {
                ...state,
                singleCreating: false
            };
        }
        case actions.CREATE_RESELLER_FILTER_FAILED: {
            return {
                ...state,
                singleCreating: false
            };
        }
        case actions.START_DELETE_SINGLE_RESELLER_FILTER: {
            return {
                ...state,
                singleDeleting: true
            };
        }
        case actions.DELETE_SINGLE_RESELLER_FILTER_SUCCESSFUL: {
            return {
                ...state,
                singleDeleting: false
            };
        }
        case actions.DELETE_SINGLE_RESELLER_FILTER_FAILED: {
            return {
                ...state,
                singleDeleting: false
            };
        }
        case actions.START_FETCHING_SINGLE_RESELLER_FILTER: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.FETCH_SINGLE_RESELLER_FILTER_SUCCESSFUL: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.FETCH_SINGLE_RESELLER_FILTER_FAILED: {
            return {
                ...state,
                singleFetching: false,
                singleError: response
            };
        }

        case actions.START_UPDATE_SINGLE_RESELLER_FILTER: {
            return {
                ...state,
                singleUpdating: true
            };
        }
        case actions.UPDATE_SINGLE_RESELLER_FILTER_SUCCESSFUL: {
            return {
                ...state,
                singleUpdating: false
            };
        }
        case actions.UPDATE_SINGLE_RESELLER_FILTER_FAILED: {
            return {
                ...state,
                singleUpdating: false,
                singleError: response
            };
        }

        case actions.STORE_SINGLE_RESELLER_FILTER_DATA: {
            return Object.assign({}, state, {
                [id]: response.data
            });
        }
        default:
            return state;
    }
};
