import { createSelector } from 'reselect';

export const getData = (state, id) =>
    state.widgets &&
    state.widgets.ticketTypes &&
    state.widgets.ticketTypes[id] &&
    state.widgets.ticketTypes[id].data;

export const getFetching = (state, id) =>
    state.widgets &&
    state.widgets.ticketTypes &&
    state.widgets.ticketTypes[id] &&
    state.widgets.ticketTypes[id].fetching;

export default createSelector([getData, getFetching]);
