import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import './FilterDateRange.scss';
import Input from 'erpcore/components/Form/components/Input';

class FilterDateRange extends Component {
    constructor(props) {
        super(props);

        this.state = {
            startDate: null,
            endDate: null,
            minDate: null
        };

        this.handleChangeStart = this.handleChangeStart.bind(this);
        this.handleChangeEnd = this.handleChangeEnd.bind(this);
    }

    handleChangeStart(value) {
        const { input } = this.props;
        const { endDate } = this.state;

        return this.setState(
            {
                startDate: value,
                minDate: value
            },
            () => input.onChange([value, endDate])
        );
    }

    handleChangeEnd(value) {
        const { input } = this.props;
        const { startDate } = this.state;

        return this.setState(
            {
                endDate: value
            },
            () => input.onChange([startDate, value])
        );
    }

    render() {
        const { input, fieldProps, field, fieldAttr, meta } = this.props;
        const { startDate, endDate, minDate } = this.state;

        console.error(startDate, endDate, fieldAttr, input, meta);

        let dateFormat = 'MMMM d, yyyy';
        if (fieldProps.showTimeSelect) {
            dateFormat = 'M/d/yyyy   h:mm aa';

            if (fieldProps.showTimeSelectOnly) {
                dateFormat = 'h:mm aa';
            }
        }

        return (
            <div className="filter-date-range">
                <h6 className="filter-date-range__label">{fieldProps && fieldProps.label}</h6>
                <div className="filter-date-range__field">
                    <Input
                        field={field}
                        meta={{
                            active: startDate
                        }}
                        input={{}}
                        fieldProps={{ label: 'Start Date' }}
                    >
                        <DatePicker
                            className="input__field"
                            dateFormat={dateFormat}
                            selected={startDate}
                            onChange={value => this.handleChangeStart(value)}
                        />
                    </Input>
                </div>
                <div className="filter-date-range__field">
                    <Input
                        field={field}
                        meta={{
                            active: endDate
                        }}
                        input={{}}
                        fieldProps={{ label: 'End Date' }}
                    >
                        <DatePicker
                            className="input__field"
                            dateFormat={dateFormat}
                            selected={endDate}
                            onChange={value => this.handleChangeEnd(value)}
                            startDate={startDate}
                            minDate={minDate}
                        />
                    </Input>
                </div>
            </div>
        );
    }
}

FilterDateRange.defaultProps = {
    fieldProps: {},
    fieldAttr: {},
    field: {},
    input: {},
    meta: {}
};

FilterDateRange.propTypes = {
    fieldProps: PropTypes.oneOfType([PropTypes.object]),
    fieldAttr: PropTypes.oneOfType([PropTypes.object]),
    field: PropTypes.oneOfType([PropTypes.object]),
    input: PropTypes.oneOfType([PropTypes.object]),
    meta: PropTypes.oneOfType([PropTypes.object])
};
export default FilterDateRange;
