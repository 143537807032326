import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import Button from 'erpcore/components/Button';
import Form, { Text, MultiAutocomplete } from 'erpcore/components/Form';

import { valueValidation } from 'erpcore/components/Form/Form.utils';

const RoleForm = ({ handleSubmit, submitting, pristine, invalid, onSubmit, submitLabel }) => {
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
                <Field
                    name="name"
                    id="name"
                    fieldProps={{
                        label: 'Role Name',
                        clearable: true
                    }}
                    fieldAttr={{ required: true }}
                    component={Text}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="permissions"
                    id="permissions"
                    fieldProps={{
                        label: 'Permissions',
                        options: {
                            endpoint: '/api/permissions?filters[active][equals]=true',
                            mapData: {
                                value: 'iri',
                                label: 'name'
                            }
                        },
                        clearable: true
                    }}
                    fieldAttr={{ required: true }}
                    component={MultiAutocomplete}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row>
                <Button
                    submit
                    disabled={pristine || invalid}
                    loading={submitting}
                    label={submitLabel}
                />
            </Form.Row>
        </Form>
    );
};

RoleForm.defaultProps = {
    onSubmit: () => {},
    submitting: false,
    pristine: false,
    invalid: false,
    submitLabel: 'Create'
};

RoleForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    submitLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default reduxForm({
    form: 'RoleForm',
    enableReinitialize: true
})(RoleForm);
