export const actions = {
    START_SYNC_TICKET_SOCKET_FEED: 'START_SYNC_TICKET_SOCKET_FEED',
    SYNC_TICKET_SOCKET_FEED_SUCCESSFUL: 'SYNC_TICKET_SOCKET_FEED_SUCCESSFUL',
    SYNC_TICKET_SOCKET_FEED_FAILED: 'SYNC_TICKET_SOCKET_FEED_FAILED',
    STORE_TICKET_SOCKET_FEED_URL_DATA: 'STORE_TICKET_SOCKET_FEED_URL_DATA',
    STORE_TICKET_SOCKET_FEED_INTERVAL_DATA: 'STORE_TICKET_SOCKET_FEED_INTERVAL_DATA',
    START_UPDATE_TICKET_SOCKET_FEED: 'START_UPDATE_TICKET_SOCKET_FEED',
    UPDATE_TICKET_SOCKET_FEED_SUCCESSFUL: 'UPDATE_TICKET_SOCKET_FEED_SUCCESSFUL',
    UPDATE_TICKET_SOCKET_FEED_FAILED: 'UPDATE_TICKET_SOCKET_FEED_FAILED',
    START_FETCHING_TICKET_SOCKET_FEED: 'START_FETCHING_TICKET_SOCKET_FEED',
    FETCHING_TICKET_SOCKET_FEED_SUCCESSFUL: 'FETCHING_TICKET_SOCKET_FEED_SUCCESSFUL',
    FETCHING_TICKET_SOCKET_FEED_FAILED: 'FETCHING_TICKET_SOCKET_FEED_FAILED',
    STORE_TICKET_SOCKET_FEED_DATA: 'STORE_TICKET_SOCKET_FEED_DATA'
};

export const initialState = {
    sync: false,
    listingFetching: false,
    listingData: [],
    listingMeta: {},
    listingError: [],
    singleFetching: false,
    data: []
};

export default (state = initialState, { type, response }) => {
    switch (type) {
        case actions.START_SYNC_TICKET_SOCKET_FEED: {
            return {
                ...state,
                sync: true
            };
        }
        case actions.SYNC_TICKET_SOCKET_FEED_SUCCESSFUL: {
            return {
                ...state
            };
        }
        case actions.SYNC_TICKET_SOCKET_FEED_FAILED: {
            return {
                ...state,
                singleError: response
            };
        }
        case actions.UPDATE_TICKET_SOCKET_FEED_FAILED: {
            return {
                ...state,
                singleError: response
            };
        }
        case actions.START_FETCHING_TICKET_SOCKET_FEED: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.FETCHING_TICKET_SOCKET_FEED_SUCCESSFUL: {
            return {
                ...state,
                singleFetching: false
            };
        }

        case actions.FETCHING_TICKET_SOCKET_FEED_FAILED: {
            return {
                ...state,
                singleError: response,
                singleFetching: false
            };
        }

        case actions.STORE_TICKET_SOCKET_FEED_DATA: {
            return {
                ...state,
                data: response.data
            };
        }
        case actions.STORE_TICKET_SOCKET_FEED_URL_DATA: {
            return {
                ...state,
                url: response.data
            };
        }

        case actions.STORE_TICKET_SOCKET_FEED_INTERVAL_DATA: {
            return {
                ...state,
                interval: response.data
            };
        }

        default:
            return state;
    }
};
