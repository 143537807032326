import { createSelector } from 'reselect';

export const getImportStatus = state =>
    state.ticketSocketFeed && state.ticketSocketFeed.data && state.ticketSocketFeed.data;

export const getTicketSocketFeedData = state => {
    const interval =
        state.ticketSocketFeed &&
        state.ticketSocketFeed.interval &&
        state.ticketSocketFeed.interval;
    const url = state.ticketSocketFeed && state.ticketSocketFeed.url && state.ticketSocketFeed.url;
    const data = {
        url: url && url.data.attributes.value,
        interval: interval && interval.data.attributes.value
    };

    return Object.keys(data).length > 0 && data;
};

export const ticketSocketFeedSingleFetching = state =>
    state.ticketSocketFeed && state.ticketSocketFeed.singleFetching;

export default createSelector([
    getImportStatus,
    getTicketSocketFeedData,
    ticketSocketFeedSingleFetching
]);
