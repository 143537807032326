import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { actions as authActions } from 'erputils/AuthManager/AuthManager.reducer';
import { connect } from 'react-redux';

const SignOut = ({ triggerSignOut }) => {
    triggerSignOut();
    return (
        <Fragment>
            <h1>Signin Out...</h1>
        </Fragment>
    );
};
SignOut.propTypes = {
    triggerSignOut: PropTypes.func.isRequired
};
//  Redux
const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => ({
    triggerSignOut: () =>
        dispatch({
            type: authActions.START_SIGN_OUT
        })
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SignOut);
