import { takeLatest, put, call } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import { actions as listingActions } from 'erpcomponents/Listing/Listing.reducer';
import { actions as notificationManagerActions } from 'erputils/NotificationManager/NotificationManager.reducer';
import dto, { dtoForm } from 'erputils/dto';
import formatLocationFormData from 'erputils/formatLocationFormData';
import { actions as venuesActions } from './Venues.reducer';

/**
 * Create Venue
 * @param  {Object} promise
 * @return {Object} formData
 */

export function* createVenue({ promise, formData }) {
    try {
        const location = Object.assign({}, formData.location, { venue_name: formData.venue_name });
        const createVenueAPI = yield restClient.post(`api/locations`, location);
        yield put({
            type: venuesActions.CREATE_VENUE_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: createVenueAPI.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: venuesActions.CREATE_VENUE_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Fetch Venue
 * @param  {Object} promise
 * @return {string} id Location id
 */
export function* fetchVenue({ promise, id, params }) {
    try {
        const fetchVenueAPI = yield restClient.get(`api/locations/${id}`, { params });
        yield put({
            type: venuesActions.FETCH_SINGLE_VENUE_SUCCESSFUL
        });
        // Format Data
        const formatApiData = dto(fetchVenueAPI.data);
        const storeData = {
            data: formatApiData.data,
            formData: {
                venue_name: formatApiData.data.venue_name,
                location: dtoForm(dto(fetchVenueAPI.data).data)
            }
        };
        yield put({
            type: venuesActions.STORE_SINGLE_VENUE_DATA,
            id,
            response: storeData
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: venuesActions.FETCH_SINGLE_VENUE_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Update Venue single data
 * @param  {Object} id ID of an Location
 * @return {Object} Response from API
 */
export function* updateSingleVenue({ promise, formData, id }) {
    try {
        // Clean formData
        // Address field has additional data that has to be removed to not to 'confuse' the API
        const data = formatLocationFormData(formData);

        // After cleanup check if there is data
        if (Object.keys(data.payload).length !== 0 && data.payload.constructor === Object) {
            const updateSingleVenueAPI = yield restClient.put(`api/locations/${id}`, data.payload);
            yield put({
                type: venuesActions.UPDATE_SINGLE_VENUE_SUCCESSFUL
            });
            yield put({
                type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                response: updateSingleVenueAPI.data
            });
            // Format Data
            // fullAddress and locationPin does not come from the API so use from formData
            const formatApiData = dto(updateSingleVenueAPI.data);
            const storeData = {
                data: formatApiData.data,
                formData: Object.assign({}, data.initialValues, {
                    venue_name: formatApiData.data.venue_name,
                    location: Object.assign(
                        {},
                        data.initialValues.location,
                        dtoForm(dto(updateSingleVenueAPI.data).data)
                    )
                })
            };
            yield put({
                type: venuesActions.STORE_SINGLE_VENUE_DATA,
                id,
                response: storeData
            });
        }
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: venuesActions.UPDATE_SINGLE_VENUE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Delete Single Venue
 * @param  {Object} id ID of an Location
 * @return {Object} Response from API
 */
export function* deleteSingleVenue({ promise, id }) {
    try {
        const deleteSingleVenueAPI = yield restClient.delete(`api/locations/${id}`);
        yield put({
            type: venuesActions.DELETE_SINGLE_VENUE_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: deleteSingleVenueAPI.data
        });
        yield put({
            type: listingActions.START_FETCHING_LISTING,
            entity: 'VENUES',
            endpoint: 'api/locations'
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: venuesActions.DELETE_SINGLE_VENUE_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Register action to watcher
 */
export const venuesSaga = [
    takeLatest(venuesActions.START_CREATE_VENUE, createVenue),
    takeLatest(venuesActions.START_FETCHING_SINGLE_VENUE, fetchVenue),
    takeLatest(venuesActions.START_UPDATE_SINGLE_VENUE, updateSingleVenue),
    takeLatest(venuesActions.START_DELETE_SINGLE_VENUE, deleteSingleVenue)
];
