import { takeLatest, put, call, select } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import { getListingUrlQueryParams } from 'erputils/RouterManager/RouterManager.selectors';
import { parseParamsForApi } from 'erputils/utils';
import { actions as listingActions } from 'erpcomponents/Listing/Listing.reducer';
import { actions as notificationManagerActions } from 'erputils/NotificationManager/NotificationManager.reducer';
import dto from 'erputils/dto';
import { getUserCurrentOrganization } from 'erputils/AuthManager/AuthManager.selectors';
import { actions as ordersActions } from './Orders.reducer';

/**
 * Create Order
 * @param  {Object} promise
 * @return {Object} formData
 */
export function* createOrder({ promise, formData }) {
    try {
        // Prepare data for /orders API
        const userCurrentOrganization = yield select(getUserCurrentOrganization);
        if (!userCurrentOrganization) {
            console.error('Missing Organization ID!');
        }
        formData = Object.assign(formData, {
            organization: userCurrentOrganization.iri
        });

        // Create Order
        const createOrderAPI = yield restClient.post(`api/orders`, formData);
        yield put({
            type: ordersActions.CREATE_ORDER_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: createOrderAPI.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: ordersActions.CREATE_ORDER_FAILED,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Fetch Order
 * @param  {Object} promise
 * @return {string} id Order id
 */
export function* fetchOrder({ promise, id, params }) {
    try {
        const fetchOrderAPI = yield restClient.get(`api/orders/${id}`, { params });
        yield put({
            type: ordersActions.FETCH_SINGLE_ORDER_SUCCESSFUL
        });
        yield put({
            type: ordersActions.STORE_SINGLE_ORDER_DATA,
            id,
            response: dto(fetchOrderAPI.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: ordersActions.FETCH_SINGLE_ORDER_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Update order single data
 * @param  {Object} id ID of an order
 * @return {Object} Response from API
 */
export function* updateSingleOrder({ promise, formData, id }) {
    try {
        const updateSingleOrderAPI = yield restClient.put(
            `api/orders/${id}?include=organization,location,location.country,location.state,location.city`,
            formData
        );
        yield put({
            type: ordersActions.UPDATE_SINGLE_ORDER_SUCCESSFUL
        });
        yield put({
            type: ordersActions.STORE_SINGLE_ORDER_DATA,
            id,
            response: dto(updateSingleOrderAPI.data)
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: updateSingleOrderAPI.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: ordersActions.UPDATE_SINGLE_ORDER_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Update order single data
 * @param  {Object} id ID of an order
 * @return {Object} Response from API
 */
export function* updateSingleOrderChecked({ promise, formData }) {
    try {
        yield put({
            type: ordersActions.UPDATE_SINGLE_ORDER_SUCCESSFUL
        });
        yield put({
            type: ordersActions.STORE_CHECKED_ORDERS,
            response: formData
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: ordersActions.UPDATE_SINGLE_ORDER_FAILED
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Update order single data
 * @param  {Object} id ID of an order
 * @return {Object} Response from API
 */
export function* checkAllOrders({ promise, formData }) {
    try {
        yield put({
            type: ordersActions.UPDATE_SINGLE_ORDER_SUCCESSFUL
        });
        yield put({
            type: ordersActions.STORE_CHECKED_ORDERS,
            response: formData
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: ordersActions.UPDATE_SINGLE_ORDER_FAILED
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Update order single data
 * @param  {Object} id ID of an order
 * @return {Object} Response from API
 */
export function* bulkActionStart({ promise, formData, routes }) {
    try {
        const data = { body: { ...formData }, routes, method: 'PUT' };
        const updateOrdersAPI = yield restClient.post(`api/batch-requests`, data);
        yield put({
            type: ordersActions.BULK_ACTION_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: updateOrdersAPI.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: ordersActions.BULK_ACTION_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Bulk Actions - Delete Orders
 * @param  {Object} iri of the order
 * @return {Object} Response from API
 */
export function* deleteOrdersBulkAction({ promise, formData }) {
    try {
        const params = Object.assign(
            {},
            {
                include:
                    'status,deliveryStatus,reseller,tickets,customer,event,eventSeries,event.ticketTypes,event.company,group'
            },
            yield select(getListingUrlQueryParams)
        );
        formData.method = 'DELETE';
        yield restClient.post('api/batch-requests', formData);
        yield put({
            type: ordersActions.BULK_ACTIONS_DELETE_ORDERS_SUCCESS
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: { code: 'order.itemSuccessfulyRemoved' }
        });
        yield put({
            type: listingActions.UPDATE_BULK_ACTIONS_IRIS,
            response: [],
            name: 'orders'
        });
        yield put({
            type: listingActions.START_FETCHING_LISTING,
            entity: 'ORDERS',
            endpoint: 'api/orders',
            params: parseParamsForApi(params)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: ordersActions.BULK_ACTIONS_DELETE_ORDERS_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Delete Single Order
 * @param  {Object} id ID of an Order
 * @return {Object} Response from API
 */
export function* deleteSingleOrder({ promise, id }) {
    try {
        // Merge default with current params for listing refresh purpose
        const params = Object.assign(
            {},
            { include: 'status,deliveryStatus,reseller,tickets,customer,event' },
            yield select(getListingUrlQueryParams)
        );

        const deleteSingleOrderAPI = yield restClient.delete(`api/orders/${id}`);
        yield put({
            type: ordersActions.DELETE_SINGLE_ORDER_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: deleteSingleOrderAPI.data
        });
        yield put({
            type: listingActions.START_FETCHING_LISTING,
            entity: 'ORDERS',
            endpoint: 'api/orders',
            params: parseParamsForApi(params)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: ordersActions.DELETE_SINGLE_ORDER_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Sync Hubspot
 * @param  {Object} promise
 * @return {Object} formData
 */
export function* syncHubspot({ promise, formData }) {
    try {
        // Create Order
        const syncHubspotAPI = yield restClient.post(`api/orders/sync/hub-spot`, formData);
        yield put({
            type: ordersActions.SYNC_HUBSPOT_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: syncHubspotAPI.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: ordersActions.SYNC_HUBSPOT_FAILED,
            response: error.response.data
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });

        yield call(promise.reject, error.response.data);
    }
}

/**
 * Fetch Order Comments
 * @param  {Object} promise
 * @return {string} id Order id
 */
export function* fetchOrderComments({ promise, id, params }) {
    try {
        const fetchOrdersCommentsAPI = yield restClient.get(`api/orders/${id}/comments`, {
            params
        });
        yield put({
            type: ordersActions.FETCH_SINGLE_ORDER_COMMENTS_SUCCESSFUL
        });
        yield put({
            type: ordersActions.STORE_SINGLE_ORDER_COMMENTS_DATA,
            id,
            response: dto(fetchOrdersCommentsAPI.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: ordersActions.FETCH_SINGLE_ORDER_COMMENTS_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Register action to watcher
 */
export const ordersSaga = [
    takeLatest(ordersActions.START_CREATE_ORDER, createOrder),
    takeLatest(ordersActions.START_FETCHING_SINGLE_ORDER, fetchOrder),
    takeLatest(ordersActions.START_UPDATE_SINGLE_ORDER, updateSingleOrder),
    takeLatest(ordersActions.START_DELETE_SINGLE_ORDER, deleteSingleOrder),
    takeLatest(ordersActions.SINGLE_ORDER_CHECKED, updateSingleOrderChecked),
    takeLatest(ordersActions.CHECK_ALL_ORDERS, checkAllOrders),
    takeLatest(ordersActions.START_BULK_ACTION, bulkActionStart),
    takeLatest(ordersActions.START_SYNC_HUBSPOT, syncHubspot),
    takeLatest(ordersActions.START_BULK_ACTIONS_DELETE_ORDERS, deleteOrdersBulkAction),
    takeLatest(ordersActions.START_FETCHING_SINGLE_ORDER_COMMENTS, fetchOrderComments)
];
