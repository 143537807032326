import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import orderBy from 'lodash/orderBy';
import uniq from 'lodash/uniq';
import LayoutManager from 'erputils/LayoutManager';
import PageHeader from 'erpcomponents/Layout/PageHeader';
import PageContent from 'erpcomponents/Layout/PageContent';
import YearOverYearFilterForm from 'erpsrc/screens/Dashboard/screens/YearOverYear/components/YearOverYearFilterForm';
import EventDetailsTable from 'erpsrc/screens/Dashboard/screens/YearOverYear/components/EventDetailsTable';
import EventTicketTypeAccordion from 'erpsrc/screens/Dashboard/screens/YearOverYear/components/EventTicketTypeAccordion';
import { change, getFormValues } from 'redux-form';
import { actions as yearOverYearAction } from 'erpsrc/screens/Dashboard/screens/YearOverYear/YearOverYear.reducer';
import PageLoader from 'erpcomponents/PageLoader';
import {
    getYearOverYearChartData,
    getYearOverYearEvents,
    getYearOverYearFetching,
    getTicketTypeBreakdownData
} from 'erpsrc/screens/Dashboard/screens/YearOverYear/YearOverYear.selectors';
import { LineChart } from 'erpcomponents/WidgetCharts';
import { kFormatter } from 'erputils/utils';
import generateKey from 'erpcore/utils/generateKey';

import formLineChartData from 'erpsrc/screens/Dashboard/screens/YearOverYear/YearOverYear.utils';
import './YearOverYear.scss';

const YearOverYear = () => {
    const dispatch = useDispatch();
    const [readyToCompare, setReadyToCompare] = useState(false);
    const [disabledField, setDisabledField] = useState({
        startDate: true,
        endDate: true
    });
    const formValues = useSelector(state => getFormValues('YearOverYearFilterForm')(state));
    const eventsDataFetching = useSelector(getYearOverYearFetching);
    const yearOverYearChartData = useSelector(getYearOverYearChartData);
    const eventsData = useSelector(getYearOverYearEvents);
    const eventTicketTypeData = useSelector(state => getTicketTypeBreakdownData(state));
    const [filteredEvents, setFilteredEvents] = useState([]);
    const [showAccordions, setShowAccordions] = useState(true);
    const { eventSeriesFilter, date_range: dateRange, start_date: startDate, end_date: endDate } = {
        ...formValues
    };
    const [hasOnlyEventSeries, setHasOnlyEventSeries] = useState(false);

    const revenueChartData = formLineChartData(
        yearOverYearChartData,
        formValues,
        eventsData,
        'revenue'
    );

    const ticketsSoldChartData = formLineChartData(
        yearOverYearChartData,
        formValues,
        eventsData,
        'attendance'
    );

    const percentageSoldChartData = formLineChartData(
        yearOverYearChartData,
        formValues,
        eventsData,
        '%Sold'
    );

    const revenuePerEventChartData = formLineChartData(
        yearOverYearChartData,
        formValues,
        eventsData,
        'per_event'
    );

    const eventOptions = (() => {
        const keys = Object.keys(eventsData);

        return keys.map(key => {
            return {
                value: eventsData[key].iri,
                label: eventsData[key].name
            };
        });
    })();

    const revenueChartOptions = {
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        callback: value => kFormatter(value)
                    }
                }
            ],
            xAxes: [
                {
                    ticks: {
                        autoSkip: true,
                        maxTicksLimit: 50
                    }
                }
            ]
        },
        tooltips: {
            callbacks: {
                title(tooltipItem) {
                    const label = tooltipItem[0].label || 0;

                    return `Revenue - ${label}`;
                },
                label(tooltipItem, data) {
                    const label = data?.datasets?.[tooltipItem?.datasetIndex]?.label || '';
                    const value = Math.round(tooltipItem?.yLabel * 100) / 100 / 100;
                    const currency = data?.datasets?.[tooltipItem?.datasetIndex]?.currency || '';

                    return [
                        `${label}: ${value
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')} ${currency}`
                    ];
                }
            }
        }
    };

    const ticketsSoldChartOptions = {
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        callback: value => kFormatter(value)
                    }
                }
            ],
            xAxes: [
                {
                    ticks: {
                        autoSkip: true,
                        maxTicksLimit: 50
                    }
                }
            ]
        },
        tooltips: {
            callbacks: {
                title(tooltipItem) {
                    const label = tooltipItem[0].label || 0;

                    return `Tickets sold - ${label}`;
                },
                label(tooltipItem, data) {
                    const label = data?.datasets?.[tooltipItem?.datasetIndex]?.label || '';
                    const value = Math.round(tooltipItem?.yLabel * 100) / 100;

                    return `${label}: ${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
                }
            }
        }
    };

    const percentageSoldChartOptions = {
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        callback: value => kFormatter(value)
                    }
                }
            ],
            xAxes: [
                {
                    ticks: {
                        autoSkip: true,
                        maxTicksLimit: 50
                    }
                }
            ]
        },
        tooltips: {
            callbacks: {
                title(tooltipItem) {
                    const label = tooltipItem[0].label || 0;

                    return `% Sold - ${label}`;
                },

                label(tooltipItem, data) {
                    const label = data?.datasets?.[tooltipItem?.datasetIndex]?.label || '';
                    const value = Math.round(tooltipItem?.yLabel * 100) / 100;

                    return `${label}: ${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}%`;
                }
            }
        }
    };

    // Fetch Events and Event Series //
    const fetchEvents = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: yearOverYearAction.START_FETCHING_YEAR_OVER_YEAR_EVENTS,
                promise: { resolve, reject }
            });
        });
    };

    const getFilterParams = () => {
        const now = new Date();
        let params = {};

        // If date Range inifinity, endpoint requires no start/end date //
        if (dateRange === Infinity) {
            params = {};
        }

        // If prepopulated dates ( last 7d, last 30d etc... )
        if (dateRange !== 'Custom Date' && dateRange !== Infinity) {
            params = {
                'filters[end_date]': new Date(now.getTime()),
                'filters[start_date]': new Date(now.getTime() - dateRange * 24 * 60 * 60 * 1000)
            };
        }

        // If Custom Date ( start - end )
        if (dateRange === 'Custom Date' && startDate && endDate) {
            params = {
                'filters[end_date]': new Date(endDate.getTime()),
                'filters[start_date]': new Date(startDate.getTime())
            };
        }

        return params;
    };

    // Fetch Chart Data //
    const fetchChartData = eventIDs => {
        if (eventIDs?.[0]) {
            return new Promise((resolve, reject) => {
                dispatch({
                    type: yearOverYearAction.START_FETCHING_YEAR_OVER_YEAR_CHART_DATA,
                    promise: { resolve, reject },
                    eventIri: eventIDs?.[0],
                    params: getFilterParams()
                });
            });
        }

        return false;
    };

    // Fetch Ticket Type Breakdown data //
    const fetchTicketTypeBreakdown = eventIri => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: yearOverYearAction.START_FETCHING_TICKETTYPE_BREAKDOWN,
                promise: { resolve, reject },
                eventIri,
                params: getFilterParams()
            });
        });
    };

    const toggleAccordions = () => {
        let eventSeriesCnt = 0;
        if (filteredEvents.length) {
            filteredEvents.map(event => {
                if (event.type === 'EventSeries') {
                    eventSeriesCnt += 1;
                }
                return eventSeriesCnt;
            });

            if (!eventSeriesCnt) {
                setShowAccordions(true);
            }
            if (eventSeriesCnt) {
                setShowAccordions(false);
            }
        }
    };

    const renderAccordions = events => {
        const elems = [];
        if (events?.length) {
            events.map(event =>
                elems.push(
                    <EventTicketTypeAccordion
                        key={generateKey(event.iri)}
                        event={{
                            ...event,
                            eventTicketTypeData: eventTicketTypeData[event.iri]
                        }}
                    />
                )
            );
        }
        return elems;
    };

    /*
     * Effects
     */
    // On initial load fetch events and event series //
    useEffect(() => {
        fetchEvents();
    }, []);

    // If start and end date are set and user changes from custom date to other dates, start,end dates set to null //
    useEffect(() => {
        if (dateRange !== 'Custom Date') {
            setDisabledField({
                startDate: true,
                endDate: true
            });
        } else {
            setDisabledField({
                startDate: false,
                endDate: false
            });
        }

        // On everychange of Date filters refetch data for events for that specific date filter, unless its Custom Date //
        if (eventSeriesFilter && dateRange !== 'Custom Date' && eventSeriesFilter?.length < 7) {
            eventSeriesFilter.forEach(event => {
                fetchChartData([event]);
                fetchTicketTypeBreakdown(event);
            });
        }

        // If Custom Date  //
        if (
            dateRange === 'Custom Date' &&
            startDate &&
            endDate &&
            eventSeriesFilter?.length > 0 &&
            eventSeriesFilter?.length < 7
        ) {
            eventSeriesFilter.forEach(event => {
                fetchChartData([event]);
                fetchTicketTypeBreakdown(event);
            });
        }
    }, [dateRange, startDate, endDate]);

    // On every change of selected event, prepare data for charts and tables for newly selected event //
    useEffect(() => {
        if (eventSeriesFilter && eventSeriesFilter?.length < 7) {
            setReadyToCompare(true);

            // Check Existing Chart Data in redux and compare them to eventSeriesFilter to avoid fetching same chart data that already exists
            // missingChartData only returns Event Iris that dont exist in redux
            const existingChartData = Object.keys(yearOverYearChartData);
            setHasOnlyEventSeries(
                eventSeriesFilter.filter(item => item.indexOf('event-series') !== -1).length ===
                    eventSeriesFilter.length
            );
            const missingChartData = eventSeriesFilter.filter(event => {
                if (existingChartData?.length > 0) {
                    return existingChartData.indexOf(event) < 0;
                }
                return true;
            });

            fetchChartData(missingChartData);

            // preparing events for event details table
            if (eventSeriesFilter?.length < 7 && eventsData?.length) {
                eventSeriesFilter.map(filterValue =>
                    eventsData.filter(event => {
                        if (event?.iri === filterValue && event.type) {
                            fetchTicketTypeBreakdown(event.iri);
                        }
                        return null;
                    })
                );
            }
        }

        if (eventSeriesFilter?.length === 0) {
            setReadyToCompare(false);
        }
    }, [eventSeriesFilter]);

    useEffect(() => {
        if (eventSeriesFilter) {
            let eventsArr = [];
            eventSeriesFilter.map(eventIri => {
                const event = eventsData.filter(item => item.iri === eventIri);
                if (eventTicketTypeData[eventIri]) {
                    eventsArr = [
                        ...eventsArr,
                        { ...event[0], report: eventTicketTypeData[eventIri] }
                    ];
                }
                return eventsArr;
            });

            if (eventsArr.length) {
                setFilteredEvents(eventsArr);
            }
        }
    }, [eventTicketTypeData]);

    useEffect(() => {
        toggleAccordions();
    }, [filteredEvents]);

    /*
     * watch changes in chart data, sort unique dates from first to last,
     * set filter form values accordingly
     */
    useEffect(() => {
        let datesArr = [];
        Object.keys(yearOverYearChartData).map(eventIri => {
            if (eventSeriesFilter?.indexOf(eventIri) > -1) {
                datesArr = [...datesArr, ...Object.keys(yearOverYearChartData[eventIri].data)];
                datesArr = orderBy(uniq(datesArr), o => new Date(o));
            }
            return datesArr;
        });

        const datesArrStart = datesArr[0];
        const datesArrEnd = datesArr[datesArr.length - 1];
        if (datesArrStart && datesArrEnd) {
            dispatch(change('YearOverYearFilterForm', 'start_date', datesArrStart));
            dispatch(change('YearOverYearFilterForm', 'end_date', datesArrEnd));
        }
    }, [yearOverYearChartData, eventSeriesFilter]);

    return (
        <LayoutManager slot="main" layoutType="merge">
            {eventsDataFetching && <PageLoader />}
            <PageHeader
                title={
                    <FormattedMessage
                        id="Dashboards.YearOverYear.title"
                        defaultMessage="Year Over Year"
                    />
                }
            />
            <PageContent className="year-over-year">
                <YearOverYearFilterForm
                    initialValues={{ date_range: Infinity }}
                    options={eventOptions}
                    disabledField={disabledField}
                />

                {readyToCompare && filteredEvents?.length && (
                    <div className="year-over-year__event-details-table">
                        <EventDetailsTable
                            eventsData={filteredEvents}
                            params={{ dateRange, startDate, endDate }}
                        />
                    </div>
                )}

                {readyToCompare && (
                    <>
                        <h3>
                            <FormattedMessage
                                id="Dashboards.YearOverYear.ChartCumulativePercentageSold.title"
                                defaultMessage="Cumulative % Sold"
                            />
                        </h3>
                        <div className="year-over-year__chart">
                            <LineChart
                                data={percentageSoldChartData}
                                gradientFill
                                options={percentageSoldChartOptions}
                            />
                        </div>
                        <h3>
                            <FormattedMessage
                                id="Dashboards.YearOverYear.ChartRevenue.title"
                                defaultMessage="Revenue"
                            />
                        </h3>
                        <div className="year-over-year__chart">
                            <LineChart
                                data={revenueChartData}
                                gradientFill
                                options={revenueChartOptions}
                            />
                        </div>
                        <h3>
                            <FormattedMessage
                                id="Dashboards.YearOverYear.ChartTicketsSold.title"
                                defaultMessage="Tickets Sold"
                            />
                        </h3>
                        <div className="year-over-year__chart">
                            <LineChart
                                data={ticketsSoldChartData}
                                gradientFill
                                options={ticketsSoldChartOptions}
                            />
                        </div>
                        {hasOnlyEventSeries && (
                            <>
                                <h3>
                                    <FormattedMessage
                                        id="Dashboards.YearOverYear.AverageRevenuePerEvent.title"
                                        defaultMessage="Average Revenue Per Event"
                                    />
                                </h3>
                                <div className="year-over-year__chart">
                                    <LineChart
                                        data={revenuePerEventChartData}
                                        gradientFill
                                        options={percentageSoldChartOptions}
                                    />
                                </div>
                            </>
                        )}
                    </>
                )}

                {readyToCompare && showAccordions && (
                    <>
                        <h3>
                            <FormattedMessage
                                id="Dashboards.YearOverYear.TableTicketTypeComparation.title"
                                defaultMessage="Ticket Type Comparation"
                            />
                        </h3>
                        {renderAccordions(filteredEvents)}
                    </>
                )}
            </PageContent>
        </LayoutManager>
    );
};

YearOverYear.defaultProps = {};

YearOverYear.propTypes = {};

export default YearOverYear;
