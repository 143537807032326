import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Tabs from 'erpcomponents/Tabs';

const SingleOrderTabs = ({ match }) => {
    const links = [
        {
            link: `/orders/${match.params.id}/order-info`,
            label: <FormattedMessage id="SingleOrder.Tabs.OrderInfo" defaultMessage="Order Info" />
        },
        {
            link: `/orders/${match.params.id}/comments`,
            label: <FormattedMessage id="SingleOrder.Tabs.Comments" defaultMessage="Comments" />
        },
        {
            link: `/orders/${match.params.id}/activity-log`,
            label: (
                <FormattedMessage id="SingleOrder.Tabs.ActivityLog" defaultMessage="Activity Log" />
            )
        }
    ];

    return <Tabs links={links} />;
};

SingleOrderTabs.defaultProps = {
    match: {}
};

SingleOrderTabs.propTypes = {
    match: PropTypes.oneOfType([PropTypes.object])
};

export default withRouter(SingleOrderTabs);
