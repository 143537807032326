import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import reduxFormErrorMapper from 'erputils/reduxFormErrorMapper';
import { Field, reduxForm, SubmissionError, reset } from 'redux-form';
import Form, { Text, Location } from 'erpcore/components/Form';
import valueValidation from 'erputils/valueValidation';
import LayoutManager from 'erputils/LayoutManager';
import PageHeader from 'erpcomponents/Layout/PageHeader';
import PageContent from 'erpcomponents/Layout/PageContent';
import Button from 'erpcomponents/Button';
import { actions as venuesActions } from 'erpcore/screens/Venues/Venues.reducer';

let VenueCreate = ({ handleSubmit, submitting, pristine, invalid, onSubmit }) => {
    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <PageHeader
                title={<FormattedMessage id="VenueCreate.title" defaultMessage="Add New Venue" />}
                backButtonLink="/venues"
            />
            <PageContent>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Row>
                        <Field
                            name="venue_name"
                            id="venue_name"
                            fieldProps={{
                                label: (
                                    <FormattedMessage
                                        id="VenueCreate.venueName"
                                        defaultMessage="Venue Name"
                                    />
                                ),
                                clearable: true
                            }}
                            component={Text}
                            validate={valueValidation([{ validator: 'required' }])}
                            fieldAttr={{ required: true }}
                        />
                    </Form.Row>
                    <Form.Row>
                        <Field
                            name="location"
                            id="location"
                            component={Location}
                            fieldAttr={{ required: true }}
                        />
                    </Form.Row>
                    <Form.Row>
                        <Button
                            submit
                            disabled={pristine || invalid}
                            loading={submitting}
                            label={<FormattedMessage id="VenueCreate.Add" defaultMessage="Add" />}
                        />
                    </Form.Row>
                </Form>
            </PageContent>
        </LayoutManager>
    );
};

VenueCreate.defaultProps = {
    submitting: false,
    pristine: false,
    invalid: false,
    onSubmit: () => {}
};

VenueCreate.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool
};

VenueCreate = reduxForm({
    form: 'VenueCreateForm',
    enableReinitialize: true
})(VenueCreate);

const mapDispatchToProps = dispatch => ({
    onSubmit: formData => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: venuesActions.START_CREATE_VENUE,
                formData
            });
        })
            .then(() => {
                dispatch(reset('VenueCreateForm'));
            })
            .catch(error => {
                throw new SubmissionError(reduxFormErrorMapper(error));
            });
    }
});

export default connect(
    null,
    mapDispatchToProps
)(VenueCreate);
