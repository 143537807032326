import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';

//  Importing reducers
import { reducer as form } from 'redux-form';
import authManager from 'erpcore/utils/AuthManager/AuthManager.reducer';
import routerManager from 'erpcore/utils/RouterManager/RouterManager.reducer';
import languageManager from 'erpcore/utils/LanguageManager/LanguageManager.reducer';
import notificationManager from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import forgotPassword from 'erpcore/screens/ForgotPassword/ForgotPassword.reducer';
import myAccount from 'erpcore/screens/MyAccount/MyAccount.reducer';
import signUp from 'erpcore/screens/SignUp/SignUp.reducer';
import listing from 'erpcomponents/Listing/Listing.reducer';
import users from 'erpcore/screens/Users/Users.reducer';
import companies from 'erpcore/screens/Companies/Companies.reducer';
import events from 'erpcore/screens/Events/Events.reducer';
import venues from 'erpcore/screens/Venues/Venues.reducer';
import customers from 'erpcore/screens/Customers/Customers.reducer';
import vendors from 'erpcore/screens/Vendors/Vendors.reducer';
import resellers from 'erpcore/screens/Resellers/Resellers.reducer';
import {
    eventCategories,
    eventSeries,
    ageRequirements,
    barTypes,
    foodTypes,
    headerMenus,
    resellerFilters,
    eventsSort,
    homepageSlider,
    ticketSocketApi,
    getOutApi,
    printSettings,
    permissions,
    roles
} from 'erpcore/screens/Settings/Settings.reducer';
import orders from 'erpcore/screens/Orders/Orders.reducer';
import groups from 'erpcore/screens/Groups/Groups.reducer';
import ticketTypes from 'erpcore/screens/Events/screens/EventEdit/TicketTypes.reducer';
import tableTemplates from 'erpcore/screens/Venues/screens/VenuesEdit/TableTemplates/TableTemplates.reducer';
import notifications from 'erpcore/screens/Notifications/Notifications.reducer';
import tables from 'erpcore/screens/Tables/Tables.reducer';
import rooms from 'erpcore/screens/Venues/screens/VenuesEdit/Rooms/Rooms.reducer';
import imageManager from 'erpcomponents/ImageManager/ImageManager.reducer';
import widgets from 'erpcomponents/Widgets/Widgets.reducer';
import dashboards from 'erpcore/screens/Dashboard/Dashboard.reducer';
import activityLog from 'erpcomponents/ActivityLog/ActivityLog.reducer';
import comments from 'erpcomponents/Comments/Comments.reducer';
import yearOverYear from 'erpcore/screens/Dashboard/screens/YearOverYear/YearOverYear.reducer';

//  Combining all existing reducers
const appReducer = combineReducers({
    authManager,
    routerManager,
    languageManager,
    notificationManager,
    listing,
    form,
    forgotPassword,
    signUp,
    myAccount,
    users,
    companies,
    events,
    venues,
    customers,
    vendors,
    resellers,
    eventCategories,
    eventSeries,
    ageRequirements,
    barTypes,
    foodTypes,
    orders,
    groups,
    ticketTypes,
    headerMenus,
    resellerFilters,
    eventsSort,
    tableTemplates,
    homepageSlider,
    notifications,
    tables,
    rooms,
    ticketSocketApi,
    getOutApi,
    imageManager,
    widgets,
    dashboards,
    printSettings,
    activityLog,
    comments,
    permissions,
    roles,
    yearOverYear
});

const rootReducer = (state, action) => {
    if (action.type === 'SIGN_OUT_SUCCESSFUL') {
        storage.removeItem('persist:root');
        state = undefined;
    }

    return appReducer(state, action);
};

export default rootReducer;
