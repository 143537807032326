import { createSelector } from 'reselect';

export const getCompaniesData = (state, id) => state.companies[id];

export const getCompaniesFormData = (state, id) =>
    state.companies &&
    state.companies[id] &&
    state.companies[id].formData &&
    state.companies[id].formData;

export const getSingleCompanyFetching = state => state.companies.singleFetching;

export default createSelector([getCompaniesData, getSingleCompanyFetching, getCompaniesFormData]);
