import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import Form, { RadioGroup } from 'erpcore/components/Form';
import Button from 'erpcomponents/Button';
import { actions as listingActions } from 'erpcomponents/Listing/Listing.reducer';
import {
    getBulkActionsIris,
    getBulkActionsModalIsOpened
} from 'erpcomponents/Listing/Listing.selectors';
import { submittingBulkAction, getGroups } from 'erpcore/screens/Groups/Groups.selectors';
import { actions as groupsActions } from 'erpcore/screens/Groups/Groups.reducer';
// import { getGroupDataFromIri } from 'erpcore/screens/Groups/Groups.selectors';

class MergeGroups extends Component {
    constructor(props) {
        super(props);

        this.state = {
            primaryChosen: false
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.handleModal = this.handleModal.bind(this);
    }

    onSubmit(formData) {
        const { bulkActionsIris, dispatch } = this.props;
        const updatedIris = bulkActionsIris.filter(iri => iri !== formData.iris);
        const data = {
            to_merge_groups: updatedIris,
            primary_group: formData.iris
        };
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: groupsActions.START_BULK_ACTIONS_UPDATE_GROUPS,
                formData: data
            })
        )
            .then(() => this.handleModal())
            .catch(error => {
                return error;
            });
    }

    primaryGroupChosen = () => {
        this.setState({ primaryChosen: true });
    };

    handleModal() {
        const { dispatch, isOpen } = this.props;

        return dispatch({
            type: listingActions.TOGGLE_BULK_ACTIONS_MODAL,
            name: 'groups',
            response: !isOpen
        });
    }

    render() {
        const { handleSubmit, bulkActionsIris, submitting, groups } = this.props;
        const { primaryChosen } = this.state;
        return (
            <Form onSubmit={handleSubmit(this.onSubmit)}>
                <p>
                    <FormattedMessage
                        id="Groups.BulkAction.MergeGroups.Subtitle"
                        defaultMessage="You are about to merge {groups} groups into 1. Please choose a primary group, other groups will be merged into primary group and their orders will be moved into primary group."
                        values={{
                            groups: bulkActionsIris && bulkActionsIris.length
                        }}
                    />
                </p>
                {bulkActionsIris && bulkActionsIris.length && (
                    <Field
                        name="iris"
                        value="iris"
                        component={RadioGroup}
                        fieldProps={{
                            inlineLabel: false,
                            label: '',
                            options: bulkActionsIris.map(iri => {
                                return {
                                    value: iri,
                                    label: `Group - ${groups &&
                                        groups.find(group => group.iri === iri).name}`,
                                    id: `radio=${iri}`
                                };
                            })
                        }}
                        fieldAttr={{ name: 'primary', disabled: false }}
                        onChange={this.primaryGroupChosen}
                    />
                )}
                {/* {bulkActionsIris &&
                    bulkActionsIris.length &&
                    bulkActionsIris.map(iri => (
                        <Form.Row>
                            <Radio
                                fieldProps={{ value: , label: iri, id: `radio-${iri}` }}
                                input={{
                                    name: `radio-${iri}`,
                                    onChange: ev => console.log(ev),
                                    onBlur: event => console.log(event)
                                }}
                                fieldAttr={{ disabled: true }}
                            />
                        </Form.Row>
                    ))} */}
                <Button
                    submit
                    loading={submitting}
                    disabled={!primaryChosen}
                    label={
                        <FormattedMessage
                            id="Groups.BulkAction.MergeGroups.Confirm"
                            defaultMessage="Merge"
                        />
                    }
                />
                <Button
                    variation="secondary"
                    label={
                        <FormattedMessage
                            id="Groups.BulkAction.MergeGroups.Cancel"
                            defaultMessage="Cancel"
                        />
                    }
                    onClick={() => this.handleModal()}
                />
            </Form>
        );
    }
}

MergeGroups.defaultProps = {
    submitting: false,
    dispatch: () => {},
    bulkActionsIris: [],
    isOpen: false,
    groups: []
};

MergeGroups.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    dispatch: PropTypes.func,
    bulkActionsIris: PropTypes.oneOfType([PropTypes.array]),
    isOpen: PropTypes.bool,
    groups: PropTypes.oneOfType([PropTypes.array])
    // getGroupName: PropTypes.func.isRequired
};

MergeGroups = reduxForm({
    form: 'MergeGroupsForm',
    enableReinitialize: true
})(MergeGroups);

const mapStateToProps = state => ({
    initialValues: { iris: getBulkActionsIris(state, 'groups') },
    bulkActionsIris: getBulkActionsIris(state, 'groups'),
    isOpen: getBulkActionsModalIsOpened(state, 'groups'),
    submitting: submittingBulkAction(state),
    groups: getGroups(state)
});

export default connect(mapStateToProps)(MergeGroups);
