import React from 'react';
import PropTypes from 'prop-types';
import FloatingNotifications from 'erpcore/components/Layout/FloatingNotifications';
import './FrontMain.scss';

const FrontMain = ({ children, className }) => {
    return (
        <>
            <main className={`front-main ${className || ''}`}>
                <div className="front-main__content">{children}</div>
            </main>
            <FloatingNotifications />
        </>
    );
};

FrontMain.defaultProps = {
    children: null,
    className: ''
};

FrontMain.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    className: PropTypes.string
};

export default FrontMain;
