const hasPermission = (permission, meData) => {
    const { userPermissions } = { ...meData };

    const permissionElement =
        userPermissions &&
        userPermissions.length > 0 &&
        userPermissions.find(element => element === permission);

    if (permissionElement) {
        return true;
    }

    return false;
};

const getPermissionsAccessForEntities = meData => {
    const entitiesPermissions = {
        companies: false,
        customers: false,
        dashboard: false,
        events: false,
        groups: false,
        notifications: false,
        orders: false,
        resellers: false,
        settings: false,
        tableAllotments: false,
        tableCardPrinting: false,
        users: false,
        venues: false
    };

    // Companies
    if (hasPermission('CAN_MANAGE_COMPANY_BUNDLE_COMPANY', meData)) {
        entitiesPermissions.companies = true;
    }

    // Customers
    if (hasPermission('CAN_MANAGE_ORDER_BUNDLE_CUSTOMER', meData)) {
        entitiesPermissions.customers = true;
    }

    // Dashboard
    entitiesPermissions.dashboard = true;

    // Events
    if (hasPermission('CAN_MANAGE_EVENT_BUNDLE_EVENT', meData)) {
        entitiesPermissions.events = true;
    }

    // Groups
    if (hasPermission('CAN_MANAGE_ORDER_BUNDLE_GROUP', meData)) {
        entitiesPermissions.groups = true;
    }

    // Notifications
    if (hasPermission('CAN_MANAGE_COMMON_NOTIFICATION', meData)) {
        entitiesPermissions.notifications = true;
    }

    // Orders
    if (hasPermission('CAN_MANAGE_ORDER_BUNDLE_ORDER', meData)) {
        entitiesPermissions.orders = true;
    }

    // Resellers

    if (
        hasPermission('CAN_MANAGE_RESELLER_BUNDLE_RESELLER', meData) ||
        hasPermission('CAN_CREATE_RESELLER_BUNDLE_RESELLER', meData) ||
        hasPermission('CAN_READ_RESELLER_BUNDLE_RESELLER', meData) ||
        hasPermission('CAN_UPDATE_RESELLER_BUNDLE_RESELLER', meData) ||
        hasPermission('CAN_DELETE_RESELLER_BUNDLE_RESELLER', meData)
    ) {
        entitiesPermissions.resellers = true;
    }

    // Settings
    if (
        hasPermission('CAN_CUSTOM_MANAGE_CATEGORIES', meData) ||
        hasPermission('CAN_CUSTOM_MANAGE_INTEGRATIONS', meData) ||
        hasPermission('CAN_CUSTOM_MANAGE_RESELLER_ADMIN', meData) ||
        hasPermission('CAN_MANAGE_SECURITY_BUNDLE_PERMISSION', meData) ||
        hasPermission('CAN_MANAGE_SECURITY_BUNDLE_ROLE', meData) ||
        hasPermission('CAN_CUSTOM_MANAGE_PRINT_SETTINGS', meData)
    ) {
        entitiesPermissions.settings = true;
    }

    // Table Allotments
    if (hasPermission('CAN_CUSTOM_MANAGE_TABLE_ALLOTMENTS', meData)) {
        entitiesPermissions.tableAllotments = true;
    }

    // Table Card Printing
    if (hasPermission('CAN_CUSTOM_MANAGE_TABLE_CARD_PRINTING', meData)) {
        entitiesPermissions.tableCardPrinting = true;
    }

    // Users
    if (hasPermission('CAN_MANAGE_COMMON_USER', meData)) {
        entitiesPermissions.users = true;
    }

    // Venues
    if (hasPermission('CAN_CUSTOM_MANAGE_VENUES', meData)) {
        entitiesPermissions.venues = true;
    }

    return entitiesPermissions;
};

export { hasPermission, getPermissionsAccessForEntities };
