import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
// import valueValidation from 'erputils/valueValidation';
import { diff } from 'deep-object-diff';
import { FormattedMessage } from 'react-intl';
import Form, { Text } from 'erpcore/components/Form';
import Button from 'erpcomponents/Button';
import { actions as tableTemplatesActions } from 'erpcore/screens/Venues/screens/VenuesEdit/TableTemplates/TableTemplates.reducer';

class TemplateNameForm extends Component {
    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(formData) {
        const { match, dispatch, initialValues } = this.props;
        formData = diff(initialValues, formData);
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: tableTemplatesActions.START_UPDATE_SINGLE_TABLE_TEMPLATE,
                id: match.params.tid,
                formData
            })
        ).catch(error => {
            return error;
        });
    }

    render() {
        const { handleSubmit, submitting, pristine, invalid } = this.props;
        return (
            <Form onSubmit={handleSubmit(this.onSubmit)}>
                <Form.Row>
                    <Field
                        name="name"
                        id="name"
                        fieldProps={{
                            label: (
                                <FormattedMessage
                                    id="TableTemplateForm.TemplateName"
                                    defaultMessage="Template Name"
                                />
                            ),
                            clearable: true
                        }}
                        component={Text}
                        // validate={valueValidation([{ validator: 'required' }])}
                    />
                </Form.Row>
                <Form.Row>
                    <Button
                        submit
                        disabled={pristine || invalid}
                        loading={submitting}
                        label={
                            <FormattedMessage
                                id="TableTemplateForm.Update"
                                defaultMessage="Update Table Template Name"
                            />
                        }
                    />
                </Form.Row>
            </Form>
        );
    }
}

TemplateNameForm.defaultProps = {
    submitting: false,
    pristine: false,
    invalid: false,
    // fetchTableTemplateData: () => {},
    // singleFetching: false,
    initialValues: {}
};

TemplateNameForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.oneOfType([PropTypes.object]).isRequired,
    initialValues: PropTypes.oneOfType([PropTypes.object]),
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool
    // fetchTableTemplateData: PropTypes.func,
    // singleFetching: PropTypes.bool,
    // newTables: PropTypes.oneOfType([PropTypes.array]).isRequired,
    // reset: PropTypes.func.isRequired
};

TemplateNameForm = reduxForm({
    form: 'TemplateNameForm',
    enableReinitialize: true,
    keepDirtyOnReinitialize: false, // important
    updateUnregisteredFields: false // important
})(TemplateNameForm);

//  Getting initial value populated in the form from the store

export default TemplateNameForm;
