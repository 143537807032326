export const actions = {
    START_FETCHING_TOTALTICKETS24H_DATA: 'START_FETCHING_TOTALTICKETS24H_DATA',
    FETCHING_TOTALTICKETS24H_DATA_SUCCESSFUL: 'FETCHING_TOTALTICKETS24H_DATA_SUCCESSFUL',
    FETCHING_TOTALTICKETS24H_DATA_FAILED: 'FETCHING_TOTALTICKETS24H_DATA_FAILED',
    STORE_TOTALTICKETS24H_DATA: 'STORE_TOTALTICKETS24H_DATA'
};

export const initialState = {};

export default (state = initialState, { type, widgetId, source, response }) => {
    switch (type) {
        case actions.START_FETCHING_TOTALTICKETS24H_DATA: {
            return {
                ...state,
                [widgetId]: { ...state[widgetId], ...{ fetching: true } }
            };
        }
        case actions.FETCHING_TOTALTICKETS24H_DATA_SUCCESSFUL: {
            return {
                ...state,
                [widgetId]: { ...state[widgetId] }
            };
        }
        case actions.FETCHING_TOTALTICKETS24H_DATA_FAILED: {
            return {
                ...state,
                [widgetId]: { ...state[widgetId], ...{ fetching: false } }
            };
        }
        case actions.STORE_TOTALTICKETS24H_DATA: {
            const sourceKey = source || 'no-source';
            return {
                ...state,
                [widgetId]: {
                    ...state[widgetId],
                    ...{
                        [sourceKey]: { ...state[widgetId][sourceKey], ...{ data: response } }
                    },
                    ...{ fetching: false }
                }
            };
        }
        default:
            return state;
    }
};
