import React from 'react';
import { FieldArray, reduxForm } from 'redux-form';
import { Gallery } from 'erpcore/components/Form';
import Accordion from 'erpcomponents/Accordion';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Button from 'erpcomponents/Button';

class FloorPlans extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            displayGallery: false
        };

        this.galleryRef = React.createRef();
    }

    getGalleryInstance() {
        if (this.galleryRef.current !== undefined) {
            return this.galleryRef.current.getRenderedComponent();
        }

        return null;
    }

    handleAccordionOpenInitialized = () => {
        this.setState({ displayGallery: true });
    };

    render() {
        const { eventId } = this.props;
        const { displayGallery } = this.state;

        return (
            <React.Fragment>
                <Accordion
                    title="Floor Plans"
                    variation="border"
                    onOpenInitialized={this.handleAccordionOpenInitialized}
                >
                    {displayGallery && (
                        <FieldArray
                            name="floorPlans"
                            id="event-floor-plans"
                            component={Gallery}
                            emptyGalleryMessage={
                                <React.Fragment>
                                    <p style={{ marginBottom: '8px' }}>
                                        <FormattedMessage
                                            id="TableAllotments.FloorPlans.emptyGalleryMessage.Text"
                                            defaultMessage="Floor Plans are not available!"
                                        />
                                    </p>
                                    <Button
                                        size="small"
                                        variation="secondary"
                                        href={`/events/${eventId}/manage/tables`}
                                        label={
                                            <FormattedMessage
                                                id="TableAllotments.FloorPlans.emptyGalleryMessage.Button"
                                                defaultMessage="Manage Floor Plans"
                                            />
                                        }
                                    />
                                </React.Fragment>
                            }
                            canAddImage={false}
                            canSort={false}
                            canDelete={false}
                            useImageManager={false}
                            useLightbox
                            disableManage
                            featuredKey={false}
                            versionName="entityVersion"
                            attachedToEntity={{
                                type: 'event',
                                iri: `/api/events/${eventId}`,
                                id: eventId
                            }}
                            getImagesApi={`/api/events/${eventId}/images`}
                            galleryApi="/api/event-images"
                            groupName="floorPlans"
                            ref={this.galleryRef}
                            forwardRef
                        />
                    )}
                </Accordion>
            </React.Fragment>
        );
    }
}

FloorPlans.defaultProps = {
    eventId: null
};

FloorPlans.propTypes = {
    eventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

FloorPlans = reduxForm({
    form: 'TableAllotmentsFloorPlan',
    enableReinitialize: true
    // keepDirtyOnReinitialize: false, // important
    // updateUnregisteredFields: false // important
})(FloorPlans);

export default FloorPlans;
