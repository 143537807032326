import React from 'react';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Tabs from 'erpcomponents/Tabs';

const SettingsTabs = () => {
    const links = [
        {
            link: `/settings/categories/event-categories`,
            label: (
                <FormattedMessage
                    id="SettingsTabs.EventCategories"
                    defaultMessage="Event Categories"
                />
            )
        },
        {
            link: `/settings/categories/event-series`,
            label: <FormattedMessage id="SettingsTabs.EventSeries" defaultMessage="Event Series" />
        },
        {
            link: `/settings/categories/age-requirements`,
            label: (
                <FormattedMessage
                    id="SettingsTabs.AgeRequirements"
                    defaultMessage="Age Requirements"
                />
            )
        },
        {
            link: `/settings/categories/bar-types`,
            label: <FormattedMessage id="SettingsTabs.BarTypes" defaultMessage="Bar Types" />
        },
        {
            link: `/settings/categories/food-types`,
            label: <FormattedMessage id="SettingsTabs.FoodTypes" defaultMessage="Food Types" />
        }
    ];

    return <Tabs links={links} />;
};

export default withRouter(SettingsTabs);
