import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './ResellerInfo.scss';
import { FormattedMessage } from 'react-intl';
import Button from 'erpcomponents/Button';
import { getResellerData } from 'erpcore/screens/Resellers/Resellers.selectors';
import StatusBadge from 'erpcore/components/StatusBadge';
import { actions as resellersActions } from 'erpcore/screens/Resellers/Resellers.reducer';
import { dtoForm } from 'erputils/dto';
// import { Field } from 'redux-form';
// import Form from 'erpcore/components/Form';

class ResellerInfo extends Component {
    state = {};

    render() {
        const { reseller, className, onChangePublishedStatus, onChangeIsActiveStatus } = this.props;
        const { email } = reseller;
        const resellerPrimaryAccount = reseller.reseller_primary_account;
        const companyName = reseller.company ? reseller.company.name : '';
        const resellerName = reseller.reseller_name;
        const contactNumber = reseller.contact_number;
        let emailAndContactNumber = '';
        if (email && contactNumber) {
            emailAndContactNumber = `${email} | ${contactNumber}`;
        }
        const published = reseller.micro_site_status === 'Published';
        const status = resellerPrimaryAccount && resellerPrimaryAccount.is_active;
        const active =
            reseller.reseller_primary_account &&
            reseller.reseller_primary_account.is_active &&
            reseller.reseller_primary_account.is_active;
        const subdomain = reseller.nye_sub_domain;
        return (
            <div className={`reseller-info ${className || ''}`}>
                {/* <Form>
                    <Field
                        component="h2"
                        name="company"
                        id="company"
                        fieldProps={{ label: 'name', value: 'iri' }}
                    />
                </Form> */}

                <h2 className="reseller-info__title">{resellerName}</h2>
                <div className="reseller-info__status">
                    <StatusBadge
                        type={active ? 'fulfilled' : 'voided'}
                        text={active ? 'Active' : 'Inactive'}
                    />
                    <StatusBadge
                        type={published ? 'pending' : 'special'}
                        text={published ? 'Published' : 'Unpublished'}
                    />
                </div>

                <div className="reseller-info__content">
                    <p className="reseller-info__venue">{companyName}</p>
                    <p className="reseller-info__date">{emailAndContactNumber}</p>
                </div>

                <div className="reseller-info__actions">
                    <div className="reseller-info__actions-primary">
                        <Button
                            href={`https://${subdomain}.nye.com`}
                            variation="secondary"
                            size="small"
                            external
                            label={
                                <FormattedMessage
                                    id="ResellerInfo.ViewResellerSite"
                                    defaultMessage="View Reseller Site"
                                />
                            }
                        />
                    </div>
                    <div className="reseller-info__actions-secondary">
                        <Button
                            onClick={() =>
                                onChangePublishedStatus(published ? 'Unpublished' : 'Published')
                            }
                            variation="tertiary"
                            size="small"
                            label={
                                published ? (
                                    <FormattedMessage
                                        id="ResellerInfo.Unpublish"
                                        defaultMessage="Set to unpublish"
                                    />
                                ) : (
                                    <FormattedMessage
                                        id="ResellerInfo.Publish"
                                        defaultMessage="Set to publish"
                                    />
                                )
                            }
                        />
                        <Button
                            onClick={() => onChangeIsActiveStatus(!status, resellerPrimaryAccount)}
                            variation="tertiary"
                            size="small"
                            label={
                                active ? (
                                    <FormattedMessage
                                        id="ResellerInfo.SetToInactive"
                                        defaultMessage="Set Account to Inactive"
                                    />
                                ) : (
                                    <FormattedMessage
                                        id="ResellerInfo.SetToActive"
                                        defaultMessage="Set Account to Active"
                                    />
                                )
                            }
                        />
                    </div>
                </div>
            </div>
        );
    }
}

ResellerInfo.defaultProps = {
    className: '',
    reseller: {},
    onChangePublishedStatus: () => {},
    onChangeIsActiveStatus: () => {}
};

ResellerInfo.propTypes = {
    className: PropTypes.string,
    reseller: PropTypes.oneOfType([PropTypes.object]),
    onChangePublishedStatus: PropTypes.func,
    onChangeIsActiveStatus: PropTypes.func
};

//  Getting initial value populated in the form from the store
const mapStateToProps = (state, ownProps) => ({
    reseller: getResellerData(state, ownProps.match.params.id),
    initialValues: dtoForm(getResellerData(state, ownProps.match.params.id))
});
const mapDispatchToProps = (dispatch, ownProps) => ({
    onChangePublishedStatus: published => {
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: resellersActions.START_UPDATE_SINGLE_RESELLER_STATUS,
                id: ownProps.match.params.id,
                formData: { micro_site_status: published }
            })
        )
            .then(() => ownProps.fetchResellerData())
            .catch(error => {
                return error;
            });
    },
    onChangeIsActiveStatus: (status, resellerPrimaryAccount) => {
        const updatedPrimaryAccount = { ...resellerPrimaryAccount, is_active: status };
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: resellersActions.START_UPDATE_SINGLE_RESELLER_STATUS,
                id: ownProps.match.params.id,
                formData: { reseller_primary_account: updatedPrimaryAccount }
            })
        )
            .then(() => ownProps.fetchResellerData())

            .catch(error => {
                return error;
            });
    }
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ResellerInfo)
);
