import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './UpdateEventDescription.scss';
import Svg from 'erpcore/components/Svg';
import Button from 'erpcomponents/Button';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import reduxFormErrorMapper from 'erputils/reduxFormErrorMapper';
import { actions as resellersActions } from 'erpcore/screens/Resellers/Resellers.reducer';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import Form, { Textarea } from 'erpcore/components/Form';
import { dtoForm } from 'erputils/dto';
import {
    getResellerData,
    getEventData,
    getEventDescriptionData
} from 'erpcore/screens/Resellers/Resellers.selectors';

class UpdateEventDescription extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        };
        this.onSubmit = this.onSubmit.bind(this);
        this.togglePanel = this.togglePanel.bind(this);
    }

    onSubmit(formData) {
        const { match, dispatch, reseller, fetchResellerData } = this.props;
        let eventDescriptions = reseller.event_descriptions ? reseller.event_descriptions : [];
        eventDescriptions = [...eventDescriptions, formData];

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: resellersActions.START_UPDATE_SINGLE_RESELLER_SEO,
                id: match.params.id,
                formData: { event_descriptions: eventDescriptions }
            })
        )
            .then(() => fetchResellerData())
            .catch(error => {
                throw new SubmissionError(reduxFormErrorMapper(error));
            });
    }

    togglePanel() {
        const { open } = this.state;
        this.setState({ open: !open });
    }

    openPanel() {
        this.setState({ open: true });
    }

    render() {
        const { handleSubmit, submitting, pristine, invalid, event } = this.props;
        const { open } = this.state;
        return (
            <Form className="event-description" onSubmit={handleSubmit(this.onSubmit)}>
                <div>
                    <div
                        className="event-description__header"
                        onKeyPress={() => this.togglePanel()}
                        onClick={() => this.togglePanel()}
                        role="button"
                        tabIndex="-1"
                    >
                        <span>{event.event && event.event.name}</span>
                        <Svg
                            onClick={() => this.togglePanel()}
                            className="event-description__svg"
                            icon={open ? 'close' : 'arrowDown'}
                        />
                    </div>
                    {open ? (
                        <div className="event-description__description">
                            <Form.Row>
                                <Field
                                    name="seo_description"
                                    id="seo_description"
                                    fieldProps={{
                                        label: (
                                            <FormattedMessage
                                                id="Reseller.UpdateEventDescription.Description"
                                                defaultMessage="Description"
                                            />
                                        ),
                                        clearable: true
                                    }}
                                    component={Textarea}
                                />
                            </Form.Row>
                            <Form.Row>
                                <Button
                                    submit
                                    disabled={pristine || invalid}
                                    loading={submitting}
                                    label={
                                        <FormattedMessage
                                            id="Reseller.UpdateEventDescription.Update"
                                            defaultMessage="Update"
                                        />
                                    }
                                />
                            </Form.Row>
                        </div>
                    ) : null}
                </div>
            </Form>
        );
    }
}

UpdateEventDescription.defaultProps = {
    submitting: false,
    pristine: false,
    invalid: false,
    event: {},
    reseller: {},
    fetchResellerData: () => {}
};
UpdateEventDescription.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    match: PropTypes.oneOfType([PropTypes.object]).isRequired,
    event: PropTypes.oneOfType([PropTypes.object]),
    dispatch: PropTypes.func.isRequired,
    // eventData: PropTypes.oneOfType([PropTypes.object]).isRequired,
    reseller: PropTypes.oneOfType([PropTypes.object]),
    fetchResellerData: PropTypes.func
};

UpdateEventDescription = reduxForm({
    enableReinitialize: true
})(UpdateEventDescription);

const mapStateToProps = (state, ownProps) => ({
    initialValues: dtoForm(getEventData(state, ownProps.match.params.id, ownProps.event.id)),
    reseller: dtoForm(getResellerData(state, ownProps.match.params.id)),
    eventData: dtoForm(getEventDescriptionData(state, ownProps.match.params.id, ownProps.event.id))
});

export default withRouter(connect(mapStateToProps)(UpdateEventDescription));
