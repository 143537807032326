import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Tabs from 'erpcomponents/Tabs';

const LocationEditTabs = ({ match, venueId, lessPadding }) => {
    const links = [
        {
            // eslint-disable-next-line no-unneeded-ternary
            link: `/venues/${venueId ? venueId : match.params.id}/edit/general-info`,
            label: (
                <FormattedMessage
                    id="LocationEdit.Tabs.GeneralInfo"
                    defaultMessage="General Info"
                />
            )
        },
        {
            link: `/venues/${match.params.id}/edit/table-templates`,
            label: (
                <FormattedMessage
                    id="LocationEdit.Tabs.TableTemplates"
                    defaultMessage="Table Templates"
                />
            )
        },
        {
            link: `/venues/${match.params.id}/edit/rooms`,
            label: <FormattedMessage id="LocationEdit.Tabs.Rooms" defaultMessage="Rooms" />
        }
    ];

    return <Tabs links={links} lessPadding={lessPadding} />;
};

LocationEditTabs.defaultProps = {
    match: {},
    venueId: '',
    lessPadding: false
};

LocationEditTabs.propTypes = {
    match: PropTypes.oneOfType([PropTypes.object]),
    venueId: PropTypes.string,
    lessPadding: PropTypes.bool
};

export default withRouter(LocationEditTabs);
