import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { BrowserRouter as Router } from 'react-router-dom';
import { store, persistor } from 'erpcore/store';
import LanguageManager from 'erputils/LanguageManager';
import './assets/scss/base/_base.scss';
import './assets/scss/base/_font.scss';
import './assets/scss/base/_typography.scss';
import RouterManager from 'erputils/RouterManager';
import * as serviceWorker from 'erpsrc/serviceWorker';

/**
|--------------------------------------------------
| Main app initialization
| Preparing Redux for App with Providers
| Rendering App inside wrapper element
|--------------------------------------------------
*/
render(
    <PersistGate persistor={persistor}>
        <Provider store={store}>
            <LanguageManager>
                <Router>
                    <RouterManager />
                </Router>
            </LanguageManager>
        </Provider>
    </PersistGate>,
    document.getElementById('wrapper')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
