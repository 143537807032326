import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import valueValidation from 'erputils/valueValidation';
import { dtoForm } from 'erputils/dto';
import { diff } from 'deep-object-diff';
import { FormattedMessage } from 'react-intl';
import Form, { Text, Email, Phone, Creatable } from 'erpcore/components/Form';
import LayoutManager from 'erputils/LayoutManager';
import PageHeader from 'erpcomponents/Layout/PageHeader';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import PageContent from 'erpcomponents/Layout/PageContent';
import Button from 'erpcomponents/Button';
import PageLoader from 'erpcomponents/PageLoader';
import UserEditTabs from 'erpcore/screens/Users/components/UserEditTabs';

import { actions as usersActions } from 'erpcore/screens/Users/Users.reducer';
import { getUsersData, getSingleUserFetching } from 'erpcore/screens/Users/Users.selectors';

class UserEdit extends Component {
    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        const { fetchUserData } = this.props;
        fetchUserData();
    }

    onSubmit(formData) {
        const { match, dispatch, initialValues } = this.props;
        // send only changed data
        formData = diff(initialValues, formData);

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: usersActions.START_UPDATE_SINGLE_USER,
                id: match.params.id,
                formData
            })
        ).catch(error => {
            return error;
        });
    }

    pageTitle() {
        const { initialValues } = this.props;
        const { first_name: firstName, last_name: lastName } = { ...initialValues };
        if (firstName || lastName) {
            return `Edit User - ${firstName} ${lastName}`;
        }

        return `Edit User`;
    }

    render() {
        const { handleSubmit, submitting, pristine, invalid, singleFetching } = this.props;

        return (
            <LayoutManager slot="main" className="main--narrow" layoutType="merge">
                <HeadMeta title={this.pageTitle()} />
                <PageHeader title={this.pageTitle()} backButtonLink="/users" />
                <PageContent>
                    {singleFetching === true && <PageLoader />}
                    <UserEditTabs />
                    <Form onSubmit={handleSubmit(this.onSubmit)}>
                        <Form.Row>
                            <Field
                                name="first_name"
                                id="first_name"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="UserEdit.firstName"
                                            defaultMessage="First Name"
                                        />
                                    ),
                                    clearable: true
                                }}
                                component={Text}
                                fieldAttr={{ required: true }}
                                validate={valueValidation([{ validator: 'required' }])}
                            />
                        </Form.Row>
                        <Form.Row>
                            <Field
                                name="last_name"
                                id="last_name"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="UserEdit.lastName"
                                            defaultMessage="Last Name"
                                        />
                                    ),
                                    clearable: true
                                }}
                                component={Text}
                                validate={valueValidation([{ validator: 'required' }])}
                                fieldAttr={{ required: true }}
                            />
                        </Form.Row>
                        <Form.Row>
                            <Field
                                name="phone_number"
                                id="phone_number"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="UserEdit.Phone"
                                            defaultMessage="Phone"
                                        />
                                    )
                                }}
                                component={Phone}
                            />
                        </Form.Row>

                        <Form.Row>
                            <Field
                                name="email"
                                id="email"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="UserEdit.email"
                                            defaultMessage="Email Address"
                                        />
                                    ),
                                    clearable: true
                                }}
                                component={Email}
                                validate={valueValidation([{ validator: 'required' }])}
                                fieldAttr={{ required: true }}
                            />
                        </Form.Row>
                        <Form.Row>
                            <Field
                                name="city"
                                id="city"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="UserEdit.City"
                                            defaultMessage="City"
                                        />
                                    ),
                                    options: {
                                        endpoint: '/api/cities',
                                        mapData: {
                                            value: 'iri',
                                            label: 'name'
                                        }
                                    },
                                    clearable: true
                                }}
                                component={Creatable}
                            />
                        </Form.Row>

                        <Form.Row>
                            <Button
                                submit
                                disabled={pristine || invalid}
                                loading={submitting}
                                label={
                                    <FormattedMessage
                                        id="UserEdit.Update"
                                        defaultMessage="Update"
                                    />
                                }
                            />
                        </Form.Row>
                    </Form>
                </PageContent>
            </LayoutManager>
        );
    }
}

UserEdit.defaultProps = {
    submitting: false,
    pristine: false,
    invalid: false,
    fetchUserData: () => {},
    singleFetching: false,
    initialValues: {}
};

UserEdit.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.oneOfType([PropTypes.object]).isRequired,
    initialValues: PropTypes.oneOfType([PropTypes.object]),
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    fetchUserData: PropTypes.func,
    singleFetching: PropTypes.bool
};

UserEdit = reduxForm({
    form: 'UserEditForm',
    enableReinitialize: true
})(UserEdit);

//  Getting initial value populated in the form from the store
const mapStateToProps = (state, ownProps) => ({
    initialValues: dtoForm(getUsersData(state, ownProps.match.params.id)),
    singleFetching: getSingleUserFetching(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchUserData: () => {
        // Getting included data from API by setting params
        const params = {
            include: 'city'
        };
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: usersActions.START_FETCHING_SINGLE_USER,
                id: ownProps.match.params.id,
                params
            });
        }).catch(error => ({ error }));
    }
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(UserEdit)
);
