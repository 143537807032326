import React, { Component } from 'react';
import LayoutManager from 'erputils/LayoutManager';
import frontLayout from 'erputils/LayoutManager/frontLayout';
import ForgotPasswordForm from 'erpcore/screens/ForgotPassword/components/ForgotPasswordForm';
import ResendResetPassword from 'erpcore/screens/ForgotPassword/components/ResendResetPassword';

class ForgotPassword extends Component {
    constructor(props) {
        super(props);

        this.nextStep = this.nextStep.bind(this);
        this.state = {
            step: 1
        };
    }

    nextStep() {
        let { step } = this.state;
        step += 1;
        this.setState({ step });
    }

    render() {
        const { step } = this.state;
        return (
            <LayoutManager layout={frontLayout} slot="main" layoutType="merge">
                {/* Content that goes into the "main" slot */}
                {step === 1 && <ForgotPasswordForm nextStep={this.nextStep} />}
                {step === 2 && <ResendResetPassword />}
            </LayoutManager>
        );
    }
}

export default ForgotPassword;
