import React from 'react';
import PropTypes from 'prop-types';
import NotificationManager from 'erputils/NotificationManager';
import './PageNotification.scss';

const PageNotification = ({ code, data, className }) => {
    return (
        <div className={`page-notification ${className || ''}`}>
            <NotificationManager code={code} data={data} />
        </div>
    );
};

PageNotification.defaultProps = {
    code: null,
    data: null,
    className: ''
};

PageNotification.propTypes = {
    code: PropTypes.string,
    data: PropTypes.string,
    className: PropTypes.string
};

export default PageNotification;
