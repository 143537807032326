export const typeOfProduct = [
    { label: 'Jewelry', value: 'Jewelry' },
    { label: 'Baby & Body', value: 'Baby & Body' },
    { label: 'Baby & Child Items', value: 'Baby & Child Items' },
    { label: 'Adult Fashion', value: 'Adult Fashion' },
    { label: 'Wood Products', value: 'Wood Products' }
];

export const attendanceDuration = [
    {
        label: '1st Week',
        value: '1st Week',
        id: 'first_week'
    },
    {
        label: '2nd Week',
        value: '2nd Week',
        id: 'second_week'
    },
    {
        label: '3rd Week',
        value: '3rd Week',
        id: 'third_week'
    },
    {
        label: '4th Week',
        value: '4th Week',
        id: 'fourth_week'
    },
    {
        label: '5th Week',
        value: '5th Week',
        id: 'fiveth_week'
    },
    {
        label: 'Entire Event',
        value: 'Entire Event',
        id: 'entire_event'
    }
];

export const boothSize = [{ label: '10x10', value: '10x10' }];
