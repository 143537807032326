import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Field, reduxForm, getFormValues, change } from 'redux-form';

import ElementLoader from 'erpcomponents/ElementLoader';
import Widget from 'erpcomponents/Widget';

import './EventSales.scss';

import { actions as eventSalesActions } from 'erpcomponents/Widgets/EventSales/EventSales.reducer';
import { getData, getFetching } from 'erpcomponents/Widgets/EventSales/EventSales.selectors';
import { Select } from 'erpcomponents/Form';
import Listing from 'erpcomponents/Listing';
import { NavLink } from 'react-router-dom';
import { getIdFromIri } from 'erputils/dto';
import generateKey from 'erputils/generateKey';

class EventSales extends Component {
    componentDidMount() {
        const { overallSalesFormValues, setFormValue } = this.props;

        setFormValue('filter', overallSalesFormValues?.filter);

        return this.fetchData();
    }

    componentWillReceiveProps(nextProps) {
        const { dateRange, setFormValue, soldBy } = this.props;
        const { dateRange: nextGlobalDateRange, soldBy: nextSoldBy } = nextProps;

        if (dateRange !== nextGlobalDateRange) {
            setFormValue('filter', nextGlobalDateRange);
        }

        let willFetchData = false;

        if (nextSoldBy !== soldBy) {
            willFetchData = true;
        }

        if (willFetchData) {
            return this.fetchData(dateRange, nextSoldBy);
        }

        return null;
    }

    componentDidUpdate(prevProps) {
        const { overallSalesFormValues, setFormValue, filterValues } = this.props;
        const { overallSalesFormValues: prevOverallSalesFormValues } = prevProps;

        if (filterValues.filter !== prevProps.filterValues.filter) {
            setFormValue('filter', filterValues.filter);
            return this.fetchData();
        }

        if (prevOverallSalesFormValues !== overallSalesFormValues) {
            setFormValue('filter', overallSalesFormValues.filter);
            return this.fetchData();
        }

        return true;
    }

    fetchData() {
        const { dispatch, id, eventID, filterValues, overallSalesFormValues } = this.props;

        const params = {
            display_by: 'event'
        };

        const { filter: dateFilter } = filterValues;
        const { soldByFilter, eventSeriesFilter } = overallSalesFormValues;

        if (dateFilter && dateFilter !== Infinity) {
            const now = new Date();
            const startDate = new Date(now.getTime() - dateFilter * 24 * 60 * 60 * 1000);
            params['filters[start_date]'] = startDate;
            params['filters[end_date]'] = now;
        }

        if (eventSeriesFilter) {
            params['filters[event_series]'] = eventSeriesFilter;
        }

        if (eventID) {
            params['filters[events]'] = eventID;
        }

        if (soldByFilter) {
            params['filters[sold_by]'] = soldByFilter;
        }

        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: eventSalesActions.START_FETCHING_EVENT_SALES_DATA,
                widgetId: id,
                params
            });
        }).catch(error => ({ error }));
    }

    tableData() {
        const { data } = this.props;
        const table = {};
        table.data = [];

        // Table Schema
        table.schema = [
            {
                title: (
                    <FormattedMessage id="EventSales.Table.EventName" defaultMessage="Event Name" />
                ),
                field: 'event_name'
            },
            {
                title: (
                    <FormattedMessage
                        id="EventSales.Table.PercentageSold"
                        defaultMessage="% Sold"
                    />
                ),
                field: 'sales_cycle',
                align: 'right'
            },
            {
                title: (
                    <FormattedMessage
                        id="EventSales.Table.Attendance"
                        defaultMessage="Tickets Sold"
                    />
                ),
                field: 'attendance',
                align: 'right'
            },
            {
                title: (
                    <FormattedMessage
                        id="EventSales.Table.revenuePerAttendee"
                        defaultMessage="Revenue per Attendee"
                    />
                ),
                field: 'revenuePerAttendee',
                align: 'right'
            },
            {
                title: <FormattedMessage id="EventSales.Table.Sales" defaultMessage="Sales" />,
                field: 'revenue',
                align: 'right'
            },
            {
                title: (
                    <FormattedMessage id="EventSales.Table.Delivery" defaultMessage="Delivery" />
                ),
                field: 'delivery',
                align: 'right'
            },
            {
                title: <FormattedMessage id="EventSales.Table.Taxes" defaultMessage="Taxes" />,
                field: 'taxes',
                align: 'right'
            },
            {
                title: (
                    <FormattedMessage
                        id="EventSales.Table.ServiceFees"
                        defaultMessage="Service Fees"
                    />
                ),
                field: 'service_fees',
                align: 'right'
            }
        ];

        if (data && data.data) {
            const dataKeys = Object.keys(data.data);

            dataKeys.sort((a, b) => {
                const revenueFirst = data.data[a].revenue;
                const revenueSecond = data.data[b].revenue;

                return revenueSecond - revenueFirst;
            });

            let revenueSum = 0;
            let ticketsSoldSum = 0;
            let revenuePerAttendeeSum = 0;
            let revenueCurrencyTotals = null;
            let deliverySum = 0;
            let taxesSum = 0;
            let serviceFeesSum = 0;

            // Table Data
            dataKeys.map(key => {
                const match = data.eventsData.find(event => event.id === key);
                const id = match.id && getIdFromIri(match.id);
                const salesCycle =
                    data.data[key] && data.data[key].attendance && data.data[key].max_attendance
                        ? (data.data[key].attendance / data.data[key].max_attendance) * 100
                        : 0;
                const attendance =
                    data.data[key] && data.data[key].attendance && data.data[key].attendance;
                const revenue =
                    data.data[key] && data.data[key].revenue && data.data[key].revenue_currency
                        ? ((style = 'currency') => {
                              return (
                                  <FormattedNumber
                                      value={data.data[key].revenue / 100}
                                      currency={data.data[key].revenue_currency}
                                      style={style}
                                      minimumFractionDigits={2}
                                      maximumFractionDigits={2}
                                  />
                              );
                          })()
                        : data.data[key].revenue;
                const revenuePerAttendee =
                    data.data[key] && data.data[key].revenue && data.data[key].revenue_currency
                        ? ((style = 'currency') => {
                              return (
                                  <FormattedNumber
                                      value={data.data[key].revenue / 100 / attendance}
                                      currency={data.data[key].revenue_currency}
                                      style={style}
                                      minimumFractionDigits={2}
                                      maximumFractionDigits={2}
                                  />
                              );
                          })()
                        : data.data[key].revenue / 100 / attendance;

                const delivery =
                    data.data[key] &&
                    data.data[key].delivery_cost_amount &&
                    data.data[key].revenue_currency
                        ? ((style = 'currency') => {
                              return (
                                  <FormattedNumber
                                      value={data.data[key].delivery_cost_amount / 100}
                                      currency={data.data[key].revenue_currency}
                                      style={style}
                                      minimumFractionDigits={2}
                                      maximumFractionDigits={2}
                                  />
                              );
                          })()
                        : data.data[key].delivery_cost_amount;

                const taxes =
                    data.data[key] && data.data[key].tax_amount && data.data[key].revenue_currency
                        ? ((style = 'currency') => {
                              return (
                                  <FormattedNumber
                                      value={data.data[key].tax_amount / 100}
                                      currency={data.data[key].revenue_currency}
                                      style={style}
                                      minimumFractionDigits={2}
                                      maximumFractionDigits={2}
                                  />
                              );
                          })()
                        : data.data[key].tax_amount;

                const serviceFees =
                    data.data[key] &&
                    data.data[key].services_amount &&
                    data.data[key].revenue_currency
                        ? ((style = 'currency') => {
                              return (
                                  <FormattedNumber
                                      value={data.data[key].services_amount / 100}
                                      currency={data.data[key].revenue_currency}
                                      style={style}
                                      minimumFractionDigits={2}
                                      maximumFractionDigits={2}
                                  />
                              );
                          })()
                        : data.data[key].tax_amount;

                revenueSum += data.data[key] && data.data[key].revenue && data.data[key].revenue;
                ticketsSoldSum +=
                    data.data[key] && data.data[key].attendance && data.data[key].attendance;
                revenuePerAttendeeSum +=
                    data.data[key] &&
                    data.data[key].revenue &&
                    data.data[key].attendance &&
                    data.data[key].revenue / 100 / data.data[key].attendance;
                revenueCurrencyTotals = data.data[key] && data.data[key].revenue_currency;

                deliverySum +=
                    data.data[key] &&
                    data.data[key].delivery_cost_amount &&
                    data.data[key].delivery_cost_amount / 100;

                taxesSum +=
                    data.data[key] && data.data[key].tax_amount && data.data[key].tax_amount / 100;

                serviceFeesSum +=
                    data.data[key] &&
                    data.data[key].services_amount &&
                    data.data[key].services_amount / 100;

                return table.data.push({
                    iri: generateKey(match.attributes.name),
                    event_name: match ? (
                        <NavLink to={`overall-sales/${id}`}>{match.attributes.name}</NavLink>
                    ) : (
                        ''
                    ),
                    revenue,
                    attendance: <FormattedNumber value={attendance} />,
                    revenuePerAttendee,
                    delivery,
                    taxes,
                    service_fees: serviceFees,
                    sales_cycle: `${salesCycle.toFixed(1)} %`
                });
            });

            table.data.push({
                iri: generateKey(
                    <FormattedMessage id="EventSales.Totals" defaultMessage="Totals:" />
                ),
                event_name: (
                    <strong>
                        <FormattedMessage id="EventSales.Totals" defaultMessage="Totals:" />
                    </strong>
                ),
                revenue: ((style = 'currency') => {
                    return (
                        <strong>
                            <FormattedNumber
                                value={Math.round(revenueSum / 100)}
                                currency={revenueCurrencyTotals}
                                style={style}
                                minimumFractionDigits={0}
                                maximumFractionDigits={0}
                            />
                        </strong>
                    );
                })(),
                attendance: (
                    <strong>
                        <FormattedNumber value={ticketsSoldSum} />
                    </strong>
                ),
                revenuePerAttendee: ((style = 'currency') => {
                    return (
                        <strong>
                            <FormattedNumber
                                value={Math.round(revenuePerAttendeeSum)}
                                currency={revenueCurrencyTotals}
                                style={style}
                                minimumFractionDigits={2}
                                maximumFractionDigits={2}
                            />
                        </strong>
                    );
                })(),
                delivery: ((style = 'currency') => {
                    return (
                        <strong>
                            <FormattedNumber
                                value={Math.round(deliverySum)}
                                currency={revenueCurrencyTotals}
                                style={style}
                                minimumFractionDigits={2}
                                maximumFractionDigits={2}
                            />
                        </strong>
                    );
                })(),
                taxes: ((style = 'currency') => {
                    return (
                        <strong>
                            <FormattedNumber
                                value={Math.round(taxesSum)}
                                currency={revenueCurrencyTotals}
                                style={style}
                                minimumFractionDigits={2}
                                maximumFractionDigits={2}
                            />
                        </strong>
                    );
                })(),
                service_fees: ((style = 'currency') => {
                    return (
                        <strong>
                            <FormattedNumber
                                value={Math.round(serviceFeesSum)}
                                currency={revenueCurrencyTotals}
                                style={style}
                                minimumFractionDigits={2}
                                maximumFractionDigits={2}
                            />
                        </strong>
                    );
                })(),
                sales_cycle: ''
            });
        }

        return table;
    }

    renderFilters() {
        return (
            <Field
                name="filter"
                id="filter"
                fieldProps={{
                    label: (
                        <FormattedMessage
                            id="EventSales.Filter.DateRange"
                            defaultMessage="Date Range: "
                        />
                    ),
                    clearable: false,
                    options: [
                        {
                            label: (
                                <FormattedMessage
                                    id="EventSales.Filter.DateRange.Last24h"
                                    defaultMessage="Last 24h"
                                />
                            ),
                            value: 1
                        },
                        {
                            label: (
                                <FormattedMessage
                                    id="EventSales.Filter.DateRange.Last7Days"
                                    defaultMessage="Last 7 Days"
                                />
                            ),
                            value: 7
                        },
                        {
                            label: (
                                <FormattedMessage
                                    id="EventSales.Filter.DateRange.Last30Days"
                                    defaultMessage="Last 30 Days"
                                />
                            ),
                            value: 30
                        },
                        {
                            label: (
                                <FormattedMessage
                                    id="EventSales.Filter.DateRange.Last3Months"
                                    defaultMessage="Last 3 Months"
                                />
                            ),
                            value: 90
                        },
                        {
                            label: (
                                <FormattedMessage
                                    id="EventSales.Filter.DateRange.Last6Months"
                                    defaultMessage="Last 6 Months"
                                />
                            ),
                            value: 180
                        },
                        {
                            label: (
                                <FormattedMessage
                                    id="EventSales.Filter.DateRange.Last12Months"
                                    defaultMessage="Last 12 Months"
                                />
                            ),
                            value: 365
                        },
                        {
                            label: (
                                <FormattedMessage
                                    id="EventSales.Filter.DateRange.AllTime"
                                    defaultMessage="All Time"
                                />
                            ),
                            value: Infinity
                        }
                    ]
                }}
                component={Select}
            />
        );
    }

    render() {
        const { size, fetching } = this.props;

        return (
            <Widget
                title={<FormattedMessage id="EventSales.Title" defaultMessage="Event Sales" />}
                size={size}
                filters={this.renderFilters()}
                className="event-sales"
            >
                {fetching && <ElementLoader overlay />}
                <Listing
                    table={this.tableData()}
                    loading={fetching}
                    name="eventSales"
                    reducerName="WIDGETS"
                    hideSearch
                    hideFooter
                    hideFilters
                    hideHeader
                    disableMobileLayout
                />
            </Widget>
        );
    }
}

EventSales.defaultProps = {
    size: null,
    fetching: false,
    data: {},
    filterValues: { filter: Infinity },
    setFormValue: () => {},
    dateRange: Infinity,
    eventID: null,
    soldBy: null,
    overallSalesFormValues: {}
};

EventSales.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    dispatch: PropTypes.func.isRequired,
    fetching: PropTypes.bool,
    data: PropTypes.oneOfType([PropTypes.object]),
    filterValues: PropTypes.oneOfType([PropTypes.object]),
    setFormValue: PropTypes.func,
    dateRange: PropTypes.number,
    eventID: PropTypes.string,
    soldBy: PropTypes.string,
    overallSalesFormValues: PropTypes.oneOfType([PropTypes.object])
};

//  Getting initial value populated in the form from the store
const mapStateToProps = (state, ownProps) => {
    const overallSalesFormValues = getFormValues('OverallSalesForm')(state);

    return {
        data: getData(state, ownProps.id),
        fetching: getFetching(state, ownProps.id),
        filterValues: getFormValues('EventSalesForm')(state),
        overallSalesFormValues
    };
};

EventSales = reduxForm(
    {
        form: 'EventSalesForm',
        destroyOnUnmount: false
    },
    mapStateToProps
)(EventSales);

const mapDispatchToProps = dispatch => ({
    setFormValue: (field, value) => dispatch(change('EventSalesForm', field, value))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EventSales);
