export const actions = {
    START_FETCHING_AGE_REQUIREMENTS: 'START_FETCHING_AGE_REQUIREMENTS',
    FETCHING_SUCCESSFUL_AGE_REQUIREMENTS: 'FETCHING_SUCCESSFUL_AGE_REQUIREMENTS',
    FETCHING_FAILED_AGE_REQUIREMENTS: 'FETCHING_FAILED_AGE_REQUIREMENTS',
    START_CREATE_AGE_REQUIREMENT: 'START_CREATE_AGE_REQUIREMENT',
    CREATE_AGE_REQUIREMENT_SUCCESSFUL: 'CREATE_AGE_REQUIREMENT_SUCCESSFUL',
    CREATE_AGE_REQUIREMENT_FAILED: 'CREATE_AGE_REQUIREMENT_FAILED',
    START_DELETE_SINGLE_AGE_REQUIREMENT: 'START_DELETE_SINGLE_AGE_REQUIREMENT',
    DELETE_SINGLE_AGE_REQUIREMENT_SUCCESSFUL: 'DELETE_SINGLE_AGE_REQUIREMENT_SUCCESSFUL',
    DELETE_SINGLE_AGE_REQUIREMENT_FAILED: 'DELETE_SINGLE_AGE_REQUIREMENT_FAILED',
    START_UPDATE_SINGLE_AGE_REQUIREMENT: 'START_UPDATE_SINGLE_AGE_REQUIREMENT',
    START_FETCHING_SINGLE_AGE_REQUIREMENT: 'START_FETCHING_SINGLE_AGE_REQUIREMENT',
    FETCH_SINGLE_AGE_REQUIREMENT_SUCCESSFUL: 'FETCH_SINGLE_AGE_REQUIREMENT_SUCCESSFUL',
    FETCH_SINGLE_AGE_REQUIREMENT_FAILED: 'FETCH_SINGLE_AGE_REQUIREMENT_FAILED',
    UPDATE_SINGLE_AGE_REQUIREMENT_SUCCESSFUL: 'UPDATE_SINGLE_AGE_REQUIREMENT_SUCCESSFUL',
    UPDATE_SINGLE_AGE_REQUIREMENT_FAILED: 'UPDATE_SINGLE_AGE_REQUIREMENT_FAILED',
    STORE_SINGLE_AGE_REQUIREMENT_DATA: 'STORE_SINGLE_AGE_REQUIREMENT_DATA'
};

export const initialState = {
    listingFetching: false,
    listingData: [],
    listingMeta: {},
    listingError: [],
    singleFetching: false,
    ageRequirements: []
};

export default (state = initialState, { type, id, response }) => {
    switch (type) {
        case actions.START_FETCHING_AGE_REQUIREMENTS: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_AGE_REQUIREMENTS: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_AGE_REQUIREMENTS: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        case actions.START_CREATE_AGE_REQUIREMENTS: {
            return state;
        }
        case actions.START_FETCHING_SINGLE_AGE_REQUIREMENT: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.FETCH_SINGLE_AGE_REQUIREMENT_SUCCESSFUL: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.FETCH_SINGLE_AGE_REQUIREMENT_FAILED: {
            return {
                ...state,
                singleFetching: false,
                singleError: response
            };
        }
        case actions.UPDATE_SINGLE_AGE_REQUIREMENT_FAILED: {
            return {
                ...state,
                singleError: response
            };
        }

        case actions.STORE_SINGLE_AGE_REQUIREMENT_DATA: {
            return Object.assign({}, state, {
                [id]: response.data
            });
        }
        default:
            return state;
    }
};
