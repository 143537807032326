import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch } from 'erpcore/components/Form';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import InfiniteScroll from 'react-infinite-scroll-component';

import LayoutManager from 'erputils/LayoutManager';
import generateKey from 'erputils/generateKey';

import PageHeader from 'erpcomponents/Layout/PageHeader';
import PageLoader from 'erpcomponents/PageLoader';
import PageContent from 'erpcomponents/Layout/PageContent';
import Filter from 'erpcore/components/Listing/components/Filter';
import Notification from 'erpcore/screens/Notifications/components/Notification';

import { getListingFetching, getListingResponse } from 'erpcomponents/Listing/Listing.selectors';
import { getNotifications } from 'erpcore/screens/Notifications/Notifications.selectors';
import { actions as listingActions } from 'erpcomponents/Listing/Listing.reducer';

import 'erpcore/screens/Notifications/screens/NotificationsListing/Notifications.scss';

const filterSchema = [
    {
        name: 'status',
        label: (
            <FormattedMessage
                id="Notifications.Listing.Filter.Status.Label"
                defaultMessage="Status"
            />
        ),
        defaultOperator: 'equals',
        filterFields: {
            value: {
                component: 'select',
                fieldProps: {
                    label: (
                        <FormattedMessage
                            id="Notifications.Listing.Filter.Status"
                            defaultMessage="Status"
                        />
                    ),
                    options: [
                        { label: 'Completed', value: 'completed' },
                        { label: 'Pending', value: 'pending' }
                    ]
                }
            }
        }
    }
];

class EventsListing extends Component {
    state = {
        toggleActionables: false,
        actionablesFilter: null
    };

    toggleActionables = () => {
        const { toggleActionables } = this.state;
        this.setState({ toggleActionables: !toggleActionables });
    };

    componentDidMount = () => {
        const { fetchNotifications } = this.props;
        fetchNotifications();
    };

    fetchMoreData = () => {
        const { fetchNotifications, listingResponse, notifications } = this.props;
        const { toggleActionables, actionablesFilter } = this.state;

        if (notifications.length < listingResponse.meta.totalItems) {
            if (toggleActionables && actionablesFilter) {
                fetchNotifications({
                    page: listingResponse.meta.currentPage + 1,
                    'filters[actionable][equals]': true,
                    'filters[status][equals]': actionablesFilter
                });
            } else if (toggleActionables) {
                fetchNotifications({
                    page: listingResponse.meta.currentPage + 1,
                    'filters[actionable][equals]': true
                });
            } else {
                fetchNotifications({ page: listingResponse.meta.currentPage + 1 });
            }
        }
    };

    render() {
        const { toggleActionables } = this.state;
        const { notifications, notificationsListingFetching, fetchNotifications } = this.props;
        const data = notifications && notifications;

        return (
            <LayoutManager slot="main" className="main--narrow" layoutType="merge">
                <PageHeader
                    title={
                        <FormattedMessage id="Notifications.title" defaultMessage="Notifications" />
                    }
                />
                <PageContent className="page-content--notifications">
                    {notificationsListingFetching && <PageLoader />}
                    <div className="listing__header">
                        <div className="listing__header-col">
                            <div className="listing__header-col">
                                <Switch
                                    input={{
                                        value: toggleActionables,
                                        onChange: () => {
                                            this.setState({
                                                toggleActionables: !toggleActionables
                                            });
                                            fetchNotifications(
                                                !toggleActionables
                                                    ? {
                                                          'filters[actionable][equals]': true
                                                      }
                                                    : {}
                                            );
                                        }
                                    }}
                                    fieldProps={{
                                        label: 'Display only actionable items',
                                        on: {
                                            label: '',
                                            value: true,
                                            id: 'switch-on-true'
                                        },
                                        off: {
                                            label: 'Display only actionable items',
                                            value: false,
                                            id: 'switch-off-false'
                                        },
                                        type: 'single'
                                    }}
                                    fieldAttr={{ id: 'field-id', disabled: false }}
                                />
                            </div>
                            {toggleActionables && (
                                <div className="listing__header-col">
                                    <Filter
                                        form="FilterNotifications"
                                        onSubmit={ev => {
                                            if (
                                                ev &&
                                                ev.filter &&
                                                ev.filter.status &&
                                                ev.filter.status[0] &&
                                                ev.filter.status[0].value
                                            ) {
                                                this.setState({
                                                    actionablesFilter: ev.filter.status[0].value
                                                });
                                                fetchNotifications({
                                                    'filters[actionable][equals]': true,
                                                    'filters[status][equals]':
                                                        ev.filter.status[0].value
                                                });
                                            }
                                        }}
                                        onChange={ev => {
                                            if (
                                                ev &&
                                                ev.filter &&
                                                ev.filter.status &&
                                                ev.filter.status[0] &&
                                                ev.filter.status[0].value === null
                                            ) {
                                                this.setState({
                                                    actionablesFilter: null
                                                });
                                                fetchNotifications({
                                                    'filters[actionable][equals]': true
                                                });
                                            }
                                        }}
                                        filterSchema={filterSchema}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    <InfiniteScroll
                        dataLength={notifications.length}
                        next={this.fetchMoreData}
                        hasMore
                        loader={PageLoader}
                    >
                        {data.map(notification => (
                            <div
                                className="container"
                                key={generateKey(`NotificationContainer-${notification.id}`)}
                            >
                                <Notification key={notification.id} data={notification} />
                            </div>
                        ))}
                    </InfiniteScroll>
                </PageContent>
            </LayoutManager>
        );
    }
}

EventsListing.defaultProps = {
    fetchNotifications: () => {},
    notifications: {},
    listingResponse: {},
    notificationsListingFetching: false
};

EventsListing.propTypes = {
    fetchNotifications: PropTypes.func,
    notifications: PropTypes.oneOfType([PropTypes.object]),
    listingResponse: PropTypes.oneOfType([PropTypes.object]),
    notificationsListingFetching: PropTypes.bool
};

//  Redux
const mapStateToProps = state => ({
    notificationsListingFetching: getListingFetching(state, 'notifications'),
    notifications: getNotifications(state),
    listingResponse: getListingResponse(state, 'notifications')
});

const mapDispatchToProps = dispatch => ({
    fetchNotifications: params => {
        const defaultParams = {
            'order_by[createdAt]': 'DESC'
        };
        params = {
            ...defaultParams,
            ...params
        };
        // Getting included data from API by setting defaultParams
        dispatch({
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: 'NOTIFICATIONS',
            endpoint: `api/notifications`
        });
    }
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(EventsListing)
);
