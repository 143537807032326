const arrayUnion = (arr1 = [], arr2 = [], equalityFunc) => {
    const union = arr1.concat(arr2);
    for (let i = 0; i < union.length; i += 1) {
        for (let j = i + 1; j < union.length; j += 1) {
            if (equalityFunc(union[i], union[j])) {
                union.splice(j, 1);
                j -= 1;
            }
        }
    }
    return union;
};

export default arrayUnion;
