import React from 'react';
import PropTypes from 'prop-types';
import { FormattedNumber } from 'react-intl';
import Cardbox from 'erpcore/components/Cardbox';
import Svg from 'erpcomponents/Svg';
import './PaymentInfoCard.scss';

const style = {
    currency: 'currency'
};

const handleCardHeader = () => {
    return (
        <div className="payment-info-header">
            <span>Event Details</span>
            <span>Price</span>
            <span>Fees</span>
            <span>Total</span>
        </div>
    );
};

const handleCardBody = data => {
    const includedData = data && data.included;
    const cardData = [];
    if (includedData) {
        includedData.forEach(el => {
            cardData[el.type] = el;
        });
    }

    const ticketTypes = data && data.ticket_types;
    const eventName = cardData && cardData.Event && cardData.Event.name;
    const eventId = cardData && cardData.Event && cardData.Event.id;
    const totalTax = data.total_tax ? data.total_tax : 0;
    return (
        <div className="payment-info-body">
            <div className="payment-info-body__event">
                <span>
                    {eventName}{' '}
                    {eventId && (
                        <a
                            href={`/events/${eventId}/edit/event-details`}
                            className="payment-info-body__event--link"
                        >
                            {' '}
                            <Svg icon="editLink" className="payment-info-body__event--icon" />
                        </a>
                    )}
                </span>
            </div>
            <div className="payment-info-body__tickets">
                {ticketTypes &&
                    ticketTypes.map(ticket => {
                        const ticketAmount = ticket.price.amount / 100;
                        const ticketFeeAmount = ticket.fees.amount / 100;
                        return (
                            <div key={ticket.name} className="payment-info-body__details">
                                <div>
                                    {ticket.quantity}x {ticket.name}
                                </div>
                                <div>
                                    <FormattedNumber
                                        value={ticketAmount}
                                        minimumFractionDigits={2}
                                    />
                                </div>
                                <div>
                                    <FormattedNumber
                                        value={ticketFeeAmount}
                                        minimumFractionDigits={2}
                                    />
                                </div>
                                <div>
                                    <FormattedNumber
                                        value={
                                            ticketAmount * ticket.quantity +
                                            ticketFeeAmount * ticket.quantity +
                                            totalTax.amount
                                        }
                                        currency={ticket.price.currency}
                                        style={style.currency}
                                    />
                                </div>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

const handleCardFooter = data => {
    const subtotal = data && data.subtotal;
    const fees = data && data.total_fees;
    const feesSubtotal = data && (data.total_fees.amount - data.delivery_cost.amount) / 100;
    const total = data && data.total;
    const delivery = data && data.delivery_cost;
    const totalTax = data && data.total_tax && data.total_tax;

    return (
        <div className="payment-info-footer">
            <div className="payment-info-footer__subtotal">
                <span>Subtotal</span>
                <span>
                    <FormattedNumber
                        value={subtotal.amount / 100}
                        currency={subtotal.currency}
                        style={style.currency}
                    />
                </span>
            </div>
            <div className="payment-info-footer__fee">
                <span>Service Fee</span>
                <span>
                    <FormattedNumber
                        value={feesSubtotal}
                        currency={fees.currency}
                        style={style.currency}
                    />
                </span>
            </div>

            <div className="payment-info-footer__fee">
                <span>Tax</span>
                <span>
                    <FormattedNumber
                        value={totalTax.amount / 100}
                        currency="USD"
                        style={style.currency}
                    />
                </span>
            </div>

            {delivery && delivery.amount > 0 && (
                <div className="payment-info-footer__fee">
                    <span>Delivery Cost</span>
                    <span>
                        <FormattedNumber
                            value={delivery.amount / 100}
                            currency={delivery.currency}
                            style={style.currency}
                        />
                    </span>
                </div>
            )}
            <div className="payment-info-footer__total">
                <span>Total</span>
                <span className="payment-info-footer__total--bold">
                    <FormattedNumber
                        value={total.amount / 100}
                        currency={total.currency}
                        style={style.currency}
                    />
                </span>
            </div>
        </div>
    );
};

const PaymentInfoCard = ({ data }) => {
    const headerCardData = handleCardHeader();
    const bodyCardData = data && handleCardBody(data);
    const footerCardData = data && handleCardFooter(data);

    return <Cardbox header={headerCardData} body={bodyCardData} footer={footerCardData} />;
};

PaymentInfoCard.propTypes = {
    data: PropTypes.oneOfType([PropTypes.object]).isRequired
};

export default PaymentInfoCard;
