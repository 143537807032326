import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm, SubmissionError } from 'redux-form';
// import valueValidation from 'erputils/valueValidation';
import { FormattedMessage } from 'react-intl';
import reduxFormErrorMapper from 'erputils/reduxFormErrorMapper';
import Form, { Text, Textarea } from 'erpcore/components/Form';
import LayoutManager from 'erputils/LayoutManager';
import PageHeader from 'erpcomponents/Layout/PageHeader';
import PageContent from 'erpcomponents/Layout/PageContent';
import EventInfo from 'erpcore/screens/Events/components/EventInfo';
import EventEditTabs from 'erpcore/screens/Events/components/EventEditTabs';
import Button from 'erpcomponents/Button';
import { actions as eventsActions } from 'erpcore/screens/Events/Events.reducer';
import { getEventData } from 'erpcore/screens/Events/Events.selectors';
import { diff } from 'deep-object-diff';

class EventThirdPartyConnect extends Component {
    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        const { fetchEventData, match } = this.props;
        fetchEventData(match.params.id);
    }

    onSubmit(formData) {
        const { match, dispatch, initialValues } = this.props;
        // send only changed data
        formData = diff(initialValues, formData);

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: eventsActions.START_UPDATE_SINGLE_EVENT_3RDPC,
                id: match.params.id,
                formData
            })
        ).catch(error => {
            throw new SubmissionError(reduxFormErrorMapper(error));
        });
    }

    render() {
        const { handleSubmit, submitting, pristine, invalid } = this.props;
        return (
            <LayoutManager slot="main" className="main--narrow" layoutType="merge">
                <PageHeader
                    title={
                        <FormattedMessage id="EventEdit.3DPC.title" defaultMessage="Edit Event" />
                    }
                    backButtonLink="/events"
                />
                <EventInfo />
                <EventEditTabs />
                <PageContent>
                    {/* {error && error.code && <NotificationManager code={error.code} />} */}
                    <Form onSubmit={handleSubmit(this.onSubmit)}>
                        <Form.Row>
                            <Form.Row>
                                <Field
                                    name="crave_event_id"
                                    id="crave_event_id"
                                    fieldProps={{
                                        label: (
                                            <FormattedMessage
                                                id="EventEdit.3DPC.CraveEventID"
                                                defaultMessage="Crave Event ID"
                                            />
                                        ),
                                        clearable: true
                                    }}
                                    component={Text}
                                />
                            </Form.Row>
                        </Form.Row>
                        <Form.Row>
                            <Form.Row>
                                <Field
                                    name="crave_event_url"
                                    id="crave_event_url"
                                    fieldProps={{
                                        label: (
                                            <FormattedMessage
                                                id="EventEdit.3DPC.CreateEventURL"
                                                defaultMessage="Crave Event URL"
                                            />
                                        ),
                                        clearable: true
                                    }}
                                    component={Text}
                                />
                            </Form.Row>
                        </Form.Row>
                        <Form.Row>
                            <Form.Row>
                                <Field
                                    name="crave_tickets_feed_url"
                                    id="crave_tickets_feed_url"
                                    fieldProps={{
                                        label: (
                                            <FormattedMessage
                                                id="EventEdit.3DPC.CreateTicketsFeedURL"
                                                defaultMessage="Crave Tickets Feed URL"
                                            />
                                        ),
                                        clearable: true
                                    }}
                                    component={Text}
                                />
                            </Form.Row>
                        </Form.Row>

                        <Form.Row>
                            <Form.Row>
                                <Field
                                    name="ticket_socket_event_id"
                                    id="ticket_socket_event_id"
                                    fieldProps={{
                                        label: (
                                            <FormattedMessage
                                                id="EventEdit.3DPC.TicketsocketEventID"
                                                defaultMessage="Ticketsocket Event ID"
                                            />
                                        ),
                                        clearable: true
                                    }}
                                    component={Text}
                                />
                            </Form.Row>
                        </Form.Row>
                        <Form.Row>
                            <Form.Row>
                                <Field
                                    name="ticket_socket_event_url"
                                    id="ticket_socket_event_url"
                                    fieldProps={{
                                        label: (
                                            <FormattedMessage
                                                id="EventEdit.3DPC.TicketsocketEventURL"
                                                defaultMessage="Ticketsocket Event URL"
                                            />
                                        ),
                                        clearable: true
                                    }}
                                    component={Text}
                                />
                            </Form.Row>
                        </Form.Row>
                        <Form.Row>
                            <Form.Row>
                                <Field
                                    name="ticket_socket_js_widget"
                                    id="ticket_socket_js_widget"
                                    fieldProps={{
                                        label: (
                                            <FormattedMessage
                                                id="EventEdit.3DPC.TicketsocketJsWidget"
                                                defaultMessage="Ticketsocket JS Widget"
                                            />
                                        ),
                                        clearable: true
                                    }}
                                    component={Textarea}
                                />
                            </Form.Row>
                        </Form.Row>
                        <Form.Row>
                            <Button
                                submit
                                disabled={pristine || invalid}
                                loading={submitting}
                                label={
                                    <FormattedMessage
                                        id="EventEdit.3DPC.Update"
                                        defaultMessage="Update"
                                    />
                                }
                            />
                        </Form.Row>
                    </Form>
                </PageContent>
            </LayoutManager>
        );
    }
}

EventThirdPartyConnect.defaultProps = {
    submitting: false,
    pristine: false,
    invalid: false,
    fetchEventData: () => {},
    initialValues: {}
};

EventThirdPartyConnect.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.oneOfType([PropTypes.object]).isRequired,
    initialValues: PropTypes.oneOfType([PropTypes.object]),
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    fetchEventData: PropTypes.func
};

EventThirdPartyConnect = reduxForm({
    form: 'EventThirdPartyConnectForm',
    enableReinitialize: true
})(EventThirdPartyConnect);

//  Getting initial value populated in the form from the store
const mapStateToProps = (state, ownProps) => ({
    initialValues: getEventData(state, ownProps.match.params.id)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchEventData: () => {
        // Getting included data from API by setting params
        const params = {
            include: 'organization,location,location.country,location.state,location.city'
        };
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: eventsActions.START_FETCHING_SINGLE_EVENT,
                id: ownProps.match.params.id,
                params
            });
        }).catch(error => ({ error }));
    }
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(EventThirdPartyConnect)
);
