export const actions = {
    START_FETCHING_ORDERS: 'START_FETCHING_ORDERS',
    FETCHING_SUCCESSFUL_ORDERS: 'FETCHING_SUCCESSFUL_ORDERS',
    FETCHING_FAILED_ORDERS: 'FETCHING_FAILED_ORDERS',
    START_CREATE_ORDER: 'START_CREATE_ORDER',
    CREATE_ORDER_SUCCESSFUL: 'CREATE_ORDER_SUCCESSFUL',
    CREATE_ORDER_FAILED: 'CREATE_ORDER_FAILED',
    ORDER_CREATE_LOCATION_SUCCESSFUL: 'ORDER_CREATE_LOCATION_SUCCESSFUL',
    ORDER_CREATE_LOCATION_FAILED: 'ORDER_CREATE_LOCATION_FAILED',
    START_FETCHING_SINGLE_ORDER: 'START_FETCHING_SINGLE_ORDER',
    FETCH_SINGLE_ORDER_SUCCESSFUL: 'FETCH_SINGLE_ORDER_SUCCESSFUL',
    FETCH_SINGLE_ORDER_FAILED: 'FETCH_SINGLE_ORDER_FAILED',
    START_UPDATE_SINGLE_ORDER: 'START_UPDATE_SINGLE_ORDER',
    UPDATE_SINGLE_ORDER_SUCCESSFUL: 'UPDATE_SINGLE_ORDER_SUCCESSFUL',
    UPDATE_SINGLE_ORDER_FAILED: 'UPDATE_SINGLE_ORDER_FAILED',
    START_DELETE_SINGLE_ORDER: 'START_DELETE_SINGLE_ORDER',
    DELETE_SINGLE_ORDER_SUCCESSFUL: 'DELETE_SINGLE_ORDER_SUCCESSFUL',
    DELETE_SINGLE_ORDER_FAILED: 'DELETE_SINGLE_ORDER_FAILED',
    STORE_SINGLE_ORDER_DATA: 'STORE_SINGLE_ORDER_DATA',
    SINGLE_ORDER_CHECKED: 'SINGLE_ORDER_CHECKED',
    CHECK_ALL_ORDERS: 'CHECK_ALL_ORDERS',
    STORE_CHECKED_ORDERS: 'STORE_CHECKED_ORDERS',
    START_BULK_ACTION: 'START_BULK_ACTION',
    BULK_ACTION_SUCCESSFUL: 'BULK_ACTION_SUCCESSFUL',
    BULK_ACTION_FAILED: 'BULK_ACTION_FAILED',

    START_SYNC_HUBSPOT: 'START_SYNC_HUBSPOT',
    SYNC_HUBSPOT_SUCCESSFUL: 'SYNC_HUBSPOT_SUCCESSFUL',
    SYNC_HUBSPOT_FAILED: 'SYNC_HUBSPOT_FAILED',

    START_BULK_ACTIONS_DELETE_ORDERS: 'START_BULK_ACTIONS_DELETE_ORDERS',
    BULK_ACTIONS_DELETE_ORDERS_SUCCESS: 'BULK_ACTIONS_DELETE_ORDERS_SUCCESS',
    BULK_ACTIONS_DELETE_ORDERS_FAILED: 'BULK_ACTIONS_DELETE_ORDERS_FAILED',

    START_FETCHING_SINGLE_ORDER_COMMENTS: 'START_FETCHING_SINGLE_ORDER_COMMENTS',
    FETCH_SINGLE_ORDER_COMMENTS_SUCCESSFUL: 'FETCH_SINGLE_ORDER_COMMENTS_SUCCESSFUL',
    FETCH_SINGLE_ORDER_COMMENTS_FAILED: 'FETCH_SINGLE_ORDER_COMMENTS_FAILED',
    STORE_SINGLE_ORDER_COMMENTS_DATA: 'STORE_SINGLE_ORDER_COMMENTS_DATA',
    START_POSTING_SINGLE_ORDER_COMMENT: 'START_POSTING_SINGLE_ORDER_COMMENT'
};

export const initialState = {
    listingFetching: false,
    listingData: [],
    listingMeta: {},
    listingError: [],
    singleFetching: false,
    orders: [],
    checked: [],
    hubspotSyncing: false,
    submittingBulkAction: false,
    comments: {}
};

export default (state = initialState, { type, id, response }) => {
    switch (type) {
        case actions.START_FETCHING_ORDERS: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_ORDERS: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response,
                orders: response.data
            };
        }
        case actions.FETCHING_FAILED_ORDERS: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        case actions.START_CREATE_ORDER: {
            return state;
        }
        case actions.START_FETCHING_SINGLE_ORDER: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.FETCH_SINGLE_ORDER_SUCCESSFUL: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.FETCH_SINGLE_ORDER_FAILED: {
            return {
                ...state,
                singleFetching: false,
                singleError: response
            };
        }
        case actions.UPDATE_SINGLE_ORDER_FAILED: {
            return {
                ...state,
                singleError: response
            };
        }
        case actions.BULK_ACTION_FAILED: {
            return {
                ...state,
                singleError: response
            };
        }
        case actions.STORE_CHECKED_ORDERS: {
            return {
                ...state,
                checked: response
            };
        }
        case actions.START_SYNC_HUBSPOT: {
            return {
                ...state,
                hubspotSyncing: true
            };
        }
        case actions.SYNC_HUBSPOT_SUCCESSFUL: {
            return {
                ...state,
                hubspotSyncing: false
            };
        }
        case actions.SYNC_HUBSPOT_FAILED: {
            return {
                ...state,
                hubspotSyncing: false
            };
        }

        case actions.START_BULK_ACTIONS_DELETE_ORDERS: {
            return {
                ...state,
                submittingBulkAction: true
            };
        }
        case actions.BULK_ACTIONS_DELETE_ORDERS_SUCCESS: {
            return {
                ...state,
                submittingBulkAction: false
            };
        }
        case actions.BULK_ACTIONS_DELETE_ORDERS_FAILED: {
            return {
                ...state,
                submittingBulkAction: false
            };
        }
        case actions.STORE_SINGLE_ORDER_DATA: {
            return Object.assign({}, state, {
                [id]: response.data
            });
        }
        case actions.START_FETCHING_SINGLE_ORDER_COMMENTS: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.FETCH_SINGLE_ORDER_COMMENTS_SUCCESSFUL: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.FETCH_SINGLE_ORDER_COMMENTS_FAILED: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.STORE_SINGLE_ORDER_COMMENTS_DATA: {
            return Object.assign({}, state, {
                comments: { [id]: response }
            });
        }

        default:
            return state;
    }
};
