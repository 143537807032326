import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import reduxFormErrorMapper from 'erputils/reduxFormErrorMapper';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import Form, { Select, Text } from 'erpcore/components/Form';
import valueValidation from 'erputils/valueValidation';
import Modal from 'erpcomponents/Modal';
import Button from 'erpcomponents/Button';
import { actions as eventsActions } from 'erpcore/screens/Events/Events.reducer';
import { actions as tableTemplatesActions } from 'erpcore/screens/Venues/screens/VenuesEdit/TableTemplates/TableTemplates.reducer';

const tableFilter = (tables, member) => {
    if (member && tables) {
        const id = member.match(/\d+/g)[0];
        const tableToRemove = tables[id] && tables[id];
        if (tableToRemove) {
            return tables
                .filter(table => table.id !== tableToRemove.id)
                .map(t => ({ value: t.iri, label: t.table_number }));
        }
        return tables;
    }
    return [];
};
const selectedTable = (tables, member) => {
    if (member && tables) {
        const id = member.match(/\d+/g)[0];
        const tableToRemove = tables[id] && tables[id];
        if (tableToRemove) {
            return tables.find(table => table.id === tableToRemove.id);
        }
        return tables;
    }
    return [];
};

class MergeTablesModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            opened: true
        };
        this.handleModal = this.handleModal.bind(this);
    }

    onSubmit = formData => {
        const { dispatch, match, fetchTables, allTables, member } = this.props;
        const primaryTable = selectedTable(allTables, member);
        const data = {
            primary_table: primaryTable && primaryTable.iri,
            table_to_merge: formData.table_to_merge
        };
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: tableTemplatesActions.START_MERGE_TABLES,
                formData: data
            });
        })
            .then(() => {
                // fetchEventData(match.params.id);
                fetchTables(match.params.id);
                this.handleModal();
            })
            .catch(error => {
                throw new SubmissionError(reduxFormErrorMapper(error));
            });
    };

    handleModal() {
        const { opened: isOpen } = this.state;
        this.setState({ opened: !isOpen });
    }

    render() {
        const { handleSubmit, submitting, pristine, invalid, allTables, member } = this.props;
        const { opened } = this.state;
        // const ticketTypes =
        //     initialValues.ticket_types &&
        //     initialValues.ticket_types
        //         .map(ticket => {
        //             return { label: ticket.name, value: ticket.iri };
        //         })
        //         .filter(t => t.value !== initialValues.primary_ticket_type.iri);

        return (
            <Modal
                variation="small"
                title="Merge Tables"
                opened={opened}
                onClose={() => this.handleModal()}
            >
                <p>You are about to merge Tables. </p>
                <Form onSubmit={handleSubmit(this.onSubmit)}>
                    <Form.Column>
                        <Form.Row>
                            <Text
                                input={{
                                    name: 'primary_table_name',
                                    value: selectedTable(allTables, member).table_number
                                }}
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="Events.MergeTables.PrimaryTable"
                                            defaultMessage="Primary Table"
                                        />
                                    ),
                                    clearable: true
                                }}
                                fieldAttr={{ id: 'primary_table_name', disabled: true }}
                            />
                            {/* <Field
                                name="primary_table_name"
                                id="primary_table_name"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="Events.MergeTables.PrimaryTable"
                                            defaultMessage="Primary Table"
                                        />
                                    ),
                                    clearable: true
                                }}
                                component={Text}
                                validate={valueValidation([{ validator: 'required' }])}
                                fieldAttr={{ disabled: true }}
                            /> */}
                        </Form.Row>
                        <Form.Row>
                            <Field
                                name="table_to_merge"
                                id="table_to_merge"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="Events.MergeTables.TableToMerge"
                                            defaultMessage="Table To Merge"
                                        />
                                    ),
                                    options: tableFilter(allTables, member)
                                }}
                                component={Select}
                                validate={valueValidation([{ validator: 'required' }])}
                            />
                        </Form.Row>
                        <Form.Columns>
                            <Form.Column autoWidth>
                                <Button
                                    submit
                                    disabled={pristine || invalid}
                                    loading={submitting}
                                    label={
                                        <FormattedMessage
                                            id="Events.MergeTT"
                                            defaultMessage="Merge"
                                        />
                                    }
                                />
                            </Form.Column>
                            <Form.Column autoWidth>
                                <Button
                                    onClick={() => this.handleModal()}
                                    variation="secondary"
                                    label={
                                        <FormattedMessage
                                            id="Events.Cancel"
                                            defaultMessage="Cancel"
                                        />
                                    }
                                />
                            </Form.Column>
                        </Form.Columns>
                    </Form.Column>
                </Form>
            </Modal>
        );
    }
}

MergeTablesModal.defaultProps = {
    submitting: false,
    pristine: false,
    invalid: false,
    fetchTables: () => {},
    member: '',
    allTables: null
};

MergeTablesModal.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    fetchTables: PropTypes.func,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.oneOfType([PropTypes.object]).isRequired,
    member: PropTypes.string,
    allTables: PropTypes.oneOfType([PropTypes.array])
};

MergeTablesModal = reduxForm({
    form: 'MergeTablesModalForm',
    enableReinitialize: true
})(MergeTablesModal);

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchEventData: () => {
        // Getting included data from API by setting params
        const params = {
            include: 'organization,location,location.country,location.state,location.city'
        };
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: eventsActions.START_FETCHING_SINGLE_EVENT,
                id: ownProps.match.params.id,
                params
            });
        }).catch(error => ({ error }));
    },
    fetchTables: eventId => {
        const params = {
            include: 'canBeGroupedWith'
        };

        return new Promise((resolve, reject) => {
            dispatch({
                type: tableTemplatesActions.START_FETCH_EVENT_TABLES,
                id: eventId,
                promise: { resolve, reject },
                params
            });
        });
    }
});

export default withRouter(
    connect(
        null,
        mapDispatchToProps
    )(MergeTablesModal)
);
