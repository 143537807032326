import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import Form from 'erpcore/components/Form';
import Button from 'erpcomponents/Button';
import { actions as listingActions } from 'erpcomponents/Listing/Listing.reducer';
import {
    getBulkActionsIris,
    getBulkActionsModalIsOpened
} from 'erpcomponents/Listing/Listing.selectors';
import { submittingBulkAction } from 'erpcore/screens/Orders/Orders.selectors';
import { actions as groupsActions } from 'erpcore/screens/Groups/Groups.reducer';

class DeleteGroups extends Component {
    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
        this.handleModal = this.handleModal.bind(this);
    }

    onSubmit(formData) {
        const { bulkActionsIris, dispatch } = this.props;
        const updatedIris = bulkActionsIris.filter(iri => iri !== formData.iris);
        const data = {
            routes: updatedIris
        };
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: groupsActions.START_BULK_ACTIONS_DELETE_GROUPS,
                formData: data
            })
        )
            .then(() => this.handleModal())
            .catch(error => {
                return error;
            });
    }

    handleModal() {
        const { dispatch, isOpen } = this.props;

        return dispatch({
            type: listingActions.TOGGLE_BULK_ACTIONS_MODAL,
            name: 'groups',
            response: !isOpen
        });
    }

    render() {
        const { handleSubmit, bulkActionsIris, submitting } = this.props;
        return (
            <Form onSubmit={handleSubmit(this.onSubmit)}>
                <p>
                    <FormattedMessage
                        id="Groups.BulkAction.Delete.Modal.Subtitle"
                        defaultMessage="Are you sure you want to delete {groups} Groups?"
                        values={{
                            groups: bulkActionsIris && bulkActionsIris.length
                        }}
                    />
                </p>
                <Button
                    submit
                    loading={submitting}
                    label={
                        <FormattedMessage
                            id="Groups.BulkAction.Delete.Modal.Confirm"
                            defaultMessage="Yes"
                        />
                    }
                />
                <Button
                    variation="secondary"
                    label={
                        <FormattedMessage
                            id="Groups.BulkAction.Delete.Modal.Cancel"
                            defaultMessage="No"
                        />
                    }
                    onClick={() => this.handleModal()}
                />
            </Form>
        );
    }
}

DeleteGroups.defaultProps = {
    submitting: false,
    dispatch: () => {},
    bulkActionsIris: [],
    isOpen: false
};

DeleteGroups.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    dispatch: PropTypes.func,
    bulkActionsIris: PropTypes.oneOfType([PropTypes.array]),
    isOpen: PropTypes.bool
};

DeleteGroups = reduxForm({
    form: 'DeleteGroupsForm',
    enableReinitialize: true
})(DeleteGroups);

const mapStateToProps = state => ({
    initialValues: { iris: getBulkActionsIris(state, 'groups') },
    bulkActionsIris: getBulkActionsIris(state, 'groups'),
    isOpen: getBulkActionsModalIsOpened(state, 'groups'),
    submitting: submittingBulkAction(state)
});

export default connect(mapStateToProps)(DeleteGroups);
