import React, { Component } from 'react';
import { actions as ordersActions } from 'erpcore/screens/Orders/Orders.reducer';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { getOrderData, getCheckedOrders, getOrders } from '../../Orders.selectors';

class TableCheckbox extends Component {
    state = {
        checked: false
    };

    onChange() {
        const {
            id,
            orderData,
            checkedOrders,
            orders,
            selectAll,
            isHeaderCheckbox,
            onSelectAll
        } = this.props;
        const { dispatch } = this.props;
        if (!isHeaderCheckbox) {
            const { checked } = this.state;
            this.setState({ checked: !checked }, () => {
                // send only changed data
                const updatedCheckedOrders = checkedOrders.find(order => order.id === orderData.id)
                    ? checkedOrders.filter(order => order.id !== orderData.id)
                    : [...checkedOrders, orderData];
                return new Promise((resolve, reject) =>
                    dispatch({
                        promise: { resolve, reject },
                        type: ordersActions.SINGLE_ORDER_CHECKED,
                        id,
                        formData: updatedCheckedOrders
                    })
                ).catch(error => {
                    return error;
                });
            });
        }
        return new Promise((resolve, reject) => {
            onSelectAll();
            dispatch({
                promise: { resolve, reject },
                type: ordersActions.CHECK_ALL_ORDERS,
                formData: !selectAll ? orders : []
            });
        }).catch(error => {
            return error;
        });
    }

    render() {
        const { checkedOrders, orderData } = this.props;
        return (
            <input
                type="checkbox"
                checked={checkedOrders.find(order => order.id === orderData.id) && true}
                onChange={() => this.onChange()}
            />
        );
    }
}
TableCheckbox.defaultProps = {
    orderData: {},
    checkedOrders: [],
    id: '',
    selectAll: false,
    orders: [],
    isHeaderCheckbox: false,
    onSelectAll: () => {}
};

TableCheckbox.propTypes = {
    dispatch: PropTypes.func.isRequired,
    id: PropTypes.string,
    orderData: PropTypes.oneOfType([PropTypes.object]),
    checkedOrders: PropTypes.oneOfType([PropTypes.array]),
    orders: PropTypes.oneOfType([PropTypes.array]),
    selectAll: PropTypes.bool,
    isHeaderCheckbox: PropTypes.bool,
    onSelectAll: PropTypes.func
};

const mapStateToProps = (state, ownProps) => ({
    orderData: getOrderData(state, ownProps.id),
    checkedOrders: getCheckedOrders(state),
    orders: getOrders(state)
});

export default withRouter(connect(mapStateToProps)(TableCheckbox));
