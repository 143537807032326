import { createSelector } from 'reselect';
import qs from 'qs';
import base64 from 'base-64';
import isBase64 from 'is-base64';

export const getUrlQueryParams = state => {
    const storedUrlQueryParams = state.routerManager.urlQueryParams;
    const urlParams = isBase64(storedUrlQueryParams.substr(1))
        ? storedUrlQueryParams.substr(1)
        : '';
    //  Decode it from base64 to object
    const base64DecodedUrlParams = base64.decode(urlParams);
    const paramsObject = qs.parse(base64DecodedUrlParams, {
        ignoreQueryPrefix: true
    });
    return paramsObject;
};

export const getListingUrlQueryParams = (state, listingName = 'listing1') => {
    const storedUrlQueryParams = state.routerManager.urlQueryParams;
    let urlParams = '';
    // if the first char in string is ? - remove it
    if (storedUrlQueryParams.charAt(0) === '?') {
        urlParams = isBase64(storedUrlQueryParams.substr(1)) ? storedUrlQueryParams.substr(1) : '';
    } else {
        urlParams = isBase64(storedUrlQueryParams) ? storedUrlQueryParams : '';
    }

    //  Decode it from base64 to object
    const base64DecodedUrlParams = base64.decode(urlParams);
    const paramsObject = qs.parse(base64DecodedUrlParams, {
        ignoreQueryPrefix: true
    });

    return paramsObject[listingName] || {};
};

export const getRedirect = state => state.routerManager.redirect;

export default createSelector([getUrlQueryParams, getListingUrlQueryParams, getRedirect]);
