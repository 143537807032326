import React from 'react';
import { Field, Fields } from 'redux-form';
import Form, { Text, Switch, Autocomplete } from 'erpcore/components/Form';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import valueValidation from 'erputils/valueValidation';
import { resolveObjectPathByString } from 'erputils/utils';
import { actions as resellerFilterActions } from 'erpcore/screens/Settings/screens/ResellerAdmin/ResellerFilter/ResellerFilter.reducer';

class ResellerFilterRepeaterItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pendingUpdates: {}
        };

        this.debounceDelay = 800;
    }

    dispatchUpdate = (updates, name) => {
        const {
            data: { value }
        } = this.props;
        const itemIndex = name.replace(/\D/g, '');
        value[itemIndex] = { ...value[itemIndex], ...updates };
        const { dispatch } = this.props;

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: resellerFilterActions.REQUEST_UPDATE_SINGLE_RESELLER_FILTER,
                formData: {
                    filter_sorts: value
                },
                debounceDelay: this.debounceDelay
            })
        )
            .catch(() => {
                this.removePendingUpdates();
            })
            .then(() => {
                this.removePendingUpdates();
            });
    };

    handleChange = (event, newValue, previousValue, name) => {
        const { member, isNewRepeaterItem } = this.props;
        const nameForApi = name.replace(`${member}.`, '');
        const updates = { [nameForApi]: newValue };
        const isFilterOnly =
            Object.keys(updates).length === 1 && Object.keys(updates)[0] === 'filter_name';
        const { pendingUpdates } = this.state;
        const mergedUpdates = { ...pendingUpdates, ...updates };
        this.setState({ pendingUpdates: mergedUpdates });
        if (!isNewRepeaterItem && !isFilterOnly) {
            this.dispatchUpdate(mergedUpdates, name);
        }
    };

    renderFilterItem = fields => {
        const { member } = this.props;
        const filterName = resolveObjectPathByString(fields, member).filter_name.input.value;
        if (filterName === 'event_categories') {
            return (
                <Field
                    name={`${member}.filter_value`}
                    id="filter_value"
                    // parse={parseInteger}
                    fieldProps={{
                        label: 'Filter item',
                        options: {
                            endpoint: 'api/event-categories?pagination=false',

                            mapData: {
                                label: 'name',
                                value: 'iri'
                            }
                        }
                    }}
                    component={Autocomplete}
                    onChange={this.handleChange}
                />
            );
        }
        return (
            <Field
                name={`${member}.filter_value`}
                id="filter_value"
                // parse={parseInteger}
                fieldProps={{
                    label: 'Filter item',
                    options: {
                        endpoint: 'api/event-series?pagination=false',

                        mapData: {
                            label: 'name',
                            value: 'iri'
                        }
                    }
                }}
                component={Autocomplete}
                onChange={this.handleChange}
            />
        );
    };

    removePendingUpdates() {
        this.setState({ pendingUpdates: {} });
    }

    isValid(validatorFunctions = [], value) {
        const errors = validatorFunctions.map(validatorFunction => {
            return validatorFunction(value);
        });
        const isNotValid = errors.find(item => item !== undefined);

        return !isNotValid;
    }

    render() {
        const { member } = this.props;
        return (
            <Form.Row>
                <Form.Columns breakOnMobile>
                    <Form.Column>
                        <Field
                            name={`${member}.label`}
                            id="label"
                            fieldProps={{
                                label: 'Filter Label'
                            }}
                            fieldAttr={{ required: true }}
                            component={Text}
                            validate={valueValidation([{ validator: 'required' }])}
                            onChange={(event, newValue, previousValue, name) => {
                                /*
                                 * redux-form validation is started after the Field onChange is done,
                                 * so the validity of the field has to be checked manually
                                 * before handling the change data further
                                 */
                                if (
                                    this.isValid(
                                        valueValidation([{ validator: 'required' }]),
                                        newValue
                                    )
                                ) {
                                    this.handleChange(event, newValue, previousValue, name);
                                }
                            }}
                        />
                    </Form.Column>
                    <Form.Column>
                        <Field
                            name={`${member}.filter_name`}
                            id="filter_name"
                            // parse={parseInteger}

                            fieldProps={{
                                on: {
                                    label: 'Event Categories',
                                    value: 'event_categories',
                                    id: 'switch-on-true'
                                },
                                off: {
                                    label: 'Event Series',
                                    value: 'event_series',
                                    id: 'switch-off-false'
                                },
                                type: 'dual'
                            }}
                            component={Switch}
                            onChange={this.handleChange}
                        />
                    </Form.Column>
                    <Form.Column>
                        <Fields
                            names={[`${member}.filter_name`, `${member}.filter_value`]}
                            component={this.renderFilterItem}
                        />
                    </Form.Column>
                </Form.Columns>
            </Form.Row>
        );
    }
}

ResellerFilterRepeaterItem.defaultProps = {
    member: null,
    data: {},
    isNewRepeaterItem: false
};

ResellerFilterRepeaterItem.propTypes = {
    dispatch: PropTypes.func.isRequired,
    member: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    data: PropTypes.oneOfType([PropTypes.object]),
    isNewRepeaterItem: PropTypes.bool
};

export default connect()(ResellerFilterRepeaterItem);
