export const actions = {
    START_FETCHING_TABLE_TEMPLATES: 'START_FETCHING_TABLE_TEMPLATES',
    FETCHING_SUCCESSFUL_TABLE_TEMPLATES: 'FETCHING_SUCCESSFUL_TABLE_TEMPLATES',
    FETCHING_FAILED_TABLE_TEMPLATES: 'FETCHING_FAILED_TABLE_TEMPLATES',
    START_CREATE_TABLE_TEMPLATE: 'START_CREATE_TABLE_TEMPLATE',
    CREATE_TABLE_TEMPLATE_SUCCESSFUL: 'CREATE_TABLE_TEMPLATE_SUCCESSFUL',
    CREATE_TABLE_TEMPLATE_FAILED: 'CREATE_TABLE_TEMPLATE_FAILED',
    START_FETCHING_SINGLE_TABLE_TEMPLATE: 'START_FETCHING_SINGLE_TABLE_TEMPLATE',
    FETCH_SINGLE_TABLE_TEMPLATE_SUCCESSFUL: 'FETCH_SINGLE_TABLE_TEMPLATE_SUCCESSFUL',
    FETCH_SINGLE_TABLE_TEMPLATE_FAILED: 'FETCH_SINGLE_TABLE_TEMPLATE_FAILED',
    START_UPDATE_SINGLE_TABLE_TEMPLATE: 'START_UPDATE_SINGLE_TABLE_TEMPLATE',
    UPDATE_SINGLE_TABLE_TEMPLATE_SUCCESSFUL: 'UPDATE_SINGLE_TABLE_TEMPLATE_SUCCESSFUL',
    UPDATE_SINGLE_TABLE_TEMPLATE_FAILED: 'UPDATE_SINGLE_TABLE_TEMPLATE_FAILED',
    START_DELETE_SINGLE_TABLE_TEMPLATE: 'START_DELETE_SINGLE_TABLE_TEMPLATE',
    DELETE_SINGLE_TABLE_TEMPLATE_SUCCESSFUL: 'DELETE_SINGLE_TABLE_TEMPLATE_SUCCESSFUL',
    DELETE_SINGLE_TABLE_TEMPLATE_FAILED: 'DELETE_SINGLE_TABLE_TEMPLATE_FAILED',
    STORE_SINGLE_TABLE_TEMPLATE_DATA: 'STORE_SINGLE_TABLE_TEMPLATE_DATA',
    STORE_DUPLICATED_TABLE_TEMPLATE_DATA: 'STORE_DUPLICATED_TABLE_TEMPLATE_DATA',
    REQUEST_UPDATE_SINGLE_TABLE: 'REQUEST_UPDATE_SINGLE_TABLE',
    START_CREATE_TABLE: 'START_CREATE_TABLE',
    CREATE_TABLE_SUCCESSFUL: 'CREATE_TABLE_SUCCESSFUL',
    CREATE_TABLE_FAILED: 'CREATE_TABLE_FAILED',
    START_UPDATE_TABLE: 'START_UPDATE_TABLE',
    UPDATE_TABLE_SUCCESSFUL: 'UPDATE_TABLE_SUCCESSFUL',
    UPDATE_TABLE_FAILED: 'UPDATE_TABLE_FAILED',
    START_DELETE_TABLE: 'START_DELETE_TABLE',
    DELETE_TABLE_SUCCESSFUL: 'DELETE_TABLE_SUCCESSFUL',
    DELETE_TABLE_FAILED: 'DELETE_TABLE_FAILED',
    START_FETCHING_TABLE_TEMPLATE_TABLES: 'START_FETCHING_TABLE_TEMPLATE_TABLES',
    FETCHING_TABLE_TEMPLATE_TABLES_SUCCESSFUL: 'FETCHING_TABLE_TEMPLATE_TABLES_SUCCESSFUL',
    FETCHING_TABLE_TEMPLATE_TABLES_FAILED: 'FETCHING_TABLE_TEMPLATE_TABLES_FAILED',
    START_DUPLICATE_SINGLE_TABLE_TEMPLATE: 'START_DUPLICATE_SINGLE_TABLE_TEMPLATE',
    DUPLICATE_SINGLE_TABLE_TEMPLATE_SUCCESSFUL: 'DUPLICATE_SINGLE_TABLE_TEMPLATE_SUCCESSFUL',
    DUPLICATE_SINGLE_TABLE_TEMPLATE_FAILED: 'DUPLICATE_SINGLE_TABLE_TEMPLATE_FAILED',
    RESET_UPDATED_ITEM: 'RESET_UPDATED_ITEM',
    STORE_CREATED_TABLE_TEMPLATE: 'STORE_CREATED_TABLE_TEMPLATE',
    START_MERGE_TABLES: 'START_MERGE_TABLES',
    MERGE_TABLES_SUCCESSFUL: 'MERGE_TABLES_SUCCESSFUL',
    MERGE_TABLES_FAILED: 'MERGE_TABLES_FAILED',
    START_FETCH_EVENT_TABLES: 'START_FETCH_EVENT_TABLES',
    FETCH_EVENT_TABLES_SUCCESSFUL: 'FETCH_EVENT_TABLES_SUCCESSFUL',
    FETCH_EVENT_TABLES_FAILED: 'FETCH_EVENT_TABLES_FAILED',
    START_FETCHING_SINGLE_TABLE_TEMPLATE_FOR_EVENT:
        'START_FETCHING_SINGLE_TABLE_TEMPLATE_FOR_EVENT',
    FETCH_SINGLE_TABLE_TEMPLATE_FOR_EVENT_SUCCESSFUL:
        'FETCH_SINGLE_TABLE_TEMPLATE_FOR_EVENT_SUCCESSFUL',
    FETCH_SINGLE_TABLE_TEMPLATE_FOR_EVENT_FAILED: 'FETCH_SINGLE_TABLE_TEMPLATE_FOR_EVENT_FAILED'
};

export const initialState = {
    listingFetching: false,
    listingResponse: {
        data: [],
        meta: {},
        included: []
    },
    listingError: [],
    singleFetching: false,
    singleError: null,
    deleteSingleError: null,
    tableTemplates: [],
    newTable: [],
    createdTable: {},
    tableTemplateTables: [],
    mergingTables: false,
    eventTables: []
};

export default (state = initialState, { type, id, response }) => {
    switch (type) {
        case actions.START_FETCHING_TABLE_TEMPLATES: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_TABLE_TEMPLATES: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response,
                tableTemplates: response
            };
        }
        case actions.FETCHING_FAILED_TABLE_TEMPLATES: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        case actions.START_FETCHING_SINGLE_TABLE_TEMPLATE: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.FETCH_SINGLE_TABLE_TEMPLATE_SUCCESSFUL: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.FETCH_SINGLE_TABLE_TEMPLATE_FAILED: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.START_FETCHING_SINGLE_TABLE_TEMPLATE_FOR_EVENT: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.FETCH_SINGLE_TABLE_TEMPLATE_FOR_EVENT_SUCCESSFUL: {
            return {
                ...state,
                singleFetching: false,
                eventTableTemplates: { tables: response.data },
                eventTables: response.data
            };
        }
        case actions.FETCH_SINGLE_TABLE_TEMPLATE_FOR_EVENT_FAILED: {
            return {
                ...state,
                singleFetching: false
            };
        }

        case actions.START_DUPLICATE_SINGLE_TABLE_TEMPLATE: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.DUPLICATE_SINGLE_TABLE_TEMPLATE_SUCCESSFUL: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.DUPLICATE_SINGLE_TABLE_TEMPLATE_FAILED: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.RESET_UPDATED_ITEM: {
            return {
                ...state,
                tableTemplate: {}
            };
        }
        case actions.CREATE_TABLE_TEMPLATE_FAILED: {
            return {
                ...state,
                singleError: response,
                newTable: []
            };
        }
        case actions.CREATE_TABLE_TEMPLATE_SUCCESSFUL: {
            return {
                ...state,
                newTable: []
            };
        }
        case actions.UPDATE_SINGLE_TABLE_TEMPLATE_FAILED: {
            return {
                ...state,
                singleError: response,
                newTable: []
            };
        }
        case actions.UPDATE_SINGLE_TABLE_TEMPLATE_SUCCESSFUL: {
            return {
                ...state,
                newTable: []
            };
        }
        case actions.CREATE_TABLE_SUCCESSFUL: {
            return {
                ...state,
                newTable: [...state.newTable, response.data.id]
            };
        }
        case actions.UPDATE_TABLE_FAILED: {
            return {
                ...state,
                singleError: response
            };
        }
        case actions.DELETE_SINGLE_TABLE_TEMPLATE_FAILED: {
            return {
                ...state,
                deleteSingleError: response
            };
        }
        case actions.DELETE_TABLE_FAILED: {
            return {
                ...state,
                deleteSingleError: response
            };
        }
        case actions.START_MERGE_TABLES: {
            return {
                ...state,
                mergingTables: true
            };
        }
        case actions.MERGE_TABLES_SUCCESSFUL: {
            return {
                ...state,
                mergingTables: false
            };
        }
        case actions.MERGE_TABLES_FAILED: {
            return {
                ...state,
                mergingTables: false
            };
        }
        case actions.START_FETCH_EVENT_TABLES: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.FETCH_EVENT_TABLES_SUCCESSFUL: {
            return {
                ...state,
                singleFetching: false,
                eventTables: response.data
            };
        }
        case actions.FETCH_EVENT_TABLES_FAILED: {
            return {
                ...state,
                singleFetching: false
            };
        }

        case actions.STORE_SINGLE_TABLE_TEMPLATE_DATA: {
            return Object.assign({}, state, {
                [id]: response,
                tableTemplate: response
            });
        }
        case actions.STORE_DUPLICATED_TABLE_TEMPLATE_DATA: {
            return Object.assign({}, state, {
                [id]: response,
                duplicatedTableTemplate: response
            });
        }
        case actions.STORE_CREATED_TABLE_TEMPLATE: {
            return {
                ...state,
                createdTableTemplate: response
            };
        }
        case actions.START_FETCHING_TABLE_TEMPLATE_TABLES: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.FETCHING_TABLE_TEMPLATE_TABLES_SUCCESSFUL: {
            return {
                ...state,
                singleFetching: false,
                tableTemplateTables: response.data
            };
        }

        case actions.FETCHING_TABLE_TEMPLATE_TABLES_FAILED: {
            return {
                ...state,
                singleFetching: false,
                tableTemplateTables: response.data
            };
        }

        default:
            return state;
    }
};
