import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import reduxFormErrorMapper from 'erputils/reduxFormErrorMapper';
import { Field, reduxForm, SubmissionError, reset } from 'redux-form';
import Form, { Text, Email, Phone, Location } from 'erpcore/components/Form';
import valueValidation from 'erputils/valueValidation';
import LayoutManager from 'erputils/LayoutManager';
import PageHeader from 'erpcomponents/Layout/PageHeader';
import PageContent from 'erpcomponents/Layout/PageContent';
import Button from 'erpcomponents/Button';
import { actions as companiesActions } from '../../Companies.reducer';

let CompanyCreate = ({ handleSubmit, submitting, pristine, invalid, onSubmit }) => {
    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <PageHeader
                title={<FormattedMessage id="CompanyCreate.title" defaultMessage="New Company" />}
                backButtonLink="/companies"
            />
            <PageContent>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Columns breakOnMobile>
                        <Form.Column>
                            <Form.Row>
                                <Field
                                    name="name"
                                    id="name"
                                    fieldProps={{
                                        label: (
                                            <FormattedMessage
                                                id="CompanyCreate.Name"
                                                defaultMessage="Company Name"
                                            />
                                        ),
                                        clearable: true
                                    }}
                                    component={Text}
                                    validate={valueValidation([{ validator: 'required' }])}
                                    fieldAttr={{ required: true }}
                                />
                            </Form.Row>
                            <Form.Row>
                                <Field name="location" id="location" component={Location} />
                            </Form.Row>

                            <Form.Row>
                                <Field
                                    name="phone_number"
                                    id="phone_number"
                                    fieldProps={{
                                        label: (
                                            <FormattedMessage
                                                id="CompanyCreate.Phone"
                                                defaultMessage="Primary Contact Phone"
                                            />
                                        )
                                    }}
                                    component={Phone}
                                />
                            </Form.Row>
                            <Form.Row>
                                <Field
                                    name="email"
                                    id="email"
                                    fieldProps={{
                                        label: (
                                            <FormattedMessage
                                                id="CompanyCreate.Email"
                                                defaultMessage="Primary Contact Email"
                                            />
                                        ),
                                        clearable: true
                                    }}
                                    component={Email}
                                />
                            </Form.Row>
                            <Form.Row>
                                <Field
                                    name="website"
                                    id="website"
                                    fieldProps={{
                                        label: (
                                            <FormattedMessage
                                                id="CompanyCreate.Website"
                                                defaultMessage="Website"
                                            />
                                        ),
                                        clearable: true
                                    }}
                                    component={Text}
                                />
                            </Form.Row>
                        </Form.Column>
                    </Form.Columns>
                    <Form.Row>
                        <Button
                            submit
                            disabled={pristine || invalid}
                            loading={submitting}
                            label={<FormattedMessage id="CompanyCreate.Add" defaultMessage="Add" />}
                        />
                    </Form.Row>
                </Form>
            </PageContent>
        </LayoutManager>
    );
};

CompanyCreate.defaultProps = {
    submitting: false,
    pristine: false,
    invalid: false,
    onSubmit: () => {}
};

CompanyCreate.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool
};

CompanyCreate = reduxForm({
    form: 'CompanyCreateForm',
    enableReinitialize: true
})(CompanyCreate);

const mapDispatchToProps = dispatch => ({
    onSubmit: formData => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: companiesActions.START_CREATE_COMPANY,
                formData
            });
        })
            .then(() => {
                dispatch(reset('CompanyCreateForm'));
            })
            .catch(error => {
                throw new SubmissionError(reduxFormErrorMapper(error));
            });
    }
});

export default connect(
    null,
    mapDispatchToProps
)(CompanyCreate);
