/**
 * Prepares data from API for redux form
 * @param {Object} error Error response from API
 * @return {Object} Returns error object suited fror redux forms
 */
const reduxFormErrorMapper = error => {
    const reduxError = {
        _error: error
    };
    if ('errors' in error) {
        error.errors.map(item => {
            reduxError[item.name] = item;
            return true;
        });
    }
    return reduxError;
};

export default reduxFormErrorMapper;
