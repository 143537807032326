import { createSelector } from 'reselect';

export const getActivityLogFetching = state =>
    state && state.activityLog && state.activityLog.fetching;

export const getActivityLogData = (state, entity, id) => {
    return state.activityLog && state.activityLog[entity] && state.activityLog[entity][id];
};

export default createSelector([getActivityLogFetching, getActivityLogData]);
