import React from 'react';
import { Field } from 'redux-form';
import Form, { Text } from 'erpcore/components/Form';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
import valueValidation from 'erputils/valueValidation';
import { actions as eventsActions } from 'erpcore/screens/Events/Events.reducer';

class RecommendationRepeaterItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pendingUpdates: {}
        };

        this.handleChange = debounce(this.handleChange, 1000);
    }

    dispatchUpdate = updates => {
        const {
            member,
            match,
            dispatch,
            data: { why_we_recommend: whyWeRecommend }
        } = this.props;
        const itemIndex = member.replace(/\D/g, '');
        whyWeRecommend[itemIndex] = { ...updates };
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: eventsActions.START_UPDATE_SINGLE_EVENT,
                id: match.params.id,
                formData: { why_we_recommend: { ...whyWeRecommend } }
            })
        )
            .catch(() => {
                this.removePendingUpdates();
            })
            .then(() => {
                this.removePendingUpdates();
            });
    };

    handleChange = (event, newValue, previousValue, name) => {
        const { member, isNewRepeaterItem } = this.props;

        if (!isNewRepeaterItem) {
            const nameForApi = name.replace(`${member}.`, '');
            const updates = { [nameForApi]: newValue };
            const { pendingUpdates } = this.state;
            const mergedUpdates = { ...pendingUpdates, ...updates };
            this.setState({ pendingUpdates: mergedUpdates });
            this.dispatchUpdate(mergedUpdates, name);
        }
    };

    removePendingUpdates() {
        this.setState({ pendingUpdates: {} });
    }

    isValid(validatorFunctions = [], value) {
        const errors = validatorFunctions.map(validatorFunction => {
            return validatorFunction(value);
        });
        const isNotValid = errors.find(item => item !== undefined);

        return !isNotValid;
    }

    render() {
        const { member } = this.props;
        return (
            <Form.Row>
                <Form.Columns breakOnMobile>
                    <Form.Column>
                        <Field
                            name={`${member}.value`}
                            id={`${member}.value`}
                            fieldProps={{
                                label: 'Why we recommend?'
                            }}
                            fieldAttr={{ required: true }}
                            component={Text}
                            validate={valueValidation([{ validator: 'required' }])}
                            onChange={(event, newValue, previousValue, name) => {
                                /*
                                 * redux-form validation is started after the Field onChange is done,
                                 * so the validity of the field has to be checked manually
                                 * before handling the change data further
                                 */
                                if (
                                    this.isValid(
                                        valueValidation([{ validator: 'required' }]),
                                        newValue
                                    )
                                ) {
                                    this.handleChange(event, newValue, previousValue, name);
                                }
                            }}
                        />
                    </Form.Column>
                </Form.Columns>
            </Form.Row>
        );
    }
}

RecommendationRepeaterItem.defaultProps = {
    member: null,
    data: {},
    isNewRepeaterItem: false,
    match: null
};

RecommendationRepeaterItem.propTypes = {
    dispatch: PropTypes.func.isRequired,
    member: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    data: PropTypes.oneOfType([PropTypes.object]),
    isNewRepeaterItem: PropTypes.bool,
    match: PropTypes.oneOfType([PropTypes.number])
};

export default withRouter(connect()(RecommendationRepeaterItem));
