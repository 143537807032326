import { createSelector } from 'reselect';

export const getData = (state, id) =>
    state.widgets &&
    state.widgets.totalSalesByTicketType &&
    state.widgets.totalSalesByTicketType[id] &&
    state.widgets.totalSalesByTicketType[id].data;

export const getFetching = (state, id) =>
    state.widgets &&
    state.widgets.totalSalesByTicketType &&
    state.widgets.totalSalesByTicketType[id] &&
    state.widgets.totalSalesByTicketType[id].fetching;

export default createSelector([getData, getFetching]);
