import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import valueValidation from 'erputils/valueValidation';
import { diff } from 'deep-object-diff';
import { FormattedMessage } from 'react-intl';
import reduxFormErrorMapper from 'erputils/reduxFormErrorMapper';
import Form, { Text, Email, Phone, Location } from 'erpcore/components/Form';
import LayoutManager from 'erputils/LayoutManager';
import PageHeader from 'erpcomponents/Layout/PageHeader';
import PageContent from 'erpcomponents/Layout/PageContent';
import Button from 'erpcomponents/Button';
import PageLoader from 'erpcomponents/PageLoader';
import { actions as companiesActions } from 'erpcore/screens/Companies/Companies.reducer';
import {
    getCompaniesFormData,
    getSingleCompanyFetching
} from 'erpcore/screens/Companies/Companies.selectors';

class CompanyEdit extends Component {
    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        const { fetchCompanyData } = this.props;
        fetchCompanyData();
    }

    onSubmit(formData) {
        const { match, dispatch, initialValues } = this.props;
        formData = diff(initialValues, formData);
        if (formData.address) {
            formData.address = formData.address.fullAddress;
        }

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: companiesActions.START_UPDATE_SINGLE_COMPANY,
                id: match.params.id,
                formData
            })
        ).catch(error => {
            throw new SubmissionError(reduxFormErrorMapper(error));
        });
    }

    render() {
        const { handleSubmit, submitting, pristine, invalid, singleFetching } = this.props;

        return (
            <LayoutManager slot="main" className="main--narrow" layoutType="merge">
                <PageHeader
                    title={
                        <FormattedMessage id="CompanyEdit.title" defaultMessage="Edit Company" />
                    }
                    backButtonLink="/companies"
                />
                <PageContent>
                    {singleFetching === true && <PageLoader />}

                    <Form onSubmit={handleSubmit(this.onSubmit)}>
                        <Form.Row>
                            <Field
                                name="name"
                                id="name"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="CompanyEdit.companyName"
                                            defaultMessage="Company Name"
                                        />
                                    )
                                }}
                                component={Text}
                                validate={valueValidation([{ validator: 'required' }])}
                                fieldAttr={{ required: true }}
                            />
                        </Form.Row>
                        <Form.Row>
                            <Field
                                name="address"
                                id="address"
                                component={Location}
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="CompanyEdit.address"
                                            defaultMessage="Address"
                                        />
                                    )
                                }}
                            />
                        </Form.Row>

                        <Form.Row>
                            <Field
                                name="phone_number"
                                id="phone_number"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="CompanyEdit.Phone"
                                            defaultMessage="Primary Contact Phone"
                                        />
                                    )
                                }}
                                component={Phone}
                            />
                        </Form.Row>
                        <Form.Row>
                            <Field
                                name="email"
                                id="email"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="CompanyEdit.Email"
                                            defaultMessage="Primary Contact Email"
                                        />
                                    ),
                                    clearable: true
                                }}
                                component={Email}
                            />
                        </Form.Row>
                        <Form.Row>
                            <Field
                                name="website"
                                id="website"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="CompanyEdit.Website"
                                            defaultMessage="Website"
                                        />
                                    ),
                                    clearable: true
                                }}
                                component={Text}
                            />
                        </Form.Row>
                        <Form.Row>
                            <Button
                                submit
                                disabled={pristine || invalid}
                                loading={submitting}
                                label={
                                    <FormattedMessage
                                        id="CompanyEdit.Update"
                                        defaultMessage="Update"
                                    />
                                }
                            />
                        </Form.Row>
                    </Form>
                </PageContent>
            </LayoutManager>
        );
    }
}

CompanyEdit.defaultProps = {
    submitting: false,
    pristine: false,
    invalid: false,
    fetchCompanyData: () => {},
    singleFetching: false,
    initialValues: {}
};

CompanyEdit.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.oneOfType([PropTypes.object]).isRequired,
    initialValues: PropTypes.oneOfType([PropTypes.object]),
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    fetchCompanyData: PropTypes.func,
    singleFetching: PropTypes.bool
};

CompanyEdit = reduxForm({
    form: 'CompanyEditForm',
    enableReinitialize: false
})(CompanyEdit);

//  Getting initial value populated in the form from the store
const mapStateToProps = (state, ownProps) => ({
    initialValues: getCompaniesFormData(state, ownProps.match.params.id),
    singleFetching: getSingleCompanyFetching(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchCompanyData: () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: companiesActions.START_FETCHING_SINGLE_COMPANY,
                id: ownProps.match.params.id
            });
        }).catch(error => ({ error }));
    }
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(CompanyEdit)
);
