export const actions = {
    START_FETCHING_TICKETZONE_EVENT_ID: 'START_FETCHING_TICKETZONE_EVENT_ID',
    FETCHING_TICKETZONE_EVENT_ID_SUCCESSFUL: 'FETCHING_TICKETZONE_EVENT_ID_SUCESSFUL',
    FETCHING_TICKETZONE_EVENT_ID_FAILED: 'FETCHING_TICKETZONE_EVENT_ID_FAILED',

    START_FETCHING_DASHBOARD_EVENT_SERIES: 'START_FETCHING_DASHBOARD_EVENT_SERIES',
    FETCHING_DASHBOARD_EVENT_SERIES_SUCCESSFUL: 'FETCHING_DASHBOARD_EVENT_SERIES_SUCCESSFUL',
    FETCHING_DASHBOARD_EVENT_SERIES_FAILED: 'FETCHING_DASHBOARD_EVENT_SERIES_FAILED',

    START_FETCHING_TICKET_TYPE_DATA: 'START_FETCHING_TICKET_TYPE_DATA',
    FETCHING_TICKET_TYPE_DATA_SUCCESSFUL: 'FETCHING_TICKET_TYPE_DATA_SUCCESSFUL',
    FETCHING_TICKET_TYPE_DATA_FAILED: 'FETCHING_TICKET_TYPE_DATA_FAILED',
    STORE_TICKET_TYPE_DATA: 'STORE_TICKET_TYPES_DATA'
};

export const initialState = {
    ticketzoneEventID: null
};

export default (state = initialState, { type, id, response, iri }) => {
    switch (type) {
        case actions.FETCHING_TICKETZONE_EVENT_ID_SUCCESSFUL: {
            return {
                ...state,
                ticketzoneEventID: id
            };
        }
        case actions.START_FETCHING_DASHBOARD_EVENT_SERIES: {
            return {
                ...state,
                eventSeriesFetching: true
            };
        }
        case actions.FETCHING_DASHBOARD_EVENT_SERIES_SUCCESSFUL: {
            return {
                ...state,
                eventSeriesFetching: false,
                eventSeries: response
            };
        }
        case actions.FETCHING_DASHBOARD_EVENT_SERIES_FAILED: {
            return {
                ...state,
                eventSeriesFetching: false,
                eventSeries: response
            };
        }
        case actions.STORE_TICKET_TYPE_DATA: {
            return {
                ...state,
                [iri]: response
            };
        }
        default:
            return state;
    }
};
