import { createSelector } from 'reselect';

export const getVenueData = (state, id) => state.venues && state.venues[id];

export const getVenueName = state =>
    state.venues.selectedVenue &&
    state.venues.selectedVenue.data &&
    state.venues.selectedVenue.data.venue_name;

export const getVenueFormData = (state, id) =>
    state.venues && state.venues[id] && state.venues[id].formData && state.venues[id].formData;

export const getSingleVenueFetching = state => state.venues.singleFetching;

export const getTableTemplateData = (state, id) =>
    state.venues[id] && state.venues[id].data && state.venues[id].data;

export const getSingleTableTemplateFetching = state => state.venues.singleFetching;

export default createSelector([
    getVenueData,
    getVenueFormData,
    getSingleVenueFetching,
    getTableTemplateData,
    getSingleTableTemplateFetching,
    getVenueName
]);
