export const actions = {
    SET_URL_QUERY_PARAMS: 'SET_URL_QUERY_PARAMS',
    SET_REDIRECT_URL: 'SET_REDIRECT_URL',
    REMOVE_REDIRECT_URL: 'REMOVE_REDIRECT_URL'
};

export const initialState = {
    urlQueryParams: '',
    redirect: null
};

export default (state = initialState, { type, response }) => {
    switch (type) {
        case actions.SET_URL_QUERY_PARAMS: {
            return {
                ...state,
                urlQueryParams: response
            };
        }
        case actions.SET_REDIRECT_URL: {
            return {
                ...state,
                redirect: response
            };
        }
        case actions.REMOVE_REDIRECT_URL: {
            return {
                ...state,
                redirect: null
            };
        }
        default:
            return state;
    }
};
