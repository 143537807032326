export const actions = {
    START_FETCHING_TICKET_TYPES_DATA: 'START_FETCHING_TICKET_TYPES_DATA',
    FETCHING_TICKET_TYPES_DATA_SUCCESSFUL: 'FETCHING_TICKET_TYPES_DATA_SUCCESSFUL',
    FETCHING_TICKET_TYPES_DATA_FAILED: 'FETCHING_TICKET_TYPES_DATA_FAILED',
    STORE_TICKET_TYPES_DATA: 'STORE_TICKET_TYPES_DATA'
};

export const initialState = {};

export default (state = initialState, { type, widgetId, response }) => {
    switch (type) {
        case actions.START_FETCHING_TICKET_TYPES_DATA: {
            return {
                ...state,
                [widgetId]: { ...state[widgetId], ...{ fetching: true } }
            };
        }
        case actions.FETCHING_TICKET_TYPES_DATA_SUCCESSFUL: {
            return {
                ...state,
                [widgetId]: { ...state[widgetId] }
            };
        }
        case actions.FETCHING_TICKET_TYPES_DATA_FAILED: {
            return {
                ...state,
                [widgetId]: { ...state[widgetId], ...{ fetching: false } }
            };
        }
        case actions.STORE_TICKET_TYPES_DATA: {
            return {
                ...state,
                [widgetId]: { ...state[widgetId], ...{ data: response }, ...{ fetching: false } }
            };
        }
        default:
            return state;
    }
};
