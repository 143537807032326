import { createSelector } from 'reselect';

export const ticketSocketApiSingleFetching = state =>
    state.ticketSocketApi && state.ticketSocketApi.singleFetching;

export const getTicketSocketInitialData = state => {
    if (state.ticketSocketApi) {
        return {
            createNewData:
                state.ticketSocketApi.createNewData &&
                (state.ticketSocketApi.createNewData.value === 'true' ||
                    state.ticketSocketApi.createNewData.value === '1')
                    ? // eslint-disable-next-line no-unneeded-ternary
                      true
                    : false,
            automatedSync:
                state.ticketSocketApi.automatedSync &&
                (state.ticketSocketApi.automatedSync.value === 'true' ||
                    state.ticketSocketApi.automatedSync.value === '1')
                    ? // eslint-disable-next-line no-unneeded-ternary
                      true
                    : false,
            sinceLastImport:
                state.ticketSocketApi.sinceLastImport &&
                (state.ticketSocketApi.sinceLastImport.value === 'true' ||
                    state.ticketSocketApi.sinceLastImport.value === '1')
                    ? // eslint-disable-next-line no-unneeded-ternary
                      true
                    : false,
            importInterval:
                state.ticketSocketApi.importInterval && state.ticketSocketApi.importInterval.value,
            importFrom: state.ticketSocketApi.importFrom && state.ticketSocketApi.importFrom.value,
            importOnlyEvents:
                state.ticketSocketApi.importOnlyEvents &&
                (state.ticketSocketApi.importOnlyEvents.value === 'true' ||
                    state.ticketSocketApi.importOnlyEvents.value === '1')
                    ? // eslint-disable-next-line no-unneeded-ternary
                      true
                    : false
        };
    }
    return null;
};

export default createSelector([ticketSocketApiSingleFetching, getTicketSocketInitialData]);
