import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import Form, { Text, Autocomplete, MultiAutocomplete, Switch } from 'erpcore/components/Form';
import valueValidation from 'erputils/valueValidation';
import { dtoForm } from 'erputils/dto';
import { diff } from 'deep-object-diff';
import Modal from 'erpcomponents/Modal';
import Svg from 'erpcomponents/Svg';
import Tooltip from 'erpcomponents/Tooltip';
import Button from 'erpcomponents/Button';
import { actions as groupsActions } from 'erpcore/screens/Groups/Groups.reducer';
import {
    getSingleGroupData,
    getSingleGroupFetching
} from 'erpcore/screens/Groups/Groups.selectors';
import PageLoader from 'erpcomponents/PageLoader';

const tablesDto = data => {
    if (data && data.tables) {
        const [table] = data.tables;
        data.tables = table;
    }

    return data;
};
class ManageGroupModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            opened: false
        };

        this.handleModal = this.handleModal.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        const { fetchGroupData } = this.props;
        const { opened } = this.state;
        if (prevState.opened !== opened) {
            fetchGroupData();
        }
    }

    onSubmit(formData) {
        const { id, dispatch, initialValues, refreshData } = this.props;
        // send only changed data
        formData = diff(initialValues, formData);
        if (formData.group_size) {
            formData.group_size = parseInt(formData.group_size, 10);
        }

        if (formData.tables) {
            formData.tables = [formData.tables];
        }

        if (!formData.tables) {
            formData.tables = [];
        }

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: groupsActions.START_UPDATE_SINGLE_GROUP,
                id,
                formData
            })
        )
            .then(() => {
                this.handleModal();
                refreshData();
            })
            .catch(error => {
                return error;
            });
    }

    handleModal() {
        const { opened: isOpen } = this.state;
        this.setState({ opened: !isOpen });
    }

    render() {
        const { handleSubmit, submitting, pristine, invalid, singleFetching, eventId } = this.props;
        const { opened } = this.state;
        return (
            <Fragment>
                <Tooltip
                    content={
                        <FormattedMessage
                            id="Groups.ManageGroup.Action"
                            defaultMessage="Manage Group"
                        />
                    }
                >
                    <button type="button" onClick={() => this.handleModal()}>
                        <Svg icon="settings" />
                    </button>
                </Tooltip>
                <Modal
                    variation="small"
                    title="Edit Group"
                    opened={opened}
                    onClose={() => this.handleModal()}
                >
                    {singleFetching === true && <PageLoader />}

                    <Form onSubmit={handleSubmit(this.onSubmit)}>
                        <Form.Column>
                            <Form.Row>
                                <Form.Columns>
                                    <Form.Column autoWidth>
                                        <Field
                                            name="group_completed"
                                            id="group_completed"
                                            fieldProps={{
                                                label: (
                                                    <FormattedMessage
                                                        id="GroupEdit.Completed"
                                                        defaultMessage="Group Complete"
                                                    />
                                                ),
                                                on: {
                                                    label: 'complete-on',
                                                    value: true,
                                                    id: 'complete-on'
                                                },
                                                off: {
                                                    label: 'complete-off',
                                                    value: false,
                                                    id: 'complete-off'
                                                },
                                                type: 'single',
                                                clearable: true
                                            }}
                                            component={Switch}
                                        />
                                    </Form.Column>
                                    <Form.Column>
                                        <Field
                                            name="willing_to_share"
                                            id="willing_to_share"
                                            fieldProps={{
                                                label: (
                                                    <FormattedMessage
                                                        id="GroupEdit.WillShare"
                                                        defaultMessage="Will share?"
                                                    />
                                                ),
                                                on: {
                                                    label: 'share-on',
                                                    value: true,
                                                    id: 'share-on'
                                                },
                                                off: {
                                                    label: 'share-off',
                                                    value: false,
                                                    id: 'share-off'
                                                },
                                                type: 'single',
                                                clearable: true
                                            }}
                                            component={Switch}
                                        />
                                    </Form.Column>
                                </Form.Columns>
                            </Form.Row>

                            <Form.Row>
                                <Field
                                    name="name"
                                    id="name"
                                    fieldProps={{
                                        label: (
                                            <FormattedMessage
                                                id="GroupCreate.Grpup name"
                                                defaultMessage="Group Name"
                                            />
                                        ),
                                        clearable: true
                                    }}
                                    component={Text}
                                    validate={valueValidation([{ validator: 'required' }])}
                                    fieldAttr={{ required: true }}
                                />
                            </Form.Row>
                            <Form.Row>
                                <Field
                                    name="group_size"
                                    id="group_size"
                                    fieldProps={{
                                        label: (
                                            <FormattedMessage
                                                id="GroupCreate.GroupSize"
                                                defaultMessage="Group Size"
                                            />
                                        )
                                    }}
                                    component={Text}
                                    validate={valueValidation([{ validator: 'numericality' }])}
                                />
                            </Form.Row>
                            <Form.Row>
                                <Field
                                    name="event"
                                    id="event"
                                    fieldProps={{
                                        label: (
                                            <FormattedMessage
                                                id="GroupCreate.Event"
                                                defaultMessage="Event"
                                            />
                                        ),
                                        options: {
                                            endpoint: 'api/events?pagination=false',
                                            mapData: {
                                                label: 'name',
                                                value: 'iri'
                                            }
                                        }
                                    }}
                                    component={Autocomplete}
                                />
                            </Form.Row>
                            <Form.Row>
                                <Field
                                    name="orders"
                                    id="orders"
                                    fieldProps={{
                                        label: (
                                            <FormattedMessage
                                                id="GroupCreate.OrderIDs"
                                                defaultMessage="Order IDs"
                                            />
                                        ),
                                        options: {
                                            endpoint: `api/orders?include=event&filters[event.id][equals]=${eventId &&
                                                eventId}&pagination=false`,
                                            mapData: {
                                                label: 'order_number',
                                                value: 'iri'
                                            }
                                        }
                                    }}
                                    component={MultiAutocomplete}
                                />
                            </Form.Row>
                            <Form.Row>
                                <Field
                                    name="tables"
                                    id="tables"
                                    fieldProps={{
                                        label: (
                                            <FormattedMessage
                                                id="GroupEdit.Table"
                                                defaultMessage="Table"
                                            />
                                        ),
                                        options: {
                                            endpoint: `api/tables?include=event&filters[event.id][equals]=${eventId &&
                                                eventId}&pagination=false`,
                                            mapData: {
                                                label: 'table_number',
                                                value: 'iri'
                                            }
                                        }
                                    }}
                                    component={Autocomplete}
                                />
                            </Form.Row>

                            <Form.Row>
                                <Button
                                    submit
                                    disabled={pristine || invalid}
                                    loading={submitting}
                                    label={
                                        <FormattedMessage
                                            id="GroupUpdate.Update"
                                            defaultMessage="Update"
                                        />
                                    }
                                />
                            </Form.Row>
                        </Form.Column>
                    </Form>
                </Modal>
            </Fragment>
        );
    }
}

ManageGroupModal.defaultProps = {
    submitting: false,
    pristine: false,
    invalid: false,
    fetchGroupData: () => {},
    refreshData: () => {},
    singleFetching: false,
    initialValues: {},
    id: '',
    eventId: ''
};

ManageGroupModal.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    initialValues: PropTypes.oneOfType([PropTypes.object]),
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    fetchGroupData: PropTypes.func,
    refreshData: PropTypes.func,
    singleFetching: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    eventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

ManageGroupModal = reduxForm({
    enableReinitialize: false
})(ManageGroupModal);

const mapStateToProps = (state, ownProps) => ({
    initialValues: tablesDto(dtoForm(getSingleGroupData(state, ownProps.id))),
    singleFetching: getSingleGroupFetching(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchGroupData: () => {
        // Getting included data from API by setting params
        const params = {
            include: 'tables,orders,event'
        };
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: groupsActions.START_FETCHING_SINGLE_GROUP,
                id: ownProps.id,
                params
            });
        }).catch(error => ({ error }));
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ManageGroupModal);
