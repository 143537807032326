import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ResizeObserver from 'resize-observer-polyfill';
import breakpoints from 'erputils/responsive';
import debounce from 'lodash/debounce';
import Button from 'erpcomponents/Button';
import { FormattedMessage } from 'react-intl';
import Form, { Switch, Select, Text } from 'erpcomponents/Form';
import { Field } from 'redux-form';
import ElementLoader from 'erpcomponents/ElementLoader';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import GroupItem from 'erpsrc/screens/Tables/components/GroupItem';
import { actions as tablesActions } from 'erpsrc/screens/Tables/Tables.reducer';
import './GroupsFooter.scss';

class GroupsFooter extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            layout: this.getNextLayout(),
            isVisible: true,
            groupsFiltered: [],
            groupsFilteredChunks: [],
            slideCount: 0,
            slideActive0: 0
        };

        this.groupsFooterRef = React.createRef();

        const { storeFooterSize } = this.props;
        this.footerResizeObserver = new ResizeObserver(entries => {
            const entry = entries[0];
            storeFooterSize({
                width: entry.contentRect.width,
                height: entry.contentRect.height
            });
        });

        this.windowResizeHandler = debounce(
            () => {
                const { layout } = this.state;
                const nextLayout = this.getNextLayout();
                if (layout !== nextLayout) {
                    this.setState({ layout: nextLayout });
                }
            },
            300,
            { maxWait: 1500 }
        ).bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.windowResizeHandler);

        const {
            groupsData,
            filterGroupsIdSearch,
            filterGroupsOrderSearch,
            filterGroupsCompletion,
            filterGroupsVip,
            filterGroupsSeated
        } = this.props;

        this.setGroupStates(
            groupsData,
            filterGroupsIdSearch,
            filterGroupsOrderSearch,
            filterGroupsCompletion,
            filterGroupsVip,
            filterGroupsSeated
        );

        this.footerResizeObserver.observe(this.groupsFooterRef.current);
    }

    componentWillReceiveProps(nextProps) {
        const {
            groupsData,
            filterGroupsIdSearch,
            filterGroupsOrderSearch,
            filterGroupsCompletion,
            filterGroupsVip,
            filterGroupsSeated
        } = nextProps;
        this.setGroupStates(
            groupsData,
            filterGroupsIdSearch,
            filterGroupsOrderSearch,
            filterGroupsCompletion,
            filterGroupsVip,
            filterGroupsSeated
        );
    }

    componentWillUnmount() {
        this.footerResizeObserver.disconnect();
        window.removeEventListener('resize', this.windowResizeHandler);
    }

    getNextLayout() {
        return window.innerWidth > breakpoints.large ? 'large' : 'default';
    }

    setGroupStates(
        groupsUnfiltered = [],
        filterGroupsIdSearch,
        filterGroupsOrderSearch,
        filterGroupsCompletion,
        filterGroupsVip,
        filterGroupsSeated
    ) {
        const groups = [];

        groupsUnfiltered.forEach(group => {
            // filter ID search
            const matchingIdSearch =
                !filterGroupsIdSearch ||
                (filterGroupsIdSearch && !filterGroupsIdSearch.length) ||
                (filterGroupsIdSearch &&
                    filterGroupsIdSearch.length &&
                    group.name.toUpperCase().includes(filterGroupsIdSearch.toUpperCase()));

            if (matchingIdSearch) {
                // filter by Completion
                const matchingCompletion =
                    !filterGroupsCompletion ||
                    !['completed', 'uncompleted'].includes(filterGroupsCompletion) ||
                    (filterGroupsCompletion &&
                        filterGroupsCompletion === 'completed' &&
                        !!group.group_completed) ||
                    (filterGroupsCompletion &&
                        filterGroupsCompletion === 'uncompleted' &&
                        !group.group_completed);

                if (matchingCompletion) {
                    // filter by Order Number
                    const matchingOrderNumber =
                        !filterGroupsOrderSearch ||
                        (filterGroupsOrderSearch && !filterGroupsOrderSearch.length) ||
                        (filterGroupsOrderSearch &&
                            filterGroupsOrderSearch.length &&
                            group.orders &&
                            group.orders.length &&
                            group.orders.findIndex(
                                order =>
                                    order.order_number &&
                                    order.order_number
                                        .toUpperCase()
                                        .includes(filterGroupsOrderSearch.toUpperCase())
                            ) > -1);

                    if (matchingOrderNumber) {
                        // filter vip/!willing_to_share
                        const matchingVip =
                            !filterGroupsVip ||
                            (filterGroupsVip &&
                                group.willing_to_share !== undefined &&
                                !group.willing_to_share);

                        if (matchingVip) {
                            // filter not-seated/seated
                            const matchSeatedFilter = filterGroupsSeated
                                ? // match seated
                                  group.tables !== undefined && group.tables && group.tables.length
                                : // match not seated
                                  group.tables === undefined ||
                                  !group.tables ||
                                  (group.tables && !group.tables.length);

                            const groupData = { ...group, displayPrivateTag: true };
                            if (group.tables !== undefined && group.tables && group.tables.length) {
                                groupData.displayTableName = group.tables.map((table, i) => [
                                    i > 0 && ', ',
                                    table.table_number || 'N.A.'
                                ]);
                            }

                            if (matchSeatedFilter) {
                                groups.push(groupData);
                            }
                        }
                    }
                }
            }
        });

        const groupsCopy = [...groups];

        // split groups list in chunks of three
        const groupChunks = [];
        if (groupsCopy && groupsCopy.length) {
            while (groupsCopy.length) {
                groupChunks.push(groupsCopy.splice(0, 3));
            }
        }

        const { slideCount, slideActive0 } = this.state;
        const nextSlideCount = groupChunks.length;

        if (slideCount !== nextSlideCount) {
            if (slideActive0 !== 0) {
                this.setState({
                    groupsFiltered: groups,
                    groupsFilteredChunks: groupChunks,
                    slideCount: nextSlideCount,
                    slideActive0: 0
                });
            } else {
                this.setState({
                    groupsFiltered: groups,
                    groupsFilteredChunks: groupChunks,
                    slideCount: nextSlideCount
                });
            }
        } else {
            this.setState({
                groupsFiltered: groups,
                groupsFilteredChunks: groupChunks
            });
        }
    }

    isPrevAllowed() {
        const { slideActive0 } = this.state;
        return slideActive0 - 1 >= 0;
    }

    isNextAllowed() {
        const { slideCount, slideActive0 } = this.state;
        return slideActive0 + 1 < slideCount;
    }

    goToPrevSlide() {
        const { slideActive0 } = this.state;
        if (this.isPrevAllowed()) {
            this.setState({ slideActive0: slideActive0 - 1 });
        }
    }

    goToNextSlide() {
        const { slideActive0 } = this.state;
        if (this.isNextAllowed()) {
            this.setState({ slideActive0: slideActive0 + 1 });
        }
    }

    toggleGroupsVisibility() {
        const { isVisible } = this.state;
        this.setState({ isVisible: !isVisible });
    }

    renderSlider() {
        const {
            groupsData,
            eventNoGroups,
            groupsListingFetching,
            resetFilters,
            getTableById
        } = this.props;

        const { layout, groupsFiltered, groupsFilteredChunks, slideActive0 } = this.state;

        let groups = [];

        if (layout === 'large') {
            groupsFilteredChunks.forEach(chunk => {
                chunk.forEach(group => {
                    groups.push(group);
                });
            });
        } else {
            groups = groupsFilteredChunks[slideActive0];
        }

        if (groupsListingFetching) {
            return (
                <div className="groups-footer__no-results">
                    <ElementLoader />
                </div>
            );
        }

        if (eventNoGroups) {
            return (
                <div className="groups-footer__no-results">
                    <p className="groups-footer__no-results-text">
                        <FormattedMessage
                            id="GroupFooter.NoResults.title"
                            defaultMessage="There are no groups assigned to this event currently!"
                        />
                    </p>
                </div>
            );
        }

        if (groupsData && groupsData.length && groupsFiltered && !groupsFiltered.length) {
            return (
                <div className="groups-footer__no-results">
                    <p className="groups-footer__no-results-text">
                        <FormattedMessage
                            id="GroupFooter.NoMatchingResults.title"
                            defaultMessage="No groups match selected filters!"
                        />{' '}
                        <Button
                            variation="secondary"
                            size="small"
                            onClick={() => resetFilters('groups')}
                            label={
                                <FormattedMessage
                                    id="GroupFooter.NoMatchingResults.resetFilter"
                                    defaultMessage="Reset Filters"
                                />
                            }
                        />
                    </p>
                </div>
            );
        }

        if (groupsFiltered && groupsFiltered.length) {
            return (
                <div className="groups-footer__slider">
                    <div className="groups-footer__slider-control groups-footer__slider-control--prev">
                        <Button
                            onClick={() => this.goToPrevSlide()}
                            className="groups-footer__slider-control-button"
                            size="small"
                            iconName="arrowLeft"
                            labelOnlyAria
                            disabled={!this.isPrevAllowed()}
                            label={
                                <FormattedMessage
                                    id="GroupsFooter.SliderGoToPrevious"
                                    defaultMessage="Go to previous slide"
                                />
                            }
                        />
                    </div>
                    <Droppable
                        droppableId="groups-footer"
                        isDropDisabled={false}
                        direction={layout === 'large' ? 'vertical' : 'horizontal'}
                        // mode="virtual"
                        // getContainerForClone={() => document.getElementById('wrapper')}
                        renderClone={(provided, snapshot, rubric) => (
                            <div
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                                /*
                                    style={{
                                        ...provided.draggableProps.style,
                                        backgroundColor: this.colors.waterPurple
                                    }}
                                    */
                                style={{
                                    ...provided.draggableProps.style,
                                    zIndex: 999999
                                }}
                            >
                                <GroupItem
                                    snapshot={{ isDragging: true }}
                                    {...groupsFiltered[rubric.source.index]}
                                />
                            </div>
                        )}
                    >
                        {(provided, snapshot) => (
                            <div
                                className={`groups-footer__slider-track${
                                    snapshot.isDraggingOver
                                        ? ' groups-footer__slider-track--is-dragging-over'
                                        : ''
                                }`}
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                {groups.map(group => {
                                    let tableLocked = false;

                                    if (
                                        group.tables !== undefined &&
                                        group.tables &&
                                        group.tables.length
                                    ) {
                                        group.tables.forEach(table => {
                                            const tableFullData = getTableById(table.id);
                                            if (
                                                tableFullData &&
                                                tableFullData.locked !== undefined &&
                                                tableFullData.locked
                                            ) {
                                                tableLocked = tableFullData.locked;
                                            }
                                        });
                                    }
                                    return (
                                        <Draggable
                                            index={groupsFiltered.findIndex(
                                                item => item.id === group.id
                                            )}
                                            key={`footer-group-${group.id}`}
                                            draggableId={`footer-group-${group.id}`}
                                            isDragDisabled={tableLocked}
                                        >
                                            {(dProvided, dSnapshot) => {
                                                return (
                                                    <GroupItem
                                                        provided={dProvided}
                                                        snapshot={dSnapshot}
                                                        tableLocked={tableLocked}
                                                        displayHandle
                                                        style={dProvided.draggableProps.style}
                                                        {...group}
                                                    />
                                                );
                                            }}
                                        </Draggable>
                                    );
                                })}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                    <div className="groups-footer__slider-control groups-footer__slider-control--next">
                        <Button
                            onClick={() => this.goToNextSlide()}
                            className="groups-footer__slider-control-button"
                            size="small"
                            iconName="arrowRight"
                            labelOnlyAria
                            disabled={!this.isNextAllowed()}
                            label={
                                <FormattedMessage
                                    id="GroupsFooter.SliderGoToNext"
                                    defaultMessage="Go to next slide"
                                />
                            }
                        />
                    </div>
                </div>
            );
        }

        return null;
    }

    render() {
        const { groupsData, eventNoGroups, groupsListingFetching } = this.props;
        const { isVisible } = this.state;

        const disabledFilters = groupsListingFetching || eventNoGroups;

        return (
            <div
                ref={this.groupsFooterRef}
                id="groups-footer"
                className={`groups-footer${isVisible ? '' : ' groups-footer--closed'}`}
            >
                {(eventNoGroups || (!!groupsData && !!groupsData.length)) && (
                    <div className="groups-footer__content">
                        <div className="groups-footer__visibility">
                            <Button
                                onClick={() => this.toggleGroupsVisibility()}
                                className="groups-footer__visibility-button"
                                size="small"
                                iconName="arrowDown"
                                labelOnlyAria
                                label={
                                    isVisible ? (
                                        <FormattedMessage
                                            id="TableAllotments.HideGroups"
                                            defaultMessage="Hide groups"
                                        />
                                    ) : (
                                        <FormattedMessage
                                            id="TableAllotments.ShowGroups"
                                            defaultMessage="Show groups"
                                        />
                                    )
                                }
                            />
                        </div>
                        <div
                            className={`groups-footer__filters${
                                disabledFilters ? ' groups-footer__filters--disabled' : ''
                            }`}
                        >
                            {/* <h3 className="groups-footer__filters-title">Groups</h3> */}
                            <div className="groups-footer__filters-options">
                                <Form.Row>
                                    <Form.Columns breakOnMobile>
                                        <Form.Column>
                                            <Field
                                                name="filterGroupsIdSearch"
                                                id="filter-groups-id-search"
                                                fieldProps={{
                                                    label: 'Group ID',
                                                    clearable: true
                                                }}
                                                fieldAttr={{
                                                    disabled: disabledFilters
                                                }}
                                                component={Text}
                                            />
                                        </Form.Column>
                                        <Form.Column>
                                            <Field
                                                name="filterGroupsOrderSearch"
                                                id="filter-groups-order-search"
                                                fieldProps={{
                                                    label: 'Order #',
                                                    clearable: true
                                                }}
                                                fieldAttr={{
                                                    disabled: disabledFilters
                                                }}
                                                component={Text}
                                            />
                                        </Form.Column>
                                        <Form.Column>
                                            <Field
                                                name="filterGroupsCompletion"
                                                id="filter-groups-completion"
                                                fieldProps={{
                                                    label: 'Filter by Completion',
                                                    options: [
                                                        { label: 'Any', value: 'any' },
                                                        {
                                                            label: 'Completed',
                                                            value: 'completed'
                                                        },
                                                        {
                                                            label: 'Uncompleted',
                                                            value: 'uncompleted'
                                                        }
                                                    ],
                                                    clearable: false,
                                                    isSearchable: false
                                                }}
                                                fieldAttr={{
                                                    disabled: disabledFilters
                                                }}
                                                component={Select}
                                            />
                                        </Form.Column>
                                        <Form.Column autoWidth>
                                            <Field
                                                name="filterGroupsVip"
                                                id="filter-groups-vip"
                                                fieldProps={{
                                                    on: {
                                                        value: true,
                                                        id: 'filter-groups-vip-true'
                                                    },
                                                    off: {
                                                        value: false,
                                                        id: 'filter-groups-vip-false'
                                                    },
                                                    label: 'VIP'
                                                }}
                                                fieldAttr={{
                                                    disabled: disabledFilters
                                                }}
                                                component={Switch}
                                            />
                                        </Form.Column>
                                        <Form.Column autoWidth>
                                            <Field
                                                name="filterGroupsSeated"
                                                id="filter-groups-seated"
                                                fieldProps={{
                                                    on: {
                                                        label: 'Seated',
                                                        value: true,
                                                        id: 'filter-groups-seated-true'
                                                    },
                                                    off: {
                                                        label: 'Not Seated',
                                                        value: false,
                                                        id: 'filter-groups-seated-false'
                                                    },
                                                    type: 'dual',
                                                    label: 'Seated'
                                                }}
                                                fieldAttr={{
                                                    disabled: disabledFilters
                                                }}
                                                component={Switch}
                                            />
                                        </Form.Column>
                                    </Form.Columns>
                                </Form.Row>
                            </div>
                        </div>
                        {this.renderSlider()}
                    </div>
                )}
            </div>
        );
    }
}

GroupsFooter.defaultProps = {
    groupsListingFetching: false,
    groupsData: null,
    eventNoGroups: false,
    filterGroupsIdSearch: null,
    filterGroupsOrderSearch: null,
    filterGroupsCompletion: null,
    filterGroupsVip: null,
    filterGroupsSeated: null,
    resetFilters: () => {},
    getTableById: () => {},
    storeFooterSize: () => {}
};

GroupsFooter.propTypes = {
    groupsListingFetching: PropTypes.bool,
    groupsData: PropTypes.oneOfType([PropTypes.array]),
    eventNoGroups: PropTypes.bool,
    filterGroupsIdSearch: PropTypes.string,
    filterGroupsOrderSearch: PropTypes.string,
    filterGroupsCompletion: PropTypes.string,
    filterGroupsVip: PropTypes.bool,
    filterGroupsSeated: PropTypes.bool,
    resetFilters: PropTypes.func,
    getTableById: PropTypes.func,
    storeFooterSize: PropTypes.func
};

const mapDispatchToProps = dispatch => ({
    storeFooterSize: size => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: tablesActions.STORE_TABLE_FOOTER_ELEMENT_SIZE,
                footerElementSize: size
            });
        }).catch(error => ({ error }));
    }
});

export default connect(
    null,
    mapDispatchToProps
)(GroupsFooter);
