import { createSelector } from 'reselect';

export const getImageData = state =>
    state.imageManager && state.imageManager.data && state.imageManager.data;

export const getImageFetching = state => state.imageManager.fetching;
export const getImageCreating = state => state.imageManager.creating;
export const getImageDeleting = state => state.imageManager.deleting;
export const getImageUpdating = state => state.imageManager.updating;

export default createSelector([
    getImageFetching,
    getImageCreating,
    getImageDeleting,
    getImageUpdating
]);
