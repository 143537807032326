import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reduxForm, Field, getFormValues } from 'redux-form';
import Button from 'erpcomponents/Button';
import { copyNoInstanceGallery } from 'erpcore/components/Form/components/Gallery/utils';
// import { dtoForm } from 'erputils/dto';
// import { diff } from 'deep-object-diff';
import { FormattedMessage } from 'react-intl';
import Form, { Text } from 'erpcore/components/Form';
// import Button from 'erpcomponents/Button';
import { actions as tableTemplatesActions } from 'erpcore/screens/Venues/screens/VenuesEdit/TableTemplates/TableTemplates.reducer';
import {
    getEventTableTemplates,
    getSingleTableTemplateFetching
} from 'erpcore/screens/Venues/screens/VenuesEdit/TableTemplates/TableTemplates.selectors';
import { getVenueData } from 'erpcore/screens/Venues/Venues.selectors';
import { getEventData } from 'erpcore/screens/Events/Events.selectors';

const canBeGroupedWithDto = data => {
    if (data) {
        if (data.tables && data.tables.length) {
            data.tables.forEach((item, index) => {
                if (data.tables[index].room) {
                    data.tables[index].room = data.tables[index].room.iri
                        ? data.tables[index].room.iri
                        : data.tables[index].room;
                }
                if (
                    data.tables[index].can_be_grouped_with &&
                    data.tables[index].can_be_grouped_with
                ) {
                    if (!data.tables[index].can_be_grouped_with.every(i => typeof i === 'string')) {
                        data.tables[index].can_be_grouped_with = data.tables[
                            index
                        ].can_be_grouped_with.map(record => record.iri);
                    }
                }
            });
        }
    }
    return data;
};
class TableTemplateName extends Component {
    constructor(props) {
        super(props);

        this.state = {
            galleryCopyInProgress: false
        };

        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(formData) {
        const { dispatch, eventData, formValues, match } = this.props;

        const formTables =
            formValues && formValues.tables !== undefined && formValues.tables
                ? formValues.tables
                : null;

        if (formTables && formTables.length) {
            const tables = [];
            formTables.forEach(table => {
                if (table.iri) {
                    tables.push(table.iri);
                }
            });
            formData.tables = tables;
            formData.locations = [eventData.location.iri];
        }

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: tableTemplatesActions.START_CREATE_TABLE_TEMPLATE,
                formData
            })
        )
            .then(data => {
                if (
                    data &&
                    data.data &&
                    data.data.id &&
                    eventData.location &&
                    eventData.location.id
                ) {
                    this.setState({ galleryCopyInProgress: true });
                    const locationId = eventData.location.id;
                    const tableTemplateId = data.data.id;
                    copyNoInstanceGallery({
                        source: {
                            getImagesApi: `/api/events/${match.params.id}/images`,
                            groupName: 'floorPlans'
                        },
                        destination: {
                            getImagesApi: `/api/locations/${locationId}/images`,
                            groupName: `table-template-${tableTemplateId}`,
                            galleryApi: '/api/location-images',
                            attachedToEntity: {
                                type: 'location',
                                iri: `/api/locations/${locationId}`,
                                id: locationId
                            }
                        }
                    }).finally(() => {
                        this.setState({ galleryCopyInProgress: false });
                    });
                }
            })
            .catch(error => {
                return error;
            });
    }

    handleSaveNewItem = ({ itemData = {} }) => {
        const { dispatch } = this.props;
        itemData.max_seats = parseInt(itemData.max_seats, 10);
        itemData.min_seats = parseInt(itemData.min_seats, 10);
        // ovdje fali event vezat za table
        // const locations =
        //     initialValues.locations && initialValues.locations.map(location => location.iri);
        return (
            new Promise((resolve, reject) =>
                dispatch({
                    promise: { resolve, reject },
                    type: tableTemplatesActions.START_CREATE_TABLE,
                    formData: itemData
                })
            )
                // .then(() => {
                //     const { newTables } = this.props;
                //     const tableIris = initialValues.tables
                //         ? initialValues.tables.filter(record => record.iri).map(record => record.iri)
                //         : [];

                //     const tables = [...tableIris, ...newTables];

                //     return new Promise((resolve, reject) =>
                //         dispatch({
                //             promise: { resolve, reject },
                //             type: tableTemplatesActions.START_UPDATE_SINGLE_TABLE_TEMPLATE,
                //             formData: { ...initialValues, tables },
                //             id: match.params.id
                //         })
                //     );
                // })
                .catch(() => {})
        );
        // .then(() => {
        //     this.handleAfterAnyTableTemplateUpdate();
        // });
    };

    handleRemoveItem = ({ itemData = {} }) => {
        const { dispatch, initialValues, match } = this.props;
        const updatedTables = initialValues.tables
            .filter(record => record.iri !== itemData.iri)
            .map(record => record.iri);
        const formData = { ...initialValues, tables: updatedTables };
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: tableTemplatesActions.START_UPDATE_SINGLE_TABLE_TEMPLATE,
                id: match.params.id,
                formData
            })
        ).catch(() => {
            // throw new SubmissionError(reduxFormErrorMapper(error));
        });
        // .then(() => {
        //     this.handleAfterAnyTableTemplateUpdate();
        // });
    };

    handleAfterAnyTableTemplateUpdate() {
        const { fetchTableTemplateData, reset } = this.props;
        fetchTableTemplateData().then(() => {
            reset();
        });
    }

    render() {
        const { pristine, invalid, submitting, handleSubmit } = this.props;
        const { galleryCopyInProgress } = this.state;

        return (
            <Form onSubmit={handleSubmit(this.onSubmit)}>
                <Form.Row>
                    <Form.Columns>
                        <Form.Column>
                            <Field
                                name="name"
                                id="name"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="TableTemplateName.TemplateName"
                                            defaultMessage="Template Name"
                                        />
                                    ),
                                    clearable: true
                                }}
                                component={Text}
                                // validate={valueValidation([{ validator: 'required' }])}
                            />
                        </Form.Column>
                        <Form.Column>
                            <Button
                                label="Save new Table Template"
                                submit
                                disabled={pristine || invalid}
                                loading={submitting || galleryCopyInProgress}
                            />
                        </Form.Column>
                    </Form.Columns>
                </Form.Row>
            </Form>
        );
    }
}

TableTemplateName.defaultProps = {
    // fetchTableTemplateData: () => {},
    // singleFetching: false,
    initialValues: {},
    fetchTableTemplateData: () => {},
    submitting: false,
    pristine: false,
    invalid: false,
    eventData: {},
    formValues: null
};

TableTemplateName.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.oneOfType([PropTypes.object]).isRequired,
    initialValues: PropTypes.oneOfType([PropTypes.object]),
    fetchTableTemplateData: PropTypes.func,
    reset: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    eventData: PropTypes.oneOfType([PropTypes.object]),
    formValues: PropTypes.oneOfType([PropTypes.object])
};

TableTemplateName = reduxForm({
    form: 'TableTemplateName',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true, // important
    updateUnregisteredFields: false // important
})(TableTemplateName);

const mapStateToProps = (state, ownProps) => ({
    initialValues: canBeGroupedWithDto(getEventTableTemplates(state)),
    singleFetching: getSingleTableTemplateFetching(state),
    data: getEventTableTemplates(state),
    venueData: getVenueData(state, ownProps.match.params.id),
    eventData: getEventData(state, ownProps.match.params.id),
    formValues: getFormValues('Tables')(state)
});

//  Getting initial value populated in the form from the store

export default withRouter(
    connect(
        mapStateToProps,
        null
    )(TableTemplateName)
);
