import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { diff } from 'deep-object-diff';
import Button from 'erpcomponents/Button';
import { FormattedMessage } from 'react-intl';
import { Field, reduxForm /* SubmissionError, reset */ } from 'redux-form';
import Form, { Text } from 'erpcore/components/Form';
import { connect } from 'react-redux';
// import reduxFormErrorMapper from 'erputils/reduxFormErrorMapper';
import PageLoader from 'erpcomponents/PageLoader';
import { actions as printCardActions } from 'erpcore/screens/Settings/screens/PrintSettings/PrintCardSettings/PrintCardSettings.reducer';
import {
    getPrintSettings2x35Height,
    getPrintSettingsFetching
} from 'erpcore/screens/Settings/screens/PrintSettings/PrintCardSettings/PrintCardSettings.selectors';
import valueValidation from 'erpsrc/utils/valueValidation';

class Card2x3Height extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        const { fetchPrintSettings } = this.props;
        fetchPrintSettings();
    }

    onSubmit(formData) {
        const { dispatch, fetchPrintSettings, initialValues } = this.props;
        formData = diff(initialValues, formData);
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: printCardActions.START_UPDATE_PRINT_2X35_HEIGHT_SETTINGS,
                formData: { value: formData.height2x35 }
            });
        }).then(() => fetchPrintSettings());
    }

    render() {
        // const { onSyncNow, getData } = this.props;
        const { handleSubmit, singleFetching, submitting, pristine, invalid } = this.props;
        return (
            <Form onSubmit={handleSubmit(this.onSubmit)}>
                {singleFetching === true && <PageLoader />}
                <Form.SectionTitle>
                    <FormattedMessage
                        id="Settings.Card2x3Height.Title.2x3.5"
                        defaultMessage="Print Settings - 2x3.5 Top margin"
                    />
                </Form.SectionTitle>
                <p>Card container top margin is represented in pixels. </p>
                <Form.Row>
                    <Field
                        name="height2x35"
                        id="height2x35"
                        fieldProps={{
                            label: (
                                <FormattedMessage
                                    id="Settings.Card2x3Height.Margin"
                                    defaultMessage="2x3.5 Card container top margin in %"
                                />
                            ),

                            clearable: true
                        }}
                        validate={valueValidation([
                            {
                                validator: 'numericality',
                                args: { '<': 101, msg: 'Max. value is 100' }
                            }
                        ])}
                        component={Text}
                    />
                </Form.Row>
                <Button disabled={pristine || invalid} loading={submitting} label="Save" submit />
            </Form>
        );
    }
}

Card2x3Height.defaultProps = {
    submitting: false,
    pristine: false,
    invalid: false,
    singleFetching: false,
    fetchPrintSettings: () => {},
    initialValues: {}
};

Card2x3Height.propTypes = {
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
    singleFetching: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    fetchPrintSettings: PropTypes.func,
    initialValues: PropTypes.oneOfType([PropTypes.object])
};

Card2x3Height = reduxForm({
    form: 'PrintCard2x3.5HeightSettingsForm',
    enableReinitialize: true
})(Card2x3Height);

const mapStateToProps = state => ({
    initialValues: getPrintSettings2x35Height(state),
    singleFetching: getPrintSettingsFetching(state)
});

const mapDispatchToProps = dispatch => ({
    fetchPrintSettings: () => {
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: printCardActions.START_FETCHING_PRINT_SETTINGS_HEIGHT_2X35
            })
        ).catch(error => error);
    }
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Card2x3Height)
);
