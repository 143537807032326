import { takeLatest, put, call, select } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import { getQueryParams } from 'erpcore/components/Listing/Listing.selectors';
import { actions as listingActions } from 'erpcomponents/Listing/Listing.reducer';
import { actions as notificationManagerActions } from 'erputils/NotificationManager/NotificationManager.reducer';
import formatLocationFormData from 'erputils/formatLocationFormData';
import dto, { dtoForm } from 'erputils/dto';

import { actions as companiesActions } from './Companies.reducer';

/**
 * Create Company
 * @param  {Object} promise
 * @return {Object} formData
 */
export function* createCompany({ promise, formData }) {
    try {
        const data = formatLocationFormData(formData);
        formData = { ...formData, ...data.payload };
        const createCompanyAPI = yield restClient.post(`api/companies`, formData);
        yield put({
            type: companiesActions.CREATE_COMPANY_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createCompanyAPI.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: companiesActions.CREATE_COMPANY_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Fetch Company
 * @param  {Object} promise
 * @return {string} id Company id
 */
export function* fetchCompany({ promise, id }) {
    try {
        const fetchCompanyAPI = yield restClient.get(`api/companies/${id}`);
        yield put({
            type: companiesActions.FETCH_SINGLE_COMPANY_SUCCESSFUL
        });
        const formatApiData = dto(fetchCompanyAPI.data);
        const storeData = {
            data: formatApiData.data,
            formData: {
                name: formatApiData.data.name,
                website: formatApiData.data.website,
                phone_number: formatApiData.data.phone_number,
                email: formatApiData.data.email,
                address: dtoForm(dto(fetchCompanyAPI.data).data)
            }
        };
        yield put({
            type: companiesActions.STORE_SINGLE_COMPANY_DATA,
            id,
            response: storeData
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: companiesActions.FETCH_SINGLE_COMPANY_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Update Company single data
 * @param  {Object} id ID of an Company
 * @return {Object} Response from API
 */
export function* updateSingleCompany({ promise, formData, id }) {
    try {
        const updateSingleCompanyAPI = yield restClient.put(`api/companies/${id}`, formData);
        yield put({
            type: companiesActions.UPDATE_SINGLE_COMPANY_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateSingleCompanyAPI.data
        });
        yield put({
            type: companiesActions.STORE_SINGLE_COMPANY_DATA,
            id,
            response: updateSingleCompanyAPI.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: companiesActions.UPDATE_SINGLE_COMPANY_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Delete Single Company
 * @param  {Object} id ID of an Company
 * @return {Object} Response from API
 */
export function* deleteSingleCompany({ promise, id }) {
    try {
        const deleteSingleCompanyAPI = yield restClient.delete(`api/companies/${id}`);
        yield put({
            type: companiesActions.DELETE_SINGLE_COMPANY_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteSingleCompanyAPI.data
        });
        const params = yield select(getQueryParams, { name: 'companies' });
        yield put({
            type: listingActions.START_FETCHING_LISTING,
            entity: 'COMPANIES',
            name: 'companies',
            endpoint: 'api/companies',
            params
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: companiesActions.DELETE_SINGLE_COMPANY_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Export All Companies
 * @return {Object} Response from API
 */
export function* exportCompanies({ promise }) {
    try {
        yield restClient
            .get(`api/companies`, {
                headers: { Accept: ' text/csv' },
                responseType: 'arraybuffer'
            })
            .then(response => {
                const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', 'companies.csv');
                document.body.appendChild(link);
                link.click();
                link.remove();
            });
        yield put({
            type: companiesActions.EXPORT_COMPANIES_SUCCESSFUL
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: companiesActions.EXPORT_COMPANIES_FAILED
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Register action to watcher
 */
export const companiesSaga = [
    takeLatest(companiesActions.START_CREATE_COMPANY, createCompany),
    takeLatest(companiesActions.START_FETCHING_SINGLE_COMPANY, fetchCompany),
    takeLatest(companiesActions.START_UPDATE_SINGLE_COMPANY, updateSingleCompany),
    takeLatest(companiesActions.START_DELETE_SINGLE_COMPANY, deleteSingleCompany),
    takeLatest(companiesActions.START_EXPORT_COMPANIES, exportCompanies)
];
