import React from 'react';
import { Field } from 'redux-form';
import Form, { Text, Select, MultiSelect } from 'erpcore/components/Form';
import Collapse from 'erpcore/components/Collapse';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import valueValidation from 'erputils/valueValidation';
// import { resolveObjectPathByString } from 'erputils/utils';
// import parseInteger from 'erputils/parseInteger';
import { actions as tableTemplatesActions } from 'erpcore/screens/Venues/screens/VenuesEdit/TableTemplates/TableTemplates.reducer';

const tableFilter = (tables, member) => {
    if (member && tables) {
        const id = member.match(/\d+/g)[0];
        const tableToRemove = tables[id] && tables[id];
        if (tableToRemove) {
            return tables.filter(table => table.value !== tableToRemove.value);
        }
        return tables;
    }
    return [];
};

const roomsParser = rooms => {
    if (rooms) {
        return rooms.map(room => ({ value: room.iri, label: room.name }));
    }
    return [];
};
class TableTemplateRepeaterItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pendingUpdates: {}
        };

        this.debounceDelay = 1000;
    }

    dispatchUpdate = updates => {
        const { dispatch, data } = this.props;
        const { id = false } = data;
        if (updates.min_seats) updates.min_seats = parseInt(updates.min_seats, 10);
        if (updates.max_seats) updates.max_seats = parseInt(updates.max_seats, 10);
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: tableTemplatesActions.REQUEST_UPDATE_SINGLE_TABLE,
                formData: updates,
                id,
                debounceDelay: this.debounceDelay
            })
        )
            .catch(() => {
                this.removePendingUpdates();
            })
            .then(() => {
                this.removePendingUpdates();
            });
    };

    handleChange = (event, newValue, previousValue, name) => {
        const { member, isNewRepeaterItem } = this.props;

        if (!isNewRepeaterItem) {
            let nameForApi = name.replace(`${member}.`, '');
            if (nameForApi === 'room.iri') {
                nameForApi = 'room';
            }
            const updates = { [nameForApi]: newValue };
            const { pendingUpdates } = this.state;
            const mergedUpdates = { ...pendingUpdates, ...updates };
            this.setState({ pendingUpdates: mergedUpdates });
            this.dispatchUpdate(mergedUpdates);
        }
    };

    removePendingUpdates() {
        this.setState({ pendingUpdates: {} });
    }

    isValid(validatorFunctions = [], value) {
        const errors = validatorFunctions.map(validatorFunction => {
            return validatorFunction(value);
        });
        const isNotValid = errors.find(item => item !== undefined);

        return !isNotValid;
    }

    render() {
        const { member, isNewRepeaterItem, data } = this.props;
        const { tables, venueRooms } = data;
        return (
            <Collapse initiallyExpanded={!!isNewRepeaterItem} hideToggle={!!isNewRepeaterItem}>
                {!!isNewRepeaterItem && <h3>Add New Table</h3>}
                <Collapse.Visible>
                    <Form.Row>
                        <Form.Columns breakOnMobile>
                            <Form.Column>
                                <Field
                                    name={`${member}.table_number`}
                                    id="name"
                                    fieldProps={{
                                        label: 'Table Name'
                                    }}
                                    fieldAttr={{ required: true }}
                                    component={Text}
                                    validate={valueValidation([{ validator: 'required' }])}
                                    onChange={(event, newValue, previousValue, name) => {
                                        /*
                                         * redux-form validation is started after the Field onChange is done,
                                         * so the validity of the field has to be checked manually
                                         * before handling the change data further
                                         */
                                        if (
                                            this.isValid(
                                                valueValidation([{ validator: 'required' }]),
                                                newValue
                                            )
                                        ) {
                                            this.handleChange(event, newValue, previousValue, name);
                                        }
                                    }}
                                />
                            </Form.Column>

                            <Form.Column autoWidth>
                                <Field
                                    name={`${member}.min_seats`}
                                    id="min_seats"
                                    fieldProps={{
                                        label: 'Minimum Seats'
                                    }}
                                    component={Text}
                                    onChange={this.handleChange}
                                />
                                <Field
                                    name={`${member}.max_seats`}
                                    id="max_seats"
                                    fieldProps={{
                                        label: 'Maximum Seats'
                                    }}
                                    component={Text}
                                    onChange={this.handleChange}
                                />
                            </Form.Column>
                            <Form.Column>
                                <Field
                                    name={`${member}.room`}
                                    id="room"
                                    component={Select}
                                    fieldProps={{
                                        label: 'Room',
                                        options: roomsParser(venueRooms)
                                    }}
                                    onChange={this.handleChange}
                                />
                            </Form.Column>
                        </Form.Columns>
                    </Form.Row>
                </Collapse.Visible>
                <Collapse.Expandable>
                    <Form.Row />
                    {/* <Field
                        name={`${member}.can_be_grouped_with`}
                        id="can_be_grouped_with"
                        variation="row"
                        component={MultiAutocomplete}
                        fieldProps={{
                            label: 'Can be grouped with',
                            options: {
                                endpoint: '/api/tables?pagination=false',
                                mapData: {
                                    value: 'iri',
                                    label: 'table_number'
                                }
                            }
                        }}
                        onChange={this.handleChange}
                    /> */}
                    <Field
                        name={`${member}.can_be_grouped_with`}
                        id="can_be_grouped_with"
                        variation="row"
                        component={MultiSelect}
                        fieldProps={{
                            label: 'Can be grouped with',
                            options: tableFilter(tables, member)
                        }}
                        onChange={this.handleChange}
                    />
                    <Form.Row />
                </Collapse.Expandable>
            </Collapse>
        );
    }
}

TableTemplateRepeaterItem.defaultProps = {
    member: null,
    isNewRepeaterItem: null,
    data: null
};

TableTemplateRepeaterItem.propTypes = {
    dispatch: PropTypes.func.isRequired,
    member: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isNewRepeaterItem: PropTypes.bool,
    data: PropTypes.oneOfType([PropTypes.object])
};

export default connect()(TableTemplateRepeaterItem);
