import React from 'react';
import { Field } from 'redux-form';
import Form from 'erpcore/components/Form';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import valueValidation from 'erputils/valueValidation';
import { actions as eventsSortActions } from 'erpcore/screens/Settings/screens/ResellerAdmin/SortEvents/SortEvents.reducer';
import './SortEventsRepeaterItem.scss';

export const Span = props => {
    // eslint-disable-next-line react/prop-types
    const { input } = props;
    return <span className="sort-events-repeater-item">{input.value}</span>;
};
class SortEventsRepeaterItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pendingUpdates: {}
        };
    }

    dispatchUpdate = (updates, name) => {
        const {
            data: { value }
        } = this.props;
        const itemIndex = name.replace(/\D/g, '');
        value[itemIndex] = { ...value[itemIndex], ...updates };
        const { dispatch } = this.props;
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: eventsSortActions.REQUEST_UPDATE_EVENTS_SORT_ORDER,
                formData: {
                    event_sorts: value
                }
            })
        )
            .catch(() => {
                this.removePendingUpdates();
            })
            .then(() => {
                this.removePendingUpdates();
            });
    };

    handleChange = (event, newValue, previousValue, name) => {
        const { member, isNewRepeaterItem } = this.props;

        if (!isNewRepeaterItem) {
            const nameForApi = name.replace(`${member}.`, '');
            const updates = { [nameForApi]: newValue };
            const { pendingUpdates } = this.state;
            const mergedUpdates = { ...pendingUpdates, ...updates };
            this.setState({ pendingUpdates: mergedUpdates });
            this.dispatchUpdate(mergedUpdates, name);
        }
    };

    removePendingUpdates() {
        this.setState({ pendingUpdates: {} });
    }

    isValid(validatorFunctions = [], value) {
        const errors = validatorFunctions.map(validatorFunction => {
            return validatorFunction(value);
        });
        const isNotValid = errors.find(item => item !== undefined);

        return !isNotValid;
    }

    render() {
        const { member } = this.props;
        return (
            <Form.Row>
                <Form.Columns breakOnMobile>
                    <Form.Column>
                        <Field
                            name={`${member}.event.data.attributes.name`}
                            id="label"
                            fieldProps={{
                                label: 'Event'
                            }}
                            fieldAttr={{}}
                            component={Span}
                        />
                    </Form.Column>
                </Form.Columns>
            </Form.Row>
        );
    }
}

SortEventsRepeaterItem.defaultProps = {
    member: null,
    data: {},
    isNewRepeaterItem: false
};

SortEventsRepeaterItem.propTypes = {
    dispatch: PropTypes.func.isRequired,
    member: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    data: PropTypes.oneOfType([PropTypes.object]),
    isNewRepeaterItem: PropTypes.bool,
    // eslint-disable-next-line react/no-unused-prop-types
    input: PropTypes.oneOfType([PropTypes.object]).isRequired
};

export default connect()(SortEventsRepeaterItem);
