import { takeLatest, put, call } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import { actions as notificationManagerActions } from 'erputils/NotificationManager/NotificationManager.reducer';
import { actions as totalSalesByEventBarActions } from './TotalSalesByEventBar.reducer';

/**
 * Fetch TotalSalesByEventBar Data
 * @param  {Object} promise
 * @return {Object} params
 */
export function* fetchData({ promise, widgetId, params }) {
    try {
        const fetchDataAPI = yield restClient.get(`api/orders/report`, {
            headers: { Accept: 'application/json' },
            params
        });
        yield put({
            type: totalSalesByEventBarActions.FETCHING_TOTALSALESBYEVENTBAR_DATA_SUCCESSFUL,
            widgetId
        });
        // Get Events info data
        if (fetchDataAPI && fetchDataAPI.data && fetchDataAPI.data.data) {
            const events = Object.keys(fetchDataAPI.data.data);

            if (events && events.length > 0) {
                const fetchEventsDataAPI = yield restClient.get(`api/events`, {
                    params: { 'filters[id][in]': events, pagination: false }
                });
                if (fetchEventsDataAPI && fetchEventsDataAPI.data && fetchEventsDataAPI.data.data) {
                    fetchDataAPI.data = {
                        ...fetchDataAPI.data,
                        ...{ eventsData: fetchEventsDataAPI.data.data }
                    };
                }
            }
        }
        yield put({
            type: totalSalesByEventBarActions.STORE_TOTALSALESBYEVENTBAR_DATA,
            widgetId,
            response: fetchDataAPI && fetchDataAPI.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: totalSalesByEventBarActions.FETCHING_TOTALSALESBYEVENTBAR_DATA_FAILED,
            widgetId
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error && error.response && error.response.data
        });
        yield call(promise.reject, error && error.response && error.response.data);
    }
}

/**
 * Register action to watcher
 */
export const totalSalesByEventBarSaga = [
    takeLatest(totalSalesByEventBarActions.START_FETCHING_TOTALSALESBYEVENTBAR_DATA, fetchData)
];
