import React from 'react';
import LayoutManager from 'erputils/LayoutManager';
import frontLayout from 'erputils/LayoutManager/frontLayout';
import SignInForm from './components/SignInForm';

const SignIn = () => {
    return (
        <LayoutManager layout={frontLayout} slot="main" layoutType="merge">
            {/* Content that goes into the "main" slot */}
            <SignInForm />
        </LayoutManager>
    );
};

export default SignIn;
