import { createSelector } from 'reselect';

export const getOutApiSingleFetching = state => state.getOutApi && state.getOutApi.singleFetching;

export const getGetOutInitialData = state => {
    if (state.getOutApi) {
        return {
            createNewDataGetOut:
                state.getOutApi.createNewDataGetOut &&
                (state.getOutApi.createNewDataGetOut.value === 'true' ||
                    state.getOutApi.createNewDataGetOut.value === '1')
                    ? // eslint-disable-next-line no-unneeded-ternary
                      true
                    : false,
            automatedSyncGetOut:
                state.getOutApi.automatedSyncGetOut &&
                (state.getOutApi.automatedSyncGetOut.value === 'true' ||
                    state.getOutApi.automatedSyncGetOut.value === '1')
                    ? // eslint-disable-next-line no-unneeded-ternary
                      true
                    : false,
            sinceLastImportGetOut:
                state.getOutApi.sinceLastImportGetOut &&
                (state.getOutApi.sinceLastImportGetOut.value === 'true' ||
                    state.getOutApi.sinceLastImportGetOut.value === '1')
                    ? // eslint-disable-next-line no-unneeded-ternary
                      true
                    : false,
            importIntervalGetOut:
                state.getOutApi.importIntervalGetOut &&
                state.getOutApi.importIntervalGetOut &&
                state.getOutApi.importIntervalGetOut.value,
            importFromGetOut:
                state.getOutApi.importFromGetOut && state.getOutApi.importFromGetOut.value,
            importOnlyEventsGetOut:
                state.getOutApi.importOnlyEventsGetOut &&
                (state.getOutApi.importOnlyEventsGetOut.value === 'true' ||
                    state.getOutApi.importOnlyEventsGetOut === '1')
                    ? // eslint-disable-next-line no-unneeded-ternary
                      true
                    : false
        };
    }
    return null;
};

export default createSelector([getOutApiSingleFetching, getGetOutInitialData]);
