import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import ElementLoader from 'erpcomponents/ElementLoader';
import Widget from 'erpcomponents/Widget';

import { actions as ticketSocketTickets24h } from 'erpcomponents/Widgets/TicketSocketTickets24h/TicketSocketTickets24h.reducer';
import {
    getData,
    getFetching,
    getDifferenceData
} from 'erpcomponents/Widgets/TicketSocketTickets24h/TicketSocketTickets24h.selectors';
import { chartColors } from 'erpdata/colors';
import './TicketSocketTickets24h.scss';
import { formatAmPm } from 'erputils/utils';
import GetOutTickets24h from 'erpcomponents/Widgets/GetOutTickets24h';

class TicketSocketTickets24h extends Component {
    componentDidMount() {
        const { eventSeries, eventID } = this.props;
        if (eventSeries || eventID) {
            const { id, sources, forDate, soldBy } = this.props;
            const properties = {
                eventSeries,
                eventID,
                id,
                sources,
                forDate,
                soldBy
            };
            this.fetch24hDifference(properties);
            this.fetchData(properties);
        }
        return null;
    }

    componentWillReceiveProps(nextProps) {
        const {
            eventSeries: nextEventSeries,
            eventID: nextEventID,
            soldBy: nextSoldBy
        } = nextProps;
        const { eventSeries, eventID, soldBy } = this.props;

        let willFetchData = false;

        if (nextEventID !== eventID) {
            willFetchData = true;
        }

        if (nextEventSeries !== eventSeries) {
            willFetchData = true;
        }

        if (nextSoldBy !== soldBy) {
            willFetchData = true;
        }

        if (willFetchData) {
            const { id, sources, forDate } = nextProps;
            const properties = {
                eventSeries: nextEventSeries,
                eventID: nextEventID,
                soldBy: nextSoldBy,
                id,
                sources,
                forDate
            };
            this.fetch24hDifference(properties);
            this.fetchData(properties);
        }

        return true;
    }

    fetch24hDifference({ eventSeries, eventID, sources, forDate, soldBy }) {
        const { dispatch } = this.props;
        const now = new Date();
        const last24h = new Date(now.getTime() - 24 * 60 * 60 * 1000);
        const last48h = new Date(now.getTime() - 48 * 60 * 60 * 1000);

        let params = {
            'filters[start_date]': last48h,
            'filters[end_date]': last24h,
            display_by: 'hourly',
            'filters[utc]': 'true'
        };

        if (eventID) {
            params = { ...params, ...{ 'filters[events]': eventID } };
        }

        if (eventSeries) {
            params = { ...params, ...{ 'filters[event_series]': eventSeries } };
        }

        if (forDate) {
            params = { ...params, ...{ 'filters[for_date]': forDate } };
        }

        if (soldBy) {
            params['filters[sold_by]'] = soldBy;
        }

        sources.map(source => {
            params = { ...params, ...{ 'filters[source]': source.value } };

            return new Promise((resolve, reject) => {
                dispatch({
                    promise: { resolve, reject },
                    type: ticketSocketTickets24h.START_FETCHING_TICKETSOCKETTICKETS24H_DATA,
                    widgetId: '24hDifference',
                    params,
                    source: source.value
                });
            }).catch(error => ({ error }));
        });
    }

    fetchData({ eventSeries, eventID, id, sources, forDate, soldBy }) {
        const { dispatch } = this.props;
        const now = new Date();
        const yesterday = new Date(now.getTime() - 24 * 60 * 60 * 1000);

        let params = {
            'filters[start_date]': yesterday,
            'filters[end_date]': now,
            display_by: 'hourly',
            'filters[utc]': 'true'
        };

        if (eventID) {
            params = { ...params, ...{ 'filters[events]': eventID } };
        }

        if (eventSeries) {
            params = { ...params, ...{ 'filters[event_series]': eventSeries } };
        }

        if (forDate) {
            params = { ...params, ...{ 'filters[for_date]': forDate } };
        }

        if (soldBy) {
            params['filters[sold_by]'] = soldBy;
        }

        sources.map(source => {
            params = { ...params, ...{ 'filters[source]': source.value } };

            return new Promise((resolve, reject) => {
                dispatch({
                    promise: { resolve, reject },
                    type: ticketSocketTickets24h.START_FETCHING_TICKETSOCKETTICKETS24H_DATA,
                    widgetId: id,
                    params,
                    source: source.value
                });
            }).catch(error => ({ error }));
        });
    }

    formatChartData() {
        const { sources, data, differenceComparison } = this.props;
        const dates = [];

        const toDate = new Date();
        const fromDate = new Date();
        fromDate.setTime(fromDate.getTime() - 24 * 60 * 60 * 1000);

        while (toDate > fromDate) {
            dates.push(`${`${toDate.getHours()}`.slice(-2)}:00:00`);

            toDate.setTime(toDate.getTime() - 1 * 60 * 60 * 1000);
        }

        // Charts options for labels, legends and axis //
        // const options = {
        //     scales: {
        //         xAxes: [
        //             {
        //                 display: false, // this will remove all the x-axis grid lines
        //                 min: 0
        //             }
        //         ],
        //         yAxes: [
        //             {
        //                 display: false, // this will remove all the y-axis grid lines
        //                 ticks: {
        //                     beginAtZero: true // minimum value will be 0.
        //                 }
        //             }
        //         ]
        //     },
        //     legend: {
        //         display: false // Hides top legend
        //     },
        //     tooltips: {
        //         callbacks: {
        //             label(tooltipItem, chartData) {
        //                 const { label: time } = tooltipItem;
        //                 const revenueData = chartData.datasets[
        //                     tooltipItem.datasetIndex
        //                 ].revenues.find(revenue => {
        //                     const date = revenue.date.split(':');
        //                     const hours = date[0];
        //                     const minutes = date[1];
        //
        //                     return formatAmPm(hours, minutes) === time;
        //                 });
        //
        //                 const label =
        //                     chartData.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] ||
        //                     '';
        //
        //                 return [
        //                     `Tickets sold: ${label}`,
        //                     `Revenue: ${formatCurrency(revenueData.revenue / 100)}`
        //                 ]; // Adds Ticket sold: label insted of just value
        //             }
        //         }
        //     }
        // };

        // Default values //
        const defaultValues = {
            labels: dates.map(date => {
                const hours = date.split(':')[0];
                const minutes = date.split(':')[1];

                return formatAmPm(hours, minutes);
            }),
            values: [],
            colors: []
        };

        let totals = null;
        let totalsDayBefore = null;

        sources.map((source, index) => {
            if (
                data &&
                differenceComparison &&
                differenceComparison[source.value] &&
                data[source.value]
            ) {
                const keys = Object.keys(data[source.value].data.data);

                keys.forEach(key => {
                    const UTCToLocalTime = new Date(`${key} UTC`);
                    defaultValues.values.push({
                        date: `${UTCToLocalTime.getHours()}:00:00`,
                        value: data[source.value].data.data[key].attendance,
                        revenue: data[source.value].data.data[key].revenue,
                        currency: data[source.value].data.data[key].revenue_currency || 'USD'
                    });
                    defaultValues.colors.push(chartColors[index]);
                });

                // eslint-disable-next-line prefer-destructuring
                totals = data[source.value].data.totals;
                totalsDayBefore = differenceComparison[source.value].data.totals;
            }
            return defaultValues;
        });

        const assignDataToDates = dates.reverse().map(dateLabel => {
            let returnValue = 0;
            defaultValues.values.forEach(chartValue => {
                if (chartValue.date === dateLabel) {
                    returnValue = chartValue.value;
                }
            });

            return returnValue;
        });

        // const chartData = {
        //     labels: defaultValues.labels.reverse(),
        //     datasets: [
        //         {
        //             revenues: defaultValues.values,
        //             backgroundColor: defaultValues.colors[0],
        //             data: assignDataToDates
        //         }
        //     ]
        // };

        const differenceData = (() => {
            let diff = null;
            if (totals && totals.attendance && totalsDayBefore) {
                if (totalsDayBefore.attendance > 0) {
                    diff =
                        ((totals.attendance - totalsDayBefore.attendance) /
                            totalsDayBefore.attendance) *
                        100;
                } else {
                    diff = 100;
                }
            }
            return diff;
        })();

        const differenceDataLastHour = (() => {
            let diff = null;
            if (
                data &&
                data.ticketsocket &&
                data.ticketsocket.data &&
                data.ticketsocket.data.data &&
                differenceComparison &&
                differenceComparison.ticketsocket &&
                differenceComparison.ticketsocket.data &&
                differenceComparison.ticketsocket.data.data
            ) {
                const lastHour = dates[dates.length - 1];
                const keysToday = Object.keys(data.ticketsocket.data.data);
                const keysYesterday = Object.keys(differenceComparison.ticketsocket.data.data);

                const todaysLastHourWithDate = keysToday.filter(el => {
                    const UTCToLocalTime = new Date(`${el} UTC`);
                    const time = `${UTCToLocalTime.getHours()}:00:00`;
                    return lastHour === time;
                });

                const yesterdaysLastHourWithDate = keysYesterday.filter(el => {
                    const UTCToLocalTime = new Date(`${el} UTC`);
                    const time = `${UTCToLocalTime.getHours()}:00:00`;
                    return lastHour === time;
                });

                const todaysDataMatch =
                    (data.ticketsocket.data.data &&
                        todaysLastHourWithDate.length > 0 &&
                        data.ticketsocket.data.data[todaysLastHourWithDate[0]] &&
                        data.ticketsocket.data.data[todaysLastHourWithDate[0]].attendance) ||
                    0;
                const yesterdaysDataMatch =
                    (differenceComparison.ticketsocket.data.data &&
                        yesterdaysLastHourWithDate.length > 0 &&
                        differenceComparison.ticketsocket.data.data[
                            yesterdaysLastHourWithDate[0]
                        ] &&
                        differenceComparison.ticketsocket.data.data[yesterdaysLastHourWithDate[0]]
                            .attendance) ||
                    0;

                if (
                    todaysDataMatch &&
                    todaysDataMatch !== 0 &&
                    yesterdaysDataMatch &&
                    yesterdaysDataMatch !== 0
                ) {
                    diff = ((todaysDataMatch - yesterdaysDataMatch) / yesterdaysDataMatch) * 100;
                } else if (yesterdaysDataMatch === 0 && todaysDataMatch !== 0) {
                    diff = 100;
                } else if (todaysDataMatch === 0 && yesterdaysDataMatch !== 0) {
                    diff = -100;
                } else {
                    diff = '0';
                }
            }

            return diff;
        })();

        let revenueSum = 0;
        defaultValues.values.forEach(revenue => {
            revenueSum += revenue.revenue;
        });

        const currency =
            defaultValues.values && defaultValues.values[0] && defaultValues.values[0].currency;

        return (
            <div className="online-tickets">
                {!!totals && !!totals.attendance && totals.attendance > 0 ? (
                    <React.Fragment>
                        <p className="online-tickets__title">
                            <FormattedMessage
                                id="TicketSocketTickets.Last24h"
                                defaultMessage="Last 24h"
                            />
                        </p>
                        <p className="online-tickets__statistic-value">
                            <FormattedNumber value={totals.attendance} />{' '}
                            {!!differenceData && (
                                <span className="online-tickets__statistic-difference">
                                    ({differenceData.toFixed(2)}%)
                                </span>
                            )}
                        </p>
                    </React.Fragment>
                ) : (
                    <p className="online-tickets__text">
                        <FormattedMessage
                            id="TicketSocketTickets24h.NoTickets"
                            defaultMessage="No sold tickets for the last 24h"
                        />
                    </p>
                )}

                {!!totals &&
                    !!totals.attendance &&
                    totals.attendance > 0 &&
                    !!differenceDataLastHour &&
                    assignDataToDates.length > 0 && (
                        <React.Fragment>
                            <p className="online-tickets__title">
                                <FormattedMessage
                                    id="TicketSocketTickets.LastHour"
                                    defaultMessage="Last hour"
                                />
                            </p>
                            <p className="online-tickets__statistic-value">
                                {assignDataToDates[assignDataToDates.length - 1]}
                                <span className="online-tickets__statistic-difference">
                                    (
                                    {typeof differenceDataLastHour === 'string'
                                        ? `${differenceDataLastHour}`
                                        : differenceDataLastHour.toFixed(2)}
                                    %)
                                </span>
                            </p>
                        </React.Fragment>
                    )}

                <React.Fragment>
                    <p className="online-tickets__title">
                        <FormattedMessage
                            id="TicketSocketTickets.Revenue"
                            defaultMessage="Revenue"
                        />
                    </p>
                    <p className="online-tickets__statistic-value">
                        {((style = 'currency') => {
                            return (
                                <FormattedNumber
                                    value={Math.round(revenueSum / 100)}
                                    style={style}
                                    currency={currency}
                                />
                            );
                        })()}
                    </p>
                </React.Fragment>

                {/* <BarChart data={chartData} options={options} /> */}
            </div>
        );
    }

    render() {
        const { size, fetching, eventSeries, soldBy } = this.props;

        return (
            <Widget
                title={
                    <FormattedMessage
                        id="TicketSocketTickets24h.Title"
                        defaultMessage="TicketSocket Tickets (24h)"
                    />
                }
                size={size}
            >
                {fetching ? <ElementLoader /> : this.formatChartData()}
                <GetOutTickets24h
                    id="getOutTickets24h"
                    size="3"
                    eventSeries={eventSeries}
                    soldBy={soldBy}
                />
            </Widget>
        );
    }
}

TicketSocketTickets24h.defaultProps = {
    eventSeries: null,
    eventID: null,
    size: null,
    fetching: false,
    data: {},
    sources: [
        {
            label: 'TicketSocket',
            value: 'ticketsocket'
        }
    ],
    differenceComparison: {},
    forDate: null,
    soldBy: null
};

TicketSocketTickets24h.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    eventSeries: PropTypes.string,
    eventID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    dispatch: PropTypes.func.isRequired,
    fetching: PropTypes.bool,
    data: PropTypes.oneOfType([PropTypes.object]),
    sources: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    differenceComparison: PropTypes.oneOfType([PropTypes.object]),
    forDate: PropTypes.string,
    soldBy: PropTypes.string
};

//  Getting initial value populated in the form from the store
const mapStateToProps = (state, ownProps) => {
    return {
        differenceComparison: getDifferenceData(state),
        data: getData(state, ownProps.id),
        fetching: getFetching(state, ownProps.id)
    };
};

export default connect(mapStateToProps)(TicketSocketTickets24h);
