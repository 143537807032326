import { takeLatest, call, put, takeEvery } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import dto from 'erputils/dto';
import { actions as yearOverYearActions } from './YearOverYear.reducer';

/**
 * Fetch Event Series
 * @param  {Object} promise
 * @return {Object} params
 */
export function* fetchEvents({ promise }) {
    try {
        const fetchEventsAPI = yield restClient.get(`api/events?pagination=false`);
        const fetchEventSeriesAPI = yield restClient.get(
            `api/event-series?pagination=false&include=events`
        );

        const events = dto(fetchEventsAPI?.data);
        const eventSeries = dto(fetchEventSeriesAPI?.data);

        const data = {
            ...events,
            data: [...events?.data, ...eventSeries?.data]
        };

        yield put({
            type: yearOverYearActions.FETCHING_YEAR_OVER_YEAR_EVENTS_SUCCESSFUL,
            response: data
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: yearOverYearActions.FETCHING_YEAR_OVER_YEAR_EVENTS_FAILED,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch Year Over Year Chart Data
 * @param  {Object} promise
 * @param  {Object} params
 * @param  {String} Event Iri
 * @return {Object} Response from API
 */
export function* fetchYearOverYearReport({ promise, eventIri, params }) {
    const eventType = `${eventIri.split('/')[eventIri.split('/').length - 2]}`;
    let eventTypeFilter = { 'filters[events]': eventIri };
    if (eventType === 'event-series') {
        eventTypeFilter = { 'filters[event_series]': eventIri };
    }
    params = {
        ...params,
        ...eventTypeFilter
    };

    try {
        const fetchYearOverYearChartDataAPI = yield restClient.get(`api/orders/report`, {
            params,
            headers: { Accept: 'application/json' }
        });

        yield put({
            type: yearOverYearActions.FETCHING_YEAR_OVER_YEAR_CHART_DATA_SUCCESSFUL,
            response: fetchYearOverYearChartDataAPI?.data,
            eventIri
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: yearOverYearActions.FETCHING_YEAR_OVER_YEAR_CHART_DATA_FAILED,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch Year Over Year Chart Data
 * @param  {Object} promise
 * @param  {String} Event Iri
 * @return {Object} Response from API
 */
export function* fetchTicketTypeBreakdown({ promise, eventIri, params }) {
    const eventType = `${eventIri.split('/')[eventIri.split('/').length - 2]}`;
    let eventTypeFilter = { 'filters[events]': eventIri };
    if (eventType === 'event-series') {
        eventTypeFilter = { 'filters[event_series]': eventIri };
    }
    params = {
        ...params,
        ...eventTypeFilter,
        display_by: 'ticket_type'
    };

    try {
        const fetchYearOverYearChartDataAPI = yield restClient.get(`api/orders/report`, {
            params,
            headers: { Accept: 'application/json' }
        });

        yield put({
            type: yearOverYearActions.FETCHING_TICKETTYPE_BREAKDOWN_SUCCESSFUL,
            response: fetchYearOverYearChartDataAPI?.data,
            eventIri
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: yearOverYearActions.FETCHING_TICKETTYPE_BREAKDOWN_FAILED,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
export default [
    takeLatest(yearOverYearActions.START_FETCHING_YEAR_OVER_YEAR_EVENTS, fetchEvents),
    takeEvery(
        yearOverYearActions.START_FETCHING_YEAR_OVER_YEAR_CHART_DATA,
        fetchYearOverYearReport
    ),
    takeEvery(yearOverYearActions.START_FETCHING_TICKETTYPE_BREAKDOWN, fetchTicketTypeBreakdown)
];
