import { all } from 'redux-saga/effects';
import { authManagerSaga } from 'erpcore/utils/AuthManager/AuthManager.saga';
import { forgotPasswordSaga } from 'erpcore/screens/ForgotPassword//ForgotPassword.saga';
import { myAccountSaga } from 'erpcore/screens/MyAccount/MyAccount.saga';
import { signUpSaga } from 'erpcore/screens/SignUp/SignUp.saga';
import { listingSaga } from 'erpcomponents/Listing/Listing.saga';
import { usersSaga } from 'erpcore/screens/Users/Users.saga';
import { companiesSaga } from 'erpcore/screens/Companies/Companies.saga';
import { eventsSaga } from 'erpcore/screens/Events/Events.saga';
import { venuesSaga } from 'erpcore/screens/Venues/Venues.saga';
import { customersSaga } from 'erpcore/screens/Customers/Customers.saga';
import { vendorsSaga } from 'erpcore/screens/Vendors/Vendors.saga';
import { resellersSaga } from 'erpcore/screens/Resellers/Resellers.saga';
import {
    eventCategoriesSaga,
    eventSeriesSaga,
    ageRequirementsSaga,
    barTypesSaga,
    foodTypesSaga,
    headerMenuSaga,
    resellerFilterSaga,
    eventsSortSaga,
    homepageSliderSaga,
    ticketSocketApiSaga,
    getOutApiSaga,
    printSettingsSaga,
    permissionsSaga,
    rolesSaga
} from 'erpcore/screens/Settings/Settings.saga';
import { ordersSaga } from 'erpcore/screens/Orders/Orders.saga';
import { groupsSaga } from 'erpcore/screens/Groups/Groups.saga';
import { ticketTypesSaga } from 'erpcore/screens/Events/screens/EventEdit/TicketTypes.saga';
import { tableTemplatesSaga } from 'erpcore/screens/Venues/screens/VenuesEdit/TableTemplates/TableTemplates.saga';
import { notificationsSaga } from 'erpcore/screens/Notifications/Notifications.saga';
import { tablesSaga } from 'erpcore/screens/Tables/Tables.saga';
import { roomsSaga } from 'erpcore/screens/Venues/screens/VenuesEdit/Rooms/Rooms.saga';
import { imageManager } from 'erpcomponents/ImageManager/ImageManager.saga';
import widgetsSaga from 'erpcomponents/Widgets/Widgets.saga';
import { dashboardsSaga } from 'erpsrc/screens/Dashboard/Dashboard.saga';
import { activityLogSaga } from 'erpcomponents/ActivityLog/ActivityLog.saga';
import { commentsSaga } from 'erpcomponents/Comments/Comments.saga';
import yearOverYearSaga from 'erpcore/screens/Dashboard/screens/YearOverYear/YearOverYear.saga';

export default function* rootSaga() {
    yield all([...authManagerSaga]);
    yield all([...forgotPasswordSaga]);
    yield all([...myAccountSaga]);
    yield all([...signUpSaga]);
    yield all([...listingSaga]);
    yield all([...usersSaga]);
    yield all([...companiesSaga]);
    yield all([...eventsSaga]);
    yield all([...venuesSaga]);
    yield all([...customersSaga]);
    yield all([...vendorsSaga]);
    yield all([...resellersSaga]);
    yield all([...eventCategoriesSaga]);
    yield all([...eventSeriesSaga]);
    yield all([...ageRequirementsSaga]);
    yield all([...barTypesSaga]);
    yield all([...foodTypesSaga]);
    yield all([...ordersSaga]);
    yield all([...groupsSaga]);
    yield all([...ticketTypesSaga]);
    yield all([...headerMenuSaga]);
    yield all([...resellerFilterSaga]);
    yield all([...eventsSortSaga]);
    yield all([...tableTemplatesSaga]);
    yield all([...homepageSliderSaga]);
    yield all([...notificationsSaga]);
    yield all([...tablesSaga]);
    yield all([...roomsSaga]);
    yield all([...ticketSocketApiSaga]);
    yield all([...getOutApiSaga]);
    yield all([...imageManager]);
    yield all([...widgetsSaga]);
    yield all([...dashboardsSaga]);
    yield all([...printSettingsSaga]);
    yield all([...activityLogSaga]);
    yield all([...commentsSaga]);
    yield all([...permissionsSaga]);
    yield all([...rolesSaga]);
    yield all([...yearOverYearSaga]);
}
