import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from 'erpcomponents/Button';
import Svg from 'erpcore/components/Svg';
import GroupItem from 'erpcore/screens/Tables/components/GroupItem';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { actions as tablesActions } from 'erpsrc/screens/Tables/Tables.reducer';
import { actions as listingActions } from 'erpcomponents/Listing/Listing.reducer';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import Tooltip from 'erpcomponents/Tooltip';
import './TableItem.scss';

class TableItem extends React.PureComponent {
    constructor(props) {
        super(props);

        this.notificationMessages = {
            overOccupied: (
                <FormattedMessage
                    id="TableItem.Notification.OverOccupied"
                    defaultMessage="over-occupied"
                />
            ),
            mixedPrivate: (
                <FormattedMessage
                    id="TableItem.Notification.MixedPrivate"
                    defaultMessage="Has both private and shared groups"
                />
            ),
            mixedAndOverOccupied: (
                <FormattedMessage
                    id="TableItem.Notification.MixedPrivateAndOverOccupied"
                    defaultMessage="over-occupied and has both private and shared groups"
                />
            )
        };
    }

    lockTable(locked = false) {
        const { updateTable, fetchTables, eventId } = this.props;
        const formData = { locked };
        updateTable(formData).then(() => {
            fetchTables({
                'filters[event.id][equals]': eventId
            });
        });
    }

    renderActions() {
        const { handleModalTableNotes, id, note } = this.props;

        let noteButton = '';

        if (
            note &&
            note.replace(/(\r\n|\n|\r)/gm, '') !== '' &&
            note.replace(/(\r\n|\n|\r)/gm, '') !== '<p></p>'
        ) {
            noteButton = (
                <Tooltip
                    direction="up"
                    content={<div dangerouslySetInnerHTML={{ __html: note }} />} // eslint-disable-line react/no-danger
                >
                    <Button
                        variation="tertiary"
                        size="small"
                        className="table-item__actions-note-edit"
                        iconName="note"
                        onClick={() => handleModalTableNotes(id)}
                        label={
                            <FormattedMessage id="TableItem.EditNote" defaultMessage="Edit Note" />
                        }
                        labelOnlyAria
                    />
                </Tooltip>
            );
        } else {
            noteButton = (
                <Button
                    className="table-item__actions-note-add"
                    onClick={() => handleModalTableNotes(id)}
                    label={<FormattedMessage id="TableItem.AddNote" defaultMessage="Add Note" />}
                    variation="tertiary"
                    size="small"
                />
            );
        }

        return (
            <React.Fragment>
                {noteButton}
                {this.renderLockActions()}
            </React.Fragment>
        );
    }

    renderLockActions() {
        const { locked, handleModalSplitTable, id } = this.props;

        // locked state
        if (locked) {
            return (
                <Fragment>
                    <div className="table-item__actions-locked">
                        <p className="table-item__actions-locked-info">
                            <Svg icon="locked" />
                            Table Locked
                        </p>
                        <div className="table-item__actions-locked-buttons">
                            <Button
                                onClick={() => this.lockTable(false)}
                                label={
                                    <FormattedMessage
                                        id="TableItem.Unlock"
                                        defaultMessage="Unlock"
                                    />
                                }
                                variation="tertiary"
                                size="small"
                            />
                        </div>
                    </div>
                    <div className="table-item__actions-split">
                        <Button
                            onClick={() => handleModalSplitTable(id)}
                            label={
                                <FormattedMessage
                                    id="TableItem.Split"
                                    defaultMessage="Split Table"
                                />
                            }
                            variation="secondary"
                            size="small"
                            className="table-item__split"
                        />
                    </div>
                </Fragment>
            );
        }

        // unlocked state
        return (
            <Fragment>
                <div className="table-item__actions-unlocked">
                    <Button
                        onClick={() => this.lockTable(true)}
                        label={
                            <FormattedMessage
                                id="TableItem.LockTable"
                                defaultMessage="Lock Table"
                            />
                        }
                        iconName="lock"
                        variation="secondary"
                        size="small"
                    />
                </div>
                <div className="table-item__actions-split">
                    <Button
                        onClick={() => handleModalSplitTable(id)}
                        label={
                            <FormattedMessage id="TableItem.Split" defaultMessage="Split Table" />
                        }
                        variation="secondary"
                        size="small"
                        className="table-item__split"
                    />
                </div>
            </Fragment>
        );
    }

    renderAlert() {
        const { occupiedSeats, max_seats: maxSeats, isPrivateMixed } = this.props;

        if (occupiedSeats > maxSeats || isPrivateMixed) {
            let message = '';
            if (occupiedSeats > maxSeats && isPrivateMixed) {
                message = this.notificationMessages.mixedAndOverOccupied;
            } else if (occupiedSeats > maxSeats) {
                message = this.notificationMessages.overOccupied;
            } else {
                message = this.notificationMessages.mixedPrivate;
            }

            return (
                <div className="table-item__alert">
                    <Tooltip content={message} direction="up">
                        <Svg icon="notificationError" />
                    </Tooltip>
                </div>
            );
        }

        return null;
    }

    render() {
        const {
            id,
            occupiedSeats,
            table_number: tableNumber,
            max_seats: maxSeats,
            min_seats: minSeats,
            groupList,
            isPrivate,
            locked,
            style
        } = this.props;

        const groupListCopy = [...groupList];

        // split groups list in chunks of three
        const groupListChunks = [];
        if (groupListCopy && groupListCopy.length) {
            while (groupListCopy.length) {
                groupListChunks.push(groupListCopy.splice(0, 3));
            }
        }

        return (
            <Droppable
                droppableId={`table-${id}`}
                isDropDisabled={locked}
                direction="horizontal"
                // mode="virtual"
                // getContainerForClone={() => document.getElementById('wrapper')}
                renderClone={(provided, snapshot, rubric) => (
                    <div
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        style={provided.draggableProps.style}
                    >
                        <GroupItem
                            snapshot={{ isDragging: true }}
                            {...groupList[rubric.source.index]}
                        />
                    </div>
                )}
            >
                {(provided, snapshot) => (
                    <div
                        className={`table-item${locked ? ' table-item--locked' : ''}${
                            snapshot.isDraggingOver ? ' table-item--is-dragging-over' : ''
                        }${
                            snapshot.draggingFromThisWith
                                ? ' table-item--is-dragging-from-this'
                                : ''
                        }`}
                        data-min-seats={minSeats}
                        data-id={id}
                        style={style}
                    >
                        {this.renderAlert()}

                        <div className="table-item__stats">
                            {occupiedSeats > maxSeats ? (
                                <Tooltip content="over-occupied" direction="up">
                                    <div className="table-item__stats-seats table-item__stats-seats--over-occupied">
                                        {occupiedSeats}/{maxSeats}
                                    </div>
                                </Tooltip>
                            ) : (
                                <div
                                    className={`table-item__stats-seats${
                                        maxSeats - occupiedSeats === 0
                                            ? ' table-item__stats-seats--fully-occupied'
                                            : ''
                                    }`}
                                >
                                    {occupiedSeats}/{maxSeats}
                                </div>
                            )}
                            <div
                                className={`table-item__stats-type table-item__stats-type--${
                                    isPrivate ? 'private' : 'shared'
                                }`}
                            >
                                {isPrivate ? 'PRV' : 'SHR'}
                            </div>
                        </div>

                        <h3 className="table-item__name">{tableNumber}</h3>

                        <div
                            className="table-item__groups"
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            {groupListChunks.map(chunk =>
                                chunk.map(group => (
                                    <Draggable
                                        index={groupList.findIndex(item => group.id === item.id)}
                                        key={`table-group-${group.id}`}
                                        draggableId={`table-group-${group.id}`}
                                        isDragDisabled={locked}
                                    >
                                        {(dProvided, dSnapshot) => (
                                            <GroupItem
                                                provided={dProvided}
                                                snapshot={dSnapshot}
                                                style={dProvided.draggableProps.style}
                                                tableLocked={locked}
                                                displayPrivateTag
                                                {...group}
                                            />
                                        )}
                                    </Draggable>
                                ))
                            )}
                            {provided.placeholder}
                        </div>

                        <div className="table-item__actions" data-locked={locked ? '1' : '0'}>
                            {this.renderActions()}
                        </div>
                    </div>
                )}
            </Droppable>
        );
    }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchTables: params => {
        // Getting included data from API by setting defaultParams
        const defaultParams = {
            pagination: false,
            include: 'room,groups,event,events',
            'order_by[table_number]': 'ASC'
        };
        const listingParams = Object.assign({}, defaultParams, params);
        dispatch({
            type: listingActions.START_FETCHING_LISTING,
            params: listingParams,
            entity: 'TABLES_DND',
            endpoint: 'api/tables'
        });
    },
    updateTable: formData => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: tablesActions.REQUEST_UPDATE_SINGLE_TABLE_DND,
                id: ownProps.id,
                formData
            });
        }).catch(error => ({ error }));
    },
    handleModalSplitTable: index =>
        dispatch({
            type: tablesActions.TOGGLE_TABLE_MODAL_SPLIT_TABLE,
            modalIndex: index
        }),
    handleModalTableNotes: index =>
        dispatch({
            type: tablesActions.TOGGLE_TABLE_MODAL_NOTES,
            modalIndex: index
        })
});

TableItem.defaultProps = {
    id: null,
    table_number: null,
    note: null,
    occupiedSeats: 0,
    max_seats: null,
    min_seats: null,
    locked: false,
    isPrivate: false,
    isPrivateMixed: false,
    groupList: null,
    style: null,
    eventId: null,
    fetchTables: () => {},
    updateTable: () => {},
    handleModalSplitTable: () => {},
    handleModalTableNotes: () => {}
};

TableItem.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    note: PropTypes.string,
    table_number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    occupiedSeats: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    max_seats: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    min_seats: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    locked: PropTypes.bool,
    isPrivate: PropTypes.bool,
    isPrivateMixed: PropTypes.bool,
    groupList: PropTypes.oneOfType([PropTypes.array]),
    style: PropTypes.oneOfType([PropTypes.object]),
    eventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    fetchTables: PropTypes.func,
    updateTable: PropTypes.func,
    handleModalSplitTable: PropTypes.func,
    handleModalTableNotes: PropTypes.func
};

export default connect(
    null,
    mapDispatchToProps
)(TableItem);
