import { takeLatest, put, call, select } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';

import { actions as rolesActions } from 'erpcore/screens/Settings/screens/Roles/Roles.reducer';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import dto from 'erpcore/utils/dto';
import { getQueryParams } from 'erpcore/components/Listing/Listing.selectors';

/**
 * Create Role
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* createRole({ promise, formData }) {
    try {
        const createRoleAPI = yield restClient.post('api/roles', formData);
        yield put({
            type: rolesActions.CREATE_ROLE_SUCCESFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createRoleAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: rolesActions.CREATE_ROLE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch Role
 * @param  {Object} promise
 * @return {string} id Role id
 */
export function* fetchRole({ promise, id }) {
    try {
        const fetchRoleAPI = yield restClient.get(`api/roles/${id}`, {
            params: { include: 'permissions' }
        });
        yield put({
            type: rolesActions.FETCHING_ROLE_SUCCESSFUL
        });

        yield put({
            type: rolesActions.STORE_ROLE_DATA,
            id,
            response: dto(fetchRoleAPI?.data)
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: rolesActions.FETCHING_ROLE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update Role data
 * @param  {Object} id id of Role
 * @return {Object} Response from API
 */
export function* updateRole({ promise, id, formData }) {
    try {
        const updateRoleAPI = yield restClient.patch(
            `api/roles/${id}?include=permissions`,
            formData
        );
        yield put({
            type: rolesActions.UPDATE_ROLE_SUCCESSFUL
        });
        yield put({
            type: rolesActions.STORE_ROLE_DATA,
            id,
            response: dto(updateRoleAPI?.data)
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: (updateRoleAPI?.data?.code && updateRoleAPI?.data) || {
                code: 'role.itemSuccessfulyUpdated'
            }
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: rolesActions.UPDATE_ROLE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Delete Role
 * @param  {Object} roleIri iri of Permission
 * @return {Object} Response from API
 */
export function* deleteRole({ promise, roleIri }) {
    try {
        const deleteRoleAPI = yield restClient.delete(roleIri);
        yield put({
            type: rolesActions.DELETE_ROLE_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: (deleteRoleAPI?.data?.code && deleteRoleAPI?.data) || {
                code: 'role.itemSuccessfulyRemoved'
            }
        });

        const params = yield select(getQueryParams, { name: 'roles' });

        yield put({
            promise,
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: 'ROLES',
            name: 'roles',
            endpoint: 'api/roles'
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: rolesActions.DELETE_ROLE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
const rolesSaga = [
    takeLatest(rolesActions.START_CREATE_ROLE, createRole),
    takeLatest(rolesActions.START_FETCHING_ROLE, fetchRole),
    takeLatest(rolesActions.START_UPDATE_ROLE, updateRole),
    takeLatest(rolesActions.START_DELETE_ROLE, deleteRole)
];

export default rolesSaga;
