import { takeLatest, put, call, select } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import { getListingUrlQueryParams } from 'erputils/RouterManager/RouterManager.selectors';
import { parseParamsForApi } from 'erputils/utils';
import { actions as listingActions } from 'erpcomponents/Listing/Listing.reducer';
import { actions as notificationManagerActions } from 'erputils/NotificationManager/NotificationManager.reducer';
import dto from 'erputils/dto';
import { actions as usersActions } from './Users.reducer';

/**
 * Create User
 * @param  {Object} promise
 * @return {Object} formData
 */

export function* createUser({ promise, formData }) {
    try {
        const createUserAPI = yield restClient.post(`invitation`, formData);
        yield put({
            type: usersActions.CREATE_USER_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: createUserAPI.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: usersActions.CREATE_USER_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Fetch User
 * @param  {Object} promise
 * @return {string} id User id
 */
export function* fetchUser({ promise, id, params }) {
    try {
        const fetchUserAPI = yield restClient.get(`api/users/${id}`, { params });
        yield put({
            type: usersActions.FETCH_SINGLE_USER_SUCCESSFUL
        });
        yield put({
            type: usersActions.STORE_SINGLE_USER_DATA,
            id,
            response: dto(fetchUserAPI.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: usersActions.FETCH_SINGLE_USER_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Update User single data
 * @param  {Object} id ID of an User
 * @return {Object} Response from API
 */
export function* updateSingleUser({ promise, formData, id }) {
    try {
        const updateSingleUserAPI = yield restClient.put(`api/users/${id}`, formData);
        yield put({
            type: usersActions.UPDATE_SINGLE_USER_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateSingleUserAPI.data
        });
        yield put({
            type: usersActions.STORE_SINGLE_USER_DATA,
            id,
            response: dto(updateSingleUserAPI.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: usersActions.UPDATE_SINGLE_USER_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Delete Single User
 * @param  {Object} id ID of an User
 * @return {Object} Response from API
 */
export function* deleteSingleUser({ promise, id }) {
    try {
        // Merge default with current params for listing refresh purpose
        const params = Object.assign(
            {},
            { include: 'city' },
            yield select(getListingUrlQueryParams)
        );

        const deleteSingleUserAPI = yield restClient.delete(`api/users/${id}`);
        yield put({
            type: usersActions.DELETE_SINGLE_USER_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: deleteSingleUserAPI.data
        });
        yield put({
            type: listingActions.START_FETCHING_LISTING,
            entity: 'USERS',
            endpoint: 'api/users',
            params: parseParamsForApi(params)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: usersActions.DELETE_SINGLE_USER_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}
/**
 * Delete Single User Invitation
 * @param  {Object} id ID of an User Invitation
 * @return {Object} Response from API
 */
export function* deleteSingleUserInvitation({ promise, token }) {
    try {
        // Merge default with current params for listing refresh purpose
        const params = Object.assign({}, { include: '' }, yield select(getListingUrlQueryParams));

        const deleteSingleUserInvitationAPI = yield restClient.delete(`invitation/${token}`);
        yield put({
            type: usersActions.DELETE_SINGLE_USER_INVITATION_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: deleteSingleUserInvitationAPI.data
        });
        yield put({
            type: listingActions.START_FETCHING_LISTING,
            entity: 'USER_INVITES',
            endpoint: 'api/invitations',
            params: parseParamsForApi(params)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: usersActions.DELETE_SINGLE_USER_INVITATION_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}
/**
 * Resend Single User Invitation
 * @param  {Object} id ID of an User Invitation
 * @return {Object} Response from API
 */
export function* resendSingleUserInvitation({ promise, token }) {
    try {
        // Merge default with current params for listing refresh purpose

        const resendSingleUserInvitationAPI = yield restClient.put(`invitation/${token}/resend`);
        yield put({
            type: usersActions.RESEND_SINGLE_USER_INVITATION_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: resendSingleUserInvitationAPI.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: usersActions.RESEND_SINGLE_USER_INVITATION_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Change Password Single User
 * @param  {Object} id ID of an User
 * @return {Object} Response from API
 */
export function* changePasswordSingleUser({ promise, formData, id }) {
    try {
        // Merge default with current params for listing refresh purpose
        const params = Object.assign(
            {},
            { include: 'city' },
            yield select(getListingUrlQueryParams)
        );

        const changePasswordSingleUserAPI = yield restClient.patch(
            `/api/users/${id}/password`,
            formData
        );
        yield put({
            type: usersActions.CHANGE_PASSWORD_SINGLE_USER_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: changePasswordSingleUserAPI.data
        });
        yield put({
            type: listingActions.START_FETCHING_LISTING,
            entity: 'USERS',
            endpoint: 'api/users',
            params: parseParamsForApi(params)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: usersActions.CHANGE_PASSWORD_SINGLE_USER_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Register action to watcher
 */
export const usersSaga = [
    takeLatest(usersActions.START_CREATE_USER, createUser),
    takeLatest(usersActions.START_FETCHING_SINGLE_USER, fetchUser),
    takeLatest(usersActions.START_UPDATE_SINGLE_USER, updateSingleUser),
    takeLatest(usersActions.START_DELETE_SINGLE_USER, deleteSingleUser),
    takeLatest(usersActions.START_DELETE_SINGLE_USER_INVITATION, deleteSingleUserInvitation),
    takeLatest(usersActions.START_CHANGE_PASSWORD_SINGLE_USER, changePasswordSingleUser),
    takeLatest(usersActions.START_RESEND_SINGLE_USER_INVITATION, resendSingleUserInvitation)
];
