// TODO: location of this file is not yet defined
import { takeLatest, delay, put, call, select, fork, take } from 'redux-saga/effects';
import { channel } from 'redux-saga';
import restClient from 'erpcore/api/restClient';
import { getQueryParams } from 'erpcore/components/Listing/Listing.selectors';
import { actions as listingActions } from 'erpcomponents/Listing/Listing.reducer';
import { actions as notificationManagerActions } from 'erputils/NotificationManager/NotificationManager.reducer';
import dto from 'erputils/dto';
import { actions as ticketTypesActions } from './TicketTypes.reducer';

/**
 * Create ticketType
 * @param  promise {Object}
 * @param  formData {Object}
 * @return {void}
 */
export function* createTicketType({ promise, formData }) {
    try {
        // Create ticketType
        const createTicketTypeAPI = yield restClient.post(`api/ticket-types`, formData);
        yield put({
            type: ticketTypesActions.CREATE_TICKET_TYPE_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: dto(createTicketTypeAPI.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: ticketTypesActions.CREATE_TICKET_TYPE_FAILED,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Fetch ticketType
 * @param promise {Object}
 * @param id {number} ticketType id
 * @param params {Object}
 * @return {void}
 */
export function* fetchTicketType({ promise, id, params }) {
    try {
        const fetchTicketTypeAPI = yield restClient.get(`api/ticket-types/${id}`, { params });
        yield put({
            type: ticketTypesActions.FETCH_SINGLE_TICKET_TYPE_SUCCESSFUL
        });
        yield put({
            type: ticketTypesActions.STORE_SINGLE_TICKET_TYPE_DATA,
            id,
            response: dto(fetchTicketTypeAPI.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: ticketTypesActions.FETCH_SINGLE_TICKET_TYPE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Update ticketType single data
 * @param  promise {Object}
 * @param  formData {Object}
 * @param  id {number} ticketType id
 * @param  debounceDelay {number} Number of milliseconds to delay execution
 * @return {void}
 */
export function* updateSingleTicketType({ promise, formData, id, debounceDelay = 0 }) {
    yield delay(debounceDelay);
    yield put({
        type: ticketTypesActions.START_UPDATE_SINGLE_TICKET_TYPE
    });
    try {
        const updateSingleTicketTypeAPI = yield restClient.put(`api/ticket-types/${id}`, formData);
        yield put({
            type: ticketTypesActions.UPDATE_SINGLE_TICKET_TYPE_SUCCESSFUL
        });
        yield put({
            type: ticketTypesActions.STORE_SINGLE_TICKET_TYPE_DATA,
            id,
            response: dto(updateSingleTicketTypeAPI.data)
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateSingleTicketTypeAPI.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: ticketTypesActions.UPDATE_SINGLE_TICKET_TYPE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Delete Single ticketType
 * @param  id {number} ticketType id
 * @param  promise {Object}
 * @return {void}
 */
export function* deleteSingleTicketType({ promise, id }) {
    try {
        const deleteSingleTicketTypeAPI = yield restClient.delete(`api/ticket-types/${id}`);
        yield put({
            type: ticketTypesActions.DELETE_SINGLE_TICKET_TYPE_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteSingleTicketTypeAPI.data
        });
        const params = yield select(getQueryParams, { name: 'ticketTypes' });
        yield put({
            type: listingActions.START_FETCHING_LISTING,
            entity: 'TICKET_TYPES',
            name: 'ticketTypes',
            endpoint: 'api/ticket-types',
            params
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: ticketTypesActions.DELETE_SINGLE_TICKET_TYPE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Sort multiple ticketTypes
 * @param  promise {Object}
 * @param  formData {Object} Should contain property 'positions' with array of ticketType positions, Example `positions: [{position: 1, object_id: 6}, {...}, ...]`
 * @param  debounceDelay {number} Number of milliseconds to delay execution
 * @return {void}
 */
export function* sortTicketTypes({ promise, formData, debounceDelay = 0 }) {
    yield delay(debounceDelay);
    yield put({
        type: ticketTypesActions.START_SORT_TICKET_TYPES
    });
    try {
        const updateSingleTicketTypeAPI = yield restClient.post(`api/ticket-types/sort`, formData);
        yield put({
            type: ticketTypesActions.SORT_SUCCESSFUL_TICKET_TYPES
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateSingleTicketTypeAPI.data
                ? updateSingleTicketTypeAPI.data
                : { code: 'tickettype.sortSuccessfulyUpdated' }
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: ticketTypesActions.SORT_FAILED_TICKET_TYPES
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response:
                error.response && error.response.data ? error.response.data : { code: 'unknown' }
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Bulk Actions - Merge ticket types
 * @param  {Object} iris of the tickettypes
 * @return {Object} Response from API
 */
export function* mergeTicketTypes({ promise, formData }) {
    try {
        const mergeGroupsAPI = yield restClient.post('api/ticket-type-merge-requests', formData);
        yield put({
            type: ticketTypesActions.MERGE_TICKET_TYPES_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: mergeGroupsAPI.data
        });
        // yield put({
        //     type: listingActions.START_FETCHING_LISTING,
        //     entity: 'GROUPS',
        //     endpoint: 'api/groups',
        //     params: parseParamsForApi(params)
        // });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: ticketTypesActions.MERGE_TICKET_TYPES_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

function takeLatestPerProps(propsOrSelector, pattern, worker, ...args) {
    // Not a generator
    return fork(function* generator() {
        // Fork a generator here to make it work like takeLatest
        const channelsMap = {};
        while (true) {
            const action = yield take(pattern); // yield necessary here
            const propsValue =
                typeof propsOrSelector === 'function'
                    ? propsOrSelector(action)
                    : action[propsOrSelector];
            if (!channelsMap[propsValue]) {
                channelsMap[propsValue] = channel();
                yield takeLatest(channelsMap[propsValue], worker, ...args);
            }
            yield put(channelsMap[propsValue], action);
        }
    });
}

/**
 * Register action to watcher
 */
export const ticketTypesSaga = [
    takeLatest(ticketTypesActions.START_CREATE_TICKET_TYPE, createTicketType),
    takeLatest(ticketTypesActions.START_FETCHING_SINGLE_TICKET_TYPE, fetchTicketType),
    takeLatestPerProps(
        'id',
        ticketTypesActions.REQUEST_UPDATE_SINGLE_TICKET_TYPE,
        updateSingleTicketType
    ),
    takeLatest(ticketTypesActions.START_DELETE_SINGLE_TICKET_TYPE, deleteSingleTicketType),
    takeLatest(ticketTypesActions.REQUEST_SORT_TICKET_TYPES, sortTicketTypes),
    takeLatest(ticketTypesActions.START_MERGE_TICKET_TYPES, mergeTicketTypes)
];
