import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field, getFormValues, reduxForm } from 'redux-form';
import restClient from 'erpsrc/api/restClient';
import { FormattedMessage } from 'react-intl';
import enviromentVariables from 'erputils/enviromentVariables';
import Form, { Select, FileUploader, Text } from 'erpcomponents/Form';
import dto, { getIdFromIri } from 'erputils/dto';
import Button from 'erpcomponents/Button';
import valueValidation from 'erputils/valueValidation';
import LayoutManager from 'erputils/LayoutManager';
import PageHeader from 'erpcomponents/Layout/PageHeader';
import PageContent from 'erpcomponents/Layout/PageContent';
import { actions as eventsActions } from 'erpcore/screens/Events/Events.reducer';
import { actions as notificationManagerActions } from 'erputils/NotificationManager/NotificationManager.reducer';
import PageLoader from 'erpcomponents/PageLoader';
import { getEventData, getSingleEventFetching } from 'erpsrc/screens/Events/Events.selectors';

const tableCardTemplateDefault = 'A6';

const tableCardTemplateOptions = [
    {
        label: '4x6 inch',
        value: 'A6'
    },
    {
        label: '8.5x11 inch',
        value: 'Letter'
    },
    {
        label: '2x3.5 inch',
        value: 'A4'
    }
];

class TableCardPrinting extends React.Component {
    constructor(props) {
        super(props);

        this.printContent = React.createRef();

        this.state = {
            eventDropdownOptions: [],
            eventDropdownOptionsFetching: false,
            readyForUpload: false,
            pdfFetchingInProgress: false
        };

        this.fileUploaderRef = React.createRef();
        this.fileUploaderSubmitButtonRef = React.createRef();
    }

    componentDidMount() {
        const { match } = this.props;
        const { eventId } = match.params;

        this.setEventDropdownOptions();

        if (eventId) {
            const { fetchEventData } = this.props;
            fetchEventData(eventId);
        }
    }

    componentWillReceiveProps(nextProps) {
        const { match } = this.props;
        const { eventId } = match.params;
        const { match: nextMatch } = nextProps;
        const { eventId: nextEventId } = nextMatch.params;

        if (nextEventId && nextEventId !== eventId) {
            const { fetchEventData } = this.props;
            fetchEventData(nextEventId);
        }
    }

    componentDidUpdate() {
        if (
            this.fileUploaderRef.current &&
            this.fileUploaderRef.current.uploader &&
            this.fileUploaderRef.current.uploader.methods &&
            this.fileUploaderRef.current.uploader.methods.setEndpoint !== undefined &&
            typeof this.fileUploaderRef.current.uploader.methods.setEndpoint === 'function'
        ) {
            const eventIri = this.getCurrentEventIri();
            const tableCardTemplate = this.getSelectedTableCardTemplate();
            const { formValues } = this.props;
            this.fileUploaderRef.current.uploader.methods.setEndpoint(
                `${enviromentVariables.REST_API}/api/events/${getIdFromIri(
                    eventIri
                )}/print-from-csv/${tableCardTemplate}?logo=${
                    formValues && formValues.logo ? formValues.logo : 'logo.png'
                }`
            );
        }
    }

    setEventDropdownOptions() {
        this.setState({ eventDropdownOptionsFetching: true });
        this.fetchAllEvents()
            .then(events => {
                this.setState({
                    eventDropdownOptionsFetching: false,
                    eventDropdownOptions: events.map(event => {
                        return {
                            value: event.iri,
                            label: event.name
                        };
                    })
                });
            })
            .catch(() => {
                this.setState({
                    eventDropdownOptionsFetching: false
                });
            });
    }

    getCurrentEventIri() {
        const { match } = this.props;
        return match && match.params !== undefined && match.params && match.params.eventId
            ? `/api/events/${match.params.eventId}`
            : null;
    }

    getSelectedTableCardTemplate() {
        return this.getFormValue('tableCardTemplate') || tableCardTemplateDefault;
    }

    getFormValue(field = false) {
        const { formValues } = this.props;
        if (!formValues || !field || formValues[field] === undefined) return null;

        return formValues[field];
    }

    setUploaderSubmitButtonState() {
        this.setState({ readyForUpload: this.uploaderHasFiles() });
    }

    downloadPdf = (url = null, tableCardTemplate = this.getSelectedTableCardTemplate()) => {
        if (!url) return null;

        this.setState({ pdfFetchingInProgress: false });

        const { eventData } = this.props;
        const eventName = eventData && eventData.name;
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.download = `${eventName && eventName} - ${tableCardTemplate}.pdf`;

        link.click();
        window.URL.revokeObjectURL(url);

        return null;
    };

    tryParseJSON = jsonString => {
        try {
            const o = JSON.parse(jsonString);
            if (o && typeof o === 'object') {
                return o;
            }
        } catch (e) {
            return e;
        }

        return false;
    };

    fetchAllEvents() {
        return new Promise((resolve, reject) => {
            const params = {
                'filters[end_utc][upcoming]': true,
                'order_by[name]': 'ASC',
                pagination: false
            };
            restClient
                .get('/api/events', {
                    params
                })
                .then(response => {
                    if (response && response.data !== undefined && response.data) {
                        const events = dto(response.data);
                        if (events && events.data) {
                            return resolve(events.data);
                        }
                    }
                    return reject();
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    changeEventUrl(eventId = null) {
        if (eventId) {
            const { history } = this.props;
            history.push(`/table-card-printing/${eventId}`);
        }
    }

    submitCsv() {
        if (
            this.fileUploaderRef.current &&
            this.fileUploaderRef.current.state &&
            this.fileUploaderRef.current.state.submittedFiles.length &&
            this.fileUploaderRef.current.uploader &&
            this.fileUploaderRef.current.uploader.methods &&
            this.fileUploaderRef.current.uploader.methods.uploadStoredFiles !== undefined &&
            typeof this.fileUploaderRef.current.uploader.methods.uploadStoredFiles === 'function'
        ) {
            this.fileUploaderRef.current.uploader.methods.uploadStoredFiles();
        }

        this.setState({ pdfFetchingInProgress: true });

        return null;
    }

    uploaderHasFiles() {
        return !!(
            this.fileUploaderRef.current &&
            this.fileUploaderRef.current.state &&
            this.fileUploaderRef.current.state.submittedFiles.length
        );
    }

    render() {
        const rootURL = function getBaseUrl() {
            return window.location.protocol;
        };

        const { dispatch } = this.props;
        const {
            eventDropdownOptions,
            eventDropdownOptionsFetching,
            eventFetching,
            readyForUpload,
            pdfFetchingInProgress
        } = this.state;

        const eventIri = this.getCurrentEventIri();

        const tableCardTemplate = this.getSelectedTableCardTemplate();

        const venueNameFormValue = this.getFormValue('venueNameOverride');

        const cardWidthOverride = this.getFormValue('cardWidthOverride');

        const cardTitleOverride = this.getFormValue('cardTitleOverride');

        const cardLeftOverride = this.getFormValue('cardLeftOverride');

        const cardTopOverride = this.getFormValue('cardTopOverride');

        const eventNameOverride = this.getFormValue('eventNameOverride');

        const currentSelectedFormat = this.getFormValue('tableCardTemplate');

        const cardOffsetOverride = this.getFormValue('cardOffsetOverride');

        const cardHorizontalOffsetOverride = this.getFormValue('cardHorizontalOffsetOverride');

        const cardWidth2x35Override = this.getFormValue('cardWidth2x35Override');

        let params = {};

        if (venueNameFormValue !== null) {
            params = { ...params, venue_name: venueNameFormValue };
        }

        if (cardWidthOverride !== null) {
            params = { ...params, width: cardWidthOverride };
        }

        if (cardTitleOverride !== null) {
            params = { ...params, title: cardTitleOverride };
        }

        if (eventNameOverride !== null) {
            params = { ...params, event_name: eventNameOverride };
        }

        if (cardLeftOverride !== null) {
            params = { ...params, left: cardLeftOverride };
        }

        if (cardTopOverride !== null) {
            params = { ...params, top: cardTopOverride };
        }

        if (cardOffsetOverride !== null) {
            params = { ...params, offset: cardOffsetOverride };
        }

        if (cardHorizontalOffsetOverride !== null) {
            params = { ...params, horizontal_offset: cardHorizontalOffsetOverride };
        }

        if (cardWidth2x35Override !== null) {
            params = { ...params, width: cardWidth2x35Override };
        }

        return (
            <LayoutManager slot="main" className="main--narrow" layoutType="merge">
                <PageHeader
                    title={
                        <FormattedMessage
                            id="TableCardPrinting.title"
                            defaultMessage="Table Card Printing"
                        />
                    }
                />
                {(!!eventDropdownOptionsFetching || !!eventFetching || !!pdfFetchingInProgress) && (
                    <PageLoader />
                )}
                <PageContent>
                    <Form.Row>
                        <Form.Columns>
                            <Form.Column>
                                <Select
                                    name="event"
                                    id="event"
                                    input={{
                                        onChange: value => {
                                            this.changeEventUrl(getIdFromIri(value));
                                        },
                                        onBlur: () => {},
                                        value: eventIri || null
                                    }}
                                    fieldProps={{
                                        label: 'Choose Event',
                                        options: eventDropdownOptions,
                                        clearable: false
                                    }}
                                    fieldAttr={{
                                        required: true,
                                        disabled: eventDropdownOptionsFetching
                                    }}
                                />
                            </Form.Column>
                            <Form.Column>
                                <Field
                                    name="tableCardTemplate"
                                    id="table-card-template"
                                    fieldProps={{
                                        label: 'Table card template',
                                        options: tableCardTemplateOptions,
                                        clearable: false
                                    }}
                                    component={Select}
                                    validate={valueValidation([{ validator: 'required' }])}
                                    fieldAttr={{ required: true }}
                                />
                            </Form.Column>
                        </Form.Columns>
                    </Form.Row>
                    <Form.Row>
                        <Form.Column>
                            <Field
                                name="logo"
                                id="logo"
                                fieldProps={{
                                    label: 'Choose Logo',
                                    options: [
                                        {
                                            label: 'Balldrop',
                                            value: 'logo.png'
                                        },
                                        {
                                            label: '4th July - Square',
                                            value: 'logo_4th_july_squared.png'
                                        },
                                        {
                                            label: '4th July - Rounded',
                                            value: 'logo_4th_july.png'
                                        }
                                    ],
                                    clearable: false
                                }}
                                fieldAttr={{
                                    required: true
                                }}
                                component={Select}
                            />
                        </Form.Column>
                    </Form.Row>
                    <Form.Row>
                        <Field
                            name="venueNameOverride"
                            id="venue_name"
                            fieldProps={{
                                label: 'Venue name override',
                                clearable: true
                            }}
                            component={Text}
                        />
                    </Form.Row>
                    {currentSelectedFormat === 'Letter' && (
                        <Form.Row>
                            <Field
                                name="eventNameOverride"
                                id="event"
                                fieldProps={{
                                    label: 'Event name override',
                                    clearable: true
                                }}
                                component={Text}
                            />
                        </Form.Row>
                    )}
                    {currentSelectedFormat === 'A6' && (
                        <React.Fragment>
                            <Form.Row>
                                <Field
                                    name="cardWidthOverride"
                                    id="width"
                                    fieldProps={{
                                        label: 'Card Width Override',
                                        clearable: true
                                    }}
                                    validate={valueValidation([
                                        {
                                            validator: 'numericality',
                                            args: { '<': 101, msg: 'Max. value is 100' }
                                        }
                                    ])}
                                    component={Text}
                                />
                            </Form.Row>
                            <Form.Row>
                                <Field
                                    name="cardTitleOverride"
                                    id="title"
                                    fieldProps={{
                                        label: 'Card Title Override',
                                        clearable: true
                                    }}
                                    component={Text}
                                />
                            </Form.Row>
                        </React.Fragment>
                    )}
                    {currentSelectedFormat === 'A4' && (
                        <React.Fragment>
                            <Form.Row>
                                <Field
                                    name="cardLeftOverride"
                                    id="left"
                                    fieldProps={{
                                        label: 'Card Left Margin Override',
                                        clearable: true
                                    }}
                                    validate={valueValidation([
                                        {
                                            validator: 'numericality',
                                            args: { '<': 101, msg: 'Max. value is 100' }
                                        }
                                    ])}
                                    component={Text}
                                />
                            </Form.Row>
                            <Form.Row>
                                <Field
                                    name="cardTopOverride"
                                    id="top"
                                    fieldProps={{
                                        label: 'Card Top Margin Override',
                                        clearable: true
                                    }}
                                    component={Text}
                                />
                            </Form.Row>
                            <Form.Row>
                                <Field
                                    name="cardOffsetOverride"
                                    id="offset"
                                    fieldProps={{
                                        label: 'Card Offset Override',
                                        clearable: true
                                    }}
                                    component={Text}
                                />
                            </Form.Row>
                            <Form.Row>
                                <Field
                                    name="cardWidth2x35Override"
                                    id="width2x35"
                                    fieldProps={{
                                        label: 'Card Width Override',
                                        clearable: true
                                    }}
                                    component={Text}
                                />
                            </Form.Row>
                            <Form.Row>
                                <Field
                                    name="cardHorizontalOffsetOverride"
                                    id="horizontalOffset"
                                    fieldProps={{
                                        label: 'Card Horizontal Offset Override',
                                        clearable: true
                                    }}
                                    component={Text}
                                />
                            </Form.Row>
                        </React.Fragment>
                    )}
                    <Form.Row>
                        <a href={`${rootURL()}/pdf_sample/SampleCSV.csv`} download="SampleCSV.csv">
                            Download sample CSV
                        </a>
                    </Form.Row>
                    {eventIri && (
                        <Form.Row>
                            <FileUploader
                                ref={this.fileUploaderRef}
                                endpoint={`${
                                    enviromentVariables.REST_API
                                }/api/events/${getIdFromIri(
                                    eventIri
                                )}/print-from-csv/${tableCardTemplate}`}
                                autoUpload={false}
                                params={params}
                                hideUploadedFileList
                                hideSubmittedFileTitle
                                fieldProps={{
                                    itemLimit: 1,
                                    allowedExtensions: ['csv'],
                                    onSubmitted: () => {
                                        this.setUploaderSubmitButtonState();
                                    },
                                    onStatusChange: () => {
                                        this.setUploaderSubmitButtonState();
                                    },
                                    onUpload: () => {
                                        this.setState({ pdfFetchingInProgress: true });
                                        this.setUploaderSubmitButtonState();
                                    },
                                    onComplete: responseJSON => {
                                        if (
                                            responseJSON &&
                                            responseJSON.detail &&
                                            responseJSON.detail.path
                                        ) {
                                            this.downloadPdf(
                                                responseJSON.detail.path,
                                                tableCardTemplate
                                            );
                                        }
                                        this.setState({ pdfFetchingInProgress: false });
                                        this.setUploaderSubmitButtonState();
                                    },
                                    onAllComplete: () => {
                                        this.setUploaderSubmitButtonState();
                                    },
                                    onError: ({ errorReason = '', xhrData }) => {
                                        let responseData = null;

                                        this.setUploaderSubmitButtonState();

                                        if (xhrData && xhrData.response) {
                                            if (
                                                typeof xhrData.response === 'string' &&
                                                this.tryParseJSON(xhrData.response)
                                            ) {
                                                responseData = {
                                                    ...this.tryParseJSON(xhrData.response)
                                                };
                                            } else {
                                                responseData = xhrData.response;
                                            }
                                        }

                                        if (responseData && responseData.code !== undefined) {
                                            return dispatch({
                                                type:
                                                    notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                                                response: {
                                                    code: responseData.code
                                                }
                                            });
                                        }

                                        return dispatch({
                                            type:
                                                notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                                            response: {
                                                code: 'errorCustom',
                                                data: { customText: errorReason }
                                            }
                                        });
                                    }
                                }}
                            />
                        </Form.Row>
                    )}
                    <Form.Row>
                        {/*
                        TODO: Hook onto one of the fineuploder methods to determine if the files are ready to be uploaded, and then enable button
                        */}
                        <Button
                            onClick={() => this.submitCsv()}
                            disabled={!eventIri || !readyForUpload}
                            label={
                                <FormattedMessage
                                    id="TableCardPrinting.generatePdfButton"
                                    defaultMessage="Generate PDF"
                                />
                            }
                        />
                    </Form.Row>
                </PageContent>
            </LayoutManager>
        );
    }
}

TableCardPrinting.defaultProps = {
    history: null,
    formValues: null,
    eventData: null,
    fetchEventData: () => {}
};

TableCardPrinting.propTypes = {
    dispatch: PropTypes.isRequired,
    history: PropTypes.any, // eslint-disable-line react/forbid-prop-types
    match: PropTypes.oneOfType([PropTypes.object]).isRequired,
    formValues: PropTypes.oneOfType([PropTypes.object]),
    eventData: PropTypes.oneOfType([PropTypes.object]),
    fetchEventData: PropTypes.func
};

TableCardPrinting = reduxForm({
    form: 'TableCardPrintingForm',
    pure: true,
    enableReinitialize: false,
    destroyOnUnmount: false
})(TableCardPrinting);

const mapStateToProps = (state, ownProps) => ({
    initialValues: {
        logo: 'logo.png'
    },
    formValues: getFormValues('TableCardPrintingForm')(state),
    eventData: getEventData(state, ownProps.match.params.eventId),
    eventFetching: getSingleEventFetching(state)
});

const mapDispatchToProps = dispatch => ({
    fetchEventData: id => {
        // Getting included data from API by setting params
        const params = {
            include: 'location'
        };
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: eventsActions.START_FETCHING_SINGLE_EVENT,
                id,
                params
            });
        }).catch(error => ({ error }));
    }
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(TableCardPrinting)
);
