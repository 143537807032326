import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import reduxFormErrorMapper from 'erputils/reduxFormErrorMapper';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import Form, { Text, Select } from 'erpcore/components/Form';
import valueValidation from 'erputils/valueValidation';
import Modal from 'erpcomponents/Modal';
import Button from 'erpcomponents/Button';
import { actions as ticketTypesActions } from 'erpcore/screens/Events/screens/EventEdit/TicketTypes.reducer';
import { actions as eventsActions } from 'erpcore/screens/Events/Events.reducer';

class MergeTicketTypesModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            opened: true
        };
        this.handleModal = this.handleModal.bind(this);
    }

    onSubmit = formData => {
        const { dispatch, initialValues, fetchEventData, match } = this.props;
        const data = {
            primary_ticket_type:
                initialValues.primary_ticket_type && initialValues.primary_ticket_type.iri,
            ticket_type_to_merge: formData.ticket_type_to_merge
        };
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: ticketTypesActions.START_MERGE_TICKET_TYPES,
                formData: data
            });
        })
            .then(() => {
                fetchEventData(match.params.id);
                this.handleModal();
            })
            .catch(error => {
                throw new SubmissionError(reduxFormErrorMapper(error));
            });
    };

    handleModal() {
        const { opened: isOpen } = this.state;
        this.setState({ opened: !isOpen });
    }

    render() {
        const { handleSubmit, submitting, pristine, invalid, initialValues } = this.props;
        const { opened } = this.state;
        const ticketTypes =
            initialValues.ticket_types &&
            initialValues.ticket_types
                .map(ticket => {
                    return { label: ticket.name, value: ticket.iri };
                })
                .filter(t => t.value !== initialValues.primary_ticket_type.iri);

        return (
            <Modal
                variation="small"
                title="Merge Ticket Types"
                opened={opened}
                onClose={() => this.handleModal()}
            >
                <p>You are about to merge Ticket Types. </p>
                <Form onSubmit={handleSubmit(this.onSubmit)}>
                    <Form.Column>
                        <Form.Row>
                            <Field
                                name="primary_ticket_type_name"
                                id="primary_ticket_type_name"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="Events.MergeTT.PrimaryTT"
                                            defaultMessage="Primary Ticket Type"
                                        />
                                    ),
                                    clearable: true
                                }}
                                component={Text}
                                validate={valueValidation([{ validator: 'required' }])}
                                fieldAttr={{ disabled: true }}
                            />
                        </Form.Row>
                        <Form.Row>
                            <Field
                                name="ticket_type_to_merge"
                                id="ticket_type_to_merge"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="Events.TTToMerge"
                                            defaultMessage="Ticket Type To Merge"
                                        />
                                    ),
                                    options: ticketTypes
                                }}
                                component={Select}
                                validate={valueValidation([{ validator: 'required' }])}
                            />
                        </Form.Row>
                        <Form.Columns>
                            <Form.Column autoWidth>
                                <Button
                                    submit
                                    disabled={pristine || invalid}
                                    loading={submitting}
                                    label={
                                        <FormattedMessage
                                            id="Events.MergeTT"
                                            defaultMessage="Merge"
                                        />
                                    }
                                />
                            </Form.Column>
                            <Form.Column autoWidth>
                                <Button
                                    onClick={() => this.handleModal()}
                                    variation="secondary"
                                    label={
                                        <FormattedMessage
                                            id="Events.Cancel"
                                            defaultMessage="Cancel"
                                        />
                                    }
                                />
                            </Form.Column>
                        </Form.Columns>
                    </Form.Column>
                </Form>
            </Modal>
        );
    }
}

MergeTicketTypesModal.defaultProps = {
    submitting: false,
    pristine: false,
    invalid: false,
    fetchEventData: () => {},
    initialValues: null
    // member: ''
};

MergeTicketTypesModal.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    fetchEventData: PropTypes.func,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    initialValues: PropTypes.oneOfType([PropTypes.object]),
    match: PropTypes.oneOfType([PropTypes.object]).isRequired
    // member: PropTypes.string
};

MergeTicketTypesModal = reduxForm({
    form: 'MergeTicketTypesModalForm',
    enableReinitialize: true
})(MergeTicketTypesModal);

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchEventData: () => {
        // Getting included data from API by setting params
        const params = {
            include: 'ticketTypes'
        };
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: eventsActions.START_FETCHING_SINGLE_EVENT,
                id: ownProps.match.params.id,
                params
            });
        }).catch(error => ({ error }));
    }
});

export default withRouter(
    connect(
        null,
        mapDispatchToProps
    )(MergeTicketTypesModal)
);
