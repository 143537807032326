// TODO: location of this file is not yet defined

import { takeLatest, delay, put, call, select, fork, take } from 'redux-saga/effects';
import { channel } from 'redux-saga';
import restClient from 'erpcore/api/restClient';
import { getListingUrlQueryParams } from 'erputils/RouterManager/RouterManager.selectors';
import { parseParamsForApi } from 'erputils/utils';
import { actions as listingActions } from 'erpcomponents/Listing/Listing.reducer';
import { actions as notificationManagerActions } from 'erputils/NotificationManager/NotificationManager.reducer';
import dto from 'erputils/dto';
import { actions as tablesActions } from './Tables.reducer';

/**
 * Create Table
 * @param  promise {Object}
 * @param  formData {Object}
 * @return {void}
 */
export function* createTable({ promise, formData }) {
    try {
        // Create Table
        const createTableAPI = yield restClient.post(`api/tables`, formData);
        yield put({
            type: tablesActions.CREATE_TABLE_DND_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: dto(createTableAPI.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: tablesActions.CREATE_TABLE_DND_FAILED,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Fetch Table
 * @param promise {Object}
 * @param id {number} Table id
 * @param params {Object}
 * @return {void}
 */
export function* fetchTable({ promise, id, params }) {
    try {
        const fetchTableAPI = yield restClient.get(`api/tables/${id}`, { params });
        yield put({
            type: tablesActions.FETCH_SINGLE_TABLE_DND_SUCCESSFUL
        });
        yield put({
            type: tablesActions.STORE_SINGLE_TABLE_DND_DATA,
            id,
            response: dto(fetchTableAPI.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: tablesActions.FETCH_SINGLE_TABLE_DND_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Update Table single data
 * @param  promise {Object}
 * @param  formData {Object}
 * @param  id {number} Table id
 * @param  debounceDelay {number} Number of milliseconds to delay execution
 * @return {void}
 */
export function* updateSingleTable({ promise, formData, id, debounceDelay = 0 }) {
    yield delay(debounceDelay);
    yield put({
        type: tablesActions.START_UPDATE_SINGLE_TABLE_DND
    });
    try {
        const updateSingleTableAPI = yield restClient.put(`api/tables/${id}`, formData);
        yield put({
            type: tablesActions.UPDATE_SINGLE_TABLE_DND_SUCCESSFUL
        });
        yield put({
            type: tablesActions.STORE_SINGLE_TABLE_DND_DATA,
            id,
            response: dto(updateSingleTableAPI.data)
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateSingleTableAPI.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: tablesActions.UPDATE_SINGLE_TABLE_DND_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Delete Single Table
 * @param  id {number} Table id
 * @param  promise {Object}
 * @return {void}
 */
export function* deleteSingleTable({ promise, id }) {
    try {
        // Merge default with current params for listing refresh purpose
        const params = Object.assign(
            {},
            { include: 'location.city' },
            yield select(getListingUrlQueryParams)
        );

        const deleteSingleTableAPI = yield restClient.delete(`api/tables/${id}`);
        yield put({
            type: tablesActions.DELETE_SINGLE_TABLE_DND_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteSingleTableAPI.data
        });
        yield put({
            type: listingActions.START_FETCHING_LISTING,
            entity: 'TICKET_TYPES',
            endpoint: 'api/tables',
            params: parseParamsForApi(params)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: tablesActions.DELETE_SINGLE_TABLE_DND_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Sort multiple Tables
 * @param  promise {Object}
 * @param  formData {Object} Should contain property 'positions' with array of Table positions, Example `positions: [{position: 1, object_id: 6}, {...}, ...]`
 * @param  debounceDelay {number} Number of milliseconds to delay execution
 * @return {void}
 */
export function* sortTables({ promise, formData, debounceDelay = 0 }) {
    yield delay(debounceDelay);
    yield put({
        type: tablesActions.START_SORT_TABLES_DND
    });
    try {
        const updateSingleTableAPI = yield restClient.post(`api/tables/sort`, formData);
        yield put({
            type: tablesActions.SORT_SUCCESSFUL_TABLES_DND
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateSingleTableAPI.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: tablesActions.SORT_FAILED_TABLES_DND
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response:
                error.response && error.response.data ? error.response.data : { code: 'unknown' }
        });
        yield call(promise.reject, error.response.data);
    }
}

function takeLatestPerProps(propsOrSelector, pattern, worker, ...args) {
    // Not a generator
    return fork(function* generator() {
        // Fork a generator here to make it work like takeLatest
        const channelsMap = {};
        while (true) {
            const action = yield take(pattern); // yield necessary here
            const propsValue =
                typeof propsOrSelector === 'function'
                    ? propsOrSelector(action)
                    : action[propsOrSelector];
            if (!channelsMap[propsValue]) {
                channelsMap[propsValue] = channel();
                yield takeLatest(channelsMap[propsValue], worker, ...args);
            }
            yield put(channelsMap[propsValue], action);
        }
    });
}

/**
 * Export allotment CSV
 * @param  promise {Object}
 * @param  id {String}
 * @return {Response from API}
 */
export function* exportAllotmentCSV({ promise, id }) {
    try {
        // Create Table
        const exportAllotmentAPI = yield restClient.get(`api/tables/export-allotments/${id}/csv `);
        yield put({
            type: tablesActions.EXPORTING_ALLOTMENT_CSV_SUCCESSFUL
        });

        yield call(promise.resolve, exportAllotmentAPI.data);
    } catch (error) {
        yield put({
            type: tablesActions.EXPORTING_ALLOTMENT_CSV_FAILED,
            response: error.response
        });
        yield call(promise.reject, error.response);
    }
}

/**
 * Register action to watcher
 */
export const tablesSaga = [
    takeLatest(tablesActions.START_CREATE_TABLE_DND, createTable),
    takeLatest(tablesActions.START_FETCHING_SINGLE_TABLE_DND, fetchTable),
    takeLatestPerProps('id', tablesActions.REQUEST_UPDATE_SINGLE_TABLE_DND, updateSingleTable),
    takeLatest(tablesActions.START_DELETE_SINGLE_TABLE_DND, deleteSingleTable),
    takeLatest(tablesActions.REQUEST_SORT_TABLES_DND, sortTables),
    takeLatest(tablesActions.START_EXPORTING_ALLOTMENT_CSV, exportAllotmentCSV)
];
