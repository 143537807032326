import React from 'react';
import PropTypes from 'prop-types';
import Form, { Switch, Select, Text } from 'erpcomponents/Form';
import { Field } from 'redux-form';

class TableFilters extends React.Component {
    getAllTablesRoomsOptions() {
        const { tablesData } = this.props;

        const roomsOptions = [];

        if (tablesData && tablesData.length) {
            tablesData.forEach(table => {
                const { room } = table;
                if (room && room.iri !== undefined) {
                    if (
                        roomsOptions.findIndex(
                            roomsOptionsItem => room.iri === roomsOptionsItem.value
                        ) === -1
                    ) {
                        roomsOptions.push({
                            value: room.iri,
                            label: room.name ? room.name : room.iri
                        });
                    }
                }
            });
        }

        return roomsOptions;
    }

    render() {
        const { tablesData, disabled } = this.props;

        if (tablesData && tablesData.length) {
            return (
                <React.Fragment>
                    <h3 className="table-allotments__title">Tables</h3>
                    <div className="table-allotments__filters">
                        <Form.Columns breakOnMobile>
                            <Form.Column>
                                <Field
                                    name="filterTablesSearch"
                                    id="filter-tables-search"
                                    fieldProps={{
                                        label: 'Search Table',
                                        clearable: true
                                    }}
                                    fieldAttr={{
                                        disabled
                                    }}
                                    component={Text}
                                />
                            </Form.Column>
                            <Form.Column>
                                <Field
                                    name="filterTablesRoom"
                                    id="filter-tables-room"
                                    fieldProps={{
                                        options: this.getAllTablesRoomsOptions(),
                                        label: 'Location/Room',
                                        clearable: true
                                    }}
                                    fieldAttr={{
                                        disabled
                                    }}
                                    component={Select}
                                />
                            </Form.Column>
                            <Form.Column autoWidth>
                                <Field
                                    name="filterTablesSortBy"
                                    id="filter-tables-sort-by"
                                    fieldProps={{
                                        options: [
                                            {
                                                label: 'Default',
                                                value: 'default'
                                            },
                                            {
                                                label: 'Availability',
                                                value: 'availability'
                                            }
                                        ],
                                        label: 'Sort by',
                                        clearable: false
                                    }}
                                    fieldAttr={{
                                        disabled
                                    }}
                                    component={Select}
                                />
                            </Form.Column>
                            <Form.Column autoWidth>
                                <Field
                                    name="filterTablesAvailable"
                                    id="filter-tables-available"
                                    fieldProps={{
                                        on: {
                                            value: true,
                                            id: 'filter-tables-available-true'
                                        },
                                        off: {
                                            value: false,
                                            id: 'filter-tables-available-false'
                                        },
                                        label: 'Available'
                                    }}
                                    fieldAttr={{
                                        disabled
                                    }}
                                    component={Switch}
                                />
                            </Form.Column>
                            <Form.Column autoWidth>
                                <Field
                                    name="filterTablesShared"
                                    id="filter-tables-shared"
                                    fieldProps={{
                                        on: {
                                            value: true,
                                            id: 'filter-tables-shared-true'
                                        },
                                        off: {
                                            value: false,
                                            id: 'filter-tables-shared-false'
                                        },
                                        label: 'Shared'
                                    }}
                                    fieldAttr={{
                                        disabled
                                    }}
                                    component={Switch}
                                />
                            </Form.Column>
                        </Form.Columns>
                    </div>
                </React.Fragment>
            );
        }

        return null;
    }
}

TableFilters.defaultProps = {
    tablesData: null,
    disabled: false
};

TableFilters.propTypes = {
    tablesData: PropTypes.oneOfType([PropTypes.array]),
    disabled: PropTypes.bool
};

export default TableFilters;
