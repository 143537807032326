import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import LayoutManager from 'erputils/LayoutManager';
import PageHeader from 'erpcomponents/Layout/PageHeader';
import PageContent from 'erpcomponents/Layout/PageContent';
import ResellerInfo from 'erpcore/screens/Resellers/components/ResellerInfo';
import ResellerEditTabs from 'erpcore/screens/Resellers/components/ResellerEditTabs';
import PageLoader from 'erpcomponents/PageLoader';
import { actions as resellersActions } from 'erpcore/screens/Resellers/Resellers.reducer';
import {
    getResellerData,
    getSingleResellerFetching,
    getResellerEvents,
    getSingleResellerUpdating
} from 'erpcore/screens/Resellers/Resellers.selectors';
import { FieldArray, reduxForm } from 'redux-form';
import Form, { Repeater } from 'erpcore/components/Form';
import EventRepeaterItem from 'erpcore/screens/Resellers/screens/ResellersEdit/components/EventRepeaterItem';

class ResellerEvents extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        const { fetchResellerData, fetchResellerEvents } = this.props;
        fetchResellerData();
        fetchResellerEvents();
    }

    handleSortEnd = formData => {
        const { match, dispatch } = this.props;
        const value = formData.allData.map((record, newIndex) => {
            return {
                event: record.iri,
                position: newIndex
            };
        });

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: resellersActions.START_UPDATE_SINGLE_RESELLER_EVENTS,
                id: match.params.id,
                formData: { sorted_events: value }
            })
        );
    };

    render() {
        const {
            singleFetching,
            singleUpdating,
            fetchResellerData,
            resellerData,
            match: { params: id }
        } = this.props;

        const resellerHideEvents = resellerData && resellerData.reseller_hide_events;

        return (
            <LayoutManager slot="main" className="main--narrow" layoutType="merge">
                <PageHeader
                    title={
                        <FormattedMessage
                            id="ResellerEdit.Events.title"
                            defaultMessage="Edit Reseller"
                        />
                    }
                    backButtonLink="/resellers"
                />
                {singleFetching && <PageLoader />}
                <ResellerInfo fetchResellerData={fetchResellerData} />
                <ResellerEditTabs />

                <PageContent>
                    <Form>
                        <Form.Column>
                            <Form.SectionTitle>
                                <FormattedMessage
                                    id="Reseller.Events.AddRemoveEventsFromTheirMicrosite"
                                    defaultMessage="Add/Remove events from their microsite"
                                />
                            </Form.SectionTitle>
                            {resellerData && resellerData.id && (
                                <FieldArray
                                    name="list"
                                    component={Repeater}
                                    RepeatableItem={EventRepeaterItem}
                                    data={{
                                        hideEvents: resellerHideEvents,
                                        resellerId: id
                                    }}
                                    loading={singleUpdating}
                                    canAdd={false}
                                    canDelete={false}
                                    onSortStart={() => {}}
                                    onSortEnd={this.handleSortEnd}
                                />
                            )}
                        </Form.Column>
                    </Form>
                </PageContent>
            </LayoutManager>
        );
    }
}

ResellerEvents.defaultProps = {
    fetchResellerData: () => {},
    fetchResellerEvents: () => {},
    singleFetching: false,
    singleUpdating: false,
    resellerData: {},
    match: {}
};

ResellerEvents.propTypes = {
    resellerData: PropTypes.oneOfType([PropTypes.object]),
    dispatch: PropTypes.func.isRequired,
    fetchResellerData: PropTypes.func,
    fetchResellerEvents: PropTypes.func,
    singleFetching: PropTypes.bool,
    singleUpdating: PropTypes.bool,
    match: PropTypes.oneOfType([PropTypes.object])
};

ResellerEvents = reduxForm({
    form: 'ResellerEventsForm',
    enableReinitialize: true,
    keepDirtyOnReinitialize: false, // important
    updateUnregisteredFields: false // important
})(ResellerEvents);

//  Getting initial value populated in the form from the store
const mapStateToProps = (state, ownProps) => ({
    resellerData: getResellerData(state, ownProps.match.params.id),
    initialValues: getResellerEvents(state, ownProps.match.params.id),
    singleFetching: getSingleResellerFetching(state),
    singleUpdating: getSingleResellerUpdating(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchResellerData: () => {
        // Getting included data from API by setting params
        const params = {
            include: 'company,resellerHideEvents,eventsIncluded,eventsExcluded,sortedEvents,events'
        };
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: resellersActions.START_FETCHING_SINGLE_RESELLER,
                id: ownProps.match.params.id,
                params
            });
        }).catch(error => ({ error }));
    },
    fetchResellerEvents: () => {
        const params = {
            pagination: false,
            'filters[end_utc][upcoming]': true
        };
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: resellersActions.START_FETCHING_RESELLER_EVENTS,
                id: ownProps.match.params.id,
                params
            });
        });
    }
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ResellerEvents)
);
