import React from 'react';
import PropTypes from 'prop-types';
import TableItem from 'erpsrc/screens/Tables/components/TableItem';
import { FormattedMessage } from 'react-intl';
import Notification from 'erpcomponents/Notification';
import Button from 'erpcomponents/Button';

class TableList extends React.Component {
    constructor(props) {
        super(props);

        this.grid = 8;

        this.tableListRef = React.createRef();
    }

    render() {
        const {
            eventId,
            tablesData,
            eventNoTables,
            getGroupById,
            filterTablesSearch,
            filterTablesRoom,
            filterTablesSortBy,
            filterTablesAvailable,
            filterTablesShared
        } = this.props;

        if (tablesData && tablesData.length) {
            const tablesAvailable = [];
            const tablesLocked = [];
            const tablesOther = [];

            tablesData.forEach(table => {
                const {
                    groups,
                    id,
                    table_number: tableNumber,
                    room,
                    max_seats: maxSeats,
                    locked = false
                } = table;

                // filter search
                const matchingSearch =
                    !filterTablesSearch ||
                    (filterTablesSearch && !filterTablesSearch.length) ||
                    (filterTablesSearch &&
                        filterTablesSearch.length &&
                        tableNumber.toUpperCase().includes(filterTablesSearch.toUpperCase()));

                if (matchingSearch) {
                    // filter room
                    const matchingRoom =
                        !filterTablesRoom ||
                        (filterTablesRoom && room && room.iri === filterTablesRoom);

                    if (matchingRoom) {
                        let occupiedSeats = 0;
                        let privateGroupCount = 0;
                        let sharedGroupCount = 0;
                        const groupList = [];

                        if (groups && groups.length) {
                            groups.forEach(group => {
                                if (group.id) {
                                    const groupData = getGroupById(group.id);
                                    if (groupData) {
                                        if (groupData.group_size) {
                                            occupiedSeats += groupData.group_size;
                                        }
                                        if (
                                            groupData.willing_to_share !== undefined &&
                                            groupData.willing_to_share
                                        ) {
                                            sharedGroupCount += 1;
                                        } else {
                                            privateGroupCount += 1;
                                        }
                                        groupList.push(
                                            { id: group.id, ...groupData }
                                            // <GroupItem {...groupData} tableLocked={table.locked} />
                                        );
                                    }
                                }
                            });
                        }

                        // table is "private" if it has at least one private group
                        const isPrivate = privateGroupCount > 0;

                        // table is "mixed private" if it has both private and shared groups
                        // not relevant for filters. is forwarded to table item
                        const isPrivateMixed = privateGroupCount > 0 && sharedGroupCount > 0;

                        const privateData = { isPrivate, isPrivateMixed };

                        // filter shared
                        const matchingShared =
                            !filterTablesShared || (filterTablesShared && !isPrivate);

                        if (matchingShared) {
                            // filter available seats
                            const matchingAvailableFilter =
                                !filterTablesAvailable ||
                                (filterTablesAvailable && !maxSeats) ||
                                (filterTablesAvailable &&
                                    maxSeats &&
                                    maxSeats > occupiedSeats &&
                                    (filterTablesAvailable && !locked));

                            if (matchingAvailableFilter) {
                                // if filterTablesSortBy === 'availability'  add table either to tablesAvailable or tablesLocked or tablesOther
                                // if filterTablesSortBy !== 'availability' add table to tablesOther,
                                if (
                                    filterTablesSortBy === 'availability' &&
                                    (maxSeats && maxSeats > occupiedSeats && !locked)
                                ) {
                                    // sort by availability is ON
                                    // AND
                                    // table has available seats and is not locked
                                    tablesAvailable.push({
                                        key: id,
                                        ...table,
                                        locked: !!table.locked,
                                        occupiedSeats,
                                        ...privateData,
                                        groupList
                                    });
                                } else if (filterTablesSortBy === 'availability' && locked) {
                                    // sort by availability is ON
                                    // AND
                                    // table is locked
                                    tablesLocked.push({
                                        key: id,
                                        ...table,
                                        locked: !!table.locked,
                                        occupiedSeats,
                                        ...privateData,
                                        groupList
                                    });
                                } else {
                                    // sort by availability is OFF
                                    // OR
                                    // sort by availability is ON
                                    // AND
                                    // table has no available seats and is not locked
                                    tablesOther.push({
                                        key: id,
                                        ...table,
                                        locked: !!table.locked,
                                        occupiedSeats,
                                        ...privateData,
                                        groupList
                                    });
                                }
                            }
                        }
                    }
                }
            });

            // sort by available tables first
            const tables = [...tablesAvailable, ...tablesLocked, ...tablesOther];

            if (!tables.length) {
                const { resetFilters } = this.props;

                return (
                    <Notification
                        title={
                            <FormattedMessage
                                id="TableList.NoMatchingResults.title"
                                defaultMessage="No tables match selected filters"
                            />
                        }
                        type="warning"
                    >
                        <Button
                            onClick={() => resetFilters('tables')}
                            label={
                                <FormattedMessage
                                    id="TableList.NoMatchingResults.resetFilter"
                                    defaultMessage="Reset Filters"
                                />
                            }
                        />
                    </Notification>
                );
            }

            return (
                <React.Fragment>
                    <div className="table-allotments__table-list" ref={this.tableListRef}>
                        {tables.map(table => (
                            <TableItem
                                eventId={eventId}
                                key={table.id}
                                {...table}
                                locked={!!table.locked}
                                occupiedSeats={table.occupiedSeats}
                            />
                        ))}
                    </div>
                </React.Fragment>
            );
        }

        if (tablesData && !tablesData.length && eventNoTables) {
            return (
                <div className="table-allotments__table-list">
                    <Notification
                        title={
                            <FormattedMessage
                                id="TableList.NoResults.title"
                                defaultMessage="No Tables"
                            />
                        }
                        text={
                            <FormattedMessage
                                id="TableList.NoResults.text"
                                defaultMessage="There are no tables assigned to this Event!"
                            />
                        }
                        type="warning"
                    >
                        <Button
                            href={`/events/${eventId}/manage/tables`}
                            label={
                                <FormattedMessage
                                    id="TableList.NoResults.EditEvent"
                                    defaultMessage="Edit Event"
                                />
                            }
                        />
                    </Notification>
                </div>
            );
        }

        return null;
    }
}

TableList.defaultProps = {
    eventId: null,
    tablesData: null,
    eventNoTables: false,
    getGroupById: () => {},
    resetFilters: () => {},
    filterTablesSearch: null,
    filterTablesRoom: null,
    filterTablesSortBy: null,
    filterTablesAvailable: false,
    filterTablesShared: false
};

TableList.propTypes = {
    eventId: PropTypes.string,
    tablesData: PropTypes.oneOfType([PropTypes.array]),
    eventNoTables: PropTypes.bool,
    getGroupById: PropTypes.func,
    resetFilters: PropTypes.func,
    filterTablesSearch: PropTypes.string,
    filterTablesRoom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    filterTablesSortBy: PropTypes.string,
    filterTablesAvailable: PropTypes.bool,
    filterTablesShared: PropTypes.bool
};

export default TableList;
