import React from 'react';
import PropTypes from 'prop-types';
import { dtoForm } from 'erputils/dto';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Field, reduxForm, reset } from 'redux-form';
import Form, { Password, Hidden } from 'erpcore/components/Form';
import valueValidation from 'erputils/valueValidation';
import Button from 'erpcomponents/Button';
import { getMeData } from 'erputils/AuthManager/AuthManager.selectors';
import { actions as myAccountActions } from '../../MyAccount.reducer';

let ChangePasswordForm = ({ handleSubmit, submitting, pristine, invalid, onSubmit }) => {
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Columns>
                <Form.Column>
                    <Form.SectionTitle>
                        <FormattedMessage
                            id="MyAccountForm.SectionTitle.ChangePassword"
                            defaultMessage="Change Password"
                        />
                    </Form.SectionTitle>
                    <Form.Row>
                        <Field
                            name="old_password"
                            id="old_password"
                            fieldProps={{
                                label: (
                                    <FormattedMessage
                                        id="MyAccountForm.CurrentPassword"
                                        defaultMessage="Current Password"
                                    />
                                )
                            }}
                            fieldAttr={{ required: true }}
                            component={Password}
                            validate={valueValidation([{ validator: 'required' }])}
                        />
                    </Form.Row>
                    <Form.Row>
                        <Field
                            name="new_password"
                            id="new_password"
                            fieldProps={{
                                label: (
                                    <FormattedMessage
                                        id="MyAccountForm.NewPassword"
                                        defaultMessage="New Password"
                                    />
                                )
                            }}
                            fieldAttr={{ required: true }}
                            component={Password}
                            validate={valueValidation([
                                { validator: 'length', args: { min: 8 } },
                                { validator: 'required' }
                            ])}
                        />
                    </Form.Row>
                    <Form.Row>
                        <Field
                            name="new_password_confirmation"
                            id="new_password_confirmation"
                            fieldProps={{
                                label: (
                                    <FormattedMessage
                                        id="MyAccountForm.password_confirmation"
                                        defaultMessage="Confirm New Password"
                                    />
                                )
                            }}
                            fieldAttr={{ required: true }}
                            component={Password}
                            validate={valueValidation([
                                { validator: 'length', args: { min: 8 } },
                                { validator: 'required' }
                            ])}
                        />
                    </Form.Row>
                    <Form.Row>
                        <Field name="id" id="id" component={Hidden} />
                        <Button
                            submit
                            disabled={pristine || invalid}
                            loading={submitting}
                            label={
                                <FormattedMessage
                                    id="MyAccountForm.UpdatePassword"
                                    defaultMessage="Update Password"
                                />
                            }
                        />
                    </Form.Row>
                </Form.Column>
                <Form.Column />
            </Form.Columns>
        </Form>
    );
};

ChangePasswordForm.defaultProps = {
    submitting: false,
    pristine: false,
    invalid: false,
    onSubmit: (formData, dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: myAccountActions.START_CHANGE_PASSWORD,
                formData
            });
        })
            .then(() => {
                dispatch(reset('ChangePasswordForm'));
            })
            .catch(() => {
                dispatch(reset('ChangePasswordForm'));
            });
    }
};

ChangePasswordForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool
};

// Order of this steps is mandatory to have initialValues working
ChangePasswordForm = reduxForm({
    form: 'ChangePasswordForm',
    enableReinitialize: true
})(ChangePasswordForm);

//  Getting initial value populated in the form from the store
const mapStateToProps = state => ({
    initialValues: dtoForm(getMeData(state))
});

export default withRouter(connect(mapStateToProps)(ChangePasswordForm));
