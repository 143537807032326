import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { Route, Switch, Redirect, withRouter } from 'react-router-dom';

import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { actions as routerManagerActions } from 'erpcore/utils/RouterManager/RouterManager.reducer';
import { getSignedIn, getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import { hasPermission, getPermissionsAccessForEntities } from 'erpcore/utils/RolesManager';
import { getRedirect } from 'erputils/RouterManager/RouterManager.selectors';
import { getPageNotification } from 'erputils/NotificationManager/NotificationManager.selectors';

import SignIn from 'erpcore/screens/SignIn';
import SignOut from 'erpcore/screens/SignOut';

import UserInvitedCreateAccount from 'erpcore/screens/SignUp/screens/UserInvited/CreateAccount.jsx';
import UserInvitedDecline from 'erpcore/screens/SignUp/screens/InvitationDeclined';

import ForgotPassword from 'erpcore/screens/ForgotPassword/screens/ForgotPassword';
import ResetPassword from 'erpcore/screens/ForgotPassword/screens/ResetPassword';

import Dashboard from 'erpcore/screens/Dashboard';
import MyAccount from 'erpcore/screens/MyAccount';

import UsersListing from 'erpcore/screens/Users/screens/UsersListing';
import UserCreate from 'erpcore/screens/Users/screens/UserCreate';
import UserEdit from 'erpcore/screens/Users/screens/UserEdit';
import UserPermissions from 'erpcore/screens/Users/screens/UserPermissions';

import CompaniesListing from 'erpcore/screens/Companies/screens/CompaniesListing';
import CompanyCreate from 'erpcore/screens/Companies/screens/CompanyCreate';
import CompanyEdit from 'erpcore/screens/Companies/screens/CompanyEdit';
// import CompanyView from 'erpcore/screens/Companies/screens/CompanyView';

import EventsListing from 'erpcore/screens/Events/screens/EventsListing';
import EventCreate from 'erpcore/screens/Events/screens/EventCreate';
import EventEditDetails from 'erpcore/screens/Events/screens/EventEdit/EventDetails.jsx';
import EventEditTickets from 'erpcore/screens/Events/screens/EventEdit/EventTickets.jsx';
import EventEditSEO from 'erpcore/screens/Events/screens/EventEdit/EventSEO.jsx';
import EventEditThirdPartyConnect from 'erpcore/screens/Events/screens/EventEdit/ThirdPartyConnect.jsx';
import EventEditImages from 'erpcore/screens/Events/screens/EventEdit/EventImages.jsx';
import EventSettings from 'erpcore/screens/Events/screens/EventManage/EventSettings.jsx';
import EventProduction from 'erpcore/screens/Events/screens/EventManage/EventProduction.jsx';
import EventTables from 'erpcore/screens/Events/screens/EventManage/EventTables.jsx';
import EventActivityLog from 'erpcore/screens/Events/screens/EventManage/EventActivityLog.jsx';

import CustomersListing from 'erpcore/screens/Customers/screens/CustomersListing';
import CustomerCreate from 'erpcore/screens/Customers/screens/CustomerCreate';
import CustomerEdit from 'erpcore/screens/Customers/screens/CustomerEdit';

import TableTemplatesListing from 'erpcore/screens/Venues/screens/VenuesEdit/TableTemplates/TableTemplatesListing.jsx';
import TableTemplateCreate from 'erpcore/screens/Venues/screens/VenuesEdit/TableTemplates/TableTemplateCreate.jsx';
import TableTemplateEdit from 'erpcore/screens/Venues/screens/VenuesEdit/TableTemplates/TableTemplateEdit.jsx';
import VenuesListing from 'erpcore/screens/Venues/screens/VenuesListing';
import VenueCreate from 'erpcore/screens/Venues/screens/VenueCreate';
import VenuesEdit from 'erpcore/screens/Venues/screens/VenuesEdit';
import RoomsListing from 'erpcore/screens/Venues/screens/VenuesEdit/Rooms/RoomsListing.jsx';

import ResellersListing from 'erpcore/screens/Resellers/screens/ResellersListing';
import ResellerCreate from 'erpcore/screens/Resellers/screens/ResellerCreate';
import GeneralInfo from 'erpcore/screens/Resellers/screens/ResellersEdit/GeneralInfo.jsx';
import ResellerSEO from 'erpcore/screens/Resellers/screens/ResellersEdit/SEO.jsx';
import ResellerEvents from 'erpcore/screens/Resellers/screens/ResellersEdit/ResellerEvents.jsx';
import ResellerThirdPartyConnect from 'erpcore/screens/Resellers/screens/ResellersEdit/ThirdPartyConnect.jsx';
import ResellerNotes from 'erpcore/screens/Resellers/screens/ResellersEdit/ResellerNotes.jsx';

import OrdersListing from 'erpcore/screens/Orders/screens/OrdersListing';
import OrderInfo from 'erpcore/screens/Orders/screens/SingleOrder/OrderInfo';
import OrderActivityLog from 'erpcore/screens/Orders/screens/SingleOrder/OrderActivityLog';
import OrderComments from 'erpcore/screens/Orders/screens/SingleOrder/OrderComments';

// Settings
import SettingsEventCategories from 'erpcore/screens/Settings/screens/AccountingCategories/EventCategories';
import SettingsEventSeries from 'erpcore/screens/Settings/screens/AccountingCategories/EventSeries';
import SettingsAgeRequirements from 'erpcore/screens/Settings/screens/AccountingCategories/AgeRequirements';
import SettingsBarTypes from 'erpcore/screens/Settings/screens/AccountingCategories/BarTypes';
import SettingsFoodTypes from 'erpcore/screens/Settings/screens/AccountingCategories/FoodTypes';
import SettingsHeaderMenu from 'erpcore/screens/Settings/screens/ResellerAdmin/HeaderMenu';
import SettingsResellerFilter from 'erpcore/screens/Settings/screens/ResellerAdmin/ResellerFilter';
import SettingsSortEvents from 'erpcore/screens/Settings/screens/ResellerAdmin/SortEvents';
import SettingsHomepageSlider from 'erpcore/screens/Settings/screens/ResellerAdmin/HomepageSlider';
import PrintCardSettings from 'erpcore/screens/Settings/screens/PrintSettings/PrintCardSettings';
import NotificationsListing from 'erpcore/screens/Notifications/screens/NotificationsListing';
import Roles from 'erpcore/screens/Settings/screens/Roles/screens/Roles';
import RoleCreate from 'erpcore/screens/Settings/screens/Roles/screens/RoleCreate';
import RoleEdit from 'erpcore/screens/Settings/screens/Roles/screens/RoleEdit';
import Permissions from 'erpcore/screens/Settings/screens/Permissions';

import TableAllotments from 'erpcore/screens/Tables/screens/TableAllotments';
import PrintGuestlistByTable from 'erpcore/screens/Tables/screens/PrintGuestlistByTable';
import PrintGuestlistByName from 'erpcore/screens/Tables/screens/PrintGuestlistByName';
import TableCardPrinting from 'erpcore/screens/Tables/screens/TableCardPrinting';

import FormTest from 'erpcore/screens/FormTest';
import ChartTest from 'erpcore/screens/ChartTest';
import GroupsListing from 'erpcore/screens/Groups/screens/GroupsListing';
import GroupEdit from 'erpcore/screens/Groups/screens/GroupEdit';
import GroupCreate from 'erpcore/screens/Groups/screens/GroupCreate';

import TicketSocketFeed from 'erpcore/screens/Settings/screens/Integrations/TicketSocketFeed';
import UserInvites from 'erpcore/screens/Users/screens/UserInvites';
import TicketSocketAPI from 'erpcore/screens/Settings/screens/Integrations/TicketSocketAPI';
import GetOutAPI from 'erpcore/screens/Settings/screens/Integrations/GetOutAPI';

// Dashboards //
import OverallSales from 'erpsrc/screens/Dashboard/screens/OverallSales';
import OverallSalesPerEvent from 'erpsrc/screens/Dashboard/screens/OverallSalesPerEvent';
import OverallSalesPerTicketType from 'erpsrc/screens/Dashboard/screens/OverallSalesPerTicketType';
import YearOverYear from 'erpsrc/screens/Dashboard/screens/YearOverYear/screens';

import Error404 from 'erpcore/screens/Error404';

/**
 * Router Manager
 * @return {Node} Returns current active route component
 */
const RouterManager = ({ location }) => {
    const dispatch = useDispatch();
    const allowedToClearNotifications = useRef(false);
    const { pathname, search } = location;
    const isSignedIn = useSelector(state => getSignedIn(state));
    // const userRole = useSelector(state => getUserRole(state));
    const pageNotification = useSelector(state => getPageNotification(state));
    const redirect = useSelector(state => getRedirect(state));
    const meData = useSelector(getMeData) || {};
    const hasPermissionsAccessForEntities = getPermissionsAccessForEntities(meData);

    const clearNotificationManagerPageNotification = () => {
        dispatch({
            type: notificationManagerActions.REMOVE_PAGE_NOTIFICATION,
            initiator: 'router'
        });
    };

    const setUrlQueryParams = params => {
        dispatch({
            type: routerManagerActions.SET_URL_QUERY_PARAMS,
            response: params
        });
    };

    const removeRedirect = () => {
        dispatch({
            type: routerManagerActions.REMOVE_REDIRECT_URL
        });
    };

    const renderRedirect = () => {
        if (redirect) {
            return <Redirect to={redirect} />;
        }

        return <Redirect exact from="/" to="/dashboard" />;
    };

    /**
     * Effect used only on location path (route) change
     */
    useEffect(() => {
        const handleLocationChange = () => {
            if (allowedToClearNotifications.current) {
                // Clear Page Notifications
                if (pageNotification && !pageNotification.skipNotificationClearOnRouteChange) {
                    clearNotificationManagerPageNotification();
                }
            }
            allowedToClearNotifications.current = true;
            removeRedirect();
            setUrlQueryParams('');
        };

        const renderBodyClassName = () => {
            //  dynamicly udating body className
            let cssClass = 'page-homepage';
            if (pathname && pathname !== '/') {
                cssClass = location.pathname.replace(/\//, 'page-');
                cssClass = cssClass.replace(/\//g, '-');
            } else {
                cssClass = 'page-404';
            }

            document.body.className = cssClass;
        };
        // Invoke functions
        renderBodyClassName();
        handleLocationChange();
    }, [pathname]);

    /**
     * Effect used only on location search (query params) change
     */
    useEffect(() => {
        setUrlQueryParams(search);
    }, [search]);

    // Render the private routes when the user is signed in
    if (isSignedIn) {
        return (
            <Switch>
                <Route key={0} path="/sign-out" exact component={SignOut} />

                <Route key={0.1} path="/form-test" exact component={FormTest} />
                <Route key={0.2} path="/chart-test" exact component={ChartTest} />

                <Route key={1} path="/dashboard" exact component={Dashboard} />
                <Route key={2} path="/my-account" exact component={MyAccount} />

                {/* DASHBOARDS */}
                {hasPermission('CAN_CUSTOM_MANAGE_OVERALL_SALES_DASHBOARD', meData) && (
                    <Route
                        key={101}
                        path="/dashboard/overall-sales"
                        exact
                        component={OverallSales}
                    />
                )}
                {hasPermission('CAN_CUSTOM_MANAGE_OVERALL_SALES_DASHBOARD', meData) && (
                    <Route
                        key={101.1}
                        path="/dashboard/overall-sales/:id"
                        exact
                        component={OverallSalesPerEvent}
                    />
                )}
                {hasPermission('CAN_CUSTOM_MANAGE_OVERALL_SALES_DASHBOARD', meData) && (
                    <Route
                        key={101.2}
                        path="/dashboard/overall-sales/:id/:ticketTypeID"
                        exact
                        component={OverallSalesPerTicketType}
                    />
                )}
                {hasPermission('CAN_CUSTOM_MANAGE_YEAR_OVER_YEAR', meData) && (
                    <Route
                        key={101.3}
                        path="/dashboard/year-over-year"
                        exact
                        component={YearOverYear}
                    />
                )}

                {/* USERS */}
                {hasPermissionsAccessForEntities.users && (
                    <Route key={10} path="/users" exact component={UsersListing} />
                )}
                {hasPermission('CAN_MANAGE_COMMON_USER', meData) && (
                    <Route key={10.1} path="/users/create" exact component={UserCreate} />
                )}
                {hasPermission('CAN_MANAGE_COMMON_USER', meData) && (
                    <Route key={10.2} path="/users/:id/edit/details" exact component={UserEdit} />
                )}
                {hasPermission('CAN_MANAGE_COMMON_USER', meData) && (
                    <Route
                        key={10.3}
                        path="/users/:id/edit/permissions"
                        exact
                        component={UserPermissions}
                    />
                )}
                {hasPermission('CAN_MANAGE_COMMON_USER', meData) && (
                    <Route key={11.1} path="/user-invites" exact component={UserInvites} />
                )}

                {/* COMPANIES */}
                {hasPermissionsAccessForEntities.companies && (
                    <Route key={50} path="/companies" exact component={CompaniesListing} />
                )}
                {hasPermission('CAN_MANAGE_COMPANY_BUNDLE_COMPANY', meData) && (
                    <Route key={50.1} path="/companies/create" exact component={CompanyCreate} />
                )}
                {hasPermission('CAN_MANAGE_COMPANY_BUNDLE_COMPANY', meData) && (
                    <Route key={50.2} path="/companies/:id/edit" exact component={CompanyEdit} />
                )}
                {hasPermission('CAN_MANAGE_COMPANY_BUNDLE_COMPANY', meData) && (
                    <Route key={50.3} path="/companies/:id/view" exact component={CompanyEdit} />
                )}

                {/* EVENTS */}
                {hasPermissionsAccessForEntities.events && (
                    <Route key={20} path="/events" exact component={EventsListing} />
                )}
                {hasPermission('CAN_MANAGE_EVENT_BUNDLE_EVENT', meData) && (
                    <Route key={20.1} path="/events/create" exact component={EventCreate} />
                )}
                {hasPermission('CAN_MANAGE_EVENT_BUNDLE_EVENT', meData) && (
                    <Route
                        key={20.2}
                        path="/events/:id/edit/event-details"
                        exact
                        component={EventEditDetails}
                    />
                )}
                {hasPermission('CAN_MANAGE_EVENT_BUNDLE_EVENT', meData) && (
                    <Route
                        key={20.3}
                        path="/events/:id/edit/tickets"
                        exact
                        component={EventEditTickets}
                    />
                )}
                {hasPermission('CAN_MANAGE_EVENT_BUNDLE_EVENT', meData) && (
                    <Route key={20.4} path="/events/:id/edit/seo" exact component={EventEditSEO} />
                )}
                {hasPermission('CAN_MANAGE_EVENT_BUNDLE_EVENT', meData) && (
                    <Route
                        key={20.5}
                        path="/events/:id/edit/thirdpartyconnect"
                        exact
                        component={EventEditThirdPartyConnect}
                    />
                )}
                {hasPermission('CAN_MANAGE_EVENT_BUNDLE_EVENT', meData) && (
                    <Route
                        key={20.6}
                        path="/events/:id/edit/images"
                        exact
                        component={EventEditImages}
                    />
                )}
                {hasPermission('CAN_MANAGE_EVENT_BUNDLE_EVENT', meData) && (
                    <Route
                        key={20.7}
                        path="/events/:id/manage/event-settings"
                        exact
                        component={EventSettings}
                    />
                )}
                {hasPermission('CAN_MANAGE_EVENT_BUNDLE_EVENT', meData) && (
                    <Route
                        key={20.8}
                        path="/events/:id/manage/production"
                        exact
                        component={EventProduction}
                    />
                )}
                {hasPermission('CAN_MANAGE_EVENT_BUNDLE_EVENT', meData) && (
                    <Route
                        key={20.9}
                        path="/events/:id/manage/tables"
                        exact
                        component={EventTables}
                    />
                )}
                {hasPermission('CAN_MANAGE_EVENT_BUNDLE_EVENT', meData) && (
                    <Route
                        key={20.11}
                        path="/events/:id/manage/activity-log"
                        exact
                        component={EventActivityLog}
                    />
                )}

                {/* VENUES */}
                {hasPermissionsAccessForEntities.venues && (
                    <Route key={40} path="/venues" exact component={VenuesListing} />
                )}
                {hasPermission('CAN_CUSTOM_MANAGE_VENUES', meData) && (
                    <Route key={40.1} path="/venues/create" exact component={VenueCreate} />
                )}
                {hasPermission('CAN_CUSTOM_MANAGE_VENUES', meData) && (
                    <Route
                        key={40.2}
                        path="/venues/:id/edit/general-info"
                        exact
                        component={VenuesEdit}
                    />
                )}
                {hasPermission('CAN_CUSTOM_MANAGE_VENUES', meData) && (
                    <Route
                        key={40.3}
                        path="/venues/:id/edit/table-templates"
                        exact
                        component={TableTemplatesListing}
                    />
                )}
                {hasPermission('CAN_CUSTOM_MANAGE_VENUES', meData) && (
                    <Route
                        key={40.4}
                        path="/venues/:id/edit/table-templates/create"
                        exact
                        component={TableTemplateCreate}
                    />
                )}
                {hasPermission('CAN_CUSTOM_MANAGE_VENUES', meData) && (
                    <Route
                        key={40.5}
                        path="/venues/:id/edit/table-templates/:tid/edit"
                        exact
                        component={TableTemplateEdit}
                    />
                )}
                {hasPermission('CAN_CUSTOM_MANAGE_VENUES', meData) && (
                    <Route
                        key={40.6}
                        path="/venues/:id/edit/rooms"
                        exact
                        component={RoomsListing}
                    />
                )}

                {/* ORDERS */}
                {hasPermissionsAccessForEntities.orders && (
                    <Route key={80} path="/orders" exact component={OrdersListing} />
                )}
                {hasPermission('CAN_MANAGE_ORDER_BUNDLE_ORDER', meData) && (
                    <Route key={80.1} path="/orders/:id/order-info" exact component={OrderInfo} />
                )}
                {hasPermission('CAN_MANAGE_ORDER_BUNDLE_ORDER', meData) && (
                    <Route
                        key={80.2}
                        path="/orders/:id/activity-log"
                        exact
                        component={OrderActivityLog}
                    />
                )}
                {hasPermission('CAN_MANAGE_ORDER_BUNDLE_ORDER', meData) && (
                    <Route key={80.3} path="/orders/:id/comments" exact component={OrderComments} />
                )}

                {/* RESELLERS */}
                {hasPermissionsAccessForEntities.resellers && (
                    <Route key={70} path="/resellers" exact component={ResellersListing} />
                )}
                {hasPermission('CAN_MANAGE_RESELLER_BUNDLE_RESELLER', meData) && (
                    <Route key={70.1} path="/resellers/create" exact component={ResellerCreate} />
                )}
                {(hasPermission('CAN_MANAGE_RESELLER_BUNDLE_RESELLER', meData) ||
                    hasPermission('CAN_UPDATE_RESELLER_BUNDLE_RESELLER', meData)) && (
                    <Route
                        key={70.2}
                        path="/resellers/:id/edit/general-info"
                        exact
                        component={GeneralInfo}
                    />
                )}
                {(hasPermission('CAN_MANAGE_RESELLER_BUNDLE_RESELLER', meData) ||
                    hasPermission('CAN_UPDATE_RESELLER_BUNDLE_RESELLER', meData)) && (
                    <Route
                        key={70.3}
                        path="/resellers/:id/edit/seo"
                        exact
                        component={ResellerSEO}
                    />
                )}
                {(hasPermission('CAN_MANAGE_RESELLER_BUNDLE_RESELLER', meData) ||
                    hasPermission('CAN_UPDATE_RESELLER_BUNDLE_RESELLER', meData)) && (
                    <Route
                        key={70.4}
                        path="/resellers/:id/edit/events"
                        exact
                        component={ResellerEvents}
                    />
                )}
                {hasPermission('CAN_MANAGE_RESELLER_BUNDLE_RESELLER', meData) && (
                    <Route
                        key={70.5}
                        path="/resellers/:id/edit/third-party-connect"
                        exact
                        component={ResellerThirdPartyConnect}
                    />
                )}
                {hasPermission('CAN_MANAGE_RESELLER_BUNDLE_RESELLER', meData) && (
                    <Route
                        key={70.6}
                        path="/resellers/:id/edit/notes"
                        exact
                        component={ResellerNotes}
                    />
                )}

                {/* SETTINGS */}
                {hasPermission('CAN_CUSTOM_MANAGE_CATEGORIES', meData) && (
                    <Route
                        key={60.1}
                        path="/settings/categories/event-categories"
                        exact
                        component={SettingsEventCategories}
                    />
                )}
                {hasPermission('CAN_CUSTOM_MANAGE_CATEGORIES', meData) && (
                    <Route
                        key={60.2}
                        path="/settings/categories/event-series"
                        exact
                        component={SettingsEventSeries}
                    />
                )}
                {hasPermission('CAN_CUSTOM_MANAGE_CATEGORIES', meData) && (
                    <Route
                        key={60.3}
                        path="/settings/categories/age-requirements"
                        exact
                        component={SettingsAgeRequirements}
                    />
                )}
                {hasPermission('CAN_CUSTOM_MANAGE_CATEGORIES', meData) && (
                    <Route
                        key={60.4}
                        path="/settings/categories/bar-types"
                        exact
                        component={SettingsBarTypes}
                    />
                )}
                {hasPermission('CAN_CUSTOM_MANAGE_CATEGORIES', meData) && (
                    <Route
                        key={60.5}
                        path="/settings/categories/food-types"
                        exact
                        component={SettingsFoodTypes}
                    />
                )}
                {hasPermission('CAN_CUSTOM_MANAGE_INTEGRATIONS', meData) && (
                    <Route
                        key={61.1}
                        path="/settings/integrations/ticketsocket-feed"
                        exact
                        component={TicketSocketFeed}
                    />
                )}
                {hasPermission('CAN_CUSTOM_MANAGE_INTEGRATIONS', meData) && (
                    <Route
                        key={61.2}
                        path="/settings/integrations/ticketsocket-api"
                        exact
                        component={TicketSocketAPI}
                    />
                )}
                {hasPermission('CAN_CUSTOM_MANAGE_INTEGRATIONS', meData) && (
                    <Route
                        key={61.3}
                        path="/settings/integrations/getout-api"
                        exact
                        component={GetOutAPI}
                    />
                )}
                {hasPermission('CAN_CUSTOM_MANAGE_RESELLER_ADMIN', meData) && (
                    <Route
                        key={62.1}
                        path="/settings/reseller/header-menu"
                        exact
                        component={SettingsHeaderMenu}
                    />
                )}
                {hasPermission('CAN_CUSTOM_MANAGE_RESELLER_ADMIN', meData) && (
                    <Route
                        key={62.2}
                        path="/settings/reseller/filter"
                        exact
                        component={SettingsResellerFilter}
                    />
                )}
                {hasPermission('CAN_CUSTOM_MANAGE_RESELLER_ADMIN', meData) && (
                    <Route
                        key={62.3}
                        path="/settings/reseller/sort-events"
                        exact
                        component={SettingsSortEvents}
                    />
                )}
                {hasPermission('CAN_CUSTOM_MANAGE_RESELLER_ADMIN', meData) && (
                    <Route
                        key={62.4}
                        path="/settings/reseller/homepage-slider"
                        exact
                        component={SettingsHomepageSlider}
                    />
                )}
                {hasPermission('CAN_CUSTOM_MANAGE_PRINT_SETTINGS', meData) && (
                    <Route
                        key={63}
                        path="/settings/print-settings"
                        exact
                        component={PrintCardSettings}
                    />
                )}
                {hasPermission('CAN_MANAGE_SECURITY_BUNDLE_ROLE', meData) && (
                    <Route key={64} path="/settings/roles" exact component={Roles} />
                )}
                {hasPermission('CAN_MANAGE_SECURITY_BUNDLE_ROLE', meData) && (
                    <Route key={64.1} path="/settings/roles/create" exact component={RoleCreate} />
                )}
                {hasPermission('CAN_MANAGE_SECURITY_BUNDLE_ROLE', meData) && (
                    <Route key={64.2} path="/settings/roles/:id/edit" exact component={RoleEdit} />
                )}
                {hasPermission('CAN_MANAGE_SECURITY_BUNDLE_PERMISSION', meData) && (
                    <Route key={65} path="/settings/permissions" exact component={Permissions} />
                )}

                {/* CUSTOMERS */}
                {hasPermissionsAccessForEntities.customers && (
                    <Route key={90} path="/customers" exact component={CustomersListing} />
                )}
                {hasPermission('CAN_CUSTOM_MANAGE_PRINT_SETTINGS', meData) && (
                    <Route key={90.1} path="/customers/create" exact component={CustomerCreate} />
                )}
                {hasPermission('CAN_CUSTOM_MANAGE_PRINT_SETTINGS', meData) && (
                    <Route key={90.2} path="/customers/:id/edit" exact component={CustomerEdit} />
                )}

                {/* GROUPS */}
                {hasPermissionsAccessForEntities.groups && (
                    <Route key={100} path="/groups" exact component={GroupsListing} />
                )}
                {hasPermission('CAN_MANAGE_ORDER_BUNDLE_GROUP', meData) && (
                    <Route key={100.1} path="/groups/create" exact component={GroupCreate} />
                )}
                {hasPermission('CAN_MANAGE_ORDER_BUNDLE_GROUP', meData) && (
                    <Route key={100.2} path="/groups/:id/edit" exact component={GroupEdit} />
                )}

                {hasPermissionsAccessForEntities.notifications && (
                    <Route key={200} path="/notifications" exact component={NotificationsListing} />
                )}

                {/* TABLE ALLOTMENTS */}
                {hasPermissionsAccessForEntities.tableAllotments && (
                    <Route key={210} path="/table-allotments" exact component={TableAllotments} />
                )}
                {hasPermission('CAN_CUSTOM_MANAGE_TABLE_ALLOTMENTS', meData) && (
                    <Route
                        key={211}
                        path="/table-allotments/:eventId"
                        exact
                        component={TableAllotments}
                    />
                )}
                {hasPermission('CAN_CUSTOM_MANAGE_TABLE_ALLOTMENTS', meData) && (
                    <Route
                        key={212}
                        path="/table-allotments/:eventId/print-by-table"
                        exact
                        component={PrintGuestlistByTable}
                    />
                )}
                {hasPermission('CAN_CUSTOM_MANAGE_TABLE_ALLOTMENTS', meData) && (
                    <Route
                        key={213}
                        path="/table-allotments/:eventId/print-by-name"
                        exact
                        component={PrintGuestlistByName}
                    />
                )}

                {/* TABLE CARD PRINTING */}
                {hasPermissionsAccessForEntities.tableCardPrinting && (
                    <Route
                        key={215}
                        path="/table-card-printing"
                        exact
                        component={TableCardPrinting}
                    />
                )}
                {hasPermission('CAN_CUSTOM_MANAGE_TABLE_CARD_PRINTING', meData) && (
                    <Route
                        key={216}
                        path="/table-card-printing/:eventId"
                        exact
                        component={TableCardPrinting}
                    />
                )}

                {renderRedirect()}
                <Route component={Error404} />
            </Switch>
        );
    }

    // render the public router when the user is not signed in
    return (
        <Switch>
            <Route exact path="/" component={SignIn} />
            <Route exact path="/join/:token" component={UserInvitedCreateAccount} />
            <Route exact path="/decline/:token" component={UserInvitedDecline} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route exact path="/reset-password/:token" component={ResetPassword} />
            <Redirect to="/" />
        </Switch>
    );
};

RouterManager.defaultProps = {
    location: {}
};

RouterManager.propTypes = {
    location: PropTypes.oneOfType([PropTypes.object])
};

export default withRouter(RouterManager);
