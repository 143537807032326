import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Receiver } from 'react-file-uploader';
import restClient from 'erpcore/api/restClient';
import { change } from 'redux-form';
import dto from 'erputils/dto';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import Svg from 'erpcomponents/Svg';
import Button from 'erpcore/components/Button';
import Modal from 'erpcomponents/Modal';
import isImage from 'is-image';
import ImageManager, { getImageVersion } from 'erpcomponents/ImageManager';
import nextId from 'react-id-generator';
import { FormattedMessage } from 'react-intl';
import ElementLoader from 'erpcomponents/ElementLoader';
import { diff } from 'deep-object-diff';
import Lightbox from 'react-image-lightbox';
import { dtoGallerySort } from 'erpcore/components/Form/components/Gallery/utils';
import './Gallery.scss';
import './GalleryLightbox.scss';

const SortableGalleryItem = SortableElement(({ children, isFeatured = false }) => {
    return (
        <div className={`gallery__item${isFeatured ? ' gallery__item--featured' : ''}`}>
            {children}
            <div className="gallery__item-move">
                <Svg className="gallery__item-move-icon" icon="move" />
            </div>
        </div>
    );
});

const SortableGalleryContainer = SortableContainer(({ children }) => {
    return <div className="gallery__grid">{children}</div>;
});

class Gallery extends Component {
    constructor(props) {
        super(props);

        this.state = {
            busy: false,
            uploadProgress: {},
            sortDragging: false,
            isReceiverOpen: false,
            modalEditorOpened: false,
            modalDeleteIdOpened: false,
            lightboxImageId: null
        };

        this.handleEditorModal = this.handleEditorModal.bind(this);
        // this.onImageDrop = this.onImageDrop.bind(this);
    }

    componentDidMount() {
        if (this.isApiDataProvided()) {
            const { getImagesApi, groupName } = this.props;
            this.startFetchImages(getImagesApi, groupName);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.isApiDataProvided(nextProps)) {
            const { attachedToEntity, getImagesApi, galleryApi, groupName } = this.props;
            const {
                attachedToEntity: nextAttachedToEntity,
                getImagesApi: nextGetImagesApi,
                galleryApi: nextgalleryApi,
                groupName: nextGroupName
            } = nextProps;

            const apiDataDiff = diff(
                {
                    diff1: { ...attachedToEntity },
                    diff2: getImagesApi,
                    diff3: galleryApi,
                    diff4: groupName
                },
                {
                    diff1: { ...nextAttachedToEntity },
                    diff2: nextGetImagesApi,
                    diff3: nextgalleryApi,
                    diff4: nextGroupName
                }
            );

            if (Object.keys(apiDataDiff).length) {
                this.startFetchImages(nextGetImagesApi, nextGroupName);
            }
        }
    }

    onImageDrop = async (event, files, inputField) => {
        this.setState({ isReceiverOpen: false });

        let queuedFiles = [];
        if (inputField) {
            queuedFiles = [...files].map(fileData => {
                return { data: fileData };
            });
        } else {
            queuedFiles = files;
        }

        this.setState({ busy: true });

        function readFileAsync(file) {
            return new Promise(resolve => {
                const reader = new FileReader();

                reader.onloadend = () => {
                    resolve(reader.result);
                };

                reader.readAsDataURL(file);
            });
        }

        const processFile = file => {
            return new Promise(resolve => {
                if (file.data === undefined) return null;

                const { fields } = this.props;

                const tempId = nextId('temp-image-id-');

                return readFileAsync(file.data).then(rawData => {
                    // add raw image to formArray
                    fields.unshift({
                        isNewGalleryItem: true,
                        tempId,
                        media_object: {
                            meta: file.data,
                            raw: rawData
                        }
                    });

                    const formData = new FormData();

                    formData.append('file', file.data);
                    formData.append(
                        'meta',
                        JSON.stringify({
                            name: file.data.name,
                            size: file.data.size,
                            type: file.data.type
                        })
                    );

                    resolve({ tempId, formData });
                });
            });
        };

        // Array of concurrent "process file with FileReader" Promises
        const processFilesPromises = data => {
            return data.map(file => processFile(file));
        };

        // Array of concurrent "upload image" Promises
        const uploadImagesPromises = data => {
            return data.map(({ tempId, formData }) => this.uploadImage({ tempId, formData }));
        };

        // Array of concurrent "add MediaObject to Gallery" Promises
        const addMediaObjectsToGalleryPromises = data => {
            const collection = [];
            data.forEach((mediaObject, position) => {
                if (mediaObject && mediaObject.iri !== undefined && mediaObject.iri) {
                    collection.push(this.addMediaObjectToGallery(mediaObject.iri, position));
                }
            });
            return collection;
        };

        Promise.all(processFilesPromises(queuedFiles)).then(dataForUpload => {
            Promise.all(uploadImagesPromises(dataForUpload)).then(mediaObjects => {
                Promise.all(addMediaObjectsToGalleryPromises(mediaObjects)).then(() => {
                    this.saveSort().finally(() => {
                        const { getImagesApi, groupName } = this.props;
                        this.startFetchImages(getImagesApi, groupName);
                    });
                });
            });
        });
    };

    // eslint-disable-next-line react/sort-comp
    uploadImage = async ({ tempId, formData }) => {
        const request = await restClient.post(`api/media-objects`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
            onUploadProgress: progressEvent => {
                const percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                this.setState(prevState => {
                    const uploadProgress = { ...prevState.uploadProgress };
                    uploadProgress[tempId] = {
                        progress: percentCompleted,
                        status: 'in-progress'
                    };
                    return { uploadProgress };
                });
            }
        });
        return new Promise((resolve, reject) => {
            try {
                const { data: responseData } = request;

                this.setState(prevState => {
                    const uploadProgress = { ...prevState.uploadProgress };
                    uploadProgress[tempId] = {
                        progress: 100,
                        status: 'success'
                    };
                    return { uploadProgress };
                });

                if (responseData) {
                    const mediaObject = { ...dto(responseData).data };

                    const { fields } = this.props;

                    const targetField = fields.getAll().find(item => {
                        return item.tempId === tempId;
                    });

                    // manually update arrayItem data
                    if (targetField) {
                        this.forceUpdate();
                    }

                    resolve(mediaObject);
                }
            } catch (error) {
                this.setState(prevState => {
                    const uploadProgress = { ...prevState.uploadProgress };
                    uploadProgress[tempId] = {
                        progress: 100,
                        status: 'fail'
                    };
                    return { uploadProgress };
                });
                reject(
                    error.response !== undefined && error.response.data !== undefined
                        ? error.response.data
                        : null
                );
            }
        });
    };

    // eslint-disable-next-line react/sort-comp
    addMediaObjectToGallery(mediaObjectIri, position = 0) {
        const { galleryApi, attachedToEntity, groupName } = this.props;
        const { type: attachedToEntityType, iri: attachedToEntityIri } = attachedToEntity;

        const formData = {
            [attachedToEntityType]: attachedToEntityIri,
            media_object: mediaObjectIri,
            group_name: groupName,
            featured: false,
            position
        };

        return new Promise((resolve, reject) => {
            restClient
                .post(galleryApi, formData)
                .then(() => {
                    resolve();
                })
                .catch(() => {
                    reject();
                });
        });
    }

    addMultipleMediaObjectsToGallery(mediaObjectIris) {
        const { galleryApi, attachedToEntity, groupName } = this.props;
        const { type: attachedToEntityType, iri: attachedToEntityIri } = attachedToEntity;

        const formData = {
            [attachedToEntityType]: attachedToEntityIri,
            media_objects: mediaObjectIris,
            group_name: groupName
        };

        return new Promise((resolve, reject) => {
            restClient
                .post(`${galleryApi}/multi`, formData)
                .then(() => {
                    resolve();
                })
                .catch(() => {
                    reject();
                });
        });
    }

    rewriteWithOtherGallery = source => {
        this.setState({ busy: true });

        return new Promise(resolve => {
            if (source) resolve();

            const { getImagesApi, groupName } = this.props;

            // Array of concurrent fetch Promises
            const fetchGalleriesPromises = [
                this.fetchImagesOnly(getImagesApi, groupName), // destination gallery
                this.fetchImagesOnly(source.getImagesApi, source.groupName) // source gallery
            ];

            // Array of concurrent "remove image from gallery" Promises
            const removeGalleryItemsPromises = data => {
                return data.map(item => this.removeItem(item.id));
            };

            // Array of concurrent "add MediaObject to Gallery" Promises
            const addMediaObjectsToGallery = items => {
                const collection = [];
                items.forEach(galleryItem => {
                    if (galleryItem.media_object !== undefined) {
                        const mediaObject = galleryItem.media_object;
                        if (mediaObject && mediaObject.iri !== undefined && mediaObject.iri) {
                            collection.push(mediaObject.iri);
                        }
                    }
                });
                return this.addMultipleMediaObjectsToGallery(collection);
            };

            Promise.all(fetchGalleriesPromises).then(bothGalleryResponses => {
                const destinationGalleryItems = [...dtoGallerySort(bothGalleryResponses[0]).data];
                const sourceGalleryItems = [...dtoGallerySort(bothGalleryResponses[1]).data];

                Promise.all(removeGalleryItemsPromises(destinationGalleryItems)).finally(() => {
                    addMediaObjectsToGallery(sourceGalleryItems).finally(() => {
                        this.saveSort().finally(() => {
                            this.startFetchImages(getImagesApi, groupName);
                            resolve();
                        });
                    });
                });
            });
        });
    };

    // eslint-disable-next-line react/sort-comp
    getGalleryAddItem() {
        const { id } = this.props;
        const { isReceiverOpen } = this.state;

        return (
            <div className="gallery__item-inner" id={id}>
                <div
                    className={`gallery__upload${
                        isReceiverOpen ? ' gallery__upload--drag-entered' : ''
                    }`}
                >
                    <label className="gallery__upload-button">
                        <input
                            type="file"
                            multiple
                            onChange={event => this.onImageDrop(event, event.target.files, true)}
                        />
                        <span className="gallery__upload-button-icon">
                            {isReceiverOpen ? '⭳' : '+'}
                        </span>
                    </label>
                    <Receiver
                        wrapperId={id}
                        customClass="gallery__upload-receiver"
                        isOpen={isReceiverOpen}
                        onDragEnter={() => {
                            this.setState({ isReceiverOpen: true });
                        }}
                        onDragOver={() => {}}
                        onDragLeave={() => {
                            this.setState({ isReceiverOpen: false });
                        }}
                        onFileDrop={this.onImageDrop}
                    />
                </div>
            </div>
        );
    }

    getProgress(galleryItemData) {
        const { tempId = false } = galleryItemData;
        const { uploadProgress } = this.state;

        if (
            tempId &&
            uploadProgress &&
            uploadProgress[tempId] !== undefined &&
            uploadProgress[tempId].progress !== undefined &&
            (uploadProgress[tempId].progress || uploadProgress[tempId].progress === 0)
        ) {
            const { status = 'in-progress' } = uploadProgress[tempId];

            const messagesMap = {
                'in-progress': null,
                success: 'Image successfully uploaded!',
                fail: 'Image upload failed!'
            };

            return (
                <div className={`gallery__item-progress gallery__item-progress--${status}`}>
                    <div
                        className="gallery__item-progress-bar"
                        style={{ height: `${100 - uploadProgress[tempId].progress}%` }}
                    />
                    <span
                        className="gallery__item-progress-value"
                        title={messagesMap[status] !== undefined ? messagesMap[status] : null}
                    >
                        {status === 'in-progress' && `${uploadProgress[tempId].progress}%`}
                        {status === 'success' && (
                            <Svg className="gallery__item-progress-value-icon" icon="checkmark" />
                        )}
                        {status === 'fail' && (
                            <Svg className="gallery__item-progress-value-icon" icon="close" />
                        )}
                    </span>
                </div>
            );
        }

        return null;
    }

    getFileExtension(filename = '') {
        if (!filename) return '';
        return filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2); // eslint-disable-line no-bitwise
    }

    getGalleryItems() {
        const {
            fields,
            maxImageCount,
            featuredKey,
            versionName,
            canAddImage,
            canSort,
            canDelete,
            useImageManager,
            useLightbox,
            emptyGalleryMessage
        } = this.props;

        const { busy } = this.state;

        const items = [];

        const canAddNewImage =
            canAddImage &&
            (maxImageCount === -1 || maxImageCount === false || maxImageCount > fields.length);

        if (canAddNewImage) {
            items.push(
                <SortableGalleryItem key="add-new" collection="control" disabled index={0}>
                    {this.getGalleryAddItem()}
                </SortableGalleryItem>
            );
        }

        // render no images message if adding images is disabled and there are no images
        if (!busy && !canAddImage && fields && !fields.length) {
            items.push(<div className="gallery__info">{emptyGalleryMessage}</div>);
        }

        if (fields && fields.length) {
            fields.map((member, index) => {
                const galleryItemData = fields.get(index);

                if (galleryItemData.media_object !== undefined && galleryItemData.media_object) {
                    const imageData = galleryItemData.media_object;

                    const key =
                        galleryItemData.id || galleryItemData.id === 0
                            ? galleryItemData.id
                            : galleryItemData.tempId;
                    const { isNewGalleryItem = false } = galleryItemData;

                    const isFeatured = featuredKey && galleryItemData[featuredKey];

                    const mediaIsImage = imageData.raw || isImage(imageData.content_url);

                    const style = {};

                    if (mediaIsImage) {
                        const editedImageUrl = getImageVersion({
                            imageData,
                            versionName,
                            getProperty: 'content_url'
                        });

                        let imageUrl = '';
                        if (editedImageUrl) {
                            imageUrl = editedImageUrl;
                        } else if (imageData.content_url) {
                            imageUrl = imageData.content_url;
                        } else {
                            imageUrl = imageData.raw;
                        }

                        style.backgroundImage = `url(${imageUrl})`;
                    }

                    const fileExtension = this.getFileExtension(
                        imageData.content_url
                    ).toLowerCase();

                    items.push(
                        <SortableGalleryItem
                            key={key}
                            collection={isNewGalleryItem ? 'new' : 'api'}
                            disabled={!canSort || isNewGalleryItem}
                            index={index}
                            isFeatured={isFeatured}
                        >
                            <div className="gallery__item-inner">
                                <div className="gallery__item-content" style={style}>
                                    {!mediaIsImage && (
                                        <span
                                            className="gallery__item-file-type"
                                            data-file-type={fileExtension}
                                            aria-label={fileExtension}
                                        />
                                    )}
                                    <div className="gallery__item-actions">
                                        {!isNewGalleryItem && (
                                            <React.Fragment>
                                                {!!featuredKey && (
                                                    <button
                                                        type="button"
                                                        className={`gallery__item-action${
                                                            galleryItemData[featuredKey]
                                                                ? ' gallery__item-action--active'
                                                                : ''
                                                        }`}
                                                        onClick={() =>
                                                            this.setFeatured(
                                                                galleryItemData.id,
                                                                !galleryItemData[featuredKey]
                                                            )
                                                        }
                                                    >
                                                        <Svg
                                                            className="gallery__item-action-icon"
                                                            icon="checkmark"
                                                        />
                                                    </button>
                                                )}
                                                {!mediaIsImage && imageData.content_url && (
                                                    <a
                                                        href={imageData.content_url}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="gallery__item-action"
                                                    >
                                                        <Svg
                                                            className="gallery__item-action-icon"
                                                            icon={
                                                                fileExtension === 'pdf'
                                                                    ? 'search'
                                                                    : 'download'
                                                            }
                                                        />
                                                    </a>
                                                )}
                                                {!!mediaIsImage && !!useLightbox && (
                                                    <button
                                                        type="button"
                                                        className="gallery__item-action"
                                                        onClick={() =>
                                                            this.setActiveLightboxImage(
                                                                imageData.id
                                                            )
                                                        }
                                                    >
                                                        <Svg
                                                            className="gallery__item-action-icon"
                                                            icon="search"
                                                        />
                                                    </button>
                                                )}
                                                {!!mediaIsImage && !!useImageManager && (
                                                    <button
                                                        type="button"
                                                        className="gallery__item-action"
                                                        onClick={() =>
                                                            this.handleEditorModal(imageData.id)
                                                        }
                                                    >
                                                        <Svg
                                                            className="gallery__item-action-icon"
                                                            icon="edit"
                                                        />
                                                    </button>
                                                )}
                                                {!!canDelete && (
                                                    <button
                                                        type="button"
                                                        className="gallery__item-action"
                                                        onClick={() =>
                                                            this.handleDeleteModal(
                                                                galleryItemData.id
                                                            )
                                                        }
                                                    >
                                                        <Svg
                                                            className="gallery__item-action-icon"
                                                            icon="remove"
                                                        />
                                                    </button>
                                                )}
                                            </React.Fragment>
                                        )}
                                    </div>
                                    {isNewGalleryItem && this.getProgress(galleryItemData)}
                                </div>
                            </div>
                        </SortableGalleryItem>
                    );
                }

                return null;
            });
        }

        return items;
    }

    /**
     * Replaces redux-form fields.getAll() method, which doesn't always return latest true redux-form state.
     * @return {Array}
     */

    getAllFieldsSafe() {
        const { fields } = this.props;
        const fieldsArray = [];
        fields.forEach((name, index, fieldsReference) => {
            fieldsArray.push(fieldsReference.get(index));
        });
        return fieldsArray;
    }

    getLightboxData() {
        const data = {
            mainId: null,
            mainSrc: null,
            nextId: null,
            nextSrc: null,
            prevId: null,
            prevSrc: null
        };

        const { fields } = this.props;

        if (fields.length) {
            const { lightboxImageId } = this.state;

            const galleryData = this.getAllFieldsSafe();

            const onlyImageFilesGalleryData = [...galleryData].reduce(
                (accumulator, currentItem) => {
                    if (
                        currentItem.media_object !== undefined &&
                        currentItem.media_object.id !== undefined &&
                        currentItem.media_object.content_url !== undefined &&
                        isImage(currentItem.media_object.content_url)
                    ) {
                        accumulator.push({
                            id: currentItem.media_object.id,
                            content_url: currentItem.media_object.content_url
                        });
                    }
                    return accumulator;
                },
                []
            );

            // current lightbox image
            const currentIndex = onlyImageFilesGalleryData.findIndex(item => {
                if (item.id === lightboxImageId) {
                    data.mainId = lightboxImageId;
                    data.mainSrc = item.content_url;
                    return true;
                }
                return false;
            });

            // prev and next lightbox image
            // if more than 1 image is available, there is always prev and next image
            if (currentIndex !== -1 && onlyImageFilesGalleryData.length > 1) {
                const nextIndex = (currentIndex + 1) % onlyImageFilesGalleryData.length;
                const prevIndex =
                    (currentIndex + onlyImageFilesGalleryData.length - 1) %
                    onlyImageFilesGalleryData.length;
                data.nextId = onlyImageFilesGalleryData[nextIndex].id;
                data.nextSrc = onlyImageFilesGalleryData[nextIndex].content_url;
                data.prevId = onlyImageFilesGalleryData[prevIndex].id;
                data.prevSrc = onlyImageFilesGalleryData[prevIndex].content_url;
            }
        }

        return data;
    }

    setPrevLightboxImage() {
        const lightboxData = this.getLightboxData();

        if (lightboxData.prevId) {
            this.setActiveLightboxImage(lightboxData.prevId);
        }
    }

    setNextLightboxImage() {
        const lightboxData = this.getLightboxData();

        if (lightboxData.nextId) {
            this.setActiveLightboxImage(lightboxData.nextId);
        }
    }

    setDraggingState(sortDragging = true) {
        this.setState({ sortDragging });
    }

    setFeatured(id = false, featured) {
        this.setState({ busy: true });
        const { galleryApi } = this.props;
        restClient.put(`${galleryApi}/${id}`, { featured }).then(() => {
            const { getImagesApi, groupName } = this.props;
            this.startFetchImages(getImagesApi, groupName);
        });
    }

    sortStart = () => {
        this.setDraggingState(true);
    };

    sortEnd = ({ oldIndex, newIndex }) => {
        this.setState({ busy: true });

        // const forwardProps = { oldIndex, newIndex };
        const forwardProps = {};

        const { fields } = this.props;
        fields.move(oldIndex, newIndex);

        const { fields: updatedFieldsReference } = this.props;
        forwardProps.itemData = updatedFieldsReference.get(newIndex);
        forwardProps.allData = updatedFieldsReference.getAll();

        this.saveSort(updatedFieldsReference.getAll()).finally(() => {
            const { getImagesApi, groupName } = this.props;
            this.startFetchImages(getImagesApi, groupName);

            this.setDraggingState(false);
        });
    };

    saveSort = async (allData = null) => {
        let galleryItems = allData;
        if (!galleryItems) {
            const { fields } = this.props;
            galleryItems = fields.getAll();
        }
        const positions = galleryItems.reduce((result, item, index) => {
            if (item.id) {
                result.push({
                    object_id: item.id,
                    position: index
                });
            }
            return result;
        }, []);

        const formData = {
            positions
        };

        const { galleryApi } = this.props;
        const sort = await restClient.post(`${galleryApi}/sort`, formData);

        return new Promise((resolve, reject) => {
            try {
                resolve(sort);
            } catch (error) {
                reject();
            }
        });
    };

    // eslint-disable-next-line react/sort-comp
    handleRemoveItemUserRequest(id = false) {
        if (id === false) return null;

        this.setState({ busy: true });

        this.removeItem(id).finally(() => {
            const { getImagesApi, groupName } = this.props;
            this.startFetchImages(getImagesApi, groupName);
        });

        return null;
    }

    removeItem(id = false) {
        return new Promise(resolve => {
            const { fields, galleryApi } = this.props;

            const index = this.getAllFieldsSafe().findIndex(
                item => item.id !== undefined && item.id > -1 && item.id === id
            );

            if (index < 0) resolve();

            fields.remove(index);

            restClient.delete(`${galleryApi}/${id}`).finally(() => {
                resolve();
            });
        });
    }

    fetchImagesOnly = (getImagesApi, groupName = null) => {
        return new Promise((resolve, reject) => {
            restClient
                .get(`${getImagesApi}`, {
                    params: {
                        'filters[group_name][equals]': groupName,
                        include: 'mediaObject.mediaObjectVersions',
                        pagination: false
                    }
                })
                .then(response => {
                    return resolve(response);
                })
                .catch(error => {
                    return reject(error);
                });
        });
    };

    startFetchImages(getImagesApi, groupName = null) {
        return new Promise((resolve, reject) => {
            if (!this.isApiDataProvided()) {
                reject();
            }

            this.setState({ busy: true });

            this.fetchImagesOnly(getImagesApi, groupName)
                .then(response => {
                    const images = [...dtoGallerySort(response).data];

                    this.updateFormFieldArray(images);
                    this.setState({ busy: false });
                    return resolve(response);
                })
                .catch(error => {
                    this.setState({ busy: false });
                    return reject(error);
                });
        });
    }

    updateFormFieldArray(newValue = null, index = null) {
        const { updateForm, fields, meta } = this.props;
        const fieldName = index || index === 0 ? `${fields.name}[${index}]` : fields.name;
        updateForm(meta.form, fieldName, newValue);
    }

    handleDeleteModal(id = false) {
        this.setState({ modalDeleteIdOpened: id });
    }

    handleEditorModal(open = false) {
        this.setState({ modalEditorOpened: open });
    }

    handleEditedImage(/* { canvasData = null, positionData = null } */) {
        this.setState({ busy: true });
        const { getImagesApi, groupName } = this.props;
        this.startFetchImages(getImagesApi, groupName);
    }

    setActiveLightboxImage(id) {
        this.setState({ lightboxImageId: id });
    }

    isApiDataProvided(props = this.props) {
        const { attachedToEntity, getImagesApi, galleryApi, groupName } = props;

        return !!(
            groupName &&
            getImagesApi &&
            galleryApi &&
            attachedToEntity &&
            attachedToEntity.id !== undefined &&
            (attachedToEntity.id || attachedToEntity.id === 0) &&
            attachedToEntity.iri !== undefined &&
            attachedToEntity.iri &&
            attachedToEntity.type !== undefined &&
            attachedToEntity.type
        );
    }

    render() {
        const {
            deleteModalTitle,
            deleteModalSubTitle,
            deleteModalButtonDelete,
            deleteModalButtonCancel,
            useImageManager,
            useLightbox,
            versionName
        } = this.props;

        if (!this.isApiDataProvided()) {
            return null;
        }

        const { lightboxImageId } = this.state;

        const { sortDragging, modalDeleteIdOpened, modalEditorOpened, busy } = this.state;

        const lightboxData = this.getLightboxData();

        return (
            <div
                className={`gallery${sortDragging ? ' gallery--dragging' : ''}${
                    busy ? ' gallery--busy' : ''
                }`}
            >
                {!!busy && (
                    <div className="gallery__loader">
                        <ElementLoader />
                    </div>
                )}
                <SortableGalleryContainer
                    helperClass="gallery__item--dragged"
                    axis="xy"
                    lockToContainerEdges
                    distance={3}
                    onSortStart={this.sortStart}
                    onSortEnd={this.sortEnd}
                    lockOffset="5%"
                    // disableAutoscroll
                >
                    {this.getGalleryItems()}
                </SortableGalleryContainer>
                <Modal
                    root="body"
                    variation="small"
                    opened={!!modalDeleteIdOpened || modalDeleteIdOpened === 0}
                    onClose={() => this.handleDeleteModal(false)}
                    title={deleteModalTitle}
                    subtitle={deleteModalSubTitle}
                >
                    <React.Fragment>
                        <Button
                            label={deleteModalButtonDelete}
                            onClick={() => {
                                this.handleRemoveItemUserRequest(modalDeleteIdOpened);
                                this.handleDeleteModal(false);
                            }}
                        />
                        <Button
                            label={deleteModalButtonCancel}
                            onClick={() => this.handleDeleteModal(false)}
                            variation="secondary"
                        />
                    </React.Fragment>
                </Modal>
                {!!useImageManager && (
                    <ImageManager
                        imageId={modalEditorOpened}
                        opened={!!modalEditorOpened || modalEditorOpened === 0}
                        versionName={versionName}
                        onClose={() => this.handleEditorModal(false)}
                        onSaveImage={data => this.handleEditedImage(data)}
                    />
                )}
                {!!useLightbox && !!(lightboxImageId || lightboxImageId === 0) && (
                    <Lightbox
                        mainSrc={lightboxData.mainSrc}
                        nextSrc={lightboxData.nextSrc}
                        prevSrc={lightboxData.prevSrc}
                        onCloseRequest={() => this.setActiveLightboxImage(null)}
                        onMovePrevRequest={() => this.setPrevLightboxImage()}
                        onMoveNextRequest={() => this.setNextLightboxImage()}
                    />
                )}
            </div>
        );
    }
}

Gallery.defaultProps = {
    attachedToEntity: null,
    getImagesApi: null,
    galleryApi: 'api/event-images',
    updateForm: () => {},
    fields: null,
    meta: null,
    maxImageCount: -1,
    featuredKey: null,
    versionName: 'edited',
    canAddImage: true,
    canSort: true,
    canDelete: true,
    useImageManager: true,
    useLightbox: false,
    deleteModalTitle: (
        <FormattedMessage id="Gallery.deleteModal.title" defaultMessage="Remove Image" />
    ),
    deleteModalSubTitle: (
        <FormattedMessage
            id="Gallery.deleteModal.subTitle"
            defaultMessage="Are you sure you wanna remove this image?"
        />
    ),
    deleteModalButtonDelete: (
        <FormattedMessage id="Gallery.deleteModal.buttonDelete" defaultMessage="Remove" />
    ),
    deleteModalButtonCancel: (
        <FormattedMessage id="Gallery.deleteModal.buttonCancel" defaultMessage="Cancel" />
    ),
    emptyGalleryMessage: (
        <FormattedMessage id="Gallery.emptyGalleryMessage" defaultMessage="Gallery is empty!" />
    )
};
Gallery.propTypes = {
    attachedToEntity: PropTypes.shape({
        type: PropTypes.string,
        iri: PropTypes.string,
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    }),
    getImagesApi: PropTypes.string,
    galleryApi: PropTypes.string,
    groupName: PropTypes.string.isRequired,
    updateForm: PropTypes.func,
    id: PropTypes.string.isRequired,
    fields: PropTypes.oneOfType([PropTypes.object]), // Passed by redux-form FieldArray. The fields object is a "pseudo-array".
    meta: PropTypes.oneOfType([PropTypes.object]), // provided by redux-form FieldArray
    maxImageCount: PropTypes.oneOfType([PropTypes.number]),
    featuredKey: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    versionName: PropTypes.string,
    canAddImage: PropTypes.bool, // Control if images can be added
    canSort: PropTypes.bool, // Control if images can be sorted
    canDelete: PropTypes.bool, // Control if items can be removed from the FieldArray
    useImageManager: PropTypes.bool, // Control if ImageManager is used to edit image
    useLightbox: PropTypes.bool, // Control if image can be displayed in lightbox
    deleteModalTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]), // Custom Delete Modal title
    deleteModalSubTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]), // Custom Delete Modal subtitle
    deleteModalButtonDelete: PropTypes.oneOfType([PropTypes.string, PropTypes.object]), // Custom Delete Modal button label
    deleteModalButtonCancel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]), // Custom Delete Modal button label
    emptyGalleryMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]) // Custom "Empty Gallery with no add image button" label
};

const mapDispatchToProps = dispatch => ({
    updateForm: (form, fieldName, value) => dispatch(change(form, fieldName, value))
});

export default connect(
    null,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(Gallery);
