import { takeEvery, put, call } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import { actions as notificationManagerActions } from 'erputils/NotificationManager/NotificationManager.reducer';
import { actions as totalSalesByTicketTypeActions } from './TotalSalesByTicketType.reducer';

/**
 * Fetch TotalSalesByTicketType Data
 * @param  {Object} promise
 * @return {Object} params
 */
export function* fetchData({ promise, widgetId, params }) {
    try {
        const fetchDataAPI = yield restClient.get(`api/orders/report`, {
            headers: { Accept: 'application/json' },
            params
        });
        yield put({
            type: totalSalesByTicketTypeActions.FETCHING_TOTALSALESBYTICKETTYPE_DATA_SUCCESSFUL,
            widgetId
        });
        yield put({
            type: totalSalesByTicketTypeActions.STORE_TOTALSALESBYTICKETTYPE_DATA,
            widgetId,
            response: fetchDataAPI && fetchDataAPI.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: totalSalesByTicketTypeActions.FETCHING_TOTALSALESBYTICKETTYPE_DATA_FAILED,
            widgetId
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error && error.response && error.response.data
        });
        yield call(promise.reject, error && error.response && error.response.data);
    }
}

/**
 * Register action to watcher
 */
export const totalSalesByTicketTypeSaga = [
    takeEvery(totalSalesByTicketTypeActions.START_FETCHING_TOTALSALESBYTICKETTYPE_DATA, fetchData)
];
