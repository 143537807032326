import React from 'react';
import { FormattedMessage } from 'react-intl';
import Svg from 'erpcomponents/Svg';
import Button from 'erpcomponents/Button';
import LayoutManager from 'erputils/LayoutManager';

import './Error404.scss';

const Error404 = () => {
    return (
        <LayoutManager slot="main" layoutType="merge">
            <div className="error-404">
                <div className="error-404__wrapper">
                    <Svg icon="notFound" className="error-404__icon" />
                    <div className="error-404__content">
                        <h1 className="error-404__title">
                            <FormattedMessage
                                id="Error404.title"
                                defaultMessage="Error 404 - PAGE NOT FOUND"
                            />
                        </h1>
                        <Button
                            href="/dashboard"
                            variation="secondary"
                            label={
                                <FormattedMessage
                                    id="Error404.back"
                                    defaultMessage="Back to Dashboard"
                                />
                            }
                        />
                    </div>
                </div>
            </div>
        </LayoutManager>
    );
};

export default Error404;
