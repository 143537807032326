import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import valueValidation from 'erputils/valueValidation';
import { dtoForm } from 'erputils/dto';
import { FormattedMessage } from 'react-intl';
import Form, { Text, Autocomplete, MultiAutocomplete, Switch } from 'erpcore/components/Form';
import LayoutManager from 'erputils/LayoutManager';
import PageHeader from 'erpcomponents/Layout/PageHeader';
import PageContent from 'erpcomponents/Layout/PageContent';
import Button from 'erpcomponents/Button';
import PageLoader from 'erpcomponents/PageLoader';
import { actions as groupsActions } from 'erpcore/screens/Groups/Groups.reducer';
import {
    getSingleGroupData,
    getSingleGroupFetching
} from 'erpcore/screens/Groups/Groups.selectors';
import './GroupEdit.scss';
import GroupSplitForm from 'erpcore/screens/Groups/components/GroupSplitForm';

const displayLabel = field => {
    switch (field) {
        case 'Vodka':
            return 'Vodka';
        case 'Champagne':
            return 'Champagne';
        case 'Prosecco':
            return 'Prosecco';
        case 'BeerbBucket':
            return 'Beerb Bucket';
        case 'Cider':
            return 'Cider';
        case 'SparklingWine':
            return 'Sparkling Wine';
        case 'RedWhiteWine':
            return 'Red / White Wine';
        case 'PlatterOfFood':
            return 'Platter Of Food';
        default:
            return '';
    }
};

const includesDto = data => {
    if (data && data.includes) {
        let parsedIncludes = {};
        Object.keys(data.includes).forEach(key => {
            parsedIncludes = { ...parsedIncludes, [key]: data.includes.key };
        });
    }
    if (data && data.tables) {
        const [table] = data.tables;
        data.tables = table;
    }

    return data;
};

const childGroupsDto = initialValues => {
    const data = [];
    if (initialValues && initialValues.included && initialValues.children) {
        initialValues.children.forEach(item => {
            const groupData = initialValues.included.find(record => record.iri === item);
            data.push(groupData);
        });
        return { ...initialValues, children: data };
    }
    return initialValues;
};
class GroupEdit extends Component {
    constructor(props) {
        super(props);

        this.state = {
            groupSplitFormVisible: false
        };

        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        const { fetchGroupData } = this.props;
        fetchGroupData();
    }

    onSubmit(formData) {
        const { match, dispatch, initialValues } = this.props;
        const includes = formData.includes && formData.includes;
        // send only changed data
        formData = { ...initialValues, ...formData };
        if (formData.group_size) {
            formData.group_size = parseInt(formData.group_size, 10);
        }
        if (formData.children) {
            delete formData.children;
        }

        formData.includes = includes;
        if (formData.tables) {
            const tables = [formData.tables];
            formData.tables = tables;
        }
        if (!formData.tables) {
            formData.tables = [];
        }

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: groupsActions.START_UPDATE_SINGLE_GROUP,
                id: match.params.id,
                formData
            })
        ).catch(error => {
            return error;
        });
    }

    handleGroupSplitForm = () => {
        const { groupSplitFormVisible } = this.state;
        this.setState({ groupSplitFormVisible: !groupSplitFormVisible });
    };

    render() {
        const {
            handleSubmit,
            submitting,
            pristine,
            invalid,
            singleFetching,
            initialValues,
            dispatch
        } = this.props;
        const event =
            initialValues &&
            initialValues.included &&
            initialValues.included.find(record => record.type === 'Event');
        const eventId = event && event.id && event.id;
        const eventIncludes = event && event.tickets_include && event.tickets_include;
        const { groupSplitFormVisible } = this.state;
        const hasParent = initialValues && initialValues.parent;
        // eslint-disable-next-line no-unneeded-ternary
        const hasChildren = initialValues && initialValues.children ? true : false;

        return (
            <LayoutManager slot="main" className="main--narrow" layoutType="merge">
                <PageHeader
                    title={<FormattedMessage id="GroupEdit.title" defaultMessage="Edit Group" />}
                    backButtonLink="/groups"
                />
                {singleFetching === true && <PageLoader />}
                <PageContent>
                    <Form onSubmit={handleSubmit(this.onSubmit)}>
                        <Form.Row>
                            <Form.Columns>
                                <Form.Column autoWidth>
                                    <Field
                                        name="group_completed"
                                        id="group_completed"
                                        fieldProps={{
                                            label: (
                                                <FormattedMessage
                                                    id="GroupEdit.Completed"
                                                    defaultMessage="Group Complete"
                                                />
                                            ),
                                            on: {
                                                label: 'complete-on',
                                                value: true,
                                                id: 'complete-on'
                                            },
                                            off: {
                                                label: 'complete-off',
                                                value: false,
                                                id: 'complete-off'
                                            },
                                            type: 'single',
                                            clearable: true
                                        }}
                                        component={Switch}
                                    />
                                </Form.Column>
                                <Form.Column>
                                    <Field
                                        name="willing_to_share"
                                        id="willing_to_share"
                                        fieldProps={{
                                            label: (
                                                <FormattedMessage
                                                    id="GroupEdit.WillShare"
                                                    defaultMessage="Will share?"
                                                />
                                            ),
                                            on: {
                                                label: 'share-on',
                                                value: true,
                                                id: 'share-on'
                                            },
                                            off: {
                                                label: 'share-off',
                                                value: false,
                                                id: 'share-off'
                                            },
                                            type: 'single',
                                            clearable: true
                                        }}
                                        component={Switch}
                                    />
                                </Form.Column>
                            </Form.Columns>
                        </Form.Row>

                        <Form.Row>
                            <Field
                                name="name"
                                id="name"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="GroupCreate.Grpup name"
                                            defaultMessage="Group Name"
                                        />
                                    ),
                                    clearable: true
                                }}
                                component={Text}
                                validate={valueValidation([{ validator: 'required' }])}
                                fieldAttr={{ required: true }}
                            />
                        </Form.Row>
                        <Form.Row>
                            <Field
                                name="group_size"
                                id="group_size"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="GroupCreate.GroupSize"
                                            defaultMessage="Group Size"
                                        />
                                    )
                                }}
                                fieldAttr={{ required: true }}
                                component={Text}
                                validate={valueValidation([
                                    { validator: 'numericality' },
                                    { validator: 'required' }
                                ])}
                            />
                        </Form.Row>
                        <Form.Row>
                            <Field
                                name="event"
                                id="event"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="GroupCreate.Event"
                                            defaultMessage="Event"
                                        />
                                    ),
                                    options: {
                                        endpoint: 'api/events?pagination=false',
                                        mapData: {
                                            label: 'name',
                                            value: 'iri'
                                        }
                                    }
                                }}
                                component={Autocomplete}
                            />
                        </Form.Row>
                        <Form.Row>
                            <Field
                                name="orders"
                                id="orders"
                                fieldProps={{
                                    label: (
                                        <FormattedMessage
                                            id="GroupCreate.OrderIDs"
                                            defaultMessage="Order IDs"
                                        />
                                    ),
                                    options: {
                                        endpoint: `api/orders?include=event&filters[event.id][equals]=${eventId &&
                                            eventId}&pagination=false`,
                                        mapData: {
                                            label: 'order_number',
                                            value: 'iri'
                                        }
                                    }
                                }}
                                component={MultiAutocomplete}
                            />
                        </Form.Row>
                        {!hasChildren && (
                            <Form.Row>
                                <Field
                                    name="tables" // temporary fix, api needs to be updated
                                    id="tables"
                                    fieldProps={{
                                        label: (
                                            <FormattedMessage
                                                id="GroupCreate.Table"
                                                defaultMessage="Table"
                                            />
                                        ),
                                        options: {
                                            endpoint: `api/tables?include=event&filters[event.id][equals]=${eventId &&
                                                eventId}&pagination=false`,
                                            mapData: {
                                                label: 'table_number',
                                                value: 'iri'
                                            }
                                        }
                                    }}
                                    component={Autocomplete}
                                />
                            </Form.Row>
                        )}
                        {eventIncludes && (
                            <Form.Row>
                                <div className="group-edit__event-includes">
                                    <Form.SectionTitle>Food & Drinks Included</Form.SectionTitle>
                                    <p>
                                        to manage bottle & food options that may be included within
                                        ticket types for this event, please set them in Settings
                                        page
                                    </p>
                                    <Form.Columns breakOnMobile>
                                        {Object.keys(eventIncludes).map(inc => (
                                            <Form.Column autoWidth key={`groupInclude${inc}`}>
                                                <Field
                                                    name={`includes.${inc}`}
                                                    id={`${inc}`}
                                                    fieldProps={{
                                                        label: displayLabel(inc)
                                                    }}
                                                    variation="row"
                                                    component={Text}
                                                    // onChange={this.handleChangeIncludes}
                                                />
                                            </Form.Column>
                                        ))}
                                    </Form.Columns>
                                </div>
                            </Form.Row>
                        )}
                        <Form.Row>
                            <Form.Columns>
                                <Form.Column autoWidth>
                                    <Button
                                        submit
                                        disabled={pristine || invalid}
                                        loading={submitting}
                                        label={
                                            <FormattedMessage
                                                id="EventEdit.Details.Update"
                                                defaultMessage="Update"
                                            />
                                        }
                                    />
                                </Form.Column>
                                {!hasParent && (
                                    <Form.Column>
                                        <Button
                                            variation="secondary"
                                            label="Split Group"
                                            onClick={() => this.handleGroupSplitForm()}
                                        />
                                    </Form.Column>
                                )}
                            </Form.Columns>
                        </Form.Row>
                        {(hasChildren || groupSplitFormVisible) && (
                            <GroupSplitForm
                                dispatch={dispatch}
                                initialValues={initialValues}
                                eventId={eventId}
                            />
                        )}
                    </Form>
                </PageContent>
            </LayoutManager>
        );
    }
}

GroupEdit.defaultProps = {
    submitting: false,
    pristine: false,
    invalid: false,
    fetchGroupData: () => {},
    singleFetching: false,
    initialValues: {}
};

GroupEdit.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.oneOfType([PropTypes.object]).isRequired,
    initialValues: PropTypes.oneOfType([PropTypes.object]),
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    fetchGroupData: PropTypes.func,
    singleFetching: PropTypes.bool
};

GroupEdit = reduxForm({
    form: 'GroupEditForm',
    enableReinitialize: false
})(GroupEdit);

//  Getting initial value populated in the form from the store
const mapStateToProps = (state, ownProps) => ({
    initialValues: includesDto(
        childGroupsDto(dtoForm(getSingleGroupData(state, ownProps.match.params.id)))
    ),
    singleFetching: getSingleGroupFetching(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchGroupData: () => {
        // Getting included data from API by setting params
        const params = {
            include: 'tables,event,event.ticketsInclude,orders,children'
        };
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: groupsActions.START_FETCHING_SINGLE_GROUP,
                id: ownProps.match.params.id,
                params
            });
        }).catch(error => ({ error }));
    }
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(GroupEdit)
);
