import React from 'react';
import PropTypes from 'prop-types';
import Button from 'erpcomponents/Button';
import Svg from 'erpcore/components/Svg';
import { FormattedMessage } from 'react-intl';
import { actions as tablesActions } from 'erpsrc/screens/Tables/Tables.reducer';
import { connect } from 'react-redux';
import './GroupItem.scss';

class GroupItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            optionsVisible: 0
        };
    }

    renderOptions() {
        const {
            id,
            tableLocked,
            tables,
            handleModalRemoveGroup,
            handleModalGroupInfo
        } = this.props;

        const isSeated = tables !== undefined && tables && tables.length;

        return (
            <div className="group-item__options">
                {!tableLocked && <Svg icon="move" className="group-item__options-move" />}
                <Button
                    onClick={() => handleModalGroupInfo(id)}
                    label={
                        <FormattedMessage id="GroupItem.ViewGroup" defaultMessage="View Group" />
                    }
                    variation="secondary"
                    size="small"
                />
                {!!isSeated && (
                    <Button
                        onClick={() => handleModalRemoveGroup(id)}
                        label={<FormattedMessage id="GroupItem.Remove" defaultMessage="Remove" />}
                        variation="secondary"
                        size="small"
                        disabled={tableLocked}
                    />
                )}
            </div>
        );
    }

    renderIcon() {
        // const type = 'alert';
        const type = null;

        if (type === 'alert') {
            return (
                <Svg
                    icon="alert"
                    className="group-item__data-name-icon group-item__data-name-icon--alert"
                />
            );
        }

        if (type === 'note') {
            return <Svg icon="note" className="group-item__data-name-icon" />;
        }

        return null;
    }

    renderPrivateTag() {
        const { willing_to_share: notPrivate } = this.props;

        return (
            <span
                className={`group-item__data-type group-item__data-type--${
                    notPrivate ? 'shared' : 'private'
                }`}
            >
                {notPrivate ? 'SHR' : 'PRV'}{' '}
            </span>
        );
    }

    render() {
        const {
            id,
            name,
            group_size: groupSize,
            group_completed: groupCompleted,
            // willing_to_share: willingToShare,
            // sold,
            // number_of_ticket_types: numberOfTicketTypes,
            // tables,
            orders,
            tableLocked,
            displayPrivateTag,
            displayTableName,
            displayHandle,
            provided,
            snapshot,
            style
        } = this.props;

        const { optionsVisible } = this.state;

        const { innerRef = null, draggableProps = null, dragHandleProps = null } = provided;
        const { isDragging = false } = snapshot;

        const isDisplayHandle = displayHandle && !tableLocked;

        const ticketTypes = [];
        if (orders && orders.length) {
            orders.forEach(order => {
                if (order.ticket_types !== undefined && order.ticket_types.length) {
                    order.ticket_types.forEach(ticketType => {
                        if (
                            ticketType.name !== undefined &&
                            ticketType.name &&
                            !ticketTypes.includes(ticketType.name)
                        ) {
                            ticketTypes.push(ticketType.name);
                        }
                    });
                }
            });
        }

        return (
            <div
                className={`group-item group-item--${groupCompleted ? 'complete' : 'incomplete'}${
                    isDragging ? ' group-item--is-dragging' : ''
                }${isDisplayHandle ? ' group-item--handle-is-displayed' : ''}`}
                data-id={id}
                data-options-visible={optionsVisible}
                ref={innerRef}
                {...draggableProps}
                {...dragHandleProps}
                style={style}
            >
                {!!isDisplayHandle && (
                    <div className="group-item__handle">
                        <Svg icon="move" className="group-item__handle-icon" />
                    </div>
                )}
                <div className="group-item__data">
                    <p className="group-item__data-name">
                        {!!displayPrivateTag && this.renderPrivateTag()}
                        {name}
                    </p>
                    <p className="group-item__data-ticket-types">
                        {ticketTypes.map((ticketType, i) => [i > 0 && ', ', ticketType])}
                    </p>
                    {!!displayTableName && (
                        <p className="group-item__data-table-name">Table: {displayTableName}</p>
                    )}
                </div>

                <p className="group-item__count">{groupSize}</p>

                {this.renderOptions()}
            </div>
        );
    }
}

GroupItem.defaultProps = {
    id: null,
    name: null,
    group_size: null,
    group_completed: null,
    willing_to_share: false,
    sold: null,
    number_of_ticket_types: null,
    tables: null,
    orders: null,
    tableLocked: false,
    private: false,
    displayPrivateTag: true,
    displayTableName: null,
    displayHandle: false,
    provided: {},
    snapshot: {},
    style: {},
    handleModalRemoveGroup: () => {},
    handleModalGroupInfo: () => {}
};

GroupItem.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    group_size: PropTypes.number,
    group_completed: PropTypes.bool,
    willing_to_share: PropTypes.bool,
    sold: PropTypes.number,
    number_of_ticket_types: PropTypes.number,
    tables: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
    orders: PropTypes.oneOfType([PropTypes.array]),
    tableLocked: PropTypes.bool,
    private: PropTypes.bool,
    displayPrivateTag: PropTypes.bool,
    displayTableName: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    displayHandle: PropTypes.bool,
    provided: PropTypes.oneOfType([PropTypes.object]),
    snapshot: PropTypes.oneOfType([PropTypes.object]),
    style: PropTypes.oneOfType([PropTypes.object]),
    handleModalRemoveGroup: PropTypes.func,
    handleModalGroupInfo: PropTypes.func
};

const mapDispatchToProps = dispatch => ({
    handleModalRemoveGroup: index =>
        dispatch({
            type: tablesActions.TOGGLE_TABLE_MODAL_REMOVE_GROUP,
            modalIndex: index
        }),
    handleModalGroupInfo: index =>
        dispatch({
            type: tablesActions.TOGGLE_TABLE_MODAL_GROUP_INFO,
            modalIndex: index
        })
});

export default connect(
    null,
    mapDispatchToProps
)(GroupItem);
