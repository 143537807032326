import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import reduxFormErrorMapper from 'erputils/reduxFormErrorMapper';
import { Field, reduxForm, SubmissionError, reset } from 'redux-form';
import Form, { Text, Email, Phone, Creatable } from 'erpcore/components/Form';
import valueValidation from 'erputils/valueValidation';
import LayoutManager from 'erputils/LayoutManager';
import PageHeader from 'erpcomponents/Layout/PageHeader';
import PageContent from 'erpcomponents/Layout/PageContent';
import Button from 'erpcomponents/Button';
import { actions as customersActions } from 'erpcore/screens/Customers/Customers.reducer';

let CustomerCreate = ({ handleSubmit, submitting, pristine, invalid, onSubmit }) => {
    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <PageHeader
                title={
                    <FormattedMessage id="CustomerCreate.Title" defaultMessage="Add New Customer" />
                }
                backButtonLink="/customers"
            />
            <PageContent>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Row>
                        <Field
                            name="first_name"
                            id="first_name"
                            fieldProps={{
                                label: (
                                    <FormattedMessage
                                        id="CustomerCreate.FirstName"
                                        defaultMessage="First Name"
                                    />
                                ),
                                clearable: true
                            }}
                            component={Text}
                            validate={valueValidation([{ validator: 'required' }])}
                            fieldAttr={{ required: true }}
                        />
                    </Form.Row>
                    <Form.Row>
                        <Field
                            name="last_name"
                            id="last_name"
                            fieldProps={{
                                label: (
                                    <FormattedMessage
                                        id="CustomerCreate.LastName"
                                        defaultMessage="Last Name"
                                    />
                                ),
                                clearable: true
                            }}
                            component={Text}
                            validate={valueValidation([{ validator: 'required' }])}
                            fieldAttr={{ required: true }}
                        />
                    </Form.Row>
                    <Form.Row>
                        <Field
                            name="email"
                            id="email"
                            fieldProps={{
                                label: (
                                    <FormattedMessage
                                        id="CustomerCreate.Email"
                                        defaultMessage="Email"
                                    />
                                ),
                                clearable: true
                            }}
                            component={Email}
                            validate={valueValidation([{ validator: 'required' }])}
                            fieldAttr={{ required: true }}
                        />
                    </Form.Row>
                    <Form.Row>
                        <Field
                            name="phone"
                            id="phone"
                            fieldProps={{
                                label: (
                                    <FormattedMessage
                                        id="CustomerCreate.Phone"
                                        defaultMessage="Phone"
                                    />
                                )
                            }}
                            component={Phone}
                            validate={valueValidation([{ validator: 'required' }])}
                            fieldAttr={{ required: true }}
                        />
                    </Form.Row>
                    <Form.Columns>
                        <Form.Column>
                            <Form.Row>
                                <Field
                                    name="country"
                                    id="country"
                                    fieldProps={{
                                        label: (
                                            <FormattedMessage
                                                id="CustomerCreate.Country"
                                                defaultMessage="Country"
                                            />
                                        ),
                                        options: {
                                            endpoint: '/api/countries',
                                            mapData: {
                                                value: 'iri',
                                                label: 'name'
                                            }
                                        },
                                        clearable: true
                                    }}
                                    component={Creatable}
                                    validate={valueValidation([{ validator: 'required' }])}
                                    fieldAttr={{ required: true }}
                                />
                            </Form.Row>
                        </Form.Column>
                        <Form.Column>
                            <Form.Row>
                                <Field
                                    name="state"
                                    id="state"
                                    fieldProps={{
                                        label: (
                                            <FormattedMessage
                                                id="CustomerCreate.State"
                                                defaultMessage="State"
                                            />
                                        ),
                                        options: {
                                            endpoint: '/api/states',
                                            mapData: {
                                                value: 'iri',
                                                label: 'name'
                                            }
                                        },
                                        clearable: true
                                    }}
                                    component={Creatable}
                                    validate={valueValidation([{ validator: 'required' }])}
                                    fieldAttr={{ required: true }}
                                />
                            </Form.Row>
                        </Form.Column>
                    </Form.Columns>
                    <Form.Columns>
                        <Form.Column>
                            <Form.Row>
                                <Field
                                    name="city"
                                    id="city"
                                    fieldProps={{
                                        label: (
                                            <FormattedMessage
                                                id="CustomerCreate.City"
                                                defaultMessage="City"
                                            />
                                        ),
                                        options: {
                                            endpoint: '/api/cities',
                                            mapData: {
                                                value: 'iri',
                                                label: 'name'
                                            }
                                        },
                                        clearable: true
                                    }}
                                    component={Creatable}
                                    validate={valueValidation([{ validator: 'required' }])}
                                    fieldAttr={{ required: true }}
                                />
                            </Form.Row>
                        </Form.Column>
                        <Form.Column>
                            <Form.Row>
                                <Field
                                    name="zip"
                                    id="zip"
                                    fieldProps={{
                                        label: (
                                            <FormattedMessage
                                                id="CustomerCreate.Zip"
                                                defaultMessage="Zip"
                                            />
                                        ),
                                        clearable: true
                                    }}
                                    component={Text}
                                    validate={valueValidation([{ validator: 'required' }])}
                                    fieldAttr={{ required: true }}
                                />
                            </Form.Row>
                        </Form.Column>
                    </Form.Columns>
                    <Form.Row>
                        <Field
                            name="address"
                            id="address"
                            fieldProps={{
                                clearable: true,
                                icon: 'location',
                                label: (
                                    <FormattedMessage
                                        id="CustomerCreate.Address"
                                        defaultMessage="Street Line 1"
                                    />
                                )
                            }}
                            component={Text}
                            validate={valueValidation([{ validator: 'required' }])}
                            fieldAttr={{ required: true }}
                        />
                    </Form.Row>
                    <Form.Row>
                        <Field
                            name="address2"
                            id="address2"
                            fieldProps={{
                                clearable: true,
                                icon: 'location',
                                label: (
                                    <FormattedMessage
                                        id="CustomerCreate.Address2"
                                        defaultMessage="Street Line 2"
                                    />
                                )
                            }}
                            component={Text}
                        />
                    </Form.Row>
                    <Form.Row>
                        <Button
                            submit
                            disabled={pristine || invalid}
                            loading={submitting}
                            label={
                                <FormattedMessage id="CustomerCreate.Add" defaultMessage="Add" />
                            }
                        />
                    </Form.Row>
                </Form>
            </PageContent>
        </LayoutManager>
    );
};

CustomerCreate.defaultProps = {
    submitting: false,
    pristine: false,
    invalid: false,
    onSubmit: () => {}
};

CustomerCreate.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool
};

CustomerCreate = reduxForm({
    form: 'CustomerCreateForm',
    enableReinitialize: true
})(CustomerCreate);

const mapDispatchToProps = dispatch => ({
    onSubmit: formData => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: customersActions.START_CREATE_CUSTOMER,
                formData
            });
        })
            .then(() => {
                dispatch(reset('CustomerCreateForm'));
            })
            .catch(error => {
                throw new SubmissionError(reduxFormErrorMapper(error));
            });
    }
});

export default connect(
    null,
    mapDispatchToProps
)(CustomerCreate);
