const colors = [
    '#de102d',
    '#000000',
    '#ff3e43',
    '#f5b56a',
    '#fb6f75',
    '#779ee0',
    '#81dbbe',
    '#9194bd'
];

const chartColors = [
    '#de102d',
    '#000000',
    '#f5b56a',
    '#9194bd',
    '#81dbbe',
    '#779ee0',
    '#ff3e43',
    '#fb6f75'
];

export default colors;
export { chartColors };
