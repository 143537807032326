import { takeLatest, call, put } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import { actions as ticketsPerTicketType } from './TicketsPerTicketType.reducer';

/**
 * Fetch Ticketzone Data
 * @param  {Object} promise
 * @return {Object} params
 */
export function* fetchTicketzoneData({ promise, widgetId, eventID }) {
    try {
        const params = {
            time_start: '1970-01-01 00:00:00',
            time_end: '2100-01-1 23:59:59'
        };

        const fetchTicketzoneDataAPI = yield restClient.get(
            `api/ticketzone-sales/stats/${eventID}`,
            { params }
        );

        yield put({
            type: ticketsPerTicketType.FETCHING_TICKETS_PER_TICKET_TYPE_DATA_SUCCESSFUL,
            widgetId
        });
        yield put({
            type: ticketsPerTicketType.STORE_TICKETS_PER_TICKET_TYPE_DATA,
            widgetId,
            response: fetchTicketzoneDataAPI.data && fetchTicketzoneDataAPI.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: ticketsPerTicketType.FETCHING_TICKETS_PER_TICKET_TYPE_DATA_FAILED,
            widgetId
        });
        yield call(promise.reject, error && error.response && error.response.data);
    }
}

/**
 * Register action to watcher
 */
export const ticketsPerTicketTypeSaga = [
    takeLatest(
        ticketsPerTicketType.START_FETCHING_TICKETS_PER_TICKET_TYPE_DATA,
        fetchTicketzoneData
    )
];
