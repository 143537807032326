export const actions = {
    START_FETCHING_GROUPS: 'START_FETCHING_GROUPS',
    FETCHING_SUCCESSFUL_GROUPS: 'FETCHING_SUCCESSFUL_GROUPS',
    FETCHING_FAILED_GROUPS: 'FETCHING_FAILED_GROUPS',
    START_CREATE_GROUP: 'START_CREATE_GROUP',
    CREATE_GROUP_SUCCESSFUL: 'CREATE_GROUP_SUCCESSFUL',
    CREATE_GROUP_FAILED: 'CREATE_GROUP_FAILED',
    START_FETCHING_SINGLE_GROUP: 'START_FETCHING_SINGLE_GROUP',
    FETCH_SINGLE_GROUP_SUCCESSFUL: 'FETCH_SINGLE_GROUP_SUCCESSFUL',
    FETCH_SINGLE_GROUP_FAILED: 'FETCH_SINGLE_GROUP_FAILED',
    REQUEST_UPDATE_SINGLE_GROUP_DND: 'REQUEST_UPDATE_SINGLE_GROUP_DND',
    START_UPDATE_SINGLE_GROUP: 'START_UPDATE_SINGLE_GROUP',
    UPDATE_SINGLE_GROUP_SUCCESSFUL: 'UPDATE_SINGLE_GROUP_SUCCESSFUL',
    UPDATE_SINGLE_GROUP_FAILED: 'UPDATE_SINGLE_GROUP_FAILED',
    START_DELETE_SINGLE_GROUP: 'START_DELETE_SINGLE_GROUP',
    DELETE_SINGLE_GROUP_SUCCESSFUL: 'DELETE_SINGLE_GROUP_SUCCESSFUL',
    DELETE_SINGLE_GROUP_FAILED: 'DELETE_SINGLE_GROUP_FAILED',
    STORE_SINGLE_GROUP_DATA: 'STORE_SINGLE_GROUP_DATA',
    SINGLE_GROUP_CHECKED: 'SINGLE_GROUP_CHECKED',
    CHECK_ALL_GROUPS: 'CHECK_ALL_GROUPS',
    STORE_CHECKED_GROUPS: 'STORE_CHECKED_GROUPS',
    START_SPLIT_SINGLE_GROUP: 'START_SPLIT_SINGLE_GROUP',
    SPLIT_SINGLE_GROUP_SUCCESSFUL: 'SPLIT_SINGLE_GROUP_SUCCESSFUL',
    SPLIT_SINGLE_GROUP_FAILED: 'SPLIT_SINGLE_GROUP_FAILED',

    START_BULK_ACTION: 'START_BULK_ACTION',
    BULK_ACTION_SUCCESSFUL: 'BULK_ACTION_SUCCESSFUL',
    BULK_ACTION_FAILED: 'BULK_ACTION_FAILED',
    START_BULK_ACTIONS_UPDATE_GROUPS: 'START_BULK_ACTIONS_UPDATE_GROUPS',
    BULK_ACTIONS_UPDATE_GROUPS_SUCCESS: 'BULK_ACTIONS_UPDATE_GROUPS_SUCCESS',
    BULK_ACTIONS_UPDATE_GROUPS_FAILED: 'BULK_ACTIONS_UPDATE_GROUPS_FAILED',

    START_MERGE_GROUPS: 'START_MERGE_GROUPS',
    MERGE_GROUPS_SUCCESSFUL: 'MERGE_GROUPS_SUCCESSFUL',
    MERGE_GROUPS_FAILED: 'MERGE_GROUPS_FAILED',

    START_BULK_ACTIONS_DELETE_GROUPS: 'START_BULK_ACTIONS_DELETE_GROUPS',
    BULK_ACTIONS_DELETE_GROUPS_SUCCESS: 'BULK_ACTIONS_DELETE_GROUPS_SUCCESS',
    BULK_ACTIONS_DELETE_GROUPS_FAILED: 'BULK_ACTIONS_DELETE_GROUPS_FAILED'
};

export const initialState = {
    listingFetching: false,
    listingData: [],
    listingMeta: {},
    listingError: [],
    singleFetching: false,
    singleError: false,
    groups: [],
    submittingBulkAction: false
};

export default (state = initialState, { type, id, response }) => {
    switch (type) {
        case actions.START_FETCHING_GROUPS: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_GROUPS: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response,
                groups: response.data
            };
        }
        case actions.FETCHING_FAILED_GROUPS: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        case actions.START_CREATE_GROUP: {
            return state;
        }
        case actions.START_FETCHING_SINGLE_GROUP: {
            return {
                ...state,
                singleFetching: true,
                group: response
            };
        }
        case actions.FETCH_SINGLE_GROUP_SUCCESSFUL: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.FETCH_SINGLE_GROUP_FAILED: {
            return {
                ...state,
                singleFetching: false,
                singleError: response
            };
        }
        case actions.UPDATE_SINGLE_GROUP_FAILED: {
            return {
                ...state,
                singleError: response
            };
        }
        case actions.BULK_ACTION_FAILED: {
            return {
                ...state,
                singleError: response
            };
        }
        case actions.STORE_CHECKED_GROUPS: {
            return {
                ...state,
                checked: response
            };
        }
        case actions.START_BULK_ACTIONS_UPDATE_GROUPS: {
            return {
                ...state,
                submittingBulkAction: true
            };
        }
        case actions.BULK_ACTIONS_UPDATE_GROUPS_SUCCESS: {
            return {
                ...state,
                submittingBulkAction: false
            };
        }
        case actions.BULK_ACTIONS_UPDATE_GROUPS_FAILED: {
            return {
                ...state,
                submittingBulkAction: false
            };
        }
        case actions.START_SPLIT_SINGLE_GROUP: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.SPLIT_SINGLE_GROUP_SUCCESSFUL: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.SPLIT_SINGLE_GROUP_FAILED: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.STORE_SINGLE_GROUP_DATA: {
            return Object.assign({}, state, {
                [id]: response.data
            });
        }
        default:
            return state;
    }
};
