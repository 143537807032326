import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import LayoutManager from 'erputils/LayoutManager';
import PageHeader from 'erpcomponents/Layout/PageHeader';
import PageContent from 'erpcomponents/Layout/PageContent';
import TotalTickets24h from 'erpcomponents/Widgets/TotalTickets24h';
import TicketSocketTickets24h from 'erpcomponents/Widgets/TicketSocketTickets24h';
import TotalSalesByEventBar from 'erpcomponents/Widgets/TotalSalesByEventBar';
import SalesOverview from 'erpcomponents/Widgets/SalesOverview';
import SalesCycle from 'erpcomponents/Widgets/SalesCycle';
import TicketsOverview from 'erpcomponents/Widgets/TicketsOverview';
import EventSales from 'erpcomponents/Widgets/EventSales';
import TicketTypes from 'erpcomponents/Widgets/TicketTypes';
import Form, { Select, Autocomplete } from 'erpcomponents/Form';
import { Field, reduxForm, getFormValues, change } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { actions as dashboardActions } from 'erpcore/screens/Dashboard/Dashboard.reducer';

import { getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import { hasPermission } from 'erpcore/utils/RolesManager';

class OverallSales extends Component {
    componentDidMount() {
        const { filterParams, dispatch } = this.props;
        const { dateRange: dateFilter, eventSeriesFilter } = filterParams;

        if (dateFilter && dateFilter !== Infinity) {
            dispatch(change('OverallSalesForm', 'filter', dateFilter));
            this.setLocalStorage('dateRange', dateFilter);
        }

        if (eventSeriesFilter) {
            dispatch(change('OverallSalesForm', 'eventSeriesFilter', eventSeriesFilter));
            this.setLocalStorage('eventSeries', eventSeriesFilter);
        }
    }

    setLocalStorage(name, value) {
        localStorage.setItem(name, value);
    }

    renderFormFields() {
        const { meData } = this.props;
        return (
            <Form.Row>
                <Field
                    name="filter"
                    id="filter"
                    fieldProps={{
                        label: (
                            <FormattedMessage
                                id="Dashboards.OverallSales.Filter.DateRange"
                                defaultMessage="Date Range"
                            />
                        ),
                        clearable: false,
                        options: [
                            {
                                label: (
                                    <FormattedMessage
                                        id="Dashboards.OverallSales.Filter.DateRange.Last24h"
                                        defaultMessage="Last 24h"
                                    />
                                ),
                                value: 1
                            },
                            {
                                label: (
                                    <FormattedMessage
                                        id="Dashboards.OverallSales.Filter.DateRange.Last7Days"
                                        defaultMessage="Last 7 Days"
                                    />
                                ),
                                value: 7
                            },
                            {
                                label: (
                                    <FormattedMessage
                                        id="Dashboards.OverallSales.Filter.DateRange.Last30Days"
                                        defaultMessage="Last 30 Days"
                                    />
                                ),
                                value: 30
                            },
                            {
                                label: (
                                    <FormattedMessage
                                        id="Dashboards.OverallSales.Filter.DateRange.Last3Months"
                                        defaultMessage="Last 3 Months"
                                    />
                                ),
                                value: 90
                            },
                            {
                                label: (
                                    <FormattedMessage
                                        id="Dashboards.OverallSales.Filter.DateRange.Last6Months"
                                        defaultMessage="Last 6 Months"
                                    />
                                ),
                                value: 180
                            },
                            {
                                label: (
                                    <FormattedMessage
                                        id="Dashboards.OverallSales.Filter.DateRange.Last12Months"
                                        defaultMessage="Last 12 Months"
                                    />
                                ),
                                value: 365
                            },
                            {
                                label: (
                                    <FormattedMessage
                                        id="Dashboards.OverallSales.Filter.DateRange.AllTime"
                                        defaultMessage="All Time"
                                    />
                                ),
                                value: Infinity
                            }
                        ]
                    }}
                    onChange={value => this.setLocalStorage('dateRange', parseInt(value, 10))}
                    component={Select}
                />
                {hasPermission('CAN_MANAGE_EVENT_BUNDLE_EVENTSERIES', meData) && (
                    <Field
                        name="eventSeriesFilter"
                        id="eventSeriesFilter"
                        fieldProps={{
                            label: (
                                <FormattedMessage
                                    id="Dashboards.OverallSales.Filter.EventSeries"
                                    defaultMessage="Event Series"
                                />
                            ),
                            clearable: false,
                            options: {
                                endpoint: `/api/event-series`,
                                mapData: { value: 'iri', label: 'name' }
                            }
                        }}
                        onChange={value => this.setLocalStorage('eventSeries', value)}
                        component={Autocomplete}
                    />
                )}

                <Field
                    name="soldByFilter"
                    id="soldByFilter"
                    fieldProps={{
                        label: (
                            <FormattedMessage
                                id="Dashboards.OverallSales.Filter.SoldBy"
                                defaultMessage="Sold By"
                            />
                        ),
                        clearable: true,
                        options: {
                            endpoint: `/api/resellers`,
                            mapData: { value: 'iri', label: 'reseller_name' }
                        }
                    }}
                    onChange={value => this.setLocalStorage('soldBy', value)}
                    component={Autocomplete}
                />
            </Form.Row>
        );
    }

    render() {
        const { filterValues } = this.props;

        return (
            <LayoutManager slot="main" layoutType="merge">
                <PageHeader
                    title={
                        <FormattedMessage
                            id="Dashboards.OverallSales.title"
                            defaultMessage="Overall Sales"
                        />
                    }
                />
                <PageContent>
                    <Form className="overall-sales">{this.renderFormFields()}</Form>
                    {filterValues.filter && (
                        <PageContent.Row>
                            <TotalTickets24h
                                id="totalTickets24h"
                                size="3"
                                eventSeries={filterValues.eventSeriesFilter}
                                soldBy={filterValues.soldByFilter}
                            />
                            <TicketSocketTickets24h
                                id="ticketSocketTickets24h"
                                size="3"
                                eventSeries={filterValues.eventSeriesFilter}
                                soldBy={filterValues.soldByFilter}
                            />
                            <SalesCycle
                                id="salesCycle"
                                size="3"
                                eventSeries={filterValues.eventSeriesFilter}
                                soldBy={filterValues.soldByFilter}
                            />
                            <TicketTypes
                                id="ticketTypes"
                                size="3"
                                eventSeries={filterValues.eventSeriesFilter}
                                soldBy={filterValues.soldByFilter}
                            />
                            <TotalSalesByEventBar id="totalSalesByEvent" size="12" />
                            <EventSales id="eventSales" size="12" />
                            <SalesOverview
                                id="salesOverview"
                                size="12"
                                dateRange={filterValues.filter}
                                eventSeries={filterValues.eventSeriesFilter}
                                soldBy={filterValues.soldByFilter}
                            />
                            <TicketsOverview
                                id="ticketsOverview"
                                size="12"
                                dateRange={filterValues.filter}
                                eventSeries={filterValues.eventSeriesFilter}
                                soldBy={filterValues.soldByFilter}
                            />
                        </PageContent.Row>
                    )}
                </PageContent>
            </LayoutManager>
        );
    }
}

const existsInLocalStorage = (name, defaultValue) => {
    if (localStorage.getItem(name) && localStorage.getItem(name) !== 'null') {
        if (name === 'dateRange' && localStorage.getItem(name) !== 'NaN') {
            return parseInt(localStorage.getItem(name), 10);
        }

        if (localStorage.getItem(name) === 'NaN') {
            return Infinity;
        }

        return localStorage.getItem(name);
    }

    return defaultValue;
};

OverallSales.defaultProps = {
    filterValues: { filter: Infinity, eventSeriesFilter: null },
    filterParams: {},
    dispatch: () => {},
    meData: {}
};

OverallSales.propTypes = {
    filterValues: PropTypes.oneOfType([PropTypes.object]),
    filterParams: PropTypes.oneOfType([PropTypes.object]),
    dispatch: PropTypes.func,
    meData: PropTypes.oneOfType([PropTypes.object])
};

//  Getting initial value populated in the form from the store
const mapStateToProps = (state, ownProps) => {
    let eventSeriesFilter = null;
    const meData = getMeData(state);
    if (hasPermission('CAN_MANAGE_EVENT_BUNDLE_EVENTSERIES', meData)) {
        if (ownProps?.location?.state?.filterParams?.eventIri) {
            eventSeriesFilter = ownProps?.location?.state?.filterParams?.eventIri;
        } else {
            eventSeriesFilter = existsInLocalStorage('eventSeries', '/api/event-series/1');
        }
    }

    return {
        filterValues: getFormValues('OverallSalesForm')(state),
        initialValues: {
            filter:
                ownProps?.location?.state?.filterParams?.dateRange ||
                existsInLocalStorage('dateRange', Infinity),
            eventSeriesFilter,
            soldByFilter: existsInLocalStorage('soldBy', null)
        },
        filterParams: ownProps?.location?.state?.filterParams,
        meData
    };
};

const mapDispatchToProps = dispatch => ({
    fetchEventSeries: () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: dashboardActions.START_FETCHING_DASHBOARD_EVENT_SERIES
            });
        });
    }
});

OverallSales = reduxForm(
    {
        form: 'OverallSalesForm',
        enableReinitialize: true
    },
    mapStateToProps
)(OverallSales);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OverallSales);
