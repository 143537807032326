import React from 'react';
import { Field, Fields } from 'redux-form';
import Form, { Text, Switch } from 'erpcore/components/Form';
import Collapse from 'erpcore/components/Collapse';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from 'erpcomponents/Button';
import valueValidation from 'erputils/valueValidation';
import { resolveObjectPathByString } from 'erputils/utils';
import parseInteger from 'erputils/parseInteger';
import { actions as ticketTypesActions } from 'erpcore/screens/Events/screens/EventEdit/TicketTypes.reducer';
import MergeTicketTypesModal from 'erpcore/screens/Events/screens/EventEdit/components/MergeTicketTypesModal';
import './TicketTypeRepeaterItem.scss';

const displayLabel = field => {
    switch (field) {
        case 'Vodka':
            return 'Vodka';
        case 'Champagne':
            return 'Champagne';
        case 'Prosecco':
            return 'Prosecco';
        case 'BeerbBucket':
            return 'Beerb Bucket';
        case 'Cider':
            return 'Cider';
        case 'SparklingWine':
            return 'Sparkling Wine';
        case 'RedWhiteWine':
            return 'Red / White Wine';
        case 'PlatterOfFood':
            return 'Platter Of Food';
        default:
            return '';
    }
};
class TicketTypeRepeaterItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pendingUpdates: {},
            mergeTTModal: false
        };

        this.debounceDelay = 300;
    }

    handleModal = () => {
        const { mergeTTModal } = this.state;
        this.setState({ mergeTTModal: !mergeTTModal });
    };

    dispatchUpdate = updates => {
        const { dispatch, data } = this.props;
        const { id = false } = data;

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: ticketTypesActions.REQUEST_UPDATE_SINGLE_TICKET_TYPE,
                formData: updates,
                id,
                debounceDelay: this.debounceDelay
            })
        )
            .catch(() => {
                this.removePendingUpdates();
            })
            .then(() => {
                this.removePendingUpdates();
            });
    };

    handleChangeIncludes = (event, newValue, previosValue, name) => {
        const { data } = this.props;
        const { includes } = data;

        const nameSplit = name.split('.');
        const parsedFieldName = nameSplit[nameSplit.length - 1];
        // const newValues = {
        //     [parsedFieldName]: includes[parsedFieldName] ? '2' : newValue
        // };
        // console.log('newValues', newValues);

        const updates = {
            includes: {
                ...includes,
                [parsedFieldName]: newValue
            }
        };
        this.dispatchUpdate(updates);
    };

    handleChange = (event, newValue, previousValue, name) => {
        const { data, member } = this.props;
        const { id = false } = data;

        if (id) {
            const nameForApi = name.replace(`${member}.`, '');
            const updates = { [nameForApi]: newValue };
            const { pendingUpdates } = this.state;
            const mergedUpdates = { ...pendingUpdates, ...updates };
            this.setState({ pendingUpdates: mergedUpdates });
            this.dispatchUpdate(mergedUpdates);
        }
    };

    removePendingUpdates() {
        this.setState({ pendingUpdates: {} });
    }

    renderAttendees = fields => {
        const { member } = this.props;

        const isDisabled =
            resolveObjectPathByString(fields, member).is_package.input.value !== true;

        return (
            <Field
                name={`${member}.attendees`}
                id="attendees"
                parse={parseInteger}
                fieldAttr={{
                    disabled: isDisabled
                }}
                fieldProps={{
                    label: 'Attendees per Ticket'
                }}
                component={Text}
                onChange={this.handleChange}
            />
        );
    };

    isValid(validatorFunctions = [], value) {
        const errors = validatorFunctions.map(validatorFunction => {
            return validatorFunction(value);
        });
        const isNotValid = errors.find(item => item !== undefined);

        return !isNotValid;
    }

    render() {
        const { member, isNewRepeaterItem, data } = this.props;
        const { initialValues } = data;
        const { mergeTTModal } = this.state;
        const memberIndex = member && member.match(/\d+/g) && member.match(/\d+/g)[0];
        const updatedInitialValues = {
            ...initialValues,
            primary_ticket_type_name:
                initialValues.ticket_types &&
                initialValues.ticket_types[memberIndex] &&
                initialValues.ticket_types[memberIndex].name,
            primary_ticket_type:
                initialValues.ticket_types && initialValues.ticket_types[memberIndex]
        };
        const eventIncludes = initialValues && initialValues.tickets_include;
        return (
            <Collapse initiallyExpanded={!!isNewRepeaterItem} hideToggle={!!isNewRepeaterItem}>
                {!!isNewRepeaterItem && <h3>Add New Ticket</h3>}
                <Collapse.Visible>
                    {mergeTTModal && (
                        <MergeTicketTypesModal
                            initialValues={updatedInitialValues}
                            member={member && member}
                        />
                    )}

                    <Form.Row>
                        <Form.Columns breakOnMobile>
                            <Form.Column className="ticket-type-repeater-item__ticket-name">
                                <Field
                                    name={`${member}.name`}
                                    id="name"
                                    fieldProps={{
                                        label: 'Ticket Name'
                                    }}
                                    fieldAttr={{ required: true }}
                                    component={Text}
                                    validate={valueValidation([{ validator: 'required' }])}
                                    onChange={(event, newValue, previousValue, name) => {
                                        /*
                                         * redux-form validation is started after the Field onChange is done,
                                         * so the validity of the field has to be checked manually
                                         * before handling the change data further
                                         */
                                        if (
                                            this.isValid(
                                                valueValidation([{ validator: 'required' }]),
                                                newValue
                                            )
                                        ) {
                                            this.handleChange(event, newValue, previousValue, name);
                                        }
                                    }}
                                />
                            </Form.Column>
                            <Form.Column>
                                <Field
                                    name={`${member}.max_tickets_to_sell`}
                                    id="max_tickets_to_sell"
                                    parse={parseInteger}
                                    fieldProps={{
                                        label: 'Max. Tickets to Sell'
                                    }}
                                    component={Text}
                                    onChange={this.handleChange}
                                />
                            </Form.Column>
                            <Form.Column autoWidth>
                                <Form.Row>
                                    <Form.Columns>
                                        <Form.Column autoWidth>
                                            <Field
                                                name={`${member}.status`}
                                                id="status"
                                                fieldProps={{
                                                    label: 'Live',
                                                    on: {
                                                        label: 'Live',
                                                        value: 'live',
                                                        id: 'status-live'
                                                    },
                                                    off: {
                                                        label: 'Offline',
                                                        value: null,
                                                        id: 'status-offline'
                                                    },
                                                    type: 'single'
                                                }}
                                                variation="row"
                                                component={Switch}
                                                onChange={this.handleChange}
                                            />
                                        </Form.Column>
                                        <Form.Column autoWidth>
                                            <Field
                                                name={`${member}.has_seating`}
                                                id="has_seating"
                                                fieldProps={{
                                                    label: 'Seating',
                                                    on: {
                                                        label: 'On',
                                                        value: true,
                                                        id: 'seating-on'
                                                    },
                                                    off: {
                                                        label: 'Off',
                                                        value: false,
                                                        id: 'seating-of'
                                                    },
                                                    type: 'single'
                                                }}
                                                variation="row"
                                                component={Switch}
                                                onChange={this.handleChange}
                                            />
                                        </Form.Column>
                                    </Form.Columns>
                                </Form.Row>
                            </Form.Column>
                        </Form.Columns>
                    </Form.Row>
                </Collapse.Visible>
                <Collapse.Expandable>
                    <Form.Row>
                        <Form.Columns breakOnMobile>
                            <Form.Column>
                                <Field
                                    name={`${member}.ticket_socket_id`}
                                    id="ticket_socket_id"
                                    fieldProps={{
                                        label: 'Ticketsocket Ticket ID'
                                    }}
                                    component={Text}
                                    onChange={this.handleChange}
                                />
                            </Form.Column>
                        </Form.Columns>
                    </Form.Row>
                    <Form.Row>
                        <Form.Columns breakOnMobile>
                            <Form.Column>
                                <Field
                                    name={`${member}.get_out_id`}
                                    id="get_out_id"
                                    fieldProps={{
                                        label: 'GetOut Ticket ID'
                                    }}
                                    component={Text}
                                    onChange={this.handleChange}
                                />
                            </Form.Column>
                        </Form.Columns>
                    </Form.Row>
                    <Form.Row>
                        <Form.Columns breakOnMobile>
                            <Form.Column autoWidth>
                                <Field
                                    name={`${member}.is_package`}
                                    id="is_package"
                                    fieldProps={{
                                        label: 'Package',
                                        on: { label: 'Yes', value: true, id: 'package-true' },
                                        off: {
                                            label: 'No',
                                            value: false,
                                            id: 'package-false'
                                        },
                                        type: 'single'
                                    }}
                                    variation="row"
                                    component={Switch}
                                    onChange={this.handleChange}
                                />
                            </Form.Column>
                            <Form.Column autoWidth>
                                <Fields
                                    names={[`${member}.is_package`, `${member}.attendees`]}
                                    component={this.renderAttendees}
                                />
                            </Form.Column>
                            <Form.Column autoWidth>
                                <Field
                                    name={`${member}.private`}
                                    id="private"
                                    fieldProps={{
                                        label: 'Private',
                                        on: { label: 'Yes', value: true, id: 'private-true' },
                                        off: {
                                            label: 'No',
                                            value: false,
                                            id: 'private-false'
                                        },
                                        type: 'single'
                                    }}
                                    variation="row"
                                    component={Switch}
                                    onChange={this.handleChange}
                                />
                            </Form.Column>
                            {!isNewRepeaterItem && (
                                <Form.Column autoWidth>
                                    <Button
                                        variation="tertiary"
                                        className="ticket-type-repeater-item__merge-ticket-types__button"
                                        size="small"
                                        label="Merge"
                                        labelOnlyAria
                                        iconName="merge"
                                        onClick={() => this.handleModal()}
                                    />
                                </Form.Column>
                            )}
                        </Form.Columns>
                    </Form.Row>
                    {eventIncludes && (
                        <Form.Row>
                            <div className="ticket-type-repeater-item__event-includes">
                                <Form.SectionTitle>Tickets include</Form.SectionTitle>
                                <p>
                                    to manage bottle & food options that may be included within
                                    ticket types for this event, please set them in Settings page
                                </p>
                                <Form.Columns breakOnMobile>
                                    {Object.keys(eventIncludes).map(inc => (
                                        <Form.Column autoWidth>
                                            <Field
                                                name={`${member}.includes.${inc}`}
                                                id={`${inc}`}
                                                fieldProps={{
                                                    label: displayLabel(inc)
                                                }}
                                                variation="row"
                                                component={Text}
                                                onChange={this.handleChangeIncludes}
                                            />
                                        </Form.Column>
                                    ))}
                                </Form.Columns>
                            </div>
                        </Form.Row>
                    )}
                </Collapse.Expandable>
            </Collapse>
        );
    }
}

TicketTypeRepeaterItem.defaultProps = {
    member: null,
    isNewRepeaterItem: null,
    data: null
    // initialValues: null
};

TicketTypeRepeaterItem.propTypes = {
    dispatch: PropTypes.func.isRequired,
    member: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isNewRepeaterItem: PropTypes.bool,
    data: PropTypes.oneOfType([PropTypes.object])
    // initialValues: PropTypes.oneOfType([PropTypes.object])
};

export default connect()(TicketTypeRepeaterItem);
