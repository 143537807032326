export const actions = {
    START_SYNC_TICKET_SOCKET_API: 'START_SYNC_TICKET_SOCKET_API',
    SYNC_TICKET_SOCKET_API_SUCCESSFUL: 'SYNC_TICKET_SOCKET_API_SUCCESSFUL',
    SYNC_TICKET_SOCKET_API_FAILED: 'SYNC_TICKET_SOCKET_API_FAILED',
    STORE_TICKET_SOCKET_API_URL_DATA: 'STORE_TICKET_SOCKET_API_URL_DATA',
    STORE_TICKET_SOCKET_API_INTERVAL_DATA: 'STORE_TICKET_SOCKET_API_INTERVAL_DATA',
    START_UPDATE_TICKET_SOCKET_API: 'START_UPDATE_TICKET_SOCKET_API',
    UPDATE_TICKET_SOCKET_API_SUCCESSFUL: 'UPDATE_TICKET_SOCKET_API_SUCCESSFUL',
    UPDATE_TICKET_SOCKET_API_FAILED: 'UPDATE_TICKET_SOCKET_API_FAILED',
    START_FETCHING_TICKET_SOCKET_API: 'START_FETCHING_TICKET_SOCKET_API',
    FETCHING_TICKET_SOCKET_API_SUCCESSFUL: 'FETCHING_TICKET_SOCKET_API_SUCCESSFUL',
    FETCHING_TICKET_SOCKET_API_FAILED: 'FETCHING_TICKET_SOCKET_API_FAILED',
    STORE_TICKET_SOCKET_API_CREATE_NEW_DATA: 'STORE_TICKET_SOCKET_API_CREATE_NEW_DATA',
    STORE_TICKET_SOCKET_API_AUTOMATED_SYNC_DATA: 'STORE_TICKET_SOCKET_API_AUTOMATED_SYNC_DATA',
    STORE_TICKET_SOCKET_API_SINCE_LAST_IMPORT_DATA:
        'STORE_TICKET_SOCKET_API_SINCE_LAST_IMPORT_DATA',
    STORE_TICKET_SOCKET_API_DATA: ' STORE_TICKET_SOCKET_API_DATA',
    STORE_TICKET_SOCKET_API_IMPORT_INTERVAL_DATA: 'STORE_TICKET_SOCKET_API_IMPORT_INTERVAL_DATA',
    STORE_TICKET_SOCKET_API_IMPORT_FROM_DATA: 'STORE_TICKET_SOCKET_API_IMPORT_FROM_DATA',
    STORE_TICKET_SOCKET_API_IMPORT_ONLY_EVENTS_DATA:
        'STORE_TICKET_SOCKET_API_IMPORT_ONLY_EVENTS_DATA'
};

export const initialState = {
    sync: false,
    listingFetching: false,
    listingData: [],
    listingMeta: {},
    listingError: [],
    singleFetching: false,
    data: []
};

export default (state = initialState, { type, response }) => {
    switch (type) {
        case actions.START_SYNC_TICKET_SOCKET_API: {
            return {
                ...state,
                sync: true
            };
        }
        case actions.SYNC_TICKET_SOCKET_API_SUCCESSFUL: {
            return {
                ...state
            };
        }
        case actions.SYNC_TICKET_SOCKET_API_FAILED: {
            return {
                ...state,
                singleError: response
            };
        }
        case actions.UPDATE_TICKET_SOCKET_API_FAILED: {
            return {
                ...state,
                singleError: response
            };
        }
        case actions.START_FETCHING_TICKET_SOCKET_API: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.FETCHING_TICKET_SOCKET_API_SUCCESSFUL: {
            return {
                ...state,
                singleFetching: false
            };
        }

        case actions.FETCHING_TICKET_SOCKET_API_FAILED: {
            return {
                ...state,
                singleError: response,
                singleFetching: false
            };
        }

        case actions.STORE_TICKET_SOCKET_API_CREATE_NEW_DATA: {
            return {
                ...state,
                createNewData: response.data
            };
        }

        case actions.STORE_TICKET_SOCKET_API_AUTOMATED_SYNC_DATA: {
            return {
                ...state,
                automatedSync: response.data
            };
        }
        case actions.STORE_TICKET_SOCKET_API_SINCE_LAST_IMPORT_DATA: {
            return {
                ...state,
                sinceLastImport: response.data
            };
        }

        case actions.STORE_TICKET_SOCKET_API_IMPORT_INTERVAL_DATA: {
            return {
                ...state,
                importInterval: response.data
            };
        }
        case actions.STORE_TICKET_SOCKET_API_IMPORT_FROM_DATA: {
            return {
                ...state,
                importFrom: response.data
            };
        }
        case actions.STORE_TICKET_SOCKET_API_IMPORT_ONLY_EVENTS_DATA: {
            return {
                ...state,
                importOnlyEvents: response.data
            };
        }

        case actions.STORE_TICKET_SOCKET_API_DATA: {
            return {
                ...state,
                ticketSocketApiData: response.data
            };
        }

        default:
            return state;
    }
};
