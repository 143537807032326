import React, { Component } from 'react';
import Dropzone from 'react-fine-uploader/dropzone';
import FileInput from 'react-fine-uploader/file-input';
import enviromentVariables from 'erputils/enviromentVariables';
import FineUploaderTraditional from 'fine-uploader-wrappers';
import restClient from 'erpcore/api/restClient';
import Svg from 'erpcomponents/Svg';
import PropTypes from 'prop-types';
import './FileUploader.scss';
import { FormattedMessage } from 'react-intl';

class FileUploader extends Component {
    /**
     *
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            submittedFiles: [],
            uploadedFiles: []
        };

        const { endpoint, autoUpload, fieldProps, params } = props;
        const { itemLimit, allowedExtensions } = fieldProps;

        this.uploader = new FineUploaderTraditional({
            options: {
                request: {
                    method: 'POST',
                    forceMultipart: false,
                    omitDefaultParams: true,
                    requireSuccessJson: false,
                    endpoint,
                    params,
                    customHeaders: {
                        Authorization: `Bearer ${localStorage.token}`
                    }
                },
                multiple: false,
                sizeError: (
                    <FormattedMessage
                        id="Fileuploader.sizeError"
                        defaultMessage="The file should be 10 MB or less."
                    />
                ),
                typeError: (
                    <FormattedMessage
                        id="Fileuploader.typeError"
                        defaultMessage="Wrong file format."
                    />
                ),
                validation: {
                    itemLimit,
                    allowedExtensions,
                    sizeLimit: 10000000 // 10 MB = 10 * 1000 kB * 1024 bytes
                },
                cors: {
                    allowXdr: true,
                    expected: true
                },
                callbacks: {
                    onAllComplete: this.onAllComplete,
                    onError: this.onError,
                    onComplete: (id, name, responseJSON, xhr) =>
                        this.onComplete(id, responseJSON, xhr),
                    onSubmitted: this.onSubmitted,
                    onStatusChange: (id, oldStatus, newStatus) => this.onStatusChange(newStatus)
                },
                autoUpload
            }
        });

        this.onFileUploaded = this.onFileUploaded.bind(this);
    }

    // region Callbacks

    /**
     *
     */
    onStatusChange(newStatus) {
        if (newStatus === 'canceled') {
            const submittedFiles = this.uploader.methods.getUploads({
                status: ['submitting', 'uploading']
            });
            this.setState({ submittedFiles });
            const { fieldProps } = this.props;
            const { onStatusChange } = fieldProps;
            if (onStatusChange) onStatusChange(newStatus);
        }
    }

    /**
     *
     */
    onSubmitted = () => {
        const submittedFiles = this.uploader.methods.getUploads({
            status: ['submitting', 'submitted', 'uploading']
        });
        this.setState({ submittedFiles });
        const { fieldProps } = this.props;
        const { onSubmitted } = fieldProps;
        if (onSubmitted) onSubmitted(submittedFiles);
    };

    /**
     *
     * @param id {number}
     * @param responseJSON {object}
     */
    onComplete(id, responseJSON, xhr) {
        const { uploadedFiles } = this.state;

        const { fieldProps } = this.props;
        const { onComplete } = fieldProps;
        if (onComplete) onComplete(responseJSON, xhr);

        // refresh submitted files
        const submittedFiles = this.uploader.methods.getUploads({
            status: ['submitting', 'submitted', 'uploading']
        });

        this.setState(
            {
                submittedFiles,
                uploadedFiles: [
                    ...uploadedFiles,
                    {
                        _id: id,
                        id: responseJSON.data.id,
                        name: responseJSON.data.name,
                        deleting: false
                    }
                ]
            },
            () => this.onFileUploaded()
        );
    }

    /**
     *
     */
    onAllComplete = (succeeded = [], failed = []) => {
        const { fieldProps } = this.props;
        const { onAllComplete } = fieldProps;
        if (onAllComplete) onAllComplete({ succeeded, failed });
    };

    /**
     *
     */
    onError = (id = false, name = false, errorReason = false, xhrData = null) => {
        const { fieldProps } = this.props;
        const { onError } = fieldProps;
        if (onError) onError({ id, name, errorReason, xhrData });
    };

    // endregion

    // region Actions

    onFileUploaded() {
        // Call callback props onFileUploaded function
        const { onFileUploaded } = this.props;
        const { uploadedFiles: latestUploadedFiles } = this.state;
        return onFileUploaded(latestUploadedFiles);
    }

    /**
     *
     * @param ev {event}
     * @param id {number}
     */
    cancelItem(ev, id) {
        ev.preventDefault();
        this.uploader.methods.cancel(id);
    }

    /**
     *
     * @param ev {event}
     * @param id {number}
     */
    deleteItem(ev, id) {
        ev.preventDefault();
        const { uploadedFiles } = this.state;

        const newUploadedFiles = uploadedFiles.filter(item => {
            if (item.id === id) {
                item.deleting = true;
            }
            return item;
        });

        // What is this doing? (if down you set uploadedFiles again)
        this.setState({ uploadedFiles: newUploadedFiles });

        restClient.delete(`${enviromentVariables.REST_API}/api/files/${id}`).then(response => {
            if (response.status === 200) {
                this.setState(
                    prevState => {
                        const restOfTheUploaded = prevState.uploadedFiles.filter(item => {
                            if (item.id === id) {
                                item.deleting = true;
                            }
                            return item.id !== id;
                        });
                        return { uploadedFiles: restOfTheUploaded };
                    },
                    () => this.onFileUploaded()
                );
            }
        });
    }

    // endregion

    /**
     *
     * @returns {*}
     */
    render() {
        const {
            input,
            fieldProps,
            hideSubmittedFileList,
            hideSubmittedFileTitle,
            hideUploadedFileList,
            hideUploadedFilesTitle,
            params
        } = this.props;
        const { uploadedFiles, submittedFiles } = this.state;
        const { name } = input;
        const { label } = fieldProps;

        this.uploader.methods.setParams(params);

        return (
            <div className="fileuploader">
                <Dropzone
                    multiple
                    name={name}
                    uploader={this.uploader}
                    className="fileuploader__droparea"
                    dropActiveClassName="fileuploader__droparea--active"
                >
                    <div className="fileuploader__droparea-inner">
                        <Svg icon="upload" className="fileuploader__icon" />
                        <p className="fileuploader__text">
                            <FormattedMessage
                                id="Fileuploader.cta"
                                defaultMessage="Drag and Drop File Here"
                            />
                            <br />
                            <FormattedMessage id="Fileuploader.ctaOr" defaultMessage="or" />
                        </p>
                        <FileInput
                            multiple
                            uploader={this.uploader}
                            className="button button--secondary button--small"
                        >
                            <FormattedMessage id="Fileuploader.btn" defaultMessage="Browse Files" />
                        </FileInput>
                    </div>
                </Dropzone>
                {label && <p className="fileuploader__label">{label}</p>}
                {!hideSubmittedFileList && submittedFiles.length > 0 && (
                    <div className="fileuploader__filelist">
                        {!hideSubmittedFileTitle && (
                            <h2 className="fileuploader__filelist-title">
                                <FormattedMessage
                                    id="Fileuploader.fileListTitleForUploading"
                                    defaultMessage="Uploading Files..."
                                />
                            </h2>
                        )}
                        <ul className="fileuploader__filelist-items">
                            {submittedFiles.map(file => {
                                return (
                                    <li key={file.id} className="fileuploader__filelist-item">
                                        <span className="fileuploader__filelist-name">
                                            {file.name}
                                        </span>
                                        <button
                                            type="button"
                                            onClick={ev => this.cancelItem(ev, file.id)}
                                            className="fileuploader__filelist-remove"
                                        >
                                            <Svg icon="remove" />
                                        </button>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                )}
                {!hideUploadedFileList && uploadedFiles.length > 0 && (
                    <div className="fileuploader__filelist">
                        {!hideUploadedFilesTitle && (
                            <h2 className="fileuploader__filelist-title">
                                <FormattedMessage
                                    id="Fileuploader.fileListTitle"
                                    defaultMessage="Uploaded Files"
                                />
                            </h2>
                        )}
                        <ul className="fileuploader__filelist-items">
                            {uploadedFiles.map(file => {
                                return (
                                    <li
                                        key={file.id}
                                        className={`fileuploader__filelist-item ${
                                            file.deleting
                                                ? 'fileuploader__filelist-item--deleting'
                                                : ''
                                        }`}
                                    >
                                        <span className="fileuploader__filelist-name">
                                            {file.name}
                                        </span>
                                        <button
                                            type="button"
                                            onClick={ev => this.deleteItem(ev, file.id)}
                                            className="fileuploader__filelist-remove"
                                        >
                                            <Svg icon="remove" />
                                        </button>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                )}
            </div>
        );
    }
}

FileUploader.defaultProps = {
    input: {},
    endpoint: `${enviromentVariables.REST_API}/api/files`,
    autoUpload: true,
    fieldProps: {},
    hideSubmittedFileList: false,
    hideSubmittedFileTitle: false,
    hideUploadedFileList: false,
    hideUploadedFilesTitle: false,
    onFileUploaded: () => {},
    params: {}
};

FileUploader.propTypes = {
    input: PropTypes.oneOfType([PropTypes.object]),
    endpoint: PropTypes.string,
    autoUpload: PropTypes.bool,
    fieldProps: PropTypes.oneOfType([PropTypes.object]),
    hideSubmittedFileList: PropTypes.bool,
    hideSubmittedFileTitle: PropTypes.bool,
    hideUploadedFileList: PropTypes.bool,
    hideUploadedFilesTitle: PropTypes.bool,
    onFileUploaded: PropTypes.func,
    params: PropTypes.oneOfType([PropTypes.object])
};

export default FileUploader;
