export const actions = {
    START_SIGN_IN: 'START_SIGN_IN',
    SIGN_IN_SUCCESSFUL: 'SIGN_IN_SUCCESSFUL',
    SIGN_IN_FAILED: 'SIGN_IN_FAILED',
    START_SIGN_OUT: 'START_SIGN_OUT',
    SIGN_OUT_SUCCESSFUL: 'SIGN_OUT_SUCCESSFUL',
    STORE_USER_DATA: 'STORE_USER_DATA',
    UPDATE_USER_DATA: 'UPDATE_USER_DATA',
    UPDATE_USER_ABILITY: 'UPDATE_USER_ABILITY',
    START_FETCHING_ME: 'START_FETCHING_ME',
    FETCHING_ME_SUCCESSFULL: 'FETCHING_ME_SUCCESSFULL',
    FETCHING_ME_FAILED: 'FETCHING_ME_FAILED',
    START_FETCHING_ME_PERMISSIONS: 'START_FETCHING_ME_PERMISSIONS',
    FETCHING_ME_PERMISSIONS_SUCCESSFULL: 'FETCHING_ME_PERMISSIONS_SUCCESSFULL',
    FETCHING_ME_PERMISSIONS_FAILED: 'FETCHING_ME_PERMISSIONS_FAILED'
};

export const initialState = {
    token: '',
    user: null,
    isSignedIn: false
};

export default (state = initialState, { type, response }) => {
    switch (type) {
        case actions.START_SIGN_IN: {
            return {
                ...state
            };
        }
        case actions.SIGN_IN_SUCCESSFUL: {
            return {
                ...state,
                token: response,
                isSignedIn: true
            };
        }
        case actions.SIGN_IN_FAILED: {
            return {
                ...state,
                token: '',
                isSignedIn: false
            };
        }
        case actions.SIGN_OUT_SUCCESSFUL: {
            return {
                ...state,
                token: '',
                isSignedIn: false
            };
        }
        case actions.STORE_USER_DATA: {
            return {
                ...state,
                user: response.data,
                userOrganization: response.data.organization
            };
        }
        case actions.UPDATE_USER_DATA: {
            return {
                ...state,
                user: response.data
            };
        }
        // case actions.UPDATE_USER_ABILITY: {
        //     switch (response) {
        //         case roles.ADMIN:
        //             ability.update(abilityRoles.ADMIN);
        //             break;
        //         case roles.COMPANY_OWNER:
        //             ability.update(abilityRoles.COMPANY_OWNER);
        //             break;
        //         case roles.COMPANY_USER:
        //             ability.update(abilityRoles.COMPANY_USER);
        //             break;
        //         default:
        //             ability.update([]);
        //     }
        //
        //     return {
        //         ...state
        //     };
        // }
        default:
            return state;
    }
};
